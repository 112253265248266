import React, {useContext} from "react"
import LayoutContext from "../../context/LayoutContext"

type Props = {
    listLength: number
    isAbsolute?: boolean
}

const ListQuantityIndicator: React.FC<Props> = ({listLength, isAbsolute = true}) => {
	const {isMobile} = useContext(LayoutContext)
	return (
		<>
			{listLength > 0 ? <span
				className={`d-flex align-items-center justify-content-center ${isAbsolute && "position-absolute"} bg-primary`}
				style={{
					borderRadius: "100%",
					width: 16,
					height: 16,
					padding: "0.5px 4px",
					lineHeight: "16px",
					top: isMobile ? -2 : -2,
					right: isMobile ? -2 : -1,
					fontSize: "12px",
					fontWeight: 700,
					color: "#212529"
				}}>
				{listLength}
			</span> : null}
		</>

	)
}

export default ListQuantityIndicator

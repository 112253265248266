import {useTranslation} from "next-i18next"
import React, {useContext, useState} from "react"
import Typography from "../../reusable/Typography/Typography"
import FinancingExplanationTooltip from "./FinancingExplanationTooltip"
import LayoutContext from "../../../context/LayoutContext"
import MobileModal from "../../reusable/MobileModal"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

type Props = {
    hasTransparentBg?: boolean
    className?: string
}

const ZeroInterestTooltipSection:React.FC<Props> = ({hasTransparentBg, className}) => {
	const [isFinancingExplanationTooltipOpen, setIsFinancingExplanationTooltipOpen] = useState(false)
	const [isFinancingExplanationModalOpen, setIsFinancingExplanationModalOpen] = useState(false)
	const {isMobile} = useContext(LayoutContext)
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)

	return (<div style={{
		borderRadius: "16px 16px 0px 0px",
		zIndex: 0,
		marginBottom: !hasTransparentBg && "-10px",
		background: !hasTransparentBg && "#F8F8F8"
	}}
	className={`px-3 ${className ? className : "pt-2 pb-3"}`}>
		<div className="d-flex w-100  gap-2 align-items-center justify-content-start">
			<div><Typography semanticTag="div"
				variant="bodySm"
				style={{
					fontSize: "14px",
					lineHeight: "20px",
					fontWeight: 700
				}}>{t("no-interest-label")} - Bike Now, Pay Later </Typography> </div>
			<div className="social-proof-tag">
				<FinancingExplanationTooltip isFinancingExplanationTooltipOpen={isFinancingExplanationTooltipOpen} />
				<img className="cursor-pointer "
					onClick={() => {
						reactGA?.event({
							category: gaCategories.pdp,
							action: gaEvents.zeroInterestTooltipClicked,
							label: `${gaEvents.zeroInterestTooltipClicked}`,
							nonInteraction: false
						})
						if (isMobile) {
							setIsFinancingExplanationModalOpen(prev => !prev)
						} else {
							setIsFinancingExplanationTooltipOpen(prev => !prev)
						}
					}}
					src={isFinancingExplanationTooltipOpen || isFinancingExplanationModalOpen ? "/assets/icons/info-active.svg" : "/assets/icons/info-transparent.svg"}
					height={20}
					width={20} />
			</div>
		</div>
		<MobileModal modalOpen={isFinancingExplanationModalOpen}
			handleClose={() => setIsFinancingExplanationModalOpen(!isFinancingExplanationModalOpen)}>
			<Typography variant="bodyLgBlack"
				style={{fontSize: 24}}>{t("no-interest-label")} - Bike Now, Pay Later </Typography>
			<Typography variant="bodyLg"
				semanticTag="h4"
				className={" ml-5 mt-3"}>
				{t("Wir arbeiten mit dem Finanzierungspartner")} <a
					href="https://swissbilling.ch/">{t("swissbilling.ch")}</a> {t("zusammen, welcher es uns ermöglicht, dir die Ratenzahlung zinsfrei anzubieten.")}
				<br/><br/> {t("Als Gegenleistung erhält")} <a
					href="https://swissbilling.ch/">{t("swissbilling.ch")}</a> {t("von uns einen Anteil des Gewinns. Diesen Weg haben wir bewusst gewählt, um dir Extrakosten zu ersparen und jede*m den Weg zur E-Mobilität zu ermöglichen. Du hast weitere Fragen dazu? Wir geben auch telefonisch gerne darüber Auskunft!")}
			</Typography>
		</MobileModal>
	</div>)
}

export default ZeroInterestTooltipSection

import React, {useContext, useEffect, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import Price from "../bikesCollection/BikesCollectionItem/Price"
import TotalPrice from "../bikesCollection/BikesCollectionItem/TotalPrice"
import {useTranslation} from "next-i18next"
import Link from "next/link"
import Image from "next/image"
import CollectionItemBadge from "../bikesCollection/BikesCollectionItem/CollectionItemBadge"
import {isOccasionBike} from "../../utility/Helper"
import useDiscount from "../../hooks/useDiscount"
import WishListContext from "../../context/WishListContext"
import ComparisonCheckboxButton from "../bikesCollection/BikesCollectionItem/ComparisonCheckboxButton"
import LayoutContext from "../../context/LayoutContext"

type Props = {
	product: any,
	bike?: any,
	onHandleClosePopup?: ()=> void,
	hasBadge?: boolean
	hideComparison?: boolean
	isStory?: boolean
    inSearchContent?: boolean
    isReferredByAi?: boolean
    borderRadius?: string
}

const CompatibleAccessory: React.FC<Props> = ({
	product,
	bike,
	isReferredByAi,
	hasBadge,
	inSearchContent,
	onHandleClosePopup = null,
	hideComparison = false,
	isStory = false,
	borderRadius
}) => {
	const {t} = useTranslation("common")
	const {isMobile} = useContext(LayoutContext)
	const productUrl = `/${t("produkt")}/${product.slug}${isReferredByAi ? "?ai-suggested=true" : ""}`
	const {onSale, finalPriceWithoutDownPayment, discount} = useDiscount(product)
	const isSecondHand = isOccasionBike(product)
	const {addToWishList, getWishListItemBySlug, deleteWishListItemBySlug} = useContext(WishListContext)
	const [isBookmarked, setIsBookmarked] = useState<boolean>(null)
	const handleDeleteBookmark = e => {
		e.preventDefault()
		e.stopPropagation()
		deleteWishListItemBySlug(bike?.slug)
		setIsBookmarked(() => false)
	}

	const handleAddBookmark = e => {
		e.preventDefault()
		e.stopPropagation()
		addToWishList(bike)
		setIsBookmarked(() => true)
	}

	useEffect(() => {
		const bikeFoundInBookmark = getWishListItemBySlug(bike?.slug)
		if (bikeFoundInBookmark) {
			setIsBookmarked(() => Boolean(bikeFoundInBookmark))
		}
	}, [addToWishList, deleteWishListItemBySlug])

	return (
		<Link href={productUrl}>
			<a className={"link-reset col-12 position-relative"}
				onClick={onHandleClosePopup}>
				<div style={{minWidth: isStory ? "320px" : "220px"}}
					className="h-100">
					<div className="h-100"
						style={{
							borderRadius: borderRadius ? borderRadius : 16,
							padding: "8px 16px",
							border: "1px solid #DEE2E6",
							backgroundColor: "#fff"
						}}>
						<div className="d-flex pe-2 position-relative"
							style={{minHeight: inSearchContent ? 60 : 100}}>
							<div className="col-auto">
								<Image src={product.images[0]?.src}
									alt="bike accessory"
									height={72}
									width={72}
									objectFit={"contain"}/>
							</div>
							<div
								className="ps-2 col-9 d-flex flex-column justify-content-center
                            text-start text-wrap text-truncate">
								{hasBadge && <div
									className="ps-0 d-flex align-items-start z-index"
								>
									<CollectionItemBadge
										inStock={product.stock_status === "instock"}
										onSale={onSale}
										discount={discount}
										fontSizeXSm
									/>
								</div>}
								<div className="d-flex overflow-hidden align-items-start gap-2">
									<Typography
										style={{
											maxWidth: inSearchContent ? "" : 160,
											width: "90%",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap"}}
										className="multiline-truncate pe-2 d-inline-block "
										variant={"bodySmBold"}>
										{product.name}
									</Typography>
									{isStory ? <div className="bookmark-compatible-accessories" >
										{isBookmarked ? <div style={{width: "24px"}}
											onClick={handleDeleteBookmark}>
											<img className="bookmark"
												src={"/assets/icons/Heart-gold.svg"}
												alt="Wish list icon" />
										</div> :
											<div style={{width: "24px"}}
												onClick={handleDeleteBookmark}>
												<img onClick={handleAddBookmark}
													className="bookmark"
													src={"/assets/icons/Heart.svg"}
													alt="Wish list icon" />
											</div>}
									</div> :
										isBookmarked ? <div style={{width: "24px"}}
											onClick={handleDeleteBookmark}>
											<img className="compatible-accessories-btn-bookmark"
												src={"/assets/icons/Heart-gold.svg"}
												alt="Wish list icon" /></div> : <div style={{width: "24px"}}
											onClick={handleDeleteBookmark}><img onClick={handleAddBookmark}
												className="compatible-accessories-btn-bookmark "
												src={"/assets/icons/Heart.svg"}
												alt="Wish list icon" /> </div>
									}
									{hideComparison ? null :
										<ComparisonCheckboxButton isCompatibleAccessories={!isMobile}
											slug={product.slug}/>}</div>

								<div className={"col-12 px-0 mt-2"}>
									<Price
										price={Number(finalPriceWithoutDownPayment)}
										discountedPrice={finalPriceWithoutDownPayment}
										onSale={onSale}
										isSecondHand={isSecondHand}
										secondHandPrice={finalPriceWithoutDownPayment}
										priceFontSize={24}/>
									{inSearchContent ?
										null :
										<TotalPrice isSmallProductCard
											price={product.uvpPrice || parseFloat(product.price)}
											onSale={onSale}
											discountedPrice={finalPriceWithoutDownPayment}
											isSecondHand={isSecondHand}
											priceVariant="bodyXSm"
											secondHandPrice={product.displayPrice || parseFloat(product.price)}/>
									}
								</div>

							</div>
						</div>
					</div>
				</div>
			</a>
		</Link>
	)
}

export default CompatibleAccessory

import React from "react"
import {variants} from "../middleware"

export type ABContextState = {
    variant: string | null
}

const ABContext = React.createContext<ABContextState>({
	variant: variants.old
})

export default ABContext

import React from "react"
import {useTranslation} from "next-i18next"
import AnimationComponent from "../reusable/components/AnimationComponent/AnimationComponent"
import Spinner from "../reusable/Spinner"

enum Colors {
	light = "rgba(255,255,255,0.8)",
	dark = "rgba(0,0,0,0.8)"
 }

type Props = {
    isVisible: boolean
	color?: keyof typeof Colors
}

const LoadingOverlay = ({isVisible, color = "light"}: Props): React.ReactElement => {
	const {t} = useTranslation()
	return (
		<AnimationComponent type="fade"
			inProp={isVisible}>
			<div className={"position-fixed top-0 left-0 vw-100 vh-100"}
				style={{
					backgroundColor: Colors[color],
					zIndex: 99999999
				}}>
				<div className={"container"}>
					<div className={"row vh-100 justify-content-center align-items-center"}>
						<Spinner />
					</div>
				</div>
			</div>
		</AnimationComponent>
	)
}

export default LoadingOverlay

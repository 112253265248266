import React, {useEffect} from "react"
import AnimationComponent from "./components/AnimationComponent/AnimationComponent"
import {X} from "react-bootstrap-icons"
import Typography from "./Typography/Typography"

type Props = {
	children: React.ReactNode;
	modalOpen: boolean;
	handleClose: () => void;
	modalTitle?: string;
	modalHeader?: React.ReactNode;
	className?: string;
	isAiModal?: boolean;
};

function MobileModal({
	children,
	modalOpen,
	handleClose,
	modalTitle,
	modalHeader,
	className,
	isAiModal = false
}: Props) {
	useEffect(() => {
		if (!modalOpen) {
			document.body.style.overflow = "auto"
		} else if (modalOpen) {
			document.body.style.overflow = "hidden"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [modalOpen])
	return (
		<>
			<AnimationComponent inProp={modalOpen}
				type="fade">
				<div
					className={`position-fixed d-md-none vh-100 vw-100 ${isAiModal ? "ai-modal" : ""}`}
					style={{
						backgroundColor: "rgba(0,0,0,0.8)",
						zIndex: 10000009,
						top: 0,
						left: 0
					}}
					onClick={e => {
						e.stopPropagation()
						handleClose()
					}}
				></div>
			</AnimationComponent>

			<AnimationComponent type="slideUp"
				inProp={modalOpen}>
				<>
					<div
						onClick={e => e.stopPropagation()}
						className={`position-fixed d-md-none px-2 py-4 bg-white start-0 bottom-0 end-0 ${className} ${isAiModal ? "shadow-top" : ""}`}
						style={{
							zIndex: 10000010,
							borderRadius: "16px 16px 0px 0px",
							border: "1px solid #f4f4f4"
						}}
					>
						<div className={`w-100 z-10 container d-flex px-2
                             ${modalTitle || modalHeader ? "justify-content-between" : "justify-content-end"} align-items-center cursor-pointer`}>
							{modalTitle && <Typography variant="bodyLgBlack"
								style={{fontSize: 28}}>{modalTitle}</Typography>}
							{modalHeader}
							<X onClick={handleClose}
								size={28} />
						</div>
						{children}
					</div>
				</>
			</AnimationComponent>
		</>
	)
}

export default MobileModal

import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import React, {useEffect} from "react"
import NewMegaMenu from "../desktop/NewMegaMenu"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import {ArrowLeft, ChevronLeft} from "react-bootstrap-icons"

type Props = {
	setIsEbikesMenuVisible?: any
	isEbikesMenuVisible?: any
	activeCategory?: any
	setIsMenuVisible?: any
}

const EbikesMenu: React.FC<Props> = ({setIsEbikesMenuVisible, isEbikesMenuVisible, activeCategory, setIsMenuVisible}) => {
	const {t} = useTranslation(["common", "filters"])
	const router = useRouter()
	const isBrandWorld = router.pathname.includes("/brand-world")
	useEffect(() => {
		if (isEbikesMenuVisible) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "auto"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [isEbikesMenuVisible])
	return (
		<>
			<AnimationComponent type="slideRight"
				inProp={isEbikesMenuVisible}>
				<div
					className={"position-fixed border-top  start-0 px-2 pt-4 overflow-auto"}
					style={{zIndex: 99999, top: 0, width: "100%", height: "100%", paddingBottom: 250, background: isBrandWorld ? "#212529" : "#fff"}}>
					<div
						className="d-flex align-items-center py-2 mb-3">
						{isBrandWorld ? <ArrowLeft onClick={() => setIsEbikesMenuVisible(false)}
							size={20}
							color="#fff"/> : <Image
							src="/assets/icons/arrow-left.svg"
							width={20}
							height={20}
							alt={"arrow left"}
							className="cursor-pointer"
							onClick={() => setIsEbikesMenuVisible(false)}
						/>}
						<Typography variant="heading3MdBodyLgBold"
							semanticTag="span"
							className="ms-3">{t(activeCategory?.title)}</Typography>
					</div>
					<NewMegaMenu item={activeCategory}
						toggleMenu={setIsMenuVisible}/>
				</div>
			</AnimationComponent>

		</>
	)
}

export default EbikesMenu

import React from "react"
import AnimationComponent from "../AnimationComponent/AnimationComponent"

type Props = {
    isOpen: boolean
    closeDropdown?:()=>void
    children: React.ReactNode
	top?: number
	left?: number
	backdrop?: boolean,
	sortList?: boolean,
	height?: number | string,
	width?: number | string,
	className?: string
	borderRadius?: string | number
    hasShadow?: boolean
    hasSearchInput?: boolean
    searchValue?: string
    onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    searchPlaceholder?: string
}

const Dropdown = ({className, height, hasShadow = true,
	width, sortList, borderRadius = 4, isOpen, closeDropdown = () => {},
	backdrop,
	top = 0,
	left = 0,
	hasSearchInput = false,
	searchValue = "",
	onSearchChange,
	searchPlaceholder = "Search...",
	children}:Props) => {
	return (
		<AnimationComponent inProp={isOpen}
			type="fade">
			<>
				{backdrop && <div className="position-fixed vh-100 vw-100"
					style={{
						backgroundColor: "#0000000",
						zIndex: 1000,
						top: 0, left: 0

					}}
					onClick={closeDropdown}/>}
				{sortList ? <div style={{zIndex: 11000, top, left, height, width}}
					className={`${className} position-absolute p-0 pt-1 d-block  container`}>
					{children}
				</div> :
					<div
						style={{
							zIndex: 1000,
							top,
							left,
							maxHeight: height,
							maxWidth: width,
							width: "100%",
							borderRadius: borderRadius ? borderRadius : "",
							boxShadow: hasShadow ? "0px 0px 8px 0px rgba(33,37,41,0.12)" : ""
						}}
						className={`${className} position-absolute p-0 d-block  container ${hasSearchInput && "bg-white mt-1 p-2 border "}`}>
						{hasSearchInput && (
							<div className=" mb py-1 bg-white  rounded-3">
								<input
									type="text"
									className="col-12 py-2 px-3 rounded-3 border service-search-field"
									placeholder={searchPlaceholder}
									value={searchValue}
									onChange={onSearchChange}
								/>
							</div>
						)}

						{children}
					</div>
				}
			</>
		</AnimationComponent>
	)
}

export default Dropdown


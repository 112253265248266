import React from "react"

export type StoryItem = {
    id: string
    imageUrl?: string
    thumbnail?: string
    videoUrl: string
    duration?: number
    label?: string
    cta?: { btnText?: string, description?: string, href?: string | null, isFullWidth?:boolean }
    featuredBike?:{slug?:string, cta?:string}
}

export type Info = {
    duration: number,
    currentTime: number
}

interface StoryContextProps {
    stories: StoryItem[]
    info: Info
    next: (swiped?:boolean) => void
    previous: (swiped?:boolean) => void
    playFromStart: () => void
    getCurrentIndex: () => number | null
}

const StoryContext = React.createContext<StoryContextProps>({
	stories: [],
	info: null,
	next: () => {},
	previous: () => {},
	playFromStart: () => {},
	getCurrentIndex: () => null
})

export default StoryContext

import React, {useContext, useEffect, useRef, useState} from "react"
import {ArrowRight, Stars} from "react-bootstrap-icons"
import Button from "../../reusable/components/Button"
import Typography from "../../reusable/Typography/Typography"
import Spinner from "../../reusable/Spinner"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {clarityEvents, gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import {storeDataToSpreadsheet} from "../../../utility/Helper"
import Link from "next/link"
import {onlyAllowedLinksForAi} from "../../../constants"
import Image from "next/image"

type Props = {
	slug: string
	name:string
}
function PdpAiAssistant({slug, name}: Props) {
	const [input, setInput] = useState("")
	const [loading, setLoading] = useState(false)
	const [result, setResult] = useState("")
	const textArea = useRef(null)
	const router = useRouter()
	const {t} = useTranslation()
	const [link, setLink] = useState<string | undefined>()
	const {reactGA} = useContext(AnalyticsContext)
	const handleSubmit = async (e, text = null) => {
		e.preventDefault()
		setLoading(true)

		if (typeof window !== "undefined") {
			window.clarity("set", clarityEvents.askedAi, "User Asked PDP AI")
		}

		reactGA?.event({
			category: gaCategories.pdp,
			action: `${gaEvents.askedPdpAi} ${input}`,
			label: `${gaEvents.askedPdpAi} ${input}`,
			nonInteraction: false
		})

		const updatedMessages = text ? text : input

		try {
			const res = await fetch(`/api/ai/chat/${slug}?locale=${router.locale || "de"}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					description: updatedMessages
				})
			})

			const data = await res.json()

			setResult(() => data.response)
			reactGA?.event({
				category: gaCategories.pdp,
				action: `${gaEvents.AiAnswer} ${data.response} To the question ${input}`,
				label: `${gaEvents.AiAnswer} ${data.response} To the question ${input}`,
				nonInteraction: false
			})
			await storeDataToSpreadsheet({
				bike: name,
				question: `${input}`,
				answer: data.response
			}, "pdpAi")
		} catch (error) {
			console.error("Error communicating with the API:", error)
		}

		setLoading(false)
	}

	useEffect(() => {
		if (result) {
			for (const aiLink of onlyAllowedLinksForAi) {
				if (result.includes(aiLink)) {
					setLink(() => aiLink)
				}
			}
		}
	}, [result])

	const handleAskAnotherQuestion = () => {
		setInput("")
		setResult("")
		textArea.current.focus()
	}

	const predefinedQuestions = [
		"ai-predefined-question-1",
		"ai-predefined-question-2",
		"ai-predefined-question-3",
		"ai-predefined-question-4",
		"ai-predefined-question-5",
		"ai-predefined-question-6"
	]
	return (
		<div className="d-flex align-items-center justify-content-start gap-3 py-3 flex-column">
			<div className="d-flex container align-items-start justify-content-start gap-1 col-12">
				<Image src={"/assets/icons/ryder-logo-primary.svg"}
					height={24}
					width={24} />
				<Typography style={{fontSize: "14px", lineHeight: "20px"}}
					variant="bodySmBold">{t("ask-ai-about-bike-label")}</Typography>
			</div>
			<div style={{gap: "8px", maxWidth: "100%", overflowX: "auto", paddingLeft: "1.5rem"}}
				className=" d-flex  align-items-start justify-content-start flex-md-wrap">
				{predefinedQuestions.map((question, index) => <div key={index}
					onClick={e => {
						setInput(t(question))
						handleSubmit(e, t(question))
					}}
					className="predefined-ai-question">
					<Typography style={{fontSize: "14px", lineHeight: "20px", color: "#750F9B"}}
						variant="bodySm">{t(question)}</Typography>
				</div>)}
			</div>
			<div className="container">
				<form className="send-message m-auto"
					onSubmit={handleSubmit}>
					<textarea
						ref={textArea}
						className="input-field"
						value={input}
						rows={1}
						onChange={e => setInput(e.target.value)}
						placeholder={t("pdp-ai-textarea-description")}
						required />
					<Button type="submit"
						bgColor={"#A219D4"}
						style={{maxWidth: "40px"}}
						className="try-ai position-relative"
						fontSizeSmall
						icon={<ArrowRight size={24}
							color="#fff" />}/>
				</form>
				{loading ? <Spinner style={ {marginTop: "1rem"}} /> : result?.length ? <div className={"ai-response-pdp  slide-in-top"}>
					<div className="d-flex align-items-center justify-content-start gap-1 mt-2"><Stars />
						<Typography style={{fontSize: "14px", lineHeight: "20px"}}
							variant="bodySmBold">{t("ai-answer-label")}</Typography></div>
					{result.includes("https://") && link ?
						<Typography variant="bodySm">{result.slice(0, result.indexOf("https://"))}
							<Link className="cursor-pointer text-primary"
								href={link ? link : "/#"}>
								{link}
							</Link>
							{result.slice(result.indexOf(" ", result.indexOf("https://")))} </Typography> :
						<Typography variant="bodySm">{result} </Typography>}
					<Typography style={{fontSize: "14px", lineHeight: "18px"}}
						variant="bodySmBold">{t("sincerely")} <br /> Ryder </Typography>
					<Typography onClick={handleAskAnotherQuestion}
						className="mt-2 cursor-pointer"
						style={{fontSize: "14px", lineHeight: "20px", color: "#C537F8"}}
						variant="bodySmBold">{t("ask-another-question-label")}</Typography>
				</div> : null}
			</div>

		</div>
	)
}

export default PdpAiAssistant

import React from "react"
import {CartItem} from "./CartContext"

/* eslint-disable */
type Contact = {
    email: string
    gender: string
    firstname: string
    lastname: string
    id_upload_link: string
    contract_type: string
    permit_type: string
    date_of_entry: string
    permit_valid_until: string
    date_of_birth: string
    phone: string
    prefix: string
    // address: string
    street: string
    street_number: number
    city: string
    zip: string
    // delivery_street_address: string
    secondary_address_street: string
    secondary_address_street_number: number
    secondary_address_city: string
    secondary_address_zip: string
    language_selected: string
    unique_referral_code: string
    date_of_birth_month: string
    date_of_birth_day: string
    date_of_birth_year: string
    permit_valid_until_month: string
    permit_valid_until_day: string
    permit_valid_until_year: string
    date_of_entry_month: string
    date_of_entry_day: string
    date_of_entry_year: string
    firebase_contact_uid?: string
}

export const DealStages = {
    appointmentscheduled: "appointmentscheduled"
}

export const DealPipelines = {
    default: "default"
}

export type Deal = {
    e_bike_frame: string,
    e_bike_choice: string,
    e_bike_size: string,
    e_bike_color: string,
    e_bike_price: string,
    plan_length: number,
    article_number_2: string,
    amount: string,
    dealname: string,
    pipeline: string,
    dealstage: string,
    discount_code: string,
    e_bike_category: string,
    expected_delivery_date__website_: string,
    deliveryTimeInDays: number,
    checkout_id?: string | null,
    bikeId: number,
    down_payment_amount: number | null
    initialPrice: number | null
}
/* eslint-enable */

export type CheckoutData = {
    contact: Contact | null,
    deals: Deal[],
    planLength: number,
    discountCode: string,
    discount: number | null,
    type: string,
    resultingPrice: number | null,
    notApplicable?: boolean,
    checkoutId: string,
    savedDataFromCart?: any[]
    insurance?: boolean,
    insurancePrice?: number,
    downPayment?: any
    contactInfoFilled?: boolean
    addressInfoFilled?: boolean
    insuranceFilled?: boolean
    accessoriesFilled?: boolean
    isContactInfoDropDownOpen?: boolean
    isAddressInfoDropDownOpen?: boolean
    isInssuranceDropDownOpen?: boolean
    isAccessoriesDropDownOpen?: boolean
    activeStep?: number
    contactInfoRef?: any
    addressInfoRef?: any
}

export type CheckoutContextState = {
    data: CheckoutData

    setData: (data: (prevData) => any) => void

    storeData: (data: CheckoutData) => void
    updateCheckoutDeals: () => void
    totalDownPayment: number
    checkoutPostHandler: (contact:any, deals:any, idToken:string, insurance:boolean, planLength:number, cartItems:CartItem[], payOneTime:boolean, sponsorMeToken:string, organizationSlug?:string) => any
}

const CheckoutContext = React.createContext<CheckoutContextState>({
	data: null,
	setData: () => {
		// Do nothing
	},
	storeData: () => {
		// Do nothing
	},
	updateCheckoutDeals: () => {
		// Do nothing
	},
	totalDownPayment: 0,
	checkoutPostHandler: () => {
		// Do nothing
	}
})

export default CheckoutContext

import React from "react"

type OrganizationType = {
    id?: number;
    companyName?: string;
    tabIcon?: string;
    companyIcon?: string;
    email?: string | null;
    createdAt?: string;
    updatedAt?: string;
    publishedAt?: string;
    key?: string;
    locale?: string;
    showBannerIcon?: string | null;
    anyEmailAllowed?: boolean;
    hideLoginPopup?:boolean
    isPublic?: boolean
    isNotDiscountedInstance?:boolean
    hideHomepageCustomText?:boolean
    hideCustomLogos?: boolean
    maxPlanLength?: string;
    maxDiscountCodeAmount?: number;
    combineDiscountCodeAndPublicDiscount?: boolean;
    restrictCartToOnlyOneProductMax?: boolean;
    discountCodeIsRequiredForCheckout?: boolean;
    home?: {
        id?: number;
        subTitle?: string;
        text?: string;
    };
    stickyBar?: {
        id?: number;
        text?: string;
        buttonCTA?: string;
        buttonLink?: string;
        textColor?:string
    backgroundColor?:string
    btnTextColor?:string
    };
    banner?: {
        id?: number;
        bgColor?: string;
        textColor?: string;
        logo?: string;
        header?: string;
        subHeader?: string;
    };
    listingItem?: {
        id?: number;
        customLogo?: string
        textColor?:string
        text?: string;
    };
    loginPopup?: {
        id?: number;
        logo?: string;
        title?: string;
        subTitle?: string;
        bulletPoints?: string;
    };
};

export type OrganizationContextProviderState = {
	organizationSlug: string | null,
	organizationInitialized: boolean,
	organizationData: OrganizationType | null
}

const OrganizationContext = React.createContext<OrganizationContextProviderState>({
	organizationSlug: null,
	organizationInitialized: false,
	organizationData: null
})

export default OrganizationContext

import React from "react"

export type RouteTransitionContextState = {
    transitioning: boolean,

    setTransitioning: (transitioning: boolean) => void
}

const RouteTransitionContext = React.createContext<RouteTransitionContextState>({
	transitioning: false,
	setTransitioning: () => {
		// Do nothing
	}
})

export default RouteTransitionContext

import React, {useState} from "react"
import AnimationComponent from "../reusable/components/AnimationComponent/AnimationComponent"
import {useRouter} from "next/router"
import {onModalButtonClick} from "../bikesCollection/BikesCollectionItem/SimilarBikesPdpButton"
import SimilarBikesModalTabs from "./components/SimilarBikesModalTabs"
import SimilarBikesModalHeader from "./components/SimilarBikesModalHeader"
import SimilarBikesModalContent from "./components/SimilarBikesModalContent"
import {Attribute} from "../../utility/Types"
import MobileModal from "../reusable/MobileModal"
import {useTranslation} from "next-i18next"

export type tabValueType = "similar-attributes" | "same-brand"
export type filterValueType = "speed" | "range" | "battery-capacity" | "brand" | null

type Props = {
    attributes:Attribute[]
}
function SimilarBikesModal({attributes}:Props) {
	const router = useRouter()
	const {t} = useTranslation()
	const isSimilarBikesModalVisible = router?.query?.modal === "open"
	const [activeTab, setActiveTab] = useState<tabValueType>("similar-attributes")
	const [activeFilter, setActiveFilter] = useState<filterValueType>(null)

	return (
		<>
			<div onClick={e => {
				e.stopPropagation()
				onModalButtonClick(router, router.pathname)
			}}
			className="d-none d-md-flex  justify-centent-center align-items-center"
			style={{background: "rgba(0,0,0,0.7)", position: "fixed", zIndex: 999999999999999, inset: 0, visibility: isSimilarBikesModalVisible ? null : "hidden"}} >
				<AnimationComponent type="fade"
					inProp={isSimilarBikesModalVisible}>
					<div onClick={e => e.stopPropagation()}
						style={{borderRadius: 16, maxWidth: "1060px"}}
						className=" similar-bikes-container-padding gap-1  container d-flex flex-column justify-content-start align-items-start  bg-white  ">
						<SimilarBikesModalHeader />
						<SimilarBikesModalTabs attributes={attributes}
							activeFilter={activeFilter}
							setActiveFilter={setActiveFilter}
							setActiveTab={setActiveTab}
							activeTab={activeTab} />
						<SimilarBikesModalContent activeFilter={activeFilter}
							attributes={attributes} />
					</div>
				</AnimationComponent>
			</div>
			<MobileModal modalTitle={t("similar-bikes")}
				modalOpen={isSimilarBikesModalVisible}
				handleClose={() => onModalButtonClick(router, router.pathname)}>
				<div onClick={e => e.stopPropagation()}
					style={{borderRadius: 16, maxWidth: "1060px"}}
					className=" similar-bikes-container-padding gap-1  container d-flex flex-column justify-content-start align-items-start  bg-white  ">
					<SimilarBikesModalTabs attributes={attributes}
						activeFilter={activeFilter}
						setActiveFilter={setActiveFilter}
						setActiveTab={setActiveTab}
						activeTab={activeTab} />
					<SimilarBikesModalContent isMobile={true}
						activeFilter={activeFilter}
						attributes={attributes} />
				</div>
			</MobileModal>
		</>
	)
}

export default SimilarBikesModal

import React from "react"
import {findBikeAttributeBySlug} from "../../utility/Helper"
import Typography from "../reusable/Typography/Typography"
import AboutThisBike from "./AboutThisBike"
import BikeDescription from "./productInfo/BikeDescription"
import BikeInfoAndOverview from "./components/BikeInfoAndOverview"
import BikeSpecs from "./components/BikeSpecs"
import BikeSizes from "./productInfo/BikeSizes"

const getAccordionContent = (bike: any, isAccessory:boolean, t:(_:string)=>string) => {
	const videoUrl = findBikeAttributeBySlug(bike, "youtube-link", true)
	const videoEmbed = videoUrl && videoUrl?.replace("watch?v=", "embed/")

	return [
		{
			title: "Besonderheiten",
			slug: "overview-tabs",
			titleContent: (
				<Typography variant="heading1Black"
					semanticTag="span">
					{t("Highlights")}
				</Typography>
			),
			content: (
				<div className={"bike-details-tab-panel"}>
					<AboutThisBike bike={bike}
						isForPDP/>
				</div>
			)
		},
		{
			title: "Übersicht",
			titleContent: (
				<Typography variant="heading1Black"
					semanticTag="span">
					{t("Übersicht")}
				</Typography>
			),
			content: (
				<div className={"bike-details-tab-panel"}>
					<BikeInfoAndOverview bike={bike}
					/>
				</div>
			)
		},
		{
			title: "Einzelheiten",
			titleContent: (
				<Typography variant="heading1Black"
					semanticTag="span">
					{t("Einzelheiten")}
				</Typography>
			),
			content: (
				<div className={"bike-details-tab-panel"}>
					<BikeSpecs
						bike={bike}/>
				</div>
			)
		},
		{
			title: "Beschreibung",
			titleContent: (
				<Typography variant="heading1Black"
					semanticTag="span">
					{t("Beschreibung")}
				</Typography>),
			content: (
				<div className={"bike-details-tab-panel"}>
					<BikeDescription description={bike?.short_description}/>
				</div>
			)
		},
		{
			title: "Grössenempfehlung",
			titleContent: (
				<Typography variant="heading1Black"
					semanticTag="span">
					{t("Sizes")}
				</Typography>
			),
			content: (
				<div className={"bike-details-tab-panel"}>
					<BikeSizes bike={bike} />
				</div>
			)
		},
		{
			title: "Youtube Videos",
			titleContent: (
				<Typography variant="heading1Black"
					semanticTag="span">
					YouTube Videos
				</Typography>
			),
			content: (
				<div className={"bike-details-tab-panel"}>
					{
						videoEmbed ?
							<div className={"row"}>
								<div className={"col-12 col-lg-6 mt-4 mt-lg-0"}>
									<div className="ratio ratio-16x9">
										<iframe width="560"
											height="315"
											src={videoEmbed}
											title="YouTube video player"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											allowFullScreen></iframe>
									</div>
								</div>
							</div> :
							null
					}
				</div>
			)
		}

	].filter(item => !(!bike.short_description && item.title === "Beschreibung") && !(isAccessory && item.title === "Besonderheiten"))
}

export default getAccordionContent

import React, {useContext, useEffect, useState} from "react"
import Typography from "./Typography/Typography"
import SearchResultItems from "../search/SearchResultItems"
import {useRouter} from "next/router"
import MobileNavBarAi from "./MobileNavBarAi"
import AnalyticsContext from "../../context/AnalyticsContext"
import {clarityEvents, gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import Image from "next/image"
import AskAiAboutLifestyle from "./AskAiAboutLifestyle"
import AiPromptSuggestions from "./AiPromptSuggestions"
import AiSuggestionLoadingSkeleton from "./LoadingSkeleton/AiSuggestionLoadingSkeleton"
import AiDrawerHeader from "./AiDrawerHeader"
import WishListContext from "../../context/WishListContext"
import {useTranslation} from "next-i18next"
import {storeDataToSpreadsheet} from "../../utility/Helper"

function MyBikePlanAiChat({closeModal, isOpen, hideDrawerContent}: { closeModal: () => void, isOpen:boolean, hideDrawerContent }) {
	const [input, setInput] = useState("")

	const [loading, setLoading] = useState(false)
	const [isGenerating, setIsGenerating] = useState(false)
	const [error, setError] = useState(false)
	const [activePrompt, setActivePrompt] = useState("")
	const {addToWishList} = useContext(WishListContext)
	const [result, setResult] = useState([])
	const [step, setStep] = useState<1 | 2 | 3>(1)
	const [suggestedPrompts, setSuggestedPrompts] = useState([])
	const router = useRouter()
	const {reactGA} = useContext(AnalyticsContext)
	const resetState = () => {
		setResult(() => [])
		setActivePrompt(() => "")
		setSuggestedPrompts(() => [])
		setStep(() => 1)
		setIsGenerating(false)
		setError(false)
	}

	const handleSubmit = async prompt => {
		setLoading(true)
		localStorage.setItem("userLifestyle", prompt)
		window.dispatchEvent(new Event("userLifestyleUpdated"))
		const updatedMessages = prompt

		reactGA?.event({
			category: gaCategories.pdp,
			action: `${gaEvents.userChoseAISuggestion} ${activePrompt}`,
			label: `${gaEvents.userChoseAISuggestion} ${activePrompt}`,
			nonInteraction: false
		})

		try {
			const res = await fetch("/api/ai/chat", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					description: updatedMessages,
					locale: router.locale || "de"
				})
			})
			const data = await res.json()
			setResult(() => data.response)
			setLoading(false)

			if (!data?.response?.length) {
				setError(true)
				return
			}

			for (const aiGeneratedBike of data.response) {
				addToWishList(aiGeneratedBike)
			}

			await storeDataToSpreadsheet({
				question: `${input}`,
				answer: prompt
			}, "searchAi")
		} catch (error) {
			setError(true)
			setLoading(false)
			console.error("Error communicating with the API:", error)
		}
	}

	const generateUserPrompts = async e => {
		setIsGenerating(true)
		e.preventDefault()
		const updatedMessages = `${input}`.trim()
		if (typeof window !== "undefined") {
			window.clarity("set", clarityEvents.askedAi, "User Asked Ai")
		}

		reactGA?.event({
			category: gaCategories.megaMenu,
			action: `${gaEvents.wroteLifestyleDescription} ${input}`,
			label: `${gaEvents.wroteLifestyleDescription} ${input}`,
			nonInteraction: false
		})

		if (updatedMessages) {
			try {
				const res = await fetch("/api/ai/generate-prompts", {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						description: updatedMessages,
						locale: router.locale || "de"
					})
				})
				const data = await res.json()
				setIsGenerating(false)
				setSuggestedPrompts(data.response || [])
				if (!data?.response?.length) {
					setError(true)
				}
			} catch (error) {
				setError(true)
				setIsGenerating(false)
				console.error("Error generating prompts:", error)
			}
		}
	}

	useEffect(() => {
		return () => {
			resetState()
		}
	}, [])
	const {t} = useTranslation()
	const loadingElementsOnDesktop = [1, 2, 3, 4]
	return (<>
		{hideDrawerContent ? null : <div style={{maxHeight: "90dvh", overflow: "auto"}}
			className="d-md-flex d-none align-items-start justify-content-start flex-column p-3 gap-4">
			{error ?
				<div style={{maxHeight: "80dvh", maxWidth: "460px", overflow: "auto"}}
					className={"d-flex slide-in-right  col-12 mt-4 relative align-items-center flex-column justify-content-start px-3 gap-3"}>
					<AiDrawerHeader closeModal={closeModal}
						error={error}
						step={step}
						loading={loading}
						result={result}
						setIsGenerating={setIsGenerating}
						setResult={setResult}
						setLoading={setLoading}
						setStep={setStep}
						setSuggestedPrompts={setSuggestedPrompts}
						suggestedPrompts={suggestedPrompts} />

					<Typography style={{color: "#C3BABA", fontSize: "14px", textAlign: "center", lineHeight: "22px"}}
						variant="bodySm">{t("something-is-wrong")}</Typography>
					<Image src={"/assets/icons/robot.png"}
						width={70}
						height={70}
						objectFit="contain"
						alt="AI Error"/>
				</div> :
				<div style={{width: "100%", maxWidth: "460px"}}
					className={"d-flex col-12 relative align-items-start justify-content-start flex-column px-3 gap-3"}>
					<AiDrawerHeader closeModal={closeModal}
						error={error}
						step={step}
						loading={loading}
						result={result}
						setIsGenerating={setIsGenerating}
						setResult={setResult}
						setLoading={setLoading}
						setStep={setStep}
						setSuggestedPrompts={setSuggestedPrompts}
						suggestedPrompts={suggestedPrompts} />

					<AskAiAboutLifestyle resetState={resetState}
						step={step}
						setStep={setStep}
						generateUserPrompts={generateUserPrompts}
						input={input}
						isGenerating={isGenerating}
						setInput={setInput}
						suggestedPrompts={suggestedPrompts} />

					<AiPromptSuggestions step={step}
						setStep={setStep}
						activePrompt={activePrompt}
						handleSubmit={handleSubmit}
						isGenerating={isGenerating}
						loading={loading}
						result={result}
						setActivePrompt={setActivePrompt}
						suggestedPrompts={suggestedPrompts} />

					{result?.length && !loading ?
						<div className={`col-12 my-4 ai-suggestions-slider ${result?.length ? "slide-in-right" : ""}`}>
							<Typography semanticTag="div"
								className="my-2"
								style={{fontSize: "18px", lineHeight: "24px"}}
								variant="bodySmBold">{t("bikes-added-to-wishlist")}</Typography>
							<SearchResultItems isAiResponse
								list={result || []}
								isAccessory />
						</div> : loading && step === 3 ? <div className="col-12 flex-column gap-2 d-flex align-items-center justify-content-start">{loadingElementsOnDesktop.map((elem, index) => <AiSuggestionLoadingSkeleton key={index}
							className={index > 1 ? "d-none d-md-block" : ""} />)}</div> : null}
				</div>}
		</div>}

		<MobileNavBarAi
			resetState={resetState}
			isOpen={isOpen}
			setSuggestedPrompts={setSuggestedPrompts}
			suggestedPrompts={suggestedPrompts}
			closeModal={() => {
				resetState()
				closeModal()
			}}
			setStep={setStep}
			step={step}
			activePrompt={activePrompt}
			setActivePrompt={setActivePrompt}
			handleSubmit={handleSubmit}
			input={input}
			error={error}
			loading={loading}
			result={result}
			setResult={setResult}
			isGenerating={isGenerating}
			generateUserPrompts={generateUserPrompts}
			setInput={setInput}
		/>
	</>
	)
}

export default MyBikePlanAiChat

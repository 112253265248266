import {useRouter} from "next/router"
import React, {useContext} from "react"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import BookmarkBtn from "../reusable/components/BookmarkBtn"
import MobileShare from "../reusable/components/MobileShare"

type Props = {
  bike?: any
  name?: string;
  brand?: string;
  stockInfo?: string;
  isSecondHand?: boolean;
  is2025?: boolean;
  isBikeOfTheMonth?: boolean;
  showBikeInfo?: boolean
  withText?: boolean
  onClickFallback?: ()=>void
  setIsGoBackLoading?: (loading: boolean) => void;
};

const GoBack: React.FC<Props> = ({
	bike,
	name,
	brand,
	stockInfo,
	isSecondHand = false,
	is2025,
	isBikeOfTheMonth,
	showBikeInfo = true,
	withText = false,
	onClickFallback = () => null,
	setIsGoBackLoading = () => {}
}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	const router = useRouter()
	const onClickBack = () => {
		onClickFallback()

		reactGA.event({
			category: gaCategories.pdp,
			action: gaEvents.goBackOnPdpClicked,
			label: gaEvents.goBackOnPdpClicked,
			nonInteraction: true
		})

		setIsGoBackLoading(true)
		setTimeout(() => {
			router.back()
			setIsGoBackLoading(false)
		}, 1000)
	}

	return (
		<div className="row cursor-pointer mb-md-1">
			<div onClick={onClickBack}
				className="go-back col-auto rounded-3 position-relative d-flex align-items-start p-2">
				<div className="d-flex justify-content-center flex-column">
					<div className="d-none d-md-block">
						<Image height={20}
							width={20}
							style={{minWidth: "20px"}}
							src="/assets/icons/arrow-left.svg"
							alt="arrowleft"/>
					</div>
					<div className="d-flex d-md-none justify-content-center "
						style={{height: 32, width: 32}}>
						<Image layout="fixed"
							height={20}
							width={20}
							style={{minWidth: "20px"}}
							src="/assets/icons/arrow-left.svg"
							alt="arrowleft"/>
					</div>
				</div>
				{withText &&
					<Typography className="ms-2"
						variant={"bodySmBold"}>{t("Back")}</Typography>
				}
			</div>
			<div className="col-10">
				<div className="row">
					<div className="col-12 p-2 d-none d-md-flex align-items-center justify-content-end gap-2 pe-5">
						<BookmarkBtn bike={bike} />
						<MobileShare title={name}/>
					</div>

					{showBikeInfo && <>
						<Typography variant="bodyLg"
							semanticTag="div"
							className={"col-auto text-secondary text-uppercase"}>
							{brand}
						</Typography>
						<Typography variant="bodyLg"
							semanticTag="div"
							className={"col-auto text-uppercase text-end text-success text-bold "}>
							{stockInfo}
						</Typography>
					</>}
					{isSecondHand && (
						<Typography variant="bodyLg"
							semanticTag="div"
							className={"col-auto text-uppercase text-end text-bold"}>
							{t("second-hand")}
						</Typography>
					)}
				</div>
				{showBikeInfo && <Typography variant="heading3"
					semanticTag="h3"
					className="col fs-3">{t(name)}</Typography>}
				{isBikeOfTheMonth && <Typography variant="bodySmBold"
					semanticTag="span"
					style={{backgroundColor: "#000"}}
					className="badge text-white text-uppercase py-1 px-3 mt-1 me-2">
					{t("bike-of-the-month")}
				</Typography>}
			</div>
		</div>
	)
}

GoBack.propTypes = {
	name: PropTypes.string,
	brand: PropTypes.string,
	stockInfo: PropTypes.string
}
export default GoBack

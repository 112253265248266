import React, {Dispatch, SetStateAction, useEffect} from "react"
import Typography from "./Typography/Typography"

enum states {
    "success",
    "danger",
    "info"
}
type props = {
    state?: keyof typeof states
    message: string
    showToast: boolean
	setShowToast: Dispatch<SetStateAction<boolean>>
	duration?: number
}
function ToastMessage({message, state = "info", showToast = null, setShowToast, duration = 3000}: props) {
	useEffect(() => {
		if (showToast) {
			setTimeout(() => setShowToast(false), duration)
		}
	}, [showToast])
	if (message) {
		return (
			<div className={`toast-message-container ${showToast === true ? "toastShow" : showToast === false ? "toastHide" : "d-none"} `}
				style={{fontWeight: "400", border: state === "success" ? "1px solid #2CA58D" : state === "danger" ? "1px solid #FE4A49" : "1px solid #DDDDE3"}}>
				<img style={{height: "20px", width: "20px"}}
					src={state === "success" ? "/assets/icons/toast/success.svg" : state === "danger" ? "/assets/icons/toast/danger.svg" : "/assets/icons/toast/info.svg"}
					alt="toast state" />
				<Typography variant="bodySm"
					style={{fontSize: "16px", lineHeight: "24px"}}>{message}</Typography>
			</div>
		)
	}

	return null
}

export default ToastMessage

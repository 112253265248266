import {isEmpty} from "../../../utility/Helper"
import {defaultValue, delimiter} from "../Config"

export const getParamValuesArray = (params, param) => {
	return isEmpty(params) ? [] : (params[param] as string)?.split(delimiter) || []
}

export const getBikesPriceRange = bikes => bikes?.reduce((acc, {uvpPrice}) => {
	const _price = parseInt(uvpPrice, 10)
	if (acc.min === null) {
		return {min: _price, max: _price}
	}

	if (_price) {
		if (uvpPrice < acc.min) {
			acc.min = Number(_price)
		}

		if (uvpPrice > acc.max) {
			acc.max = Number(_price)
		}
	}

	return acc
}, {min: null, max: null})

export const getActiveFilters = params => {
	return Object.keys(params)?.reduce((acc, filterName) => {
		if (params?.[filterName] !== defaultValue) {
			const values = params?.[filterName]?.split(delimiter)
			const valuesArr = values?.map(value => ({name: filterName, id: filterName + value, value}))
			acc.push(...valuesArr)
		}

		return acc
	}, [])
}

export const getSortedItemsByBikeSlug = (items, bikeSlug) => {
	return [...items].sort((a, b) => {
		if (bikeSlug === a.slug) {
			return -1
		}

		if (bikeSlug === b.slug) {
			return 1
		}

		return 0
	})
}

export const getFullSizeLabel = attribute => {
	return attribute.includes("XL") ? "Extra Large" : attribute?.includes("L") ? "Large" : attribute?.includes("M") ? "Medium" : attribute?.includes("S") ? "Small" : attribute
}

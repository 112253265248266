import React, {Dispatch, SetStateAction} from "react"
import {BikeType} from "../../../utility/Types"
import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import {CheckoutBtn} from "./CheckoutBtn"
import {useTranslation} from "next-i18next"
import useDiscount from "../../../hooks/useDiscount"

type Props = {
  bike: BikeType
  planLength: any
  handleClick: any
  addingToCart:any
  selectedSize:boolean
  startShake:any
  isOpen: any
  isSecondHand: boolean;
  setIsOpen:Dispatch<SetStateAction<boolean>>
  setIsFinancingPopupOpen?:any
}
function StickyMobileBikeDetails({
	bike,
	isOpen,
	setIsOpen,
	isSecondHand,
	selectedSize,
	startShake,
	addingToCart,
	handleClick,
	planLength,
	setIsFinancingPopupOpen
}: Props) {
	const {finalPriceWithDownPayment} = useDiscount(bike)
	const {t} = useTranslation()
	return (
		<div className={"d-md-none position-fixed pb-2 container bg-white col-12  top-shadow "}
			style={{bottom: "8.8%", left: 0, right: 0, zIndex: 10000000, borderBottom: "1px solid #DEE2E6"}}>
			<div className="col-12 d-flex align-items-center py-2 justify-content-between">
				<div className="d-flex align-items-start justify-content-start gap-2">
					<Image src={bike.images?.[0]?.src}
						width={48}
						height={48}
						alt={bike.name} />
					<div style={{rowGap: "8px"}}
						className="d-flex align-items-start justify-content-between flex-column ">
						<div className="d-flex align-items-end justify-content-center">
							<Typography style={{fontSize: "12px", lineHeight: "16px", marginBottom: "1px"}}
								variant="bodyXSmBold"
								semanticTag="span">
													CHF
							</Typography>
							<Typography variant="heading1Black"
								semanticTag="h1"
								className={"mx-1 mb-0 d-inline-block"}
								style={{fontSize: 20}}>
								{Math.ceil(planLength >= 12 ? (finalPriceWithDownPayment / planLength) : finalPriceWithDownPayment / planLength)}
							</Typography>
							{planLength !== 1 && <Typography style={{fontSize: "12px", lineHeight: "16px"}}
								variant="bodyXSmBold"
								semanticTag="span">
								{"/m"} x {planLength}
							</Typography>}
						</div>
						<Typography onClick={setIsFinancingPopupOpen}
							variant="bodyXSmBold"
							semanticTag="h1"
							className={"cursor-pointer mb-0 d-inline-block"}
							style={{fontSize: 12, weight: 700, color: "#956306"}}>
							{t("edit-plan")}
						</Typography>
					</div>
				</div>
				<div className={"ms-5"}>
					<CheckoutBtn
						hideLabels
						planLength={planLength}
						product={bike}
						handleSizeClick={startShake}
						disabled={!selectedSize}
						isLoading={addingToCart}
						handleClick={handleClick}
						stockStatus={bike.stock_status} />
				</div>
			</div>
		</div>
	)
}

export default StickyMobileBikeDetails

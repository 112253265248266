import React, {useContext, useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types"
import CheckoutContext, {CheckoutData, Deal} from "../context/CheckoutContext"
import {cartItemToDeal} from "../transform/CartItemToDeal"
import CartContext from "../context/CartContext"
import {fetchToken, removeUndefined} from "../utility/Helper"
import FirebaseContext from "../context/FirebaseContext"
import {Collections} from "../firebaseAdmin/collections"
import {generateUUID} from "../utility/UUID"
import {formatContact, formatDeals} from "../utility/CheckoutHelpers"
import {gaCategories, gaEvents} from "../config/googleAnalytics/events"
import AnalyticsContext from "../context/AnalyticsContext"
import {storeFronts} from "../utility/storeFronts"
import {customPlanLengthMap} from "../components/bikeDetails/plan/customPlanLengthMap"

const localStorageItemName = "checkout"

const checkoutDataDefault: CheckoutData = {
	contact: null,
	deals: [],
	planLength: 36,
	discount: null,
	discountCode: "",
	checkoutId: "",
	type: "string",
	resultingPrice: null
}

type Props = {
    children: React.ReactNode
}

const storeData = (data: CheckoutData) => {
	if (typeof window !== undefined) {
		localStorage.setItem(localStorageItemName, JSON.stringify(data))
	}
}

export const getData = () => {
	if (typeof window !== undefined) {
		const data = localStorage.getItem(localStorageItemName)
		if (data !== null) {
			const parsedData = JSON.parse(data)
			const _data = {...parsedData} as CheckoutData
			if (!_data.planLength) {
				_data.planLength = 36
			}

			return _data
		}

		return checkoutDataDefault
	}

	return checkoutDataDefault
}

const CheckoutContextProvider: React.FC<Props> = ({children}) => {
	const [data, setData] = useState<CheckoutData | null>(null)
	const {items} = useContext(CartContext)
	const {db} = useContext(FirebaseContext)
	const {reactGA} = useContext(AnalyticsContext)

	const totalDownPayment = useMemo(() => {
		return data?.deals?.reduce((acc, deal) => acc + deal.down_payment_amount, 0)
	}, [data])

	const checkoutPostHandler = async (contactData: any, dealsData: any, uid: string, insurance: boolean, planLength: number, cartItems:any, payOneTime:boolean = false, sponsorMeToken: any = null, organizationSlug: string | null = null) => {
		try {
			// Prepare checkout data
			const {deals, discountCode} = dealsData
			const checkoutId = generateUUID()
			const updateCheckoutDocToken = await fetchToken()
			const contact = await formatContact(contactData)

			const dealsFormatted = formatDeals(deals, contact, uid, checkoutId, discountCode)
			const {addDoc, Timestamp, collection} = await import("@firebase/firestore/lite")

			const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
			const correctPlanLength = planLength ? planLengthMap ? planLengthMap[planLength] : planLength : 48
			const organization_token = organizationSlug || null

			// Format checkout data
			const docContent = removeUndefined({
				contact: {
					...removeUndefined(contact),
					uid: uid || null,
					checkout_id: checkoutId || null,
					organization_token: organization_token || null
				},
				deals: dealsFormatted.map(deal => {
					// Calculate delivery date
					let expectedDeliveryDate = null
					if (deal.deliveryTimeInDays) {
						expectedDeliveryDate = new Date()
						expectedDeliveryDate.setUTCHours(0, 0, 0, 0)
						expectedDeliveryDate.setDate(expectedDeliveryDate.getDate() + deal.deliveryTimeInDays)
					}

					delete deal.deliveryTimeInDays

					return {...removeUndefined(deal),
						store_front: storeFronts[process.env.NEXT_PUBLIC_STOREFRONT],
						store_front_instance: organization_token ? `${organization_token}-costum-instance` : process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE,
						organization_token,
						expected_delivery_date_to_: expectedDeliveryDate?.valueOf() || null,
						sponsor_me: sponsorMeToken ? "true" : "false",
						sponsor_me_token: sponsorMeToken || null
					}
				}),
				cartItems: cartItems.map(item => {
					const {id, name, slug, attributes, images, finalPriceWithoutDownPayment, categories, sku} = item.product
					return {
						id,
						name,
						slug,
						sku,
						price: finalPriceWithoutDownPayment,
						brand: attributes?.find(e => e.slug === "brand")?.terms[0] || null,
						frame: attributes?.find(e => e.slug === "frame-variant")?.terms[0] || null,
						image: images[0]?.src || null,
						selectedSize: item.selectedSize || null,
						categories
					}
				}),
				storefrontIntance: process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE,
				storefront: storeFronts[process.env.NEXT_PUBLIC_STOREFRONT],
				createdAt: Timestamp.now(),
				processed: false,
				insuranceUpdated: false,
				idUploadUpdated: false,
				updateCheckoutDocToken,
				checkoutContextData: {
					discount: data.discount || null,
					insurance: data.insurance || null,
					notApplicable: data.notApplicable || null,
					resultingPrice: data.resultingPrice || null,
					insurancePrice: data.insurancePrice || null
				},
				payOneTime,
				checkoutId,
				uid: uid || null,
				insurance,
				planLength: correctPlanLength,
				totalDownPayment,
				sponsorMeToken
			})

			// Try to store checkout in Firestore
			try {
				const doc = await addDoc(collection(db, Collections.checkOuts), docContent)

				// Send GA4 test event
				reactGA?.event({
					category: "Test",
					action: "Test Event Frontend",
					label: "Test Event Frontend",
					nonInteraction: true
				})

				return {
					status: 201,
					docId: doc.id,
					updateDocToken: updateCheckoutDocToken
				}
			} catch (e) {
				// Log event
				reactGA?.event({
					category: gaCategories.checkoutDocument,
					action: gaEvents.failedToCreateCheckoutDocument,
					label: gaEvents.failedToCreateCheckoutDocument,
					nonInteraction: true
				})
				console.error("Failed to add document with following content:", docContent)
				console.error(e)
			}
		} catch (e) {
			// Log event
			reactGA?.event({
				category: gaCategories.checkout,
				action: gaEvents.checkoutFailedA,
				label: gaEvents.checkoutFailedA,
				nonInteraction: true
			})
			console.error(e)
		}
	}

	useEffect(() => {
		const _data = getData()
		setData(_data)
	}, [])

	useEffect(() => {
		if (data) {
			storeData(data)
		}
	}, [data])

	useEffect(() => {
		updateCheckoutDeals()
	}, [items])

	const updateCheckoutDeals = () => {
		const deals: Deal[] = []
		const checkoutId = `${generateUUID()}`

		// Sum up down payment of all cart items
		// const totalDownPayment = items?.reduce((total, item) => total + (item.downPayment || 0), 0)

		items?.forEach(item => {
			deals.push(cartItemToDeal({
				...item,
				uniqueId: item.uniqueId || null,
				isOnSale: item.isOnSale || false,
				initialPrice: item.initialPrice
			}, data?.planLength || 36, data?.discountCode || ""))
		})

		setData(prevData => {
			return {
				...prevData,
				checkoutId,
				deals
			}
		})
	}

	return (
		<CheckoutContext.Provider
			value={{data, setData, storeData, updateCheckoutDeals, totalDownPayment, checkoutPostHandler}}>
			{children}
		</CheckoutContext.Provider>
	)
}

CheckoutContextProvider.propTypes = {
	children: PropTypes.node
}

export default CheckoutContextProvider

import React, {Dispatch, SetStateAction} from "react"
import Typography from "./Typography/Typography"
import {ArrowLeft} from "react-bootstrap-icons"
import {useTranslation} from "next-i18next"

type Props = {
    setIsGenerating:Dispatch<SetStateAction<boolean>>
    setLoading:Dispatch<SetStateAction<boolean>>
    closeModal?:()=>void
	result: any
	setSuggestedPrompts:Dispatch<SetStateAction<any>>
	setResult:Dispatch<SetStateAction<any>>
	suggestedPrompts: any
	setStep: Dispatch<SetStateAction<1 | 2 | 3>>
	loading: boolean
	error: boolean
	step:number
}

function AiDrawerHeader({error, closeModal, suggestedPrompts, result, setResult, setLoading, setStep, setIsGenerating, setSuggestedPrompts, step}: Props) {
	const {t} = useTranslation()
	return (<>
		{error ? <div className="d-flex align-items-center justify-content-center gap-2"><ArrowLeft className="cursor-pointer d-md-none"
			size={24}
			onClick={() => {
				closeModal()
			}} /><Typography style={{fontSize: "56px", lineHeight: "64px"}}
			variant="bodySmBlack">😓 Oops!</Typography></div>						:
			suggestedPrompts?.length && result?.length && step === 3 ? <div className="d-flex flex-column align-items-start justify-content-center gap-2"><ArrowLeft className="cursor-pointer"
				size={24}
				onClick={() => {
					setResult([])
					setLoading(false)
					setStep(2)
				}} /><Typography style={{fontSize: "56px", lineHeight: "64px"}}
				variant="bodySmBlack">{t("select-a-bike")}</Typography></div> :
				suggestedPrompts?.length && step === 2 ? <div className="d-flex flex-column align-items-start justify-content-center gap-2"><ArrowLeft className="cursor-pointer"
					size={24}
					onClick={() => {
						setStep(1)
						setIsGenerating(false)
						setSuggestedPrompts([])
					}} /><Typography style={{fontSize: "56px", lineHeight: "64px"}}
					variant="bodySmBlack">{t("choose-your-lifestyle") }</Typography></div> : <Typography style={{fontSize: "56px", lineHeight: "64px"}}
					variant="bodySmBlack">{t("ask-mybikeplan-ai") }</Typography>}
	</>
	)
}

export default AiDrawerHeader

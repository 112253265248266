/* eslint-disable radix */
import React, {useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import {StockStatus} from "../../bikeDetails/BikeDetails"
import {findBikeMetaFieldByName, isCategory} from "../../../utility/Helper"
import PropTypes from "prop-types"
import CollectionItemGridView from "./CollectionItemGridView"
import CollectionItemListView from "./CollectionItemListView"
import DataContext from "../../../context/DataContext"
import {attributesToHideType} from "../../../utility/Types"
import useBadges from "../../../hooks/useBadges"

type Props = {
	bike: any,
	occasion?: boolean
	listView?: boolean
	isRowForMobile?: boolean
	cardWidth?: string
	isTwoCardsView?: boolean
	withArrows?:boolean
    withDots?: boolean
	withOneImage?:boolean
	isAccessory?: boolean
	isLast?: boolean
	isSearchResult?: boolean
	isCarouselElement?: boolean
	isWishList?: boolean
	isListviewItemSmall?: boolean
	forSearchContent?: boolean
	hideComparison?: boolean
	showIsCurrentBikeLabel?: boolean
	showComparisonAttributes?: boolean
	attributesToHide?: attributesToHideType
	isGridTopLeftRounded?: boolean
	isGridTopRightRounded?: boolean
	isGridBottomRightRounded?: boolean
	isGridBottomLeftRounded?: boolean
	isIkeaDesign?: boolean
	articleClassName?: string
	isMiniPLP?: boolean
	isStory?:boolean
    borderRadius?: string
    hasBorder?: boolean
	priceFontSize?: number
	isReferredByAi?:boolean
}

const BikesCollectionItem: React.FC<Props> = ({
	bike,
	occasion,
	listView,
	isRowForMobile,
	cardWidth,
	isTwoCardsView = false,
	withArrows,
	withDots,
	attributesToHide,
	withOneImage,
	showComparisonAttributes,
	isSearchResult,
	isAccessory,
	isCarouselElement = false,
	isLast,
	hideComparison,
	isReferredByAi,
	isWishList = false,
	isListviewItemSmall,
	showIsCurrentBikeLabel,
	forSearchContent,
	isGridTopRightRounded = false,
	isGridTopLeftRounded = false,
	isGridBottomRightRounded = false,
	isGridBottomLeftRounded = false,
	isIkeaDesign = false,
	isMiniPLP = false,
	borderRadius,
	hasBorder = true,
	isStory = false,
	priceFontSize
}) => {
	const {t} = useTranslation("common")
	const [selected, setSelected] = useState<boolean>(false)
	const [currentSlugWithCarousel, setCurrentSlugWithCarousel] = useState<string | null>(null)
	const [touchTimeout, setTouchTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
	const [isTouching, setIsTouching] = useState(false)
	const mainCategory = bike?.main_category?.name
	const soldOut = bike?.stock_status === StockStatus.outOfStock
	const isInStock = bike?.stock_status === StockStatus.inStock
	const productUrl = isReferredByAi ? `/${t("produkt")}/${bike.slug}?ai-suggested=${isReferredByAi}` : `/${t("produkt")}/${bike?.slug}`
	const {customBikeRecommendations} = useContext(DataContext)
	const isRecommended = customBikeRecommendations.some(recommendation => recommendation?.slug === bike?.slug)
	const kilometers = findBikeMetaFieldByName(bike, "kms")
	const isSwissMade = isCategory(bike, "swiss-made")
	const {hasTestDrive, is2025, isBikeOfTheMonth, isFeatured, isBrandWorldRecommended, isNew, isNonEBike, isRentable, saleOfTheWeek} = useBadges(bike)

	const handleMouseEnter = (bikeSlug: string) => {
		if (!soldOut) {
			setSelected(true)
		}

		setCurrentSlugWithCarousel(bikeSlug)
	}

	const handleMouseLeave = () => {
		if (!soldOut) {
			setSelected(false)
		}

		setCurrentSlugWithCarousel(null)
	}

	const handleTouchStart = (bikeSlug: string) => {
		setIsTouching(true)
		setCurrentSlugWithCarousel(bikeSlug)

		if (touchTimeout) {
			clearTimeout(touchTimeout)
		}

		const timeoutId = setTimeout(() => {
			setIsTouching(false)
			setCurrentSlugWithCarousel(null)
		}, 4000)

		setTouchTimeout(timeoutId)
	}

	const handleTouchCancel = () => {
		if (touchTimeout) {
			clearTimeout(touchTimeout)
		}

		setIsTouching(false)
		setCurrentSlugWithCarousel(null)
	}

	return (
		listView ?
			<CollectionItemListView
				bike={bike}
				isSearchResult={isSearchResult}
				badgeProps={{
					isInStock,
					isBikeOfTheMonth,
					hasTestDrive,
					isRentable,
					is2025,
					isFeatured,
					isRecommended,
					isNew,
					occasion,
					kilometers,
					isSwissMade,
					isNonEBike,
					isBrandWorldRecommended
				}}
				mainCategory={mainCategory}
				handleMouseEnter={handleMouseEnter}
				handleMouseLeave={handleMouseLeave}
				productUrl={productUrl}
				selected={selected}
				soldOut={soldOut}
				isGridTopLeftRounded={isGridTopLeftRounded}
				isGridTopRightRounded={isGridTopRightRounded}
				isGridBottomLeftRounded={isGridBottomLeftRounded}
				isGridBottomRightRounded={isGridBottomRightRounded}
				isLast={isLast}
				isListviewItemSmall={isListviewItemSmall}
			/> :
			<CollectionItemGridView
				isCarouselElement={isCarouselElement}
				isRowForMobile={isRowForMobile}
				bike={bike}
				showIsCurrentBikeLabel={showIsCurrentBikeLabel}
				badgeProps={{
					isInStock,
					isBikeOfTheMonth,
					hasTestDrive,
					isRentable,
					is2025,
					isFeatured,
					isBrandWorldRecommended,
					isRecommended,
					isNew,
					occasion,
					kilometers,
					isSwissMade,
					isNonEBike,
					saleOfTheWeek}}
				mainCategory={mainCategory}
				handleMouseEnter={handleMouseEnter}
				handleMouseLeave={handleMouseLeave}
				handleTouchStart={handleTouchStart}
				isTouching={isTouching}
				productUrl={productUrl}
				selected={selected}
				isStory={isStory}
				isReferredByAi={isReferredByAi}
				soldOut={soldOut}
				isMiniPLP={isMiniPLP}
				isGridTopLeftRounded={isGridTopLeftRounded}
				isGridTopRightRounded={isGridTopRightRounded}
				isGridBottomLeftRounded={isGridBottomLeftRounded}
				isGridBottomRightRounded={isGridBottomRightRounded}
				isIkeaDesign={isIkeaDesign}
				cardWidth={cardWidth}
				isTwoCardsView={isTwoCardsView}
				withArrows={withArrows}
				withDots={withDots}
				withOneImage={withOneImage}
				isAccessory={isAccessory}
				hideComparison={hideComparison}
				isLast={isLast}
				attributesToHide={attributesToHide}
				showComparisonAttributes={showComparisonAttributes}
				isWishList={isWishList}
				forSearchContent={forSearchContent}
				borderRadius={borderRadius}
				hasBorder={hasBorder}
				priceFontSize={priceFontSize}
				currentSlugWithCarousel={currentSlugWithCarousel}
				handleTouchCancel={handleTouchCancel}

			/>
	)
}

BikesCollectionItem.propTypes = {
	bike: PropTypes.any.isRequired,
	occasion: PropTypes.bool,
	listView: PropTypes.bool
}

export default React.memo(BikesCollectionItem)

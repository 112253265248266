import {collectionstEndpoints} from "./collections"
import axios from "axios"
import {readJsonFromS3, storeJsonToS3} from "../aws/helpers"

const strapiInstances = {

	mybikeplan: "MBP"

}

export const fetchStrapiData = async (collections:string[], locale) => {
	// Strapi fetching data test
	const collectionsEndpoints = collections.map(collection => collectionstEndpoints[collection])
	const url = `${process.env.STRAPI_URL}instances/?filters[name][$eq]=${strapiInstances[process.env.STOREFRONT]}&populate=${collectionsEndpoints.join(",")}&locale=${locale}`

	const requestOptions = {
		url,
		method: "get",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${process.env.STRAPI_API_TOKEN}`
		}
	}

	try {
		const response = await axios.request(requestOptions)
		const {data} = response

		return data
	} catch (e) {
		console.error(e)
		return []
	}
}

export const fetchStrapiDataCollectionFromStrapi = async (locale:string, collectionsList:string[], organization:string|null = null) => {
	// Try directly from Strapi

	const s3BuildStrapiDataKey = `strapi_${process.env.NEXT_PUBLIC_VERCEL_URL}_${process.env.VERCEL_GIT_COMMIT_SHA}_${locale}.json`

	try {
		const content = await readJsonFromS3(s3BuildStrapiDataKey).catch(error => null)

		if (!content) {
			const content = await fetchStrapiData(collectionsList as string[], locale)
			await storeJsonToS3(s3BuildStrapiDataKey, content)

			// Filter data
			const organizationKey = organization ? organization : "none"

			const filteredContent = content?.data?.filter(item => item?.attributes?.organization === organizationKey)

			// If no content is not found
			if (filteredContent?.length === 0) {
				return null
			}

			// Processing data to only return the required collections
			const processedContent = Object.keys(filteredContent?.[0]?.attributes)?.reduce((acc, key) => {
				if (collectionsList.includes(key)) {
					acc[key] = filteredContent[0]?.attributes?.[key]?.data
				}

				return acc
			}, {})

			return {data: processedContent}
		}

		// Filter data
		const organizationKey = organization ? organization : "none"

		const filteredContent = content?.data?.filter(item => item?.attributes?.organization === organizationKey)

		// If no content is not found
		if (filteredContent?.length === 0) {
			return null
		}

		// Processing data to only return the required collections
		const processedContent = Object.keys(filteredContent?.[0]?.attributes)?.reduce((acc, key) => {
			if (collectionsList.includes(key)) {
				acc[key] = filteredContent[0]?.attributes?.[key]?.data
			}

			return acc
		}, {})

		return {data: processedContent}
	} catch (e) {
		console.error("Error fetching Strapi data from Strapi", e)
		return null
	}
}

export const stripProcessedContent = (content:any, collectionsList:string[], organization:string) => {
	const organizationKey = organization ? organization : "none"

	const filteredContent = content.filter(item => item.attributes.organization === organizationKey)

	// If no content is not found
	if (filteredContent.length === 0) {
		console.error("No content found in Strapi")
		return null
	}

	// Processing data to only return the required collections
	const processedContent = Object.keys(filteredContent[0].attributes)?.reduce((acc, key) => {
		if (collectionsList.includes(key)) {
			acc[key] = filteredContent[0].attributes[key].data
		}

		return acc
	}, {})

	return {data: processedContent}
}

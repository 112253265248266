import {useTranslation} from "next-i18next"
import Link from "next/link"
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import {BikeType} from "../../utility/Types"
import Typography from "../reusable/Typography/Typography"
import {CheckoutBtn} from "./components"
import PriceDisplay from "./components/PriceDisplay"
import useDiscount from "../../hooks/useDiscount"
import {isOccasionBike} from "../../utility/Helper"
import useHandleBookmark from "../../hooks/useHandleBookmark"
import {customPlanLengthMap} from "./plan/customPlanLengthMap"
import CheckoutContext from "../../context/CheckoutContext"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import useBadges from "../../hooks/useBadges"
import LayoutContext from "../../context/LayoutContext"

type Props = { bike: BikeType, activeLink:"photos" |
	"Übersicht" |
	"Q&A" |
	"accessories" |
	"test-drive" |
    "Ähnliche E-Bikes"|"find-perfect-bike-title",
	setActiveLink: Dispatch<SetStateAction<
	"photos" |
	"Übersicht" |
	"Q&A" |
	"accessories" |
	"test-drive" |
	"Ähnliche E-Bikes" |
	"find-perfect-bike-title">>
	startShake: () => void
	isScrollingDown?:boolean
    handleCheckoutBtnClick: any
    addingToCart: boolean
    selectedSize: any
    isCartCompatibility:boolean
}

function StickyPdpNavigation({bike, startShake, isScrollingDown, handleCheckoutBtnClick, addingToCart,
	selectedSize, isCartCompatibility, activeLink, setActiveLink}: Props) {
	const navigation = [{label: "photos", href: "#photos"},
		{label: "Übersicht", href: "#overview"},
		{label: "Q&A", href: "#questionAndAnswers"},
		{label: "Ähnliche E-Bikes", href: "#similar-products"}]

	let navigationMobile = [{label: "photos", href: "#photos"},
		{label: "Übersicht", href: "#overview-mobile"},
		{label: "Q&A", href: "#questionAndAnswers-mobile"},
		{label: "Ähnliche E-Bikes", href: "#similar-products"}]
	const {hasTestDrive} = useBadges(bike)

	if (hasTestDrive) {
		navigationMobile = [{label: "photos", href: "#photos"},
			{label: "test-drive", href: "#test-drive"},
			{label: "Übersicht", href: "#overview-mobile"},
			{label: "Q&A", href: "#questionAndAnswers-mobile"},
			{label: "Ähnliche E-Bikes", href: "#similar-products"}]
	}

	const isSecondHand = isOccasionBike(bike)
	const {handleAddBookmark, handleDeleteBookmark, isBookmarked} = useHandleBookmark(bike)
	const {data: checkoutData} = useContext(CheckoutContext)
	const {reactGA} = useContext(AnalyticsContext)
	const [showCheckoutBtn, setShowCheckoutBtn] = useState(null)
	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = checkoutData?.planLength ?
		planLengthMap ?
			planLengthMap[checkoutData?.planLength] : checkoutData?.planLength : 48
	const {t} = useTranslation()
	useEffect(() => {
		if (typeof window !== "undefined") {
			const handleScroll = () => {
				if (window.scrollY > 1400) {
					setShowCheckoutBtn(true)
				} else {
					setShowCheckoutBtn(false)
				}
			}

			window.addEventListener("scroll", handleScroll)

			return () => {
				window.removeEventListener("scroll", handleScroll)
			}
		}
	}, [])
	const {finalPriceWithoutDownPayment
		, finalPriceWithDownPayment} = useDiscount(bike)
	const {isMobile} = useContext(LayoutContext)
	return (
		<div
			className={` ${isMobile ? "sticky-top" : ""} plp-sticky-bar-shadow bg-white w-100 
			`}>
			<div style={{overflow: "hidden"}}
				className="d-none d-md-flex w-100 container align-items-center justify-content-between">
				<ul style={{gap: "24px"}}
					className="d-flex list-unstyled align-items-center justify-content-start m-0">
					{navigation.map(({label, href}, index) =>
						<Link key={index}
							href={href}
							replace>
							<li onClick={() => {
								reactGA?.event({
									category: gaCategories.pdp,
									action: gaEvents.pdpTabClicked,
									label: `${gaEvents.pdpTabClicked} ${label}`,
									nonInteraction: false
								})

								/* @ts-expect-error string not assignable */
								setActiveLink(() => label)
							}}
							className={`pdp-link ${activeLink === label ? "active" : ""}`}>
								{t(label)}
							</li>
						</Link>)}
				</ul>
				<div className={`d-flex p-1 align-items-center justify-content-start gap-3 
                    ${showCheckoutBtn ? "slide-in-right" : showCheckoutBtn === null ? "d-none" : "slide-out-right"}`}>
					<PriceDisplay
						finalPriceWithDownPayment={finalPriceWithDownPayment}
						finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
						planLength={planLength}
						isSecondHand={isSecondHand} />
					{!isCartCompatibility && <Typography variant="bodyLg"
						semanticTag="p">
						{t("First and secondhand ebikes can’t be in the cart together.")}
					</Typography>}

					<div className=" d-flex justify-content-start align-items-center gap-2">
						<div style={{flex: 1}}>
							<CheckoutBtn
								planLength={planLength}
								product={bike}
								isSecondHand={isSecondHand}
								secondHandPrice={bike.displayPrice}
								handleSizeClick={startShake}
								disabled={!selectedSize}
								isLoading={addingToCart}
								handleClick={handleCheckoutBtnClick}
								stockStatus={bike.stock_status} />
						</div>
						{isBookmarked ? <span title="Delete this bike from wishlist"
							onClick={e => handleDeleteBookmark(e)}
							style={{
								backgroundColor: "#F8F8F8",
								borderRadius: "4px", height: "82%", padding: "8px 12px"
							}}
							className="cursor-pointer d-flex
                             pdp-add-wishlist-button justify-content-center align-items-center px-3 ">
							<img style={{height: "40px", width: "40px"}}
								src={"/assets/icons/Heart-gold.svg"} />
						</span> : <span title="Add this bike to your wishList"
							onClick={e => handleAddBookmark(e)}
							style={{
								backgroundColor: "#FFE9C2",
								borderRadius: "4px", height: "82%", padding: "8px 12px"
							}}
							className="cursor-pointer d-flex
                             justify-content-center
                              pdp-add-wishlist-button
                               align-items-center px-3 "><img style={{height: "40px", width: "40px"}}
								src={"/assets/icons/Heart.svg"} /> </span>}
					</div>
				</div>
			</div>

			<div style={{minHeight: "40px", maxWidth: "100%", overflowX: "auto"}}
				className="d-flex d-md-none w-100 container align-items-center justify-content-between">
				<ul style={{gap: "24px"}}
					className="d-flex list-unstyled align-items-center justify-content-start m-0">
					{navigationMobile.map(({label, href}, index) =>
						<Link key={index}
							href={label === "Übersicht" ? "#overview-mobile" : href}><li style={{whiteSpace: "nowrap"}}
								className={`pdp-link  ${activeLink === label ? "active" : ""}`}
							>{t(label)}</li></Link>)}
				</ul>
			</div>
		</div>
	)
}

export default StickyPdpNavigation

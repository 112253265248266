import React, {Dispatch, SetStateAction} from "react"
import {BikeType, NotificationTypes} from "../utility/Types"

export type NotificationContextState = {
    visitedPdpsCount: number
    setVisitedPdpsCount: Dispatch<SetStateAction<number>>
    incrementPdpViews: (bike:BikeType)=>void
    showNotification: Dispatch<SetStateAction<
       NotificationTypes>>
        displayNotificationAfterUsing:(delay: number,
            activeNotification:NotificationTypes,
            // eslint-disable-next-line
            conditionToKeepCounting: boolean)=>void
}

const NotificationsContext = React.createContext<NotificationContextState>({
	visitedPdpsCount: 0,
	setVisitedPdpsCount: () => null,
	incrementPdpViews: arg => null,
	showNotification: () => null,
	displayNotificationAfterUsing: (arg1, arg2, arg3) => null
})

export default NotificationsContext

import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import PropTypes from "prop-types"
import Typography from "../reusable/Typography/Typography"
import Button from "../reusable/components/Button"
import {filterUrl} from "../filter/FilterUrl"
import {BikeType} from "../../utility/Types"
import RenderBikesGrid from "./components/RenderBikesGrid"
import LayoutContext from "../../context/LayoutContext"

type Props = {
  upsellsList: BikeType[]
}

const SoldOut: React.FC<Props> = ({upsellsList}) => {
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)

	return (
		<div className={"position-absolute top-0 bottom-0 start-0 end-0"}
			style={{
				zIndex: 9998,
				top: "4.5rem",
				backgroundColor: "rgba(0,0,0,0.5)"
			}}>
			<div className="col-12 d-flex align-items-center justify-content-center">
				<div className={`row ${isMobile ? "" : "container"}`}>
					<div className={"col-12"}>
						<div style={{borderRadius: "16px"}}
							className={"bg-white mt-5 p-4"} >
							<div className={"text-center"}>
								<Typography variant="heading2"
									semanticTag="h1">
									{t("Dieses Modell ist leider ausverkauft")}
								</Typography>
								<Typography variant="heading3"
									semanticTag="h2"
									className={"mt-4"}>
									{t("Ähnliche E-Bikes")}{":"}
								</Typography>
								<div className="d-flex col-12 flex-column  mt-3  justify-content-start align-items-start">
									<RenderBikesGrid isMobile={isMobile}
										showOverflow={false}
										bikesList={upsellsList || []} />
								</div>
								<div className={"row justify-content-center mt-4"}>
									<div className={"col-12 col-md-4 col-lg-3"}>
										<div className={"d-grid"}>
											<Button label={t("Mehr anzeigen")}
												href={filterUrl({}, null, null)}
												bgColor="#FEC04D"
												color="#212529"
												textColor="#212529"
												spinnerColor="#212529"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

SoldOut.propTypes = {
	upsellsList: PropTypes.array.isRequired
}

export default SoldOut

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import OrganizationContext from "../context/OrganizationContext"
import {useRouter} from "next/router"
import {getOrganizationDataFromStrapi} from "../utility/strapi/helpers"
import {collectionstEndpoints, strapiCollections} from "../utility/strapi/collections"

type Props = {
    children: React.ReactNode
}

const OrganizationContextProvider: React.FC<Props> = ({children}) => {
	const [organizationSlug, setOrganizationSlug] = useState<string | null>(null)
	const [organizationInitialized, setOrganizationInitialized] = useState<boolean>(false)
	const [organizationData, setOrganizationData] = useState<any>(false)
	const router = useRouter()
	const fetchAndRedirectOrganizations = async () => {
		const config = {
			method: "GET"
		}
		const organizationsResponse = await fetch("/api/strapi/fetchcontent/?locale=de&collections=" + collectionstEndpoints.organizations, config)
		const organizationsData = await organizationsResponse.json()
		const organizations = organizationsData?.data?.organizations

		if (organizations) {
			const organizationData = getOrganizationDataFromStrapi(organizations, organizationSlug)

			if (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug && !organizationData) {
				router.replace(process.env.NEXT_PUBLIC_APP_URL).then()
			} else if (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug && organizationData) {
				setOrganizationData(organizationData)
			}
		}
	}

	useEffect(() => {
		setOrganizationSlug(process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && !["localhost:3000"].includes(window.location.host.split(".")[0]) ? window.location.host.split(".")[0] : null)
		setOrganizationInitialized(true)
	}, [])

	useEffect(() => {
		// Redirect if empty instance / wrong subdomain
		fetchAndRedirectOrganizations()
	}, [organizationSlug])

	return (
		<OrganizationContext.Provider value={{organizationInitialized, organizationSlug, organizationData}}>
			{children}
		</OrganizationContext.Provider>
	)
}

OrganizationContextProvider.propTypes = {
	children: PropTypes.node
}

export default OrganizationContextProvider

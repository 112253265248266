import Image from "next/image"
import React from "react"
import {IImageSlide} from "../types"

interface Props {
	images: IImageSlide[]
	activeSlideIndex: number
	goToSlide: (_: number) => void
}

export const ImageGalary = ({images, activeSlideIndex, goToSlide}: Props) => {
	return (
		<div className="d-flex flex-column justify-content-start flex-wrap gap-1">
			{images.map(({alt, id, src}, i) => {
				return (
					<div
						style={{
							height: "72px",
							minWidth: 72,
							opacity: activeSlideIndex === i ? 1 : 0.6,
							backgroundColor: "#F8F8F8"
						}}
						key={id}
						onClick={() => goToSlide(i)}
						className={`pointer rounded-3 ${
							activeSlideIndex === i ? "shake" : ""
						}`}
					>
						<div style={{height: "100%"}}
							className={id === "whatsapp-call" ? "d-flex justify-content-center align-items-center" : ""}>
							<Image
								src={src}
								alt={alt}
								objectFit="contain"
								layout={id === "whatsapp-call" ? "fixed" : "responsive"}
								width={id === "whatsapp-call" ? 24 : 72}
								height={id === "whatsapp-call" ? 24 : 72}
							/>
						</div>
						{activeSlideIndex === i && (
							<Image
								src={"/assets/icons/doodle-border.svg"}
								alt={alt}
								objectFit="contain"
								layout={"responsive"}
								width={72}
								height={3.85}/>
						)}
					</div>
				)
			})}
		</div>)
}


import React, {useContext, useState} from "react"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../../context/LayoutContext"
import Typography from "../../reusable/Typography/Typography"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"

export const insuranceBonusesList = [
	{id: 1, icon: "/assets/icons/insurance-check-mark.svg", text: "list-text-homepage-1"},
	{id: 3, icon: "/assets/icons/insurance-check-mark.svg", text: "list-text-homepage-3"},
	{id: 4, icon: "/assets/icons/insurance-check-mark.svg", text: "list-text-homepage-4"},
	{id: 5, icon: "/assets/icons/insurance-check-mark.svg", text: "list-text-homepage-5"},
	{id: 2, icon: "/assets/icons/insurance-check-mark.svg", text: "list-text-homepage-2"}
]

type Props = {
  isCheckoutPage?: boolean,
  isVerticalFilters?: boolean
}

export const InsuranceTile: React.FC<Props> = ({isCheckoutPage = false, isVerticalFilters = false}) => {
	const {t} = useTranslation("insurance")
	const {isMobile, isListView} = useContext(LayoutContext)
	const [isShowDetails, setShowDetails] = useState(false)
	const handleShowDetails = () => setShowDetails(prev => !prev)
	return (
		<>
			{
				isVerticalFilters && !isMobile ?
				// Part above vertical filters
					<div className="row justify-content-center align-items-center mb-2">
						<div className="col-12 bg-white p-0 mb-4">
							<div className={"d-flex mt-1 mb-2 mb-xxl-3"}>
								<div className="col-2 mb-xxl-2">
									<Image src={"/assets/icons/calingo-insurance-shield.svg"}
										layout={"intrinsic"}
										objectFit={"contain"}
										width={17}
										height={20}/>
								</div>
								<div className="col-10 ms-0">
									<Typography variant="bodyLgBold"
										semanticTag="span">
										{t("title-1-homepage")}
									</Typography>
									<Typography variant="bodyLgBold"
										style={{color: "#00686E"}}
										semanticTag="span">
										{t(" calingo")}
									</Typography>
								</div>
							</div>
							<div className="col-12 d-flex justify-content-between">
								<div>
									<Typography variant="bodyLg"
										semanticTag="h3">
										{t("Was ist inbegriffen?")}
									</Typography>
								</div>
							</div>
							{insuranceBonusesList.map(({icon, text}, indx) => {
								if (indx < 2) {
									return (
										<div className="d-flex flex-direction-row ps-0 pt-2"
											key={indx++}>
											<div className="col-1">
												<Image src={icon}
													layout={"intrinsic"}
													objectFit={"contain"}
													width={16}
													height={16}/>
											</div>
											<Typography className="col-11 ms-2 pe-2"
												variant="bodyLg"
												semanticTag="span">
												{t(`${text}`)}
											</Typography>
										</div>
									)
								}

								return null
							})}
							<div onClick={handleShowDetails}
								className="col-12 mt-3 d-flex cursor-pointer align-items-center">
								<div className="me-2">
									<Typography variant="bodyLgBold"
										semanticTag="span">
										{t("Was noch?")}
									</Typography>
								</div>
								{isShowDetails ? <Image style={{transform: "rotate(180deg)"}}
									src={"/assets/icons/chevron-down.svg"}
									height={15}
									width={15}/> :
									<Image src={"/assets/icons/chevron-down.svg"}
										height={15}
										width={15}/>}
							</div>
							<AccordionAnimation duration={500}
								inProp={isShowDetails}
								transTimingFn="ease-out">
								<>
									{insuranceBonusesList.map(({icon, text}, indx) => {
										if (indx > 1) {
											return (
												<div className="d-flex flex-direction-row  ps-1 pt-2"
													key={indx++}>
													<div className="col-auto">
														<Image src={icon}
															layout={"intrinsic"}
															objectFit={"contain"}
															width={16}
															height={16}/>
													</div>
													<Typography className="ms-2"
														variant="bodyLg"
														semanticTag="span">
														{t(`${text}`)}
													</Typography>
												</div>
											)
										}

										return null
									})}
								</>
							</AccordionAnimation>
							<Typography className="mb-0 mt-3"
								variant="bodyLg"
								semanticTag="p">
								{t(`${"Du kannst beim Check-out eine Versicherung zu Deinem E-Bike hinzufügen."}`)}
							</Typography>
						</div>
						<hr></hr>
					</div> :
					isMobile ?
					// Use for mobile on plp and checkout page if isMobile
						<div className={`row justify-content-center align-items-center ${isCheckoutPage ? "mt-4 mb-0" : ""}`}>
							<div>
								<div className="col-12  ">
									<div style={{columnGap: "16px"}}
										className={"d-flex align-items-start justify-centent-center"}>
										<Image src={"/assets/icons/calingo-insurance-shield.svg"}
											layout={"intrinsic"}
											objectFit={"contain"}
											width={40}
											height={40}/>
										<div className={"col-10"}>
											<Typography variant="bodyLgBold"
												semanticTag="span">
												{t("title-1-homepage")}
											</Typography>
											<Typography variant="bodyLgBold"
												style={{color: "#00686E"}}
												semanticTag="span">
												{t(" calingo")}
											</Typography>
											<div onClick={handleShowDetails}
												className="col-12 mt-3">
												<Typography className="me-2"
													variant="bodySmBold"
													semanticTag="span">
													{t("list-header-homepage")}
												</Typography>
												{isShowDetails ? <Image src={"/assets/icons/chevron-up-icon.svg"}
													height={6.5}
													width={12}/> :
													<Image src={"/assets/icons/chevron-down.svg"}
														height={6.5}
														width={12}/>}
											</div>
										</div>
									</div>
									<AccordionAnimation duration={500}
										inProp={isShowDetails}
										transTimingFn="ease-out">
										<>
											{insuranceBonusesList.map(({icon, text}, indx) => {
												return (
													<div className="d-flex flex-direction-row ps-1 pt-2"
														key={indx++}>
														<div className="col-auto">
															<img src={icon}
																alt="checkmark"/>
														</div>
														<Typography className="ms-3"
															variant="bodyLg"
															semanticTag="span">
															{t(`${text}`)}
														</Typography>
													</div>
												)
											})}
											<Typography className="mt-3"
												variant="bodyLg"
												semanticTag="p">
												{t(`${"Du kannst beim Check-out eine Versicherung zu Deinem E-Bike hinzufügen."}`)}
											</Typography>
										</>
									</AccordionAnimation>
								</div>
							</div>
						</div> : !isMobile && !isListView && !isCheckoutPage ?
						// Grid view on PLP
							<div style={{minWidth: "250px", maxWidth: 300, maxHeight: "465px", height: "100%", margin: "0 0.5px"}}
								className="row normal-card-item-border justify-content-center align-items-center">
								<div className="mx-4">
									<div className="col-12">
										<div className={"row align-items-center mt-1 mb-2 mb-xxl-4"}>
											<div className="mb-xxl-2">
												<Image src={"/assets/icons/calingo-insurance-shield.svg"}
													layout={"intrinsic"}
													objectFit={"contain"}
													width={40}
													height={40}/>
											</div>
											<div>
												<Typography variant="heading3MdBodyLgBlack"
													semanticTag="span">
													{t("title-1-homepage")}
												</Typography>
												<Typography variant="heading3MdBodyLgBlack"
													semanticTag="p"
													className="mb-0">
													{t(" calingo")}
												</Typography>
											</div>
										</div>
										{insuranceBonusesList.map(({icon, text}, indx) => {
											return (
												<div className="d-flex flex-direction-row  ps-1 mb-3"
													key={indx++}>
													<div className="col-auto">
														<Image src={icon}
															layout={"intrinsic"}
															objectFit={"contain"}
															width={16}
															height={16}/>
													</div>
													<Typography className="ms-2"
														variant="bodySm"
														semanticTag="span">
														{t(`${text}`)}
													</Typography>
												</div>
											)
										})}
									</div>
								</div>
							</div> : (!isMobile && isListView) || isCheckoutPage ?
								<div className="row align-items-center justify-content-center py-2">
									<div className={"col-auto align-items-center mt-1 mb-2 mb-xxl-4"}>
										<div className="d-inline-block me-3">
											<Image src={"/assets/icons/calingo-insurance-shield.svg"}
												layout={"intrinsic"}
												objectFit={"contain"}
												width={40}
												height={40}/>
										</div>
										<div className="d-inline-block">
											<Typography variant="bodyLgBold"
												semanticTag="span">
												{t("title-1-homepage")}
											</Typography>
											<Typography variant="bodyLgBold"
												semanticTag="p"
												className="mb-0">
												{t(" calingo")}
											</Typography>
										</div>
									</div>
									<div className="col-6 row">
										{insuranceBonusesList.map(({icon, text}, indx) => {
											return (
												<div className="d-flex align-items-start col-6 my-1"
													key={indx++}
													style={{padding: "0px 12px"}}>
													<div className="col-auto">
														<Image src={icon}
															layout={"intrinsic"}
															objectFit={"contain"}
															width={16}
															height={16}/>
													</div>
													<Typography className="ms-2"
														variant="bodySm"
														semanticTag="span">
														{t(`${text}`)}
													</Typography>
												</div>
											)
										})}</div>
								</div> :
								null
			}
		</>
	)
}

import React from "react"
import Image from "next/image"
import BookAppointmentButton from "../reusable/ModalButtons/BookAppointmentButton"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
  className?: string
	isBookAppointment?: boolean
	isDisabled?: boolean
	imageWidth?: number
	handleClick?: ()=>void
	imgSrc?: string
	text?: string
	description?: string
	remainingRentTime?:number
}
// eslint-disable-next-line
const ReusableConsultation: React.FC<Props> = ({className,remainingRentTime, imageWidth, handleClick, imgSrc, text, description, isBookAppointment, isDisabled}) => {
	const {t} = useTranslation(["common", "secondHandBikes"])
	if (isDisabled) {
		return <div className={`recommendation-tooltip-tag rounded-3 ${className ? className : ""}`}>
			<div style={{opacity: 0.3}}
				className={"d-flex align-items-center"}>
				<div className="d-flex align-items-center justify-content-start gap-3 position-relative">
					<div className="d-flex align-items-center justify-content-center position-relative"
						style={{
							minWidth: imageWidth && isBookAppointment ? (imageWidth + 4) : 52,
							minHeight: imageWidth && isBookAppointment ? (imageWidth + 4) : 52,
							borderRadius: "50%",
							zIndex: 2,
							backgroundColor: "#F8F8F8"
						}}>
						{isBookAppointment ? <Image src={ "/assets/images/book-consultation-high-quality.png"}
							layout="fill"/> : <Image src={imgSrc}
							height={imageWidth ? imageWidth : 48}
							width={imageWidth ? imageWidth : 48}
							objectFit="contain" />}
					</div>
					<div>
						{isBookAppointment ? <BookAppointmentButton label={t("Beratung Buchen")}
							linkWithIcon={true}
							isListView/> :
							<div className="d-flex align-items-center px-0  p-1
                             justify-content-start align-items-center gap-2 cursor-pointer rounded-3" >
								<Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 700}}
									variant={ "bodyLgBold" }
									className="mb-0"
									semanticTag={ "span" }>{text}</Typography>
								<Image src={"/assets/icons/chevron-right-bg-grey.svg"}
									width={20}
									height={20}/>
							</div>}
						{isBookAppointment ? <Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 500}}
							className=""
							variant="bodySm">{t("talk-to-our-experts")}
						</Typography> : <Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 500}}
							className=""
							variant="bodySm">{description}</Typography>}
					</div>
				</div>

			</div>
			<Typography variant="bodySmBold"
				style={{opacity: 1, zIndex: 99999}}
				className="recommendation-tooltip-tagtext">
                    This bike is reserved for the next {Math.round(Math.floor(remainingRentTime) / 24)} days
			</Typography>
		</div>
	}

	return (
		<div onClick={handleClick}
			className={`${className ? className : undefined}`}>
			<div className={"d-flex align-items-center col-12"}>
				<div className="col-10 d-flex align-items-center justify-content-start gap-3 position-relative">
					<div className="d-flex align-items-center justify-content-center position-relative"
						style={{
							minWidth: 40,
							minHeight: 40,
							borderRadius: "3px",
							zIndex: 2,
							backgroundColor: "#F8F8F8"
						}}>

						{isBookAppointment ?
							<Image src={imgSrc ? imgSrc : "/assets/images/book-consultation-high-quality.png"}
								layout="fixed"
								objectFit="cover"
								height={40}
								width={40}
							/> :
							<Image src={imgSrc}
								height={imageWidth ? imageWidth : 40}
								width={imageWidth ? imageWidth : 40}
								objectFit="contain" />}
					</div>
					<div className="col-12">
						{isBookAppointment ?
							<BookAppointmentButton label={t("Beratung Buchen")}
								linkWithIcon={true}
								isListView
								icon={
									<Image src={"/assets/icons/chevron-right.svg"}
										width={20}
										height={20}/>
								}
							/> : <div
								className="d-flex align-items-center px-0  p-1
                            justify-content-start align-items-center gap-2 cursor-pointer rounded-3">
								<Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 700}}
									variant={ "bodyLgBold" }
									className="mb-0"
									semanticTag={ "span" }>{text}</Typography>
								<Image src={"/assets/icons/chevron-right-bg-grey.svg"}
									width={20}
									height={20}/>
							</div>}
						{isBookAppointment ? <Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 500}}
							className=""
							variant="bodySm">{t("talk-to-our-experts")}
						</Typography> : <Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 500}}
							className=""
							variant="bodySm">{description}</Typography>}
					</div>
				</div>

			</div>

		</div>
	)
}

export default ReusableConsultation

import {useEffect, useState} from "react"
import {StockStatus} from "../components/bikeDetails/BikeDetails"
import {findBikeAttributeBySlug} from "../utility/Helper"
import {useRouter} from "next/router"
import {BikeType} from "../utility/Types"

function useInitializePDPSizes(bike:BikeType) {
	const [variations, setVariations] = useState([])
	const [soldOut, setSoldOut] = useState(bike?.status === "draft")
	const [selectedVariation, setSelectedVariation] = useState<any>(null)
	const [selectedSize, setSelectedSize] = useState<string>(null)
	const router = useRouter()
	useEffect(() => {
		let variationsSoldOut = true
		bike.staticVariations.forEach(variation => {
			if (variation.stock_status === StockStatus.inStock || variation.stock_status === StockStatus.onBackOrder) {
				variationsSoldOut = false
			}
		})

		setSoldOut(bike.status === "draft" || variationsSoldOut)
		const sizeOrder = ["XS", "S", "M", "L", "XL", "XXL"]

		const _response = [...bike.staticVariations]
		_response.sort((a, b) => {
			const sizeA = findBikeAttributeBySlug(a, "sizes", false, true)
			const sizeB = findBikeAttributeBySlug(b, "sizes", false, true)

			const indexA = sizeOrder.indexOf(sizeA)
			const indexB = sizeOrder.indexOf(sizeB)

			return (indexA === -1 ? sizeOrder.length : indexA) - (indexB === -1 ? sizeOrder.length : indexB)
		})

		setVariations(_response)
	}, [bike.staticVariations, bike, router])

	useEffect(() => {
		const onlyOneSizeAvailable = variations.filter(variation => {
			return variation.stock_status === "instock" || variation.stock_status === "onbackorder"
		})?.length === 1
		const onlySizeAvailableVariation = variations.find(variation => variation.stock_status === "instock" || variation.stock_status === "onbackorder")
		if (variations.length === 1 || onlyOneSizeAvailable) {
			const sizeAttribute = findBikeAttributeBySlug(onlyOneSizeAvailable ? onlySizeAvailableVariation : variations[0], "sizes", false, true) === "No Data" ? findBikeAttributeBySlug(onlyOneSizeAvailable ? onlySizeAvailableVariation : variations[0], "seat-tube-length", false, true) : findBikeAttributeBySlug(onlyOneSizeAvailable ? onlySizeAvailableVariation : variations[0], "sizes", false, true)
			setSelectedVariation(onlyOneSizeAvailable ? onlySizeAvailableVariation : variations[0])
			setSelectedSize(sizeAttribute)
		}
	}, [variations, bike, router])

	// Resets selectedSize and selectedVariation
	useEffect(() => {
		setSelectedVariation(null)
		setSelectedSize(null)
	}, [bike])

	return {variations, setVariations, soldOut, setSoldOut, selectedVariation, setSelectedVariation, selectedSize, setSelectedSize}
}

export default useInitializePDPSizes

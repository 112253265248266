import React, {useEffect, useState} from "react"
import Typography from "./Typography/Typography"
import {colors} from "../../colors/Colors"
import {useRouter} from "next/router"

type Props = {
	color?: string
	style?: any
	size?:keyof typeof sizes
}

enum sizes {sm="sm", md="md", lg="lg"}

const messages = {
	de: [
		["🚴‍ Kurz Kette ölen... fast fertig!"],
		["🏁 Noch ein paar Watt treten... gleich da!"],
		["🔧 Ladegeschwindigkeit: Shimano Dura-Ace oder doch eher gemütlich?"],
		["🚲 Noch kurz den Helm richten..."],
		["🌍 Nachhaltig geladen – komplett CO₂-frei!"],
		["⏳ Ein Moment... wir sind noch im Windschatten!"],
		["🏔 Bergauf ist es immer langsamer – gleich oben!"],
		["🏆 Fast geschafft! Die Ziellinie ist in Sicht."],
		["💨 Aerodynamik optimiert... fast bereit!"],
		["🔄 Kurzer Kettenwechsel... gleich geht’s weiter!"],
		["🚦 Warten auf Grün... und los!"],
		["🛠 Lädt so schnell wie ein E-Bike im Turbo-Modus!"],
		["🚴‍ Noch schnell eine Banane essen, dann geht’s weiter!"],
		["🔋 Lädt... aber keine Sorge, unser Akku hält länger als deiner!"],
		["🌊 Mit Rückenwind geht’s schneller... gleich soweit!"]
	],
	fr: [
		["🚴 Un peu d'huile sur la chaîne... presque prêt !"],
		["🏁 Encore quelques watts à pédaler... on y est presque !"],
		["🔧 Vitesse de chargement : Shimano Dura-Ace ou plutôt détente ?"],
		["🚲 Attends... juste le temps d'ajuster le casque !"],
		["🌍 Chargement 100% éco-responsable – zéro émission !"],
		["⏳ Un instant... on est encore dans l'aspiration !"],
		["🏔 En montée, c’est toujours plus lent… encore un effort !"],
		["🏆 Presque arrivé ! La ligne d’arrivée est en vue."],
		["💨 Optimisation aérodynamique en cours... presque prêt !"],
		["🔄 Changement de chaîne en cours... encore une seconde !"],
		["🚦 En attente du feu vert... et c'est parti !"],
		["🛠 Chargement en mode turbo, comme un vélo électrique !"],
		["🚴‍ Pause banane express... on repart !"],
		["🔋 Chargement... mais ne t’inquiète pas, notre batterie dure plus longtemps que la tienne !"],
		["🌊 Avec le vent dans le dos, ça ira plus vite... patience !"]
	]
}

const pickNextMessage = (message: string | null, locale: string = null) => {
	const _locale = locale || "de"
	if (!message) {
		return messages[_locale][Math.random() * messages[_locale].length]?.[Math.floor(Math.random() * messages[_locale][0].length)]
	}

	let currentIndex = 0
	for (let i = 0; i < messages[_locale].length; i++) {
		if (messages[_locale][i].includes(message)) {
			currentIndex = i
		}
	}

	if (currentIndex + 1 > messages[_locale].length - 1) {
		return messages[_locale][0][Math.floor(Math.random() * messages[_locale][0].length)]
	}

	return messages[_locale][currentIndex + 1][Math.floor(Math.random() * messages[_locale][0].length)]
}

let currentTimeout = null
let currentInterval = null

function Spinner({color = "text-primary", style, size = "md"}: Props) {
	const spinnerSize = "spinner-border-" + size
	const [showLoadingMessages, setShowLoadingMessages] = useState(false)
	const [currentLoadingMessage, setCurrentLoadingMessage] = useState(null)
	const {locale} = useRouter()

	useEffect(() => {
		clearTimeout(currentTimeout)
		currentTimeout = setTimeout(() => {
			setShowLoadingMessages(true)
		}, 1500)

		return () => {
			if (currentTimeout) {
				clearTimeout(currentTimeout)
			}

			if (currentInterval) {
				clearInterval(currentInterval)
			}

			currentTimeout = null
			currentInterval = null
			setShowLoadingMessages(false)
			setCurrentLoadingMessage(null)
		}
	}, [])

	useEffect(() => {
		if (showLoadingMessages) {
			setCurrentLoadingMessage((prevMessage:string) => pickNextMessage(prevMessage, locale))
			clearInterval(currentInterval)
			currentInterval = setInterval(() => {
				setCurrentLoadingMessage((prevMessage:string) => pickNextMessage(prevMessage, locale))
			}, 3000)
		}
	}, [showLoadingMessages])

	return (
		<div className="text-center"
			style={{...style}}>
			<div className={`spinner-border ${color} ${spinnerSize}`}
				role="status">
				<span className="sr-only"></span>
			</div>
			{showLoadingMessages &&
				<Typography variant={"bodyXSmBold"}
					semanticTag={"div"}
					style={{color: colors[color.replace("text-", "")]}}>
					{currentLoadingMessage}
				</Typography>}
		</div>
	)
}

export default Spinner

import React, {useEffect, useState} from "react"
import dynamic from "next/dynamic"

const StagingLogin = dynamic(() => import("../../components/staging/StagingLogin"))

const Auth = () => {
	const [devAuthenticated, setDevAuthenticated] = useState<boolean>(process.env.NEXT_PUBLIC_ENVIRONMENT === "production")
	useEffect(() => {
		// Show staging login
		if (localStorage.getItem("devAuthenticated") === process.env.NEXT_PUBLIC_STAGING_PASSWORD) {
			setDevAuthenticated(true)
		}
	}, [])

	const handleAuthSubmit = values => {
		if (values.password === process.env.NEXT_PUBLIC_STAGING_PASSWORD) {
			setDevAuthenticated(values.password)
			localStorage.setItem("devAuthenticated", values.password)
		} else {
			// eslint-disable-next-line no-alert
			alert("Wrong password")
		}
	}

	return (
		!devAuthenticated && <div style={{
			zIndex: 999999
		}}
		className={"position-fixed top-0 start-0 bottom-0 end-0 bg-white"}>
			<div className={"container mt-4"}>
				<div className={"row justify-content-center"}>
					<div className={"col-12 col-md-6 col-lg-4"}>
						<h1>Please login</h1>
						<StagingLogin onSubmit={values => handleAuthSubmit(values)}/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auth

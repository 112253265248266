export type Category = {
    id: number
    name: string
    slug: string
}

export const mainCategories = [
	{
		name: "E-Trekking",
		slug: "e-trekking"
	},
	{
		name: "E-Mountain",
		slug: "e-mountain"
	},
	{
		name: "E-Urban",
		slug: "e-urban"
	},
	{
		name: "Accessories",
		slug: "accessories"
	},

	{
		name: "Anhänger",
		slug: "anhaenger"
	},
	{
		name: "Cargo E-Bikes",
		slug: "cargo-e-bikes"
	},

	{
		name: "Mountain Fully",
		slug: "mountain-fully"
	},
	{
		name: "Gravel Bikes",
		slug: "gravel-bikes"
	},
	{
		name: "Mountain Hardtail",
		slug: "mountain-hardtail"
	},
	{
		name: "Helme",
		slug: "helme"
	},

	{
		name: "Kindersitz",
		slug: "kindersitz"
	},
	{
		name: "Kindervelos",
		slug: "kindervelos"
	},
	{
		name: "Korb",
		slug: "korb"
	},
	{
		name: "Mountain Bikes",
		slug: "mountain-bikes"
	},
	{
		name: "Occasion",
		slug: "occasion"
	},
	{
		name: "Road Bikes",
		slug: "road-bikes"
	},
	{
		name: "Schlösser",
		slug: "schloesser"
	},
	{
		name: "Schutzkleidung",
		slug: "schutzkleidung"
	},
	{
		name: "Swiss Made",
		slug: "swiss-made"
	},
	{
		name: "Taschen",
		slug: "taschen"
	},
	{
		name: "Velopumpe",
		slug: "velopumpe"
	},
	{
		name: "Veloträger",
		slug: "velotraeger"
	},
	{
		name: "E-Mountain Fully",
		slug: "e-mountain-fully"
	},
	{
		name: "E-Mountain Hardtail",
		slug: "e-mountain-hardtail"
	},
	{
		name: "E-Road Bike",
		slug: "e-road-bike"
	},
	{
		name: "Bikes",
		slug: "bikes"
	},
	{
		name: "E-Bikes",
		slug: "e-bikes"
	},
	{
		name: "Nicht-elektrische Bikes",
		slug: "nicht-elektrische-bikes"
	}
]

const getMainCategory = categories => {
	for (let i = 0; i < mainCategories.length; i++) {
		const _mainCategory = categories?.find(e => e.slug === mainCategories[i].slug)
		if (_mainCategory) {
			return {..._mainCategory, name: mainCategories[i].name}
		}
	}

	return null
}

export default getMainCategory

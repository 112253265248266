import React from "react"
import styles from "./PureSlider.module.scss"

type Props = {
    dotsPositionY: number
    visibleDots: any[]
    currentSlide: number
    handleDotClick: (index: number) => void;
    isAbsolute?: boolean
}

const SliderDots: React.FC<Props> = ({dotsPositionY, visibleDots, currentSlide, handleDotClick, isAbsolute = true}) => {
	return (
		<div
			className={styles["dots-container"]}
			style={{
				position: isAbsolute ? "absolute" : "inherit",
				transform: isAbsolute ? "translateX(-50%)" : "",
				zIndex: 1000,
				bottom: `${dotsPositionY}px`
			}}>
			{visibleDots.map(dotIndex => (
				<button
					key={dotIndex}
					className={`${styles.dot} ${dotIndex === currentSlide ? styles.active : ""}`}
					onClick={() => handleDotClick(dotIndex)}
				/>
			))}
		</div>
	)
}

export default SliderDots

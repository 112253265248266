import React, {Dispatch, SetStateAction} from "react"
import MobileModal from "./MobileModal"
import Typography from "./Typography/Typography"
import SearchResultItems from "../search/SearchResultItems"
import {ArrowLeft} from "react-bootstrap-icons"
import AskAiAboutLifestyle from "./AskAiAboutLifestyle"
import AiPromptSuggestions from "./AiPromptSuggestions"
import AiSuggestionLoadingSkeleton from "./LoadingSkeleton/AiSuggestionLoadingSkeleton"
import Image from "next/image"
import {useTranslation} from "next-i18next"

type Props = {
    generateUserPrompts:(e)=>void
    isGenerating:boolean
    setActivePrompt:Dispatch<SetStateAction<string>>
    setInput:Dispatch<SetStateAction<string>>
    input:string
    activePrompt:string
    resetState:()=>void
    closeModal:()=>void
    handleSubmit:(e)=>void
	result: any
	setSuggestedPrompts:Dispatch<SetStateAction<any>>
	setResult:Dispatch<SetStateAction<any>>
	suggestedPrompts: any
	setStep: Dispatch<SetStateAction<1 | 2 | 3>>
    step: 1|2|3
	loading: boolean
	isOpen:boolean
	error:boolean
}
function MobileNavBarAi({generateUserPrompts, error, resetState, step, setStep, isOpen, suggestedPrompts, setResult, setSuggestedPrompts, isGenerating, handleSubmit, setInput, input, loading, setActivePrompt, result, activePrompt, closeModal}: Props) {
	const {t} = useTranslation()
	return (
		<MobileModal modalHeader={error ? <div className="d-flex align-items-center justify-content-center gap-2"><ArrowLeft size={24}
			onClick={() => {
				closeModal()
			}} /><Typography style={{fontSize: "18px", lineHeight: "26px"}}
			variant="bodySmBold">{ t("something-is-worng")}</Typography></div> : suggestedPrompts?.length && result?.length && step === 3 ? <div className="d-flex align-items-center justify-content-center gap-2"><ArrowLeft size={24}
			onClick={() => {
				setResult([])
				setStep(2)
			}} /><Typography style={{fontSize: "28px", lineHeight: "36px"}}
			variant="bodySmBold">{t("select-a-bike")}</Typography></div> : suggestedPrompts?.length && step === 2 ? <div className="d-flex align-items-center justify-content-center gap-2"><ArrowLeft size={24}
			onClick={() => {
				setStep(1)
				setSuggestedPrompts([])
			}} /><Typography style={{fontSize: "28px", lineHeight: "36px"}}
			variant="bodySmBold">{t("choose-your-lifestyle")}</Typography></div> : <Typography style={{fontSize: "28px", lineHeight: "36px"}}
			variant="bodySmBold">{t("ask-mybikeplan-ai") }</Typography>}
		isAiModal
		modalOpen={isOpen}
		handleClose={closeModal}>

			{error ?
				<div style={{maxHeight: "80dvh", overflow: "auto"}}
					className={"d-flex slide-in-right d-md-none col-12 mt-4 relative align-items-center flex-column justify-content-start px-3 gap-3"}>
					<Typography style={{color: "#C3BABA", fontSize: "14px", textAlign: "center", lineHeight: "22px"}}
						variant="bodySm">Looks like our AI might have gotten overwhelmed, please retry again</Typography>
					<Image src={"/assets/icons/robot.png"}
						width={70}
						height={70}
						objectFit="contain"
						alt="AI Error"/>
				</div> :
				<div style={{maxHeight: "80dvh", overflowY: "auto", overflowX: "hidden"}}
					className={"d-flex d-md-none col-12 relative align-items-start justify-content-start flex-column px-3 gap-3"}>
					<AskAiAboutLifestyle resetState={resetState}
						step={step}
						setStep={setStep}
						generateUserPrompts={generateUserPrompts}
						input={input}
						isGenerating={isGenerating}
						setInput={setInput}
						suggestedPrompts={suggestedPrompts} />
					<AiPromptSuggestions step={step}
						setStep={setStep}
						activePrompt={activePrompt}
						handleSubmit={handleSubmit}
						isGenerating={isGenerating}
						loading={loading}
						result={result }
						setActivePrompt={setActivePrompt}
						suggestedPrompts={suggestedPrompts} />

					{result?.length && !loading ?
						<div className={`col-12 my-4 ai-suggestions-slider ${result?.length ? "slide-in-right" : ""}`}>
							<Typography semanticTag="div"
								className="my-2 slide-in-right"
								style={{fontSize: "14px", lineHeight: "20px"}}
								variant="bodySmBold">{t("bikes-added-to-wishlist")}</Typography>
							<SearchResultItems isAiResponse
								list={result || []}
								isAccessory />
						</div> : loading && step === 3 ? <AiSuggestionLoadingSkeleton /> : null}
				</div>}
		</MobileModal>
	)
}

export default MobileNavBarAi

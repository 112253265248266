import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
    isFinancingExplanationTooltipOpen?:boolean
}
function FinancingExplanationTooltip({isFinancingExplanationTooltipOpen}:Props) {
	const {t} = useTranslation()
	return (
		<div className={`social-proof-tagtext ${isFinancingExplanationTooltipOpen ? "active" : ""}`}><Typography variant="bodyLgBlack"
			style={{fontSize: 24}}>{t("no-interest-label")} - Bike Now, Pay Later </Typography>
		<Typography variant="bodyLg"
			semanticTag="h4"
			className={" ml-5 mt-3"}>
			{t("Wir arbeiten mit dem Finanzierungspartner")} <a
				href="https://swissbilling.ch">{t("Swissbilling.ch")}</a> {t("zusammen, welcher es uns ermöglicht, dir die Ratenzahlung zinsfrei anzubieten.")}
			<br/><br/> {t("Als Gegenleistung erhält")} <a
				href="https://swissbilling.ch">{t("Swissbilling.ch")}</a> {t("von uns einen Anteil des Gewinns. Diesen Weg haben wir bewusst gewählt, um dir Extrakosten zu ersparen und jede*m den Weg zur E-Mobilität zu ermöglichen. Du hast weitere Fragen dazu? Wir geben auch telefonisch gerne darüber Auskunft!")}
		</Typography></div>
	)
}

export default FinancingExplanationTooltip

import React from "react"
import {Transition} from "react-transition-group"

type Props = {
  inProp: boolean,
  duration?: number,
  children: React.ReactChildren | React.ReactChild
  transTimingFn?: string
  customHeight?: number | string
  onExited?: () => void
  unmount?: boolean,
  className?: string
}

const AccordionAnimation = ({
	inProp,
	duration = 400,
	transTimingFn,
	children,
	customHeight = 0,
	onExited,
	unmount
}: Props) => {
	const [height, setHeight] = React.useState(customHeight)

	const defaultStyle = {
		transition: `all ${duration}ms ${transTimingFn ? transTimingFn : "linear"}`,
		overflow: "hidden"
	}

	const transitionStyles = {
		entering: {maxHeight: height},
		entered: {maxHeight: height},
		exiting: {maxHeight: 0},
		exited: {maxHeight: 0}
	}

	return (
		<Transition
			unmountOnExit={unmount}
			mountOnEnter={unmount}
			in={inProp}
			timeout={duration}
			onExited={onExited}
			onEnter={node => {
				// @ts-ignore
				if (!customHeight || height < node.scrollHeight) {
					// @ts-ignore
					setHeight(node.scrollHeight)
				}
			}}>
			{state => (
				<div style={{...defaultStyle, ...transitionStyles[state]}}>{children}</div>
			)}
		</Transition>
	)
}

export default AccordionAnimation

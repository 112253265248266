import React, {useContext} from "react"
import StoryContext from "../../context/StoryContext"
import {X} from "react-bootstrap-icons"
import {ModalContext} from "../../context/ModalContext"

const ProgressBar = () => {
	const {stories, getCurrentIndex, info} = useContext(StoryContext)
	const {closeStory} = useContext(ModalContext)

	return (
		<div className={"position-absolute top-0 start-0 end-0"}
			style={{
				height: "auto"
			}}>
			<div className={"gradient-black-transparent top-down"}
				style={{
					height: 100
				}}/>
			<div className={"d-flex justify-content-between align-items-center gap-1 px-4 py-2 g-1"}>
				<X onClick={closeStory}
					size={34}
					style={{zIndex: 99999999, paddingRight: "1rem", width: "3rem", cursor: "pointer"}}
					color="#FFFFFF" />
				{stories.map((video, index) => {
					const currentIndex = getCurrentIndex()
					return <div key={index}
						className={"col"}>
						<div className={"position-relative"}
							style={{
								height: 2,
								background: "#DFDBD880"
							}}>
							<div
								className={"position-absolute top-0 start-0 bottom-0"}
								style={{
									background: "#FFFFFF",
									width: info && index === currentIndex ? `${(info?.currentTime / info?.duration) * 100}%` : index < currentIndex ? "100%" : 0
								}}/>
						</div>
					</div>
				})}
			</div>
		</div>
	)
}

ProgressBar.propTypes = {

}

export default ProgressBar

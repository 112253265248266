export const findTopBrandsPerCategory = (category: string, topBrandsPerCategories: any[], brands:any[]) => {
	const brandList = topBrandsPerCategories?.find(({attributes}) => attributes.name === category) || null

	if (brandList) {
		const topBrands = brandList.attributes.brand_list?.map(brand => brand.name)
		return brands?.filter(brand => topBrands.includes(brand.fields.menuLabel))
	}

	return []
}

import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import React from "react"

function ConditionAndMileage({isSecondHand, mileage}:{isSecondHand:boolean, mileage:number}) {
	const {t} = useTranslation()
	return (
		<div className="d-md-none px-3">
			{isSecondHand && (<>
				<Typography className="mb-2 mt-3 text-uppercase"
					variant="bodySmBold"
					semanticTag="div">{t("condition", {ns: "secondHandBikes"})}</Typography>
				<Typography className="mb-2 mt-3"
					variant="bodySm"
					semanticTag="div">{t("perfect", {ns: "secondHandBikes"})}</Typography>
				<Typography className="mb-2 mt-3 text-uppercase"
					variant="bodySmBold"
					semanticTag="div">{t("mileage", {ns: "secondHandBikes"})}</Typography>
				<Typography className="mb-2 mt-3"
					variant="bodySm"
					semanticTag="div">{`${mileage}km`}</Typography>
			</>)}
		</div>
	)
}

export default ConditionAndMileage

import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import Button from "../../reusable/components/Button"
import styles from "./styles.module.scss"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

const ServiceCenters = () => {
	const {t} = useTranslation("common")
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<div className="container mt-5 mt-md-0 pb-3 pb-md-0 mb-md-5">
			<div className="col-12 d-md-flex align-items-center justify-content-between bg-grey py-4 pb-3 py-md-5 px-3 px-md-5"
				style={{borderRadius: 16}}>

				<div className="col-md-5 px-5 px-md-0 ps-md-5 mb-4 mb-md-0">
					<Image src={"/assets/images/service-centres-map-desktop.svg"}
						height={255}
						width={400}
						layout="responsive"
						objectFit="cover"
						objectPosition="center"/>
				</div>

				<div className="col-md-5">
					<div className="ps-md-2">
						<Typography variant="bodyLgBlack"
							semanticTag="h3"
							className="mb-2 d-md-none"
							style={{fontSize: 28, lineHeight: "36px"}}>
							{t("service-centers-title")}
						</Typography>
						<Typography variant="heading2Black"
							semanticTag="h3"
							className="mb-2 d-none d-md-block">
							{t("service-centers-title")}
						</Typography>
						<Typography variant="bodyLg"
							semanticTag="p"
							className="mb-2 mb-md-5">
							{t("service-centers-text")}
						</Typography>
					</div>
					<Button
						paddingX="0"
						isNoUppercase
						onClick={() => {
							reactGA?.event({
								category: gaCategories.homePage,
								action: gaEvents.serviceCentersButton,
								label: `${gaEvents.serviceCentersButton}`,
								nonInteraction: false
							})
						}}
						icon={<img src="/assets/icons/chevron-right-bg-grey.svg"/>}
						color=""
						spinnerColor="black"
						href={t("/service-centers")}
						className={`rounded-3 mx-0 ps-md-2 reset btn shadow-none ${styles.buttonHover}`}
						opacity={1}>
						<Typography className="me-2"
							style={{fontSize: "16px"}}
							variant="bodyLgBold">{t("service-centers-button-text")}</Typography>
					</Button>
				</div>
			</div>
		</div>
	)
}

export default ServiceCenters

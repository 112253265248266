import React, {useContext} from "react"
import {StoryItem} from "../../context/StoryContext"
import Typography from "../reusable/Typography/Typography"
import Button from "../reusable/components/Button"
import LayoutContext from "../../context/LayoutContext"
import {useTranslation} from "next-i18next"
import {ModalContext} from "../../context/ModalContext"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"

function StoryCta({story}: { story: StoryItem }) {
	const {isMobile} = useContext(LayoutContext)
	const {closeStory} = useContext(ModalContext)
	const {reactGA} = useContext(AnalyticsContext)
	const {t} = useTranslation()
	if (isMobile) {
		return (<div onClick={e => {
			e.stopPropagation()
		}}
		style={{top: (story?.cta?.description && story?.cta?.description.split(" ").length < 11) ? "87%" : (story?.cta?.description && story?.cta?.description.split(" ").length >= 11) ? "84%" : "93%", position: "absolute", left: "50%", transform: "translate(-50%)"}}
		className="col-12  ">
			{story?.cta?.description || story?.cta?.btnText ? <div style={{zIndex: 99999999}}
				className="d-flex col-12 flex-column gap-3 container align-items-center justify-content-center">
				{story?.cta?.description ? <Typography className="text-center"
					style={{color: "white", fontSize: "12px"}}
					variant="bodySmBold">
					{story?.cta?.description}
				</Typography> : null}
				{story?.cta?.btnText ? <Button onClick={() => {
					reactGA?.event({
						category: gaCategories.homePage,
						action: gaEvents.storyCtaClicked,
						label: `story:${story.id} CTA:${story?.cta?.btnText}`,
						nonInteraction: false
					})
					closeStory()
				}}
				href={story?.cta?.href || "/"}
				fontSizeSmall
				label={t(story?.cta?.btnText)}
				className="col-12"
				bgColor="#FEC04D" /> : null}
			</div> : null}
		</div>
		)
	}

	return (<>
		{story?.cta?.description || story?.cta?.btnText ? <div onClick={e => {
			e.stopPropagation()
		}}
		style={{maxWidth: isMobile ? "310px" : "350px"}}
		className="d-flex col-12 flex-column gap-3 align-items-center justify-content-center">
			{story?.cta?.description ? <Typography className="text-center"
				style={{color: "white", fontSize: "12px"}}
				variant="bodySmBold">
				{story?.cta?.description}
			</Typography> : null}
			{story?.cta?.btnText ? <Button onClick={() => {
				reactGA?.event({
					category: gaCategories.homePage,
					action: gaEvents.storyCtaClicked,
					label: `story:${story.id} CTA:${story?.cta?.btnText}`,
					nonInteraction: false
				})
				closeStory()
			}}
			href={story?.cta?.href || "/"}
			fontSizeSmall
			label={t(story?.cta?.btnText)}
			className={`${story?.cta?.isFullWidth ? "col-12" : ""}`}
			bgColor="#FEC04D" /> : null}
		</div> : null}
	</>
	)
}

export default StoryCta

import React, {Dispatch, SetStateAction, useContext} from "react"
import Typography from "../reusable/Typography/Typography"
import useCache from "../../hooks/useCache"
import SearchContext from "../../context/SearchContext"
import {useTranslation} from "next-i18next"
import {fetchGet} from "../../firebaseAdmin/fetchGet"
import {useRouter} from "next/router"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"
import TopSearches from "./TopSearches"
import SuggestionsSkeletonsLoader from "../reusable/LoadingSkeleton/SuggestionsSkeletonsLoader"
import SuggestionItem from "./SuggestionItem"
import {CollectionName} from "../../constants"
import DataContext from "../../context/DataContext"

type Props = {
	setIsBackgroundOn:Dispatch<SetStateAction<boolean>>
}
function SearchSuggestions({setIsBackgroundOn}:Props) {
	const {data: topSearches, isLoading: isLoadingSuggestions} = useCache("/api/search/top-searches?limit=15", false, false, true)
	const {results, value, isLoading, setValue, setTermValue, handleSearch} = useContext(SearchContext)
	const {t} = useTranslation()
	const router = useRouter()
	const isBrandWorld = router.pathname.includes("/brand-world")
	const {brandWorld} = useContext(DataContext)
	return (
		<div className="col-12 col-lg-5  pb-4">
			{(results && results?.searchSuggestions?.length) || isLoading || (!results?.searchSuggestions?.length && !results?.autoCorrect?.length) ? <Typography variant={"bodySmBold"}
				semanticTag={"div"}
				className={"mt-2"}>
				{t("search-suggestions", {ns: "search"})}
			</Typography> : results && results?.autoCorrect?.length ? <Typography variant={"bodySmBold"}
				semanticTag={"div"}
				className={"mt-2"}>
				{t("did-you-mean", {ns: "search"})}
			</Typography> : null}
			<div style={{rowGap: "12px", marginTop: "2rem", overflowY: "auto"}}
				className="d-flex flex-column">
				{results && !isLoadingSuggestions && !isLoading && results?.searchSuggestions?.length ? results?.searchSuggestions.map((suggestion, index) =>
					<SuggestionItem key={index}
						caractersToHighlight={value.length}
						suggestion={suggestion}
						handleClick={async () => {
							await fetchGet(`/api/search?term=${value}&index=${index}&limit=10&offset=0&locale=${router.locale}&source=user-clicked-on-a-specific-bike`)
							router.replace(isBrandWorld ? `${filterUrl({[urlParams.searchValue]: suggestion}, "accessory-type", suggestion, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({[urlParams.searchValue]: suggestion, page: "-"}, null, null))
							setIsBackgroundOn(() => false)
							setValue(() => suggestion)
							setTermValue(() => suggestion)
						}} />
				) : isLoadingSuggestions || isLoading ?
					<SuggestionsSkeletonsLoader numberOfSkeletonElements={6} /> :
					results && results?.autoCorrect?.length ? results?.autoCorrect.map((suggestion, index) =>
						<SuggestionItem isAutoCorrect
							key={index}
							caractersToHighlight={value.length}
							suggestion={suggestion}
							handleClick={() => {
								setValue(suggestion)
								setTermValue(suggestion)
								handleSearch(suggestion)
							}} />
					) : <Typography variant={"bodySm"}
						semanticTag={"div"}
						className={"mt-0"}>
						{t("no-suggestions-found", {ns: "search"})}
					</Typography>}
			</div>

			{isLoading ? null : <><Typography variant={"bodySmBold"}
				semanticTag={"div"}
				className={"mt-3"}>
				{t("top-search-terms", {ns: "search"})}
			</Typography>
			<TopSearches topSearches={topSearches}
				setIsBackgroundOn={setIsBackgroundOn}/></>}
		</div>
	)
}

export default SearchSuggestions

/* eslint-disable no-mixed-spaces-and-tabs */
import {useTranslation} from "next-i18next"
import slugify from "slugify"
import {getFaqData} from "../components/faqComponents/FaqContent"
import Image from "next/image"
import React, {Dispatch, SetStateAction} from "react"
import Typography from "../components/reusable/Typography/Typography"
import {delimiter, priceRangeDelimiter} from "../components/filter/Config"
import getImageUrl from "../strapi/image/image"
import {countryList} from "../components/forms/data/countryList"
import {Collections} from "../firebaseAdmin/collections"
import {customPlanLengthMap} from "../components/bikeDetails/plan/customPlanLengthMap"
import {aiQuestionSource, BikeType, DataItem, LiveNotification, premiumBrandWidgetsType, StaticTile} from "./Types"
import {categorySlugs} from "../components/filter/config/Categories"
import {formatImageSrc} from "../strapi/strapiHelpers"
import {fetchGet} from "../firebaseAdmin/fetchGet"
import {guardRecentlyViewedBikesType} from "./typeGuards"
import {fetchPost} from "../firebaseAdmin/fetchPost"
import {fetchStrapiData, fetchStrapiDataCollectionFromStrapi} from "./strapi/fetchDataCollection"
import {strapiCollections} from "./strapi/collections"
import {getOrganizationDataFromStrapi} from "./strapi/helpers"

export const getDiscountsMap = async (product: any) => {
	let discount = null
	const customInstanceDiscounts = {}

	const organizations = await fetchStrapiDataCollectionFromStrapi("de", [strapiCollections.organizations]).then((res:any) => res?.data?.organizations)

	for (const cat of product?.categories || []) {
		const splitCatSlug = cat.slug.split("_")
		const organizationData = getOrganizationDataFromStrapi(organizations, splitCatSlug[0])
		if (splitCatSlug.length === 2) {
			const number = splitCatSlug[1].replaceAll("-", ".")

			// Get custom instance slug
			if (splitCatSlug[0] === "sale") {
				discount = Number(number)
			} else if (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationData !== undefined) {
				customInstanceDiscounts[splitCatSlug[0]] = Number(number)
			} else if (splitCatSlug[0] === "custominstancesale" && process.env.NEXT_PUBLIC_CUSTOM_INSTANCE) {
				customInstanceDiscounts[splitCatSlug[0]] = Number(number)
			}
		}
	}

	return {
		discount,
		customInstanceDiscounts
	}
}

export const generateAwsUrl = (bucket: string, fileName: string) => {
	return `https://s3.eu-central-1.amazonaws.com/${bucket}/${fileName}`
}

export const downloadBikePDFfromAWS = async (bucket:string, fileName:string) => {
	const url = generateAwsUrl(bucket, fileName)

	const link = document.createElement("a")
	link.href = url
	link.download = fileName
	link.target = "_blank"

	document.body.appendChild(link)

	link.click()

	link.parentNode?.removeChild(link)
}

export const countryFilterSearch = (search: string, options: any) => options?.filter((option: any) => option?.value?.toLowerCase().includes(search.toLowerCase()) || option.label.toLowerCase().includes(search.toLowerCase()))

export const getCountryBy = (searchTerm: string, searchBy: string) => {
	return countryList.find(country => country[searchBy] === searchTerm)
}

export const getCountryValueByLabel = (label: string) => {
	return countryList.find(country => country.label === label)?.value
}

export const phonePrefixSearch = (search: string, options: any) => options.filter((option: any) => option.code.toLowerCase().includes(search?.toLowerCase()) ||
	option.dial_code.toLowerCase().includes(search?.toLowerCase()) ||
	option.dial_code_zero.toLowerCase().includes(search?.toLowerCase()) ||
	option.label.toLowerCase().includes(search?.toLowerCase()))

export const isOccasionBike = (bike: any) => Boolean(bike?.categories?.filter((category: any) => category.name === "Occasion")?.length > 0)

export type MenuTitlesArray = {
	name: string,
	items: MenuTitlesItem[]
}

export enum MenuTitlesItemType {
	category = "category",
	brand = "brand"
}

export const clampValue = (value, min, max) => {
	if (value < min) {
		return min
	}

	if (value > max) {
		return max
	}

	return value
}

type MenuTitlesItem = {
	name: string,
	href: string,
	src: string,
	imgDimensions: any
	type: MenuTitlesItemType
}
type MenuTitles = {
	desktop: MenuTitlesArray[],
	mobile: MenuTitlesArray[],
	brands: MenuTitlesItem[],
	collections: MenuTitlesItem[]
}
export const getMenuTitles = (collections: any[], brands: any[]): MenuTitles => {
	const _collections = transformContentCollections(collections)
	const _brands = transformContentBrands(brands)
	const collectionsArrays = splitArray(_collections)
	const brandsArray = splitArray(_brands)

	return {
		desktop:
			[
				{
					name: "Kategorien",
					items: collectionsArrays.firstHalf as MenuTitlesItem[]
				},
				{
					name: "blank",
					items: collectionsArrays.secondHalf as MenuTitlesItem[]
				},
				{
					name: "Marken",
					items: brandsArray.firstHalf as MenuTitlesItem[]
				},
				{
					name: "blankMarken",
					items: brandsArray.secondHalf as MenuTitlesItem[]
				}
			],
		mobile:
			[
				{
					name: "Kategorien",
					items: _collections
				},
				{
					name: "Marken",
					items: _brands
				}
			],
		brands: _brands,
		collections: _collections
	}
}

type SplitArray = {
	firstHalf: any[],
	secondHalf: any[]
}
export const splitArray = (array: any[]): SplitArray => {
	if (array) {
		const half = Math.ceil(array.length / 2)
		return {
			firstHalf: array.slice(0, half),
			secondHalf: array.slice(half)
		}
	}

	return {
		firstHalf: [],
		secondHalf: []
	}
}

export const transformContentCollections = (collections: any[]): any[] => {
	return collections?.sort((a, b) => b.fields.menuOrder || 0 - a.fields.menuOrder || 0).map(item => {
		const {menuLabel, filterSlug, icon} = item.fields

		return {
			name: menuLabel,
			href: `/${filterSlug}`,
			src: icon ? formatImageSrc(icon.image.data.attributes.url) : "/assets/icons/bike.svg",
			type: MenuTitlesItemType.category
		}
	})
}

export const transformContentBrands = (brands: any[]): any[] => {
	return brands?.sort((a, b) => b.fields.menuOrder || 0 - a.fields.menuOrder || 0).map(item => {
		const {menuLabel, slug, icon} = item.fields
		const image = icon?.fields?.file?.details?.image
		return {
			name: menuLabel,
			href: `/${slug}`,
			src: getImageUrl(icon) || "/assets/icons/bike.svg",
			imgDimensions: image ? {
				width: image.width,
				height: image.height
			} : null,
			type: MenuTitlesItemType.brand
		}
	})
}

export const getQueryParams = (asPath: string): any => {
	const checkArr = asPath.split("?")

	const paramObj = {}

	if (checkArr[1]) {
		const paramsArr = checkArr[1].split("&")

		paramsArr?.forEach(param => {
			const key = param.split("=")[0]
			const value = param.split("=")[1]

			paramObj[key] = value
		})

		return paramObj
	}

	return {
		at_gd: undefined
	}
}

export const fetchToken = async (): Promise<string> => {
	const res = await fetch("/api/getToken")
	const data = await res.json()
	return data.token
}

export const rotateArray = (arr, val, pos) => {
	// Set pos to 0 if moving val to first position, or 1 for last position
	arr = arr.concat(arr.splice(0, arr.indexOf(val) + pos))
	return arr
}

export const getImgDescriptionFormat = (description, t) => {
	const strArr = description.split(" ")
	const isValidArray = strArr.length >= 3
	const modelName = isValidArray ? strArr[0] : null
	const modelSizeStr = isValidArray ? strArr[2] : null
	const modelSize = modelSizeStr?.split("c")[0]
	const bikeSize = isValidArray ? strArr[strArr.length - 1] : null

	return modelSizeStr && isValidArray ? <div style={{zIndex: 999999}}
		className="text-center text-lg-start mb-2 mb-lg-3 py-1 py-lg-2 px-lg-2 d-flex align-items-center">
		<Typography variant={"bodyXSm"}
			semanticTag={"span"}
			style={{color: "#6C7074"}}>
			{`${modelName} ${t("img-label-text-1")}`}
			<b className="text-bold">{modelSize} cm </b>{t("img-label-text-2")}<b
				className="text-bold"> {bikeSize}</b>
		</Typography>
	</div> : null
}

export const isCategory = (bike, catSlug) => {
	return Boolean(bike?.categories?.find(cat => cat.slug === catSlug))
}

export const injectBikesCategoryToTheTop = (bikes:any[], categorySlug:string) => {
	const featuredBikes = []
	let updatedBikesList = []
	updatedBikesList = bikes.filter(bike => {
		if (bike?.categories?.some(category => category.slug === categorySlug)) {
			featuredBikes.push(bike)
			return false
		}

		return true
	})

	const result = featuredBikes.concat(updatedBikesList)

	return result
}

export const getStars = (number, starSize = 18) => {
	const componentArr = []
	const starsAmount = Number((number)?.toFixed(0)) || 0
	let i = 0
	while (i < starsAmount) {
		const starComponent = (
			<div className="col-auto"
				key={i}>
				<Image key={i}
					src={"/assets/icons/google-star-icon.svg"}
					width={starSize}
					height={starSize}/>
			</div>
		)
		componentArr.push(starComponent)
		i++
	}

	return componentArr
}

export const getNumberOfStars = starData => {
	if (starData === "ONE") {
		return 1
	}

	if (starData === "TWO") {
		return 2
	}

	if (starData === "THREE") {
		return 3
	}

	if (starData === "FOUR") {
		return 4
	}

	if (starData === "FIVE") {
		return 5
	}
}

export const filterFaqByCategory = (data, category) => {
	return data.filter(element => element.slug === category)
}

export const getFaqSlugCategory = locale => {
	const data = createFaqSlugs(locale)
	const arr = data.map(element => element.slug)
	const uArr = new Set(arr)
	return Array.from(uArr)
}

export const createFaqSlugs = locale => {
	const data = getFaqData(locale)
	return data.map(element => {
		const {category} = element
		const slug = slugify(category).toLowerCase()
		return {...element, slug}
	})
}

export const getFaqCategory = locale => {
	const data = getFaqData(locale)
	const arr = data.map(element => element.category)
	const uArr = new Set(arr)
	return Array.from(uArr)
}

export const isEmpty = object => {
	return Object.keys(object).length === 0
}

export const getCategoryBySlug = (bike, slug) => {
	return bike?.categories?.find(e => e.slug === slug)
}

export const findBikeAttributeByName = (bike, attributeName, single = false, singularOption = false) => {
	if (single) {
		return bike?.attributes?.find(e => e.name === attributeName)?.terms?.[0].name || bike?.attributes?.find(e => e.name === attributeName)?.option
	}

	if (singularOption) {
		const option = bike?.attributes?.find(e => e.name === attributeName)?.terms?.[0].name || bike?.attributes?.find(e => e.name === attributeName)?.option
		if (option) {
			return option
		}

		return "No Data"
	}

	return bike?.attributes?.find(e => e.name === attributeName)?.terms.map(term => term.name)
}

export const findBikeAttributeBySlug = (bike, attributeSlug, single = false, singularOption = false) => {
	if (single) {
		return bike?.attributes?.find(e => e.slug === attributeSlug)?.terms?.[0].name || bike?.attributes?.find(e => e.slug === attributeSlug)?.option
	}

	if (singularOption) {
		const option = bike?.attributes?.find(e => e.slug === attributeSlug)?.terms?.[0].name || bike?.attributes?.find(e => e.slug === attributeSlug)?.option
		if (option) {
			return option
		}

		return "No Data"
	}

	return bike?.attributes?.find(e => e.slug === attributeSlug)?.terms.map(term => term.name)
}

export const findBikeMetaFieldByName = (bike, fieldName) => bike?.meta_data?.find(e => e?.key === fieldName)?.value

export const idsParams = (ids: any) => {
	return ids.join(",")
}

const productPageTabsTranslate = {
	ubersicht: "vue-d'ensemble",
	einzelheiten: "details",
	beschreibung: "description",
	"vue-d'ensemble": "ubersicht",
	details: "einzelheiten",
	description: "beschreibung"
}
const consultationPageTabsTranslate = {
	motor: "le-moteur",
	"le-moteur": "motor",
	akku: "la-batterie",
	"la-batterie": "akku",
	"e-bike-rahmen": "le-cadre",
	"le-cadre": "e-bike-rahmen",
	"display-bordcomputer": "l'ordinateur-de-bord",
	"l'ordinateur-de-bord": "display-bordcomputer",
	federung: "les-suspensions",
	"les-suspensions": "federung",
	gangschaltung: "le-levier-de-vitesse",
	"le-levier-de-vitesse": "gangschaltung"
}
const kundendienstTabsTranslate = {
	"allgemeine-fragen": "general-questions",
	"general-questions": "allgemeine-fragen",
	"bike-zurückgeben": "return-bike",
	"return-bike": "bike-zurückgeben",
	schaden: "bike-damage",
	"bike-damage": "schaden"
}
export const localisedPath = (url, t, path, paramItemsOverride = null, isContentful = false, contentfulSlugs = []) => {
	const pathParts = path.split("/")
	const translationHash = pathParts[1] === "bike-verzeichnis" ? consultationPageTabsTranslate : pathParts[1] === "kundendienst" ? kundendienstTabsTranslate : productPageTabsTranslate
	const localisedParts = []

	pathParts?.forEach(item => {
		if (item !== "") {
			const itemIsRouteParam = isContentful ? contentfulSlugs.includes(item) : item?.[0] === "[" && item[item.length - 1] === "]"
			if (itemIsRouteParam) {
				const overrideItem = Array.isArray(paramItemsOverride) ? paramItemsOverride?.shift() : paramItemsOverride
				const key = translationHash[overrideItem] ? translationHash[overrideItem] : overrideItem
				localisedParts.push(key || "-")
			} else {
				localisedParts.push(item.split(delimiter).map(el => {
					if (el.includes("?")) {
						const queryParts = el.split("?")
						if (queryParts.length === 2) {
							return [
								isContentful ? paramItemsOverride : queryParts?.[0],
								queryParts[1].split("&").map(el => {
									const params = el.split("=")
									if (params.length === 2) {
										return [
											params?.[0],
											t(params[1], {ns: "URL"})
										].join("=")
									}

									return el
								}).join("&")
							].join("?")
						}

						return t(el, {ns: "URL"})
					}

					return t(el, {ns: "URL"})
				}).join(delimiter))
			}
		}
	})

	const resultingUrl = `/${localisedParts.join("/")}`

	return resultingUrl
}

export const dealPlanLengthFormater = planLength => {
	if (typeof planLength !== "number") {
		const length = planLength.split(" ")[0]

		return length + " Months"
	}

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const correctPlanLength = planLength ? planLengthMap ? planLengthMap[planLength] : planLength : 48

	return correctPlanLength + " Months"
}

export const getTitle = category => {
	const {t} = useTranslation()
	const title = category === t("e-mountain") ?
		t("Mountain E-Bikes") :
		category === t("e-trekking") ?
			t("Trekking E-Bikes") :
			category === t("e-urban") ?
				t("Urban E-Bikes") :
				category === t("damen-e-bikes") ?
					t("Damen E-Bikes") :
					category === t("herren-e-bikes") ?
						t("Herren E-Bikes") :
						category === t("wave-e-bikes") ?
							t("Die Rahmenform Wave") :
							category === t("trapez-e-bikes") ?
								t("Die Rahmenform Trapez") :
								category === t("diamant-e-bikes") ?
									t("Die Rahmenform Diamant") :
									category === t("25-km-e-bikes") ?
										t("S PEdelecs bis 25 km/h") :
										category === t("45-km-e-bikes") ?
											t("S Pedelecs bis 45 km/h") :
											t("Alle E-Bikes")

	return title
}

export const getBackgroundImage = category => {
	const {t} = useTranslation()
	const backgroundImage = category === t("e-mountain") ?
		"url('/assets/images/focus-bikes-bosch-e-mtb-e-is-for-everyone-jam_-sun_1-1.png')" :
		category === t("e-trekking") ?
			"url('/assets/images/cyclist-riding-bike-sunset-mountain-road-1-3.png')" :
			category === t("e-urban") ?
				"url('/assets/images/wolfram-bolte-yqCRZzc49h8-unsplash-1-1.png')" :
				category === t("damen-e-bikes") ?
					"url('/assets/images/damen-cover.png')" :
					category === t("herren-e-bikes") ?
						"url('/assets/images/herren-cover.png')" :
						category === t("wave-e-bikes") ?
							"url('/assets/images/wave-cover.png')" :
							category === t("trapez-e-bikes") ?
								"url('/assets/images/trapez-cover.png')" :
								category === t("diamant-e-bikes") ?
									"url('/assets/images/diamant-cover.png')" :
									category === t("25-km-e-bikes") ?
										"url('/assets/images/25-km-cover.png')" :
										category === t("45-km-e-bikes") ?
											"url('/assets/images/45-km-cover.png')" :
											"url('/assets/images/header-inventory.png')"

	return backgroundImage
}

export const isDevelopment = () => {
	return process.env.NEXT_PUBLIC_ENVIRONMENT === "development" || process.env.NEXT_PUBLIC_ENVIRONMENT === "local"
}

export const isProduction = () => {
	return process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
}

export const calculateInsurance = totalAmount => {
	const pricing = [
		{
			level: 1000,
			amount: 73
		},
		{
			level: 1500,
			amount: 73
		},
		{
			level: 2000,
			amount: 93
		},
		{
			level: 2500,
			amount: 111
		},
		{
			level: 3000,
			amount: 126
		},
		{
			level: 3500,
			amount: 144
		},
		{
			level: 4000,
			amount: 160
		},
		{
			level: 4500,
			amount: 173
		},
		{
			level: 5000,
			amount: 188
		},
		{
			level: 5500,
			amount: 219
		},
		{
			level: 6000,
			amount: 237
		},
		{
			level: 6500,
			amount: 253
		},
		{
			level: 7000,
			amount: 274
		},
		{
			level: 7500,
			amount: 285
		},
		{
			level: 8000,
			amount: 299
		},
		{
			level: 8500,
			amount: 317
		},
		{
			level: 9000,
			amount: 330
		},
		{
			level: 9500,
			amount: 333
		},
		{
			level: 10000,
			amount: 336
		}
	]
	let pricingLevel = pricing[0]
	const targetAmount = Math.round(totalAmount / 500) * 500
	pricing?.forEach(item => {
		if (item.level.toFixed() === targetAmount.toFixed()) {
			pricingLevel = item
		}
	})
	return pricingLevel.amount / 12
}

export const removeUndefined = object => Object.keys(object)?.reduce((result, key) => (

	![undefined, null].includes(object[key]) ?
		{...result, [key]: object[key]} :
		result
), {})

export const fetchCheckoutDoc = async (db, checkoutDocId) => {
	try {
		const {doc, getDoc} = await import("@firebase/firestore/lite")
		const docRef = doc(db, Collections.checkOuts, checkoutDocId as string)
		const docData = await getDoc(docRef)
		return {
			...docData.data(),
			id: docData.id
		} as any
	} catch (e) {
		console.error(e)
		return null
	}
}

export const sortByRelevance = products => {
	const customSort = (a, b) => {
		const relevanceA = a.relevance === undefined || a.relevance === "" ? 0 : a.relevance
		const relevanceB = b.relevance === undefined || b.relevance === "" ? 0 : b.relevance

		return relevanceB - relevanceA || b.views - a.views || b.slug.localeCompare(a.slug) // Sort in descending order
	}

	const sortedProducts = products.sort(customSort)

	return sortedProducts
}

export const getSortedOrderLabel = (params, t) => {
	if (!params.sortOrder) {
		return t("Relevanz")
	}

	const sortOrderMap = {
		aufsteigend: t("Günstigste"),
		"-": t("Günstigste"),
		[t("gunstigste")]: t("Günstigste"),
		[t("teuerste")]: t("Teuerste"),
		[t("sale")]: t("Sale"),
		[t("neuestes")]: t("Neuestes"),
		[t("relevanz")]: t("Relevanz"),
		[t("pertinence")]: t("Relevanz")
	}

	return sortOrderMap[String(params.sortOrder)] || params.sortOrder || t("Günstigste")
}

export const getPriceAfterDiscount = (discount: string, afterDownPaymentPrice: number) => {
	if (discount) {
		return afterDownPaymentPrice - parseFloat(discount) <= 0 ? 0 : afterDownPaymentPrice - parseFloat(discount) || null
	}

	return afterDownPaymentPrice
}

export const getMatchingColorsCount = (bike1: BikeType, bike2: BikeType) => {
	let matchingColorCount = 0
	bike1?.colorData?.forEach(color => bike2?.colorData?.forEach(colorToMatchAgainst => {
		if (color.color === colorToMatchAgainst.color) {
			matchingColorCount++
		}
	}))

	return matchingColorCount
}

export const getAttributeRelevanceScores = (bike1: BikeType, bike2: BikeType) => {
	let matchingSizesCount = 0
	let batterySizesMatch = false
	let SpeedsMatch = false
	let wheelSizesMatch = false
	let sameBrand = false
	const bike1Attributes = []
	const bike2Attributes = []
	bike1?.attributes?.forEach(attribute => {
		bike1Attributes[attribute?.slug] = attribute
	})
	bike2?.attributes?.forEach(attribute => {
		bike2Attributes[attribute?.slug] = attribute
	})
	// eslint-disable-next-line dot-notation
	if (bike1Attributes["sizes"]?.terms && bike2Attributes["sizes"]?.terms) {
		// eslint-disable-next-line dot-notation
		bike1Attributes["sizes"]?.terms?.forEach(size => {
			// eslint-disable-next-line dot-notation
			bike2Attributes["sizes"]?.terms?.forEach(matchAgainst => {
				if (size === matchAgainst) {
					matchingSizesCount++
				}
			})
		})
	}

	if (bike1Attributes["battery-size"]?.terms && bike2Attributes["battery-size"]) {
		batterySizesMatch = Number(bike1Attributes["battery-size"]?.terms[0]?.name.split(" ")[0]) === Number(bike2Attributes["battery-size"]?.terms[0]?.name.split(" ")[0])
	}

	// eslint-disable-next-line dot-notation
	if (bike1Attributes["speed"]?.terms && bike2Attributes["speed"]?.terms) {
		// eslint-disable-next-line dot-notation
		SpeedsMatch = Number(bike1Attributes["speed"]?.terms?.[0]?.name.split(" ")[0]) === Number(bike2Attributes["speed"]?.terms?.[0]?.name.split(" ")[0])
	}

	if (bike1Attributes["wheel-size"] && bike2Attributes["wheel-size"]) {
		wheelSizesMatch = Number(bike1Attributes["wheel-size"]?.terms?.[0].name) === Number(bike2Attributes["wheel-size"]?.terms?.[0].name)
	}

	// eslint-disable-next-line dot-notation
	if (bike1Attributes["brand"] && bike2Attributes["brand"]) {
		// eslint-disable-next-line dot-notation
		sameBrand = bike1Attributes["brand"]?.terms?.[0]?.name === bike2Attributes["brand"]?.terms?.[0]?.name
	}

	return {matchingSizesCount, batterySizesMatch, SpeedsMatch, wheelSizesMatch, sameBrand}
}

export const getSimilarProducts = (mainBike: BikeType, bikes: BikeType[], similarBikesCount: number) => {
	const categoryArray = getMainCategoryForRecommendationsAlgo(mainBike?.categories)
	// To not always loop in one direction making the suggestions always similar to each other these next lines are to change the flow and pick different bikes the algo might not have reached otherwise
	const coinFlip = Math.round(Math.random())
	if (coinFlip) {
		bikes = bikes.reverse()
	}

	const isAccessory = categoryArray?.some(e => e.slug === categorySlugs.accessories)
	const isSecondHandBike = isOccasionBike(mainBike)
	let categoricallySimilarBikesCount = similarBikesCount
	const categoricallySimilarBikes = []
	bikes?.forEach(bike => {
		const bikeCategoryArray = getMainCategoryForRecommendationsAlgo(bike?.categories)
		const shouldBeExcluded = bikeShouldBeExcluded(mainBike, bike, categoricallySimilarBikes)
		const SimilarCategory = categoryArray.every(e => bikeCategoryArray.some(cat => e.slug === cat.slug))

		if (!shouldBeExcluded && (SimilarCategory || isAccessory)) {
			const priceIsInRange = bike.uvpPrice > mainBike.uvpPrice - 1000 && mainBike.uvpPrice + 1000 < bike.uvpPrice
			const matchingBikeColors = isAccessory ? 0 : getMatchingColorsCount(mainBike, bike)
			const matches = getAttributeRelevanceScores(mainBike, bike)
			let currentProductRelevance = 0

			if (isSecondHandBike) {
				const secondHandMatch = isOccasionBike(bike)
				currentProductRelevance += secondHandMatch ? 40 : 0
			}

			if (SimilarCategory) {
				currentProductRelevance += 45
			}

			if (matchingBikeColors > 0) {
				currentProductRelevance += (5 * matchingBikeColors)
			}

			if (matches.matchingSizesCount > 0) {
				currentProductRelevance += (2.5 * matchingBikeColors)
			}

			if (matches.batterySizesMatch || matches.SpeedsMatch || matches.wheelSizesMatch) {
				currentProductRelevance += 5
			}

			if (matches.sameBrand) {
				currentProductRelevance += 15
			}

			if (priceIsInRange) {
				currentProductRelevance += 35
			}

			if (bike && ((priceIsInRange || matches.sameBrand || currentProductRelevance > 60) || isAccessory) && categoricallySimilarBikesCount > 0) {
				const {description, short_description, variation_objects, upsell_ids, staticVariations, color_data, cross_sell_ids, variations, crossSellProducts, date_created, ...product} = bike
				categoricallySimilarBikes.push({...product, score: currentProductRelevance})
				categoricallySimilarBikesCount--
			}

			if (categoricallySimilarBikesCount === 0) {
				return categoricallySimilarBikes.sort((a, b) => b.score - a.score || b.uvpPrice - a.uvpPrice)
			}
		}
	})

	const result = categoricallySimilarBikes.sort((a, b) => b.score - a.score || b.uvpPrice - a.uvpPrice)
	return result
}

const bikeShouldBeExcluded = (mainBike: BikeType, bike: BikeType, similarBikes: BikeType[]) => {
	// This line will check to see if the bike we're at right now is a variation of the main bike in the similar bikes function so we exclude it
	const shouldBeExcluded = Boolean(bike?.id === mainBike?.id || mainBike?.relatedAttributeBatterySizes?.some(relation => relation?.id === bike.id || relation?.slug === bike?.slug) || mainBike?.relatedCategoryFrames?.some(relation => relation?.id === bike.id || relation?.slug === bike?.slug) || mainBike?.relatedMotorStrength?.some(relation => relation?.id === bike.id || relation?.slug === bike?.slug) || mainBike?.colorData?.some(relation => relation?.id === bike.id || relation?.slug === bike?.slug))

	if (!shouldBeExcluded) {
		// This Loop will go over all the similar Bikes we already stored previously to see if, the current bike is a variation of any of them so we exclude it from the search
		for (const similarBike of similarBikes) {
			if (similarBike?.id === bike?.id || bike?.relatedAttributeBatterySizes?.some(relation => relation?.id === similarBike.id || relation?.slug === similarBike?.slug) || bike?.relatedCategoryFrames?.some(relation => relation?.id === similarBike.id || relation?.slug === similarBike?.slug) || bike?.relatedMotorStrength?.some(relation => relation?.id === similarBike.id || relation?.slug === similarBike?.slug) || bike?.colorData?.some(relation => relation?.id === similarBike.id || relation?.slug === similarBike?.slug)) {
				return true
			}
		}
	}

	return shouldBeExcluded
}

const getMainCategoryForRecommendationsAlgo = categories => {
	const mainCategories = []

	if (!categories) {
		return mainCategories
	}

	for (let i = 0; i < mainCategoriesForTheSimilarBikeAlgo.length; i++) {
		for (const category of categories) {
			if (category?.slug === mainCategoriesForTheSimilarBikeAlgo?.[i]?.slug) {
				mainCategories.push(category)
			}
		}
	}

	return mainCategories
}

const mainCategoriesForTheSimilarBikeAlgo = [
	{
		name: "45 km",

		slug: "45-km"
	},
	{
		name: "25 km",
		slug: "25-km"
	},
	{
		name: "E-Trekking",
		slug: "e-trekking"
	},
	{
		name: "E-Mountain",
		slug: "e-mountain"
	},
	{
		name: "E-Urban",
		slug: "e-urban"
	},
	{
		name: "Accessories",
		slug: "zubehoer"
	},
	{
		name: "Accessories",
		slug: "accessories-fr"
	},
	{
		name: "Road Bikes",
		slug: "road-bikes"
	},
	{
		name: "Gravel Bikes",
		slug: "gravel-bikes"
	},
	{
		name: "Fully Velo",
		slug: "mountain-fully"
	},
	{
		name: "Hardtail Velo",
		slug: "hardtail-non-electric"
	},
	{
		name: "Swiss Made",
		slug: "swiss-made"
	},
	{
		name: "Bio-Bikes_Featured",
		slug: "bio-bikes-featured"
	}
]

export const createRecommendationsList = (recentlyViewedBikes: BikeType[], previouslyRecommendedBikes: BikeType[], recommendationsLimit = 30) => {
	const recommendationsList = previouslyRecommendedBikes
	const maxRecommendationsPerBike = Math.round(recommendationsLimit / recentlyViewedBikes.length)
	let totalToRecommend = recommendationsLimit - recommendationsList?.length
	for (const viewedBike of recentlyViewedBikes) {
		const isAccessory = viewedBike?.categories?.some(cat => cat.slug === "zubehoer" || cat.slug === "accessoires")
		let iterations = maxRecommendationsPerBike
		if (totalToRecommend >= 0 && !isAccessory && viewedBike?.similarBikes?.length) {
			for (let i = 0; i < iterations && i < viewedBike?.similarBikes.length; i++) {
				const isAlreadyRecommended = recommendationsList.some(recommendation => recommendation.id === viewedBike?.similarBikes?.[i]?.id) || recentlyViewedBikes.some(viewed => viewed.id === viewedBike?.similarBikes?.[i]?.id)
				if (isAlreadyRecommended) {
					iterations++
					continue
				}

				recommendationsList.push(viewedBike.similarBikes[i])
				totalToRecommend--
			}
		} else {
			break
		}
	}

	const result = recommendationsList.length < recommendationsLimit ? sortByRelevance(recommendationsList).filter(bike => bike?.uvpPrice) : sortByRelevance(recommendationsList)?.filter(bike => bike?.uvpPrice)?.slice(0, recommendationsLimit)
	return result
}

export const getItemsToHideInComparisonFromBike = (currentBike: BikeType, attributesForComparison) => {
	const attributesToHide = []
	const colorsToHide = currentBike?.colorData?.map(color => color.color)
	for (const attributeToHide of attributesForComparison) {
		// @ts-ignore
		 attributesToHide[attributeToHide] = currentBike?.attributes?.find(attribute => attribute?.slug === attributeToHide)
	}

	return {[currentBike.id]: {colors: colorsToHide, ...attributesToHide}}
}

export function stripBackslashes(str) {
	return str.replace(/\\/g, "")
}

export const parseJsonColorData = (str: string) => {
	if (!str) {
		return null
	}

	try {
		const colorDataStr = stripBackslashes(str).slice(2, -2)
		const jsonStringToParse = fixJsonString(colorDataStr)
		return JSON.parse(jsonStringToParse)
	} catch (error) {
		console.error(error, str)
		return null
	}
}

function fixJsonString(jsonString) {
	// The color_data json string has many problems that are fixed in this function, like trailing whitespace, extra double quotes etc...
	jsonString = `[${jsonString}]`

	jsonString = jsonString.replace(/"\s*},\s*"/g, "},")
	jsonString = jsonString.replace(/}"\s*,\s*"/g, "},")
	jsonString = jsonString.replace(/"\s*{\s*/g, "{")
	jsonString = jsonString.replace(/\s*"\s*}\s*"/g, "}")

	return jsonString
}

export const handleMultiplePriceRanges = param => {
	if (param.includes(",")) {
		const priceRangeParam = param.replace(",", priceRangeDelimiter)
		const priceRangeArray = priceRangeParam.split(priceRangeDelimiter).sort((a, b) => Number(b) - Number(a))
		const priceRange = `${priceRangeArray[priceRangeArray.length - 1]}${priceRangeDelimiter}${priceRangeArray[0]}`
		return priceRange
	}

	return param
}

export const getAttributeRelations = (product, products, metaKey, attrSlug) => {
	const relatedSizesMetaField = product?.meta_data?.find(item => item.key === metaKey)

	const relatedSizesProducts = ((relatedSizesMetaField && Boolean(JSON.parse(relatedSizesMetaField.value || "[]").length)) ? products.filter(item => JSON.parse(relatedSizesMetaField.value)?.map(id => Number(id)).includes(item.id)) : [])
	const relations = []

	// Iterate over relatedSizesProducts and extract relevant information
	relatedSizesProducts?.forEach(relation => {
		const displayAttribute = relation.attributes?.find(attr => attr.slug === attrSlug)
		if (displayAttribute?.terms?.length) {
			relations.push({
				id: relation.id,
				slug: relation.slug,
				label: displayAttribute.terms[0].name
			})
		}
	})

	return relations
}

export const formatPrice = price => {
	const formattedPrice = new Intl.NumberFormat("de-CH", {
		style: "currency",
		currency: "CHF",
		minimumFractionDigits: 0
	}).format(price)

	return formattedPrice
}

export const fetchRecentlyViewedBikesAndStoreInState = (uid:string, locale, setRecentlyViewedBikes:Dispatch<SetStateAction<BikeType[]>>, setIsLoading:Dispatch<SetStateAction<boolean>>) => {
	let storedRecentlyViewed = []
	if (uid) {
		fetchGet(`/api/products/recently-viewed/${uid}?locale=${locale}`).then(response => response.json()).then(data => {
			if (data?.success) {
				storedRecentlyViewed = data?.data || []
			}

			const recentlyViewed = guardRecentlyViewedBikesType(storedRecentlyViewed)
			setRecentlyViewedBikes(() => recentlyViewed)
			setIsLoading(false)
			window.dispatchEvent(new Event("RecentlyViewedUpdated"))
		})
	}
}

export function timeLeftToReach3Days(updatedAt) {
	const updatedAtDate = new Date(updatedAt)
	const currentDate = new Date()
	// @ts-ignore
	const timeElapsed = currentDate - updatedAtDate
	const threeDaysInMs = 3 * 24 * 60 * 60 * 1000
	const timeLeftInMs = threeDaysInMs - timeElapsed
	const timeLeftInHours = timeLeftInMs / (60 * 60 * 1000)

	return timeLeftInHours > 0 ? timeLeftInHours : 0
}

export const isSaleCategory = (categoriesArray, locale, activeMainCategorySlug) => {
	const saleCategory = categoriesArray[locale]?.find(cat => cat.categoryType === "sale")
	return saleCategory && saleCategory.slugName === activeMainCategorySlug
}

export const logger = {
	log: (...args) => {
		if (isDevelopment()) {
			console.log(...args)
		}
	},
	error: (...args) => {
		if (isDevelopment()) {
			console.error(...args)
		}
	},
	warn: (...args) => {
		if (isDevelopment()) {
			console.warn(...args)
		}
	},
	info: (...args) => {
		if (isDevelopment()) {
			console.info(...args)
		}
	},
	debug: (...args) => {
		if (isDevelopment()) {
			console.debug(...args)
		}
	}
}

export function extractJsonArray(inputString) {
	try {
	  const cleanedString = inputString
			.replace(/\\n/g, "")
			.replace(/\n/g, "")
			.replace(/\s+/g, " ")
			.trim()

	  const jsonArray = JSON.parse(cleanedString)

	  return jsonArray
	} catch (error) {
	  console.error("Error parsing the string as JSON:", error.message)
	  return null
	}
}

export async function storeDataToSpreadsheet(
	data: { question: string, answer: string, bike?: string, url?:string},
	source: aiQuestionSource = "pdpAi") {
	if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "production") {
		return null
	}

	try {
		await fetchPost(`/api/store-spreadsheet?source=${source}`, data, process.env.MBP_API_KEY)
	} catch (error) {
		logger.error(error)
	}
}

export function extractAllStaticTiles(data: premiumBrandWidgetsType, extractedField = "staticTiles"): StaticTile[] | LiveNotification[] | any[] {
	return data?.reduce((accumulator: StaticTile[], currentItem: DataItem) => {
	  if (currentItem.attributes?.[extractedField]?.length) {
			return [...accumulator, ...currentItem.attributes?.[extractedField]]
	  }

	  return accumulator
	}, []) || []
}


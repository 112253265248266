import DoubleClick from "../doubleClick/DoubleClick"
import ProgressBar from "./ProgressBar"
import StoryCta from "./StoryCta"
import {useSwipeable} from "react-swipeable"
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import {StoryItem} from "../../context/StoryContext"
import LayoutContext from "../../context/LayoutContext"
import StoryFeaturedBike from "./StoryFeaturedBike"
import Loading from "./Loading"
import Image from "next/image"
import {fetchPost} from "../../firebaseAdmin/fetchPost"
import useCache from "../../hooks/useCache"
import {startConfettiAnimation} from "../reusable/StartConfettiAnimation"
import Typography from "../reusable/Typography/Typography"
import {VolumeMuteFill} from "react-bootstrap-icons"

type Props = {
    player: HTMLVideoElement
    setLoading: Dispatch<SetStateAction<boolean>>
    setInfo: Dispatch<SetStateAction<any>>
    setBikeFetched: Dispatch<SetStateAction<boolean>>
    setIsLiked?: Dispatch<SetStateAction<boolean>>
    next:(swiped?:boolean)=>void
    bikeFetched:boolean
    loading:boolean
	previous: (swiped?: boolean) => void
	isSeen?: boolean
	isLiked?:boolean
    story: StoryItem
	stories: StoryItem[]
	className: string
	playerRef?: React.MutableRefObject<any>
	videoContainerRef?:React.MutableRefObject<any>
}
export const isImage = (url: string) => {
	return /\.(jpeg|jpg|gif|png|webp|bmp|svg|tiff)$/i.test(url)
}

const YoutubeVideoContainer = React.forwardRef(({player, isSeen, setIsLiked, isLiked, videoContainerRef, bikeFetched, className, setBikeFetched, setLoading, loading, next, story, previous, stories, setInfo}: Props, ref:React.RefObject<HTMLVideoElement>) => {
	const {isMobile} = useContext(LayoutContext)
	const [isVideoMuted, setIsVideoMuted] = useState<boolean>(true)

	// Swipeable handlers
	const handlers = useSwipeable({
		onSwipedLeft: () => next(true),
		onSwipedRight: () => previous(true)
	})

	const [refCreated, setRefCreated] = useState<boolean>(false)

	const onEnded = () => {
		next()
	}

	const {data, isLoading, mutate} = useCache(`/api/firestore/get-story-interactions/${story.id}`)
	const onCanPlay = () => {
		setLoading(false)
	}

	const handleLike = async () => {
		if (!isLiked) {
			const storiesState = JSON.parse(localStorage.getItem("likedStories") || "[]")
			localStorage.setItem("likedStories", JSON.stringify({...storiesState, [story?.id]: {isLiked: true}}))
			window.dispatchEvent(new Event("sessionStorage"))
			setIsLiked(() => true)
			await fetchPost("/api/firestore/add-story-interaction?isLike=true", {
				storyId: story.id
			})
			startConfettiAnimation()
		}

		mutate()
	}

	useEffect(() => {
		if (player && !isImage(story.videoUrl)) {
			player.play()
		}
	}, [player, story, refCreated])

	useEffect(() => {
		setRefCreated(true)
	}, [])

	const onVideoCenterClick = () => {
		try {
			if (player) {
				if (player.paused) {
					player.play()
				} else {
					player.pause()
				}
			}
		} catch (error) {
			console.error("Error toggling video play/pause:", error)
		}
	}

	const onLoadedMetaData = () => {
		setInfo({
			duration: player?.duration || 0,
			currentTime: 0
		})
		onCanPlay()
	}

	const onTimeUpdate = () => {
		setInfo(prevState => ({
			...prevState,
			currentTime: player.currentTime
		}))
	}

	const handleVideoClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		try {
			if (player) {
				player.muted = false
				player.currentTime = 0
				setIsVideoMuted(false)
			}
		} catch (error) {
			console.error("Error handling video click:", error)
		}
	}

	return (<div className={`video-container col-12 d-flex align-items-center
	 flex-column justify-content-center px-4  gap-4 col-md-6 position-relative ${className}`}
	{...handlers}>
		<div ref={videoContainerRef}
			className={"ratio ratio-9x16 overflow-hidden position-relative shadow"}
			style={{
				borderRadius: isMobile ? 0 : 15,
				overflow: "none",
				transform: "rotatX(90)",
				opacity: 1,
				width: isMobile ? "100dvw" : "26dvw",
				height: isMobile ? "100dvh" : "82dvh"
			}}>
			{isImage(story.videoUrl) ? (
				<img
					src={story.videoUrl}
					alt="Story content"
					className="w-100 h-100"
					style={{objectFit: "cover"}}
				/>
			) : (
				<>{isVideoMuted && isMobile ? <div onClick={handleVideoClick}
					style={{inset: "50%", transform: "translate(-50%)", zIndex: "999999999", maxHeight: "84px"}}
					className="position-absolute gap-3 d-flex
						 flex-column align-items-center justify-content-center">
					<div style={{width: "44px", backgroundColor: "#e5ecf4", height: "44px", opacity: 0.6}}
						className=" d-flex align-items-center justify-content-center rounded-circle ">
						<VolumeMuteFill size={24} />
					</div>
					<Typography style={{whitespace: "nowrap"}}
						className="text-primary whitespace-nowrap"
						variant="bodySmBold">Tap to unmute</Typography></div> : null}
				<video className="video"
					muted={isMobile && isVideoMuted}
					playsInline
					ref={ref}
					src={story.videoUrl}
					onLoadedMetadata={onLoadedMetaData}
					onTimeUpdate={onTimeUpdate}
					onEnded={onEnded}
					onCanPlay={onCanPlay} /></>
			)}
			{loading && !isImage(story.videoUrl) && <Loading/>}
			<DoubleClick className={"position-absolute top-0 start-0 bottom-0"}
				style={{
					width: "40%"
				}}
				onClick={previous} />
			<DoubleClick className={"position-absolute top-0 bottom-0"}
				style={{
					left: "40%",
					right: "40%",
					width: "20%"
				}}
				onClick={onVideoCenterClick} />

			<DoubleClick className={"position-absolute top-0 bottom-0 end-0"}
				style={{
					left: "60%",
					width: "40%"
				}}
				onClick={next} />

			<div onClick={e => {
				e.stopPropagation()
			}}
			className="position-absolute d-flex flex-column align-items-center gap-3"
			style={{
				left: "90%",
				top: isMobile ? "60%" : "70%",
				height: "115px",
				width: "28px",
				zIndex: "9800000"
			}}>
				<div className="d-flex flex-column align-items-center"
					style={{gap: "4px"}}>
					<Image src="/assets/icons/eye.svg"
						width={24}
						height={24}
						alt="story views" />
					<Typography variant="bodySm"
						style={{fontSize: "12px", lineHeight: "16px", color: "white"}}> {!isLoading && data?.data ?
							data?.data?.views || 0 : ""}</Typography>
				</div>
				<div onClick={handleLike}
					className="d-flex cursor-pointer flex-column align-items-center"
					style={{gap: "4px"}}>
					{isLiked ? <Image src="/assets/icons/story-heart-liked.svg"
						width={24}
						className="story-like"
						height={24}
						alt="story likes"/> : <Image src="/assets/icons/story-heart.svg"
						width={24}
						height={24}
						alt="story likes"/>}
					<Typography variant="bodySm"
						style={{fontSize: "12px", lineHeight: "16px", color: "white"}}>
						{!isLoading && data?.data ?
							data?.data?.likes || 0 : ""}
					</Typography>
				</div>
			</div>

			<ProgressBar />
			{isMobile ? <StoryCta story={story} /> : null}
			{story?.featuredBike?.[0]?.slug && isMobile ?
				<StoryFeaturedBike className={`${bikeFetched ? "slide-up" : ""}`}
					setBikeFetched={setBikeFetched}
					slug={story?.featuredBike?.[0]?.slug} />									:
				null}
		</div>
		{isMobile ? null : <StoryCta story={story} />}
	</div>
	)
})
YoutubeVideoContainer.displayName = "YoutubeVideoContainer"

export default YoutubeVideoContainer


import Image from "next/image"
import {useRouter} from "next/router"
import Typography from "../../reusable/Typography/Typography"
import CartIndicator from "../../reusable/CartIndicator"
import React, {useContext} from "react"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

type Props = {
    href?: string
    onClick?:(e:any)=>void
    imgSrc: string
    label:string
	isActive: boolean
	isCart?:boolean
}
function NavTabLink({href, imgSrc, label, onClick, isActive = false, isCart = false}:Props) {
	const router = useRouter()
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<div onClick={e => {
			reactGA?.event({
				category: gaCategories.mobileNavigationTab,
				action: gaEvents.mobileNavigationTab,
				label: `Open navigation tab: ${label}`,
				nonInteraction: false
			})
			if (href) {
				router.push(href)
			} else {
				onClick(e)
			}
		}}
		style={{position: "relative"}}
		className={`tab-link ${isActive ? "active" : ""}`}>
			<Image src={imgSrc}
				width={24}
				height={24} />
			<Typography variant="bodySmBold"
				style={{color: "#6C7074", fontSize: "12px", lineHeight: "12px", fontWeight: 500}}>{label}</Typography>
			{isCart ? <CartIndicator isTab /> : null}
		</div>
	)
}

export default NavTabLink

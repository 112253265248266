import React, {Dispatch, SetStateAction, useContext} from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import {filterValueType, tabValueType} from "../SimilarBikesModal"
import {Attribute} from "../../../utility/Types"
import LayoutContext from "../../../context/LayoutContext"
import {filterButtons} from "../../../constants"

type Props = {
    setActiveTab: Dispatch<SetStateAction<tabValueType>>
    activeTab: tabValueType
    setActiveFilter: Dispatch<SetStateAction<filterValueType>>
    activeFilter: filterValueType
    attributes:Attribute[]
}

function SimilarBikesModalTabs({activeTab, setActiveTab, activeFilter, setActiveFilter, attributes}:Props) {
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	return (
		<div className={`similar-bikes-child-container-padding ${isMobile && activeTab !== "similar-attributes" ? "pb-0" : "pb-2"} col-12 d-flex align-items-start justify-content-start flex-column`}>
			{/* DESKTOP tabs */}
			<div className="col-12 d-none d-md-flex align-items-start justify-content-start">
				<Typography onClick={() => {
					window.dispatchEvent(new Event("resetBikes"))
					setActiveFilter(() => null)
					setActiveTab(() => "similar-attributes")
				}}
				variant={"bodyLg"}
				style={{padding: "8px 9px", fontSize: "16px", whiteSpace: "nowrap", lineHeight: "20px", fontWeight: activeTab === "similar-attributes" ? "700" : "500", borderBottom: activeTab === "similar-attributes" ? "2px solid #FEC04D" : "2px solid #DEE2E6"}}
				semanticTag={"div"}
				className={`mb-4 cursor-pointer ${activeTab === "similar-attributes" ? "text-black" : "text-secondary"}`}>
					{t("similar-attributes")}
				</Typography>
				<Typography onClick={() => {
					window.dispatchEvent(new Event("resetBikes"))
					setActiveTab(() => "same-brand")
					setActiveFilter(() => "brand")
				}}
				variant={"bodyLg"}
				style={{padding: "8px 9px", fontSize: "16px", whiteSpace: "nowrap", lineHeight: "20px", fontWeight: activeTab === "same-brand" ? "700" : "500", borderBottom: activeTab === "same-brand" ? "2px solid #FEC04D" : "2px solid #DEE2E6"}}
				semanticTag={"div"}
				className={`mb-4 cursor-pointer ${activeTab === "same-brand" ? "text-black" : "text-secondary"}`}>
					{t("same-brand")}
				</Typography>
				<div style={{transform: "translateY(1800%)"}}
					className={"col-5 flex-1 col-lg-7 "}>
					<div style={{borderTop: "2px solid #DEE2E6"}}
						className={" "}/>
				</div>
			</div>
			{/* Mobile tabs */}
			<div style={{marginTop: "-1rem"}}
				className="col-12  d-flex d-md-none align-items-start justify-content-center">
				<Typography onClick={() => {
					window.dispatchEvent(new Event("resetBikes"))
					setActiveFilter(() => null)
					setActiveTab(() => "similar-attributes")
				}}
				variant={"bodyLg"}
				style={{padding: "8px 9px", fontSize: "16px", whiteSpace: "nowrap", lineHeight: "20px", fontWeight: activeTab === "similar-attributes" ? "700" : "500", borderBottom: activeTab === "similar-attributes" ? "2px solid #FEC04D" : "2px solid #DEE2E6"}}
				semanticTag={"div"}
				className={`mb-4 col-6 text-center cursor-pointer ${activeTab === "similar-attributes" ? "text-black" : "text-secondary"}`}>
					{t("similar-attributes")}
				</Typography>
				<Typography onClick={() => {
					window.dispatchEvent(new Event("resetBikes"))
					setActiveTab(() => "same-brand")
					setActiveFilter(() => "brand")
				}}
				variant={"bodyLg"}
				style={{padding: "8px 9px", fontSize: "16px", whiteSpace: "nowrap", lineHeight: "20px", fontWeight: activeTab === "same-brand" ? "700" : "500", borderBottom: activeTab === "same-brand" ? "2px solid #FEC04D" : "2px solid #DEE2E6"}}
				semanticTag={"div"}
				className={`mb-4 cursor-pointer text-center col-6 ${activeTab === "same-brand" ? "text-black" : "text-secondary"}`}>
					{t("same-brand")}
				</Typography>
			</div>
			{/* Filtering buttons */}
			{activeTab === "similar-attributes" ? <div style={{width: "100%", overflowX: isMobile ? "scroll" : "hidden", marginTop: "-0.75rem"}}
				className="d-flex hide-overflow-scrollbar overflow-x-auto justify-content-start gap-2 align-items-center">
				{filterButtons.map(({filter, label, iconSrc}: { filter: filterValueType, label:string, iconSrc: string }, index) => {
					const attributeExists = attributes?.find(attribute => attribute.slug === filter)
					if (attributeExists) {
						return <div key={index}
							onClick={() => {
								window.dispatchEvent(new Event("resetBikes"))
								setActiveFilter(prev => prev === filter ? null : filter)
							}}
							style={{padding: "10px 16px", outline: "hidden", border: "1px solid #DEE2E6", borderRadius: "16px", background: activeFilter === filter ? "#E3E3E3" : "#fff", whiteSpace: "nowrap"}}
							className={"d-flex btn justify-content-center  align-items-center gap-2"} ><Image width={18}
								height={18}
								alt={t(filter)}
								src={iconSrc} /><Typography style={{fontSize: "14px", fontWeight: 500, lineHeight: "20px"}}
								variant="bodySmBold">{t(label)}</Typography></div>
					}

					return <React.Fragment key={index}></React.Fragment>
				})}
			</div> : null}
		</div>
	)
}

export default SimilarBikesModalTabs

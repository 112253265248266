import React, {useContext, useEffect, useMemo} from "react"
import {SignUpForConsultationBanner, WhyBuyFromMBPlan} from "./staticTiles"
import LayoutContext from "../../context/LayoutContext"
import {InsuranceTile} from "./staticTiles/InsuranceTile"
import GetCustomOfferTile from "./staticTiles/GetCustomOfferTile"
import CreditCheckTile from "./staticTiles/CreditCheckTile"
import CreditCheckWishList from "../wishList/CreditCheckWishList"
import RyderAiStaticTile from "./staticTiles/RyderAiStaticTile"
import DataContext from "../../context/DataContext"
import {extractAllStaticTiles} from "../../utility/Helper"
import {premiumBrandWidgetsType, StaticTile as staticTileType} from "../../utility/Types"

const baseComponentsArr = [CreditCheckTile, RyderAiStaticTile, GetCustomOfferTile, SignUpForConsultationBanner, WhyBuyFromMBPlan, InsuranceTile]
const componentsArrMobile = [CreditCheckWishList, RyderAiStaticTile, GetCustomOfferTile, InsuranceTile, SignUpForConsultationBanner]

const getExtendedComponentsArr = (staticTiles: staticTileType[], isMobile) => {
	if (!staticTiles?.length) {
		return baseComponentsArr
	}

	const staticTileComponents = staticTiles.map((tile, index) => {
		const DynamicItem = isMobile ? (props: any) => (
			<CreditCheckWishList
				isListView
				{...props}
				staticTileContent={tile}
				key={`static-tile-${index}`}
			/>
		) : (props: any) => (
			<CreditCheckTile
				{...props}
				staticTileContent={tile}
				key={`static-tile-${index}`}
			/>
		)

		return DynamicItem
	})

	return isMobile ? [...staticTileComponents, ...componentsArrMobile] : [...staticTileComponents, ...baseComponentsArr]
}

export const getTileArrToShow = (isMobile: boolean, staticTiles?: staticTileType[]) => {
	return staticTiles ? getExtendedComponentsArr(staticTiles, isMobile) : baseComponentsArr
}

type Props = {
  index: number;
  isIkeaDesign?: boolean;
};

const StaticTile: React.FC<Props> = ({index, isIkeaDesign = false}) => {
	const {isMobile, isListView} = useContext(LayoutContext)
	const {pageProps} = useContext(DataContext)
	const premiumBrandWidgets = pageProps?.strapiContent?.data?.premium_brand_widgets

	const staticTiles: staticTileType[] = useMemo(() => {
		return extractAllStaticTiles(premiumBrandWidgets as premiumBrandWidgetsType) as staticTileType[]
	}, [premiumBrandWidgets])

	const tileArr = getTileArrToShow(isMobile, staticTiles)
	const Component = tileArr[index - 1]

	const spanUpGridColumns = isMobile ? {gridColumn: "span 2"} : {height: "100%"}

	return (
		<div
			style={{...spanUpGridColumns}}
			className={`mt-4 ${isMobile ? "container" : ""} mt-md-0 pb-1 pb-md-0`}
		>
			{Component && !(isListView && index === 1) ? (
				<Component
					isListView
					isPlpStaticTile
					isIkeaDesign={isIkeaDesign}
				/>
			) : null}
		</div>
	)
}

export default StaticTile

import React, {ReactNode, useContext, useEffect} from "react"
import {useSwipeable} from "react-swipeable"

import styles from "./PureSlider.module.scss"
import SliderArrows from "./SliderArrows"
import SliderDots from "./SliderDots"
import LayoutContext from "../../../../context/LayoutContext"

type PureSliderProps = {
  children?: ReactNode;
  slidesToShow?: number;
  autoplay?: boolean;
  dots?: boolean;
  dotsPositionY?: number;
  arrows?: boolean;
  draggable?: boolean;
  handleSliderArrowClick?: () => void;
  className?: string;
  totalItems?: number;
  setCurrentSlide?: any;
  currentSlide?: any;
  slideWidth?: number;
  arrowsBottom?: boolean
  arrowsPositionX?: number
};

const PureSlider: React.FC<PureSliderProps> = ({
	children,
	slidesToShow = 4,
	autoplay = false,
	dotsPositionY = -10,
	dots,
	arrows,
	draggable = true,
	handleSliderArrowClick,
	className = "",
	totalItems = 0,
	currentSlide,
	setCurrentSlide,
	slideWidth = 300,
	arrowsBottom,
	arrowsPositionX
}) => {
	const {isMobile} = useContext(LayoutContext)

	const itemsPerPage = slidesToShow
	const itemsToMove = 1

	useEffect(() => {
		if (autoplay) {
			const interval = setInterval(() => {
				nextSlide()
			}, 3000)
			return () => clearInterval(interval)
		}
	}, [autoplay])

	const nextSlide = () => {
		handleSliderArrowClick?.()
		setCurrentSlide((prevSlide: number) => {
			const newSlide = prevSlide + itemsToMove
			const adjustedTotalItems = isMobile && totalItems > 4 ? totalItems + 1 : totalItems

			if (newSlide + itemsPerPage <= adjustedTotalItems) {
				return newSlide
			}

			return prevSlide
		})
	}

	const prevSlide = () => {
		setCurrentSlide((prevSlide: number) => (prevSlide > 0 ? prevSlide - 1 : 0))
	}

	const handlers = useSwipeable({
		onSwipedLeft: nextSlide,
		onSwipedRight: prevSlide,
		trackMouse: draggable
	})

	const totalSlides = Math.ceil(totalItems / itemsPerPage)
	const minDots = 5
	const visibleDots = Array.from({length: Math.max(minDots, totalSlides)}, (_, index) => index)

	const handleDotClick = (index: number) => {
		handleSliderArrowClick?.()
		setCurrentSlide(index)
	}

	return (
		<section className={`position-relative ${className}`}>
			<div className={`${styles["slider-container"]} d-flex justify-content-start`}
				{...handlers}>
				<div
					className={styles.slides}
					style={{
						display: "flex",
						transform: `translateX(-${currentSlide * slideWidth}px)`,
						transition: "transform 0.5s ease-in-out"
					}}
				>
					{children}
				</div>
			</div>

			{!arrowsBottom && arrows && (totalItems > 4 || slidesToShow === 1) && (
				<SliderArrows
					nextSlide={nextSlide}
					prevSlide={prevSlide}
					arrowsPositionX={arrowsPositionX}
				/>
			)}

			{arrowsBottom && arrows && totalItems > 4 && (
				<SliderArrows
					nextSlide={nextSlide}
					prevSlide={prevSlide}
					arrowsBottom
					arrowsPositionX={arrowsPositionX}
				>
					{dots && (
						<SliderDots
							dotsPositionY={dotsPositionY}
							visibleDots={visibleDots}
							currentSlide={currentSlide}
							handleDotClick={handleDotClick}
							isAbsolute={false}
						/>
					)}
				</SliderArrows>
			)}

			{!arrowsBottom && dots && totalItems > 4 && (
				<SliderDots
					dotsPositionY={dotsPositionY}
					visibleDots={visibleDots}
					currentSlide={currentSlide}
					handleDotClick={handleDotClick}
				/>
			)}
		</section>
	)
}

export default PureSlider

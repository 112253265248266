import React, {useState} from "react"
import Typography from "../../Typography/Typography"
import SliderContent from "../SliderContent/SliderContent"
import CompatibleAccessory from "../../../bikeDetails/CompatibleAccessory"
import ComparisonPLPFixedComponent from "../../../bikesCollection/ComparisonPLPFixedComponent"

type Props = {
    bikes: any[]
    title?: string
    hasComparisonSection?: boolean
    titlePosition?: string
    hasBorderBottom?: boolean
    inSearchContent?: boolean
    arrowsPositionX?: number
}

const BikeContent: React.FC<Props> = ({
	bikes,
	title,
	hasComparisonSection = true,
	titlePosition,
	hasBorderBottom,
	inSearchContent,
	arrowsPositionX
}) => {
	const [currentSlide, setCurrentSlide] = useState(0)

	if (!bikes || bikes.length === 0) {
		return null
	}

	return (
		<div>
			<div
				className="d-none d-md-block container px-0 col-12 mb-1"
				style={{minHeight: bikes.length > 4 ? 210 : 180}}
			>
				<div className="row justify-content-start recently-viewed-products pt-2">
					<div className="col-12">
						{title && (
							<Typography
								variant="heading2Black"
								className="mb-4 pb-3"
								semanticTag="h2"
							>
								{title}
							</Typography>
						)}

						{inSearchContent ?
							<div>
								{bikes.map((product, index) => {
									return (
										<div className={"mb-2"}
											key={product.id + index}>
											<CompatibleAccessory hasBadge
												key={product.id + index}
												product={product}
												bike={product}
												inSearchContent={true} />
										</div>
									)
								})}
							</div>		:
							<div>
								{bikes.length > 4 ? (
									<SliderContent
										productsList={bikes}
										setCurrentSlide={setCurrentSlide}
										currentSlide={currentSlide}
										totalItems={bikes.length}
										slideWidth={305}
										dots
										arrows
										arrowsBottom
										isCompactView={!inSearchContent}
										arrowsPositionX={arrowsPositionX}
									/>
								) : (
									<div
										className={`d-flex ${
											bikes.length === 4 ?
												"justify-content-between gap-2" :
												"gap-3"
										}`}
									>
										{bikes.map((bike, index) => (
											<div key={bike.id + index}>
												<CompatibleAccessory
													product={bike}
													bike={bike}
													inSearchContent={inSearchContent}
												/>
											</div>
										))}
									</div>
								)}
							</div>}

					</div>
				</div>
			</div>
			<div className="d-md-none">
				{title && !inSearchContent && (
					<Typography
						className="mb-4 pb-1"
						variant="bodyLgBlack"
						style={{
							fontSize: 28,
							paddingLeft: "12px"
						}}
					>
						{title}
					</Typography>
				)}
				{bikes.length > 1 ? (
					<SliderContent
						productsList={bikes}
						setCurrentSlide={setCurrentSlide}
						currentSlide={currentSlide}
						totalItems={bikes.length}
						slideWidth={288}
						slidesToShow={inSearchContent ? 2 : 1}
						isCompactView={!inSearchContent}
					/>
				) : (
					bikes.map((bike, idx) => (
						<div className="container"
							key={bike.id}>
							<CompatibleAccessory
								product={bike}
								bike={bike}
								inSearchContent={inSearchContent}
							/>
						</div>
					))
				)}
			</div>
			{hasComparisonSection && (
				<div className="col-12 container">
					<ComparisonPLPFixedComponent sticky={false} />
				</div>
			)}
			{hasBorderBottom && (
				<div
					style={{
						height: 1,
						backgroundColor: "#DEE2E6",
						margin: "12px 0px"
					}}
					className="col-12 mb-3"
				/>
			)}
		</div>
	)
}

export default BikeContent

import React from "react"
import {StoryItem} from "../../context/StoryContext"
import {placeholders} from "./Story"
import StoryButton from "./StoryButton"
import Typography from "../reusable/Typography/Typography"
import {Slides} from "../reusable/components/Slides"

type Props = {
  title?: string
  stories: StoryItem[]
}

const Stories = ({stories, title}: Props) => {
	return (
		<div className={"container pt-3 mt-4 pt-md-0 mb-3"}>
			<div className={"row"}>
				<div className={"col-auto text-md-center"}>
					{title &&
					<>
						<Typography className="d-none d-md-block text-start mb-4"
							variant="heading2Black"
							semanticTag={"h2"}>
							{title}
						</Typography>
						<Typography className="d-block d-md-none mb-4"
							variant="bodyLgBlack"
							semanticTag={"h4"}
							style={{fontSize: 28}}>
							{title}
						</Typography>
					</>
					}
					{/* {subtitle && <Typography className={"mb-4"} variant={"bodyLg"} semanticTag={"p"}>
						{subtitle}
					</Typography>} */}
				</div>
			</div>
			<div className={"row pt-2 g-3"}>
				<div className="d-none d-md-block">
					<Slides autoplay={false}
						slidesToShow={2.3}
						centerMode
						centerModePadding={{left: 60, bottom: 0, right: 0}}>
						{stories.map(story => <div key={story.id}
							className={"col-auto"}>
							<StoryButton story={story}
								stories={placeholders}
								size={243}/>
						</div>)}
					</Slides>
				</div>

				<div className="d-md-none">
					<Slides autoplay={false}
						slidesToShow={1.3}
						centerMode >
						{stories.map(story => <div key={story.id}
							className={"col-auto"}>
							<StoryButton story={story}
								stories={placeholders}
								size={243}/>
						</div>)}
					</Slides>
				</div>
			</div>
		</div>
	)
}

export default Stories

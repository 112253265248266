import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import Button from "../../reusable/components/Button"
import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../../context/LayoutContext"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

const GetCustomOfferTile = ({isIkeaDesign}:{isIkeaDesign:boolean}) => {
	const {t} = useTranslation()
	const {isListView, isMobile} = useContext(LayoutContext)
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<div className="find-perfect-bike h-100">
			<div
				className={`col-12  info-card p-3 d-none d-md-block ${isListView ? "col-md-11 container d-md-flex gap-5 flex-row-reverse justify-content-between py-4" : ""}`}
				style={{outline: "1px solid #DEE2E6", minHeight: isListView ? "250px" : "300px", maxHeight: "432px", height: "100%", borderRadius: isIkeaDesign ? "0px" : "24px", minWidth: isMobile ? "164px" : "250px"}}>
				{isListView ?
					<div className="position-relative col-5">
						<Image src={"/assets/images/whatsapp-suggestion-pic.jpg"}
							layout={"fill"}
							objectFit={"cover"}
							objectPosition="top"
							style={{borderRadius: 16}}/>
					</div>					:
					<div className="position-relative"
						style={{width: "100%", paddingBottom: "65%"}}>
						<Image
							src={"/assets/images/get-custom-offer-mobile.png"}
							layout={"fill"}
							objectFit={"cover"}
							style={{borderRadius: 16}}
							objectPosition={"top"}
						/>
					</div>

				}
				<div className={`info-card-text mt-4 ${isListView ? "d-flex flex-column col-5 justify-content-center mt-md-0" : ""}`}>
					<Typography variant={isListView ? "heading2Black" : "heading3MdBodyLgBlack"}
						semanticTag="h4"
						className={`${isListView ? "mb-2" : "mb-1"}`}
						style={{fontSize: isListView ? 32 : ""}}>{t("custom-offer-card-title")}</Typography>
					<Typography variant={"bodySm"}
						semanticTag="span">{t("take-short-quiz")}</Typography>

					<div className="col-12"
						style={{marginTop: 12}}>
						<Button
							href={"/suggestion/1"}
							fontSizeSmall
							bgColor="#FEC04D"
							textColor="#212529"
							color="#212529"
							onClick={() => {
								reactGA?.event({
									category: gaCategories.plp,
									action: gaEvents.getCustomOfferClickedOnPlp,
									label: `${gaEvents.getCustomOfferClickedOnPlp}`,
									nonInteraction: false
								})
							}}
							className={isListView ? "mt-2" : "w-100"}>
							<Image src={"/assets/icons/question-mark-box.svg"}
								width={20}
								height={20}
								objectFit="contain"/>
							<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{t("take-quiz-btn")}</Typography>
						</Button>
					</div>
				</div>
			</div>
			<div
				className="col-12 info-card mb-4 d-md-none"
				style={{backgroundColor: "#F8F8F8"}}>
				<div className="col-12 info-card-image position-relative">
					<Image src={"/assets/images/whatsapp-suggestion-pic.jpg"}
						layout={"fill"}
						objectFit={"cover"}
						objectPosition="top"/>
				</div>
				<Typography variant={"heading3MdBodyLgBlack"}
					semanticTag="h4"
					className="my-3">{t("custom-offer-card-title")}</Typography>

				<div className="info-card-text d-md-flex gap-5 justify-content-between align-items-center mt-4">
					<Typography variant={"bodyLg"}
						semanticTag="span">{t("take-short-quiz")}</Typography>

					<div className="col-12 mt-3 ">
						<Button
							href={"/suggestion/1"}
							fontSizeSmall
							bgColor="#FEC04D"
							textColor="#212529"
							color="#212529"
							onClick={() => {
								reactGA?.event({
									category: gaCategories.plp,
									action: gaEvents.getCustomOfferClickedOnPlp,
									label: `${gaEvents.getCustomOfferClickedOnPlp}`,
									nonInteraction: false
								})
							}}
							className="w-100">
							<Image src={"/assets/icons/question-mark-box.svg"}
								width={20}
								height={20}
								objectFit="contain"/>
							<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{t("take-quiz-btn")}</Typography>
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GetCustomOfferTile

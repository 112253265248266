import {useTranslation} from "next-i18next"
import React, {useContext, useState} from "react"
import LayoutContext from "../../../../context/LayoutContext"
import AccordionAnimation from "../../../reusable/components/AnimationComponent/AccordionAnimation"
import Button from "../../../reusable/components/Button"
import PlusDash from "../../../reusable/PlusDash"
import Typography from "../../../reusable/Typography/Typography"
import AnalyticsContext from "../../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../../config/googleAnalytics/events"

type Props = {
    question: string,
    answer: any,
    isOpen: boolean,
    onClick: () => void
    isMiniFAQDesign?: boolean
}

const FAQItem: React.FC<Props> = ({question, answer, isOpen, onClick, isMiniFAQDesign}) => {
	const {isMobile} = useContext(LayoutContext)
	const textBold = isMobile ? "text-bold" : ""

	return (
		<li
			onClick={onClick}
			className={`${isMiniFAQDesign ? "mb-2" : "ps-3 mb-md-5 mb-3"} pointer`}
			style={{borderLeft: isMiniFAQDesign ? "" : isOpen ? "4px solid #FEC04D" : "4px solid #DEE2E6",
				backgroundColor: isMiniFAQDesign ? "#F8F8F8" : "",
				borderRadius: isMiniFAQDesign ? "4px" : "",
				padding: isMiniFAQDesign ? "1rem" : ""
			}}
		>
			<span style={{cursor: "pointer"}}
				className="row g-2 g-md-3 align-items-top">
				<div className="col-11 overflow-hidden">
					<Typography
						variant={isMiniFAQDesign ? "bodySm" : "bodyLgBold"}
						className={`${textBold} mb-0`}
						semanticTag="h3"
					>
						{question}
					</Typography>
					<AccordionAnimation inProp={isOpen}
						duration={400}
						transTimingFn="ease-out">
						<Typography
							variant={isMiniFAQDesign ? "bodySm" : "bodyLg"}
							semanticTag="h4"
							className={`${isOpen ? "" : ""} ml-5 mt-3 ${isMiniFAQDesign && "bg-white rounded-3 p-2"} `}
						>
							{answer}
						</Typography>
					</AccordionAnimation>
				</div>
				<div className="col-1 mt-1 mt-md-3">
					<PlusDash isOpen={isOpen} />
				</div>
			</span>
		</li>
	)
}

type FAQProps = {
    mostAskedOnly?: boolean
    isMiniFAQDesign?: boolean
}

const FAQ: React.FC<FAQProps> = ({mostAskedOnly, isMiniFAQDesign}) => {
	const {t} = useTranslation("common")
	const [loading, setLoading] = useState(false)
	const [activeToggle, setActiveToggle] = useState(null)
	const {reactGA} = useContext(AnalyticsContext)

	const faqs = [
		{
			question: t("Ich verstehe nicht, wie 0 % Zins möglich sind."),
			answer: (
				<>
					{t("Wir arbeiten mit dem Finanzierungspartner")}{" "}
					<a href="https://swissbilling.ch/"
						className="new-mbp-link">{t("swissbilling.ch")}</a>{" "}
					{t("zusammen, welcher es uns ermöglicht, dir die Ratenzahlung zinsfrei anzubieten.")}
					<br />
					<br />
					{t("Als Gegenleistung erhält")}{" "}
					<a href="https://swissbilling.ch/"
						className="new-mbp-link"
					>{t("swissbilling.ch")}</a>{" "}
					{t("von uns einen Anteil des Gewinns. Diesen Weg haben wir bewusst gewählt, um dir Extrakosten zu ersparen und jede*m den Weg zur E-Mobilität zu ermöglichen. Du hast weitere Fragen dazu? Wir geben auch telefonisch gerne darüber Auskunft!")}
				</>
			),
			event: gaEvents.firstFaqQuestion
		},
		{
			question: t("Bezahle ich gleich viel, wie wenn ich alles auf einmal bezahlen würde?"),
			answer: (
				<>
					{t("Ja, Du bezahlst mit monatlichen Raten keinen Franken mehr, als wenn Du alles auf einmal bezahlst.")}
					<br />
					<br />
					{t("Unser 0%-Finanzierungsangebot ist für Dich völlig zinsfrei.")}
				</>
			),
			event: gaEvents.secondFaqQuestion
		},
		{
			question: t("Wie kann ich den Monatspreis berechnen?"),
			answer: (
				<>
					{t("Es ist ganz einfach! Nehme den Gesamtpreis und teile ihn durch die gewünschte Laufzeit. Beispiel:")}
					<br />
					<br />
					{t("Gesamtpreis: CHF 4’320.")}
					<br />
					{t("Dauer des Plans: 36 Monate")}
					<br />
					{t("Monatsrate: CHF 120 (4’320/36)")}
				</>
			),
			event: gaEvents.thirdFaqQuestion
		},
		{
			question: t("Wann ist die erste Rate fällig?"),
			answer: (
				<>
					{t("Nach Vertragsunterzeichnung werden dich innerhalb 1-2 Wochen die Einzahlungsscheine erreichen. Die erste Rate ist jeweils aber erst am 1. des übernächsten Monats fällig.")}
					<br />
					<b>{t("Beispiel:")}</b>{" "}
					{t("Du unterzeichnest den Vertrag am 15. Oktober – die erste Rate wird somit am 1. Dezember fällig sein.")}
				</>
			),
			event: gaEvents.fourthFaqQuestion
		},
		{
			question: t("Wird mein E-Bike zu mir nach Hause geliefert?"),
			answer: (
				<>
					{t("Ja. Dein E-Bike wird komplett montiert und fahrbereit zu Dir nach Hause geliefert.")}
				</>
			),
			event: gaEvents.fifthFaqQuestion
		},
		{
			question: t("Besitze ich das E-Bike nach meinem Plan?"),
			answer: (
				<>
					{t("Ja! Du zahlst den Gesamtpreis Deines E-Bikes über den gewünschten Zeitraum ab.")}
				</>
			),
			event: gaEvents.sixthFaqQuestion
		}
	]

	const handleToggle = (index, eventLabel) => {
		reactGA?.event({
			category: gaCategories.feedback,
			action: eventLabel,
			label: eventLabel,
			nonInteraction: false
		})
		setActiveToggle(prev => (prev === index ? null : index))
	}

	return (
		<div className={`col-12 ${!isMiniFAQDesign && "col-lg-10"}`}>
			<div className={isMiniFAQDesign ? "" : "row align-items-top justify-content-center"}>
				{isMiniFAQDesign ? null :
					<>
						<Typography
							variant="bodyLgBlack"
							semanticTag="h2"
							className="col-12 pb-3 d-block d-md-none"
							style={{fontSize: 28}}
						>
							{t("Häufige Fragen")}
						</Typography>
						<Typography
							variant="heading2Black"
							semanticTag="h2"
							className="col-12 col-lg-8 pb-3 d-none d-md-block text-center"
						>
							{t("Häufige Fragen")}
						</Typography>
					</>
				}
				<ul className={`col-12 ${isMiniFAQDesign ? "" : "col-lg-8 pt-2 pt-md-5"} faq-list list-unstyled`}>
					{(mostAskedOnly ? faqs.slice(0, 3) : faqs).map((faq, index) => (
						<FAQItem
							key={index}
							question={faq.question}
							answer={faq.answer}
							isOpen={activeToggle === index}
							onClick={() => handleToggle(index, faq.event)}
							isMiniFAQDesign={isMiniFAQDesign}
						/>
					))}
				</ul>
				{!isMiniFAQDesign &&
					<>
						<div className="col-12 d-block d-md-none">
							<div className="row justify-content-center">
								<div className="col-12">
									<Button label={t("Siehe vollständige FAQ")}
										outline
										textColor="#212529"
										hoverColor="#212529"
										color="#fff"
										paddingY="3"
										href={"/faq/konzept"}
										className="w-100"
										isLoading={loading}
										onClick={() => {
											reactGA?.event({
												category: gaCategories.feedback,
												action: gaEvents.seeFullFaq,
												label: `${gaEvents.seeFullFaq}`,
												nonInteraction: false
											})
											setLoading(true)
										}}/>
								</div>
							</div>
						</div>
						<div className="col-12 d-none d-md-block">
							<div className="row">
								<div className="col-12 d-flex justify-content-center">
									<Button label={t("Siehe vollständige FAQ")}
										outline
										paddingY="3"
										textColor="#212529"
										hoverColor="#212529"
										color="#fff"
										href={"/faq/konzept"}
										className="w-auto"
										isLoading={loading}
										onClick={() => {
											reactGA?.event({
												category: gaCategories.feedback,
												action: gaEvents.seeFullFaq,
												label: `${gaEvents.seeFullFaq}`,
												nonInteraction: false
											})
											setLoading(true)
										}}/>
								</div>
							</div>
						</div>
					</>
				}
			</div>
		</div>
	)
}

export default FAQ

import React, {useEffect, useState} from "react"
import {StockStatus} from "../BikeDetails"
import Link from "next/link"
import Image from "next/image"
import {useRouter} from "next/router"
import {logger} from "../../../utility/Helper"

type Props = {
    colorData: any,
    isLoadingColors?: boolean,
    small?: boolean,
		highlightSelectedColor?: boolean
}

const Colors: React.FC<Props> = ({colorData, small, highlightSelectedColor = false}) => {
	const circleBorder = "1px solid #ccc"
	const circleBorderSelected = "1px solid #212529"
	const router = useRouter()
	const [availabilityData, setAvailabilityData] = useState<any[]>()
	useEffect(() => {
		if (!colorData?.length) {
			return
		}

		const fetchAvailability = async () => {
			try {
				const requests = colorData.map(({slug}) =>
					fetch(`/api/products/check-availability?slug=${slug?.split("/")[1]}&locale=${router.locale}`)
						.then(response => response.json())
				)

				const results = await Promise.all(requests)
				setAvailabilityData(results)
			} catch (error) {
				logger.error("Error fetching availability:", error)
			}
		}

		fetchAvailability()
	}, [colorData, router.locale])
	return (
		<div className="d-flex justify-content-start align-items-center">
			{colorData.map((data, index) => {
				const {color, slug, onStock, selected} = data
				const hexColor = color
				const trimmedSlug = slug?.trim()
				const linkColor = (trimmedSlug && trimmedSlug.charAt(0) === "/") ?
					trimmedSlug : trimmedSlug ? `/${trimmedSlug}` : undefined
				const outOfStock = onStock === StockStatus.outOfStock ||
					Boolean(slug && availabilityData?.[index]?.bikeExists === false)
				return (
					linkColor ? (
						<Link href={linkColor}
							key={color}>
							<a className={`col-auto ${small ? "me-1" : "me-2"}`}
								onClick={e => {
									if (outOfStock) {
										e.preventDefault()
									}
								}}>
								<div key={color}
									className={`${outOfStock ? "force-no-pointer" : "pointer"} d-flex justify-content-center align-items-center overflow-hidden position-relative`}
									style={{
										height: small ? 17 : selected && highlightSelectedColor ? 32 : 20,
										width: small ? 17 : selected && highlightSelectedColor ? 32 : 20,
										borderRadius: 4,
										border: selected && highlightSelectedColor ? circleBorderSelected : circleBorder
									}}>
									<div style={{
										height: small ? 17 : 20,
										width: small ? 17 : 20,
										borderRadius: 4,
										backgroundColor: hexColor
									}}/>
									{outOfStock ?
										<span className="d-flex align-items-center justify-content-center"
											style={{
												position: "absolute",
												top: "50%",
												left: "50%",
												transform:	"translate(-50%, -50%)"
											}}>
											<Image src={"/assets/icons/dismiss-white.svg"}
												width={16}
												height={16}
												alt={"out of stock"}/>
										</span> :
										null}
								</div>
							</a>
						</Link>
					) : (
						<div key={color}
							className={`${small ? "me-1" : "me-2"} d-flex cursor-pointer justify-content-center align-items-center position-relative`}
							style={{
								height: small ? 24 : highlightSelectedColor ? 32 : 20,
								width: small ? 24 : highlightSelectedColor ? 32 : 20,
								borderRadius: 4,
								border: highlightSelectedColor ? circleBorderSelected : ""
							}}>
							<div style={{
								height: small ? 17 : 20,
								width: small ? 17 : 20,
								borderRadius: 4,
								border: "1px solid #edebeb",
								backgroundColor: hexColor
							}}
							className="d-flex align-items-center justify-content-center"/>
							{outOfStock ?
								<span
									className={"strike-through-diagonal border-bottom border-primary border-2"}/> :
								null}
						</div>
					)
				)
			})}
		</div>
	)
}

export default Colors

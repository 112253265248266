import React, {useContext, useEffect, useState} from "react"
import Typography from "../../reusable/Typography/Typography"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

type Props = {
    disabledBrackets: boolean[]
    downpaymentBracketsList: any[]
    onBracketSelect: (percentage: number) => void;
    sliderValue?: number
    maxValue?: number
    monthlyPriceWithoutDownpayment?: number
}

const DownpaymentBrackets: React.FC<Props> = ({
	disabledBrackets,
	downpaymentBracketsList = [],
	onBracketSelect = () => {},
	sliderValue,
	maxValue,
	monthlyPriceWithoutDownpayment
}) => {
	const [selected, setSelected] = useState(0)
	const {reactGA} = useContext(AnalyticsContext)

	const handleBracketSelection = (bracketPercentage: number) => {
		onBracketSelect(bracketPercentage)
		setSelected(bracketPercentage)
	}

	useEffect(() => {
		const currentValue = Math.floor(maxValue * selected / 100)
		if (currentValue !== sliderValue) {
			setSelected(0)
		}
	}, [sliderValue, selected])

	return (
		<ul className="d-flex justify-content-between gap-1 list-unstyled">
			{downpaymentBracketsList.map((item, index) => {
				const isBracketPassed = disabledBrackets[index]
				const bracketPercentage = parseFloat(item.title)
				const priceDifferenceWithDownpayment = Math.ceil(
					monthlyPriceWithoutDownpayment * (bracketPercentage / 100)
				)
				return (
					<li
						className={`downpayment-bracket border rounded-3 text-center d-flex
                        flex-column align-items-center justify-content-center 
                        ${isBracketPassed ? "disabled-bracket text-secondary" : "cursor-pointer"}
                        ${bracketPercentage === selected && !isBracketPassed ?
						"bg-primary fw-bold" : "cursor-pointer"}
                        `}
						key={item.id}
						onClick={() => {
							handleBracketSelection(bracketPercentage)
							reactGA.event({
								category: gaCategories.pdp,
								action: gaEvents.downPaymentBracketValue,
								label: `${bracketPercentage}% Down Payment`,
								nonInteraction: false
							})
						}}
					>
						<Typography variant="bodySm"
							className={`${bracketPercentage === selected && !isBracketPassed ? "fw-bold" : "mb-1"}`}>
							{item.title}
						</Typography>
						{bracketPercentage !== selected &&
						<Typography
							variant="bodyXSmBold"
							className="discount-per-month"
							style={{color: !isBracketPassed && "#077940"}}
						>
                            -{priceDifferenceWithDownpayment}/m
						</Typography>}
					</li>
				)
			})}
		</ul>
	)
}

export default DownpaymentBrackets

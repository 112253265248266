import {useField} from "formik"
import React, {useContext} from "react"
import {Color} from "../../reusable/FormInput/FormInput"
import Typography from "../../reusable/Typography/Typography"
import LayoutContext from "../../../context/LayoutContext"

interface Props {
  color?: keyof typeof Color
  bgColor?: keyof typeof Color
  errColor?: keyof typeof Color
  className?: string
  required?: boolean
  styles?: any
}

const FormTextArea: React.FC<Props | any> = ({
	required,
	className,
	label,
	errColor = Color.danger,
	color = Color.dark,
	bgColor = Color.white,
	styles = null,
	...props
}) => {
	const {name} = props
	const [field, meta] = useField(props)
	const {error, touched} = meta
	const {isMobile} = useContext(LayoutContext)

	return (
		<div className={`position-relative border rounded mb-4 pt-3 ${className ? className : ""}`}>
			<label htmlFor={name}
				className={`position-absolute px-2 text-center ${!isMobile && `bg-${bgColor}`} text-${color}`}
				style={{
					marginTop: isMobile ? "-48px" : "-25px",
					left: isMobile ? "-10px" : ""
				}}
			>
				<Typography variant="bodySmBoldMdBodyLgBold"
					semanticTag="span">{label}</Typography>{required &&
      <span className="text-danger">*</span>}
			</label>
			<textarea style={styles ? {...styles} : {}}
				rows="4"
				className={`col-12 ps-2 transparent-input text-${color}`}
				id={name}
				{...field}
				{...props} />
			{/* Error Display below */}
			{error && touched ? (
				<Typography variant="bodyLg"
					semanticTag="div"
					className={`text-${errColor} ps-2`}>{error}</Typography>
			) : null}
		</div>
	)
}

export default FormTextArea

import React, {useContext} from "react"
import WhatsAppButton from "../navigation/WhatsAppButton"
import ReusableConsultation from "../bikeDetails/ResusableConsultation"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../context/LayoutContext"
import Image from "next/image"
import Link from "next/link"

function ContactUsOnEmptyPlp() {
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	return (
		<div style={{flex: 1, width: isMobile ? "100%" : ""}}
			className="mt-3 d-flex justify-content-start gap-3 align-items-start flex-column">
			<Typography style={{color: "#212529", fontSize: "14px", lineHeight: "20px"}}
				variant="bodySmBold"
				semanticTag="div"
				className="col-12">
				{t("contact-us")}
			</Typography>
			<div className="col-12 ">
				<WhatsAppButton withTextAndArrow/>
			</div>
			<ReusableConsultation imgSrc="/assets/icons/consultation copy.svg"
				isBookAppointment
				className="cursor-pointer  col-12" />
			<div className="d-flex col-12 align-items-center justify-content-between  cursor-pointer">
				<div className="d-flex align-items-center justify-content-start gap-3">
					<div className="d-flex align-items-center justify-content-center position-relative"
						style={{
							minWidth: 40,
							minHeight: 40,
							borderRadius: "4px",
							zIndex: 2,
							backgroundColor: "#F8F8F8"
						}}>
						<Image src={"/assets/icons/bike-question.svg"}
							height={ 20}
							width={ 20}
							objectFit="contain" />
					</div>
					<Link href={"/suggestion/1"}><div>
						<Typography semanticTag="div"
							className="my-2"
							style={{fontSize: "14px", lineHeight: "20px"}}
							variant="bodySmBold">{t("suggestion-eligible-for-financing-title")}</Typography>
						<Typography semanticTag="div"
							className="my-2"
							style={{fontSize: "14px", lineHeight: "20px"}}
							variant="bodySm">{t("custom-offer-card-title")}</Typography></div></Link>

				</div>
				<Image src={"/assets/icons/chevron-right.svg"}
					width={40}
					height={20}/>
			</div>
		</div>
	)
}

export default ContactUsOnEmptyPlp

import React, {Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState} from "react"
import GoBack from "../GoBack"
import CustomSlider from "../../imageSlides/CustomSlider"
import ProductInfo from "../ProductInfo"
import ComparisonPLPFixedComponent from "../../bikesCollection/ComparisonPLPFixedComponent"
import BikeOrdersCount from "../BikeOrdersCount"
import Typography from "../../reusable/Typography/Typography"
import bikePrice from "../BikePrice"
import CollectionItemBadge from "../../bikesCollection/BikesCollectionItem/CollectionItemBadge"
import ConditionAndMileage from "./ConditionAndMileage"
import Colors from "../colors/Colors"
import RelatedProducts from "../RelatedProducts"
import Sizes from "../sizes/Sizes"
import DurationAndDownpayment from "./DurationAndDownpayment"
import PaymentAndBookMeetingDesktopSection from "./PaymentAndBookMeetingDesktopSection"
import {findBikeMetaFieldByName, formatPrice} from "../../../utility/Helper"
import useBadges from "../../../hooks/useBadges"
import {useTranslation} from "next-i18next"
import {customPlanLengthMap} from "../plan/customPlanLengthMap"
import CheckoutContext from "../../../context/CheckoutContext"
import useDiscount from "../../../hooks/useDiscount"
import LayoutContext from "../../../context/LayoutContext"
import SponsorMeModal from "../SponsorMeModal"
import {CartItem} from "../../../context/CartContext"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import StickyMobileBikeDetails from "./StickyMobileBikeDetails"
import useOnScreen from "../../../hooks/OnScreen"
import PDPBreadcrumb from "./PDPBreadcrumb"
import BikeNameAndDescSection from "./BikeNameAndDescSection"
import PriceDisplay from "./PriceDisplay"
import FAQ from "../../home/faq/FAQ"
import PdpAiAssistant from "./PdpAiAssistant"

export const StockStatus = {
	inStock: "instock",
	outOfStock: "outofstock",
	onBackOrder: "onbackorder"
}
type Props = {
    bike: any,
    startShake: () => void,
    handleCheckoutBtnClick: (directPayment?: any) => void,
    addingToCart: boolean,
    variations: any,
    selectedVariation: any,
    soldOut: boolean,
    active: string,
    setActive: Dispatch<SetStateAction<string>>,
    tab: string,
    isShaking: boolean,
    brand: string,
    isSecondHand: boolean,
    accordionContent: any,
    isAccessory: boolean
    questions:any
    setAdditionalInfoDrawer:Dispatch<SetStateAction<boolean>>
    setIsSponsorMeModalOpen:Dispatch<SetStateAction<boolean>>
    setAskAQuestion: Dispatch<SetStateAction<boolean>>
    setSelectedSize: Dispatch<SetStateAction<any>>
    setSelectedVariation: Dispatch<SetStateAction<any>>
    setActiveLink: Dispatch<SetStateAction<any>>
    executeScroll: () => void
    getCartItem: () => CartItem
    isSponsorMeModalOpen:boolean
    selectedSize: any
    setIsGoBackLoading?: (loading: boolean) => void;
}

function ProductGalleryAndPaymentSection({
	setActiveLink,
	bike,
	soldOut,
	questions,
	setAdditionalInfoDrawer,
	getCartItem,
	setSelectedVariation,
	setIsSponsorMeModalOpen,
	isSponsorMeModalOpen,
	setSelectedSize,
	setAskAQuestion,
	addingToCart,
	executeScroll,
	selectedSize,
	handleCheckoutBtnClick,
	isShaking,
	startShake,
	selectedVariation,
	variations,
	brand,
	active,
	setActive,
	tab,
	isSecondHand,
	accordionContent,
	isAccessory,
	setIsGoBackLoading = () => {}
}:Props) {
	const [isOpen, setIsOpen] = useState(false)
	const [isFinancingPopupOpen, setIsFinancingPopupOpen] = useState(false)
	const [showCheckoutBtnOnScreen, setShowCheckoutBtnOnScreen] = useState(false)
	const {data: checkoutData} = useContext(CheckoutContext)
	const {finalPriceWithoutDownPayment, finalPriceWithDownPayment, onSale, discount, customInstanceDiscount} = useDiscount(bike)
	const {isMobile} = useContext(LayoutContext)
	// PlanLength
	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = checkoutData?.planLength ?
		planLengthMap ? planLengthMap[checkoutData?.planLength] : checkoutData?.planLength : 48

	const mileage = Number(findBikeMetaFieldByName(bike, "mileage"))
	const {isNew, isNonEBike, isFeatured, saleOfTheWeek, is2025, isBikeOfTheMonth, hasTestDrive, isRentable, isBrandWorldRecommended} = useBadges(bike)
	const {t} = useTranslation()
	const kilometers = findBikeMetaFieldByName(bike, "kms")
	const formattedPriceForSecondHand = formatPrice(bike.displayPrice)
	const formatteduvpPriceForSecondHand = formatPrice(bike.uvpPrice)
	const shortDescription = findBikeMetaFieldByName(bike, "short_description")
	const stockInfo = bike.stock_status === StockStatus.inStock ? "in-stock" : ""
	const [scriptsLoaded] = useState([])
	const scriptsRef = useRef([])
	scriptsRef.current = scriptsLoaded
	const bikeOrdersCount = useMemo(() => bike?.ordersLastWeek, [bike])
	const galleryRef = useRef()

	const isOnScreen = useOnScreen(galleryRef)
	useEffect(() => {
		if (isOnScreen) {
			setActiveLink("photos")
		}
	}, [isOnScreen])

	useEffect(() => {
		if (typeof window !== "undefined") {
			const handleScroll = () => {
				if (window.scrollY > 400) {
					setShowCheckoutBtnOnScreen(true)
				} else {
					setShowCheckoutBtnOnScreen(false)
				}
			}

			window.addEventListener("scroll", handleScroll)

			return () => {
				window.removeEventListener("scroll", handleScroll)
			}
		}
	}, [])
	return (
		<div
			className={"row justify-content-between"}>
			<div className="d-block d-md-none">
				<AnimationComponent inProp={showCheckoutBtnOnScreen}
					type="slideRight">
					<StickyMobileBikeDetails isSecondHand={isSecondHand}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						addingToCart={addingToCart}
						selectedSize={selectedSize}
						startShake={startShake}
						handleClick={handleCheckoutBtnClick}
						bike={bike}
						planLength={planLength}
						setIsFinancingPopupOpen={() => setIsFinancingPopupOpen(true)}/>
				</AnimationComponent>
			</div>
			<SponsorMeModal handleSizeClick={startShake}
				disabled={!selectedSize}
				cartItem={getCartItem}
				bike={bike}
				isModalOpen={isSponsorMeModalOpen}
				closeModal={() => setIsSponsorMeModalOpen(false)} />
			<div ref={galleryRef} />
			<div className="d-none d-md-block col-md-6 col-lg-8 pb-md-5 sticky-wrapper-pdp">
				<div className="d-none d-md-block col-12">
					<GoBack
						showBikeInfo={false}
						isBikeOfTheMonth={isBikeOfTheMonth}
						is2025={is2025}
						name={bike.name}
						brand={brand}
						stockInfo={t(stockInfo)}
						isSecondHand={isSecondHand}
						withText
						setIsGoBackLoading={setIsGoBackLoading}
					/>
				</div>
				<CustomSlider showDescription
					slidesList={bike.images}
					bike={bike}
					withGalery
					hasComparisonBtn
					arrows
					hasWhatsappSlide
					isAccessory={isAccessory}
					zoomableImage={true}
				/>
				{JSON.parse(process.env.NEXT_PUBLIC_AI_IS_ACTIVATED || "false") ?
					<PdpAiAssistant
						name={bike.name}
						slug={bike.slug} /> :
					null}
				<div className="mt-5 pt-2 col-md-11">
					<ProductInfo
						setActiveLink={setActiveLink}
						accordionContent={accordionContent}
						tab={tab}
						t={t}
						active={active}
						setActive={setActive}
						bike={bike}
						isAccessory={isAccessory}/>
				</div>
			</div>
			<div className="col-md-4 main-slider-pdp px-0 pb-md-5">
				<div
					style={{marginTop: "1rem"}}
					className="col-12 d-block d-md-none container px-0">
					<div style={{marginTop: "1rem"}}
						className="container col-12 px-3">
						<PDPBreadcrumb bike={bike}
							className="mb-3"/>
						<CollectionItemBadge
							testDriveLink={hasTestDrive?.[0] || null}
							isFeatured={isFeatured}
							isBrandWorldRecommended={isBrandWorldRecommended}
							isNonEBike={isNonEBike}
							isBikeOfTheMonth={isBikeOfTheMonth}
							isNew={isNew}
							inStock={bike.stock_status === "instock"}
							kilometers={kilometers}
							occasion={isSecondHand}
							onSale={onSale}
							offersTestDrive={hasTestDrive || false}
							soldOut={soldOut}
							isRentable={isRentable}
							saleOfTheWeek={saleOfTheWeek}
							itemsLeft={bike?.totalPhysicalStock as number}
							fontSizeXSm
							discount={discount}
							isRow
						/>
						<BikeNameAndDescSection
							bike={bike}
							bikeOrdersCount={bikeOrdersCount}
							isMobile={isMobile}
							shortDescription={shortDescription}
							isSecondHand={isSecondHand}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
							onSale={onSale}
							formatteduvpPriceForSecondHand={formatteduvpPriceForSecondHand}
							formattedPriceForSecondHand={formattedPriceForSecondHand}
							t={t}
							discount={discount}
						/>
						<div className="mt-1 mb-3">
							<PriceDisplay
								finalPriceWithDownPayment={finalPriceWithDownPayment}
								finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
								planLength={planLength}
								isSecondHand={isSecondHand}
								montlyPriceSize={28}
								handleEditFinanceBtnClick={() => setIsFinancingPopupOpen(true)}
								hasEditFinancingBtn/>
						</div>
					</div>
					<div className="custom-slider-mobile-pdp">
						<CustomSlider showDescription
							slidesList={bike.images}
							bike={bike}
							hasComparisonBtn
							dots
							hasWhatsappSlide
							isAccessory={isAccessory}
							zoomableImage={true}
							hasGoBackBtn
							borderRadius={"0px 0px 32px 32px"}
						/>
						{!isAccessory && <div className="d-md-none">
							<ComparisonPLPFixedComponent sticky={false}/>
						</div>}
						{JSON.parse(process.env.NEXT_PUBLIC_AI_IS_ACTIVATED || "false") ?
							<PdpAiAssistant
								name={bike.name}
								slug={bike.slug} /> :
							null}
					</div>
				</div>
				<div id="sizes-button"
					className={"col-12 px-0 col-lg-12"}>
					{/* Desktop */}
					<div className="d-none d-md-block">
						<PDPBreadcrumb bike={bike}
							className="mb-2 pb-1"/>
						{bikeOrdersCount >= 1 && <div
							className="row mb-3">
							<BikeOrdersCount count={bikeOrdersCount}
								t={t}/>
						</div>}
						<CollectionItemBadge
							testDriveLink={hasTestDrive?.[0]}
							isFeatured={isFeatured}
							isBrandWorldRecommended={isBrandWorldRecommended}
							isNonEBike={isNonEBike}
							isBikeOfTheMonth={isBikeOfTheMonth}
							isNew={isNew}
							inStock={bike.stock_status === "instock"}
							kilometers={kilometers}
							occasion={isSecondHand}
							onSale={onSale}
							offersTestDrive={hasTestDrive || false}
							isRentable={isRentable}
							soldOut={soldOut}
							saleOfTheWeek={saleOfTheWeek}
							itemsLeft={bike?.totalPhysicalStock as number}
							fontSizeXSm
							discount={discount}
							isRow
						/>
						<BikeNameAndDescSection
							bike={bike}
							bikeOrdersCount={bikeOrdersCount}
							isMobile={isMobile}
							shortDescription={shortDescription}
							isSecondHand={isSecondHand}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
							onSale={onSale}
							formatteduvpPriceForSecondHand={formatteduvpPriceForSecondHand}
							formattedPriceForSecondHand={formattedPriceForSecondHand}
							t={t}
							discount={discount}
						/>
					</div>
					<ConditionAndMileage isSecondHand={isSecondHand}
						mileage={mileage} />
					<div
						className="p-3 sizes-colors-wrapper">
						{!isSecondHand ?
							<div>
								<div className="d-flex gap-3 align-items-center">
									<Typography variant="bodySmBold"
										semanticTag="span">{t(" Farbe")}</Typography>
									<div className="col-auto d-inline-block">
										<Colors colorData={bike.colorData}
											highlightSelectedColor/>
									</div>
								</div>
								{!isAccessory &&
								<div className={"col-12"}>
									<RelatedProducts items={(bike.relatedAttributeBatterySizes)}
										label={t("Batteriekapazitäten")}
										bike={bike}/>
									<RelatedProducts items={bike.relatedMotorStrength}
										label={t("Motorisierungen")}
										bike={bike}/>
									<RelatedProducts items={bike.relatedCategoryFrames}
										label={t("Rahmenvarianten")}
										bike={bike}/>
								</div>}
							</div> : null
						}
						<Sizes
							shake={isShaking}
							selectedVariation={selectedVariation}
							variations={variations}
							scriptsRef={scriptsRef}
							isAccessory={isAccessory}
							bike={bike}
							setSelectedVariation={setSelectedVariation}
							setSelectedSize={setSelectedSize}
							selectedSize={selectedSize}/>
					</div>
					{/* Mobile */}
					<ConditionAndMileage isSecondHand={isSecondHand}
						mileage={mileage} />
					{bike.sku ?
						<div className={" col-auto mt-4 px-3"}>
							<div id=" onlinesizer-target">
								<button
									id=" my-custom-oz-button"
									className=" oz-trigger btn p-0"
									data-oz-code={bike.sku}
									data-oz-name={bike.name}
									data-oz-image={bike.images[0].src}
									data-oz-price={bikePrice}
									data-oz-fullscreen=" true"
								>
									<img className={" me-2"}
										src={"/assets/size-calculator-icon.svg"}
										width={20}
										height={" auto"}
										alt=" calculator"/>
									<u className={" text-bold"}>{t(" Grösse berechnen")}</u>
								</button>
							</div>
						</div> :
						null}
					{/* Mobile */}
					<div className="d-md-none mt-4">
						<DurationAndDownpayment
							startShake={startShake}
							addingToCart={addingToCart}
							handleCheckoutBtnClick={handleCheckoutBtnClick}
							product={bike}
							selectedSize={selectedSize}
							selectedVariation={selectedVariation}
							isMobile={isMobile}
							isSecondHand={isSecondHand}
							disabled={!selectedSize}
							isLoading={addingToCart}
							handleClick={handleCheckoutBtnClick}
							isAccessory={isAccessory}
							handleSizeClick={startShake}
							planLength={planLength}
							initialPrice={Number(bike.uvpPrice)}
							priceInputBesidesTitle
							isFinancingPopupOpen={isFinancingPopupOpen}
							handleFinancigPopupClose={() => setIsFinancingPopupOpen(false)}
							customInstanceDiscount={customInstanceDiscount}
						/>
					</div>
					<div className="px-3 container d-md-none  border-top col-11"/>
					<div className="px-3 px-md-0 pt-4 d-md-none">
						<FAQ mostAskedOnly
							isMiniFAQDesign/>
					</div>
					{/* Desktop */}
					<PaymentAndBookMeetingDesktopSection
						addingToCart={addingToCart}
						bike={bike}
						executeScroll={executeScroll}
						isOpen={isOpen}
						handleCheckoutBtnClick={handleCheckoutBtnClick}
						questions={questions}
						setActive={setActive}
						selectedSize={selectedSize}
						selectedVariation={selectedVariation}
						setAdditionalInfoDrawer={setAdditionalInfoDrawer}
						setAskAQuestion={setAskAQuestion}
						setIsOpen={setIsOpen}
						setIsSponsorMeModalOpen={setIsSponsorMeModalOpen}
						startShake={startShake}
					/>
				</div>
			</div>
		</div>
	)
}

export default ProductGalleryAndPaymentSection

import {useContext, useEffect, useState} from "react"
import FirebaseContext from "../context/FirebaseContext"
import {useRouter} from "next/router"
import {BikeType} from "../utility/Types"
import useCache from "./useCache"

export default function useGetRecentlyViewedBikes(recentBikesAlreadyFetched:BikeType[] = []) {
	const [recentlyViewedBikes, setRecentlyViewedBikes] = useState<any[]>(recentBikesAlreadyFetched)
	const {user} = useContext(FirebaseContext)
	const router = useRouter()
	const url = user ? `/api/products/recently-viewed/${user?.uid}?locale=${router?.locale}` : null
	const {data, isLoading, mutate: refetch} = useCache(url || "", true, true, true)

	useEffect(() => {
		if (data) {
			setRecentlyViewedBikes(data?.data)
		}
	}, [data, isLoading])

	useEffect(() => {
		refetch()
	}, [router, refetch])

	return {isLoading, recentlyViewedBikes, refetch}
}

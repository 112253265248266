import React, {useCallback, useContext, useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import FirebaseContext from "../context/FirebaseContext"
import {firebaseConfig} from "../firebase/config"
import {useRouter} from "next/router"
import debounce from "lodash.debounce"
import {
	FirestoreRoutes,
	getCheckoutsRouteAdmin,
	getProductQuestionRoute,
	getProductQuestionRouteAdmin,
	getSponsorMeListRouteAdmin,
	getSponsorMePaymentsListRouteAdmin
} from "../firebaseAdmin/routes"
import {fetchPost} from "../firebaseAdmin/fetchPost"
import {fetchGet} from "../firebaseAdmin/fetchGet"
import {
	handleGetAuth,
	handleGetFirestore,
	handleInitializeApp,
	handleOnAuthStateChanged
} from "../firebase/dynamicImportHandlers/dynamicImportHandlers"
import PartnershipUserLoginPopup from "../components/partnership/PartnershipUserLoginPopup"
import {Visibility} from "../auth/visibility"
import {signInWithPopup} from "@firebase/auth"
import OrganizationContext from "../context/OrganizationContext"

type Props = {
	pageProps: any,
    children: React.ReactNode
}

const FirebaseContextProvider: React.FC<Props> = ({pageProps, children}) => {
	const [app, setApp] = useState<any | null>(null)
	const [auth, setAuth] = useState<any | null>(null)
	const [user, setUser] = useState<any | null>(null)
	const [db, setDb] = useState<any | null>(null)
	const [userLoading, setUserLoading] = useState<boolean>(false)
	const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)
	const scrollPos = useRef<number>(0)
	const {locale, pathname} = useRouter()
	const [isUserPartOfOrganization, setIsUserPartOfOrganization] = useState(false)
	const [showLoginModal, setShowLoginModal] = useState(false)
	const {organizationData, organizationSlug} = useContext(OrganizationContext)
	const router = useRouter()
	const login = async () => {
		if (auth) {
			const {GoogleAuthProvider, signInWithPopup} = await import("@firebase/auth")
			const provider = new GoogleAuthProvider()
			if (provider) {
				const result = await signInWithPopup(auth, provider)
				console.log("Result", result)
				// Await linkWithRedirect(auth.currentUser, provider)
			} else {
				console.error("Firebase | No auth provider provided")
				return null
			}
		}
	}

	const logout = async () => {
		if (auth) {
			const {signOut} = await import("@firebase/auth")
			signOut(auth).catch(error => console.error(error))
		}
	}

	const postProductQuestion = async (bikeSlug: string, bikeName: string, sku: string, firstName: string, lastName: string, questionText: string, email: string) => {
		if (db && user) {
			try {
				return await fetchPost(FirestoreRoutes.postProductQuestion, {
					bikeSlug,
					bikeName,
					sku,
					firstName,
					lastName,
					questionText,
					email,
					idToken: await user.getIdToken(),
					locale
				})
			} catch (e) {
				console.error(e)
				return false
			}
		}

		console.error("Firebase | Post product question | Firestore not available or user not logged in.")
		return false
	}

	const getProductQuestions = async (bikeSlug: string) => {
		if (db && user) {
			try {
				const questions = await fetchGet(getProductQuestionRoute(bikeSlug, locale))
				return await questions.json()
			} catch (e) {
				console.error(e)
				return false
			}
		}
	}

	const getProductQuestionsAdmin = async () => {
		if (db && user) {
			try {
				const idToken = await user.getIdToken()
				const questions = await fetchGet(getProductQuestionRouteAdmin(locale, idToken))
				return await questions.json()
			} catch (e) {
				console.error(e)
				return false
			}
		}
	}

	const getSponsorMeListAdmin = async () => {
		if (db && user) {
			try {
				const idToken = await user.getIdToken()
				const questions = await fetchGet(getSponsorMeListRouteAdmin(idToken))
				return await questions.json()
			} catch (e) {
				console.error(e)
				return false
			}
		}
	}

	const getSponsorMePaymentsListAdmin = async () => {
		if (db && user) {
			try {
				const idToken = await user.getIdToken()
				const questions = await fetchGet(getSponsorMePaymentsListRouteAdmin(idToken))
				return await questions.json()
			} catch (e) {
				console.error(e)
				return false
			}
		}
	}

	const handleScroll = useCallback(debounce(() => {
		const newScrollY = window.scrollY
		if (newScrollY > 1100) {
			setShowLoginModal(true)
		}
	}, 300), [scrollPos])

	useEffect(() => {
		window.addEventListener("scroll", handleScroll)
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [handleScroll])
	const getCheckoutsAdmin = async () => {
		if (db && user) {
			try {
				const idToken = await user.getIdToken()
				const checkouts = await fetchGet(getCheckoutsRouteAdmin(locale, idToken))
				return await checkouts.json()
			} catch (e) {
				console.error(e)
				return false
			}
		}
	}

	// Initialize Firebase app
	useEffect(() => {
		handleInitializeApp().then(app => setApp(app))
	}, [firebaseConfig])

	// If app has been initialized correctly, initialize auth
	useEffect(() => {
		if (app) {
			handleGetAuth().then(auth => setAuth(auth))
		}
	}, [app])

	useEffect(() => {
		if (user) {
			setUserLoading(false)
		}
	}, [user])

	// If auth has been initialized correctly, set up auth state event and try to sign in anonymously
	useEffect(() => {
		if (auth) {
			handleOnAuthStateChanged(auth, setUserLoading, setUser).then()
		}
	}, [auth])

	// Initialize Firestore
	useEffect(() => {
		if (app) {
			handleGetFirestore(app).then(db => setDb(db))
		}
	}, [app])

	useEffect(() => {
		if (router.pathname === "/produkt/[bike]") {
			setShowLoginModal(true)
		}
	}, [router])

	useEffect(() => {
		if (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE) {
		// Check if user is allowed to access
			setIsAuthenticating(true)
			if (db && user && (organizationSlug ? pageProps.visibility?.[organizationSlug] === Visibility.private : pageProps.visibility === Visibility.private) && process.env.NEXT_PUBLIC_ENVIRONMENT !== "local") {
				user.getIdToken().then(idToken => {
					fetchPost("/api/auth/isUserPartOfOrganization", {
						idToken
					}).then(res => {
					// Show or hide login popup
						setIsUserPartOfOrganization(res.status === 200)
						setIsAuthenticating(false)
					}).catch(err => {
						setIsAuthenticating(false)
						console.error("Error in checking if user Part of the organization:" + err)
					})
				})
			}
		}
	}, [db, pathname, user])

	return (
		<FirebaseContext.Provider value={{
			app,
			auth,
			db,
			user,
			login,
			logout,
			postProductQuestion,
			userLoading,
			getProductQuestions,
			getProductQuestionsAdmin,
			getCheckoutsAdmin,
			getSponsorMeListAdmin,
			getSponsorMePaymentsListAdmin
		}}>
			{children}
			{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && !isUserPartOfOrganization && showLoginModal && !isAuthenticating &&
				<PartnershipUserLoginPopup visibility={pageProps.visibility?.[organizationSlug]}
					organizations={pageProps?.strapiContent?.data?.organizations} />}
		</FirebaseContext.Provider>
	)
}

FirebaseContextProvider.propTypes = {
	pageProps: PropTypes.any,
	children: PropTypes.node
}

export default FirebaseContextProvider

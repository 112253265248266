import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {findBikeAttributeBySlug, findBikeMetaFieldByName} from "../../../utility/Helper"

type Props = {
    variation: any[]
}

const SizeItem: React.FC<Props> = ({variation}) => {
	const sizeAttr = findBikeAttributeBySlug(variation, "sizes", false, true)

	const sizeAttribute = sizeAttr !== "No Data" ? sizeAttr :
		findBikeAttributeBySlug(variation, "seat-tube-length", false, true)
	const [size, sizeLabel] = sizeAttribute.split(": ")
	const bikerHeight = findBikeMetaFieldByName(variation, "biker_height")

	return (
		<li className="col-6 col-md-4 d-flex align-items-center gap-3">
			<Typography variant="heading1Black"
				semanticTag="h3"
				className="text-secondary mb-0">
				{size}
			</Typography>

			<div>
				<Typography variant="bodySm"
					semanticTag="h5"
					className="mb-0">
					{sizeLabel}
				</Typography>
				<Typography variant="heading3MdBodyLgBold"
					semanticTag="h4"
					className="mb-0"
					style={{lineHeight: "32px"}}>
					{bikerHeight}
				</Typography>
			</div>
		</li>
	)
}

export default SizeItem

import React from "react"
import Typography from "../reusable/Typography/Typography"

type Props = {
    handleClick: () => void
    suggestion: string
    isAutoCorrect?: boolean
    caractersToHighlight?: number
}
function SuggestionItem({handleClick, suggestion, caractersToHighlight, isAutoCorrect}:Props) {
	return (
		<div onClick={handleClick}
			className="d-flex align-items-center justify-content-start">
			{isAutoCorrect ? <Typography variant={"bodySm"}
				semanticTag={"div"}
				className={"mb-0 cursor-pointer"}>
				{suggestion}
			</Typography> :
				<><Typography variant={"bodySmBold"}
					semanticTag={"div"}
					className={"mb-0 cursor-pointer"}>
					{suggestion.slice(0, caractersToHighlight)}
				</Typography>
				<Typography variant={"bodySm"}
					semanticTag={"div"}
					className={"mb-0 cursor-pointer"}>
					{suggestion.slice(caractersToHighlight)}
				</Typography></>}
		</div>
	)
}

export default SuggestionItem

import React from "react"
import Button from "../../reusable/components/Button"
import {useTranslation} from "next-i18next"
import {ExclamationCircle} from "react-bootstrap-icons"
import {StockStatus} from "../BikeDetails"
import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"

interface Props {
	product: any
  disabled: boolean
	isLoading: boolean
  handleClick: (directPayment?:any) => void
  handleSizeClick: () => void
  stockStatus?: string
  isSecondHand?: boolean
  secondHandPrice?: number
	planLength?: number
	isCartCompatibility?: boolean
	hideLabels?:boolean
}

export const CheckoutBtn = ({
	disabled,
	isLoading,
	hideLabels,
	handleSizeClick,
	handleClick,
	stockStatus = StockStatus.inStock
}: Props) => {
	const {t} = useTranslation(["common", "productDescrPage"])
	const label = stockStatus === StockStatus.inStock ? t("In den Warenkorb") :
		stockStatus === StockStatus.onBackOrder ? t("checkout-btn-pre-order") :
			t("In den Warenkorb")
	return (
		<div className="row">
			{hideLabels ? <div className="col-12">
				{disabled ?
					<a onClick={handleSizeClick}
						href="#sizes-button"
						style={{height: 40, width: "88px"}}
						className="text-decoration-none text-dark bg-primary d-flex justify-content-center
                        align-items-center rounded p-3">
						<Image height={17}
							width={17}
							src="/assets/icons/cart.svg" />
					</a> :
					<Button bgColor="#FEC04D"
						textColor="#212529"
						color="#212529"
						style={{height: 40, width: "88px"}}
						className=" py-3 data-cypress-checkout-btn d-flex gap-2 justify-content-center
                        align-items-center"
						spinnerColor="#212529"
						label={label}
						high
						disabled={disabled}
						isLoading={isLoading}
						onClick={handleClick}
						isNoUppercase
					>
						<Image height={17}
							width={17}
							src="/assets/icons/cart.svg" />
					</Button>
				}
			</div> : <div className="col-12">
				{disabled ?
					<a onClick={handleSizeClick}
						href="#sizes-button"
						style={{height: 48}}
						className="text-decoration-none btn-hover bg-primary text-dark d-flex justify-content-center
                        align-items-center rounded p-3">
						<Image height={17}
							width={17}
							src="/assets/icons/cart.svg" />
						<Typography variant={"bodySmBold"}
							semanticTag={"span"}
							className={"ms-3"}>
							{t("fixed-btn-select-size", {ns: "productDescrPage"})}
						</Typography>
					</a> :
					<Button bgColor="#FEC04D"
						textColor="#212529"
						color="#212529"
						paddingY="0"
						className="w-100 py-2 data-cypress-checkout-btn"
						spinnerColor="#212529"
						label={label}
						high
						disabled={disabled}
						isLoading={isLoading}
						onClick={handleClick}
						style={{height: 48}}
						isNoUppercase
					>
						<div className="d-flex gap-2 justify-content-center align-items-center">
							<Image height={17}
								width={17}
								src="/assets/icons/cart.svg" />
							<Typography style={{fontWeight: 800}}
								variant={"bodySmBold"}
								semanticTag="span"
								className={"text-capitalize"}>
								{`${t("In den Warenkorb")}`}
							</Typography>
						</div>
					</Button>
				}
			</div>}

		</div>
	)
}

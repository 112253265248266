import React from "react"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import UpsellModalContent from "../../reusable/ModalsContent/UpsellModalContent"
import CartCompatibilityModalContent from "../../reusable/ModalsContent/CartCompatibilityModalContent"

type Props = {
	setAddingToCart: (bolean)=> void
    isCartCompatibility: boolean
    closeModal?: () => void
    currentCompBike: object
    showUpsellModal: boolean
	deals?:number
    bike: any,
}

const UpsellModal:React.FC<Props> = ({
	isCartCompatibility,
	showUpsellModal,
	setAddingToCart,
	currentCompBike,
	closeModal,
	deals,
	bike
}) => {
	return (
		<AnimationComponent inProp={showUpsellModal}
			type={"fade"}
		>
			<div className="position-fixed "
				style={{
					height: "100%",
					width: "100%",
					backgroundColor: "rgba(0,0,0,0.64)",
					zIndex: 999999999,
					top: 0, left: 0
				}}
				onClick={closeModal}
			>
				<div className={"container-fluid"}>
					<div className={"row justify-content-center align-items-start align-items-md-center pt-3 pt-md-0 vh-100"}>
						<div className={"col-auto p-0"}
							onClick={e => e.stopPropagation()}>
							{isCartCompatibility ?
								<UpsellModalContent deals={deals}
									bike={bike}
									sellProducts={bike.crossSellProducts}
									setAddingToCart={setAddingToCart}
									onModalClose={closeModal}
								/> :
								<CartCompatibilityModalContent closeModal={closeModal}
									currentBike={currentCompBike} />
							}
						</div>
					</div>
				</div>
			</div>
		</AnimationComponent>
	)
}

export default UpsellModal

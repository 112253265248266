import React, {useContext, useState} from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import AskAiAboutMbp from "../../reusable/AskAiAboutMbp"
import LayoutContext from "../../../context/LayoutContext"
import {ModalContext} from "../../../context/ModalContext"
import MobileModal from "../../reusable/MobileModal"
import RyderAiStaticTile from "../../bikesCollection/staticTiles/RyderAiStaticTile"
import Image from "next/image"
import {colors} from "../../../colors/Colors"
import Button from "../../reusable/components/Button"

function RyderAiFreeService() {
	const predefinedQuestions = [
		"plp-ai-predefined-question-1",
		"plp-ai-predefined-question-2",
		"plp-ai-predefined-question-3"
	]
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	const {setModalContent, openDrawer} = useContext(ModalContext)

	const [isOpen, setIsOpen] = useState(false)
	const [input, setInput] = useState("")
	const [askedQuestion, setAskedQuestion] = useState("")
	const handleOpen = (askedQuestionParam = null) => {
		if (isMobile === true) {
			setAskedQuestion(askedQuestionParam ? askedQuestionParam : input)
		} else {
			setModalContent(<AskAiAboutMbp askedQuestion={askedQuestionParam ? askedQuestionParam : askedQuestion}

			/>)
			openDrawer()
		}

		setIsOpen(true)
	}

	return (
		<>
			<MobileModal modalOpen={isOpen && isMobile}
				isAiModal
				handleClose={() => setIsOpen(false)} >
				<div style={{minHeight: "60dvh", overflowY: "auto", marginBottom: isMobile ? "5rem" : "none", zIndex: "99999999999999999"}}>
					<RyderAiStaticTile askedQuestion={askedQuestion}
						customLayoutClassname="d-flex align-items-start justify-content-start gap-3  col-12 h-75 flex-column" />
				</div>
			</MobileModal>
			<div className="d-flex align-items-start justify-content-start col-12 gap-3 ">
				<div
					className="position-relative free-service-image-wrapper d-flex align-items-center rounded-3 justify-content-center"
					style={{width: "40px", height: "40px", padding: "8px 10px", backgroundColor: "#F8F8F8"}}><Image
						src={"/assets/icons/ryder-logo-primary.svg"}
						alt="Service Image"
						width={24}
						height={24}
						layout="fixed"

						style={{borderRadius: "4px", minWidth: "24px"}}
					/>
				</div>
				<div style={{flex: 1}}
					className="overflow-auto d-flex flex-column align-items-start justify-content-start">
					<div style={{gap: "8px", maxWidth: "100%", overflowX: "auto"}}
						className=" d-flex hide-overflow-scrollbar align-items-start justify-content-start ">
						{predefinedQuestions.map((question, index) => <div key={index}
							onClick={() => {
								handleOpen(t(question))
							}}
							className="predefined-ai-question">
							<Typography style={{
								fontSize: "14px",
								lineHeight: "20px", color: "#750F9B"
							}}
							variant="bodySm">{t(question)}</Typography>
						</div>)}
					</div>
					<Button bgColor={colors.primary}
						textColor="#fff"
						onClick={handleOpen}
						className="try-ai position-relative"
						fontSizeSmall
						style={{marginTop: "16px"}}
						label={isMobile ? null : t("ask-something-else")}
						iconFirst={!isMobile}
						icon={
							<Image src={"/assets/icons/ryder-logo.svg"}
								width={24}
								height={24} />
						} />
				</div>
			</div></>
	)
}

export default RyderAiFreeService

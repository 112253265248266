import React, {useContext, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {Visibility} from "../../auth/visibility"
import Modal from "../reusable/components/Modal/Modal"
import Image from "next/image"
import FormPartnersLogin from "../forms/FormPartnersLogin"
import {content, tabItems} from "../../constants/Instances"
import OrganizationContext from "../../context/OrganizationContext"
import DataContext from "../../context/DataContext"
import {getOrganizationDataFromStrapi} from "../../utility/strapi/helpers"

type Props = {
	visibility: string
	organizations: any
}

const PartnershipUserLoginPopup = ({visibility, organizations}:Props) => {
	const {organizationSlug} = useContext(OrganizationContext)
	const organizationData = getOrganizationDataFromStrapi(organizations, organizationSlug)

	const [magicLinkSent, setMagicLinkSent] = useState(false)
	const [error, setError] = useState(null)
	const {t} = useTranslation()
	const checkmarkSrc = "/assets/icons/checkmark.svg"

	const getter = key => {
		const resultData = organizationData?.loginPopup[key] || null

		if (key === "bulletPoints") {
			return resultData?.split(",")
		}

		return resultData
	}

	return (visibility === Visibility.private && getter("logo") && organizationData &&
		<Modal closeModal={() => null}
			isOpen={visibility === Visibility.private} >
			<>
				{/* Desktop modal starts here */}
				<div className="newsletter-modal transform-all  bg-white overflow-hidden d-none d-md-block px-3 "
					style={{borderRadius: 32, maxWidth: 1024}}>
					<div className="row position-relative mt-md-3 mx-0 justify-content-between">
						<div className="col-12 col-md-7 bg-white d-flex justify-content-center align-items-start">
							<div className="container d-flex position-relative align-start  flex-column px-0 px-md-4 pb-5 pb-lg-0">
								<div className="d-flex gap-3 mt-4"
									style={{marginBottom: 50, flexWrap: "wrap", minWidth: "25rem"}}>
									{getter("logo") && <img alt={organizationData?.loginPopup?.title}
										src={getter("logo")}
										height={17}
										width={"auto"} />}
									{tabItems?.map((item, index) => <Image key={index}
										alt={item.title}
										src={item.iconSrc}
										height={17}
										width={130}
										style={{marginTop: "0.4rem", height: "100%"}}/>)}
								</div>
								{magicLinkSent ? <div style={{minHeight: "34rem"}}
									className="d-flex flex-column gap-4 justify-content-center align-items-center pb-1">
									<Image src="/assets/icons/green_check.png"
										alt="success"
										height={38}
										width={37}
										className="rotateAnimation" />
									<Typography variant={"bodySmBold"}
										className="container py-8 text-success text-center">{t("check_email")}</Typography></div> : <div className="row mb-md-4 ">
									<div className="row d-none d-md-flex">
										<Typography className="col-14 mb-3"
											variant="heading1Black"
											style={{lineHeight: "24px"}}>{t(getter("title"))}</Typography>
										<Typography className="col-18 my-4"
											variant="bodyLg"
											semanticTag="h3">{t(getter("subTitle"))}</Typography>
										<ul style={{listStyle: "none"}}
											className="d-flex flex-column column-gap-2 px-3">
											{getter("bulletPoints")?.map((item, index) => (
												<li className="d-flex justify-content-start align-items-center gap-2"
													key={index}>

													<Image width={25}
														objectFit="contain"
														height={25}
														src={checkmarkSrc}
														alt={`Checkmark ${index + 1}`} />
													{<Typography className="col-10 mb-0"
														variant="bodyLg"
														semanticTag="h3">{t(item)}</Typography>}
												</li>
											))}
										</ul>
									</div>
								</div>}
								<FormPartnersLogin error={error}
									setError={setError}
									magicLinkSent={magicLinkSent}
									setMagicLinkSent={setMagicLinkSent} />
							</div>
						</div>

						<div className="col-md-5 p-0 position-relative  d-none d-md-block"
							style={{marginRight: -16, marginTop: -16}}>
							<Image src="/assets/images/biking.png"
								alt="bike"
								layout="fill"
								className="ml-auto"
								style={{width: "100%", height: "100%", marginLeft: "auto"}} />

						</div>
					</div>
				</div>
				{/* Desktop modal ends here */}
				{/* Mobile modal starts here */}
				<div className="d-md-none">
					{visibility === Visibility.private &&
								<Modal isOpen={visibility === Visibility.private}
									closeModal={() => null}>
									<div className="slide-up d-flex justify-content-start flex-column"
										style={{position: "absolute", bottom: 0, left: 0, right: 0, background: "white", borderTopLeftRadius: "2rem", borderTopRightRadius: "2rem", zIndex: 9999999}}>
										<div className="row mb-1 mt-1 justify-content-between align-items-center ">
										</div>
										<div className=" d-flex justify-content-center mt-2 align-items-center flex-column">

											<div className="col-10 d-flex justify-content-start align-items-start  gap-4"
												style={{marginBottom: 25, flexWrap: "wrap"}}>
												{getter("logo") && <img alt={organizationData?.loginPopup?.title}
													src={getter("logo")}
													height={"auto"}
													width={40}
													style={{marginTop: "0.4rem", height: "100%"}}/>}
												{tabItems?.map((item, index) => <img key={index}
													alt={item.title}
													src={item.iconSrc}
													height={18}
													width={140}
													style={{marginTop: "0.4rem", height: "100%"}}/>)}
											</div>
											{magicLinkSent ? <div style={{minHeight: "20rem"}}
												className="d-flex flex-column gap-4 justify-content-center align-items-center col-12 pb-1">
												<Image src="/assets/icons/green_check.png"
													alt="success"
													height={38}
													width={37}
													className="rotateAnimation"/>
												<Typography variant={"bodySmBold"}
													className="container py-8 text-success text-center">{t("check_email")}</Typography></div> :
												<div className="col-10 pb-1 d-flex flex-column justify-content-start align-items-center">
													<div className="row d-flex">
														<Typography className="col-14 mb-3 "
															variant="heading1Black">{t(getter("title"))}</Typography>
														<Typography className="col-18 my-4"
															variant="bodyLg"
															semanticTag="h3">{t(getter("subTitle"))}</Typography>
														<ul style={{listStyle: "none"}}
															className="d-flex flex-column gap-2 ">
															{getter("bulletPoints")?.map((item, index) => (
																<li className="d-flex justify-content-start align-items-center gap-2 "
																	key={index}>

																	<Image width={25}
																		objectFit="contain"
																		height={25}
																		src={checkmarkSrc}
																		alt={`Checkmark ${index + 1}`} />
																	{<Typography className="col-10 mb-0"
																		variant="bodyLg"
																		semanticTag="h3">{t(item)}</Typography>}
																</li>
															))}
														</ul>
													</div>

													<FormPartnersLogin error={error}
														setError={setError}
														magicLinkSent={magicLinkSent}
														setMagicLinkSent={setMagicLinkSent} />
												</div>}

										</div>
									</div>
								</Modal>}</div>
				{/* Mobile modal ends here */}
			</>
		</Modal>
	)
}

export default PartnershipUserLoginPopup

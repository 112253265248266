import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import {X} from "react-bootstrap-icons"
import PropTypes from "prop-types"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import ContactFormOnProductList from "../forms/ContactFormOnProductList"
import Typography from "../reusable/Typography/Typography"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
    setAskAQuestion: (onOff: boolean) => void
}

const AskAQuestion: React.FC<Props> = ({setAskAQuestion}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	return (
		<div className={"container"}>
			<div className={"row justify-content-center align-items-center vh-100"}>
				<div className={"col-12 col-md-12 col-lg-12"}>
					<div className={"bg-white"}
						onClick={e => {
							e.stopPropagation()
						}}>
						<div className={"row"}>
							<div className={"col-md-5 d-none d-md-block"}
								style={{
									backgroundImage: "url('/assets/images/focus-bikes-bosch-e-mtb-e-is-for-everyone-display-off 1.jpeg')",
									backgroundSize: "cover",
									backgroundPosition: "center center"
								}}/>
							<div className={"col-md-7"}>
								<div className={"row justify-content-end"}>
									<div className={"col-auto"}>
										<div className={"p-2"}>
											<button type={"button"}
												className={"btn"}
												onClick={() => {
												// Log event
													reactGA?.event({
														category: gaCategories.support,
														action: gaEvents.askAQuestion,
														label: gaEvents.askAQuestion,
														nonInteraction: false
													})
													setAskAQuestion(false)
												}}>
												<X size={36}
													color={"#cccccc"}/>
											</button>
										</div>
									</div>
								</div>
								<div className={"ps-5 pe-5 pb-5"}>
									<Typography variant="heading2"
										semanticTag="h3"
										className={"mb-4"}>
										{t("Stelle eine Frage")}
									</Typography>
									<Typography variant="bodyLg"
										semanticTag="p"
										className={"text-secondary"}>
										{t("Fragen rund um Grösse, Modell oder Finanzierung? Stelle uns alle deine Fragen hier, wir helfen gerne.")}
									</Typography>
									<div className="row">
										<ContactFormOnProductList/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

AskAQuestion.propTypes = {
	setAskAQuestion: PropTypes.func.isRequired
}

export default AskAQuestion

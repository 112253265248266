import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import ABContext from "../context/ABContext"
import {getCookie} from "cookies-next"
import {cookies, variants} from "../middleware"
import {useRouter} from "next/router"

type Props = {
    children: React.ReactNode
}

const ABContextProvider: React.FC<Props> = ({children}) => {
	const [variant, setVariant] = useState<string | null>(null)
	const {query} = useRouter()

	useEffect(() => {
		if (query.variant === variants.forceNew) {
			setVariant(variants.new as string)
		} else if (query.variant === variants.forceOld) {
			setVariant(variants.old as string)
		}
	}, [query])

	useEffect(() => {
		setVariant(getCookie(cookies.mbp_app_nav_variant) as string)
	}, [])

	return (
		<ABContext.Provider value={{variant}}>
			{children}
		</ABContext.Provider>
	)
}

ABContextProvider.propTypes = {
	children: PropTypes.node
}

export default ABContextProvider


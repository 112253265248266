import React, {Dispatch, SetStateAction, useContext, useEffect} from "react"
import Typography from "./Typography/Typography"
import Button from "./components/Button"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../context/LayoutContext"

type Props = {
    input: string
    isGenerating: boolean
    suggestedPrompts: any[]
    setInput: Dispatch<SetStateAction<string>>
    setStep: Dispatch<SetStateAction<1 | 2 | 3>>
	step: 1 | 2 | 3
	isAiSearchLandingPage?:boolean
    generateUserPrompts:(e:any)=>void
    resetState:()=>void
}
function AskAiAboutLifestyle({input, resetState, isGenerating, step, setStep, suggestedPrompts, setInput, isAiSearchLandingPage, generateUserPrompts}: Props) {
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	useEffect(() => {
		let timeout
		if (step === 1 && isGenerating && !isAiSearchLandingPage) {
			timeout = setTimeout(() => {
				setStep(() => 2)
			}, 2000)
		} else if (isAiSearchLandingPage && isGenerating) {
			setStep(() => 2)
		}

		return () => clearTimeout(timeout)
	}, [step, isGenerating, suggestedPrompts])
	if (step > 1 && !isAiSearchLandingPage) {
		return <></>
	}

	return (
		<div style={{maxWidth: isAiSearchLandingPage ? "800px" : "none"}}
			className={`d-flex align-items-start justify-content-start flex-column gap-1 mt-4 col-12  ${isGenerating && !isAiSearchLandingPage ? "slide-left-exit" : (suggestedPrompts?.length || step > 1) && !isAiSearchLandingPage ? "d-none" : ""}`}>
			<Typography semanticTag="div"
				style={{fontSize: "14px", lineHeight: "20px"}}
				variant="bodySmBold">{t("lifestyle-label")}</Typography>
			<div style={{borderRadius: isAiSearchLandingPage ? "4px" : "16px"}}
				className="send-message m-auto" >
				<textarea
					className="input-field resize-none"
					value={input}
					style={{resize: "none"}}
					rows={2}
					onChange={e => {
						setInput(e.target.value)
					}}
					placeholder={t("ai-text-area-example")}
					required />
				{isAiSearchLandingPage && !isMobile ? <div onClick={e => {
					resetState()
					generateUserPrompts(e)
				}}
				className="try-ai d-flex align-items-center justify-content-center cursor-pointer"
				style={{minWidth: "48px", minHeight: "48px", borderRadius: "4px"}} ><Image src="/assets/icons/stars-ai.svg"
						alt="ai"
						width={24}
						height={24} /></div> : null}

			</div>
			{isAiSearchLandingPage && !isMobile ? null : <Button onClick={e => {
				resetState()
				generateUserPrompts(e)
			}}
			bgColor={"#A219D4"}
			iconFirst
			fontSizeSmall
			textColor="#fff"
			label={t("ask-ai")}
			className="col-12 try-ai"
			type="submit"
			icon={<Image src={"/assets/icons/ryder-logo.svg"}
				width={24}
				height={24} />} />}
		</div>
	)
}

export default AskAiAboutLifestyle

import React, {useContext, useState} from "react"
import Button from "../../../reusable/components/Button"
import {getMySofaPlanUrl, otherInstancesCategoryTitles} from "../../../../constants/Instances"
import {filterUrl} from "../../../filter/FilterUrl"
import urlParams from "../../../../utility/UrlParams"
import {gaCategories, gaEvents} from "../../../../config/googleAnalytics/events"
import Typography from "../../../reusable/Typography/Typography"
import TopCategoriesMegaMenu from "../../TopCategoriesMegaMenu"
import {generateUUID} from "../../../../utility/UUID"
import Link from "next/link"
import {CollectionName} from "../../../../constants"
import styles from "../../navigation.module.scss"
import LinkWithIcon from "../../../reusable/components/LinkWithIcon"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import DataContext from "../../../../context/DataContext"
import {formatStrapiData} from "../../../../strapi/strapiHelpers"
import {colors} from "../../../../colors/Colors"
import OrganizationContext from "../../../../context/OrganizationContext"
import {findTopBrandsPerCategory} from "../../../../utility/helpers/findTopBrandsPerCategory"
import AnalyticsContext from "../../../../context/AnalyticsContext"

interface Props {
	toggleMenu?: (router, pathname) => void
	item?: any,
	megaMenuTopCategories?:any[]
}

const MobileMegaMenuItem:React.FC<Props> = ({toggleMenu = () => {}, item, megaMenuTopCategories}) => {
	const {t} = useTranslation("common")
	const router = useRouter()
	const {query, pathname} = router

	// Context

	const {brands, height, brandWorld, accessoryFilters, pageProps: {strapiContent}} = useContext(DataContext)

	const mega_menus = strapiContent?.data?.mega_menus

	const top_brands_per_categories = strapiContent?.data?.top_brands_per_categories
	const items = formatStrapiData(mega_menus, ["topCategories", "title", "buttonTitle", "link", "slugName", "allBrandsLink", "hideButton", "isDropDown", "showSuggestionSection", "showHeight"])
	const dataItem = items.find(({slugName}) => slugName === item?.slugName || item?.categorySlug)

	// Design variables
	const {primary} = colors

	// States/conditionals variables for MegaMenu

	const activeItemTitle = dataItem?.topCategories?.[0]?.title || dataItem?.name
	const activeLink = dataItem.link || dataItem?.slugName || item?.categorySlug
	const [activeItemLink, setActiveItemLink] = useState(activeLink)
	const [activeItem, setActiveItem] = useState(activeItemTitle)

	const [loading, setLoading] = useState(false)
	const {hideButton, showHeight, allBrandsLink} = dataItem

	// Brands for MegaMenu
	const accessoriesBrands = accessoryFilters?.find(filter => filter.slug === "brand")
	const sortedBrands = brands?.length ? brands.sort((a, b) => a?.fields?.menuLabel?.localeCompare(b?.fields?.menuLabel)) : brands
	const totalBrands = sortedBrands?.length
	const brandsPerColumn = Math.ceil(totalBrands / 3)

	// Vars
	const {organizationSlug} = useContext(OrganizationContext)
	const accessoriesTypes = accessoryFilters?.find(filter => filter.slug === "accessory-type")

	// Top brands for MegaMenu
	const topBrandsForMegaMenu = brands.filter(({fields}) => fields?.addToMegaMenu)
	const topBrandsList = findTopBrandsPerCategory(activeItem || activeItemTitle, top_brands_per_categories, brands)
	const isBrandWorld = router.pathname.includes("/brand-world")
	const onBtnClick = () => {
		sessionStorage.setItem("page", (0).toString())
		sessionStorage.setItem("scrollPosition", (0).toString())
		setLoading(true)
		toggleMenu(router, pathname)
	}

	const {reactGA} = useContext(AnalyticsContext)
	const accessoriesPageLink = isBrandWorld ? `${filterUrl({[urlParams.categories]: t("accessories-url-slug")}, urlParams.categories, t("accessories-url-slug"), CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({[urlParams.categories]: t("accessories-url-slug")}, urlParams.categories, t("accessories-url-slug"))
	const isAccessoriesActive = activeItemLink === "zubehoer" || activeItemLink === "accessoires"
	return (
		<div style={{background: isBrandWorld ? "#212529" : "#fff"}}>
			{/* Button */}
			{!hideButton &&
				<Button label={item?.buttonTitle ? item?.buttonTitle : t(item?.title)}
					textColor={"#212529"}
					color="#212529"
					bgColor={primary}
					outline={false}
					paddingY="2"
					fontSizeSmall
					href={isAccessoriesActive ? accessoriesPageLink : otherInstancesCategoryTitles.includes(item.title) ?
						getMySofaPlanUrl(organizationSlug, "-", "-", "-") : item?.slugName === "e-bikes" ? isBrandWorld ? `${filterUrl({[urlParams.categories]: item?.slugName || item?.categorySlug}, urlParams.categories, item.slug, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({[urlParams.categories]: item?.slugName || item?.categorySlug}, null, null) :
							(item?.slugName === "nicht-elektrische-bikes" || item?.slugName === "velos-non-electriques") ? isBrandWorld ? `${filterUrl({[urlParams.categories]: item?.slugName || item?.categorySlug}, urlParams.categories, item.slug, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({[urlParams.categories]: item?.slugName || item?.categorySlug}, null, null) : isBrandWorld ? `${filterUrl({}, null, null, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({}, null, null)}
					className={"w-100"}
					isLoading={loading}
					onClick={() => {
						sessionStorage.setItem("page", (0).toString())
						sessionStorage.setItem("scrollPosition", (0).toString())

						onBtnClick()
						reactGA?.event({
							category: gaCategories.megaMenu,
							action: gaEvents.allEBikesButton,
							label: gaEvents.allEBikesButton,
							nonInteraction: false
						})
					}}/>}

			{/* MegaMenu */}
			{/* Top Categories */}
			<div className="d-flex mt-4 justify-content-between flex-wrap"
				style={{rowGap: 8}}>
				{dataItem.topCategories.length ?
					<div style={{maxWidth: 156, width: "100%"}}>
						<Typography variant="bodySmBold"
							className="px-3"
							style={{marginBottom: 12, color: isBrandWorld ? "#fff" : "#212529"}}>{t("Categories")}</Typography>
						<TopCategoriesMegaMenu onBtnClick={onBtnClick}
							hasItemArrow={false}
							megaMenuTopCategories={dataItem.topCategories}/>
					</div> :
					isAccessoriesActive ?
						<div style={{maxWidth: 156, width: "100%"}}>
							<Typography variant="bodySmBold"
								style={{marginBottom: 12, color: isBrandWorld ? "#fff" : "#212529"}}
								className="px-3">{t("Categories")}</Typography>

							{accessoriesTypes ? accessoriesTypes?.options?.map(brand =>
								<div key={generateUUID()}
									className="col-auto"
									onClick={onBtnClick}>
									<Link
										href={isBrandWorld ? `${filterUrl({"accessory-type": brand.slug}, "accessory-type", brand.slug, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({page: "-"}, "accessory-type", brand.slug, CollectionName.Accessories, "Accessories")}>
										<a className={`text-decoration-none rounded-3 height-link d-block ${styles.megamenuItem}`}>
											<Typography
												style={{color: isBrandWorld ? "#fff" : "#212529"}}
												variant={`${query?.type === brand.slug ? "bodyLgBold" : "bodySm"}`}>{t(brand.name)}</Typography>
										</a>
									</Link>
								</div>
							) : null}
						</div> : null
				}

				{showHeight &&
					<div style={{maxWidth: 156, width: "100%"}}>
						<Typography variant="bodySmBold"
							style={{marginBottom: 12, color: isBrandWorld ? "#fff" : "#212529"}}
							className="px-2">{t("Your height")}</Typography>
						{
							height?.options?.map(({name, slug}) =>
								<div key={generateUUID()}
									className="col-auto"
									onClick={onBtnClick}>
									<Link href={isBrandWorld ? `${filterUrl({[urlParams.categories]: activeItemLink, [urlParams.categories]: activeItemLink}, urlParams.bikerHeight, slug, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({
										[urlParams.categories]: activeItemLink,
										[urlParams.bikerHeight]: slug
									}, null, null)}>
										<a style={{backgroundColor: query.bikerHeight === slug && "#eff1f3"}}
											className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
											<Typography
												style={{color: isBrandWorld ? "#fff" : "#212529"}}
												variant={`${query.bikerHeight === slug ? "bodySmBold" : "bodySm"}`}>{name} </Typography>
										</a>
									</Link>
								</div>
							)
						}
					</div>}
				<div style={{maxWidth: 156, width: "100%", height: "auto"}}>
					{(item?.slugName === "e-bikes" || item?.slugName === "e-mountain") &&
						<div className="px-3 pb-3">
							<div style={{border: "1px solid #DEE2E6"}}/>
						</div>}

					{isAccessoriesActive ?
						<div><Typography variant="bodySmBold"
							style={{marginBottom: 12, color: isBrandWorld ? "#fff" : "#212529"}}
							className="px-3">{t("top-brands")}</Typography>
						<ul className="list-unstyled">
							{accessoriesBrands ? accessoriesBrands?.options?.map(brand =>
								<div key={generateUUID()}
									className="col-auto"
									onClick={onBtnClick}>
									<Link href={filterUrl({
										[urlParams.brand]: brand.slug
									}, null, null)}>
										<a
											style={{backgroundColor: query.brand === brand.slug && "#eff1f3"}}
											className={`text-decoration-none rounded-3 
                                                        d-inline-block ${styles.megamenuItem}`}>
											<Typography
												style={{color: isBrandWorld ? "#fff" : "#212529"}}
												variant={`${query.brand === brand.slug ?
													"bodySmBold" : "bodySm"}`}>
												{brand.name}
											</Typography>
										</a>
									</Link>
								</div>
							) : null}
						</ul>
						</div> :
						null}

					{!isAccessoriesActive && <div>
						<Typography variant="bodySmBold"
							style={{marginBottom: 12, color: isBrandWorld ? "#fff" : "#212529"}}
							className="px-3">{t("top-brands")}</Typography>

						<div className="d-flex px-2 col-auto">
							{item?.slugName || item?.categorySlug === "all-brands" ?
								[0, 1, 2].map(columnIndex => (
									<ul key={columnIndex}
										className="list-unstyled mb-3"
										style={{flex: "1"}}>
										{sortedBrands?.slice(columnIndex * brandsPerColumn, (columnIndex + 1) * brandsPerColumn).map(({
											fields: {
												menuLabel,
												slug
											}
										}) => (
											<li onClick={() => {
												sessionStorage.setItem("page", (0).toString())
												sessionStorage.setItem("scrollPosition", (0).toString())
											}}
											key={menuLabel}>
												<Link href={filterUrl({[urlParams.brand]: slug}, null, null)}>
													<a onClick={() => {
														reactGA?.event({
															category: gaCategories.megaMenu,
															action: gaEvents.topBrands,
															label: menuLabel,
															nonInteraction: false
														})
													}}
													className={`text-decoration-none rounded-3 d-inline-block ${styles.megamenuItem}`}>
														<Typography
															style={{color: isBrandWorld ? "#fff" : "#212529"}}
															variant={`${query.slug === slug ? "bodyLgBold" : "bodySm"}`}>{menuLabel}</Typography>
													</a>
												</Link>
											</li>
										))}
									</ul>)) :
								<ul className="list-unstyled">
									{topBrandsForMegaMenu?.map(({fields}, idx) => {
										const {menuLabel, slug, filterSlug} = fields

										return (
											<li onClick={() => {
												sessionStorage.setItem("page", (0).toString())
												sessionStorage.setItem("scrollPosition", (0).toString())
											}}
											key={idx}>
												<Link href={filterUrl({
													[urlParams.categories]: activeItemLink,
													[urlParams.brand]: filterSlug
												}, null, null)}>
													<a onClick={() => {
														reactGA?.event({
															category: gaCategories.megaMenu,
															action: gaEvents.topBrands,
															label: menuLabel,
															nonInteraction: false
														})
													}}
													className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
														<Typography
															style={{color: isBrandWorld ? "#fff" : "#212529"}}
															variant={`${query.slug === slug ? "bodyLgBold" : "bodySm"}`}>{menuLabel}</Typography>
													</a>
												</Link>
											</li>)
									})}
								</ul>
							}

							{item?.slugName || item?.categorySlug === "all-brands" &&
								<div className="col-auto ">
									<ul className="list-unstyled">
										{accessoriesBrands ? accessoriesBrands?.options?.map(brand =>
											<div key={generateUUID()}>
												<Link href={filterUrl({
													[urlParams.brand]: brand.slug
												}, null, null)}>
													<a
														style={{backgroundColor: query.brand === brand.slug && "#eff1f3"}}
														className={`text-decoration-none rounded-3 text-dark 
                                                        d-inline-block ${styles.megamenuItem}`}>
														<Typography
															style={{color: isBrandWorld ? "#fff" : "#212529"}}
															variant={`${query.brand === brand.slug ?
																"bodySmBold" : "bodySm"}`}>
															{brand.name}
														</Typography>
													</a>
												</Link>
											</div>
										) : null}
									</ul>
								</div>
							}
						</div>
					</div>}
					{allBrandsLink &&
						<div onClick={() => {
							onBtnClick()
							reactGA?.event({
								category: gaCategories.megaMenu,
								action: gaEvents.allEBrandsButton,
								label: gaEvents.allEBrandsButton,
								nonInteraction: false
							})
						}}
						className="ms-2">
							<LinkWithIcon
								isBrandWorld={isBrandWorld}
								href={"/alle-marken"}
								label={t("Alle Marken")}
								iconBgColor="#EBEBEB"
								iconMargin="ms-2"
								labelVariant={"bodySmBold"}
								padding={"5.5px 8px"}
								icon={
									<Image src="/assets/icons/chevron-dark-bg-light.svg"
										width={20}
										height={20}
										alt="chevron right"/>
								}/>
						</div>}
				</div>
			</div>
		</div>
	)
}

export default MobileMegaMenuItem

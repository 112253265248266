import React, {useContext} from "react"
import DataContext from "../../../context/DataContext"
import {useTranslation} from "next-i18next"
import BikesCollectionItem from "../../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import LayoutContext from "../../../context/LayoutContext"

function MiniPLP() {
	const {customBikeRecommendations} = useContext(DataContext)
	const {isMobile} = useContext(LayoutContext)
	const {t} = useTranslation("common")
	if (customBikeRecommendations?.length) {
		return (<div style={{marginBottom: "4rem", marginTop: "4rem"}}
			className=" py-4">
			<div style={{margin: "auto"}}
				className={`${customBikeRecommendations.length >= 4 ? " miniPLP-container container " : " d-none d-md-flex gap-4 relative justify-content-start"}`}>
				{customBikeRecommendations.length ? customBikeRecommendations.map((bike, idx) => <BikesCollectionItem
					isIkeaDesign
					key={idx}
					withOneImage
					cardWidth={isMobile ? "170px" : "196px"}
					hideComparison
					bike={bike}
					isTwoCardsView={true}
					isRowForMobile={true}
				/>) : null}
			</div>
		</div>
		)
	}

	return null
}

export default MiniPLP

import React from "react"
import PureSlider from "../PureSlider/PureSlider"
import BikesCollectionItem from "../../../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import CompatibleAccessory from "../../../bikeDetails/CompatibleAccessory"

type Props = {
    slidesToShow?: number;
    autoplay?: boolean;
    dots?: boolean;
    dotsPositionY?: number;
    arrows?: boolean;
    draggable?: boolean;
    totalItems?: number
    setCurrentSlide?: any
    currentSlide?: any
    productsList: any
    slideWidth?: number
    arrowsBottom?: boolean
    handleSliderArrowClick?: () => void;
    isCompactView?: boolean;
    isRowForMobile?: boolean,
    showComparisonAttributes?: boolean,
    attributesToHide?: any
    arrowsPositionX?: number
}

const SliderContent: React.FC<Props> = ({
	arrows,
	totalItems,
	currentSlide,
	setCurrentSlide,
	productsList,
	slideWidth,
	dots,
	slidesToShow = 4,
	arrowsBottom,
	handleSliderArrowClick = () => {},
	isCompactView,
	isRowForMobile,
	showComparisonAttributes,
	attributesToHide,
	arrowsPositionX
}) => {
	return (
		<PureSlider
			setCurrentSlide={setCurrentSlide}
			currentSlide={currentSlide}
			totalItems={totalItems}
			slideWidth={slideWidth}
			arrows={arrows}
			dots={dots}
			slidesToShow = {slidesToShow}
			arrowsBottom={arrowsBottom}
			handleSliderArrowClick={handleSliderArrowClick}
			arrowsPositionX={arrowsPositionX}
		>
			{productsList.map((product, index) => {
				const perviousSlide = index === currentSlide - 1
				const nextSlide = index === currentSlide + 4
				const isVisibleFirst = index === currentSlide
				const isVisibleLast = index === currentSlide + 3

				const borderRadius = isVisibleFirst ?
					"24px 0px 0px 24px" :
					isVisibleLast ?
						"0px 24px 24px 0px" :
						"0px"

				return (
					<div key={product.id + index}
						className={"h-100"}
						style={{
							transition: "opacity 0.3s ease",
							opacity: (perviousSlide || nextSlide) ? 0 : 1,
							minWidth: `${slideWidth}px`
						}}>
						{
							isCompactView ?
								<CompatibleAccessory hasBadge
									key={product.id}
									product={product}
									bike={product}
									borderRadius={borderRadius}
								/>	:
								<BikesCollectionItem isCarouselElement
									bike={product}
									withOneImage
									cardWidth={`${slideWidth}px`}
									isRowForMobile={isRowForMobile}
									isTwoCardsView
									priceFontSize={24}
									borderRadius={borderRadius}
									isIkeaDesign
									showComparisonAttributes={showComparisonAttributes}
									attributesToHide={attributesToHide}
								/>
						}
					</div>
				)
			})}
		</PureSlider>
	)
}

export default SliderContent

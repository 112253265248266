import {uploadImageHandler} from "./aws/uploadIdToAWSHelper"

type Params = {
    formId: string;
    instanceId: string;
    data: any;
	context?: any
}

export const formFooterHandler = async (obj: Params) => {
	const url = `/${obj.formId}/${obj.instanceId}`
	const payload = {
		fields: [
			{
				name: "email",
				value: obj.data.email
			},
			{
				name: "firstname",
				value: obj.data.firstname
			},
			{
				name: "lastname",
				value: obj.data.lastname
			}
		]
	}

	const response = fetchPostHandler(url, payload)
		.catch(error => {
			return error
		})
	return response
}

export const formContactHandler = async (obj: Params) => {
	const url = `/${obj.formId}/${obj.instanceId}`
	const payload = {
		fields: [
			{
				name: "email",
				value: obj.data.email
			},
			{
				name: "firstname",
				value: obj.data.firstname
			},
			{
				name: "lastname",
				value: obj.data.lastname
			},
			{
				name: "questions",
				value: obj.data.questions
			}
		],
		context: obj.context
	}

	const response = fetchPostHandler(url, payload)
		.catch(error => {
			return error
		})
	return response
}

export const formSuggestHandler = async (obj: Params) => {
	const url = `/${obj.formId}/${obj.instanceId}`
	const payload = {
		fields: [
			{
				name: "email",
				value: obj.data.email
			},
			{
				name: "firstname",
				value: obj.data.firstname
			},
			{
				name: "lastname",
				value: obj.data.lastname
			},
			{
				name: "phone",
				value: obj.data.phone
			},
			{
				name: "requested_bike_brand",
				value: obj.data.requested_bike_brand
			},
			{
				name: "requested_bike_deadline",
				value: obj.data.requested_bike_deadline
			}
		]
	}

	const response = fetchPostHandler(url, payload)
		.catch(error => {
			return error
		})
	return response
}

export const formKundendienstHandler = async (obj: Params) => {
	const url = `/${obj.formId}/${obj.instanceId}`

	const payload = {
		fields: [
			{
				name: "email",
				value: obj.data.email
			},
			{
				name: "firstname",
				value: obj.data.firstname
			},
			{
				name: "lastname",
				value: obj.data.lastname
			},
			{
				name: "TICKET.hs_ticket_category",
				value: obj.data.hs_ticket_category
			},
			{
				name: "TICKET.e_bike_model",
				value: obj.data.bike_model
			},
			{
				name: "language_selected",
				value: obj.data.language_selected
			},
			{
				name: "TICKET.subject",
				value: obj.data.subject
			},
			{
				name: "TICKET.content",
				value: obj.data.content
			},
			{
				name: "TICKET.hs_file_upload",
				value: ""
			},
			{
				name: "service_ticket_info_confirmed",
				value: obj.data.service_ticket_info_confirmed
			}
		]
	}

	if (obj.data.hs_file_upload && obj.data.hs_file_upload.size) {
		const tag = "file-" + obj.data.firstname

		const response = await uploadImageHandler({
			fileFront: obj.data.hs_file_upload
		}, tag)
			.then(async response => {
				const uploadFieldIndex = payload.fields.map(e => {
					return e.name
				}).indexOf("TICKET.hs_file_upload")
				payload.fields[uploadFieldIndex].value = `Link: ${response[0].link}`

				const submitResponse = fetchPostHandler(url, payload)
					.catch(error => {
						return error
					})
				return submitResponse
			})
		return response
	}

	const submitResponse = fetchPostHandler(url, payload)
	return submitResponse
}

export const formReturnHandler = async (obj: Params) => {
	const url = `/${obj.formId}/${obj.instanceId}`

	const payload = {
		fields: [
			{
				name: "email",
				value: obj.data.email
			},
			{
				name: "TICKET.e_bike_model",
				value: obj.data.e_bike_model
			},
			{
				name: "TICKET.subject",
				value: obj.data.subject
			},
			{
				name: "TICKET.content",
				value: obj.data.content
			},
			{
				name: "TICKET.return_reason",
				value: obj.data.return_reason
			},
			{
				name: "TICKET.current_km",
				value: obj.data.current_km
			},
			{
				name: "TICKET.hs_ticket_category",
				value: "Return"
			},
			{
				name: "TICKET.picture_of_display",
				value: ""
			},
			{
				name: "TICKET.picture_right_side",
				value: ""
			},
			{
				name: "TICKET.picture_left_side",
				value: ""
			}
		]
	}

	const response = await uploadImageHandler({
		fileFront: obj.data.picture_of_display,
		fileRight: obj.data.picture_right_side,
		fileLeft: obj.data.picture_left_side
	}, obj.data.e_bike_model).then(async response => {
		response?.forEach(item => {
			if (item.side === "front") {
				const uploadFieldIndex = payload.fields.map(e => {
					return e.name
				}).indexOf("TICKET.picture_of_display")
				payload.fields[uploadFieldIndex].value = `Link: ${item.link}`
			}	else if (item.side === "right") {
				const uploadFieldIndex = payload.fields.map(e => {
					return e.name
				}).indexOf("TICKET.picture_right_side")
				payload.fields[uploadFieldIndex].value = `Link: ${item.link}`
			}	else if (item.side === "left") {
				const uploadFieldIndex = payload.fields.map(e => {
					return e.name
				}).indexOf("TICKET.picture_left_side")
				payload.fields[uploadFieldIndex].value = `Link: ${item.link}`
			}
		})

		const submitResponse = fetchPostHandler(url, payload)
		return submitResponse
	})
	return response
}

export const collectLead = async (currentUrl: string, locale: string) => {
	await leadCollectionHandler(currentUrl, locale)
}

const leadCollectionHandler = async (currentUrl: string, locale: string) => {
	const userCartData = JSON.parse(localStorage.getItem("userCartData"))
	if (userCartData) {
		const {submited, data, eBikeChoice} = userCartData

		if (!submited && data.firstname && data.lastname && data.email) {
			const url = locale === "fr" ? `/${process.env.NEXT_PUBLIC_PORTAL_PROD_ID}/${process.env.NEXT_PUBLIC_FORM_LEADCOLLECT_FR}` : `/${process.env.NEXT_PUBLIC_PORTAL_PROD_ID}/${process.env.NEXT_PUBLIC_FORM_LEADCOLLECT_DE}`

			const context = {
				pageUri: currentUrl
			}

			const payload = {
				fields: [
					{
						name: "email",
						value: data.email
					},
					{
						name: "firstname",
						value: data.firstname
					},
					{
						name: "lastname",
						value: data.lastname
					},
					{
						name: "requested_bike_model",
						value: eBikeChoice
					}
				],
				context
			}

			const response = fetchPostHandler(url, payload)
				.then(response => {
					localStorage.setItem("userCartData", JSON.stringify({submited: true, data}))
					return response
				})
				.catch((e: any): any => {
					console.error(e)
					return e
				})

			return response
		}
	}

	return "No data to submit"
}

export const formNewsletterHandler = async (obj: Params, handleChangeLoading: (arg:boolean) => void) => {
	const url = `/${obj.formId}/${obj.instanceId}`
	const payload = {
		fields: [
			{
				name: "email",
				value: obj.data.email
			},
			{
				name: "firstname",
				value: obj.data.firstname
			},
			{
				name: "lastname",
				value: obj.data.lastname
			}
		]
	}
	handleChangeLoading(true)

	// ClickFunnel API

	const payloadClickFunnel = {
		email_address: obj.data.email,
		first_name: obj.data.firstname,
		last_name: obj.data.lastname
	}
	const clickFunnelResponse = await fetch("/api/clickfunnel/createcontact", {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(payloadClickFunnel)
	})

	// end ClickFunnel API

	const response = fetchPostHandler(url, payload)
		.catch(error => {
			return error
		}).finally(() => handleChangeLoading(false))
	return response
}

export const simpleAskQuestionFormHandler = async (obj: Params) => {
	const url = `/${obj.formId}/${obj.instanceId}`
	const payload = {
		fields: [
			{
				name: "email",
				value: obj.data.email
			},
			{
				name: "firstname",
				value: obj.data.firstname
			},
			{
				name: "lastname",
				value: obj.data.lastname
			},
			{
				name: "questions",
				value: obj.data.questions
			}
		]
	}

	const response = fetchPostHandler(url, payload)
		.catch(error => {
			console.log("error", error)
			return error
		})
	return response
}

const fetchPostHandler = async (url, data) => {
	const baseUrl = "https://api.hsforms.com/submissions/v3/integration/submit"
	const response = await fetch(`${baseUrl}${url}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	}).then(response => {
		return response
	}).catch(e => {
		console.error(e)
		return null
	})

	return response
}

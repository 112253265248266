import React from "react"
import {CSSTransition} from "react-transition-group"
import slideUpClasses from "./slideUp.module.scss"
import slideDownClasses from "./slideDown.module.scss"
import accordionDownClasses from "./accordionDown.module.scss"
import fadeClasses from "./fade.module.scss"
import slideRightClasses from "./slideRight.module.scss"

export enum AnimationType {
  fade = "fade",
  slideUp = "slideUp",
  slideRight = "slideRight",
}

type Props = {
  inProp: boolean;
  duration?: number;
  children: React.ReactNode;
  type: keyof typeof AnimationType;
  onExited?: () => void;
};

const classes = {
	fade: fadeClasses,
	slideUp: slideUpClasses,
	slideRight: slideRightClasses,
	slideDown: slideDownClasses,
	accordionDown: accordionDownClasses
}

const AnimationComponent = ({inProp, duration, type, children, onExited}: Props) => {
	return (
		<CSSTransition
			unmountOnExit
			mountOnEnter
			in={inProp}
			timeout={duration ?? 400}
			classNames={classes[type]}
			onExited={onExited}
		>
			{children}
		</CSSTransition>
	)
}

export default AnimationComponent

import React from "react"
import useInitializePDPSizes from "../../../hooks/useInitializePDPSizes"
import SizeItem from "./SizeItem"

type Props = {
    bike: any
}

const BikeSizes: React.FC<Props> = ({bike}) => {
	const {variations} = useInitializePDPSizes(bike)

	return (
		<section>
			<ul className={`row list-unstyled row gx-3 ${variations?.length > 3 && "gy-4"}`}>
				{variations.map((variation, i) =>
					<SizeItem variation={variation}
						key={i}
					/>
				)}
			</ul>
		</section>
	)
}

export default BikeSizes

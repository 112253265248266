import React, {useContext, useEffect, useRef, useState} from "react"
import Image from "next/image"
import Slider, {CustomArrowProps, Settings} from "react-slick"
import Link from "next/link"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {ImageDescription, ImageGalary, ShowMoreOverlay} from "./components"
import {ChevronLeft, ChevronRight} from "react-bootstrap-icons"
import WhatsappSlide from "./components/WhatsappSlide"
import Typography from "../reusable/Typography/Typography"
import GoBack from "../bikeDetails/GoBack"
import SimilarBikesPdpFilter from "../bikesCollection/BikesCollectionItem/SimilarBikesPdpButton"
import ComparisonCheckboxButtonPdp from "../bikesCollection/BikesCollectionItem/ComparisonCheckboxButtonPdp"
import "react-medium-image-zoom/dist/styles.css"
import AboutThisBike from "../bikeDetails/AboutThisBike"
import BookmarkBtn from "../reusable/components/BookmarkBtn"
import MobileShare from "../reusable/components/MobileShare"
import FullScreenZoomableSlider from "./components/FullScreenZoomableSlider"
import {useRouter} from "next/router"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"

type Props = {
	slidesList: any[],
	arrows?: boolean,
	dots?: boolean
	bike?: any
	slidesToShow?: number
	withGalery?: boolean
	limitItemsToRender?: number,
	showMoreButton?: boolean,
	goNext?: boolean,
	showDescription?: boolean,
	productUrl?: string,
	hasComparisonBtn?: boolean
	hasWhatsappSlide?: boolean,
	isAccessory?: boolean
	hasGoBackBtn?: boolean
	onGoBackBtnClick?: any
    zoomableImage?: boolean
    fullScreenSlider?: boolean
    borderRadius?: number | string,
    currentSlide?: number;
    currentSlugWithCarousel?: string;
    trackSliding?: boolean
}

const newImage = {
	id: "whatsapp-call",
	alt: "Whatsapp",
	description: "whatsapp slide",
	src: "/assets/icons/whats-app-button-black-icon.svg"
}
const CustomSlider: React.FC<Props> = ({
	currentSlugWithCarousel,
	limitItemsToRender,
	hasComparisonBtn,
	fullScreenSlider,
	hasWhatsappSlide,
	onGoBackBtnClick,
	slidesToShow = 1,
	showDescription,
	showMoreButton,
	zoomableImage,
	hasGoBackBtn,
	trackSliding,
	currentSlide,
	borderRadius,
	isAccessory,
	withGalery,
	slidesList,
	productUrl,
	arrows,
	goNext,
	bike,
	dots
}) => {
	const sliderRef = useRef<Slider>()
	const [showFullScreenSlider, setShowFullScreenSlider] = useState(false)
	const [activeSlideIndex, setActiveSlideIndex] = useState(currentSlide ?? 0)
	const slideIndexRef = useRef(activeSlideIndex)
	const slidesListToRender = limitItemsToRender && slidesList.length > limitItemsToRender ?
		slidesList.slice(0, limitItemsToRender) : slidesList
	const updatedSlidesToRender = [...slidesListToRender, newImage]
	const lastImageOfSlider = slidesListToRender[slidesListToRender.length - 1]?.src
	const handleGoTo = (slideNumber: number) => sliderRef.current?.slickGoTo(slideNumber)
	const {reactGA} = useContext(AnalyticsContext)
	const router = useRouter()
	const {pathname} = router

	useEffect(() => {
		if (!currentSlide) {
			handleGoTo(0)
		}	else if (goNext && activeSlideIndex === 0 && slidesListToRender.length > 1) {
			handleGoTo(1)
		}
	}, [goNext, bike, currentSlide])

	useEffect(() => {
		if (currentSlide === undefined) {
			handleGoTo(0)
			setActiveSlideIndex(0)
		} else {
			handleGoTo(currentSlide)
		}
	}, [bike, currentSlide])

	useEffect(() => {
		if (currentSlugWithCarousel && trackSliding) {
			onSlide(currentSlugWithCarousel)
		}
	}, [currentSlugWithCarousel])

	const onSlide = label => {
		reactGA?.event({
			category: gaCategories.plp,
			action: gaEvents.plpBikeSliderInteraction,
			label
		})
	}

	interface ArrowProps extends CustomArrowProps {
		direction: "left" | "right"
	}

	function CustomArrow({onClick, direction}: ArrowProps) {
		const handleClick = (e: React.MouseEvent) => {
			e.stopPropagation()
			e.preventDefault()
			onClick(e)
		}

		const styles = direction === "right" ?
			{right: fullScreenSlider ? "1%" : "3%"} : {left: fullScreenSlider ? "1%" : "3%"}

		return (
			<div
				className={"rounded-circle text-dark p-2 position-absolute shadow-sm pointer"}
				style={{
					background: "#212529CC",
					opacity: "0,8",
					top: "48%",
					transform: "translateY(-50%)",
					borderRadius: "4px !important",
					width: fullScreenSlider ? 60 : 40, height: fullScreenSlider ? 60 : 40,
					zIndex: 1, ...styles}}
				onClick={handleClick}
			>
				{direction === "right" ? <ChevronRight color="white"
					size={fullScreenSlider ? 30 : 20}/> :
					<ChevronLeft color="white"
						size={fullScreenSlider ? 30 : 20}/>}
			</div>
		)
	}

	const sliderConfig: Settings = {
		infinite: true,
		speed: 500,
		slidesToShow,
		slidesToScroll: 1,
		dots,
		dotsClass: "custom-dots",
		arrows,
		focusOnSelect: false,
		beforeChange: (_, next) => setActiveSlideIndex(next),
		prevArrow: <CustomArrow direction="left"/>,
		nextArrow: <CustomArrow direction="right"/>,
		initialSlide: currentSlide ?? 0
	}

	const openModal = index => {
		setShowFullScreenSlider(true)
		setActiveSlideIndex(index)
		handleGoTo(index)
	}

	const closeModal = () => {
		setActiveSlideIndex(0)
		handleGoTo(0)
		setShowFullScreenSlider(false)
	}

	useEffect(() => {
		slideIndexRef.current = activeSlideIndex
	}, [activeSlideIndex])

	useEffect(() => {
		setActiveSlideIndex(currentSlide)
	}, [bike, currentSlide, showFullScreenSlider])

	return (
		<div className={`${fullScreenSlider ? "" : "bg-white"} ${withGalery ? "d-flex" : ""}`}
			style={{columnGap: withGalery ? "58px" : ""}}
		>
			<div className="d-flex flex-column align-items-start">
				{hasGoBackBtn &&
					<div
						className="d-flex col-12 justify-content-between
                        d-md-none mt-1 container align-items-center"
					>
						<div className="col-auto">
							<GoBack
								showBikeInfo={false}
								name={bike.name}
								bike={bike}
							/>
						</div>
						<div className="col-auto d-flex align-items-center justify-content-start gap-2">
							<MobileShare title={bike.name}/>
							<BookmarkBtn bike={bike} />
						</div>

					</div>}

				{withGalery &&
				<div>
					<ImageGalary activeSlideIndex={activeSlideIndex}
						goToSlide={handleGoTo}
						images={hasWhatsappSlide ? updatedSlidesToRender : slidesListToRender}/>
				</div>}
			</div>

			<div className={`col-12 ${withGalery ? "col-md-9" : ""}`}>

				<div className="slider-wrapper"
					style={{zIndex: 2}}
				>
					<Slider ref={sliderRef}
						{...sliderConfig}>
						{(hasWhatsappSlide ?
							updatedSlidesToRender : slidesListToRender).map(({alt, id, description, src}, i) => (
							<div key={id}
								className={`position-relative bike-item 
                                ${fullScreenSlider && "d-flex justify-content-center"}`}>
								{showDescription && i === 0 && <ImageDescription description={description}/>}
								{(showMoreButton && i === limitItemsToRender - 1) &&
                                        <ShowMoreOverlay productUrl={productUrl}/>}
								{id === "whatsapp-call" ?
									<WhatsappSlide lastImageOfSlider={lastImageOfSlider}/> :
									<>
										{zoomableImage ?
											<Image
												onClick={openModal}
												style={{borderRadius: borderRadius ? borderRadius : "32px"}}
												className=
													{`zoom-in
                                                        ${showMoreButton && i === limitItemsToRender - 1 ? "blur" : ""} 
                                                        `}
												src={src}
												alt={alt}
												layout={"responsive"}
												objectFit="cover"
												width={259}
												height={240}
												priority/> :
											<div
												className={`${fullScreenSlider ? "fullscreen-slider-image" : ""}`}
											>
												<Image
													className={`
									                ${showMoreButton && i === limitItemsToRender - 1 ? "blur" : ""} `}
													src={src}
													alt={alt}
													layout={"responsive"}
													objectFit="cover"
													width={242}
													height={242}
													priority
													style={{borderRadius: borderRadius ? borderRadius :
														fullScreenSlider ? "" : "32px"}}
												/></div>
										}
									</>
								}
							</div>
						)
						)}
					</Slider>
				</div>
				<div>
					{pathname === "/produkt/[bike]" ? <div className="d-md-none position-relative"
						style={{bottom: 25, zIndex: 1, background: "#F8F8F8", borderRadius: "0px 0px 32px 32px"}}>
						<AboutThisBike
							bike={bike}
							detailsInCustomSliderMobile/>
					</div> : null}

					{hasComparisonBtn &&
					<div style={{maxWidth: "100dvw", overflow: "auto"}}
						className="d-flex gap-2 hide-overflow-scrollbar justify-content-between
                        px-3 px-md-0 mt-md-4  mt-md-0">
						<div className="d-flex gap-3">
							{!isAccessory && <div style={{maxWidth: 168}}>
								<ComparisonCheckboxButtonPdp bgColor={"#FFF6E7"}
									borderRadius="4x"
									slug={bike?.slug}
									paddingY="py-2"
									isPDPcomparisonBtn
									textVariant="bodySmBold"
								/>
							</div>}
							{!isAccessory && <div>
								<SimilarBikesPdpFilter bike={bike}
									bgColor={"#FFF6E7"}
									textVariant="bodySmBold"
									borderRadius="4px"
									slug={bike?.slug}
									paddingY="py-2"
									isPDPcomparisonBtn/>
							</div>}
						</div>
						<div className="d-inline-block px-3 py-2"
							style={{backgroundColor: "#FFF6E7", cursor: "pointer", borderRadius: 4}}>
							<Link href={"http://api.whatsapp.com/send?phone=41435051318"}>
								<a className="d-flex align-items-center text-decoration-none "
									target="_blank">
									<Image src={"/assets/icons/whats-app-button-black-icon.svg"}
										width={24}
										height={24}/>
									<Typography variant="bodySmBold"
										semanticTag="span"
										className="ms-2 ms-md-3"
										style={{color: "#212529"}}>WhatsApp</Typography>
								</a>
							</Link>
						</div>
					</div> }
				</div>
			</div>
			{showFullScreenSlider &&
			<FullScreenZoomableSlider
				isOpen={showFullScreenSlider}
				initialSlide={slideIndexRef.current}
				closeModal={closeModal}
				bike={bike}/>
			}
		</div>
	)
}

export default CustomSlider

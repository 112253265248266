import React, {useCallback, useContext, useEffect, useMemo, useState} from "react"
import Slider from "../../slider/Slider"
import CheckoutContext from "../../../context/CheckoutContext"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {clampValue} from "../../../utility/Helper"
import CartContext from "../../../context/CartContext"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {useRouter} from "next/router"
import LayoutContext from "../../../context/LayoutContext"
import {debounce} from "lodash"
import NotificationsContext from "../../../context/NotificationsContext"
import {downpaymentBracketsArr} from "../../../constants/TopCategories"
import DownpaymentBrackets from "./DownpaymentBrackets"

type Props = {
  bikeId?: number | null;
  min: number;
  max: number;
  hideLabel?: boolean;
  showPriceTooltip?: boolean;
    hasPlaceholder?: boolean;
    PriceInputBesidesTitle?: boolean
    isTitleUppercase?: boolean
    IsPDPDownpayment?: boolean
    isNewOrderDownpayment?: boolean
    monthlyPriceWithoutDownpayment?: number
    showBrackets?: boolean
}

export function useLocalStorage(key: string, initialValue: any, max:number) {
	const [value, setValue] = useState(() => {
		if (typeof window !== "undefined") {
			const storedValue = localStorage.getItem(key)
			return storedValue && Number(storedValue) < max ?
				JSON.parse(storedValue) : Number(storedValue) > max ? max : initialValue
		}

		return initialValue
	})
	useEffect(() => {
		if (typeof window !== "undefined") {
			localStorage.setItem(key, JSON.stringify(value))
			window.dispatchEvent(new Event("reset"))
		}
	}, [key, value])

	return [value, setValue]
}

const DownPayment: React.FC<Props> = ({
	bikeId = null,
	min,
	max,
	hideLabel,
	showPriceTooltip = false,
	hasPlaceholder = true,
	PriceInputBesidesTitle,
	IsPDPDownpayment,
	isTitleUppercase,
	isNewOrderDownpayment,
	monthlyPriceWithoutDownpayment,
	showBrackets
}) => {
	const {data, setData} = useContext(CheckoutContext)
	const {items, setItems} = useContext(CartContext)
	const {reactGA} = useContext(AnalyticsContext)
	const checkoutData = data
	const setCheckoutData = setData
	const {t} = useTranslation("common")
	const {isMobile} = useContext(LayoutContext)
	const [downpaymentValue, setDownpaymentValue] = useLocalStorage("totalDownPayment", min, max)
	const [disabledBrackets, setDisabledBrackets] = useState<boolean[]>([])
	const step = 50
	const {displayNotificationAfterUsing} = useContext(NotificationsContext)
	const router = useRouter()

	useEffect(() => {
		setDisabledBrackets(calculateDisabledBrackets(downpaymentValue, max))
	}, [downpaymentValue, max])

	useEffect(() => {
		if (IsPDPDownpayment) {
			if (downpaymentValue) {
				handleUpdate(downpaymentValue)
				handleOnChange(downpaymentValue)
			} else {
				handleUpdate(0)
				handleOnChange(0)
			}
		}
	}, [])

	const totalCartValue = useMemo(
		() =>
			items?.reduce(
				(cartValue, item) => cartValue +
                parseFloat(item.product.finalPriceWithoutDownPayment || item.product.price),
				0
			),
		[items]
	)
	const handleOnChange = (value:number) => {
		const clampedValue = clampValue(value, min, max)
		setDownpaymentValue(clampedValue)
	}

	const handleUpdateDebounced = useCallback(
		debounce((value: number) => {
			const clampedValue = clampValue(value, min, max)

			if (bikeId) {
				setCheckoutData(prevData => ({
					...prevData,
					downPayment: {
						...prevData?.downPayment,
						[bikeId]: clampedValue
					}
				}))
			} else {
				setItems(prevItems =>
					prevItems.map(prevItem => {
						const ratioToCartValue =
                  prevItem.product.finalPriceWithoutDownPayment / totalCartValue
						return {
							...prevItem,
							downPayment: clampedValue * ratioToCartValue
						}
					})
				)
			}
		}, 300),
		[bikeId, min, max, totalCartValue, downpaymentValue]
	)

	const handleUpdate = (value: number) => {
		handleUpdateDebounced(value)
	}

	useEffect(() => {
		handleUpdate(downpaymentValue)
	}, [max, min])

	const handleDownPaymentGAEvent = (clampValue: number) => {
		setTimeout(() => {
			reactGA?.event({
				category:
          router.pathname === "/checkout" ? gaCategories.checkout : gaCategories.pdp,
				action: gaEvents.downPaymentValue,
				label: clampValue,
				nonInteraction: false
			})
		}, 500)
	}

	useEffect(() => {
		if (checkoutData?.planLength === 1) {
			handleUpdate(0)
		}

		if (max < downpaymentValue) {
			handleUpdate(max)
			handleOnChange(max)
		}
	}, [checkoutData?.planLength, max])

	const calculateDisabledBrackets = (downpaymentValue: number, max: number) => {
		const percentage = (downpaymentValue / max) * 100
		return downpaymentBracketsArr.map(bracket => {
			const bracketPercentage = parseFloat(bracket.title)
			return percentage > bracketPercentage
		})
	}

	const handleBracketSelection = (percentage: number) => {
		const newValue = Math.floor((percentage / 100) * max)
		handleUpdate(newValue)
		setDownpaymentValue(newValue)
	}

	return (
		<div>
			<div className={`h-75 ${isNewOrderDownpayment ? "w-100" : PriceInputBesidesTitle ?
				"d-flex flex-column  justify-content-between" : IsPDPDownpayment ?
					"d-flex align-items-center justify-content-between" : ""}`}>
				{IsPDPDownpayment && !hideLabel && !isNewOrderDownpayment ? (
					<Typography variant="bodySmBold"
						semanticTag="span"
						className={isTitleUppercase ? "text-uppercase" : "col-auto"}>
						{t("Downpayment")}
					</Typography>
				) : null}
				{isNewOrderDownpayment ?
					<div className={"col-12 gap-5 d-flex justify-content-between align-items-center"}>
						{hideLabel ? null : (
							<Typography variant="bodySmBold"
								semanticTag="span"
								className={isTitleUppercase ? "text-uppercase" : "col-auto"}>
								{t("Downpayment")}
							</Typography>
						)}
						<div style={{flex: 1}}
							className={ "col-auto d-flex gap-2 justify-content-between align-items-center" }>
							<div className="col-8"><Slider
								disabled={data?.planLength === 1 || false}
								paymentValueOne={downpaymentValue}
								hasPriceTooltip={showPriceTooltip}
								defaultValue={downpaymentValue}
								min={min}
								max={max}
								step={step}
								onChange={value => {
									handleOnChange(value)
								}}
								onAfterChange={value => {
									displayNotificationAfterUsing(58, "credit-check-notification", true)
									handleUpdate(value)
									handleDownPaymentGAEvent(clampValue(value, min, max))
								}}
							/></div>
							<div
								style={{width: isMobile && isNewOrderDownpayment ?
									106 : isNewOrderDownpayment ? 66 : "auto"}}
								className="d-inline-flex align-items-center downpayment-input-wrapper "
							>
								{hasPlaceholder && (
									<Typography variant="bodySm"
										className="me-1">
								CHF
									</Typography>
								)}
								<input
									disabled={data?.planLength === 1 || false}
									type="number"
									style={{textAlign: "end"}}
									value={downpaymentValue}
									className={"downpayment-input"}
									onChange={e => {
										e.preventDefault()
										if (e?.target?.value.startsWith("0")) {
											e.target.value = e?.target?.value.slice(1, e?.target?.value.length)
										}

										const newValue = parseFloat(e?.target?.value)
										handleOnChange(newValue || 0)
										handleUpdate(newValue || 0)
										handleDownPaymentGAEvent(clampValue(newValue, min, max))
									}}
								/>
							</div>
						</div>

					</div> : <div className="col-auto d-flex justify-content-between align-items-center">
						{PriceInputBesidesTitle && !hideLabel && !isNewOrderDownpayment ? (
							<Typography variant="bodySmBold"
								semanticTag="span"
								className={isTitleUppercase ? "text-uppercase" : "col-auto"}>
								{t("Downpayment")}
							</Typography>
						) : null}
						{PriceInputBesidesTitle && !isNewOrderDownpayment &&
					<div
						className="d-inline-flex align-items-center downpayment-input-wrapper col-5"
					>
						{hasPlaceholder && (
							<Typography variant="bodySm"
								className="me-1">
								CHF
							</Typography>
						)}
						<input
							disabled={data?.planLength === 1 || false}
							type="number"
							value={downpaymentValue}
							className={"downpayment-input"}
							onChange={e => {
								e.preventDefault()
								if (e?.target?.value.startsWith("0")) {
									e.target.value = e?.target?.value.slice(1, e?.target?.value.length)
								}

								const newValue = parseFloat(e?.target?.value)
								handleOnChange(newValue || 0)
								handleUpdate(newValue || 0)
								handleDownPaymentGAEvent(clampValue(newValue, min, max))
							}}
						/>
					</div>
						}
					</div>}

				<div className={` ${isNewOrderDownpayment ? "d-none" : IsPDPDownpayment ?
					"d-flex gap-4 mt-2 col-9 align-items-center" :
					"d-flex flex-column align-items-start flex-column-reverse col-12"} 
                    justify-content-between `}>
					<div className={`${IsPDPDownpayment ? "col-8" : "col-12 mt-2 pt-1"}`}>
						<Slider
							disabled={data?.planLength === 1 || false}
							paymentValueOne={downpaymentValue}
							hasPriceTooltip={showPriceTooltip}
							defaultValue={downpaymentValue}
							min={min}
							max={max}
							step={step}
							onChange={value => {
								handleOnChange(value)
							}}
							onAfterChange={value => {
								displayNotificationAfterUsing(58, "credit-check-notification", true)
								handleUpdate(value)
								handleDownPaymentGAEvent(clampValue(value, min, max))
							}}
						/>
					</div>
					<div className={`d-inline-flex align-items-center downpayment-input-wrapper 
                        ${IsPDPDownpayment ? "col-3" : "col-12"}  ${PriceInputBesidesTitle ? "d-none" : ""}`}>
						{hasPlaceholder && (
							<Typography variant="bodySm"
								className="me-1">
                CHF
							</Typography>
						)}
						<input
							disabled={data?.planLength === 1 || false}
							type="number"
							value={downpaymentValue}
							className={"downpayment-input"}
							onChange={e => {
								e.preventDefault()
								if (e?.target?.value.startsWith("0")) {
									e.target.value = e?.target?.value.slice(1, e?.target?.value.length)
								}

								const newValue = parseFloat(e?.target?.value)
								handleOnChange(newValue || 0)
								handleUpdate(newValue || 0)
								handleDownPaymentGAEvent(clampValue(newValue, min, max))
							}}
						/>
					</div>
				</div>
				<div className="mt-2 pt-1 mb-3">
					<DownpaymentBrackets
						disabledBrackets={disabledBrackets}
						downpaymentBracketsList={downpaymentBracketsArr}
						onBracketSelect={handleBracketSelection}
						sliderValue={downpaymentValue}
						maxValue={max}
						monthlyPriceWithoutDownpayment={monthlyPriceWithoutDownpayment}
					/>
				</div>
			</div>
		</div>
	)
}

DownPayment.propTypes = {}

export default DownPayment

import React, {useContext, useEffect, useState} from "react"
import {findBikeAttributeBySlug, isCategory, isProduction} from "../../../utility/Helper"
import {StockStatus} from "../BikeDetails"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import {ModalContext} from "../../../context/ModalContext"
import Button from "../../reusable/components/Button"
import SizeNotificationModalContent from "../../reusable/ModalsContent/SizeNotificationModalContent"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {generateUUID} from "../../../utility/UUID"
import getMainCategory from "../../filter/GetMainCategory"
import PropTypes from "prop-types"
import {fetchPost} from "../../../firebaseAdmin/fetchPost"
import VariationItem from "./VariationItem"
import MobileModal from "../../reusable/MobileModal"
import Dropdown from "../../reusable/components/Dropdown/Dropdown"

type Props = {
  variations: any,
  scriptsRef: any,
  bike: any,
  setSelectedVariation: (variation: any) => void,
  setSelectedSize: (size: any) => void
  selectedSize?: any,
  selectedVariation?: any
  shake: boolean
	isAccessory?: boolean
}

export const EnvelopeIcon = () => {
	return (
		<svg width="20"
			height="16"
			viewBox="0 0 20 16"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.25 0C1.46403 0 0 1.46403 0 3.25V12.75C0 14.536 1.46403 16 3.25 16H16.75C18.536 16 20 14.536 20 12.75V3.25C20 1.46403 18.536 0 16.75 0H3.25ZM3.25 1.5H16.75C17.725 1.5 18.5 2.27497 18.5 3.25V3.80273L10 8.39746L1.5 3.80273V3.25C1.5 2.27497 2.27497 1.5 3.25 1.5ZM1.5 5.50781L9.64355 9.91016C9.75305 9.9693 9.87555 10.0003 10 10.0003C10.1245 10.0003 10.2469 9.9693 10.3564 9.91016L18.5 5.50781V12.75C18.5 13.725 17.725 14.5 16.75 14.5H3.25C2.27497 14.5 1.5 13.725 1.5 12.75V5.50781Z"
				fill="currentColor"/>
		</svg>
	)
}

const Sizes: React.FC<Props> = ({
	variations,
	bike,
	shake,
	selectedSize,
	selectedVariation,
	setSelectedVariation,
	setSelectedSize,
	isAccessory
}) => {
	const {t} = useTranslation()
	const inStockSizes = variations?.filter(variation => variation.stock_status === StockStatus.inStock || variation.stock_status === StockStatus.onBackOrder)
	const isOneSize = Boolean(inStockSizes.length === 1)
	const {setModalContent, closeModal, openModal} = useContext(ModalContext)
	const [activeId, setActiveId] = useState()
	const [isUnavailableSizeChosen, setUnavailableSizeChosen] = useState(false)
	const {reactGA} = useContext(AnalyticsContext)
	const [isOutStockSelected, setIsOutStockSelected] = useState(false)
	const mainCategory = getMainCategory(bike?.categories)
	const is45KmH = isCategory(bike, "45-km")
	const [isOpen, setIsOpen] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	useEffect(() => {
		if (inStockSizes.length === 1) {
			const [lastSizeBike] = inStockSizes
			const size = findBikeAttributeBySlug(lastSizeBike, "sizes", false, true)
			if (size === "No Data") {
				const seatTubeLength = findBikeAttributeBySlug(lastSizeBike, "seat-tube-length", false, true)

				// Check if seat tube length also has no data and if yes, then trigger log error message
				if (seatTubeLength === "No Data") {
					// Log message
					if (isProduction()) {
						fetchPost("/api/firestore/storeErrorLogsToFirebase", {
							message: `No data error in sizes for ${bike.slug}`,
							stack: "",
							componentStack: ""
						})
							.then(data => console.log("Error stored in Firestore"))
							.catch(err => console.error("Storing error in Firestore failed:", err))
					}
				}

				handleSizeBtnClick(seatTubeLength, lastSizeBike)
			} else {
				handleSizeBtnClick(size, lastSizeBike)
			}
		}
	}, [inStockSizes.length])

	useEffect(() => {
		setUnavailableSizeChosen(false)
	}, [bike?.name])

	const handleSizeBtnClick = (fullSizeValue, variation) => {
		setIsOpen(false)
		if (variation?.stock_status === StockStatus?.outOfStock) {
			setActiveId(variation.id)
			setIsOutStockSelected(true)
			setSelectedVariation(null)
			setSelectedSize(null)
			return
		}

		if (selectedSize !== fullSizeValue) {
			setSelectedSize(fullSizeValue)
		}

		setIsOutStockSelected(false)
		setActiveId(variation.id)
		setUnavailableSizeChosen(false)
		setSelectedVariation(variation)
	}

	const handleMouseEnter = () => {
		setIsOpen(true)
	}

	const handleMouseLeave = () => {
		setIsOpen(false)
	}

	return (
		<div className={`row pt-3 ${shake ? "shake" : ""}`}>
			<div className={"col-12"}>
				<AccordionAnimation duration={500}
					inProp={true}
					transTimingFn="ease-out">
					<div className="col-12 col-lg-10 mb-3 border rounded pt-4 ps-4 pe-4 pb-4 pb-lg-3">
						<Typography variant="bodyLg"
							semanticTag="div"
							className={"col-auto"}>
							{t("unavailable_size_box_text")}
						</Typography>
						<Button
							label={t("newsletter_button_text")}
							textColor="#212529"
							color="#fff"
							hoverColor="#212529"
							outline
							fontSizeSmall
							className="w-100 mt-4"
							onClick={() => {
								setModalContent(<SizeNotificationModalContent closeModal={closeModal} />)
								openModal()
								reactGA?.event({
									category: gaCategories.pdp,
									action: gaEvents.remindMeSizeUnavailable,
									label: gaEvents.remindMeSizeUnavailable,
									nonInteraction: true
								})
							}}
						/>
					</div>
				</AccordionAnimation>
			</div>
			<div className={`d-flex flex-column flex-md-row 
                ${selectedVariation ? "" : "align-items-md-center"} justify-content-between`}>
				<Typography variant={"bodySmBold"}
					className="col-auto mb-1 mb-md-0 mb-md-0 me-md-2">{t("Size")}</Typography>
				<div className="col-12 col-md-11 pe-xxl-3 position-relative"
					onMouseLeave={handleMouseLeave}>
					<div className={`col-12 col-lg-11 col-xxl-12 cursor-pointer 
                    ${selectedVariation ? "" : "border rounded-3 px-3 py-12"}  `}
					>
						{selectedVariation ? (
							<>
								<div data-cy="cypress-e2e-add-sizes-dropdown-button"
									onMouseEnter={handleMouseEnter}
									className="d-none d-md-block">
									<VariationItem
										variation={selectedVariation}
										activeId={activeId}
										handleSizeBtnClick={handleSizeBtnClick}
										mainCategory={mainCategory}
										is45KmH={is45KmH}
										selectedSize={selectedSize}
										selectedVariation={selectedVariation}
										isOutStockSelected={isOutStockSelected}
										isInDropdown={false}
										isDropdownOpen={isOpen}
										isSelectedVariation={true}
										isMobile={false}
										variations={variations}
									/>
								</div>
								<div onClick={() => setIsModalOpen(true)}
									className="d-md-none">
									<VariationItem
										variation={selectedVariation}
										activeId={activeId}
										handleSizeBtnClick={handleSizeBtnClick}
										mainCategory={mainCategory}
										is45KmH={is45KmH}
										selectedSize={selectedSize}
										selectedVariation={selectedVariation}
										isOutStockSelected={isOutStockSelected}
										isInDropdown={false}
										isDropdownOpen={isModalOpen}
										isSelectedVariation={true}
										isMobile={true}
										variations={variations}
									/>
								</div>
								{selectedVariation?.stock_status !== StockStatus?.outOfStock && <div className="col-12 pt-1">
									<div className="row d-flex ms-3">
										<div className="col-12 px-0">
											<Typography variant={"bodyXSm"}
												semanticTag={"span"}
												className="text-secondary">
												Art. {selectedVariation.sku}
											</Typography>
											<Typography variant={"bodyXSm"}
												semanticTag={"p"}
												className="text-secondary pe-2 mb-0">
												{t("Die Lieferfrist kann sich ändern")}
											</Typography>
										</div>
									</div>
								</div>}
							</>
						) : (
							<>
								{variations.length > 1 ?
									<>
										<div onClick={() => setIsModalOpen(true)}
											className="d-flex justify-content-between align-items-center d-md-none">
											<Typography semanticTag="span"
												variant="bodySmBold">{t("select-size")}</Typography>
											<div className={"d-flex pointer new-mbp-link"}><Typography variant="bodySmBold"
												semanticTag="span">{t("change")}</Typography>
											</div>

										</div>
										<div data-cy="cypress-e2e-add-sizes-dropdown-button"
											onMouseEnter={handleMouseEnter}
											className="d-none d-md-flex justify-content-between align-items-center">
											<Typography semanticTag="span"
												variant="bodySmBold">{t("select-size")}</Typography>
											<div className={"d-flex pointer new-mbp-link"}><Typography variant="bodySmBold"
												semanticTag="span">{t("change")}</Typography>
											</div>
										</div>
									</> :
									null}
							</>
						)}
					</div>
					<Dropdown hasShadow
						top={selectedVariation ? 105 : 45}
						left={0}
						isOpen={isOpen}
						width={356}
						borderRadius={8}
						className="col-12 d-none d-md-block">
						<ul style={{maxWidth: "356px", width: "100%", borderRadius: "8px"}}
							className="bg-white mb-0 border px-2 py-2 list-unstyled">
							{variations.map((variation, i) => (
								<VariationItem
									key={generateUUID()}
									variation={variation}
									activeId={activeId}
									handleSizeBtnClick={handleSizeBtnClick}
									mainCategory={mainCategory}
									is45KmH={is45KmH}
									selectedSize={selectedSize}
									selectedVariation={selectedVariation}
									isOutStockSelected={isOutStockSelected}
									isInDropdown={true}
									isSelectedVariation={selectedVariation?.id === variation?.id}
									isLastVariation={i === variations.length - 1}
									isMobile={false}
									variations={variations}
								/>
							))}
						</ul>
					</Dropdown>
					<div className="d-md-none">
						<MobileModal modalOpen={isModalOpen}
							handleClose={() => setIsModalOpen(!isModalOpen)}
							modalTitle={t("select-size")}>
							<ul style={{width: "100%", borderRadius: 8}}
								className="bg-white mb-0 mt-3 pt-2 list-unstyled">
								{variations.map((variation, i) => (
									<VariationItem
										key={generateUUID()}
										variation={variation}
										activeId={activeId}
										handleSizeBtnClick={handleSizeBtnClick}
										mainCategory={mainCategory}
										is45KmH={is45KmH}
										selectedSize={selectedSize}
										selectedVariation={selectedVariation}
										isOutStockSelected={isOutStockSelected}
										isInDropdown={true}
										isSelectedVariation={selectedVariation?.id === variation?.id}
										isLastVariation={i === variations.length - 1}
										isMobile={true}
										setIsModalOpen={setIsModalOpen}
										variations={variations}
									/>
								))}
							</ul>
						</MobileModal>
					</div>
				</div>
			</div>
		</div>
	)
}

Sizes.propTypes = {
	variations: PropTypes.any.isRequired,
	scriptsRef: PropTypes.any.isRequired,
	bike: PropTypes.any.isRequired,
	selectedSize: PropTypes.string,
	setSelectedVariation: PropTypes.func.isRequired,
	setSelectedSize: PropTypes.any.isRequired,
	selectedVariation: PropTypes.any
}

export default Sizes

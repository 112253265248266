import React from "react"
import PropTypes from "prop-types"
import {XLg} from "react-bootstrap-icons"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
	onClose:()=>void
}

const DeliveryAndReturnsSidebar:React.FC<Props> = ({onClose = () => {
	// Do nothing
}}) => {
	const {t} = useTranslation()
	return (
		<div className={"position-fixed top-0 end-0 bottom-0 start-0"}
			onClick={onClose}
			style={{
				backgroundColor: "rgba(0,0,0,0.5)",
				zIndex: 130000
			}}>
			<div onClick={e => e.stopPropagation()}
				className={"position-absolute top-0 end-0 bottom-0 p-4 p-md-0 overflow-auto delivery-and-returns-sidebar-width"}>
				<div className={"bg-white shadow p-4 delivery-and-returns-sidebar-height"}>
					<div className={"row justify-content-end"}>
						<div className={"col-auto"}>
							<button type={"button"}
								className={"btn"}
								onClick={onClose}>
								<XLg />
							</button>
						</div>
					</div>
					<Typography variant={"heading3"}
						className={"mb-4 d-block"}
						semanticTag={"span"}>
						{t("Kundendienst")}
					</Typography>
					<Typography variant={"bodyLg"}
						semanticTag={"p"}>
						<img src={"/assets/phone.svg"}
							className={"me-4"}/>{"043 505 13 18"}
						<br/>
						<br/>
						<img src={"/assets/envelope.svg"}
							className={"me-4"}/>{"sales@mybikeplan.ch"}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"}
						className={"mb-4 d-block"}
						semanticTag={"span"}>
						{t("Versand")}
					</Typography>
					<Typography variant={"bodyLg"}
						semanticTag={"p"}>
						{t("Unsere E-Bikes werden komplett montiert bis vor deine Haustür geliefert. Kostenlose Lieferung in die Schweiz durch unseren Logistikpartner.")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"}
						className={"mb-4 d-block"}
						semanticTag={"span"}>
						{t("Unser Konzept")}
					</Typography>
					<Typography variant={"bodyLg"}
						semanticTag={"p"}>
						{t("unser-konzept-description")}
						<br/>
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"}
						className={"mb-4 d-block"}
						semanticTag={"span"}>
						{t("Finanzierung")}
					</Typography>
					<Typography variant={"bodyLg"}
						semanticTag={"p"}>
						{t("finanzierung-desc-one")}
						<br/>
						{t("finanzierung-desc-two")}
						<br/>
						{t("finanzierung-desc-three")}
						<br/>
						{t("finanzierung-desc-four")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"}
						className={"mb-4 d-block"}
						semanticTag={"span"}>
						{t("Flexibler Vertrag")}
					</Typography>
					<Typography variant={"bodyLg"}
						semanticTag={"p"}>
						{t("flexibler-vertrag-desc")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"}
						className={"mb-4 d-block"}
						semanticTag={"span"}>
						{t("Vertragsstornierung")}
					</Typography>
					<Typography variant={"bodyLg"}
						semanticTag={"p"}>
						{t("Der Vertrag kann innerhalb 14 Tagen nach Unterzeichnung unkompliziert storniert werden. (Frist beginnt mit Unterschriftsdatum, nicht nach Lieferung)")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"}
						className={"mb-4 d-block"}
						semanticTag={"span"}>
						{t("Garantie")}
					</Typography>
					<div style={{
						paddingBottom: 140
					}}>
						<Typography variant={"bodyLg"}
							semanticTag={"p"}>
							{t("Alle unsere Modelle verfügen über 2 Jahre Werksgarantie. Bei Schadensfällen hilft dir unser Kundendienst gerne weiter und findet einen geeigneten Servicepartner für dich oder organisiert die Abholung und Reparatur im Garantiefall.")}
						</Typography>
					</div>
				</div>
			</div>
		</div>
	)
}

DeliveryAndReturnsSidebar.propTypes = {
	onClose: PropTypes.func.isRequired
}

export default DeliveryAndReturnsSidebar

import React, {useCallback, useContext, useEffect, useRef} from "react"
import AccordionItem from "./AccordionItem"
import PropTypes from "prop-types"
import {useRouter} from "next/router"
import slugify from "slugify"
import {useTranslation} from "next-i18next"
import {findBikeAttributeBySlug} from "../../utility/Helper"
import useOnScreen from "../../hooks/OnScreen"
import {variants} from "../../middleware"
import ABContext from "../../context/ABContext"

const Accordion = ({
	accordionItemsContent,
	multiple,
	setActiveLink,
	noDividerBeforeBody,
	urlBase,
	plusIcon,
	grey,
	accItemsClasses,
	translate,
	activeHighlighted,
	active,
	setActive,
	bike,
	isDesktop = false,
	isAccessory
}) => {
	const t = translate ? translate : useTranslation().t
	const videoEmbed = findBikeAttributeBySlug(bike, "youtube-link", true)
	const {variant} = useContext(ABContext)
	const isNew = variant === variants.new

	const router = useRouter()

	const onToggle = useCallback(
		title => {
			const slugifiedTitle = slugify(title).toLowerCase()

			if (multiple) {
				setActive(prev => {
					const activeItems = Array.isArray(prev) ? prev : []
					const isAlreadyActive = activeItems.includes(slugifiedTitle)

					if (isAlreadyActive) {
						return activeItems.filter(item => item !== slugifiedTitle)
					}

					return [...activeItems, slugifiedTitle]
				})
			} else if (active.includes(slugifiedTitle)) {
				setActive(null)
				router.replace({pathname: urlBase}, undefined, {shallow: true, scroll: false})
			} else {
				router.replace(
					{pathname: urlBase, query: {tab: slugifiedTitle}},
					undefined,
					{shallow: true, scroll: false}
				)
				setActive(slugifiedTitle)
			}
		},
		[active, multiple, router, setActive, urlBase]
	)

	const accordionContent = videoEmbed ? accordionItemsContent : isNew ?
		accordionItemsContent.filter(item => item.title !== "Youtube Videos" &&
            (isNew && item.title !== "Einzelheiten")) :
		accordionItemsContent.filter(item => item.title !== "Youtube Videos" &&
            item.title !== "Size")

	const refQ = useRef(null)
	const isOnScreenQNA = useOnScreen(refQ)
	useEffect(() => {
		if (isOnScreenQNA) {
			setActiveLink("Q&A")
		}
	}, [isOnScreenQNA])

	return (
		<div className="accordion accordion-flush d-grid pt-2 pt-md-0"
			id="accordionFlush">
			{accordionContent.map(({title, slug, content, titleContent}, index) => {
				const contentToRender = typeof content === "string" ? t(content) : content
				return (
					<React.Fragment key={index}>
						<div ref={refQ} />
						<AccordionItem
							key={title}
							active={
								multiple ?
									active?.includes(slugify(slug || t(title)).toLowerCase()) :
									active === slugify(slug || t(title)).toLowerCase()
							}
							title={t(title)}
							slug={slug}
							onToggle={onToggle}
							multiple={multiple}
							plusIcon={plusIcon}
							grey={grey}
							noShadow={true}
							accItemsClasses={accItemsClasses}
							titleContent={titleContent}
							activeHighlighted={activeHighlighted}
							noDividerBeforeBody={noDividerBeforeBody}
							bike={bike}
							isForPDP
							isAccessory={isAccessory}
							accordionContent={accordionItemsContent}
							isVariantNew={isNew}
						>
							{contentToRender}
						</AccordionItem>

					</React.Fragment>)
			})}
		</div>
	)
}

Accordion.propTypes = {
	accordionItemsContent: PropTypes.array.isRequired,
	current: PropTypes.any,
	noDividerBeforeBody: PropTypes.any,
	multiple: PropTypes.bool,
	activeHighlighted: PropTypes.bool,
	urlBase: PropTypes.string,
	plusIcon: PropTypes.bool,
	grey: PropTypes.bool,
	accItemsClasses: PropTypes.string,
	translate: PropTypes.func,
	active: PropTypes.any,
	setActive: PropTypes.func,
	bike: PropTypes.any,
	isForPDP: PropTypes.bool,
	isDesktop: PropTypes.bool,
	setActiveLink: PropTypes.func,
	isAccessory: PropTypes.bool
}

export default Accordion

import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import DataContext from "../../context/DataContext"
import Button from "../reusable/components/Button"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import {useRouter} from "next/router"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
  sticky: boolean,
  classes?: string
	forRecentlyViewedProducts?: boolean
}

enum pagePathname {
  productPage = "produkt",
  landingPage = "/"
}

const ComparisonPLPFixedComponent: React.FC<Props> = ({sticky, classes = "mt-4", forRecentlyViewedProducts}) => {
	const {t} = useTranslation()
	const {comparisonProducts, setComparisonProducts} = useContext(DataContext)
	const onClearAllComparisonProducts = () => setComparisonProducts([])
	const router = useRouter()
	const {pathname} = router
	const gaCategory = pathname.includes(pagePathname.productPage) ? "pdp" : pathname === pagePathname.landingPage ? "homePage" : "plp"
	const {reactGA} = useContext(AnalyticsContext)

	return (<>{comparisonProducts?.length ?
		<div style={{zIndex: 9999}}
			className={`${sticky ? "position-sticky bottom-0 bg-white" : classes}`}>
			<div className="d-lg-none position-relative">
				<div style={{
					background: "#F8F8F8"
				}}
				className="p-3 comparison-btn-wrapper justify-content-between align-items-center rounded">
					<div className="d-flex align-items-center mb-1 justify-content-between">
						<Typography style={{color: "#2CA58D"}}
							variant="bodyLgBold"
							semanticTag="span">{t("added-to-comparison-list")}</Typography>
						<a
							className="text-decoration-none col-auto"
							style={{cursor: "pointer"}}
							onClick={() => {
								onClearAllComparisonProducts()
								reactGA.event({
									category: gaCategories[gaCategory],
									action: gaEvents.removeAllProductsFromComparison,
									label: gaEvents.removeAllProductsFromComparison,
									nonInteraction: false
								})
							}}>
							<img src="/assets/icons/close-icon.svg"
								alt="close"/>
						</a>
					</div>
					<Typography className=""
						variant="bodySm"
						semanticTag="h3">{forRecentlyViewedProducts ? t("select-couple-products-to-compare") : t("select-couple-e-bikes-to-compare")}</Typography>
					<div className="d-lg-flex align-items-center justify-content-between">
						<Button
							className={"mt-3 mt-lg-0 w-100"}
							disabled={!(comparisonProducts.length > 0)}
							fontSizeSmall={true}
							paddingX="10px"
							paddingY="0"
							label={t("compare")}
							textColor={comparisonProducts.length > 0 ? "#fff" : "#212529"}
							bgColor={comparisonProducts.length > 0 ? "#212529" : "#f2f2f2"}
							hoverColor="#212529"
							href={t("/comparison-page")}
							onClick={() => {
								reactGA.event({
									category: gaCategories[gaCategory],
									action: gaEvents.goToComparisonPage,
									label: gaEvents.goToComparisonPage,
									nonInteraction: false
								})
							}}
						/>
					</div>
				</div>
			</div>

			<div style={{border: 1, borderColor: "#DEE2E6", borderStyle: "solid"}}
				className="d-none d-lg-flex px-lg-4 py-3 py-lg-3 justify-content-between align-items-center  mb-lg-0  rounded">
				<Typography className="col-6"
					variant="heading3"
					semanticTag="h3">{forRecentlyViewedProducts ? t("select-couple-products-to-compare") : t("select-couple-e-bikes-to-compare")}</Typography>
				<div className="d-lg-flex col-6 align-items-center justify-content-between">
					<Typography className="me-3 col-auto"
						variant="bodyLg"
						semanticTag="span">{`${comparisonProducts.length} ${t("selected")}`}</Typography>
					<a
						className="text-decoration-none text-dark col-auto border-bottom border-3 border-primary pointer px-0 me-lg-4"
						onClick={() => {
							onClearAllComparisonProducts()
							reactGA.event({
								category: gaCategories[gaCategory],
								action: gaEvents.removeAllProductsFromComparison,
								label: gaEvents.removeAllProductsFromComparison,
								nonInteraction: false
							})
						}}>
						<Typography variant="bodyLgBold"
							semanticTag="span">{t("clear-all")}</Typography>
					</a>
					<Button className="mt-3 mt-lg-0 col-auto"
						label={t("compare")}
						color="#212529"
						textColor="#212529"
						bgColor="#FEC04D"
						href={t("/comparison-page")}
						onClick={() => {
							reactGA.event({
								category: gaCategories[gaCategory],
								action: gaEvents.goToComparisonPage,
								label: gaEvents.goToComparisonPage,
								nonInteraction: false
							})
						}}
					/>
				</div>
			</div>

		</div> :
		null}</>
	)
}

export default ComparisonPLPFixedComponent

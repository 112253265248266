import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import React, {useContext, useState} from "react"
import Link from "next/link"
import {tabItems} from "../../../constants/Instances"
import OrganizationContext from "../../../context/OrganizationContext"

const MobileEcosystemTabs = () => {
	const {organizationSlug} = useContext(OrganizationContext)
	const [activeItem, setActiveItem] = useState("MyBikePlan")
	return (
		<>
			<div className="my-4"
				style={{border: "1px solid #DEE2E6"}}/>
			<div className="d-flex align-items-center justify-content-between mb-4 ">
				<Typography variant="bodySm"
					semanticTag="span">MyPlan ecosystem</Typography>
			</div>
			<ul className="list-unstyled">
				{tabItems.map((item, index) =>
					<li
						key={index}
						className="p-3 mb-3 cursor-pointer"
						style={{backgroundColor: item.bgColor, borderRadius: 16}}
						onClick={() => setActiveItem(item.title)}>
						<Link href={item.href.replace("instance", organizationSlug)}>
							<a
								href={item.href.replace("instance", organizationSlug)}
								className="text-decoration-none d-flex justify-content-between align-items-center"
								style={{color: "inherit"}}
								rel="noreferrer"
							>
								<div className="d-flex align-items-center"
									style={{columnGap: 16}}>
									<Image src={item.mobileIconSrc}
										width="24px"
										height="24px"
										alt="icon" />
									<div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "0.4rem"}}>
										<Image src={item.iconSrc}
											width="120px"
											height="19px"
											alt={item.title} />
									</div>
								</div>
								{item.title === activeItem && <Image src={item.checkmarkSrc}
									width="24px"
									height="24px"
									alt="checkmark" />}
							</a>
						</Link>
					</li>
				)}
			</ul>
		</>
	)
}

export default MobileEcosystemTabs

export const descriptionTitles = {
	overview: {
		de: "Übersicht",
		fr: "Vue d'ensemble"
	},
	description: {
		de: "Einzelheiten",
		fr: "Détails"
	}
}

import React, {useContext} from "react"
import {BikeType} from "../../../utility/Types"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {getItemsToHideInComparisonFromBike} from "../../../utility/Helper"
import DataContext from "../../../context/DataContext"

type Props = {
	bike: BikeType
	attributesToCompare?:Object
}
function ComparisonAttributes({bike, attributesToCompare = {}}: Props) {
	const {t} = useTranslation()
	const {comparisonAttributes} = useContext(DataContext)

	const localAttributesToCompare = getItemsToHideInComparisonFromBike(bike, comparisonAttributes)
	const atts = []
	Object.entries(localAttributesToCompare[bike.id])?.forEach(([key, value]) => {
		if (Object.entries(attributesToCompare).length) {
			Object.entries(attributesToCompare)?.forEach(([comparedKey, comparedValue]) => {
				// @ts-ignore
				if (key === comparedKey && !value?.terms?.some(option => option.slug === comparedValue?.terms[0].slug)) {
					atts.push(value)
				}
			})
		} else if (key !== undefined && value !== undefined) {
			atts.push(value)
		}
	})

	return (
		<>
			{atts?.length ? atts?.map((att, index) => {
				const attr = att?.terms?.[0].name || null

				return <React.Fragment key={index}> {attr ? <div
					className="d-flex  py-1 px-md-3 justify-content-start align-items-start flex-column">
					{<Typography style={{fontSize: "14px", lineHeight: "20px", textAlign: "left !important"}}
						variant="bodyXSmBold">{att?.name.trim()}</Typography>}
					<Typography style={{fontSize: "12px", lineHeight: "16px"}}
						className="text-start"
						variant="bodySm"> {attr}</Typography></div> : null}</React.Fragment>
			}) : null}
		</>
	)
}

export default ComparisonAttributes

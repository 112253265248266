import {useRouter} from "next/router"
import React, {useContext} from "react"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {ModalContext} from "../../../context/ModalContext"
import Button, {Color} from "../components/Button"
import BookAppointmentModalContent from "../ModalsContent/BookAppointmentModalContent"
import Image from "next/image"
import Typography from "../Typography/Typography"
import AnalyticsContext from "../../../context/AnalyticsContext"

type Props = {
	label?: string
	color?: keyof typeof Color | string
	textColor?: keyof typeof Color | string
	hoverColor?: keyof typeof Color | string
	bgColor?: keyof typeof Color | string
	fontSizeSmall?: boolean,
	outline?: boolean,
	high?: boolean,
	padding?: string,
	header?: boolean,
	plp?: boolean,
	icon?: React.ReactNode,
	className?: string,
	onlyIcon?: boolean,
	linkWithIcon?: boolean,
	render?: (onClick: () => void) => React.ReactElement,
	isListView?: boolean
	iconFirst?: boolean
	isBtnLeftAligned?:boolean
    textVariant?: any
}

const BookAppointmentButton = ({
	onlyIcon,
	className,
	plp,
	header,
	label,
	render,
	color = "#212529",
	textColor = "#fff",
	bgColor,
	fontSizeSmall,
	outline,
	high,
	padding,
	linkWithIcon = false,
	icon,
	isListView,
	iconFirst,
	isBtnLeftAligned,
	textVariant
}: Props): React.ReactElement => {
	const {setModalContent, openModal, closeModal} = useContext(ModalContext)

	const router = useRouter()
	const pathName = router.pathname.split("/")[1] ? router.pathname.split("/")[1] : ""
	const {reactGA} = useContext(AnalyticsContext)

	const handleClick = () => {
		if (header) {
			if (pathName === "produkt") {
				reactGA?.event({
					category: gaCategories.consultation,
					action: gaEvents.pdp,
					label: `${gaCategories.consultation} - ${gaEvents.pdp}`,
					nonInteraction: false
				})
			} else if (pathName === "blog") {
				reactGA?.event({
					category: gaCategories.consultation,
					action: gaEvents.blog,
					label: `${gaCategories.consultation} - ${gaEvents.blog}`,
					nonInteraction: false
				})
			} else {
				reactGA?.event({
					category: gaCategories.consultation,
					action: gaEvents.headers,
					label: `${gaCategories.consultation} - ${gaEvents.headers}`,
					nonInteraction: false
				})
			}
		}

		if (plp) {
			reactGA?.event({
				category: gaCategories.consultation,
				action: gaEvents.plp,
				label: `${gaCategories.consultation} - ${gaEvents.plp}`,
				nonInteraction: false
			})
		}

		setModalContent(<BookAppointmentModalContent closeModal={closeModal}/>)
		openModal()
	}

	return (render ?
		render(handleClick) : onlyIcon ?
			<button className={"btn pt-2"}
				onClick={handleClick}>
				<Image src={"/assets/icons/calendar-consultation.svg"}
					height={24}
					width={24} />
			</button> :
			linkWithIcon ?
				<div className="d-flex align-items-center px-0 p-1
                justify-content-between align-items-center gap-2 cursor-pointer rounded-3"
				onClick={handleClick}>
					<Typography variant={textVariant ? textVariant : isListView ? "bodySmBold" : "heading3"}
						className="mb-0"
						semanticTag={isListView ? "span" : "h3"}>{label}</Typography>
					{icon ? icon : null}
				</div> :
				isBtnLeftAligned ?
					<div className={`d-flex px-0 justify-content-start align-items-center gap-2 cursor-pointer ${className ? className : ""}`}
						onClick={handleClick}>

						<Typography style={{lineHeight: "20px", fontSize: "14px"}}
							variant={ "bodySmBlack"}
							semanticTag={"span"}>{label}</Typography>
					</div>				:
					<Button className={`w-100 ${className ? className : ""}`}
						icon={icon}
						padding={padding}
						high={high}
						onClick={handleClick}
						bgColor={bgColor}
						fontSizeSmall={fontSizeSmall}
						textColor={textColor}
						color={color}
						outline={outline}
						label={label}
						iconFirst={iconFirst}/>

	)
}

export default BookAppointmentButton

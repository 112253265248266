import React, {useContext, useEffect, useState} from "react"
import slugify from "slugify"
import Typography from "../reusable/Typography/Typography"
import AccordionAnimation from "../reusable/components/AnimationComponent/AccordionAnimation"
import AccordionHeaderWithPlusIcon from "./AccordionHeaderWithPlusIcon"
import {useRouter} from "next/router"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import LayoutContext from "../../context/LayoutContext"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import ComparisonCheckboxButtonPdp from "../bikesCollection/BikesCollectionItem/ComparisonCheckboxButtonPdp"
import {downloadBikePDFfromAWS, findBikeMetaFieldByName} from "../../utility/Helper"
import {s3BucketNames} from "../../utility/aws/s3BucketNames"
import {descriptionTitles} from "../bikeDetails/descriptionTitles"
import HighlightsAndSpecsTabs from "./HighlightsAndSpecsTabs"

type CustomHeaderProps = {
	title: string
	isActive: boolean
	onClick: () => void
}

type Props = {
	title: string
	slug?: string
	active?: boolean
	onToggle?: (title: string) => void
	multiple?: boolean,
	children: React.ReactNode
	grey?: boolean
	plusIcon?: boolean
	accItemsClasses?: string
	customHeader?: (props: CustomHeaderProps) => React.ReactElement
	titleContent?: React.ReactElement | null
	bodyClasses?: string
	activeHighlighted?: boolean
	noShadow?: boolean
	noDividerBeforeBody?: boolean
	bike?: any
	isForPDP?: boolean
	isAccessory?: boolean
    accordionContent?: any
    isVariantNew?: boolean
}

const AccordionCustomItem = ({
	title,
	slug,
	active,
	onToggle,
	multiple,
	children,
	grey,
	plusIcon,
	accItemsClasses,
	customHeader,
	bodyClasses,
	titleContent,
	noShadow,
	noDividerBeforeBody,
	bike,
	isAccessory,
	accordionContent,
	isVariantNew
}: Props): React.ReactElement => {
	const [visibility, setVisibility] = useState(active)
	const [activeTab, setActiveTab] = useState("Highlights")
	const [mounted, setMounted] = useState(false)
	const isActive = multiple ? visibility : active
	const {isMobile} = useContext(LayoutContext)

	const router = useRouter()
	const {locale} = router
	const {pathname} = router
	const {reactGA} = useContext(AnalyticsContext)
	const {t} = useTranslation(["common"])
	const pdfKey = findBikeMetaFieldByName(bike, "pdf-key") || null
	const [bikeSlugForCompare, setBikeSlugForCompare] = useState("")

	const bikeSpecs = accordionContent?.find(accordion => accordion.title === "Einzelheiten")

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (bike?.slug) {
			setBikeSlugForCompare(bike?.slug)
		}
	}, [bike?.slug])

	useEffect(() => {
		setVisibility(active)
	}, [active])

	const toggleVisibility = () => {
		const newVisibility = !visibility
		setVisibility(newVisibility)
		onToggle(slugify(slug || title).toLowerCase())

		if (pathname.includes("produkt") && !visibility) {
			const variantLabel = isVariantNew ? "A(new)" : "B(old)"

			reactGA?.event({
				category: gaCategories.pdp,
				action: gaEvents.descriptionSection,
				label: `${title} - Variant ${variantLabel}`,
				nonInteraction: false
			})
		}
	}

	const handleClick = async () => {
		await downloadBikePDFfromAWS(s3BucketNames.pdfBucket, pdfKey)
	}

	const className = `aaccordion-flush shadow-none  ${!isActive && "collapsed"}`
	const wrapClasses = `accordion-item ${noShadow ? "shadow-0" : "shadow-sm"} border-bottom overflow-hidden border-0 
    ${grey ? "bg-grey" : ""} ${accItemsClasses ? accItemsClasses : ""}`

	const isActiveItem = router.query.tab ? router.query.tab === slugify(title).toLocaleLowerCase() :
		title === descriptionTitles.overview[locale] ? true :
			title === descriptionTitles.description[locale]

	useEffect(() => {
		if (isActiveItem) {
			setVisibility(true)
		}
	}, [isActiveItem])

	const handleTabClick = tabName => {
		if (slug === "overview-tabs" && visibility) {
			setActiveTab(tabName)
		}

		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.highlightsAndSpecsTabs,
			label: tabName,
			nonInteraction: false
		})
	}

	return (
		<>
			{isVariantNew && slug === "overview-tabs" &&
					<HighlightsAndSpecsTabs
						title={title}
						activeTab={activeTab}
						t={t}
						handleTabClick={handleTabClick}/>
			}
			<div className={wrapClasses}>
				{customHeader && customHeader({title, isActive, onClick: toggleVisibility})}
				<div className="d-md-none">
					{customHeader ? null : plusIcon ?
						<AccordionHeaderWithPlusIcon isActive={isActive}
							title={title}
							onClick={toggleVisibility}/> :
						<div onClick={toggleVisibility}
							className={`accordion-header pointer pt-3 pb-2 d-flex justify-content-between bg-white 
                            ${className}`}>
							{titleContent && !isMobile ? titleContent :
								<Typography variant="bodyLgBold"
									semanticTag="span"
									style={{color: "#212529"}}>
									{activeTab === "Einzelheiten" ? "Einzelheiten" : title}
								</Typography>}

							{isActive ? <Image style={{transform: "rotate(180deg)"}}
								src={"/assets/icons/chevron-down.svg"}
								height={15}
								width={15}/> :
								<Image src={"/assets/icons/chevron-down.svg"}
									height={15}
									width={15}/>}
						</div>}
				</div>
				<div className="d-none d-md-block">
					{/* Conditional Content Rendering */}
					{customHeader ? null :
						<div onClick={toggleVisibility}
							className={`accordion-header pointer pb-4 pt-3 
                            d-flex align-items-center justify-content-between bg-white ${className}`}>
							<div className="col-12
                            d-flex justify-content-between align-items-start">
								<div className=" d-flex align-items-center">
									{titleContent ? (
										isVariantNew && activeTab === "Einzelheiten" ?
											bikeSpecs?.titleContent :
											titleContent
									) :
										<Typography variant="bodyLgBold"
											semanticTag="span">
											{activeTab === "Einzelheiten" ? "Einzelheiten" : title}
										</Typography>}
									{title === "Einzelheiten" ? <div className="d-flex ms-4">
										{!isAccessory && <div>
											<ComparisonCheckboxButtonPdp bgColor={"#FFF6E7"}
												borderRadius="4px"
												slug={bike?.slug}
												paddingY="py-2"
												isPDPcomparisonBtn
												textVariant="bodySmBold"
											/>
										</div>}
									</div> : ""}
									{pdfKey && <div
										className="d-flex py-2 px-3 ms-2 justify-content-center align-items-center"
										onClick={handleClick}
										style={{background: "#F8F8F8", borderRadius: 16}}>
										<Image src={"/assets/icons/file-pdf.svg"}
											height="24"
											width="24"/>
										<Typography className="ms-3"
											variant="bodyLgBold">{t("Download overview")}</Typography>
									</div>}
								</div>
								<div className="d-flex justify-content-center align-items-center mt-2"
									style={{width: 20, height: 20}}>
									{isActive ? <Image style={{transform: "rotate(180deg)"}}
										src={"/assets/icons/chevron-down.svg"}
										height={6.5}
										width={12}/> :
										<Image src={"/assets/icons/chevron-down.svg"}
											height={6.5}
											width={12}/>}
								</div>
							</div>
						</div>}
				</div>

				<div className="overflow-hidden py-0 d-none d-md-block">
					<AccordionAnimation inProp={mounted && isActive}
						transTimingFn="ease-in-out"
						duration={600}
						customHeight={"100%"}>
						<div
							className={bodyClasses ? bodyClasses : `${title === "Übersicht" ? "p-0" : "p-0"} 
                                accordion-body pb-4  ${noDividerBeforeBody ? "" : `${!isMobile && "border-top"}`} mx-0`}
							style={{backgroundColor: "#fff", borderRadius: 16}}>
							{(isVariantNew && activeTab === "Einzelheiten") ? bikeSpecs?.content : children}
						</div>
					</AccordionAnimation>
				</div>
				<div className="overflow-hidden py-0 d-md-none">
					<AccordionAnimation inProp={mounted && isActive}
						transTimingFn={"ease-in-out"}
						duration={600}
						customHeight={"100%"}>
						<div
							className={bodyClasses ? bodyClasses : `accordion-body p-0 px-md-3  
                                ${noDividerBeforeBody ? "" : `${!isMobile && "border-top"}`} my-2 mx-2`}
							style={{backgroundColor: "#fff", borderRadius: 16,
								maxWidth: title === "Übersicht" ? 568 : ""}}>
							{(isVariantNew && activeTab === "Einzelheiten") ? bikeSpecs?.content : children}
						</div>
					</AccordionAnimation>
				</div>
			</div>
		</>
	)
}

export default AccordionCustomItem


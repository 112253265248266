import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import LayoutContext, {devices} from "../../../context/LayoutContext"
import {getImgDescriptionFormat} from "../../../utility/Helper"

interface Props {
  description: string
}

export const ImageDescription = ({description}: Props) => {
	const {device, isMobile} = useContext(LayoutContext)
	const {t} = useTranslation()
	const formatedDescription = getImgDescriptionFormat(description, t)
	return (
		isMobile ?
			<div style={{
				zIndex: 1,
				backgroundColor: "white !important",
				top: device === devices.phone ? "80%" : device === devices.tablet ? "21%" : 0
			}}
			className={"bg-white position-absolute start-0 end-0 m-0 mt-5"}>
				{formatedDescription}
			</div> :
			<div className="position-absolute"
				style={{height: "40px", width: "100%", bottom: 0}}>
				<div style={{zIndex: 1, top: device === devices.tablet ? 230 : 0, left: device === devices.tablet ? 70 : 0}}
					className="position-absolute m-0">
					{formatedDescription}
				</div>
			</div>
	)
}

export default ImageDescription

import React, {Dispatch, SetStateAction, useContext, useEffect} from "react"
import styles from "../navigation/navigation.module.scss"
import Link from "next/link"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import AnalyticsContext from "../../context/AnalyticsContext"
import DataContext from "../../context/DataContext"
import {useRouter} from "next/router"
import LinkWithIcon from "../reusable/components/LinkWithIcon"
import Image from "next/image"
import useCache from "../../hooks/useCache"
import Spinner from "../reusable/Spinner"
import TopSearches from "./TopSearches"
import {SearchTopCategories} from "../../constants/TopCategories"
import BikeExplorerTabs from "../bikesCollection/BikeExplorerTabs"

type Props = {
	setIsBackgroundOn?: Dispatch<SetStateAction<boolean>>
	isBackgroundOn?:boolean
	isBrandsPage?:boolean
}

const SearchContent: React.FC<Props> = ({setIsBackgroundOn, isBackgroundOn, isBrandsPage}) => {
	useEffect(() => {
		if (typeof document !== "undefined" && isBackgroundOn) {
			document.body.style.overflow = "hidden"
		} else if (!isBackgroundOn) {
			document.body.style.overflow = "auto"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [isBackgroundOn])
	const {t} = useTranslation(["common"])
	const {reactGA} = useContext(AnalyticsContext)
	const {brands, height} = useContext(DataContext)
	const topBrandsForMegaMenu = brands?.filter(({fields}) => fields?.addToMegaMenu)
	const router = useRouter()
	const {locale} = router
	const {data: topSearches, isLoading: isLoadingSuggestions} = useCache("/api/search/top-searches?limit=3", false, false, true)

	return (
		<>
			<section className="search-content bg-white "
				style={{width: "100%", overflowY: "auto", left: 0, zIndex: 90000, marginTop: 10, height: "80dvh"}}>
				<div className="container px-3 px-md-0">
					<div className="d-flex flex-column flex-md-row justify-content-center align-items-start align-items-md-center pt-3 pb-4 pb-md-5">
						<Typography variant="bodySmBold"
							className="me-3 mb-3 mb-md-0">{t("most-commonly-searched:")}</Typography>
						{isLoadingSuggestions ? <Spinner/> :
							<TopSearches topSearches={topSearches}
								forSearchContent
								setIsBackgroundOn={setIsBackgroundOn}/>}
					</div>
					<div className="row d-none d-md-flex justify-content-center gap-5 ">
						<div className="col-auto">
							<Typography variant="bodySmBold"
								className="ps-3 pb-2 mb-1">{t("Categories")}</Typography>
							<ul className="list-unstyled">
								{SearchTopCategories[locale].map(({link, title}, i) => (
									<li
										key={i}
										className={`rounded-3 d-flex justify-content-between align-items-center ${styles.megamenuItem}`}
										style={{maxWidth: 240, minWidth: 155, width: "100%", padding: "6px 16px"}}>
										<Link href={filterUrl({[urlParams.categories]: link}, null, null)}>
											<a onClick={() => {
												reactGA?.event({
													category: gaCategories.megaMenu,
													action: gaEvents.category,
													label: `${gaEvents.category} ${title}`,
													nonInteraction: false
												})
											}}
											className={"link-reset col-12"}>
												<Typography style={{color: "#212529"}}
													variant="bodySm"
													className="d-none d-md-block">{t(title)}</Typography>
												<Typography style={{color: "#212529"}}
													variant="bodySm"
													className="d-md-none">{t(title)}</Typography>
											</a>
										</Link>
									</li>
								))}
							</ul>
						</div>
						<div className="col-2 ">
							<Typography variant="bodySmBold"
								className="ps-3 pb-2 mb-1">{t("Your height")}</Typography>
							<ul className="list-unstyled">
								{
									height?.options?.map((heightValue, i) =>
										<div key={i}
											className="col-auto">
											<Link href={filterUrl({[urlParams.bikerHeight]: heightValue.slug}, null, null)}>
												<a className={`text-decoration-none rounded-3 text-dark d-block ${styles.megamenuItem}`} >
													<Typography variant={"bodySm"}>{heightValue.name}</Typography>
												</a>
											</Link>
										</div>
									)
								}
							</ul>
						</div>
						<div className="col-2">
							<Typography variant="bodySmBold"
								className="ps-3 pb-2 mb-1">{t("top-brands")}</Typography>
							<ul className="list-unstyled">
								{topBrandsForMegaMenu?.map(({fields}, idx) => {
									const {menuLabel, slug, filterSlug} = fields
									return (
										<li key={idx}>
											<Link href={filterUrl({[urlParams.brand]: filterSlug}, null, null)}>
												<a onClick={() => {
													reactGA?.event({
														category: gaCategories.megaMenu,
														action: gaEvents.topBrands,
														label: menuLabel,
														nonInteraction: false
													})
												}}
												className={`text-decoration-none rounded-3 text-dark d-block ${styles.megamenuItem}`} >
													<Typography variant={`${router.query.slug === slug ? "bodySmBold" : "bodySm"}`}>{menuLabel}</Typography>
												</a>
											</Link>
										</li>)
								})}
							</ul>
							<div onClick={() => {
								reactGA?.event({
									category: gaCategories.megaMenu,
									action: gaEvents.allEBrandsButton,
									label: gaEvents.allEBrandsButton,
									nonInteraction: false
								})
							}}
							className="ms-2">
								<LinkWithIcon
									href={"/alle-marken"}
									label={t("Alle Marken")}
									iconBgColor="#EBEBEB"
									iconMargin="ms-2"
									labelVariant={"bodySmBold"}
									padding={"5.5px 8px"}
									icon={
										<Image src="/assets/icons/chevron-dark-bg-light.svg"
											width={20}
											height={20}
											alt="chevron right"/>
									}/>
							</div>
						</div>
						<div className="col-4">
							<BikeExplorerTabs inSearchContent={true} />
						</div>
					</div>
					{/* Mobile Version */}
					<div className="row mb-3 px-0 d-flex  search-content-list  pb-4 d-md-none">
						<div className="px-0 mb-4">
							<BikeExplorerTabs inSearchContent={true}/>
						</div>

						<div className="col-5 px-0">
							<Typography variant="bodySmBold"
								className="ps-3 pb-2 mb-1">{t("Categories")}</Typography>
							<ul className="list-unstyled">
								{SearchTopCategories[locale].map(({link, title}, i) => (
									<li
										key={i}
										className={`rounded-3 d-flex justify-content-between align-items-center ${styles.megamenuItem}`}
										style={{maxWidth: 240, minWidth: 155, width: "100%", padding: "6px 16px"}}>
										<Link href={filterUrl({[urlParams.categories]: link}, null, null)}>
											<a onClick={() => {
												reactGA?.event({
													category: gaCategories.megaMenu,
													action: gaEvents.category,
													label: `${gaEvents.category} ${title}`,
													nonInteraction: false
												})
											}}
											className={"link-reset col-12"}>
												<Typography style={{color: "#212529"}}
													variant="bodySm"
													className="d-none d-md-block">{t(title)}</Typography>
												<Typography style={{color: "#212529"}}
													variant="bodySm"
													className="d-md-none">{t(title)}</Typography>
											</a>
										</Link>
									</li>
								))}
							</ul>
						</div>
						<div className="col-5 px-0">
							<Typography variant="bodySmBold"
								className="ps-2 ps-md-3 pb-2 mb-1">{t("Your height")}</Typography>
							<ul className="list-unstyled">
								{
									height?.options?.map((heightValue, i) =>
										<div key={i}
											className="col-auto">
											<Link href={filterUrl({[urlParams.bikerHeight]: heightValue.slug}, null, null)}>
												<a className={`text-decoration-none rounded-3 text-dark d-block ${styles.megamenuItem}`} >
													<Typography variant={"bodySm"}>{heightValue.name}</Typography>
												</a>
											</Link>
										</div>
									)
								}
							</ul>
						</div>
						<div className="col-5 px-0">
							<Typography variant="bodySmBold"
								className="ps-2 ps-md-3 pb-2 mb-1">{t("top-brands")}</Typography>
							<ul className="list-unstyled">
								{topBrandsForMegaMenu?.map(({fields}, idx) => {
									const {menuLabel, slug, filterSlug} = fields
									return (
										<li key={idx}>
											<Link href={filterUrl({[urlParams.brand]: filterSlug}, null, null)}>
												<a onClick={() => {
													reactGA?.event({
														category: gaCategories.megaMenu,
														action: gaEvents.topBrands,
														label: menuLabel,
														nonInteraction: false
													})
												}}
												className={`text-decoration-none rounded-3 text-dark d-block ${styles.megamenuItem}`} >
													<Typography variant={`${router.query.slug === slug ? "bodySmBold" : "bodySm"}`}>{menuLabel}</Typography>
												</a>
											</Link>
										</li>)
								})}
							</ul>
							<div onClick={() => {
								reactGA?.event({
									category: gaCategories.megaMenu,
									action: gaEvents.allEBrandsButton,
									label: gaEvents.allEBrandsButton,
									nonInteraction: false
								})
							}}
							className="ms-md-2">
								<LinkWithIcon
									href={"/alle-marken"}
									label={t("Alle Marken")}
									iconBgColor="#EBEBEB"
									iconMargin="ms-2"
									labelVariant={"bodySmBold"}
									padding={"5.5px 8px"}
									icon={
										<Image src="/assets/icons/chevron-dark-bg-light.svg"
											width={20}
											height={20}
											alt="chevron right"/>
									}/>
							</div>
						</div>
					</div>
				</div>
			</section>

		</>
	)
}

export default SearchContent

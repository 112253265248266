import {CartItem} from "../context/CartContext"
import {Deal, DealPipelines, DealStages} from "../context/CheckoutContext"
import {findBikeMetaFieldByName, isOccasionBike} from "../utility/Helper"
import {generateUUID} from "../utility/UUID"
import {HubspotDealCategories} from "../constants/HubspotDealCategories"

export const cartItemToDeal = (cartItem: CartItem, planLength: number, discountCode: string) => {
	const {name, price, finalPriceWithoutDownPayment, sku, expected_delivery_date__website_, id, deliveryTimeInDays, logisticsCompany, discount} = cartItem.product
	const frame = cartItem?.product?.attributes?.find(e => e.slug === "frame-variant")?.terms[0]?.name
	const color = cartItem?.product?.attributes?.find(e => e.slug === "color")?.terms[0]?.name

	const isOccasion = isOccasionBike(cartItem.product)
	const frameNumber = findBikeMetaFieldByName(cartItem.product, "frame_number")

	// Get Hubspot category
	let hubspotCategory = null
	cartItem.product?.categories?.forEach(productCategory => {
		const hubspotCategoryValue = HubspotDealCategories[productCategory.slug]
		if (hubspotCategoryValue) {
			hubspotCategory = hubspotCategoryValue
		}
	})

	const e_bike_stock_information = cartItem.product.stock_status === "instock" ? "In stock" : "On order"
	const bikeId = id

	const brand = (cartItem?.product?.attributes?.find(e => e.slug === "brand") || cartItem?.product?.attributes?.find(e => e.slug === "accessory-brand"))?.terms[0]?.name

	const deal = {

		e_bike_frame: frame,
		e_bike_choice: name,
		e_bike_size: cartItem.selectedSize,
		e_bike_color: color,
		e_bike_price: finalPriceWithoutDownPayment || price,
		plan_length: planLength,
		article_number_2: sku,
		amount: finalPriceWithoutDownPayment || price,
		dealname: null,
		pipeline: DealPipelines.default,
		dealstage: DealStages.appointmentscheduled,
		discount_code: discountCode,
		discount_price: discount || null,
		e_bike_category: hubspotCategory,
		expected_delivery_date__website_,
		deliveryTimeInDays,
		// hs_analytics_source_data_1,
		// hs_analytics_source,
		e_bike_stock_information,
		logitics_company: logisticsCompany,
		bikeId,
		brand,
		isOccasion: isOccasion ? "true" : "false",
		frame_number: isOccasion ? frameNumber : null,
		selected_add_on: cartItem.selectedAddOn,
		insurance_selected: "false",
		insurance_price: 0,
		free_insurance: "false",
		down_payment_amount: cartItem?.downPayment,
		checkout_deal_id: generateUUID(),
		uniqueId: cartItem.uniqueId || null,
		isOnSale: cartItem.isOnSale || false,
		initialPrice: cartItem.initialPrice || null
		/* eslint-enable camelcase */
	} as Deal

	return deal
}

export const zibatraBrands = [
	"Bergstrom",
	"Liv",
	"Giant",
	"Viking",
	"Kalkhoff",
	"Focus",
	"Allegro",
	"Cilo",
	"Stromer",
	"Ruff Cycles",
	"Ruff Cycle",
	"Simpel",
	"Ghost",
	"Haibike",
	"Benno",
	"Flyer",
	"Fischer",
	"Bird",
	"R Raymon",
	"HNF Nicolai",
	"Electra",
	"Winora",
	"Union",
	"Abus",
	"HNF Nikolai",
	"CA GO",
	"Thule",
	"Cresta",
	"Specialized",
	"AddBike",
	"QIO",
	"IXS",
	"Rudy Project",
	"100 Percent",
	"Leatt",
	"Kryptonite",
	"Atera",
	"Hollywood Racks",
	"Vaude",
	"GasGas",
	"FELT",
	"SoFlow"
]

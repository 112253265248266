import React from "react"

type Props = {
    numberOfSkeletonElements: number
}

function SuggestionsSkeletonsLoader({numberOfSkeletonElements}:Props) {
	const dynamicRepitionsTable = Array.from({length: numberOfSkeletonElements}, (_, index) => index + 1)

	return (
		<>
			{dynamicRepitionsTable.map((rep, index) => <div key={index}
				style={{height: "6px", width: "14rem", borderRadius: "8px"}}
				className="suggestion-skeleton-loader" />)}
		</>
	)
}

export default SuggestionsSkeletonsLoader

import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {BikeType} from "../../../utility/Types"

type Props = {
    slug: string
    bgColor?: any
    textBold?: boolean
    borderRadius?: string
    paddingY?: string
	isPDPcomparisonBtn?: boolean
	bike?:BikeType
    textVariant?: any
  }

export const onModalButtonClick = (router, pathname) => {
	const params = new URLSearchParams()
	Object.entries(router.query)?.forEach(([key, value]) => params.set(key, value as string))
	if (params.has("modal")) {
		params.delete("modal")
		router.replace(`${pathname}?${params}`, null, {shallow: true, scroll: false})
		return
	}

	params.append("modal", "open")
	router.push(`${pathname}?${params}`, null, {shallow: true, scroll: false})
}

const SimilarBikesPdpButton: React.FC<Props> = ({
	textVariant = "bodyLgBold",
	isPDPcomparisonBtn,
	borderRadius,
	textBold,
	bgColor,
	bike
}) => {
	const {t} = useTranslation()
	const router = useRouter()
	const {pathname} = router
	const hasTheRequiredAttributes = bike?.attributes?.find(attribute => attribute.slug === "speed") || bike?.attributes?.find(attribute => attribute.slug === "range") || bike?.attributes?.find(attribute => attribute.slug === "battery-capacity")
	if (hasTheRequiredAttributes) {
		return (
			<>
				<div onClick={() => onModalButtonClick(router, pathname)}
					className={`col-auto ${!isPDPcomparisonBtn && "d-none d-md-flex"}  align-items-center compare-btn-new`}
					style={{padding: "8px 16px", width: "auto", backgroundColor: router.query?.modal === "open" ? "#DCE1E7" : bgColor, cursor: "pointer", borderRadius: borderRadius ? borderRadius : 4}}>
					<div className={"d-flex align-items-center"}>
						<img src="/assets/icons/similar.svg"
							alt="icon"
							width={20}
							height={20} />
						<Typography style={{whiteSpace: "nowrap"}}
							className="ms-2 ms-md-3"
							variant={textVariant}
							semanticTag="div">
							{t("similar")}
						</Typography>
					</div>

				</div>
			</>
		)
	}

	return <></>
}

export default SimilarBikesPdpButton

import React from "react"
import Typography from "../Typography/Typography"
import Image from "next/image"

function AiPromptLoadingSkeleton({className}:{className:string}) {
	return (
		<div className={`carousel-ai-card p-4 my-3 col-12 slide-in-right d-flex align-items-start justify-content-start flex-column gap-3 ${className}`} >
			<div style={{width: "30%"}}
				className="d-flex align-items-center justify-content-start p-2 suggestion-skeleton-loader gap-1">
			</div>
			<Typography style={{width: "70%"}}
				variant="bodySm"
				className="col-12 p-2 suggestion-skeleton-loader"></Typography>
			<div style={{width: "100%", borderRadius: "4px"}}
				className=" mt-3 gap-2 d-flex align-items-start justify-content-start flex-column " >
				<Typography style={{width: "100%"}}
					variant="bodySm"
					className="col-12 p-2 suggestion-skeleton-loader"></Typography>
				<Typography style={{width: "100%"}}
					variant="bodySm"
					className="col-12 p-2 suggestion-skeleton-loader"></Typography>

				<Typography style={{width: "100%"}}
					variant="bodySm"
					className="col-12 p-2 suggestion-skeleton-loader"></Typography>

				<Typography style={{width: "100%"}}
					variant="bodySm"
					className="col-12 p-2 suggestion-skeleton-loader"></Typography>

				<Typography style={{width: "100%"}}
					variant="bodySm"
					className="col-12 p-2 suggestion-skeleton-loader"></Typography>
			</div>
			<div style={{width: "80%"}}
				className="d-flex align-items-center justify-content-end p-2 suggestion-skeleton-loader gap-1"><Image src={"/assets/icons/chevron-right-purple.svg"}
					width={24}
					height={24}
					alt="Discover AI Suggestions" />
			</div>
		</div>
	)
}

export default AiPromptLoadingSkeleton

import React from "react"
import Typography from "../reusable/Typography/Typography"

type Props ={
    handleTabClick: (tab) => void
    activeTab: string
    title: string
    t: any
}

const HighlightsAndSpecsTabs: React.FC<Props> = ({handleTabClick, activeTab, title, t}) => {
	return (
		<div className="mt-3 d-flex align-item-center gap-2">
			<div
				className={`rounded-3 tab-button ${activeTab === "Highlights" ? "active" : ""}`}
				onClick={() => handleTabClick("Highlights")}
				style={{
					cursor: "pointer",
					padding: "10px 12px",
					backgroundColor: activeTab === "Highlights" ? "#FEC04D" : "#F8F8F8"
				}}
			>
				<Typography variant="bodySmBold"
					semanticTag="span"
					style={{lineHeight: "20px"}}>{t("Highlights")}</Typography>
			</div>
			<div
				className={`rounded-3 tab-button ${activeTab === "Einzelheiten" ? "active" : ""}`}
				onClick={() => handleTabClick("Einzelheiten")}
				style={{
					cursor: "pointer",
					padding: "10px 12px",
					backgroundColor: activeTab === "Einzelheiten" ? "#FEC04D" : "#F8F8F8"
				}}
			>
				<Typography variant="bodySmBold"
					semanticTag="span"
					style={{lineHeight: "20px"}}>{t("Einzelheiten")}</Typography>
			</div>
		</div>
	)
}

export default HighlightsAndSpecsTabs

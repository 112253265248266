import {useRouter} from "next/router"
import {useContext, useEffect, useState} from "react"
import {fetchPost} from "../firebaseAdmin/fetchPost"
import FirebaseContext from "../context/FirebaseContext"
import AnalyticsContext from "../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../config/googleAnalytics/events"

function usePdpUtilities(bike) {
	const [isLoading, setIsLoading] = useState(false)
	const router = useRouter()
	const [isShaking, setIsShaking] = useState(false)
	const {user, userLoading} = useContext(FirebaseContext)
	const {reactGA} = useContext(AnalyticsContext)

	useEffect(() => {
		if (JSON.parse(localStorage.getItem("pdpViewed"))) {
			const pageView = JSON.parse(localStorage.getItem("pdpViewed"))
			pageView.times++

			localStorage.setItem("pdpViewed", JSON.stringify(pageView))
		} else {
			localStorage.setItem("pdpViewed", JSON.stringify({viewed: true, times: 0}))
		}
	}, [])

	useEffect(() => {
		setTimeout(() => window.scroll({top: 0}), 10)
	}, [isLoading])

	useEffect(() => {
		if (window) {
			router.beforePopState(() => {
				setIsLoading(true)
				return true
			})
		}

		return () => {
			if (window) {
				window.onbeforeunload = null
			}

			router.beforePopState(() => {
				return true
			})
		}
	}, [isLoading])

	useEffect(() => {
		setIsLoading(false)
	}, [router.query])
	useEffect(() => {
		const storedRecentlyViewed = JSON.parse(localStorage.getItem("recentlyViewedBikes")) || []
		const bikeExistsIndex = storedRecentlyViewed.findIndex(el => el?.id === bike.id)
		let updatedRecentlyViewed = []
		if (bikeExistsIndex === -1) {
			updatedRecentlyViewed = [bike, ...storedRecentlyViewed]
		} else {
			updatedRecentlyViewed = storedRecentlyViewed
		}

		if (updatedRecentlyViewed.length > 10) {
			updatedRecentlyViewed = updatedRecentlyViewed.slice(0, 10)
		}

		if (user?.uid) {
			fetchPost(`/api/products/addRecentlyViewedBikes?locale=${router?.locale || "de"}`, {userId: user?.uid, bikes: updatedRecentlyViewed.map(bike => ({slug: bike?.slug, id: bike.id}))})
				.then(response => response.json())
				.catch(error => {
					console.error(error)
				})
		}

		window.dispatchEvent(new Event("RecentlyViewedUpdated"))
	}, [bike, userLoading])

	const startShake = () => {
		reactGA.event({
			category: gaCategories.pdp,
			action: gaEvents.disabledAddToCartClicked,
			label: gaEvents.disabledAddToCartClicked,
			nonInteraction: true
		})
		setIsShaking(true)
		setTimeout(() => setIsShaking(false), 1000)
	}

	return {isLoading, isShaking, startShake}
}

export default usePdpUtilities

import React from "react"
import RyderAiStaticTile from "../bikesCollection/staticTiles/RyderAiStaticTile"

function AskAiAboutMbp({askedQuestion}:{askedQuestion?:string}) {
	return (
		<div style={{maxWidth: "440px", height: "100%", overflowY: "auto"}}>
			<RyderAiStaticTile askedQuestion={askedQuestion}
				customLayoutClassname="d-flex align-items-start justify-content-start gap-3 col-12 h-100 flex-column" />
		</div>
	)
}

export default AskAiAboutMbp

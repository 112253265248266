import {useTranslation} from "next-i18next"
import React, {useState} from "react"
import Typography from "./Typography/Typography"
import {useRouter} from "next/router"
import {Slides} from "./components/Slides"
import MobileModal from "./MobileModal"

type Props = {
    className?: string
    bgColor?: string
	isBootstrapAlertElement?: boolean
	isPDP?:boolean
}
function SocialProof({className, bgColor, isBootstrapAlertElement = true, isPDP = false}:Props) {
	const {t} = useTranslation()
	const router = useRouter()
	const wordsToMakeBold = router.locale === "fr" ? 4 : 5
	const arrayOfWords = t("social-proof-label").split(" ")
	const [modalOpen, setModalOpen] = useState(false)
	return (
		<div style={{backgroundColor: bgColor ? bgColor : "transparent", columnGap: "16px", borderRadius: "16px"}}
			className={`${isBootstrapAlertElement ? "alert" : ""}   ${className}`}
			role="alert">
			<Slides className="d-none d-md-block"
				arrows
				autoplay
				infinite
				customArrowClasses="bg-transparent">
				<div className="d-flex w-100 gap-2 align-items-center justify-content-center">
					<img src="/assets/icons/yellow-heart.svg"
						width={24}
						height={24} /><div><Typography semanticTag="div"
						variant="bodySm"
						style={{fontSize: "14px", lineHeight: "20px"}}><span style={{fontWeight: 700}}>{arrayOfWords.slice(0, wordsToMakeBold).map(word => word + " ")}</span> {arrayOfWords.slice(wordsToMakeBold, arrayOfWords.length).map(word => word + " ")}</Typography> </div>
				</div>
				{isPDP ? null : <div className="d-flex w-100 gap-2 align-items-center justify-content-center">
					<div><Typography semanticTag="div"
						variant="bodySm"
						style={{fontSize: "14px", lineHeight: "20px", fontWeight: 700}}>{t("no-interest-label")} - Bike Now, Pay Later  <span onClick={() => router.push("/konzept")}
							style={{backgroundColor: "#FFE9C2", padding: "0px 4px", borderRadius: "4px", cursor: "pointer"}}> {t("how-it-works")}</span></Typography> </div>
				</div>}
			</Slides>
			<Slides className="d-md-none mt-2 "
				autoplay
				infinite >
				<div className="d-flex w-100 gap-2 align-items-center justify-content-start">
					<img src="/assets/icons/yellow-heart.svg"
						width={24}
						height={24} /><div><Typography semanticTag="div"
						variant="bodySm"
						style={{fontSize: "14px", lineHeight: "20px"}}><span style={{fontWeight: 700}}>{arrayOfWords.slice(0, wordsToMakeBold).map(word => word + " ")}</span> {arrayOfWords.slice(wordsToMakeBold, arrayOfWords.length).map(word => word + " ")}</Typography> </div>
				</div>
				{isPDP ? null : <div className="d-flex w-100  gap-2 align-items-start justify-content-start">
					<div><Typography semanticTag="div"
						variant="bodySm"
						style={{fontSize: "14px", lineHeight: "20px", fontWeight: 700}}>{t("no-interest-label")} - Bike Now, Pay Later </Typography> </div>
					<div className="cursor-pointer"><img onClick={() => setModalOpen(true)}
						src={modalOpen ? "/assets/icons/info-active.svg" : "/assets/icons/info-transparent.svg"}
						height={20}
						width={20}
						className="cursor-pointer"/> </div>
				</div>}
			</Slides>
			<MobileModal modalOpen={modalOpen}
				handleClose={() => setModalOpen(!modalOpen)}>
				<Typography variant="bodyLgBlack"
					style={{fontSize: 24}}>{t("no-interest-label")} - Bike Now, Pay Later </Typography>
				<Typography variant="bodyLg"
					semanticTag="h4"
					className={" ml-5 mt-3"}>
					{t("Wir arbeiten mit dem Finanzierungspartner")} <a
						href="https://swissbilling.ch/">{t("swissbilling.ch")}</a> {t("zusammen, welcher es uns ermöglicht, dir die Ratenzahlung zinsfrei anzubieten.")}
					<br/><br/> {t("Als Gegenleistung erhält")} <a
						href="https://swissbilling.ch/">{t("swissbilling.ch")}</a> {t("von uns einen Anteil des Gewinns. Diesen Weg haben wir bewusst gewählt, um dir Extrakosten zu ersparen und jede*m den Weg zur E-Mobilität zu ermöglichen. Du hast weitere Fragen dazu? Wir geben auch telefonisch gerne darüber Auskunft!")}
				</Typography>
			</MobileModal>

		</div>
	)
}

export default SocialProof

import React from "react"

const Loading = () => {
	return (
		<div className={"container"}>
			<div className={"row justify-content-center align-items-center"}
				style={{height: "100%"}}>
				<div className={"col-auto"}>
					<div className="spinner-border text-light"
						role="status"/>
				</div>
			</div>
		</div>
	)
}

export default Loading

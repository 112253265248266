import React, {useEffect, useState} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import Link from "next/link"

const consent = "cookie_consent"

const consentStatus = {
	accepted: "accepted",
	pending: "pending"
}

const CookieConsent: React.FC = () => {
	const {t} = useTranslation()
	const [accepted, setAccepted] = useState<string | null>(consentStatus.accepted)

	useEffect(() => {
		setAccepted(window === undefined ? consentStatus.pending : localStorage.getItem(consent))
	}, [])

	useEffect(() => {
		if (accepted !== null && accepted === consentStatus.accepted) {
			localStorage.setItem(consent, consentStatus.accepted)
		}
	}, [accepted])

	return (accepted ? null :
		<div className={"position-fixed start-0 bottom-0 end-0 bg-white shadow border-top"}
			style={{zIndex: 9999999}}>
			<div className={"container mt-0 mt-md-1"}>
				<div className={"row"}>
					<div className={"col-12"}>
						<div className={"px-1 py-2 pb-md-2"}>
							<Typography variant="bodyXSm"
								semanticTag="div"
								className={"col-12"}>
								{t("Unsere Website verwendet Cookies, damit wir dir das bestmögliche Erlebnis bieten können.")}
							</Typography>
							<div className={"row align-items-center mt-2"}>
								<div className={"col-6 col-md-auto"}>
									<button className={"btn btn-primary btn-sm px-2 py-1"}
										onClick={() => {
											setAccepted(consentStatus.accepted)
										}}>
										{t("Akzeptieren")}
									</button>
								</div>
								<div className={"col-6 col-md-auto"}>
									<Link href={t("/datenschutz")}
										as={t("/datenschutz")}>
										<a className="text-dark border-bottom border-primary border-4 text-decoration-none">
											<Typography variant="bodyXSm"
												semanticTag="span">{t("mehr erfahren")}</Typography>
										</a>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CookieConsent

import React, {useContext, useEffect, useMemo, useRef, useState} from "react"
import Notification from "../components/reusable/Notification"
import {Whatsapp} from "react-bootstrap-icons"
import Typography from "../components/reusable/Typography/Typography"
import LayoutContext from "../context/LayoutContext"
import NotificationsContext from "../context/NotificationsContext"
import {BikeType, LiveNotification, NotificationTypes, premiumBrandWidgetsType} from "../utility/Types"
import {deleteCookie, getCookie, setCookie} from "cookies-next"
import getMainCategory from "../components/filter/GetMainCategory"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import Link from "next/link"
import AnalyticsContext from "../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../config/googleAnalytics/events"
import {useRouter} from "next/router"
import DataContext from "../context/DataContext"
import {extractAllStaticTiles} from "../utility/Helper"
import {formatImageSrc} from "../strapi/strapiHelpers"

type Props = {
    children: React.ReactNode
}

const getMostRedundantInArrayOfThree = array => {
	return array[0] === array[1] ? array[0] : array[1] === array[2] ? array[1] : array[0] === array[2] ? array[0] : null
}

function NotificationContextProvider({children}: Props) {
	const [visitedPdpsCount, setVisitedPdpsCount] = useState(null)
	const {isMobile} = useContext(LayoutContext)
	const {reactGA} = useContext(AnalyticsContext)
	// eslint-disable-next-line
	const notificationIntervalRef = useRef<NodeJS.Timeout | null>(null);
	const router = useRouter()
	const {pageProps} = useContext(DataContext)
	const premiumBrandWidgets = pageProps?.strapiContent?.data?.premium_brand_widgets
	const liveNotifications:LiveNotification[] | null = useMemo(() => {
		return extractAllStaticTiles(premiumBrandWidgets as premiumBrandWidgetsType, "liveNotifications") as LiveNotification[]
	}, [premiumBrandWidgets])

	const [currentBike, setCurrentBike] = useState<BikeType>(null)
	const [notificationBike, setNotificationBike] = useState<BikeType>(null)
	const [isNotificationShown, setIsNotificationShown] = useState<boolean>(false)
	const [addedBikeCategory, setAddedBikeCategory] = useState<string>(null)
	const {t} = useTranslation("common")
	const [activeNotification, setActiveNotification] = useState<NotificationTypes>(null)
	const [activeLiveNotification, setActiveLiveNotification] = useState<LiveNotification>(null)

	const resetNotification = (isPdpVisitsNotification = false) => {
		setActiveNotification(() => null)
		setActiveLiveNotification(() => null)
		if (isPdpVisitsNotification) {
			setVisitedPdpsCount(0)
			deleteCookie("visitedPdpsCount")
			deleteCookie("visitedMainCategories")
		}
	}

	const incrementPdpViews = bike => {
		setCurrentBike(() => bike)
		const visitedPersistedCount = getCookie("visitedPdpsCount") || 1
		const visitedCategoriesCookie = getCookie("visitedMainCategories") || "[]"

		const visitedMainCategories = typeof visitedCategoriesCookie === "string" &&
			visitedCategoriesCookie.startsWith("[") ?
			JSON.parse(visitedCategoriesCookie) : []

		setVisitedPdpsCount(() => Number(visitedPersistedCount))

		if (visitedPdpsCount === null || Number(visitedPersistedCount) < 3) {
			setCookie("visitedPdpsCount", Number(visitedPersistedCount) + 1, {maxAge: 60 * 60})
			const cookie = [...visitedMainCategories, getMainCategory(bike?.categories)?.name]
			setCookie("visitedMainCategories", JSON.stringify(cookie))
		} else if (Number(visitedPersistedCount) === 3) {
			const mostRecurrentCat = getMostRedundantInArrayOfThree(visitedMainCategories)
			if (mostRecurrentCat) {
				setNotificationBike(() => currentBike?.similarBikes
					?.find(bike => bike?.categories
						?.some(cat => cat?.slug === mostRecurrentCat)) || currentBike?.similarBikes[0]
				)
				setAddedBikeCategory(() => mostRecurrentCat)
			}

			showNotification("bike-recommendation-notification", 3)
			setVisitedPdpsCount(() => 0)
			deleteCookie("visitedPdpsCount")
		} else {
			resetNotification(true)
		}
	}

	useEffect(() => {
		if (!liveNotifications?.length || typeof window === "undefined") {
			return
		}

		const sessionItems = localStorage.getItem("displayedNotifications") || "[]"
		const displayedNotifications = JSON.parse(sessionItems)
		console.log(displayedNotifications)
		liveNotifications.reverse().forEach(liveNotification => {
			const links = liveNotification?.displayOnPages?.map(link => link.link)
			if (links?.includes(router?.pathname) && !displayedNotifications?.includes(liveNotification.id)) {
				showNotification("dynamic-live-notification", 15 * (Number(liveNotification.showUpDelayInMinutes) || 10))
				setActiveLiveNotification(() => liveNotification)
				setTimeout(() => {
					localStorage.setItem("displayedNotifications", JSON.stringify([...displayedNotifications, liveNotification.id]))
				}, 15 * (Number(liveNotification.showUpDelayInMinutes) || 10) * 1000)
			}
		})
	}, [router.pathname, activeLiveNotification])

	useEffect(() => {
		if (currentBike?.slug) {
			setTimeout(() => setIsNotificationShown(() => false), 2000)
		}

		if (!router.pathname.includes("/[bike]")) {
			clearInterval(notificationIntervalRef.current)
		}

		return () => {
			if (notificationIntervalRef.current) {
				clearInterval(notificationIntervalRef.current)
				notificationIntervalRef.current = null
			}
		}
	}, [currentBike?.slug, router.pathname])

	const displayNotificationAfterUsing = (
		delay: number,
		activeNotification:NotificationTypes,
		conditionToKeepCounting: boolean
	) => {
		let secondsPassed = 0

		if (notificationIntervalRef.current) {
			clearInterval(notificationIntervalRef.current)
		}

		if (conditionToKeepCounting && !isNotificationShown) {
			notificationIntervalRef.current = setInterval(() => {
				if (secondsPassed < delay) {
					secondsPassed++
				} else {
					if (!isNotificationShown) {
						showNotification(activeNotification, 1)
						setIsNotificationShown(() => true)
						setActiveNotification(() => null)
					}

					clearInterval(notificationIntervalRef.current!)
					notificationIntervalRef.current = null
				}
			}, 1000)
		}
	}

	const notificationToasts = {
		"bike-recommendation-notification": notificationBike?.images?.length ? <Notification resetNotification={() => resetNotification(true)}
			mobile={isMobile}>
			<Link onClick={() => {
				reactGA?.event({
					category: gaCategories.pdp,
					action: gaEvents.userClickedOnBikeFromNotification,
					label: gaEvents.userClickedOnBikeFromNotification,
					nonInteraction: false
				})
			}}
			href={`/${t("produkt")}/${notificationBike?.slug}`}>
				<div
					className="d-flex cursor-pointer align-items-start justify-content-start gap-2">
					<Image
						src={notificationBike?.images[0].src}
						width={75}
						height={75}
						alt={notificationBike?.name} />
					<div className="d-flex flex-1 align-items-start justify-content-start flex-column"
						style={{gap: "4px"}}>
						<Typography variant="bodySm"
							style={{fontSize: "14px", lineHeight: "20px"}}>
							{addedBikeCategory ?
								<>{t("this-label")}
									<b style={{color: "#956306", marginRight: ".5rem"}}>{" " + addedBikeCategory}</b>
									{t("bike-is-in-users-cart-label")
										.replace("x", JSON.stringify(Math.round(Math.random() * 10) + 1))} </> :
								<>{t("this-bike-label")}{" "}
									{t("bike-is-in-users-cart-label")
										.replace("x", JSON.stringify(Math.round(Math.random() * 10) + 1))} </>}
						</Typography>
					</div>
				</div>
			</Link>
		</Notification> : null,
		"dynamic-live-notification": activeLiveNotification?.id ? <Notification resetNotification={() => resetNotification(true)}
			mobile={isMobile}>
			<Link onClick={() => {
				reactGA?.event({
					category: gaCategories.pdp,
					action: gaEvents.userClickedOnBikeFromNotification,
					label: gaEvents.userClickedOnBikeFromNotification,
					nonInteraction: false
				})
			}}
			href={activeLiveNotification?.link}>
				<div
					className="d-flex cursor-pointer align-items-start justify-content-start gap-2">
					{activeLiveNotification && activeLiveNotification?.image?.data?.attributes?.url ? <img
						src={formatImageSrc(activeLiveNotification?.image?.data?.attributes?.url)}
						style={{maxWidth: "60px", height: "auto", objectFit: "cover", borderRadius: "4px"}}
						alt={activeLiveNotification?.title} /> : null}
					<div className="d-flex flex-1 align-items-start justify-content-evenly flex-column"
						style={{gap: "4px"}}>
						<Typography variant="bodySm"
							style={{fontSize: "14px", lineHeight: "20px"}}>
							{activeLiveNotification?.title}
						</Typography>
						{activeLiveNotification.hasCta ? <Typography variant="bodySmBold"
							style={{fontSize: "14px", lineHeight: "20px", color: activeLiveNotification.textColor ? activeLiveNotification.textColor : "#956306"}}>
							{activeLiveNotification.ctaText}
						</Typography> : null}
					</div>
				</div>
			</Link>
		</Notification> : null,
		"whatsapp-notification": <Notification resetNotification={resetNotification}
			mobile={isMobile}>
			<Link onClick={() => {
				reactGA?.event({
					category: gaCategories.pdp,
					action: gaEvents.userOpenedWhatsappFromNotification,
					label: gaEvents.userOpenedWhatsappFromNotification,
					nonInteraction: false
				})
			}}
			href="https://api.whatsapp.com/send?phone=41435051318">
				<div style={{cursor: "pointer"}}
					className="d-flex align-items-start justify-content-start gap-2">
					<Whatsapp />
					<div className="d-flex align-items-start justify-content-start flex-column"
						style={{gap: "4px"}}>
						<Typography variant="bodySm"
							style={{fontSize: "14px", lineHeight: "20px"}}>
							{t("user-asked-us-on-whatsapp")}{" "}
							<b style={{color: "#956306", marginRight: ".5rem"}}>{t("ask-us-too-label")}</b>
						</Typography>
					</div>
				</div>
			</Link>
		</Notification>,
		"credit-check-notification": <Notification resetNotification={resetNotification}
			mobile={isMobile}>
			<Link onClick={() => {
				reactGA?.event({
					category: gaCategories.pdp,
					action: gaEvents.userClickedOnCreditCheckFromNotification,
					label: gaEvents.userClickedOnCreditCheckFromNotification,
					nonInteraction: false
				})
			}}
			href="/creditcheck/2">
				<div style={{cursor: "pointer"}}
					className="d-flex align-items-start justify-content-start gap-2">
					<Image src="/assets/icons/credit-check-icon.svg"
						width={34}
						height={34} />
					<div className="d-flex align-items-start justify-content-start flex-column"
						style={{gap: "4px"}}>
						<Typography variant="bodySm"
							style={{fontSize: "14px", lineHeight: "20px"}}>
							{t("someone-did-a-credit-check-label")}
						</Typography>
						<Typography variant="bodySmBold"
							style={{fontSize: "14px", lineHeight: "20px", color: "#956306"}}>
							{t("try-yourself")}
						</Typography>
					</div>
				</div>
			</Link>
		</Notification>
	}

	const showNotification = (
		type:NotificationTypes,
		delayInSeconds = 1) => {
		setTimeout(() => {
			setActiveNotification(() => type)
		}, delayInSeconds * 1000)
	}

	return (
		<NotificationsContext.Provider
			value={{
				visitedPdpsCount,
				setVisitedPdpsCount,
				showNotification,
				incrementPdpViews,
				displayNotificationAfterUsing
			}} >
			<>
				{activeNotification && showNotification ?
					notificationToasts[activeNotification] :
					null}
				{children}
			</>
		</NotificationsContext.Provider>
	)
}

export default NotificationContextProvider

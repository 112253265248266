import React, {FC} from "react"
import {CheckLg} from "react-bootstrap-icons"
import Typography from "../../Typography/Typography"
import styles from "./Checkbox.module.scss"
import PropTypes from "prop-types"
import Image from "next/image"

type Props = {
	label: string
	className?: string
	isActive: boolean
	isLoading?: boolean
	disabled?: boolean
	id?: string
	onChange: () => void,
	type?: string,
	isForm?: boolean
	isBoxShape?: boolean
	labelOnly?: boolean
	isSwissMade?: boolean
}

const Checkbox: FC<Props> = ({
	isForm,
	onChange,
	label,
	className,
	isActive,
	isLoading,
	disabled,
	id,
	type = "checkbox",
	isBoxShape = false,
	labelOnly,
	isSwissMade
}) => {
	const handleChange = () => {
		if (!disabled) {
			onChange()
		}
	}

	return (
		isBoxShape ?
			<div onChange={handleChange}>
				<label className={`${disabled ? "pe-none" : "pointer"} d-flex align-items-center`}
					htmlFor={`checkbox-${id ? id + label : label}`}>
					<div className="cursor-pointer d-flex align-items-center justify-content-center me-2 filter-item-box"
						style={{
							height: 40,
							padding: "10px 16px",
							borderRadius: 16,
							backgroundColor: isActive ? "#FFE9C2" : "",
							border: isActive ? "1px solid #FFE9C2" : "1px solid #DEE2E6",
							color: isActive ? "#212529" : "#212529",
							columnGap: isSwissMade ? 8 : ""
						}}>
						{isSwissMade && <Image src="/assets/icons/swiss-flag-sm.svg"
							width={12}
							height={12}/>}
						<div className={className} >
							<div className={(isBoxShape || labelOnly) ? "d-none" : ""}
								style={{height: 20}}>
								<input disabled={disabled}
									defaultChecked={isActive}
									className={styles.hideVisually}
									id={`checkbox-${id ? id + label : label}`}
									type={type}/>
								<span
									className={`${isForm ? styles.checkbox_grey : styles.checkbox} ${isActive ? styles.checkboxActive : ""} border-grey ${disabled ? styles.disabled : ""}`}>
									{isActive && <CheckLg style={{color: "#fff"}}/>}
								</span>
							</div>
							<Typography variant={isActive ? "bodySmBold" : "bodySm"}
								style={{cursor: isBoxShape ? "pointer" : ""}}
								semanticTag="span">
								{label}
							</Typography>
							{isLoading && <span className="ms-2 spinner-border spinner-border-sm text-primary"
								role="status"
								aria-hidden="true"/>}
							<span>
							</span>
						</div>
					</div>
				</label>
			</div> :
			<div className={className}
				onChange={handleChange} >
				<label className={`${disabled ? "pe-none" : "pointer"} d-flex align-items-start`}
					htmlFor={`checkbox-${id ? id + label : label}`}>
					<div className={(isBoxShape || labelOnly) ? "d-none" : ""}
						style={{height: 20}}>
						<input disabled={disabled}
							defaultChecked={isActive}
							className={styles.hideVisually}
							id={`checkbox-${id ? id + label : label}`}
							type={type}/>
						<span
							className={`${isForm ? styles.checkbox_grey : styles.checkbox} ${isActive ? styles.checkboxActive : ""} border-grey ${disabled ? styles.disabled : ""}`}>
							{isActive && <CheckLg style={{color: "#fff"}}/>}
						</span>
					</div>
					<Typography variant={"bodySm"}
						style={{cursor: isBoxShape ? "pointer" : "", whiteSpace: "pre-wrap"}}
						semanticTag="span">
						{label}
					</Typography>
					{isLoading && <span className="ms-2 spinner-border spinner-border-sm text-primary"
						role="status"
						aria-hidden="true"/>}
					<span>
					</span>
				</label>
			</div>
	)
}

Checkbox.propTypes = {
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
	isActive: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	onChange: PropTypes.func.isRequired
}
export default Checkbox

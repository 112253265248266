import React from "react"
import Image from "next/image"
import Typography from "./Typography/Typography"
import Button from "./components/Button"

type Props = {
  t?: any,
  white?: boolean
}

const BikeSuggest: React.FC<Props> = ({t, white}) => {
	return (
		<div className={`position-relative ${white ? "bg-white" : "bg-grey"} `}>
			<div className={"w-100 container row justify-content-between align-items-center g-0 mx-auto"}>

				<div className={"col-12 col-md-6 d-block d-md-none pt-5 mt-2"}>
					{/* image-suggest-bike-new.png */}
					<div className={"position-relative vh-40 "}>
						<Image src="/assets/images/image-suggest-bike-new.png"
							alt="bike"
							layout="fill"
							objectFit="contain"
						/>
					</div>
				</div>

				<div className={"col-12 col-md-6"}>
					<div className={"mb-3 my-md-5 pb-5 py-md-5 px-3"}>
						<Typography variant="heading2"
							semanticTag="h2"
							className={"text-start mb-3"}>{t("bike-suggest-title")}</Typography>
						<Typography variant="bodyLg"
							semanticTag="p">{t("bike-suggest-description")}</Typography>

						<div className={""}>
							<div className={"mb-3"}>
								<Button className={"w-auto d-block d-md-inline-block"}
									label={t("write-us-message")}
									href={"https://api.whatsapp.com/send?phone=41435051318"}
									hoverColor="#212529"
									textColor="#fff"
									color="#fff"
									bgColor="#212529"
									fontSizeSmall
								/>
							</div>
							<div className={""}>
								<Button className={"w-auto d-block d-md-inline-block"}
									label={t("recieve-recomendations")}
									href={"https://skh5fu1vwwv.typeform.com/to/dxx1P3mM"}
									hoverColor="#212529"
									color="#fff"
									outline
									fontSizeSmall
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={"col-12 col-md-6 d-none d-md-block"}>
					{/* image-suggest-bike-new.png */}
					<div className={"position-relative vh-40 "}>
						<Image src="/assets/images/image-suggest-bike-new.png"
							alt="bike"
							layout="fill"
							objectFit="contain"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BikeSuggest

import Image from "next/image"
import React, {useContext, useEffect, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import DataContext from "../../context/DataContext"
import useGetRecentlyViewedBikes from "../../hooks/useGetRecentlyViewedBikes"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import BikeContent from "../reusable/components/BikeContent/BikeContent"
import useCache from "../../hooks/useCache"
import {fetchGet} from "../../firebaseAdmin/fetchGet"
import {useRouter} from "next/router"

type Props = {
    inSearchContent?: boolean
    alignSectionInCenter?: boolean
}

const BikeExplorerTabs:React.FC<Props> = ({inSearchContent, alignSectionInCenter = true}) => {
	const {customBikeRecommendations, pageProps} = useContext(DataContext)

	const {t} = useTranslation()
	const {recentlyViewedBikes} = useGetRecentlyViewedBikes()
	const [activeTab, setActiveTab] = useState<string>(
		customBikeRecommendations?.length ? "recommendations" : "recently-viewed"
	)
	const {reactGA} = useContext(AnalyticsContext)
	if (recentlyViewedBikes?.length) {
		return (
			<div style={{maxWidth: 1235, width: "100%"}}
				className={alignSectionInCenter ? "mx-auto" : ""}>
				<div className="d-flex mt-2 mt-md-0 align-items-center
                                justify-content-start bike-explorer-tabs pe-3 pe-md-0 hide-scrollbar">
					{customBikeRecommendations?.length > 0 && (
						<div
							onClick={() => {
								setActiveTab(() => "recommendations")
								reactGA?.event({
									category: gaCategories.plp,
									action: gaEvents.recommendedForYouTabClick,
									label: gaEvents.recommendedForYouTabClick,
									nonInteraction: false
								})
							}}
							className={`tab ${activeTab === "recommendations" ?
								"recommendations-active" :
								"recommendations-inactive"}
                            cursor-pointer px-12 py-2`}
							style={{width: "auto", flexShrink: 0}}
						>
							<div className="tab-content d-flex gap-2 align-items-center">
								<Image src="/assets/icons/sparkle.svg"
									height={20}
									width={20} />
								<Typography variant="bodySmBold"
									semanticTag="span">
									{t("recommended-for-you")}
								</Typography>
							</div>
						</div>
					)}
					<div
						onClick={() => {
							setActiveTab(() => "recently-viewed")
							reactGA?.event({
								category: gaCategories.plp,
								action: gaEvents.recentlyViewedTabClick,
								label: gaEvents.recentlyViewedTabClick,
								nonInteraction: false
							})
						}}
						className={`tab ${activeTab === "recently-viewed" ? "recently-viewed-active" : "recently-viewed-inactive"} 
                        cursor-pointer px-12 py-2 `}
						style={{width: "auto", flexShrink: 0}}>
						<div className="tab-content d-flex gap-2 align-items-center">
							<Image src="/assets/icons/rollback.svg"
								height={16}
								width={16} />
							<Typography variant="bodySmBold">
								{t("recently-viewed-products-title")}
							</Typography>
						</div>
					</div>
				</div>

				<div style={{marginTop: "8px"}}
					className={` ${inSearchContent && "bike-explorer-tabs-content"}`}>
					{activeTab === "recommendations" && (
						<BikeContent
							bikes={customBikeRecommendations}
							hasBorderBottom={!inSearchContent}
							inSearchContent={inSearchContent}
						/>
					)}
					{activeTab === "recently-viewed" && (
						<BikeContent
							bikes={recentlyViewedBikes}
							hasBorderBottom={!inSearchContent}
							inSearchContent={inSearchContent}
						/>
					)}
				</div>
			</div>
		)
	}

	return <></>
}

export default BikeExplorerTabs

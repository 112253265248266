import React, {useContext, useEffect, useState} from "react"
import Slider from "../../slider/Slider"
import CheckoutContext from "../../../context/CheckoutContext"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import AnalyticsContext from "../../../context/AnalyticsContext"
import RegularDurationLabels from "./RegularDurationLabels"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import Checkbox from "../../reusable/components/Checkbox/Checkbox"
import NotificationsContext from "../../../context/NotificationsContext"
import OrganizationContext from "../../../context/OrganizationContext"
import {strapiPlanLengthsMap} from "../../../constants"

type Props = {
    isSecondHand?: boolean;
    isMobile?: boolean,
    hasHeader?: boolean,
    showByDefault?: boolean,
    payOneTime?: boolean,
	hidePayOneTimeLabel?: boolean
	hideOneTimePaymentCheckBox?: boolean
}

enum PathNameValue {
  produkt = "produkt",
  checkout = "checkout"
}

const Plan: React.FC<Props> = ({hidePayOneTimeLabel = false, isSecondHand = false, isMobile, showByDefault = true, payOneTime, hideOneTimePaymentCheckBox = true}) => {
	const {data: checkoutData, setData} = useContext(CheckoutContext)
	const {organizationData, organizationSlug} = useContext(OrganizationContext)
	const {t} = useTranslation(["common", "filters"])
	const setCheckoutData = setData
	const router = useRouter()
	const {displayNotificationAfterUsing} = useContext(NotificationsContext)
	const {pathname} = router
	const isProductPage = pathname.includes(PathNameValue.produkt)
	const isCheckoutPage = pathname.includes(PathNameValue.checkout)
	const durations = isSecondHand ? ["pay-one-time", 12, 24] : process.env.NEXT_PUBLIC_MAX_PLAN_LENGTH === "36" ? ["pay-one-time", 12, 24, 36] : ["pay-one-time", 12, 24, 36, 48]
	const durationInMonth = organizationSlug && organizationData?.maxPlanLength ? durations?.filter(duration => typeof duration === "number" ? duration <= strapiPlanLengthsMap[organizationData?.maxPlanLength] : duration === "pay-one-time") : durations
	const [isOpen, setIsOpen] = useState(showByDefault)
	const getMinSliderValue = () => {
		return isNaN(Number(durationInMonth[0])) ? isNaN(Number(durationInMonth[1])) ? 12 : Number(durationInMonth[1]) : Number(durationInMonth[0])
	}

	const {reactGA} = useContext(AnalyticsContext)
	useEffect(() => {
		if (checkoutData && (!payOneTime)) {
			if (!isSecondHand) {
				if (checkoutData?.planLength < 12) {
					setCheckoutData(prevData => {
						return ({
							...prevData,
							planLength: organizationSlug && organizationData?.maxPlanLength ? Number(organizationData?.maxPlanLength) : 48
						})
					})
				}
			}
		}

		if (isSecondHand && (!payOneTime)) {
			if (checkoutData?.planLength > 24 || !checkoutData || !checkoutData?.planLength) {
				setCheckoutData(prevData => {
					return ({
						...prevData,
						planLength: 24
					})
				})
			}
		}
	}, [checkoutData, isSecondHand])

	const handleGAaction = (value:any) => {
		reactGA?.event({
			category: gaCategories.planLength,
			action: isSecondHand ? (value === 3 ? (isProductPage ? gaEvents.planLength3 : isCheckoutPage ? gaEvents.setPlanLengthCart : gaEvents.planLengthProductListingsPage3) :
				value === 6 ? (isProductPage ? gaEvents.planLength6 : isCheckoutPage ? gaEvents.setPlanLengthCart : gaEvents.planLengthProductListingsPage6) :
					value === 9 ? (isProductPage ? gaEvents.planLength9 : isCheckoutPage ? gaEvents.setPlanLengthCart : gaEvents.planLengthProductListingsPage9) :
						(isProductPage ? gaEvents.planLength12ForUsed : isCheckoutPage ? gaEvents.setPlanLengthCart : gaEvents.planLengthProductListingsPage12ForUsed)) :
				(value === 12 ? (isProductPage ? gaEvents.planLength12 : isCheckoutPage ? gaEvents.planLengthCart12 : gaEvents.planLengthProductListingsPage12) :
					value === 24 ? (isProductPage ? gaEvents.planLength24 : isCheckoutPage ? gaEvents.planLengthCart24 : gaEvents.planLengthProductListingsPage24) :
						value === 36 ? (isProductPage ? gaEvents.planLength36 : isCheckoutPage ? gaEvents.planLengthCart36 : gaEvents.planLengthProductListingsPage36) :
							(isProductPage ? gaEvents.planLength48 : isCheckoutPage ? gaEvents.planLengthCart48 : gaEvents.planLengthProductListingsPage48)),
			label: `Set Plan Length ${value}${isProductPage ? "Product Page" : isCheckoutPage ? "Checkout Page" : "Listing Page"}`,
			value,
			nonInteraction:
              false
		})
	}

	return (
		<div className={"mb-md-0 pb-3 pb-md-0"}>
			<RegularDurationLabels
				hidePayOneTimeLabel={hidePayOneTimeLabel}
				checkoutData={checkoutData}
				setCheckoutData={setCheckoutData}
				t={t}
				isMobile={isMobile}
				isOpen={isOpen}
				payOneTime={payOneTime}
				durationInMonth={durationInMonth}
			/>
			<div className={isMobile ? `accordion-collapse collapse ${isOpen ? "show" : null}` : ""}>
				<Slider defaultValue={isSecondHand ? checkoutData?.planLength || 24 : checkoutData?.planLength || 36}
					step={12}
					disabled={checkoutData?.planLength === 1 && hidePayOneTimeLabel}
					min={payOneTime && !hidePayOneTimeLabel ? 0 : getMinSliderValue()}
					max={isSecondHand ? 24 : organizationSlug && organizationData?.maxPlanLength ? strapiPlanLengthsMap[organizationData?.maxPlanLength] : (process.env.NEXT_PUBLIC_MAX_PLAN_LENGTH ? parseInt(process.env.NEXT_PUBLIC_MAX_PLAN_LENGTH, 10) : 48)}
					onChange={value => {
						handleGAaction(value)
						setCheckoutData(prevData => {
							return ({
								...prevData,
								planLength: value === 0 ? 1 : value
							})
						})
					}}
					onAfterChange={value => {
						displayNotificationAfterUsing(58, "credit-check-notification", true)
						setCheckoutData(prevData => {
							return ({
								...prevData,
								planLength: value === 0 ? 1 : value
							})
						})
					}}/>
			</div>
			{hideOneTimePaymentCheckBox ? null :
				<label className="mt-3"
					data-cyperess="cp-slider" >
					<div className="d-none cypress-plan-length-class"
						data-cypress-checked={`${checkoutData?.planLength}`} >{checkoutData?.planLength}</div>
					<Checkbox label={t("pay-one-time")}
						isActive={checkoutData?.planLength === 1}
						onChange={() => {
							reactGA?.event({
								category: gaCategories.homePage,
								action: gaEvents.oneTimePaymentCheckboxChecked,
								label: `${gaEvents.oneTimePaymentCheckboxChecked}`,
								nonInteraction: false
							})
							setCheckoutData(prevData => {
								return ({
									...prevData,
									planLength: prevData.planLength === 1 ? 12 : 1
								})
							})
						}}/></label>}
		</div>

	)
}

Plan.propTypes = {}

export default Plan

import React from "react"
import Typography from "../Typography/Typography"
import Button from "../components/Button"
import {colors} from "../../../colors/Colors"
import {Stars} from "react-bootstrap-icons"
import {useTranslation} from "next-i18next"

function AiSuggestionLoadingSkeleton({className}: { className?: string }) {
	const {t} = useTranslation()
	return (
		<div className={`carousel-ai-card mt-2 p-3 mb-3 col-12 slide-in-right d-flex align-items-start justify-content-start flex-column gap-3 ${className}`} >
			<div style={{border: "1px solid #DEE2E6", width: "100%", borderRadius: "16px"}}
				className=" p-5 suggestion-skeleton-loader" ></div>
			<div style={{width: "100%"}}
				className="d-flex align-items-start justify-content-start gap-2 flex-column">
				<div style={{width: "100%"}}
					className="d-flex align-items-center justify-content-start p-2 suggestion-skeleton-loader gap-1"><Stars />
				</div>
				<Typography style={{width: "100%"}}
					variant="bodySm"
					className="col-12 p-4 suggestion-skeleton-loader"></Typography>
			</div>
			<Button label={t("view-bike") }
				outline
				borderColor="#DEE2E6"
				bgColor={colors.primary}
				fontSizeSmall
				className="col-12 suggestion-skeleton-loader"/></div>
	)
}

export default AiSuggestionLoadingSkeleton

import {filterUrl} from "../components/filter/FilterUrl"

export const tabItems = [
	{
		href: process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? "instance.mybikeplan.ch/" : "https://www.mybikeplan.ch/",
		iconSrc: "/assets/icons/mbp.svg",
		mobileIconSrc: "/assets/icons/bike-nobg.svg",
		title: "MyBikePlan",
		primaryColor: "#FEC04D",
		bgColor: "#f9f3ec",
		checkmarkSrc: "/assets/icons/checkmark.svg",
		// When we provide a Null megaTitle it means that we will now show a dropDown when hovering this element
		megaTitle: null
	}
]

// Comment

export const content = {
	sbb: {
		companyName: "SBB",
		tabIcon: "/assets/icons/sbb_logo_red_bg.svg",
		companyIcon: "/assets/icons/SBB-logo.svg",
		loginPopup: {
			logo: "/assets/icons/sbb_logo_red_bg.svg",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ed0200",
			textColor: "white",
			logo: "/assets/icons/sbb_logo.svg",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, SBB-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_employee",
			text: "instances_home_text"
		},
		email: ["chemoil.ch", "elvetino.ch", "sbb-deutschland.de", "sbbcargo.com", "transsicura.ch", "sbb.ch", "thurbo.ch", "railaway.ch", "sbbcargoint.com", "regionalps.ch", "login.org", "zentralbahn.ch", "swisstravelsystem.com", "tilo.ch"]
	},
	tcs: {
		companyName: "TCS",
		tabIcon: "/assets/icons/tcs-logo.png",
		companyIcon: "/assets/icons/tcs-logo.png",
		loginPopup: {
			logo: "/assets/icons/tcs-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ffeb02",
			textColor: "dark",
			logo: "/assets/icons/tcs-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, TCS-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_member",
			text: "instances_home_text"
		},
		anyEmailAllowed: true
	},
	axpo: {
		companyName: "AXPO",
		tabIcon: "/assets/icons/axpo-logo.png",
		companyIcon: "/assets/icons/axpo-logo.png",
		loginPopup: {
			logo: "/assets/icons/axpo-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/axpo-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, AXPO-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_employee",
			text: "instances_home_text"
		},
		email: ["axpo.ch"]
	},
	grape: {
		companyName: "grape",
		tabIcon: "/assets/icons/grape-logo.png",
		companyIcon: "/assets/icons/grape-logo.png",
		loginPopup: {
			logo: "/assets/icons/grape-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#7b1bff",
			textColor: "dark",
			logo: "/assets/icons/grape-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, grape-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_employee",
			text: "instances_home_text"
		},
		anyEmailAllowed: true
	},
	zurich: {
		companyName: "zurich",
		tabIcon: "/assets/icons/zurich-logo.svg",
		companyIcon: "/assets/icons/zurich-logo.svg",
		loginPopup: {
			logo: "/assets/icons/zurich-logo.svg",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/zurich-logo.svg",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, Zurich-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_employee",
			text: "instances_home_text"
		},
		email: ["zurich.ch"]
	},
	uhbs: {
		companyName: "Universitätsspital Basel",
		tabIcon: "/assets/icons/uhbs-logo.svg",
		companyIcon: "/assets/icons/uhbs-logo.svg",
		loginPopup: {
			logo: "/assets/icons/uhbs-logo.svg",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/uhbs-logo.svg",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, Universitätsspital Basel-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_employee",
			text: "instances_home_text"
		},
		email: ["uhbs.ch"]
	},
	gleason: {
		companyName: "Gleason",
		tabIcon: "/assets/icons/gleason-logo.png",
		companyIcon: "/assets/icons/gleason-logo.png",
		loginPopup: {
			logo: "/assets/icons/gleason-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/gleason-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, Gleason-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_employee",
			text: "instances_home_text"
		},
		email: ["gleason.ch"]
	},
	valora: {
		companyName: "Valora",
		tabIcon: "/assets/icons/valora-logo.png",
		companyIcon: "/assets/icons/valora-logo.png",
		loginPopup: {
			logo: "/assets/icons/valora-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/valora-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, Valora-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_employee",
			text: "instances_home_text"
		},
		email: ["valora.ch", "valora.com", "valora.net", "bob.ch"]
	},
	css: {
		companyName: "CSS",
		tabIcon: "/assets/icons/css-logo.png",
		companyIcon: "/assets/icons/css-logo.png",
		loginPopup: {
			logo: "/assets/icons/css-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/css-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, CSS-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_employee",
			text: "instances_home_text"
		},
		anyEmailAllowed: true
	},
	guudies: {
		companyName: "guudies",
		tabIcon: "/assets/icons/guudies-logo.png",
		companyIcon: "/assets/icons/guudies-logo.png",
		loginPopup: {
			logo: "/assets/icons/guudies-logo.png",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		listingItem: {
			text: "instances_exclusive"
		},
		banner: {
			bgColor: "#ebebeb",
			textColor: "dark",
			logo: "/assets/icons/guudies-logo.png",
			header: "for_instances_employees",
			subHeader: "for_instances_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, guudies-Mitarbeiter/in!",
			buttonCTA: "E-Bikes entdecken!",
			buttonLink: t => filterUrl([], null, null)
		},
		home: {
			subTitle: "welcome_instances_member",
			text: "instances_home_text"
		},
		anyEmailAllowed: true
	}
}

export const otherInstancesCategoryTitles = [
	"All furniture", "Alle Möbel"
]

export const getMySofaPlanUrl = (organisation:string, category: string = "-", brand: string = "-", quickFilter: string = "relevanz") => {
	let baseUrl
	switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
		case "development":
		case "preview":
		case "local":
			baseUrl = "https://staging.mysofaplan.ch"
			break
		case "production":
			if (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE) {
				baseUrl = `https://${organisation}.mysofaplan.ch/`
				break
			}

			baseUrl = "https://www.mysofaplan.ch"
			break
		default:
			baseUrl = "https://www.mysofaplan.ch"
			break
	}

	return `${baseUrl}/alle-produkte-schweiz/kategorie/${category}/marke/${brand}/farbe/-/preisbereich/-/verfugbar/-/suchen/-/sortieren/preis/${quickFilter}/-`
}

export const authStateChangedCallback = async (auth, user) => {
	const {getRedirectResult, signInAnonymously, signInWithCredential, GoogleAuthProvider} = await import("@firebase/auth")
	try {
		const result = await getRedirectResult(auth)
		// Handle redirect after sign-in
		if (result) {
			const credential = GoogleAuthProvider.credentialFromResult(result)
			if (credential) {
				// Accounts successfully linked.
				const {user} = result
				console.log("Firebase | Accounts successfully linked", user)
			}
		} else if (!user) {
			try {
				await signInAnonymously(auth)
			} catch (error) {
				const errorCode = error.code
				const errorMessage = error.message
				console.error("Firebase | Code / Error:", errorCode, errorMessage)
			}
		}
	} catch (error) {
		console.log("Firebase | Account already exists, logging in using credential from error")
		try {
			await signInWithCredential(auth, GoogleAuthProvider.credentialFromError(error))
		} catch (error) {
			console.error("Firebase", error)
		}
	}
}

import React, {useContext} from "react"
import {Form, Formik} from "formik"
import * as yup from "yup"
import FormInput from "../reusable/FormInput/FormInput"
import {useTranslation} from "next-i18next"
import FormTextArea from "./fields/FormTextArea"
import Button from "../reusable/components/Button"
import Typography from "../reusable/Typography/Typography"
import FirebaseContext from "../../context/FirebaseContext"
import {useRouter} from "next/router"

interface FormValues {
  firstName: string
  lastName: string
  email: string
  message: string
  // Term_condition: string
}

const FormSchema = yup.object().shape({
	firstName: yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	lastName: yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	email: yup.string()
		.email("Invalid email")
		.required("Required"),
	message: yup.string()
		.required("Required")
	// Term_condition: yup.string()
	// 	.required("Required")

})

interface Props {
  name: string
  sku: string,
  isSubmitError: boolean
  isSubmitSuccess: boolean
  closeModal: () => void
  setIsSubmitError: (arg: any) => void
  setIsSubmitSuccess: (arg: any) => void
}

const CustomerQuestionForm: React.FC<Props> = ({
	name,
	sku,
	isSubmitError,
	isSubmitSuccess,
	setIsSubmitError,
	setIsSubmitSuccess,
	closeModal
}) => {
	const bikeName = name
	const router = useRouter()
	const {t} = useTranslation("common")
	const {postProductQuestion} = useContext(FirebaseContext)
	const slug = router?.query?.bike?.toString()

	const initialValues: FormValues = {
		firstName: "",
		lastName: "",
		email: "",
		message: ""
		// Term_condition: ""
	}
	return (
		<Formik
			initialValues={initialValues}
			validateOnMount={true}
			validationSchema={FormSchema}
			onSubmit={async (values: any) => {
				const {firstName, lastName, message, email} = values
				const postQuestionResponse = await postProductQuestion(slug, bikeName, sku, firstName, lastName, message, email)
				if (postQuestionResponse.status === 201) {
					setIsSubmitSuccess(!isSubmitSuccess)
				} else {
					setIsSubmitError(!isSubmitError)
				}
			}}
		>
			{({handleSubmit, errors, isValid, isSubmitting, touched}) => {
				return (
					<>
						{
							isSubmitError ?
								<div className="row justify-content-center align-items-center">
									<div className="col-12 text-center">
										<Typography semanticTag="span"
											variant={"bodyLg"}>{t("customer-question-form-error")}</Typography>
									</div>
								</div> :
								isSubmitSuccess ?
									<div className="row justify-content-center align-items-center">
										<div className="col-12 text-center">
											<Typography semanticTag="span"
												variant={"bodyLg"}>{t("customer-question-form-success")}</Typography>
										</div>
									</div> :
									<Form onSubmit={handleSubmit}>
										<div className="row">
											<div className="col-12 mb-4 pb-2">

												<div className={"row justify-content-between align-items-start"}>
													<Typography semanticTag="h2"
														className="col-10"
														variant="bodyLgBlack"
														style={{fontSize: 28}}>{t("customer-question-form-title")}</Typography>
													<div className={"col-auto"}>
														<button type={"button"}
															className={"btn py-0 px-0 d-md-none"}
															onClick={() => {
																closeModal()
																setIsSubmitError(false)
																setIsSubmitSuccess(false)
															}}>
															<img src="/assets/icons/close-icon.svg"
																width="15.5"
																height="15.5"/>
														</button>
													</div>
												</div>

											</div>
											<div className="col-6 col-md-6">
												<FormInput className="pb-3"
													errors={errors}
													touched={touched}
													name="firstName"
													label={t("Vorname")}
													required
													errColor="dark"
													checkout
													isNewDesign/>
											</div>
											<div className="col-6 col-md-6">
												<FormInput className="pb-3"
													errors={errors}
													touched={touched}
													name="lastName"
													required
													errColor="dark"
													label={t("Nachname")}
													checkout
													isNewDesign/>
											</div>
										</div>
										<div className="row">
											<div className="col-12 mb-4">
												<FormInput className="pb-3"
													errors={errors}
													touched={touched}
													name="email"
													label={t("E-mail")}
													required
													errColor="dark"
													checkout
													isNewDesign/>
											</div>
											<div className="col-12">
												<FormTextArea label={t("Frage")}
													name={"message"}
													required/>
											</div>
											{/* <div className="col-12 mb-2">

												<label className="mt-2 container-radio">
													<Field type="checkbox" name="term_condition"></Field>
													<span className="radio radio-sqre "></span>
													<Typography className="text-dark" variant="bodySm" semanticTag="span" >{t("customer-question-form-conditions")}</Typography>
												</label>
											</div> */}
										</div>
										<div className="row">
											<div className="col-12">
												<Button
													className="w-100"
													label={t("customer-question-form-submit")}
													textColor={isValid ? "#fff" : "#212529"}
													bgColor={isValid ? "#212529" : "#212529"}
													color={isValid ? "#fff" : "#212529"}
													type={"submit"}
													disabled={!isValid}
													isLoading={isSubmitting}
													spinnerColor={"white"}
												/>
											</div>
										</div>
									</Form>
						}
					</>

				)
			}}
		</Formik>
	)
}

export default CustomerQuestionForm

type bikeCategory = {
    name: string,
    slug: string
}

export const categorySlugs = {
	trekking: "e-trekking",
	mountain: "e-mountain",
	urban: "e-urban",
	damen: "damen-e-bikes",
	herren: "herren-e-bikes",
	wave: "wave-e-bikes",
	trapez: "trapez-e-bikes",
	diamant: "diamant-e-bikes",
	twentyFive: "25-km-e-bikes",
	fortyFive: "45-km-e-bikes",
	occasion: "e-bike-on-sale",
	accessories: "zubehoer"
}

export const getBikeCategories = t => {
	return [
		{
			name: t("Alle e-bikes"),
			slug: "alle-e-bikes"
		},
		{
			name: t("E-Urban"),
			slug: "e-urban"
		},
		{
			name: t("E-Trekking"),
			slug: "e-trekking"
		},
		{
			name: t("E-Mountain"),
			slug: "e-mountain"
		}
	] as bikeCategory[]
}

import React, {useContext} from "react"
import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../../context/LayoutContext"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"
import Link from "next/link"

type Props = {
	data?: {text:{bold:string, reg:string}, icon: string, image: string, linkParams: any}[]
}

export const WhichBikeForYou = ({data}: Props) => {
	const {t} = useTranslation("PDPTiles")
	const tCommon = useTranslation("common").t
	const {isListView} = useContext(LayoutContext)

	return (
		<div className={"bg-white py-4 row"}>
			<div className={`col-12 d-flex align-items-center justify-content-center ${isListView ? "col-md-4" : ""}`}>
				<Typography className="p-y-4 px-5 text-center"
					variant="heading2"
					semanticTag="h3">
					{t("which-bike-for-you-title")}
				</Typography>
			</div>
			<div className={`col-12 d-flex flex-column gap-2 gap-md-4 position-relative ${isListView ? "col-md-8 flex-md-row" : ""}`}>
				{data.map(({text: {bold, reg}, icon, image, linkParams}) =>
					<Link key={bold}
						href={filterUrl(linkParams, null, null)}>
						<a className="text-decoration-none text-center rounded position-relative">
							<div style={{zIndex: 1, minWidth: 220, minHeight: 169}}
								className="position-relative text-white d-flex flex-column justify-content-center">
								<div className="d-md-none">
									<Image src={icon}
										height={50}
										width={50} />
								</div>
								<div className="d-none d-md-block">
									<Image src={icon}
										height={48}
										width={48} />
								</div>
								<div className="mt-4">
									<Typography className="me-1"
										semanticTag="span"
										variant="bodyLgBold">
										{t(bold)}
									</Typography>
									<Typography semanticTag="span"
										variant="bodyLg">
										{t(reg)}
									</Typography>
								</div>
							</div>
							<Image className="rounded"
								src={image}
								layout={"fill"}/>
						</a>
					</Link>
				)
				}
			</div>
		</div>
	)
}

WhichBikeForYou.defaultProps = {
	data: [
		{text: {bold: "which-bike-for-you-text-1-bold", reg: "which-bike-for-you-text-1-reg"}, icon: "/assets/icons/plpBanners/mountain.svg", image: "/assets/images/tiles/tile-mountain.png", linkParams: {[urlParams.categories]: "e-mountain"}},
		{text: {bold: "which-bike-for-you-text-2-bold", reg: "which-bike-for-you-text-2-reg"}, icon: "/assets/icons/plpBanners/urban.svg", image: "/assets/images/tiles/tile-urban.png", linkParams: {[urlParams.categories]: "e-urban"}},
		{text: {bold: "which-bike-for-you-text-3-bold", reg: "which-bike-for-you-text-3-reg"}, icon: "/assets/icons/plpBanners/trekking.svg", image: "/assets/images/tiles/tile-trekking.png", linkParams: {[urlParams.categories]: "e-trekking"}}
	]
}

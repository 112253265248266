import React, {ReactNode, useEffect, useState} from "react"
import {X} from "react-bootstrap-icons"
import {useTranslation} from "next-i18next"

type Props = {
    mobile?: boolean
    children: ReactNode
    resetNotification: ()=>void
}

function Notification({mobile, children, resetNotification}:Props) {
	const {t} = useTranslation()
	const [progress, setProgress] = useState(100)

	useEffect(() => {
		setProgress(() => 100)

		const interval = setInterval(() => {
			setProgress(prevProgress => {
				if (prevProgress > 0) {
					return prevProgress - 1
				}

				clearInterval(interval)
				setTimeout(() => resetNotification(), 300)
				return 0
			})
		}, 7000 / 100)

		return () => clearInterval(interval)
	}, [])

	const percentage = `${progress}%`

	return (
		<div style={{
			borderRadius: "4px", backgroundColor: "white",
			transform: mobile ? "translateX(50%)" : "translateX(-50%)",
			padding: "16px 16px 8px 16px", width: mobile ? "95dvw" : "320px",
			zIndex: 99999, top: mobile ? "10%" : "13%", border: "1px solid #DEE2E6",
			rowGap: "10px", right: mobile ? "50%" : "-7rem", position: "fixed"
		}}
		className="fade-in  d-flex flex-column align-items-start justify-content-start">
			<div className="d-flex  col-12 text-black justify-content-start align-items-start gap-2">
				{children}
				<X onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					resetNotification()
				}}
				style={{position: "absolute", top: "4px", right: "3px"}}
				size={24}
				color={"#cccccc"} />
			</div>
			<div style={{height: "4px", width: "111.15%", marginBottom: "-8px", marginLeft: "-16px"}}
				className="progress bg-white">
				<div className="progress-bar bg-black "
					role="progressbar"
					aria-valuenow={90}
					aria-valuemin={0}
					aria-valuemax={100}
					style={{width: `${percentage}`}}>
				</div>
			</div>
		</div>
	)
}

export default Notification

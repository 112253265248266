import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {customPlanLengthMap} from "./customPlanLengthMap"

interface Props {
	checkoutData: any
	setCheckoutData: (arg0: any) => void
	t: (arg0: string) => string
	isMobile: boolean
	isOpen: boolean
	payOneTime: boolean
	durationInMonth: (string | number)[]
	hidePayOneTimeLabel?: boolean
}

const RegularDurationLabels: React.FC<Props> = ({
	checkoutData,
	setCheckoutData,
	t,
	isMobile,
	isOpen,
	payOneTime,
	durationInMonth,
	hidePayOneTimeLabel
}) => {
	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	return (
		<div
			className={`row pb-1 justify-content-between ${isMobile && `accordion-collapse collapse ${isOpen ? "show" : null}`}`}>
			{durationInMonth.map((month, i) => {
				if (month === "pay-one-time" && (!payOneTime || hidePayOneTimeLabel)) {
					return null
				}

				return (
					<div cypress-slider-label-to-select={`label-${i}`}
						key={i}
						style={{
							borderRadius: 16,
							cursor: "pointer"
						}}
						className={"col-2 plan-length-label-container text-center"}
						onClick={() => {
							setCheckoutData(prevData => {
								return ({
									...prevData,
									planLength: month === "pay-one-time" ? 1 : month
								})
							})
						}}>
						<Typography
							style={{color: "#212529"}}
							variant={checkoutData?.planLength === month || (month === "pay-one-time" && checkoutData?.planLength === 1) ? "bodySmBold" : "bodySm"}>
							{t(`${month === "pay-one-time" ? month : planLengthMap[month]}`)}
						</Typography>
					</div>
				)
			})}
		</div>

	)
}

export default RegularDurationLabels

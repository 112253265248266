import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../../context/LayoutContext"
import Button from "../../reusable/components/Button"
import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import {useRouter} from "next/router"

interface Props {
	productUrl : string
}

export const ShowMoreOverlay = ({productUrl}: Props) => {
	const {isListView} = useContext(LayoutContext)
	const {t} = useTranslation()
	const router = useRouter()

	const handleAllPhotosClick = e => {
		e.stopPropagation()
		router.push(productUrl)
	}

	return (
		<div style={{backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 1}}
			className="position-absolute w-100 h-100 py-3 d-flex justify-content-center align-items-center">
			<div className="text-center">
				<div className="col-auto">
					<Image src={"/assets/icons/plus-box.svg"}
						height={29}
						width={29} />
				</div>
				<div className="col-12 mt-1">
					<Typography variant={"bodyLg"}
						semanticTag={"span"}
						className="text-center text-bold">{t("Wir haben noch mehr Fotos!")}</Typography>
				</div>
				<div className={`col-12 ${isListView ? "mt-2" : "mt-5"}`}>
					<Button label={t("Zu allen Fotos")}
						fontSizeSmall
						color={"orange"}
						onClick={handleAllPhotosClick} />
				</div>
			</div>
		</div>
	)
}

export default ShowMoreOverlay

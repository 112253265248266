import React, {useContext} from "react"
import SearchContext from "../../context/SearchContext"
import dynamic from "next/dynamic"
import {useRouter} from "next/router"

const DynamicSearchFieldA = dynamic(() => import("./SearchFieldA"))
const DynamicSearchField = dynamic(() => import("./SearchField"))

type Props = {
	setIsBackgroundOn?:any
	isBackgroundOn?: boolean
	isNew?: boolean
	isBrandsPage?: boolean
}

const SearchDesktop:React.FC<Props> = ({isNew, setIsBackgroundOn, isBackgroundOn, isBrandsPage}) => {
	const {handleSearch, setLoading, value} = useContext(SearchContext)
	if (isNew) {
		return <DynamicSearchFieldA
			onStartTyping={() => setLoading(true)}
			setIsBackgroundOn = {setIsBackgroundOn}
			isBackgroundOn = {isBackgroundOn}
			onEndTyping={value => {
				handleSearch(value)
			}}
			onType={undefined}
			className={"form-control border bg-black search-field w-100"}	/>
	}

	return <DynamicSearchField
		isBrandsPage={isBrandsPage}
		onStartTyping={() => setLoading(true)}
		setIsBackgroundOn = {setIsBackgroundOn}
		isBackgroundOn = {isBackgroundOn}
		onEndTyping={value => {
			handleSearch(value)
		}}
		onType={undefined}
		className={isBrandsPage ? "form-control text-white bg-transparent cypress-class-search-field  search-field w-100 py-2  brandworld" : "form-control cypress-class-search-field border bg-white search-field w-100"}	/>
}

export default SearchDesktop

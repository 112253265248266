import React, {useContext, useState} from "react"
import Typography from "./Typography/Typography"
import {ModalContext} from "../../context/ModalContext"
import AnalyticsContext from "../../context/AnalyticsContext"
import BookAppointmentModalContent from "./ModalsContent/BookAppointmentModalContent"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import Image from "next/image"

type Prop = {
    service:{title:(t:any)=>string, description:(t:any)=>string, imageUrl:string, handleClick:(paramsObject:any)=>void}
}
function FreeServiceMenuItem({service}: Prop) {
	const {title, description, imageUrl, handleClick} = service
	const [isHoverActive, setIsHoverActive] = useState(false)
	const {t} = useTranslation()
	const router = useRouter()
	const {setModalContent, openModal, closeModal} = useContext(ModalContext)
	const {reactGA} = useContext(AnalyticsContext)
	const bookAppointmentModalSetter = () => {
		setModalContent(<BookAppointmentModalContent closeModal={closeModal}/>)
	}

	return (
		<div
			onMouseOver={() => setIsHoverActive(true)}
			onMouseLeave={() => setIsHoverActive(false)}
			onClick={() => handleClick({router, reactGA, setModalContent, openModal, closeModal, t, bookAppointmentModalSetter})}
			style={{padding: "12px 8px"}}
			className="col-12 rounded free-service d-flex justify-content-start align-items-start gap-3">
			<div
				className="position-relative free-service-image-wrapper d-flex align-items-center rounded-3 justify-content-center"
				style={{width: "40px", height: "40px", padding: "8px 10px", backgroundColor: isHoverActive ? "" : "#F8F8F8"}}>
				<Image
					src={imageUrl}
					alt="Service Image"
					width={20}
					height={20}
					layout="fixed"
					objectFit="contain"
					style={{borderRadius: "4px"}}
				/>
			</div>
			<div className="d-flex flex-column justify-content-start align-items-start gap-1">
				<Typography variant="bodySmBold"
					style={{fontSize: "14px", lineHeight: "20px"}}>{title(t)}</Typography>
				<Typography variant="bodySm"
					style={{fontSize: "12px", lineHeight: "16px"}}>{description(t)}</Typography>
			</div>
		</div>
	)
}

export default FreeServiceMenuItem

import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import DataContext from "../../../context/DataContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {useRouter} from "next/router"
import AnalyticsContext from "../../../context/AnalyticsContext"
import Image from "next/image"

type Props = {
  slug: string
	bgColor?: any
	textBold?: boolean
	borderRadius?: string
	paddingY?: string
	isPDPcomparisonBtn?: boolean
	isListView?: boolean
	isCompatibleAccessories?:boolean
}

enum pagePathname {
  productPage = "produkt",
  landingPage = "/"
}

const ComparisonCheckboxButton: React.FC<Props> = ({slug, isListView, bgColor, textBold, isCompatibleAccessories, borderRadius, paddingY, isPDPcomparisonBtn}) => {
	const {t} = useTranslation()
	const {comparisonProducts, setComparisonProducts} = useContext(DataContext)
	const router = useRouter()
	const {pathname} = router

	const gaCategory = pathname.includes(pagePathname.productPage) ? "pdp" : pathname === pagePathname.landingPage ? "homePage" : "plp"
	const {reactGA} = useContext(AnalyticsContext)

	const onChangeComparisonProducts = (slug: string, e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
		if (!comparisonProducts.includes(slug)) {
			setComparisonProducts([slug, ...comparisonProducts])
			reactGA?.event({
				category: gaCategories[gaCategory],
				action: gaEvents.addProductToComparison,
				label: gaEvents.addProductToComparison,
				nonInteraction: false
			})
		}

		if (comparisonProducts.includes(slug)) {
			comparisonProducts.splice(comparisonProducts.indexOf(slug), 1)
			setComparisonProducts([...comparisonProducts])
			reactGA?.event({
				category: gaCategories[gaCategory],
				action: gaEvents.removeProductFromComparison,
				label: gaEvents.removeProductFromComparison,
				nonInteraction: false
			})
		}
	}

	const isActive = comparisonProducts.includes(slug)

	return (
		<>
			{!isPDPcomparisonBtn &&
				<div className={`col-12 d-flex d-block d-md-none align-items-center ${isListView ? "compare-btn-list-view comparison-btn-mobile-list-view" : isCompatibleAccessories ? "compare-btn-accessory " : "compare-btn comparison-btn-mobile"}`}
					style={{marginRight: isListView ? "8px" : ""}}
					onClick={e => onChangeComparisonProducts(slug, e)}>
					<div className={"row g-2 d-flex align-items-center"}>
						{isActive ? <Image layout="fixed"
							src="/assets/icons/compare-btn-active.svg"
							alt="Compare Bike"
							width={20}
							height={20} /> :
							<Image layout="fixed"
								src="/assets/icons/compare-bikes-icon.svg"
								alt="Compare Bike"
								width={20}
								height={20} />}
					</div>
				</div>}
			<div className={` ${!isPDPcomparisonBtn && "d-none d-md-flex "} px-2 align-items-center ${isListView ? "compare-btn-list-view " : isCompatibleAccessories ? "comparison-btn-accessory" : "compare-btn "}`}
				style={{cursor: "pointer", borderRadius: borderRadius ? borderRadius : 4}}
				onClick={e => onChangeComparisonProducts(slug, e)}>
				<div className={"d-flex align-items-center"}>
					{isActive ? <Image layout="fixed"
						src="/assets/icons/compare-btn-active.svg"
						alt="Compare Bike"
						width={20}
						height={20} /> :
						<Image layout="fixed"
							src="/assets/icons/compare-bikes-icon.svg"
							alt="Compare Bike"
							width={20}
							height={20} />}
				</div>
			</div>
		</>
	)
}

export default ComparisonCheckboxButton

import React, {useContext, useState} from "react"
import parse from "html-react-parser"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"

type Props = {
    description: string
}

const BikeDescription: React.FC<Props> = ({description}) => {
	const parsedText = parse(description)
	const [fullTextShown, setFullTextShown] = useState(false)
	const {t} = useTranslation("common")
	const {reactGA} = useContext(AnalyticsContext)

	const toggleText = () => {
		setFullTextShown(prev => !prev)

		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.descriptionSection,
			label: fullTextShown ? "Desc Section - Clicked Show More" : "Desc Section - Clicked Show Less",
			nonInteraction: false
		})
	}

	return (
		<>
			<div style={{fontSize: 14}}
				className={`${!fullTextShown ? "multiline-truncate-desc" : null} productinfo-bike-desc`}>
				{parsedText}
			</div>
			{fullTextShown ?
				<div className="mt-3"
					onClick={toggleText}>
					<div className={"d-flex gap-2 pointer new-mbp-link"}>
						<Typography variant="bodySmBold"
							semanticTag="span">
							{t("read-less")}
						</Typography>
						<Image src={"/assets/icons/chevron-down-golden.svg"}
							height={6.5}
							width={12}
							alt="down"
							style={{transform: "rotate(180deg)"}}
						/>
					</div>
				</div> : description &&
				<div className="mt-3"
					onClick={toggleText}>
					<div className={"d-flex gap-2 pointer new-mbp-link"}>
						<Typography variant="bodySmBold"
							semanticTag="span">
							{t("read-more")}
						</Typography>
						<Image src={"/assets/icons/chevron-down-golden.svg"}
							height={6.5}
							width={12}
							alt="down"/>
					</div>
				</div>}
		</>
	)
}

export default BikeDescription

import React, {Dispatch, SetStateAction, useContext, useEffect} from "react"
import Typography from "./Typography/Typography"
import {Slides} from "./components/Slides"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import AiPromptLoadingSkeleton from "./LoadingSkeleton/AiPromptLoadingSkeleton"
import LayoutContext from "../../context/LayoutContext"

type Props = {
    loading: boolean
    isGenerating: boolean
    activePrompt:string
    suggestedPrompts: any[]
    result: any[]
    setStep: Dispatch<SetStateAction<1 | 2 | 3>>
    step: 1|2|3
    setActivePrompt: Dispatch<SetStateAction<string>>
	handleSubmit: (e: any) => void
	isAiSearchLandingPage?:boolean
}
function AiPromptSuggestions({suggestedPrompts, step, setStep, isGenerating, loading, result, handleSubmit, setActivePrompt, activePrompt, isAiSearchLandingPage}: Props) {
	useEffect(() => {
		let timeout
		if (step === 2 && loading) {
			timeout = setTimeout(() => {
				setStep(() => 3)
			}, 2000)
		}

		return () => clearTimeout(timeout)
	}, [step, loading])
	const {t} = useTranslation()
	if (step > 2 || (!suggestedPrompts?.length && !isGenerating)) {
		return <></>
	}

	const {isMobile} = useContext(LayoutContext)
	const loadingElementsOnDesktop = [1, 2, 3, 4]
	const className = isAiSearchLandingPage ? "search-ai-prompts-on-landing-page slide-in-right" : "d-md-flex d-none align-items-start slide-in-right justify-content-start pb-2 flex-column gap-4"
	return (
		<div style={{maxWidth: "100%", overflowX: "hidden"}}
			className={`d-flex ${loading ? "slide-left-exit" : result?.length ? "d-none" : ""} col-12 align-items-start justify-content-start flex-column pt-3 gap-3 `}>{suggestedPrompts?.length ? <Typography semanticTag="div"
				style={{fontSize: "14px", lineHeight: "20px"}}
				variant="bodySmBold">{t("pick-one-ai-suggestion")}</Typography> : null}
			{suggestedPrompts?.length && !isGenerating ?
				<><div className={`${step === 2 ? "slide-in-right" : ""} d-md-none col-12 ai-suggestions-slider `}>
					<Slides slidesToShow={1.1}
						arrowsPositionX={-5}
						centerModePadding={{left: 0, bottom: 0, right: -1}}>
						{suggestedPrompts.map((prompt, index) =>
							<div onClick={() => {
								handleSubmit(prompt)
								setActivePrompt(() => prompt)
							}}
							key={index}
							className={`generated-prompt ms-3 ${activePrompt === prompt ? "active" : ""}`}>
								<Typography semanticTag="div"
									style={{fontSize: "20px", lineHeight: "30px"}}
									variant="bodySmBold">{t("ai-suggestion") + " " + Number(index + 1)}</Typography>
								<Typography variant="bodySm">{t("general-ai-pick")}
									<b>{prompt.includes("|") ? prompt.split("|")[0] :
										`Prompt ${index + 1}`}</b></Typography>
								<Typography variant="bodySm">{prompt.includes("|") ?
									prompt.split("|")[1] : prompt}</Typography>
								<div className="d-flex align-items-center justify-content-start gap-2">
									<Typography semanticTag="div"
										style={{fontSize: "14px", lineHeight: "20px"}}
										variant="bodySmBold">{t("view-ai-suggestions")}</Typography>
									<Image src={"/assets/icons/chevron-right-purple.svg"}
										width={24}
										height={24}
										alt="Discover AI Suggestions" /></div>
							</div>)}
					</Slides></div>
				<div className={className}>
					{suggestedPrompts.map((prompt, index) =>
						<div onClick={() => {
							handleSubmit(prompt)
							setActivePrompt(prompt)
						}}
						key={index}
						className={`generated-prompt  ${activePrompt === prompt ? "active" : ""}`}>
							<Typography semanticTag="div"
								style={{fontSize: "20px", lineHeight: "30px"}}
								variant="bodySmBold">{t("ai-suggestion") + " " + Number(index + 1)}</Typography>
							<Typography variant="bodySm">{t("general-ai-pick")} <b>{prompt.includes("|") ?
								prompt.split("|")[0] : `Prompt ${index + 1}`}</b></Typography>
							<Typography variant="bodySm">{prompt.includes("|") ? prompt.split("|")[1] :
								prompt}</Typography>
							<div className="d-flex align-items-center justify-content-start gap-2">
								<Typography semanticTag="div"
									style={{fontSize: "14px", lineHeight: "20px"}}
									variant="bodySmBold">{t("view-ai-suggestions")}</Typography>
								<Image src={"/assets/icons/chevron-right-purple.svg"}
									width={24}
									height={24}
									alt="Discover AI Suggestions" /></div>
						</div>)}
				</div>
				</> : isGenerating && step === 2 ? <div style={{maxWidth: "100%", overflowX: "hidden"}}
					className={isAiSearchLandingPage && !isMobile ?
						"search-ai-prompts-on-landing-page col-12 slide-in-right" :
						"col-12 flex-column gap-2 d-flex align-items-center justify-content-start"}>
					{loadingElementsOnDesktop.map((elem, index) => <AiPromptLoadingSkeleton key={index}
						className={index > 1 ? "d-none d-md-block" : ""} />)}</div> : null}
		</div>
	)
}

export default AiPromptSuggestions

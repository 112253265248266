import React, {useContext} from "react"
import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../../context/LayoutContext"
import {Slides} from "../../reusable/components/Slides"
import AnalyticsContext from "../../../context/AnalyticsContext"

type Props = {
  data?: { text: string, icon: string, listviewText: string, listviewIcon: string}[]
}

export const WhyBuyFromMBPlan = ({data}: Props) => {
	const {t} = useTranslation("PDPTiles")
	const {isListView} = useContext(LayoutContext)
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<>

			<div className="d-md-none mt-3 mb-4">
				<Typography className="mb-3"
					variant="bodyLgBold"
					semanticTag="h3">
					{t("why-buy-from-us-title")}
				</Typography>
				<div className={"row "}>
					<Slides centerMode
						centerModePadding={{left: 0, bottom: 0, right: 75}}>
						{data.map(({text, icon}) =>
							<div key={text}>
								<div className={"col-auto px-0 ms-2 d-flex flex-column align-items-center rounded"}
									style={{maxWidth: 170, width: "100%"}}>
									<div className="d-flex mb-2 justify-content-center align-items-center"
										style={{
											width: 64,
											height: 64,
											borderRadius: "50%",
											border: "2px solid #DEE2E6"
										}}>
										<Image src={icon}
											height={25}
											width={25}/>
									</div>
									<Typography semanticTag="div"
										className="text-center"
										variant="bodyLg">
										{t(text)}
									</Typography>
								</div>
							</div>)
						}
					</Slides>
				</div>
			</div>

			<div style={{minWidth: "250px", margin: "0 0.5px", maxHeight: "432px", height: "100%"}}
				className={`d-none d-md-flex normal-card-item-border row ${isListView ? "align-items-center justify-content-center p-2 mb-2" : "p-4"}`}>
				<div className={`px-0 ${isListView ? "col-md-auto pe-4 me-2" : ""}`}>
					<Typography className="mt-2"
						variant={isListView ? "bodyLgBold" : "heading3MdBodyLgBlack"}
						semanticTag="h3">
						{t("why-buy-from-us-title")}
					</Typography>
				</div>

				<div className={`col-12 px-0 ${isListView ? "col-md-auto d-flex " : "mt-4"}`}>
					{data.map(({text, icon, listviewText, listviewIcon}) =>
						<div key={text}
							className={`d-flex ${isListView ? "mx-2 flex-column align-items-center px-2 mb-0" : "mb-3 "}`}>
							{isListView ?
								<div className="rounded-circle d-flex align-items-center justify-content-center"
									style={{padding: 18, border: "2px solid #DEE2E6"}}>
									<Image src={listviewIcon}
										height={32}
										width={32}
										objectFit="cover"/>
								</div> :
								<Image src={ icon}
									height={40}
									width={40} />
							}
							<Typography semanticTag="div"
								variant="bodySm"
								className={`${isListView ? "mt-2 col-12" : "col-8 ms-3 "}`}>
								{isListView ? listviewText : t(text)}
							</Typography>
						</div>)
					}
				</div>
			</div>

		</>
	)
}

WhyBuyFromMBPlan.defaultProps = {
	data: [
		{text: "why-buy-from-us-text-1", listviewText: "Pay monthly", icon: "/assets/icons/plpBanners/monthly-payments.svg", listviewIcon: "/assets/icons/plpBanners/calendar.svg"},
		{text: "why-buy-from-us-text-2", listviewText: "0% financing", icon: "/assets/icons/plpBanners/sale-zero-interest.svg", listviewIcon: "/assets/icons/plpBanners/zero-interest.svg"},
		{text: "why-buy-from-us-text-3", listviewText: "Free delivery", icon: "/assets/icons/plpBanners/truck.svg", listviewIcon: "/assets/icons/plpBanners/delivery.svg"},
		{text: "why-buy-from-us-text-4", listviewText: "Free assembly", icon: "/assets/icons/plpBanners/repair.svg", listviewIcon: "/assets/icons/plpBanners/repair-listview.svg"}
	]
}


export const customPlanLengthMap = {
	mhp_bfe: {
		1: 1,
		12: 6,
		24: 12,
		36: 24,
		48: 36
	},
	mhp: {
		1: 1,
		12: 6,
		24: 12,
		36: 24,
		48: 36
	},
	mbp: {
		1: 1,
		12: 12,
		24: 24,
		36: 36,
		48: 48
	},
	mbp_bfe: {
		1: 1,
		12: 6,
		24: 12,
		36: 24,
		48: 36
	}

}


import React, {useContext, useEffect, useRef, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"
import {testDriveLink} from "../../constants"
import {X} from "react-bootstrap-icons"
import {ModalContext} from "../../context/ModalContext"

type Props = {
	bannerTitle: string
	listTitle: string
	bgImageSrc: string
	stepsWithIconsContent:any[]
}

const BannerWithCalendar: React.FC<Props> = ({bannerTitle, listTitle, bgImageSrc, stepsWithIconsContent}) => {
	const iframeContainertRef = useRef<HTMLDivElement>()
	const [update, forceUpdate] = useState(0)
	const {closeModal} = useContext(ModalContext)
	const [isLoading, setIsLoading] = useState(true)
	const scriptRef = useRef<HTMLScriptElement>(null)
	useEffect(() => {
		const scriptTag = document.createElement("script")
		scriptTag.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
		scriptRef.current = scriptTag
		document.body.appendChild(scriptTag)
		setTimeout(() => forceUpdate(1), 2000)
		return () => {
			scriptRef.current?.remove()
		}
	}, [])
	useEffect(() => {
		if (iframeContainertRef.current.firstElementChild) {
			setIsLoading(false)
		}
	}, [update])

	return (
		<>
			<X size={24}
				onClick={closeModal}
				style={{top: "18px", right: "4px"}}
				className="position-absolute"/>
			<section className="d-none d-md-flex justify-content-between align-items-center banner-with-form container position-relative"
				style={{
					backgroundImage: `url(${bgImageSrc})`,
					backgroundPosition: "center",
					backgroundSize: "cover"

				}}>
				<div className="text-section col-6">
					<Typography variant="heading1"
						semanticTag="h1"
						className="text-white mb-4 pb-3 text-start"
						style={{lineHeight: "64px", fontSize: 48}}>
						{bannerTitle}
					</Typography>
					{stepsWithIconsContent?.length ? <div className="text-section col-12 ">
						<div className="instruction-steps">
							<Typography variant="bodySmBlack"
								className="mb-2 text-white mt-3 pb-1">{listTitle}</Typography>
							{stepsWithIconsContent.map((step, index) => (<div key={index}
								className="step">
								<Typography style={{minHeight: "3rem"}}
									variant="heading3SmBlack"
									className="number text-dark">{step?.header}</Typography>
								<Typography variant="bodyLg"
									className="text-white">{step?.description}</Typography>
							</div>))}
						</div>
					</div> : null}
				</div>
				{isLoading &&
			<div style={{zIndex: 15000}}
				className="position-absolute d-flex end-0 justify-content-center align-items-center h-100 w-50">
				<div className="spinner-border text-primary p-5 me-7"/>
			</div>
				}
				<div ref={iframeContainertRef}
					id={"hubspot_frame"}
					className="d-flex meetings-iframe-container justify-content-center form-section col-12  position-relative"
					data-src={`${testDriveLink}?type=embed`} />
			</section>
			<section className="container d-md-none banner-form-mobile">
				<div className="col-12 info-card-image position-relative">
					<Image src={bgImageSrc}
						layout={"fill"}
						objectFit={"cover"}
						objectPosition="top"/>
					<Typography variant="heading1Black"
						className="position-absolute text-white info-card-title pe-2"
						style={{zIndex: 10}}>
						{bannerTitle}
					</Typography>
				</div>
				{ stepsWithIconsContent?.length ? <div className="text-section col-12 ">
					<div className="instruction-steps">
						<Typography variant="bodySm"
							className="mb-2 mt-3 pb-1">{listTitle}</Typography>
						{stepsWithIconsContent.map((step, index) => (<div key={index}
							className="step">
							<Typography variant="heading3SmBlack"
								className="number text-dark">{step?.header}</Typography>
							<Typography variant="bodySm">{step?.description}</Typography>
						</div>))}
					</div>
				</div> : null}
				{isLoading &&
			<div style={{zIndex: 15000}}
				className="position-absolute d-flex justify-content-center end-9 align-items-center w-100 h-100">
				<div className="spinner-border text-primary p-5"/>
			</div>
				}
				<div ref={iframeContainertRef}
					id={"hubspot_frame"}
					className="d-flex meetings-iframe-container justify-content-center form-section col-12 mt-4 pt-3 position-relative"
					data-src={`${testDriveLink}?type=embed`} />

			</section>
		</>
	)
}

export default BannerWithCalendar

import React from "react"

type Props = {
    onClick?:()=>void
}
function Switch({onClick}:Props) {
	return (
		<label className="switch" >
			<input type="checkbox"
				onClick={onClick} />
			<span className="switch-slider round"></span>
		</label>
	)
}

export default Switch

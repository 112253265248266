import React from "react"
import Typography from "./Typography/Typography"
import {useTranslation} from "next-i18next"
import {CheckLg} from "react-bootstrap-icons"
import VideoAskIframe from "../videoAsk/VideoAskIframe"

const VideoAskSection = () => {
	const {t} = useTranslation()

	return (
		<div className="container mt-5 mt-md-0 mb-5">
			<div className="row align-items-center">
				<div className="col-md-6">
					<div className="row mb-4">
						<div className="col-12 d-flex align-items-center">
							<img className="me-3"
								src="/assets/icons/componentsIcons/videa-answer-question.svg"
								alt="icon" />
							<Typography variant="heading3"
								semanticTag="span">{t("videoask-section-title")}</Typography>
						</div>
					</div>
					<div className="row mb-1 pb-3 justify-content-start list-unstyled">
						<ul className="col-12">
							<li className="mb-2 d-flex"><CheckLg size={16}/><Typography className="ms-2"
								variant="bodyLg"
								semanticTag="span">{t("videoask-section-list-item-1")}</Typography></li>
							<li className="mb-2 d-flex"><CheckLg size={16}/><Typography className="ms-2"
								variant="bodyLg"
								semanticTag="span">{t("videoask-section-list-item-2")}</Typography></li>
							<li className="d-flex"><CheckLg size={16}/><Typography className="ms-2"
								variant="bodyLg"
								semanticTag="span">{t("videoask-section-list-item-3")}</Typography></li>
						</ul>
					</div>
				</div>
				<div className="col-md-6">
					<VideoAskIframe/>
				</div>
			</div>
		</div>
	)
}

export default VideoAskSection

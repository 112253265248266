import React, {Dispatch, SetStateAction} from "react"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {freeServices} from "../../../constants"
import FreeServiceMenuItem from "../../reusable/FreeServiceMenuItem"
import RyderAiFreeService from "../desktop/RyderAiFreeService"

type Props = {
    isFreeServicesMenuOpen:boolean
    setIsFreeServicesMenuOpen:Dispatch<SetStateAction<boolean>>
}
function FreeServicesMenu({isFreeServicesMenuOpen, setIsFreeServicesMenuOpen}: Props) {
	const {t} = useTranslation()
	return (
		<AnimationComponent type="slideRight"
			inProp={isFreeServicesMenuOpen}>
			<div className="position-fixed bg-white border-top "
				style={{zIndex: 99999, top: 0, width: "100dvw", height: "100dvh"}}>
				<div className="d-flex align-items-center py-2 px-3 pt-4 mb-3">
					<Image
						src="/assets/icons/arrow-left.svg"
						width={20}
						height={20}
						alt={"arrow left"}
						className="cursor-pointer"
						onClick={() => setIsFreeServicesMenuOpen(false)}
					/>
					<Typography variant="heading3MdBodyLgBold"
						semanticTag="span"
						className="ms-3">{t("free-services")}</Typography>
				</div>
				<div style={{overflowY: "auto", maxHeight: "75dvh"}}
					className="d-flex align-items-start justify-content-start flex-column gap-1 ">
					{freeServices.items.map((service, index) => <React.Fragment key={index}>{
						service.title(t) === t("ask-ai") ? <RyderAiFreeService /> : <>
							<FreeServiceMenuItem key={index}
								service={service} />
							{index < freeServices.items.length - 1 ? <div className={"col-12 px-3 mt-2"}>
								<div className={"border-top mb-2"}/>
							</div> : null}
						</>
					}</React.Fragment>)}
				</div>
			</div>
		</AnimationComponent>
	)
}

export default FreeServicesMenu

import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import DataContext from "../context/DataContext"
import {useRouter} from "next/router"
import CartContext from "../context/CartContext"
import {BikeType} from "../utility/Types"
import {createRecommendationsList} from "../utility/Helper"
import {guardRecommendedBikesType} from "../utility/typeGuards"
import {defaultValue} from "../components/filter/Config"
import useGetRecentlyViewedBikes from "../hooks/useGetRecentlyViewedBikes"
import {fetchGet} from "../firebaseAdmin/fetchGet"
import {S} from "@upstash/redis/zmscore-d1ec861c"

type Props = {
    children: React.ReactNode
	pageProps: any
}

const sessionStorageItemName = "comparisonList"
const channel = new BroadcastChannel("comparison-sync")

const storeComparison = items => {
	if (typeof window !== "undefined") {
		sessionStorage.setItem(sessionStorageItemName, JSON.stringify(items))
		channel.postMessage(items) // This will share changes to other tabs
	}
}

const getComparison = () => {
	if (typeof window !== "undefined") {
		const list = sessionStorage.getItem(sessionStorageItemName)
		return list ? JSON.parse(list) : []
	}

	return []
}

const DataContextProvider: React.FC<Props> = ({children, pageProps}) => {
	const {bikesList: bikes, isCollectionOrBrandPage, brandWorld, bikeFilters, comparisonAttributes, horizontalBikesCategoryFilters, accessoryFilters, productsCount, deals, productsPriceRange, accessoriesPriceRange, sponsorshipData} = pageProps
	const {clearCart} = useContext(CartContext)
	const [organizationSlug, setOrganizationSlug] = useState<string | null>(null)
	const [organizationInitialized, setOrganizationInitialized] = useState<boolean>(false)
	const [navigationObjects, setNavigationObjects] = useState()
	const {asPath, query} = useRouter()
	const [data, setData] = useState<any>(null)
	const [customBikeRecommendations, setCustomBikeRecommendations] = useState<BikeType[]>([])
	const [isSponsored, setIsSponsored] = useState(false)
	const [userLifestyle, setUserLifestyle] = useState("")
	const [collectionBikes, setCollectionBikes] = useState<any[] | null>(bikes)
	const [accessories, setAccessories] = useState<any[] | null>(bikes)
	const [collections, setCollections] = useState<any[] | null>(pageProps.collections)
	const [height, setHeight] = useState<any[] | null>(pageProps.height)
	const [categories, setCategories] = useState<any[] | null>(pageProps?.categories)
	const [localisedSlugs] = useState<any[] | null>(pageProps.localisedSlugs)
	const [brands, setBrands] = useState<any[] | null>(pageProps.brands)
	const [eventListeners, setEventListeners] = useState(null)
	const [productHSList, setProductHSList] = useState<any[]>(null)
	const [comparisonProducts, setComparisonProducts] = useState<string[]>([])
	const maxAmountBlackFridayBikes = 300
	const soldAmountBlackFridayBikes = 150
	const router = useRouter()
	const {recentlyViewedBikes, isLoading} = useGetRecentlyViewedBikes()
	const [pageIndex, setPageIndex] = useState<number>(0)
	const premiumBrandWidgets = pageProps?.strapiContent?.data?.premium_brand_widgets

	useEffect(() => {
		if (!premiumBrandWidgets || !Array.isArray(premiumBrandWidgets)) {
			return
		}

		premiumBrandWidgets.forEach(brandWidget => {
			if (brandWidget?.attributes?.recommendedBikeSlugs?.length) {
				const slugs = brandWidget?.attributes?.recommendedBikeSlugs?.map(item => item?.slug).join(",")
				const locale = router?.locale || "de"

				fetchGet(`/api/products/bikes/fetch-bikes-by-slugs/${slugs}?locale=${locale}&multiple=true`)
					.then(response => response.json())
					.then(data => {
						setCustomBikeRecommendations(prev => {
							if (Array.isArray(prev)) {
								return [...data, ...(prev.filter(bike => !slugs?.includes(bike.slug)))]
							}

							return data
						})
					})
					.catch(error => console.error("Error fetching bikes:", error))
			}
		})
	}, [premiumBrandWidgets])

	const handleRecommendations = () => {
		if (recentlyViewedBikes?.length) {
			const recommendedBikesList = createRecommendationsList(recentlyViewedBikes, customBikeRecommendations)
			const recommendedBikes = guardRecommendedBikesType(recommendedBikesList)
			setCustomBikeRecommendations(() => recommendedBikes)
		}
	}

	useEffect(() => {
		if (typeof window !== "undefined") {
			const previousFilters = JSON.parse(localStorage.getItem("previousFilters") || "{}")

			const currentFilters = {}

			Object.entries(router.query).forEach(([key, value]) => {
				if (value !== "" && value !== undefined && value !== defaultValue) {
					currentFilters[key] = value
				}
			})

			Object.entries(router.query).forEach(([key, value]) => {
				const prev = previousFilters[key] as string || ""
				let prevFiltersArray = prev?.split(",") || []
				const newFilter = value as string || ""
				const currentFiltersArray = newFilter?.split(",") || []

				currentFiltersArray.forEach(currentValue => {
					if (!prevFiltersArray?.includes(currentValue?.trim()) && currentValue !== defaultValue && currentValue !== undefined) {
						prevFiltersArray = [currentValue?.trim(), ...prevFiltersArray]
					}
				})
				previousFilters[key] = prevFiltersArray.join(",")
			})
			localStorage.setItem("previousFilters", JSON.stringify(previousFilters || {}))
			window.dispatchEvent(new Event("previousFiltersUpdated"))
		}
	}, [router])

	const [isInitialized, setIsInitialized] = useState<boolean>(false)
	useEffect(() => {
		if (sponsorshipData && isSponsored) {
			clearCart()
		}
	}, [sponsorshipData, isSponsored])
	const bikesCountByOption = pageProps.productsCount

	const _asPath = asPath.split("?")[0]
	const isBlackFriday = _asPath === "/black-friday"

	useEffect(() => {
		const handleSync = event => {
			setComparisonProducts(prevProducts => {
				const newProducts = event.data || []
				const mergedSet = new Set([...prevProducts, ...newProducts])

				if (mergedSet.size === prevProducts.length) {
					return prevProducts
				}

				return Array.from(mergedSet)
			})
		}

		channel.addEventListener("message", handleSync)

		return () => {
			channel.removeEventListener("message", handleSync)
		}
	}, [])

	useEffect(() => {
		if (isInitialized) {
			if (JSON.stringify(getComparison()) !== JSON.stringify(comparisonProducts)) {
				storeComparison(comparisonProducts)
			}
		}
	}, [comparisonProducts])

	useEffect(() => {
		setIsInitialized(true)
		setComparisonProducts(getComparison())
		setOrganizationSlug(process.env.NEXT_PUBLIC_CUSTOM_INSTANCE ? window.location.host.split(".")[0] : null)
		setOrganizationInitialized(true)
	}, [])

	useEffect(() => {
		handleRecommendations()
	}, [isLoading, recentlyViewedBikes])

	const fetchLifestyle = () => {
		const lifestyle = localStorage.getItem("userLifestyle") || ""
		if (lifestyle?.length) {
			setUserLifestyle(() => lifestyle)
		}
	}

	useEffect(() => {
		fetchLifestyle()
		if (typeof window !== "undefined") {
			window.addEventListener("userLifestyleUpdated", fetchLifestyle)
		}

		return () => window.removeEventListener("userLifestyleUpdated", fetchLifestyle)
	}, [userLifestyle])

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("RecentlyViewedUpdated", handleRecommendations)
		}

		return () => window.removeEventListener("RecentlyViewedUpdated", handleRecommendations)
	}, [])

	useEffect(() => {
		if (isInitialized) {
			storeComparison(comparisonProducts)
		}
	}, [comparisonProducts])

	// After navigating to any page
	useEffect(() => {
		const handleRouteChange = () => {
			setPageIndex(prevIndex => prevIndex + 1)
		}

		// Subscribe to the event
		router.events.on("routeChangeComplete", handleRouteChange)

		// Cleanup the subscription on component unmount
		return () => {
			router.events.off("routeChangeComplete", handleRouteChange)
		}
	}, [router])

	return (
		<DataContext.Provider value={{navigationObjects, brandWorld, setNavigationObjects, organizationInitialized, userLifestyle, comparisonAttributes, customBikeRecommendations, horizontalBikesCategoryFilters, organizationSlug, productsCount, height, accessoriesPriceRange, setHeight, categories, setCategories, setProductHSList, productHSList, setComparisonProducts, comparisonProducts, recentlyViewedBikes, maxAmountBlackFridayBikes, soldAmountBlackFridayBikes, isCollectionOrBrandPage, data, setData, bikes, accessories, setAccessories, bikesCountByOption, collections, setCollections, brands, setBrands, localisedSlugs, collectionBikes, setCollectionBikes, pageProps, isBlackFriday, eventListeners, setEventListeners, bikeFilters, accessoryFilters, deals, productsPriceRange, pageIndex}}>
			{children}
		</DataContext.Provider>
	)
}

DataContextProvider.propTypes = {
	children: PropTypes.node,
	pageProps: PropTypes.any
}

export default DataContextProvider

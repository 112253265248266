import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {formatPrice} from "../../../utility/Helper"
import {BikeType} from "../../../utility/Types"
import {useTranslation} from "next-i18next"
import DownpaymentGift from "./DownpaymentGift"

function TotalPriceOnPdp({
	bike,
	finalPriceWithoutDownPayment,
	isSecondHand,
	discount,
	onSale}:{bike:BikeType, discount:number, finalPriceWithoutDownPayment:any, onSale:boolean, isSecondHand:boolean}) {
	const formattedPriceWithoutDownPayment = formatPrice(finalPriceWithoutDownPayment)
	const formattedPriceForSecondHand = formatPrice(bike.displayPrice)
	const formatteduvpPriceForSecondHand = formatPrice(bike.uvpPrice)
	const {t} = useTranslation()
	return (
		<div className={"row g-1"}>
			{isSecondHand ? (
				<div className={"row g-1"}>
					<div className={"col-auto"}>
						<Typography variant="bodySm"
							semanticTag="span"
							style={{color: "#6C7074"}}>
							{`${t("Gesamtpreis")} - ${formattedPriceForSecondHand}`}
						</Typography>
					</div>
					{ <div className={"col-auto"}>
						<Typography semanticTag="span"
							variant="bodySm"
							className={"text-secondary text-decoration-line-through"}>
												CHF {formatteduvpPriceForSecondHand}
						</Typography>
					</div>}
				</div>
			) : <div className={"col-auto"}>
				<Typography variant="bodyXSm"
					semanticTag="span"
					style={{color: "#6C7074"}}>
					{`${t("Gesamtpreis")} - ${formattedPriceWithoutDownPayment}`}
				</Typography>
			</div>}

			{onSale && <><div className={"col-auto"}>
				<Typography semanticTag="span"
					variant="bodyXSm"
					className={"text-secondary text-decoration-line-through"}>
					{formatPrice(bike.uvpPrice)}
				</Typography>
				<Typography semanticTag="span"
					variant="bodyXSm"
					className={"text-secondary ms-1"}>
					{"(UVP)"}
				</Typography>
			</div>
			<DownpaymentGift amount={(discount) || 0} /></>}

		</div>
	)
}

export default TotalPriceOnPdp

import React, {useContext} from "react"
import DurationSlideHeader from "./DurationSlideHeader"
import DownPayment from "../plan/Downpayment"
import PriceTooltipWithCheckoutBtn from "./PriceTooltipWithCheckoutBtn"
import Plan from "../plan/Plan"
import PriceDisplay from "./PriceDisplay"
import Typography from "../../reusable/Typography/Typography"
import Button from "../../reusable/components/Button"
import useDiscount from "../../../hooks/useDiscount"

type Props = {
    planLength: any
    initialPrice?: any
    customInstanceDiscount?: number
    product: any
    isSecondHand: boolean
    isMobile: boolean
    disabled?: boolean
    isLoading?: boolean
    handleClick?: any
    isAccessory?: boolean
    handleSizeClick?: any
    priceInputBesidesTitle?: boolean
    hasPlaceHolder?: boolean
    hasOrganizationDiscount?: boolean
    selectedVariation?: any
    handleCheckoutBtnClick: (directPayment?:any) => void
    onSale?: boolean
    checkoutData?: any
    t?: any
    finalPriceWithoutDownPayment?: any
    monthlyPriceWithoutDownpayment?: any
    finalPriceWithDownPayment?: any
    isCartCompatibility?: boolean
    isPopup?: boolean
    setIsPopupOpen?: (isModalOpen?:boolean) => void
}

const DurationAndDownpaymentContent: React.FC<Props> = ({
	monthlyPriceWithoutDownpayment,
	finalPriceWithoutDownPayment,
	finalPriceWithDownPayment,
	handleCheckoutBtnClick,
	customInstanceDiscount,
	isCartCompatibility,
	handleSizeClick,
	setIsPopupOpen,
	initialPrice,
	isSecondHand,
	checkoutData,
	isAccessory,
	planLength,
	isLoading,
	disabled,
	isMobile,
	isPopup,
	product,
	onSale,
	t
}) => {
	const {discount} = useDiscount(product)
	return (
		<div className={`pt-mb-3 bg-white ${isPopup ? "px-2 pt-4 mt-2" : "px-3 py-4 pb-md-3"}`}
			style={{borderRadius: "16px"}}>
			<div style={{maxHeight: "80dvh", overflowY: "auto", overflowX: "hidden"}}>
				<div className="d-flex justify-content-between gap-md-3 align-items-start">
					<DurationSlideHeader
						product={product}
						checkoutData={checkoutData}
						t={t}
					/>
					<div style={{minWidth: "263px"}}
						className="d-none d-xl-block">
						<Plan isMobile={isMobile}
							isSecondHand={isSecondHand} />
					</div>
					<div style={{minWidth: "193px"}}
						className="mt-md-3 d-xl-none">
						<Plan isMobile={isMobile}
							isSecondHand={isSecondHand} />
					</div>
				</div>
				<div style={{marginTop: "16px"}}
					className={`${checkoutData?.planLength === 1 && "d-none"}`}>
					<DownPayment isNewOrderDownpayment={!isMobile}
						bikeId={product.id}
						min={0}
						max={finalPriceWithoutDownPayment}
						PriceInputBesidesTitle={isMobile}
						hasPlaceholder={isMobile}
						monthlyPriceWithoutDownpayment={monthlyPriceWithoutDownpayment}
						showBrackets
					/>
				</div>
				{onSale && <PriceTooltipWithCheckoutBtn
					product={product}
					isOnSale={onSale}
					isSecondHand={isSecondHand}
					disabled={disabled}
					isLoading={isLoading}
					handleClick={handleCheckoutBtnClick}
					isAccessory={isAccessory}
					handleSizeClick={handleSizeClick}
					planLength={planLength}
					initialPrice={initialPrice}
					customInstanceDiscount={customInstanceDiscount}/>}
				{/* // This is the price text */}
				{onSale || isPopup ? null : <PriceDisplay finalPriceWithDownPayment={finalPriceWithDownPayment}
					finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
					planLength={planLength}
					isSecondHand={isSecondHand}
					montlyPriceSize={isPopup ? 24 : ""}/> }
				{!isCartCompatibility && <Typography variant="bodyLg"
					semanticTag="p">
					{t("First and secondhand ebikes can’t be in the cart together.")}
				</Typography>}

				{isPopup ?
					<div className="col-12 d-flex align-items-center justify-content-between pt-3">
						<PriceDisplay finalPriceWithDownPayment={finalPriceWithDownPayment}
							finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
							planLength={planLength}
							isSecondHand={isSecondHand}
							montlyPriceSize={isPopup ? 24 : ""}
							isInPopop/>

						<Button bgColor="#FEC04D"
							textColor="#212529"
							color="#212529"
							style={{height: 44, width: "102px"}}
							className=" py-3 data-cypress-checkout-btn d-flex gap-2 justify-content-center align-items-center"
							spinnerColor="#212529"
							label={t("confirm")}
							btnFontSize={14}
							onClick={setIsPopupOpen}
						/>
					</div> :
					null}
			</div>
		</div>
	)
}

export default DurationAndDownpaymentContent

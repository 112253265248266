import {useContext, useEffect, useState} from "react"
import WishListContext from "../context/WishListContext"
import {BikeType} from "../utility/Types"
import {useRouter} from "next/router"
import AnalyticsContext from "../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../config/googleAnalytics/events"

function useHandleBookmark(bike:BikeType) {
	const {getWishListItemBySlug, deleteWishListItemBySlug, addToWishList} = useContext(WishListContext)
	const {reactGA} = useContext(AnalyticsContext)

	const router = useRouter()
	const [isBookmarked, setIsBookmarked] = useState<boolean>(null)
	const handleDeleteBookmark = e => {
		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.deleteBookMark,
			label: `${gaEvents.deleteBookMark} ${bike?.name}`,
			nonInteraction: false
		})
		e.preventDefault()
		e.stopPropagation()
		deleteWishListItemBySlug(bike?.slug)
		setIsBookmarked(() => false)
	}

	const handleAddBookmark = e => {
		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.addBookMark,
			label: `${gaEvents.addBookMark} ${bike?.name}`,
			nonInteraction: false
		})
		e.preventDefault()
		e.stopPropagation()
		addToWishList(bike)
		setIsBookmarked(() => true)
	}

	useEffect(() => {
		const bikeFoundInBookmark = getWishListItemBySlug(bike?.slug)
		if (bikeFoundInBookmark) {
			setIsBookmarked(() => Boolean(bikeFoundInBookmark))
		}
	}, [addToWishList, deleteWishListItemBySlug, router])

	return {handleAddBookmark, handleDeleteBookmark, isBookmarked}
}

export default useHandleBookmark

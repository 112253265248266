import {useTranslation} from "next-i18next"
import React, {useContext, useState} from "react"
import ReactDOM from "react-dom"
import CustomerQuestionModal from "../reusable/modals/CustomerQuestionModal"
import Typography from "../reusable/Typography/Typography"
import CustomerQuestionItem from "./CustomermerQuestionItem"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import Image from "next/image"
import LinkWithIcon from "../reusable/components/LinkWithIcon"

interface Props {
  bike: any
  selectedVariation: any,
  questions: any[],
  isButtonDesign?: boolean
}

const CustomerQuestionsSection: React.FC<Props> = ({bike, selectedVariation, questions, isButtonDesign = true}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const sku = selectedVariation ? selectedVariation?.sku : bike?.sku ? bike?.sku : "0000000"
	const name = bike?.name
	const {reactGA} = useContext(AnalyticsContext)

	const closeModal = (): void => {
		setIsModalOpen(false)
	}

	const openModal = (): void => {
		setIsModalOpen(true)
	}

	const {t} = useTranslation()

	return (
		<>
			{isButtonDesign ?
				<div>
					<LinkWithIcon
						label={t("ask_your_question")}
						iconMargin="me-2"
						labelVariant={"bodySmBold"}
						padding={"6px 12px"}
						borderRadius={8}
						backgroundColor="#FFF6E7"
						iconFirst
						icon={<Image src={"/assets/icons/question-hand-raise.svg"}
							height={20}
							width={20}
							alt="Ask a question"/>}
						onClick={() => {
							openModal()
							reactGA?.event({
								category: gaCategories.pdp,
								action: gaEvents.askYourQuestion,
								label: gaEvents.askYourQuestion,
								nonInteraction: false
							})
						}}
					/>
					<div className="row">
						{questions ?
							questions.length < 1 ?
								<div className="col-12 pt-4">
									<Typography className="mb-2"
										semanticTag="p"
										variant={"bodyLgBold"}>{`${t("do-you-have-a-question?")} ${bike.name}?`}</Typography>
									<Typography semanticTag="p"
										variant={"bodyLg"}
										className="mb-0">{t("ask-our-experts")}</Typography>
								</div> :
								questions.map((question, i) => {
									const {firstName, lastName} = question
									if (i < questions.length - 1) {
										return (<React.Fragment key={firstName + lastName + i}>
											<CustomerQuestionItem question={question} />
											<div style={{margin: "24px 0px "}}
												className={"col-8 "}>
												<div className={"border-top "}/>
											</div>
										</React.Fragment>)
									}

									return <CustomerQuestionItem key={firstName + lastName + i}
										question={question}/>
								}) :
							<div className="col-12 text-danger">
								<Typography semanticTag="span"
									variant={"bodyLg"}>{"Error Fetching questions"}</Typography>
							</div>}
					</div>
				</div> :
				<div className="pb-2 px-3 d-flex col-12 flex-column align-items-center text-center"
					style={{
						background: "#FFFFFF",
						border: "4px solid #F8F8F8",
						borderRadius: "16px",
						paddingTop: 12,
						minHeight: 100

					}}
					onClick={() => {
						openModal()

						reactGA?.event({
							category: gaCategories.pdp,
							action: gaEvents.askYourQuestion,
							label: gaEvents.askYourQuestion,
							nonInteraction: false
						})
					}}>
					<Image className="mt-1"
						src="/assets/icons/person.svg"
						height={20}
						width={18}/>
					<Typography className="mt-2"
						variant="bodySm">{t("ask_your_question")}</Typography>
				</div>
			}
			{isModalOpen ?
				ReactDOM.createPortal(<CustomerQuestionModal name={name}
					sku={sku}
					isModalOpen={isModalOpen}
					closeModal={closeModal} />, document.body
				) : null}
		</>
	)
}

export default CustomerQuestionsSection

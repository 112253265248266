import Typography from "../../reusable/Typography/Typography"
import {X} from "react-bootstrap-icons"
import {onModalButtonClick} from "../../bikesCollection/BikesCollectionItem/SimilarBikesPdpButton"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import React from "react"

function SimilarBikesModalHeader() {
	const router = useRouter()
	const {t} = useTranslation()
	return (
		<div className="similar-bikes-child-container-padding  d-flex col-12 align-items-center justify-content-between">
			<Typography style={{fontSize: "36px", lineHeight: "44px"}}
				semanticTag="span"
				variant="bodySmBlack"
				className={" "}>{ t("similar-bikes")}</Typography>
			<X onClick={e => {
				e.stopPropagation()
				onModalButtonClick(router, router.pathname)
			}}
			size={30}
			className="cursor-pointer" />
		</div>
	)
}

export default SimilarBikesModalHeader

import {useRouter} from "next/router"
import React, {useContext} from "react"
import LayoutContext from "../../context/LayoutContext"

const VideoAskIframe = () => {
	const {isMobile} = useContext(LayoutContext)
	const {locale} = useRouter()
	const src = isMobile ?
		locale === "de" ?
			`https://www.videoask.com/${process.env.NEXT_PUBLIC_VIDEO_ASK_DE}` :
			`https://www.videoask.com/${process.env.NEXT_PUBLIC_VIDEO_ASK_FR}` :
		locale === "de" ?
			`https://www.videoask.com/${process.env.NEXT_PUBLIC_VIDEO_ASK_DE}` :
			`https://www.videoask.com/${process.env.NEXT_PUBLIC_VIDEO_ASK_FR}`

	return (
		<iframe src={src}
			allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
			width="100%"
			height="400px"
			style={{border: "none", borderRadius: "3px"}}
		>
		</iframe>

	)
}

export default VideoAskIframe

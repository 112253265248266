export const Collections = {
	productQuestions: "productQuestions",
	storyViews: "storyViews",
	storyInteractions: "storyInteractions",
	storyLikes: "storyLikes",
	sponsorMe: "sponsorMe",
	breakingErrorLogs: "breakingErrorLogs",
	wishLists: `userWishLists-${process.env.NEXT_PUBLIC_STOREFRONT}`,
	wishListsLegacy: "userWishLists",
	sponsorMePayments: "sponsorMePayments",
	admins: "admins",
	stockBroker: "stockBroker",
	checkOuts: `checkouts-${process.env.NEXT_PUBLIC_STOREFRONT}`,
	contentfulPageData: "contentfulPageData",
	magicDocs: "magicDocs",
	userOrganizations: "userOrganizations",
	shortURLs: "shortURLs",
	topSearches: `topSearches-${process.env.NEXT_PUBLIC_STOREFRONT}`,
	wheelOfFortune: "wheelOfFortuneSpring",
	wheelOfFortuneShowToUser: "wheelOfFortuneShowToUserSpring",
	wheelOfFortuneUserForm: "wheelOfFortuneUserFormSpring"
}

import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {CheckoutData} from "../../../context/CheckoutContext"

interface Props {
	product: any
    checkoutData: any|CheckoutData
    t: (arg0: string) => string
}

const DurationSlideHeader:React.FC<Props> = ({
	t
}) => {
	return (
		<div className="d-flex flex-column gap-2 justify-content-between">
			<div className="d-flex  gap-1 flex-column align-items-start  justify-content-between">
				<div className="d-flex justify-content-start align-items-center gap-2">
					<Typography style={{fontSize: "14px", lineHeight: "20px"}}
						variant="bodyLgBold"
						semanticTag="span">{t("Laufzeit")}</Typography>
				</div>
				<div className="d-flex align-items-center justify-content-start">
					<Typography variant="bodyLg"
						semanticTag="span"
						style={{fontSize: "14px", lineHeight: "20px", color: "#6C7074"}}>({t("Monate")})</Typography>
				</div>
			</div>
		</div>
	)
}

export default DurationSlideHeader

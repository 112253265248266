import React, {useContext, useState} from "react"
import parse, {domToReact} from "html-react-parser"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import ABContext from "../../../context/ABContext"
import {variants} from "../../../middleware"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

type Props = {
  bike: any;
};

const BikeInfoAndOverview: React.FC<Props> = ({bike}) => {
	const [showMore, setShowMore] = useState(false)
	const {t} = useTranslation("common")
	const {variant} = useContext(ABContext)
	const isNew = variant === variants.new
	const description = typeof bike?.description === "string" ? bike?.description : ""
	const {reactGA} = useContext(AnalyticsContext)

	const groupSections = (html: string) => {
		const sections: string[] = []
		let currentSection = ""

		html.split(/(?=<h2>)/).forEach(chunk => {
			if (chunk.startsWith("<h2>")) {
				if (currentSection) {
					sections.push(currentSection)
				}

				currentSection = chunk
			} else {
				currentSection += chunk
			}
		})

		if (currentSection) {
			sections.push(currentSection)
		}

		return sections
	}

	let sections = groupSections(description)
	if (isNew) {
		sections = sections?.filter(
			section => !section.toLowerCase().includes("grössenempfehlung")
		)
	}

	const parseHtml = (html: string) =>
		parse(html, {
			replace: (node: any) => {
				if (node.name === "li") {
					return (
						<div className="row row-md-none px-3 custom-list-item d-flex align-items-start">
							<Image
								src="/assets/icons/checkmark-yellow.svg"
								alt="icon"
								className="list-icon col-1"
								width={20}
								height={20}
							/>
							<span className="col-10">{domToReact(node.children)}</span>
						</div>
					)
				}
			}
		})

	// Renders only the first section initially
	const contentToDisplay = showMore ? sections : [sections[0]]

	const handleBtnClick = () => {
		setShowMore(prev => !prev)

		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.descriptionSection,
			label: showMore ? "Overview Section - Clicked Show More" : "Overview Section - Clicked Show Less",
			nonInteraction: false
		})
	}

	return (
		<div className="row bike-overview">
			<div className="col-12">
				{contentToDisplay.map((section, index) => (
					<div key={index}>
						{typeof section === "string" ? parseHtml(section) : null}
					</div>
				))}
			</div>
			{sections?.length > 1 && <div onClick={handleBtnClick}
				className="d-flex gap-2 pointer new-mbp-link">
				<Typography variant="bodySmBold"
					semanticTag="span">
					{showMore ? t("read-less") : t("read-more")}
				</Typography>
				<Image src={"/assets/icons/chevron-down-golden.svg"}
					height={6.5}
					width={12}
					alt="down"
					style={{transform: showMore ? "rotate(180deg)" : ""}}
				/>
			</div>}
		</div>
	)
}

export default BikeInfoAndOverview

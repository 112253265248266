import React, {useContext, useEffect} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import CheckoutContext from "../../context/CheckoutContext"
import {customPlanLengthMap} from "./plan/customPlanLengthMap"
import useDiscount from "../../hooks/useDiscount"

type Props = {
    small?: boolean;
    isSecondHand?: boolean;
    product: any
}

const BikePrice: React.FC<Props> = ({
	small = false,
	isSecondHand = false,
	product
}) => {
	const {t} = useTranslation()
	const {data, setData} = useContext(CheckoutContext)
	const checkoutData = data
	const {onSale, finalPriceWithDownPayment, downPayment, discount} = useDiscount(product)

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = data?.planLength ? planLengthMap ? planLengthMap[data?.planLength] : data?.planLength : 48

	useEffect(() => {
		if (isSecondHand && planLength > 12) {
			setData(prevData => {
				return ({
					...prevData,
					planLength: 12
				})
			})
		}
	}, [])

	return (
		<div className={"col"}>
			<div className={` d-none monthly-price-value-${Math.ceil(finalPriceWithDownPayment / (planLength))}`}/>
			{(!isSecondHand && !onSale) && (<>
				<Typography variant={"bodyLgBold"}
					semanticTag="span">
                    CHF
				</Typography>
				<Typography variant={small ? "bodyLgBold" : "heading1"}
					className="ms-1"
					semanticTag="span"
					style={{fontSize: 36}}>
					{`${Math.ceil(finalPriceWithDownPayment / (planLength))}`}
				</Typography>
				{checkoutData?.planLength > 1 && <Typography variant={"bodyLgBold"}
					semanticTag="span"
					className="ms-1">
					{`${t("pro Monat")}`}
				</Typography>}
			</>)}

			{isSecondHand ? <>
				<Typography variant={"bodyLgBold"}
					semanticTag="span">
                    CHF
				</Typography>
				<Typography className={"text-decoration-line-through text-secondary"}
					variant={small ? "bodyLgBold" : "heading2"}
					semanticTag="span">
					{` ${Math.ceil((product.uvpPrice - downPayment) / (planLength))}`}
				</Typography>
				{checkoutData?.planLength > 1 && <Typography variant={small ? "bodySm" : "bodyLg"}
					semanticTag="span"
					className={"text-secondary text-decoration-line-through"}>
					{`${t("pro Monat")}`}
				</Typography>}
				<br/>
				<Typography variant={"bodyLgBold"}
					semanticTag="span">
                    CHF
				</Typography>
				<Typography style={{color: "#FC6471"}}
					variant={small ? "bodyLgBold" : "heading1"}
					semanticTag="span">
					{`${Math.ceil(product.displayPrice / (planLength))}`}
				</Typography>
				{checkoutData?.planLength > 1 && <Typography variant={small ? "bodySm" : "bodyLg"}
					semanticTag="span">
					{`${t("pro Monat")}`}
				</Typography>}
			</> : onSale && <div className={"d-flex align-items-end"}>
				<div className={"col-auto"}>
					<Typography className={"text-decoration-line-through text-secondary"}
						variant={"bodyLgBold"}
						semanticTag="span">
						{`CHF ${Math.ceil((product.uvpPrice - downPayment) / (planLength))} ${t("pro Monat")}`}
					</Typography>
					<br/>
					<Typography style={{color: "#FC6471"}}
						variant={"bodyLgBold"}
						semanticTag="span">
						CHF
					</Typography>
					<Typography style={{color: "#FC6471"}}
						variant={small ? "bodyLgBold" : "heading2Black"}
						semanticTag="span">
						{` ${Math.ceil(finalPriceWithDownPayment / (planLength))} `}
					</Typography>
					{checkoutData?.planLength > 1 && <Typography style={{color: "#FC6471"}}
						variant={small ? "bodySm" : "bodyLgBold"}
						semanticTag="span">
						{`${t("pro Monat")}`}
					</Typography>}
				</div>
				<div className={"col text-end"}>
					<Typography style={{color: "#FC6471"}}
						variant={"heading3Black"}
						semanticTag="span">
						{`-${discount}`}
					</Typography>
					<Typography style={{color: "#FC6471"}}
						variant={"bodyLgBold"}
						semanticTag="span">
						{" CHF"}
					</Typography>
				</div>
			</div>}
		</div>
	)
}

export default BikePrice

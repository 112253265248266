import useSWR from "swr"
// The url you pass to this hook is going to be the key to the cached value, so make it unique. Then the url will be passed to the fetcher function.
// If the request depends on some variables, pass them in the url and it will re-fetch on Changes
export default function useCache(url:string, revalidateOnFocus:boolean = false, revalidateIfStale:boolean = false, revalidateOnReconnect:boolean = false) {
	const fetcher = url => fetch(url).then(res => res.json())
	const {data, isLoading, error, mutate} = useSWR(url, fetcher, {
		// This configuration if all set to false, no extra requests will be sent. we will not revalidate if it was updated on the endpoints
		revalidateOnFocus,
		revalidateIfStale,
		revalidateOnReconnect})

	return {data, error, isLoading, mutate}
}

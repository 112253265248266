import React from "react"
import Togglelist from "./itsComponents/Togglelist"
import PropTypes from "prop-types"

type Props = {
  bgColor?: boolean;
  mostAskedOnly?: boolean
  isMiniFAQDesign?: boolean
}

const FAQ: React.FC<Props> = ({bgColor = false, mostAskedOnly, isMiniFAQDesign}) => {
	return (
		<section className={`${bgColor ? "bg-grey" : "bg-white"} ${isMiniFAQDesign ? "" : "container pt-5 mt-3 pb-5"}`}>
			<div className="row justify-content-center">
				<Togglelist mostAskedOnly={mostAskedOnly}
					isMiniFAQDesign={isMiniFAQDesign}
				/>
				{/* <PicSection/> */}
			</div>
		</section>
	)
}

FAQ.propTypes = {
	bgColor: PropTypes.bool
}

export default FAQ

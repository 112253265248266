export const getProductQuestionRoute = (bikeSlug: string, locale: string) => {
	return `${process.env.NEXT_PUBLIC_APP_URL}/api/firestore/getProductQuestions?bikeSlug=${bikeSlug}&locale=${locale}`
}

export const getProductQuestionRouteAdmin = (locale: string, idToken: string) => {
	return `${process.env.NEXT_PUBLIC_APP_URL}/api/firestore/admin/getProductQuestions?locale=${locale}&idToken=${idToken}`
}

export const getSponsorMeListRouteAdmin = (idToken: string) => {
	return `${process.env.NEXT_PUBLIC_APP_URL}/api/firestore/admin/listSponsorMe?idToken=${idToken}`
}

export const getSponsorMePaymentsListRouteAdmin = (idToken: string) => {
	return `${process.env.NEXT_PUBLIC_APP_URL}/api/firestore/admin/listSponsorMePayments?idToken=${idToken}`
}

export const getCheckoutsRouteAdmin = (locale: string, idToken: string) => {
	return `${process.env.NEXT_PUBLIC_APP_URL}/api/firestore/admin/getCheckouts?idToken=${idToken}`
}

export enum FirestoreRoutes {
    postProductQuestion = "/api/firestore/postProductQuestion",
	postProductQuestionAnswer = "/api/firestore/admin/updateProductQuestion",
}

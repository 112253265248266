import React from "react"
import BikeSuggest from "../reusable/BikeSuggest"
import {useTranslation} from "next-i18next"
import {createFaqSlugs, filterFaqByCategory, getFaqCategory} from "../../utility/Helper"
import FaqTabs from "./FaqTabs"
import {useRouter} from "next/router"
import VideoAskSection from "../reusable/VideoAskSection"

const FaqContent = () => {
	const {t} = useTranslation()
	const router = useRouter()
	const {tab} = router.query

	const data = createFaqSlugs(router.locale)
	const filteredData = filterFaqByCategory(data, tab)
	const tabs = getFaqCategory(router.locale)

	return (
		<section className="pt-5">
			<FaqTabs tabs={tabs}
				faqData={filteredData}/>
			<VideoAskSection/>
			<BikeSuggest t={t}/>
		</section>
	)
}

export default FaqContent

export const getFaqData = locale => {
	return require(`../../public/content/faq/${locale}/content.json`)
}


import React, {useContext, useState} from "react"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import PropTypes from "prop-types"
import styles from "./bikeDetails.module.scss"
import PDPTooltip from "./PDPTooltip"
import {ModalContext} from "../../context/ModalContext"
import ConsultBookingModalContent from "../reusable/ModalsContent/ConsultBookingModalContent"
import SocialProof from "../reusable/SocialProof"
import {BikeType} from "../../utility/Types"
import Link from "next/link"

type Props = {
	setAdditionalInfoDrawer: (state: boolean) => void
	bike:BikeType
    hasQuestionBtn?: boolean
}

const InfoBox: React.FC<Props> = ({setAdditionalInfoDrawer, bike, hasQuestionBtn}) => {
	const {t} = useTranslation("productDescrPage")
	const {t: tCommon} = useTranslation()
	const {setModalContent, openModal, closeModal} = useContext(ModalContext)
	const [isOpen, setIsOpen] = useState(false)
	const [activeTooltip, setActiveTooltip] = useState("")

	const handleTooltipClick = label => {
		setIsOpen(!isOpen)
		setActiveTooltip(label)
	}

	return (
		<>
			<div className={"col-12"}>
				<div className={"border-top mb-4"}/>
			</div>
			<div className={"px-md-3 pt-sm-4 pb-sm-3 pt-md-0 rounded mb-2"}>
				<div className="row px-3 justify-content-between gap-2 ">
					<div className={`col-12 px-0 d-flex justify-content-between align-items-center
                         mb-1 ${isOpen && activeTooltip === "service-shipping" && "position-relative"}`}>
						<div className="d-flex align-items-center ">
							<div className="d-flex">
								<Image className="cursor-pointer d-flex"
									src="/assets/icons/usp-delivery.svg"
									alt="car icon"
									width={24}
									height={24} />
							</div>
							<div className="d-flex ms-3  align-items-center gap-3 justify-content-evenly">
								<Typography variant="bodySm"
									semanticTag="span">{t("free-delivery")} </Typography>
							</div>
						</div>
						<Image
							className="cursor-pointer d-block"
							onClick={() => handleTooltipClick("service-shipping")}
							src={
								isOpen && activeTooltip === "service-shipping" ?
									process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan" ?
										"/assets/icons/info-active-brown.svg" :
										"/assets/icons/info-active.svg" :
									"/assets/icons/info-transparent.svg"
							}
							alt="tools icon"
							width={24}
							height={24}
						/>
					</div>
					<div className="col-12 px-0 mb-1">
						<div className="d-flex  align-items-center ">
							<div className="col-9 d-flex  align-items-center">
								<div className="col-auto me-3 d-flex ">
									<Image className="cursor-pointer"
										src="/assets/icons/usp-return.svg"
										alt="tools icon"
										width={24}
										height={24}/>
								</div>
								<div className="col-12 d-none  align-items-center">
									<Image className="cursor-pointer"
										src="/assets/icons/usp-return.svg"
										alt="tools icon"
										width={40}
										height={40}/>
								</div>
								<div className="col-12 ">
									<Typography variant="bodySm"
										semanticTag="span">{t("cancel-and-exchange-policy")} </Typography>
								</div>
							</div>
						</div>
					</div>
					<div className={`col-12 px-0 mb-1
                        ${isOpen && activeTooltip === "guarantee" && "position-relative"}`}>
						<div className=" d-flex  gx-3 align-items-center  justify-content-between ">
							<div className="col-10 mb-2 d-flex  align-items-center">
								<div className="col-auto me-3 d-flex align-items-center ">
									<Image className="cursor-pointer"
										src="/assets/icons/usp-service.svg"
										alt="tools icon"
										width={24}
										height={24}/>
								</div>
								<div className="col-12 d-none  align-items-center">
									<Image className="cursor-pointer"
										src="/assets/icons/usp-service.svg"
										alt="tools icon"
										width={40}
										height={40}/>
								</div>
								<div className="col-12 ">
									<Typography variant="bodySm"
										semanticTag="span">{t("guarantee-and-service-stores")} </Typography>
								</div>
							</div>
							{isOpen && activeTooltip === "guarantee" ?
								<Image className="cursor-pointer col-1"
									onClick={() => handleTooltipClick("guarantee")}
									src={"/assets/icons/info-active.svg"}
									alt="tools icon"
									width={24}
									height={24}/> :
								<Image className="cursor-pointer col-2"
									onClick={() => handleTooltipClick("guarantee")}
									src={"/assets/icons/info-transparent.svg"}
									alt="tools icon"
									width={24}
									height={24}/>
							}
						</div>

					</div>
					<Link href="/service-centers"
						className="col-12 px-0 mb-1">
						<div className="d-flex gx-0 align-items-center justify-content-between">
							<div className="col-9 mb-2 d-flex   align-items-center ">
								<div className="col-auto me-3 d-flex align-items-center ">
									<Image className="cursor-pointer"
										src="/assets/icons/service-partners.svg"
										alt="tools icon"
										width={24}
										height={24}/>
								</div>
								<div className="col-12 d-none  align-items-center">
									<Image className="cursor-pointer"
										src="/assets/icons/service-partners.svg"
										alt="tools icon"
										width={40}
										height={40}/>
								</div>
								<div className="col-12 ">
									<Typography variant="bodySm"
										semanticTag="span">{t("access-our-service-partners")} </Typography>
								</div>
							</div>
						</div>
					</Link>
					<div className="d-flex px-0 align-items-center justify-content-between col-12">
						<div className="d-flex align-items-center justify-content-start gap-3">
							<Image className="cursor-pointer"
								src="/assets/icons/checkmark-emoji.svg"
								alt="tools icon"
								width={24}
								height={24} />
							<Typography variant="bodySm"
								semanticTag="span">{t("best-monthly-price-label", {ns: "common"})} </Typography>
						</div>
						{isOpen && activeTooltip === "best-monthly-price" ?
							<Image className="cursor-pointer col-1"
								onClick={() => handleTooltipClick("best-monthly-price")}
								src={"/assets/icons/info-active.svg"}
								alt="tools icon"
								width={24}
								height={24}/> :
							<Image className="cursor-pointer col-2"
								onClick={() => handleTooltipClick("best-monthly-price")}
								src={"/assets/icons/info-transparent.svg"}
								alt="tools icon"
								width={24}
								height={24}/>
						}
					</div>
					<div className="position-relative">
						<PDPTooltip isOpen={isOpen}
							setIsOpen={setIsOpen}
							desktop
							label={activeTooltip}/>
					</div>
					<SocialProof isPDP
						isBootstrapAlertElement={false}
						className="px-1 col-12"/>
					{hasQuestionBtn &&
				<button
					className={`d-flex align-items-center justify-content-between reset border-0 mt-3 py-3 px-2 col-12  
                ${styles.infoBoxQuestionButton}`}
					onClick={() => {
						setModalContent(<ConsultBookingModalContent closeModal={closeModal}/>)
						openModal()
					}}>
					<div className="d-flex">
						<div className="d-flex ">
							<Image src={"/assets/icons/person.svg"}
								layout={"intrinsic"}
								objectFit={"contain"}
								width={20}
								height={20}/>
						</div>
						<Typography className="ms-3"
							variant="bodySmBold">
							{`${bike?.categories?.some(cat => cat.slug.includes("e-bikes")) ?
								t("have-a-question").replace("bike", "E-bike") :
								bike?.categories?.some(cat => cat.slug.includes("mountain-fully") ||
                                cat.slug.includes("fully-velo")) ? t("have-a-question").replace("bike", "Velo") :
									t("have-a-question").replace("bike", "Accessoire")}`}
						</Typography>
					</div>
					<Image className="cursor-pointer col-2"
						style={{transform: "rotate(90deg)"}}
						src={"/assets/icons/chevron-up-icon.svg"}
						alt="arrow icon"
						width={14}
						height={14}/>
				</button>}
				</div>
			</div>
		</>
	)
}

InfoBox.propTypes = {
	setAdditionalInfoDrawer: PropTypes.func.isRequired
}

export default InfoBox

import Image from "next/image"
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {onlyAllowedLinksForAi} from "../../../constants"
import {clarityEvents, gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {useRouter} from "next/router"
import AnalyticsContext from "../../../context/AnalyticsContext"
import Button from "../../reusable/components/Button"
import {ArrowRight, Stars, X} from "react-bootstrap-icons"
import Link from "next/link"
import Spinner from "../../reusable/Spinner"
import LayoutContext from "../../../context/LayoutContext"
import {storeDataToSpreadsheet} from "../../../utility/Helper"

type Props = {
	askedQuestion?: string,
	customLayoutClassname?: string,
	isPlpStaticTile?: boolean
	isEmptyPage?: boolean
	customPredefinedQuestions?: string[]
	setBikeRecommendations?:Dispatch<SetStateAction<any[]>>
}

function RyderAiStaticTile({askedQuestion, customLayoutClassname = null, isPlpStaticTile = false, isEmptyPage = false, customPredefinedQuestions = [], setBikeRecommendations}:
    Props) {
	const [input, setInput] = useState(askedQuestion ? askedQuestion : "")
	const [loading, setLoading] = useState(false)
	const [result, setResult] = useState<string>("")
	const textArea = useRef(null)
	const router = useRouter()
	const {t} = useTranslation(["common"])
	const [link, setLink] = useState<string | undefined>()
	const {reactGA} = useContext(AnalyticsContext)
	const {isMobile} = useContext(LayoutContext)
	const handleSubmit = async (e, text = null) => {
		e?.preventDefault()
		setLoading(true)

		if (typeof window !== "undefined") {
			window.clarity("set", clarityEvents.askedAi, "User Asked PDP AI")
		}

		reactGA?.event({
			category: gaCategories.pdp,
			action: `${gaEvents.askedPdpAi} ${input}`,
			label: `${gaEvents.askedPdpAi} ${input}`,
			nonInteraction: false
		})

		const updatedMessages = text ? text : input

		try {
			const res = await fetch(isEmptyPage ?
				`/api/ai/chat/mbp-recommendation-ai?locale=${router.locale || "de"}` :
				`/api/ai/chat/mbp-assistant?locale=${router.locale || "de"}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					description: updatedMessages
				})
			})

			const data = await res.json()
			if (isEmptyPage && data?.isBikeRecommendations && typeof setBikeRecommendations !== "undefined") {
				setBikeRecommendations(() => data.response)
				setResult(() => "")
			} else {
				if (typeof setBikeRecommendations !== "undefined") {
					setBikeRecommendations(() => [])
				}

				await storeDataToSpreadsheet({
					question: `${text ? text : input}`,
					answer: data.response,
					url: `${process.env.NEXT_PUBLIC_APP_URL}${router.asPath}`
				}, "staticTileAi")
				setResult(() => data.response)
			}

			reactGA?.event({
				category: gaCategories.pdp,
				action: `${gaEvents.AiAnswer} ${data.response} To the question ${input}`,
				label: `${gaEvents.AiAnswer} ${data.response} To the question ${input}`,
				nonInteraction: false
			})
		} catch (error) {
			console.error("Error communicating with the API:", error)
		}

		setLoading(false)
	}

	useEffect(() => {
		if (result) {
			for (const aiLink of onlyAllowedLinksForAi) {
				if (result.includes(aiLink)) {
					setLink(() => aiLink)
				}
			}
		}
	}, [result])

	const handleAskAnotherQuestion = () => {
		setInput("")
		setResult("")
		if (typeof setBikeRecommendations !== "undefined") {
			setBikeRecommendations(() => [])
		}

		textArea.current.focus()
	}

	useEffect(() => {
		if (askedQuestion && input) {
			handleSubmit(null, askedQuestion)
		}
	}, [askedQuestion])

	const predefinedQuestions = customPredefinedQuestions?.length ? customPredefinedQuestions : [
		"plp-ai-predefined-question-1",
		"plp-ai-predefined-question-2",
		"plp-ai-predefined-question-3",
		"plp-ai-predefined-question-4"
	]
	return (
		<div style={{
			padding: isMobile ?
				"1rem 0rem" :
				"1rem"
		}}
		className={customLayoutClassname ?
			customLayoutClassname :
			"d-flex align-items-start justify-content-center gap-4 col-12 h-100 flex-column"}>
			<div className="d-flex align-items-center justify-content-start gap-1 col-12">
				<Image src={"/assets/icons/ryder-logo-primary.svg"}
					height={24}
					width={24} />
				<Typography style={{fontSize: "14px", lineHeight: "20px"}}
					variant="bodySmBold">{t("ask-ai-about-bike-label")}</Typography>
			</div>
			<div style={{gap: "8px", maxWidth: "100%", overflowX: "auto"}}
				className={`d-flex hide-overflow-scrollbar align-items-start justify-content-start flex-md-wrap ${isEmptyPage ? "flex-column my-2 col-12" : "my-1"}`}>
				{predefinedQuestions.map((question, index) => <div key={index}
					onClick={e => {
						setInput(t(question))
						handleSubmit(e, t(question))
					}}
					style={{width: isEmptyPage ? "100%" : "auto"}}
					className="predefined-ai-question">
					<Typography style={{fontSize: "14px", lineHeight: "20px", color: "#750F9B", whiteSpace: "wrap"}}
						variant="bodySm">{t(question)}</Typography>
				</div>)}
			</div>
			<form className="send-message mx-auto"
				onSubmit={handleSubmit}>
				<input
					ref={textArea}
					style={{textOverflow: "ellipsis"}}
					className="input-field"
					value={input}
					onChange={e => setInput(e.target.value)}
					placeholder={t("ask-ai-about-us")}
					required />
				<Button type="submit"
					bgColor={"#A219D4"}
					style={{maxWidth: "20px", height: "100%"}}
					className="try-ai position-relative"
					fontSizeSmall
					icon={<ArrowRight size={24}
						color="#fff" />} />
				{loading ? <Spinner style={{position: "absolute", right: "50%", top: "120%"}}/> :
					result?.length ? <div style={{top: "135%", right: "0px", border: "1px solid #ececec"}}
						className={"ai-response-pdp translate-y position-absolute  slide-in-top"}>
						<div style={{minWidth: isPlpStaticTile && !isMobile ? "30rem" : "none"}}
							className="d-flex align-items-center col-12 position-relative justify-content-start gap-1 mt-2"><Stars />
							<Typography style={{fontSize: "14px", lineHeight: "20px"}}
								variant="bodySmBold">{t("ai-answer-label")}</Typography>
							<X
								onClick={handleAskAnotherQuestion}
								size={24}
								className="position-absolute end-0"/></div>
						{result.includes("https://") && link ?
							<Typography variant="bodySm">{result.slice(0, result.indexOf("https://"))}
								<Link className="cursor-pointer text-primary"
									href={link ? link : "/#"}>
									{link}
								</Link>
								{result.slice(result.indexOf(" ", result.indexOf("https://")))} </Typography> :
							<Typography variant="bodySm">{result} </Typography>}
						<Typography style={{fontSize: "14px", lineHeight: "18px"}}
							variant="bodySmBold">{t("sincerely")} <br /> Ryder </Typography>
						<Typography onClick={handleAskAnotherQuestion}
							className="mt-2 cursor-pointer"
							style={{fontSize: "14px", lineHeight: "20px", color: "#C537F8"}}
							variant="bodySmBold">{t("ask-another-question-label")}</Typography>
					</div> : null}
			</form>

		</div>
	)
}

export default RyderAiStaticTile

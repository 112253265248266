import React, {useContext} from "react"
import CheckoutContext from "../../../context/CheckoutContext"
import {useTranslation} from "next-i18next"
import priceFormatter from "../../../utility/priceFormatter"
import Typography from "../../reusable/Typography/Typography"
import {formatPrice} from "../../../utility/Helper"

type Props = {
	price: number
	onSale?: boolean,
	listView?: boolean
	isSecondHand?: boolean
	secondHandPrice?: number
	isAccessoryPrice?: boolean
	priceVariant?: any
	discountedPrice?: number
	isSmallProductCard?: boolean
	isListViewOnMobile?:boolean
}

const TotalPrice: React.FC<Props> = ({
	price,
	discountedPrice,
	onSale,
	isAccessoryPrice,
	listView,
	isSecondHand,
	secondHandPrice,
	isSmallProductCard,
	priceVariant = "bodyLg",
	isListViewOnMobile
}) => {
	const {data} = useContext(CheckoutContext)
	const {t} = useTranslation()
	const _secondHandPrice = priceFormatter(secondHandPrice)

	return (
		listView ?
			<div className={"row text-secondary"}>
				<div className={"col-12 justify-content-start align-items-center d-flex"}>
					<Typography variant={(discountedPrice) ? "bodyXSmBoldMdBodySmBold" : priceVariant}
						semanticTag="div"
						className={"me-1"}
						style={{color: onSale || isSecondHand ? "#E61C45" : "", fontSize: 12}}>
						{t("Gesamtpreis")}:
					</Typography>
					{onSale ? <>
						<div className={" text-end  d-none d-md-flex flex-wrap"}>
							<Typography variant={priceVariant}
								semanticTag="div"
								style={{color: "#E61C45", fontSize: 12}}>
								{` ${formatPrice(discountedPrice)}`}
							</Typography>
							<Typography style={{fontSize: 12}}
								variant={priceVariant}
								semanticTag="div"
								className={"text-decoration-line-through ms-2"}>
								{`${formatPrice(price as number)}`}
							</Typography>
						</div>
						<div className={"col-auto text-end d-md-none"}>
							<Typography style={{fontSize: 12}}
								variant={priceVariant}
								semanticTag="div"
								className={"text-decoration-line-through ms-2"}>
								{` ${formatPrice(price as number)}`}
							</Typography>
							<Typography variant={priceVariant}
								semanticTag="div"
								style={{color: "#E61C45", fontSize: 12}}>
								{` ${formatPrice(discountedPrice)}`}
							</Typography>
						</div>
					</> :
						isSecondHand ? <div className={"col-auto text-end d-md-flex"}>
							<Typography variant={priceVariant}
								semanticTag="div"
								className={"d-md-none text-decoration-line-through ms-2"}>
								{`CHF ${price}`}
							</Typography>
							<Typography variant={priceVariant}
								semanticTag="div"
								style={{color: "#E61C45", fontSize: 12}}>
								{`${t(" CHF")} ${_secondHandPrice}`}
							</Typography>
							<Typography variant={priceVariant}
								semanticTag="div"
								className={"d-none d-md-block text-decoration-line-through ms-2"}>
								{`CHF ${price}`}
							</Typography>
						</div> :
							<Typography style={{fontSize: 12}}
								variant={priceVariant}
								semanticTag="div"
								className={"col-auto"}>
								{formatPrice(price)}
							</Typography>}
				</div>
			</div> :
			<div className={`row justify-content-between align-items-end ${isAccessoryPrice ? "mt-0" : "mt-2"} mt-md-1  d-flex`}
				style={{color: "#6C7074", fontSize: 12}}>
				{!isSecondHand && !isAccessoryPrice && !isSmallProductCard && <Typography style={{fontSize: 12}}
					variant={priceVariant}
					semanticTag="div"
					className={"col"}>
					{`${(data?.planLength && data?.planLength >= 12) ? data.planLength : 48} ${t("Monate")}`}
				</Typography>}

				{isSecondHand && !isAccessoryPrice && !isSmallProductCard && <Typography style={{fontSize: 12}}
					variant={priceVariant}
					semanticTag="div"
					className={"col"}>
					{`${(data?.planLength && data?.planLength <= 12) ? data.planLength : 12} ${t("Monate")}`}
				</Typography>}

				{onSale ? <div className={"col-auto text-end"}>
					<Typography style={{fontSize: 12}}
						variant={priceVariant}
						semanticTag="div"
						className={"text-decoration-line-through"}>
						{` ${formatPrice(price)}`}
					</Typography>
					<Typography style={{fontSize: 12}}
						variant={isAccessoryPrice ? "bodyXSm" : priceVariant}
						semanticTag="div">
						{`${t("Gesamtpreis CHF")} ${(discountedPrice)}`}
					</Typography>
				</div> :
					isSecondHand ? <div className={"col-auto text-end"}>
						<Typography style={{fontSize: 12}}
							variant={priceVariant}
							semanticTag="div"
							className={"text-decoration-line-through"}>
							{formatPrice(price)}
						</Typography>
						<Typography style={{fontSize: 12}}
							variant={isAccessoryPrice ? "bodyXSm" : priceVariant}
							semanticTag="div">
							{`${t("Gesamtpreis CHF")} ${_secondHandPrice}`}
						</Typography>
					</div> :
						<Typography style={{fontSize: 12}}
							variant={isAccessoryPrice ? "bodyXSm" : priceVariant}
							semanticTag="span"
							className={"col-auto"}>
							{t("Gesamtpreis")}:	{`${t("CHF")} ${price}`}
						</Typography>
				}
			</div>
	)
}

export default TotalPrice

import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import DataContext from "../../../context/DataContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {useRouter} from "next/router"
import AnalyticsContext from "../../../context/AnalyticsContext"

type Props = {
  slug: string
	bgColor?: any
	textBold?: boolean
	borderRadius?: string
	paddingY?: string
	isPDPcomparisonBtn?: boolean
    textVariant?: any
}

enum pagePathname {
  productPage = "produkt",
  landingPage = "/"
}

const ComparisonCheckboxButtonPdp: React.FC<Props> =
({
	isPDPcomparisonBtn,
	borderRadius,
	textVariant = "bodyLgBold",
	textBold,
	paddingY,
	bgColor,
	slug
}) => {
	const {t} = useTranslation()
	const {comparisonProducts, setComparisonProducts} = useContext(DataContext)
	const router = useRouter()
	const {pathname} = router

	const gaCategory = pathname.includes(pagePathname.productPage) ?
		"pdp" : pathname === pagePathname.landingPage ? "homePage" : "plp"
	const {reactGA} = useContext(AnalyticsContext)

	const onChangeComparisonProducts = (slug: string, e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
		if (!comparisonProducts.includes(slug)) {
			setComparisonProducts([slug, ...comparisonProducts])
			reactGA?.event({
				category: gaCategories[gaCategory],
				action: gaEvents.addProductToComparison,
				label: gaEvents.addProductToComparison,
				nonInteraction: false
			})
		}

		if (comparisonProducts.includes(slug)) {
			comparisonProducts.splice(comparisonProducts.indexOf(slug), 1)
			setComparisonProducts([...comparisonProducts])
			reactGA?.event({
				category: gaCategories[gaCategory],
				action: gaEvents.removeProductFromComparison,
				label: gaEvents.removeProductFromComparison,
				nonInteraction: false
			})
		}
	}

	const isActive = comparisonProducts.includes(slug)

	return (
		<>
			{!isPDPcomparisonBtn &&
			<div className="col-12 d-flex d-block d-md-none align-items-center comparison-btn-mobile-not-absolute"
				style={{backgroundColor: isActive ? "#DCE1E7" : ""}}
				onClick={e => onChangeComparisonProducts(slug, e)}>
				<div className={"row g-2 d-flex align-items-center"}>
					<img className="col-auto"
						src="/assets/icons/compare-bikes-icon.svg"
						alt="icon"
						width={20}
						height={20}/>
					<div className={"col-auto "}>
						<Typography
							variant="bodySm"
							semanticTag="span">
							{t("compare")}
						</Typography>
					</div>
				</div>
			</div>}
			<div className={`col-auto ${!isPDPcomparisonBtn && "d-none d-md-flex"} 
            px-2 align-items-center ${paddingY ? paddingY : "py-1"} compare-btn-not-absolute`}
			style={{backgroundColor: isActive ? "#DCE1E7" : bgColor,
				cursor: "pointer",
				borderRadius: borderRadius ? borderRadius : 4}}
			onClick={e => onChangeComparisonProducts(slug, e)}>
				<div className={"d-flex align-items-center"}>
					<img src="/assets/icons/compare-bikes-icon.svg"
						alt="icon"
						width={20}
						height={20} />
					<Typography className="ms-2 ms-md-3"
						variant={textVariant}
						semanticTag="span">
						{t("compare")}
					</Typography>
				</div>
			</div>
		</>
	)
}

export default ComparisonCheckboxButtonPdp

import React, {useContext} from "react"
import ComparisonPLPFixedComponent from "../../bikesCollection/ComparisonPLPFixedComponent"
import BikesCollectionItem from "../../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import Button from "../../reusable/components/Button"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import NoBikesState from "../../bikesCollection/NoBikesState"
import {getTileArrToShow} from "../../bikesCollection/StaticTile"
import Spinner from "../../reusable/Spinner"
import DataContext from "../../../context/DataContext"
import {formatImageSrc} from "../../../strapi/strapiHelpers"
import {isImage} from "../../story/StoryVideoContainer"
import Image from "next/image"
import LayoutContext from "../../../context/LayoutContext"

type Props = {
  bikesList: any[];
  useLazyLoading?: boolean;
	doneFetchingProducts?: boolean;
	isLoadingMoreProducts?:boolean
	triggerFetchMore?: () => void
	showOverflow?: boolean
	isMobile?:boolean
}

const RenderBikesGrid = React.forwardRef<HTMLDivElement, Props>(({
	bikesList, doneFetchingProducts, isLoadingMoreProducts, triggerFetchMore, showOverflow = true, useLazyLoading = false, isMobile = false
}, forwardedRef) => {
	const router = useRouter()
	const isBrandWorld = router.pathname.includes("/brand-world")
	const tileArrayLength = getTileArrToShow(isMobile)?.length
	const {brandWorld} = useContext(DataContext)
	const {isMobile: mobileDevice} = useContext(LayoutContext)
	const {t} = useTranslation()
	return (
		<div style={{maxHeight: showOverflow ? "65dvh" : "none", overflowY: showOverflow ? "auto" : "hidden", overflowX: "hidden"}}
			className=" col-12 py-2">
			<div
				className={`${

					"similar-bikes-modal-content"

				}`}
			>
				{bikesList?.length ?
					bikesList.map((bike, idx) => {
						const isThirdLast = idx === bikesList.length - 2
						const isSixth = idx === 4
						const showBrandVideo = (isSixth && mobileDevice) || (!mobileDevice && isThirdLast)
						if (showBrandVideo && isBrandWorld) {
							const isVideo = !isImage(brandWorld?.lifestyleVideo?.data?.attributes?.url)
							return (
								bikesList?.length >= 1 ? <>{isVideo ? <video style={{height: mobileDevice ? "343px" : "100%", width: mobileDevice ? "343px" : null, gridColumn: mobileDevice ? "span 2" : null, objectFit: "cover", justifySelf: "center", borderRadius: "4px", margin: mobileDevice ? "1rem" : null}}
									src={formatImageSrc(brandWorld?.lifestyleVideo?.data?.attributes?.url)}
									width={isMobile ? 150 : "auto"}
									height={"auto"} /> : <img style={{height: mobileDevice ? "343px" : "100%", width: mobileDevice ? "343px" : null, gridColumn: mobileDevice ? "span 2" : null, objectFit: "cover", justifySelf: "center", borderRadius: "4px", margin: mobileDevice ? "1rem" : null}}
									src={formatImageSrc(brandWorld?.lifestyleVideo?.data?.attributes?.url)}
									width={isMobile ? 150 : 194}
									height={420} />} <BikesCollectionItem
									isIkeaDesign
									isGridBottomRightRounded={isBrandWorld ? false : isMobile && bikesList.length % 2 > 0 ? idx === bikesList.length - 2 || idx === bikesList.length - 1 : idx === bikesList.length - 1}
									isGridBottomLeftRounded={isBrandWorld ? false : isMobile ? bikesList.length % 2 > 0 ? idx === bikesList.length - 1 : idx === bikesList.length - 2 : idx === bikesList.length - 5 || idx === 0}
									isGridTopRightRounded={isBrandWorld ? false : isMobile ? idx === 1 : idx === 4 || idx === bikesList.length - 1}
									isGridTopLeftRounded={isBrandWorld ? false : idx === 0}
									key={idx}
									withOneImage
									cardWidth={isMobile ? "150px" : "190px"}
									hideComparison
									bike={bike}
									isTwoCardsView={true}
									isRowForMobile={true}
								/> </> : null
							)
						}

						return (
							bikesList?.length >= 1 ? <BikesCollectionItem
								isIkeaDesign
								isGridBottomRightRounded={isBrandWorld ? false : isMobile && bikesList.length % 2 > 0 ? idx === bikesList.length - 2 || idx === bikesList.length - 1 : idx === bikesList.length - 1}
								isGridBottomLeftRounded={isBrandWorld ? false : isMobile ? bikesList.length % 2 > 0 ? idx === bikesList.length - 1 : idx === bikesList.length - 2 : idx === bikesList.length - 5 || idx === 0}
								isGridTopRightRounded={isBrandWorld ? false : isMobile ? idx === 1 : idx === 4 || idx === bikesList.length - 1}
								isGridTopLeftRounded={isBrandWorld ? false : idx === 0}
								key={idx}
								withOneImage
								cardWidth={isMobile ? "150px" : "190px"}
								hideComparison
								bike={bike}
								isTwoCardsView={true}
								isRowForMobile={true}
							/> : null
						)
					}) : null }
			</div>
			{!bikesList?.length && !isLoadingMoreProducts ? <NoBikesState count={tileArrayLength} /> : null}
			<div className="d-md-none">
				<ComparisonPLPFixedComponent sticky={false} />
			</div>
			{useLazyLoading ? (<div ref={forwardedRef}
				className="d-flex py-4 justify-content-center align-items-center">
				{doneFetchingProducts ? null : <Button disabled={isLoadingMoreProducts}
					fontSizeSmall
					isLoading={isLoadingMoreProducts}
					label={t("load-more-products")}
					bgColor="#ffbf4d"
					onClick={triggerFetchMore} />}</div>
			) : null}
		</div>
	)
})

RenderBikesGrid.displayName = "RenderBikesGrid"

export default RenderBikesGrid

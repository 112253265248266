import {CartItem} from "../context/CartContext"
import {Attribute, BikeType, Category, Image, MetaData, ProductTypeGuard} from "./Types"

// eslint-disable-next-line
export function isCartProductType(obj: any): obj is ProductTypeGuard {
	return (
		(typeof obj.id === "number") &&
        (typeof obj.name === "string") &&
        (typeof obj.slug === "string") &&
        Array.isArray(obj.categories) && obj.categories.every(isCategory) &&
        (Array.isArray(obj.images)) &&
        obj.attributes && Array.isArray(obj.attributes) && obj.attributes.every(isAttribute) &&
        (typeof obj.stock_status === "string") &&

        (typeof obj.uvpPrice === "number" || typeof obj.uvpPrice === "string")
	)
}

// eslint-disable-next-line
export function isCartItem(obj: any): obj is CartItem {
	return (
		typeof obj === "object" &&
        isCartProductType(obj.product) &&
        typeof obj.selectedSize === "string" &&
        typeof obj.uniqueId === "string" &&
        typeof obj.selectedVariationId === "number" &&
        (typeof obj.downPayment === "number" || typeof obj.downPayment === "string" || typeof obj.downPayment === "undefined") &&
        (obj.initialPrice !== undefined || typeof obj.initialPrice === "number" || typeof obj.initialPrice === "string")
	)
}

export const cartTypeGuard = (cart: CartItem[]) => {
	const cartArray = []
	if (Array.isArray(cart)) {
		for (const cartItem of cart) {
			try {
				if (isCartItem(cartItem)) {
					cartArray.push(cartItem)
				}
			} catch (error) {
				console.error(error)
			}
		}
	}

	return cartArray
}

function logBikeType(bike: BikeType) {
	console.log("BikeType Properties:")
	console.log(`id: ${bike.id}`)
	console.log(`name: ${bike.name}`)
	console.log(`slug: ${bike.slug}`)
	console.log(`status: ${bike.status}`)
	console.log(`description: ${bike.description}`)
	console.log(`categories: ${bike.categories}`)
	console.log(`images: ${bike.images}`)
	console.log(`attributes: ${bike.attributes}`)
	console.log(`colorData: ${bike.colorData}`)
	console.log(`uvpPrice: ${bike.uvpPrice}`)
	console.log(`image: ${bike.image}`)
	console.log(`displayPrice: ${bike.displayPrice}`)
}

export function logCartItem(cartItem: CartItem) {
	console.log("CartItem Properties:")
	console.log(`selectedSize: ${cartItem.selectedSize}`)
	console.log(`uniqueId: ${cartItem.uniqueId}`)
	console.log(`selectedVariationId: ${cartItem.selectedVariationId}`)
	console.log(`downPayment: ${cartItem.downPayment}`)
	console.log(`initialPrice: ${cartItem.initialPrice}`)

	// Log the product (BikeType)
	if (cartItem.product) {
		logBikeType(cartItem.product)
	}
}

// eslint-disable-next-line
export function isBikeType(obj: any): obj is BikeType {
	return (
		(obj.id !== undefined && typeof obj.id === "number") &&
        (obj.name !== undefined && typeof obj.name === "string") &&
        (obj.slug !== undefined && typeof obj.slug === "string") &&
        (obj.status !== undefined && typeof obj.status === "string") &&
        (obj.description !== undefined && typeof obj.description === "string") &&
        (obj.short_description !== undefined && typeof obj.short_description === "string") &&
        ((obj.categories !== undefined && Array.isArray(obj.categories)) && obj.categories.every(isCategory)) &&
        ((obj.images !== undefined && Array.isArray(obj.images)) && obj.images.every(isImage)) &&
        ((obj.attributes !== undefined && Array.isArray(obj.attributes)) && obj.attributes.every(isAttribute)) &&
        ((obj.meta_data !== undefined && Array.isArray(obj.meta_data)) && obj.meta_data.every(isMetaData)) &&
        (obj.date_created !== undefined && typeof obj.date_created === "string") &&
        (obj.lang !== undefined && typeof obj.lang === "string") &&
        (obj.uvpPrice !== undefined && (typeof obj.uvpPrice === "number" || typeof obj.uvpPrice === "string")) &&
        (obj.displayPrice !== undefined && (typeof obj.displayPrice === "number" || typeof obj.displayPrice === "string")) &&
        ((obj.crossSellProducts !== undefined && Array.isArray(obj.crossSellProducts))) &&
        ((obj.staticVariations !== undefined && Array.isArray(obj.staticVariations)))
	)
}

// Supporting type guards
function isCategory(obj: any): obj is Category {
	return typeof obj.name === "string" && typeof obj.slug === "string" && typeof obj.id === "number"
}

function isImage(obj: any): obj is Image {
	return typeof obj.id === "number" && typeof obj.src === "string" && typeof obj.name === "string" && typeof obj.alt === "string" && typeof obj.description === "string"
}

function isAttribute(obj: any): obj is Attribute {
	return typeof obj.id === "number" &&
        typeof obj.name === "string" &&
        typeof obj.slug === "string" &&
        Array.isArray(obj.terms) && obj.terms.every(isCategory)
}

function isMetaData(obj: any): obj is MetaData {
	return typeof obj.value === "string" && typeof obj.key === "string" && typeof obj.id === "number"
}

export const guardRecentlyViewedBikesType = RecentlyViewedProducts => {
	try {
		if (RecentlyViewedProducts?.length > 0 && isRecommendedBikeType(RecentlyViewedProducts[0])) {
			const recentlyViewedArray = []
			for (const recentlyViewedBike of RecentlyViewedProducts) {
				if (isRecommendedBikeType(recentlyViewedBike)) {
					recentlyViewedArray.push(recentlyViewedBike)
				}
			}

			if (recentlyViewedArray?.length !== RecentlyViewedProducts?.length) {
				localStorage.setItem("recentlyViewedBikes", JSON.stringify(recentlyViewedArray))
				window.dispatchEvent(new Event("RecentlyViewedUpdated"))
			}

			return recentlyViewedArray
		}

		// LocalStorage.removeItem("recentlyViewedBikes")

		window.dispatchEvent(new Event("RecentlyViewedUpdated"))

		return []
	} catch (error) {
		console.error(error)
		localStorage.removeItem("recentlyViewedBikes")
		window.dispatchEvent(new Event("RecentlyViewedUpdated"))

		return []
	}
}

export const guardRecommendedBikesType = recommendedProducts => {
	try {
		if (recommendedProducts?.length > 0 && isRecommendedBikeType(recommendedProducts[0])) {
			const recommendationsArray = []
			for (const recentlyViewedBike of recommendedProducts) {
				if (isRecommendedBikeType(recentlyViewedBike)) {
					recommendationsArray.push(recentlyViewedBike)
				}
			}

			return recommendationsArray
		}

		return []
	} catch (error) {
		console.error(error)

		return []
	}
}
// eslint-disable-next-line
export function isRecommendedBikeType(obj: any): obj is BikeType {
	return (
		(obj.id !== undefined && typeof obj.id === "number") &&
        (obj.name !== undefined && typeof obj.name === "string") &&
        (obj.slug !== undefined && typeof obj.slug === "string") &&
        (obj.status !== undefined && typeof obj.status === "string") &&
        ((obj.categories !== undefined && Array.isArray(obj.categories)) && obj.categories.every(isCategory)) &&
        ((obj.images !== undefined && Array.isArray(obj.images)) && obj.images.every(isImage)) &&
        ((obj.attributes !== undefined && Array.isArray(obj.attributes)) && obj.attributes.every(isAttribute)) &&
        (obj.lang !== undefined && typeof obj.lang === "string") &&
        (obj.uvpPrice !== undefined && (typeof obj.uvpPrice === "number" || typeof obj.uvpPrice === "string")) &&
        (obj.displayPrice !== undefined && (typeof obj.displayPrice === "number" || typeof obj.displayPrice === "string"))
	)
}

import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import SearchDesktop from "../search/SearchDesktop"
import {XLg} from "react-bootstrap-icons"
import Image from "next/image"
import SearchResults from "../search/SearchResults"
import SearchContext from "../../context/SearchContext"
import {onMegaMenuBtnClick} from "../navigation/mobile/NavigationTabs"
import {useRouter} from "next/router"
import SearchContent from "../search/SearchContent"
import MyBikePlanAI from "./NavBarAI"

type Props = {
	isMegaMenu?: boolean
	hideAi?: boolean
	hideNavIcon?:boolean
	isBrandsPage?: boolean
	setIsMenuVisible?:Dispatch<SetStateAction<boolean>>
}
function Search({isMegaMenu = false, hideAi = false, hideNavIcon = false, isBrandsPage = false}:Props) {
	const router = useRouter()
	const {pathname} = router
	const [isBackgroundOn, setIsBackgroundOn] = useState(false)
	const {value} = useContext(SearchContext)
	const isMenuVisible = router?.query?.megamenu === "open"
	const toggleMenu = e => {
		e.stopPropagation()
		onMegaMenuBtnClick(router, pathname)
	}

	useEffect(() => {
		if (!isBackgroundOn || !isMenuVisible) {
			document.body.style.overflow = "auto"
		} else if (isBackgroundOn) {
			document.body.style.overflow = "hidden"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [isBackgroundOn, isMenuVisible])

	return (
		<div style={{borderRadius: "0px 0px 16px 16px"}}
			className={isBrandsPage ? "col-12 py-2" : "container d-md-none pt-2 pb-2 px-3"}>
			<div className="row justify-content-center">
				<div style={{minHeight: "fit-content"}}
					className="col-12">
					<div className={`d-flex gap-3 ${isMegaMenu ?
						"flex-column align-items-start" : "align-items-center"}`}>
						{hideNavIcon ? null : <div
							onClick={toggleMenu}
							className={`d-flex align-items-center p-2 pe-0 cursor-pointer ${isMegaMenu ?
								"d-none" : ""}`}
						>
							{isMenuVisible ? (
								<XLg color={"#000"}
									size={24} />
							) : (
								<Image width={24}
									height={24}
									src={"/assets/icons/burger-menu.svg"} />
							)}
						</div>}
						<div style={{flex: isMegaMenu ? "" : 1}}
							className={isMegaMenu ? "col-12" : ""}>
							<SearchDesktop
								isBrandsPage={isBrandsPage}
								setIsBackgroundOn={setIsBackgroundOn}
								isBackgroundOn={isBackgroundOn}
							/>
						</div>
						{JSON.parse(process.env.NEXT_PUBLIC_AI_IS_ACTIVATED || "false") && !hideAi ? <MyBikePlanAI /> : null}

					</div>
				</div>
				<SearchResults setIsBackgroundOn={setIsBackgroundOn} />
				{(isBackgroundOn && !value?.length) || value?.length < 3 ? (
					<div className="d-md-none">
						<SearchContent
							isBackgroundOn={isBackgroundOn}
							setIsBackgroundOn={setIsBackgroundOn} />
					</div>
				) : null}
			</div>
		</div>
	)
}

export default Search

import React, {useContext} from "react"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import Button from "../reusable/components/Button"
import Typography from "../reusable/Typography/Typography"
import styles from "./navigation.module.scss"
import Image from "next/image"

type Props = {
  onlyIcon?: boolean
	onlyText?: boolean
	textVariant?: any
	classes?: any
	withTextAndArrow?: boolean
	isBrandsPage?: boolean
	bgColor?:string
}

const WhatsAppButton: React.FC<Props> = ({onlyIcon, onlyText, textVariant = "bodySmBlack", classes, withTextAndArrow, bgColor = null, isBrandsPage}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	const link = "https://api.whatsapp.com/send?phone=41435051318"

	const handleGAEvent = () => {
		reactGA?.event({
			category: gaCategories.consultation,
			action: gaEvents.whatsAppButtonTextUs,
			label: gaEvents.whatsAppButtonTextUs,
			nonInteraction: false
		})
	}

	return (
		<>
			{withTextAndArrow ?
				<div>
					<a target={"_blank"}
						className={"link-reset cursor-pointer text-dark d-flex align-items-center gap-3"}
						onClick={() => handleGAEvent()}>
						<div className="rounded-3 d-flex align-items-center justify-content-center"
							style={{
								backgroundColor: "#25D366",
								height: 40,
								width: 40
							}}>
							<Image src={"/assets/icons/whats-app-button-black-icon.svg"}
								width={16}
								height={16}/>
						</div>
						<div className="col-10 d-flex align-items-center justify-content-between">
							<Typography
								variant={ "bodySmBold"}
								semanticTag={"span"}>{t("text-us")}</Typography>
							<Image src={"/assets/icons/chevron-right.svg"}
								width={20}
								height={20}/>
						</div>
					</a>
				</div> :
				<div>
					{onlyIcon ? <Link href={link}>
						<a target={"_blank"}
							className={`btn shadow-none ${styles.ctaGreyHover}`}
							onClick={() => handleGAEvent()}>
							<img src={isBrandsPage ? "/assets/icons/whatsapp-white.svg" : "/assets/icons/whats-app-button-black-icon.svg"} />
						</a>
					</Link> :
						<Button onClick={() => handleGAEvent()}
							className={`shadow-none d-flex  ${classes ? classes : ""}`}
							target="_blank"
							href={link}
							fontSizeSmall
							bgColor={bgColor ? bgColor : "#FEC04D"}
							textColor="#212529"
							color="#212529"
							icon={!onlyText && <Image src="/assets/icons/whats-app-button-black-icon.svg"
								width={20}
								height={20}/>}
							iconFirst
						>
							<Typography semanticTag="span"
								variant={textVariant}>{t("text-us")}</Typography>
						</Button>
					}
				</div>
			}
		</>
	)
}

export default WhatsAppButton

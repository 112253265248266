import React from "react"
import slugify from "slugify"
import {getProductUrl} from "../../url/Url"
import Accordion from "../Accordion/Accordion"
import TabsWrapper from "../tabs/TabsWrapper"

interface Props {
  accordionContent: any,
  tab: string,
  slug: string
  t: (arg: string) => any,
  variant: string
  active: any
	setActive: (string) => void
	setActiveLink: (string) => void
	multiple?: boolean
	grey?: boolean
	noDividerBeforeBody?: boolean
	bike?: any
	isAccessory?: boolean
}

const BikeDescriptionSection: React.FC<Props> = ({bike, variant, setActiveLink, accordionContent, slug, tab, t, active, setActive, multiple, grey, isAccessory}) => {
	return (
		<div className="mb-3 mb-md-5">
			<div className={"d-md-none col-12 pb-4"}>
				<Accordion active={active}
					multiple={multiple}
					isDesktop={false}
					setActiveLink={setActiveLink}
					setActive={setActive}
					activeHighlighted
					accordionItemsContent={accordionContent}
					current={tab || slugify(t("ubersicht"))}
					urlBase={getProductUrl(t, slug)}/>
			</div>
			{variant === "B" ? <div className={"d-none d-md-block col-12"}>

				<Accordion noDividerBeforeBody
					isDesktop={true}
					setActiveLink={setActiveLink}
					active={active}
					setActive={setActive}
					multiple={multiple}
					activeHighlighted
					accordionItemsContent={accordionContent}
					current={tab || slugify(t("ubersicht"))}
					grey={grey}
					urlBase={getProductUrl(t, slug)}
					bike={bike}
					isForPDP
					isAccessory={isAccessory} />
				<div style={{position: "absolute", marginTop: "-30rem"}}
					id="questionAndAnswers" />
			</div> :
				<div className={"d-none d-md-block col-12"}>
					<TabsWrapper tabsContent={accordionContent}
						current={tab as string || slugify(t("ubersicht"))}
						urlBase={getProductUrl(t, slug)}
						active={active}
						setActive={setActive}
					/>
				</div>}
		</div>
	)
}

export default BikeDescriptionSection

import Image from "next/image"
import React, {useContext} from "react"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import Button from "../reusable/components/Button"
import {useRouter} from "next/router"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import {StaticTile} from "../../utility/Types"
import {formatImageSrc} from "../../strapi/strapiHelpers"

type props = {
	isListView: boolean,
	staticTileContent?:StaticTile
}
function CreditCheckWishList({isListView, staticTileContent = null}: props) {
	const {t} = useTranslation()
	const router = useRouter()
	const {reactGA} = useContext(AnalyticsContext)
	return (
		<div
			className={`${isListView ? "span-up-2-grid-columns" : ""}`}
			style={{borderRadius: 16, inset: 0, position: "relative", minHeight: isListView ? "320px" : "", maxHeight: "562px", minWidth: isListView ? "" : "300px"}}>
			<Image src={staticTileContent && staticTileContent?.imageMobile?.data?.attributes?.url ? formatImageSrc(staticTileContent?.imageMobile?.data?.attributes?.url) : "/assets/images/credit-check.jpg"}
				layout={"fill"}
				objectFit={"cover"}
				style={{borderRadius: 16}}/>
			<div className="transparent-black-background container"
				style={{paddingBottom: "16px", borderRadius: "16px", position: "absolute", bottom: "0px", left: "50%", transform: "translateX(-50%)", width: "100%"}}>
				<div className=" d-flex col-12  flex-column align-items-start justify-content-start">
					<Typography variant={isListView ? "heading2Black" : "heading3MdBodyLgBlack"}
						semanticTag="h4"
						className="mb-2"
						style={{fontSize: isListView ? 32 : "18px", lineHeight: isListView ? "" : "26px", color: "white"}}>{staticTileContent ? staticTileContent?.title : t("creditcheck-eligible-for-financing-title")}</Typography>
					<Typography variant="bodySm"
						style={{fontSize: "14px", lineHeight: "20px", color: "white"}}>{staticTileContent ? staticTileContent?.description : t("eligible-for-financing-desc")}</Typography>
					<div className="col-12 mt-4">
						<Button
							href={staticTileContent ? staticTileContent.ctaLink : router?.locale === "de" ? "/creditcheck/2" : "/fr/creditcheck/2"}
							fontSizeSmall
							bgColor={staticTileContent ? staticTileContent?.ctaBackgroundColor : "#FEC04D"}
							textColor={staticTileContent ? staticTileContent?.ctaTextColor : "#212529"}
							color={staticTileContent ? staticTileContent?.ctaTextColor : "#212529"}
							onClick={() => {
								reactGA?.event({
									category: gaCategories.plp,
									action: gaEvents.creditCheckClickedOnPLP,
									label: `${gaEvents.creditCheckClickedOnPLP}`,
									nonInteraction: false
								})
							}}
							className={ "w-100"}>
							{staticTileContent ? <>{staticTileContent?.ctaBtnIcon?.data?.attributes?.url ? <Image src={ formatImageSrc(staticTileContent.ctaBtnIcon?.data?.attributes?.url)}
								width={20}
								height={20}
								objectFit="contain"/> : null}<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{staticTileContent.ctaText}</Typography></> : <><Image src={"/assets/icons/credit-check-icon.svg"}
								width={20}
								height={20}
								objectFit="contain"/>
							<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{t("check-score-btn")}</Typography></>}
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreditCheckWishList

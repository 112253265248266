import React, {useEffect, useState} from "react"
import ReactDOM from "react-dom"
import Modal from "../../reusable/components/Modal/Modal"
import Image from "next/image"
import CustomSlider from "../CustomSlider"

type Props = {
  isOpen: boolean;
  closeModal: any;
  bike: any
  initialSlide?: number;
};

const FullScreenZoomableSlider: React.FC<Props> = ({initialSlide, bike, isOpen, closeModal}) => {
	const [currentSlide, setCurrentSlide] = useState(initialSlide)

	useEffect(() => {
		if (isOpen) {
			setCurrentSlide(initialSlide)
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "auto"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [isOpen, initialSlide])

	const handleModalClose = () => {
		closeModal()
	}

	const modalContent = (
		<div style={{height: "100vh", overflow: "hidden"}}
			className="position-relative bg-success">
			<Modal isOpen={isOpen}
				closeModal={handleModalClose}
				closeOnTouch>
				<div style={{width: "100vw", height: "100vh"}}
					className="d-flex position-relative flex-column justify-content-center">
					<CustomSlider
						slidesList={bike?.images}
						bike={bike}
						arrows
						fullScreenSlider
						borderRadius={0}
						currentSlide={currentSlide}
					/>
				</div>
				<button onClick={handleModalClose}
					className="fullscreen-slider-close-btn"
				>
					<Image src={"/assets/icons/close-icon-white.svg"}
						width={20}
						height={20}
						alt="close"/>
				</button>
			</Modal>
		</div>
	)

	return isOpen ? ReactDOM.createPortal(modalContent, document.body) : null
}

export default FullScreenZoomableSlider

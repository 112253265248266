import React, {useContext, useEffect} from "react"
import PropTypes from "prop-types"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import AnimationComponent from "../reusable/components/AnimationComponent/AnimationComponent"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"

type Props = {
  isOpen: boolean,
  setIsOpen: any,
  desktop?: boolean,
  label?: string
}

const PDPTooltip: React.FC<Props> = ({isOpen, setIsOpen, desktop, label}) => {
	const {t} = useTranslation()
	const desktopClass = "position-absolute bg-white border px-4 pt-3 pb-2"
	const mobileClass = "position-fixed px-2 py-4 bg-white start-0 bottom-0 end-0"
	const {reactGA} = useContext(AnalyticsContext)

	useEffect(() => {
		if (isOpen) {
			reactGA?.event({
				category: gaCategories.pdp,
				action: gaEvents.tooltipClicked,
				label: `${gaEvents.tooltipClicked}: ${label} `,
				nonInteraction: false
			})
		}
	}, [isOpen])
	return (
		<>

			<div className="d-none d-md-block">
				{isOpen ? <div
					className={desktop ? desktopClass : mobileClass}
					style={{
						zIndex: 999999,
						right: 31,
						bottom: 84,
						maxWidth: 300,
						width: "100%"
					}}>
					<div className="row">
						<div className="col-12 px-0">
							<div className="d-flex justify-content-between">
								{label === "service-shipping" ?
									<Typography variant="bodyLg"
										semanticTag="p">
										{t("free-service-shipping")}
									</Typography> : label === "best-monthly-price" ? <Typography variant="bodyLg"
										semanticTag="p">
										{t("best-monthly-price-description-label")}
									</Typography> : <Typography variant="bodyLg"
										semanticTag="p">
										{t("pdp-tooltip-text")}
									</Typography>}
								<div className="ms-auto  cursor-pointer"
									onClick={() => setIsOpen(!isOpen)}>
									<img src="/assets/icons/close-icon.svg"
										width="14"
										height="14"/>
								</div>

							</div>

						</div>
						<div className="d-md-none col-12 text-end">
							<Typography
								variant="bodyLgBold"
								semanticTag="p"
								onClick={() => setIsOpen(!isOpen)}
								className="pointer text-uppercase"
							>
								{t("pdp-tooltip-button-text")}
							</Typography>
						</div>
					</div>
				</div> : null}
			</div>
			<div className="d-block d-md-none"
				style={{zIndex: 20010}}>
				<div className={`position-fixed top-0 start-0 bottom-0 end-0 bg-light-dark-transparent ${!isOpen && "d-none"}`}
					style={{
						zIndex: 10000001
					}}
					onClick={() => setIsOpen(!isOpen)}/>
				<AnimationComponent type="slideUp"
					inProp={isOpen}>
					<div className={"position-fixed px-3 py-4 bg-white start-0 bottom-0 end-0"}
						style={{
							zIndex: 10000004,
							borderRadius: "16px 16px 0px 0px",
							border: "1px solid #f4f4f4"
						}}>
						{label === "service-shipping" ?
							<>
								<div className="row mb-3  justify-content-between align-items-center">
									<div className="col-8">
										<Typography variant="bodyLgBlack"
											style={{fontSize: 20}}>{t("Kundendienst")}</Typography>
									</div>
									<div className="col-auto cursor-pointer text-end"
										onClick={() => setIsOpen(!isOpen)}
									>
										<img src="/assets/icons/close-icon.svg"
											width="15.5"
											height="15.5"/>
									</div>
								</div>
								<div className="row align-items-center mb-3">
									<img className="col-auto"
										src="/assets/icons/phone.svg"
										alt="phone"
										width="12.21"
										height="16"/>
									<Typography className="col-8 ps-2"
										variant="bodyLg">043 505 13 18</Typography>
								</div>
								<div className="row align-items-center  mb-4">
									<img className="col-auto"
										src="/assets/icons/mail.svg"
										alt="phone"
										width="16"
										height="13"/>
									<Typography className="col-8 ps-2"
										variant="bodyLg">sales@mybikeplan.ch</Typography>
								</div>
								<div>
									<Typography variant="bodyLgBlack"
										className="mb-3"
										semanticTag="h3"
										style={{fontSize: 20}}>{t("Versand")}</Typography>
									<Typography className="mb-0"
										variant="bodyLg"
										semanticTag="p">{t("free-service-shipping")}</Typography>
								</div>
							</> :
							label === "financing" ? <>
								<div className="row mb-4 pb-2 justify-content-between align-items-center">
									<div className="col-8">
										<Typography variant="bodyLgBlack"
											style={{fontSize: 28}}>{t("Finanzierung")}</Typography>
									</div>
									<div className="col-auto cursor-pointer text-end"
										onClick={() => setIsOpen(!isOpen)}
									>
										<img src="/assets/icons/close-icon.svg"
											width="15.5"
											height="15.5"/>
									</div>
								</div>
								<div className="mb-3">
									<Typography variant="bodyLgBlack"
										style={{fontSize: 20}}
										semanticTag="h2">{t("Unser Konzept")}</Typography>
									<Typography variant="bodyLg"
										semanticTag="p">{t("unser-konzept-description")}</Typography>
								</div>
								<div className="mb-3 pt-2">
									<Typography className="mb-3"
										variant="bodyLgBlack"
										style={{fontSize: 20}}
										semanticTag="h2">{t("Finanzierung")}</Typography>
									<Typography className="mb-1"
										variant="bodyLg"
										semanticTag="p">
										{t("finanzierung-desc-one")}
									</Typography>
									<Typography className="mb-1"
										variant="bodyLg"
										semanticTag="p">
										{t("finanzierung-desc-two")}
									</Typography>
									<Typography className="mb-1"
										variant="bodyLg"
										semanticTag="p">
										{t("finanzierung-desc-three")}
									</Typography>
									<Typography className="mb-0"
										variant="bodyLg"
										semanticTag="p">
										{t("finanzierung-desc-four")}
									</Typography>
								</div>
								<div className="mb-3 pt-2">
									<Typography className="mb-3"
										variant="bodyLgBlack"
										style={{fontSize: 20}}
										semanticTag="h2">{t("Flexibler Vertrag")}</Typography>
									<Typography className="mb-1"
										variant="bodyLg"
										semanticTag="p">
										{t("flexibler-vertrag-desc")}
									</Typography>
								</div>
							</> : label === "guarantee" ?
								<>
									<div className="row mb-4 pb-2 justify-content-between align-items-center">
										<div className="col-8">
											<Typography variant="bodyLgBlack"
												style={{fontSize: 28}}>{t("Garantie")}</Typography>
										</div>
										<div className="col-auto cursor-pointer text-end"
											onClick={() => setIsOpen(!isOpen)}
										>
											<img src="/assets/icons/close-icon.svg"
												width="15.5"
												height="15.5"/>
										</div>
									</div>
									<Typography variant="bodyLg"
										semanticTag="p"
										className="mb-0">{t("Alle unsere Modelle verfügen über 2 Jahre Werksgarantie. Bei Schadensfällen hilft dir unser Kundendienst gerne weiter und findet einen geeigneten Servicepartner für dich oder organisiert die Abholung und Reparatur im Garantiefall.")}</Typography>
								</> : label === "best-monthly-price" ? <>
									<div className="row mb-4 pb-2 justify-content-between align-items-center">
										<div className="col-8">
											<Typography variant="bodyLgBlack"
												style={{fontSize: 28}}>{t("best-monthly-price-label")}</Typography>
										</div>
										<div className="col-auto cursor-pointer text-end"
											onClick={() => setIsOpen(!isOpen)}
										>
											<img src="/assets/icons/close-icon.svg"
												width="15.5"
												height="15.5"/>
										</div>
									</div>
									<Typography variant="bodyLg"
										semanticTag="p"
										className="mb-0">{t("best-monthly-price-description-label")}</Typography>
								</> : null
						}
					</div>
				</AnimationComponent>
			</div>
		</>
	)
}

PDPTooltip.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.any
}

export default PDPTooltip

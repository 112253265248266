import React, {Dispatch, SetStateAction, useContext} from "react"
import Link from "next/link"
import Typography from "../reusable/Typography/Typography"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import styles from "./navigation.module.scss"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"
import {EBikesList} from "../../constants/TopCategories"
import Image from "next/image"
import {getMySofaPlanUrl} from "../../constants/Instances"
import {useRouter} from "next/router"
import OrganizationContext from "../../context/OrganizationContext"
import {CollectionName} from "../../constants"
import DataContext from "../../context/DataContext"

type Props = {
	setIsMenuVisible?: Dispatch<SetStateAction<boolean>>;
	navItemClassName?: string;
	sectionWidth?: number;
	megaMenuTopCategories?: any[];
	getOtherInstanceUrl?: (arg0?: string, arg1?: string, arg2?: string) => string;
	handleActiveItemSelect?: (itemTitle: string) => void;
	handleActiveItemLinkSelect?: (link: string) => void;
	handleToggleOpen?: (isOpen: boolean) => void;
	activeItem?: string;
	onBtnClick?: any;
	hasItemArrow?: boolean;
};

const colors = {dark: "#212529", white: "#fff"}

const TopCategoriesMegaMenu = ({
	activeItem,
	onBtnClick,
	hasItemArrow = true,
	handleActiveItemLinkSelect = () => {},
	handleActiveItemSelect = () => {},
	handleToggleOpen = () => {},
	navItemClassName,
	sectionWidth,
	getOtherInstanceUrl,
	megaMenuTopCategories
}: Props) => {
	const {organizationSlug} = useContext(OrganizationContext)
	const router = useRouter()
	const {locale} = router
	const {dark} = colors
	const {reactGA} = useContext(AnalyticsContext)
	const topCategories = megaMenuTopCategories || EBikesList[locale]
	const accessoriesPageLink = locale === "de" ? "/zubehoer" : "/accessoires"
	const isBrandWorld = router.pathname.includes("/brand-world")
	const handleMouseEnter = (itemTitle: string, link: string) => {
		handleActiveItemSelect(itemTitle)
		handleActiveItemLinkSelect(link)
		handleToggleOpen(true)
	}

	const {brandWorld} = useContext(DataContext)

	const getCategoryUrl = (slugName: string) => {
		if (getOtherInstanceUrl) {
			return getMySofaPlanUrl(organizationSlug, slugName, "-", "-")
		}

		return slugName === accessoriesPageLink ?
			isBrandWorld ? `${filterUrl({[urlParams.categories]: accessoriesPageLink}, urlParams.categories, accessoriesPageLink, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` :
				filterUrl({[urlParams.categories]: accessoriesPageLink}, null, null) :
			isBrandWorld ? `${filterUrl({[urlParams.categories]: slugName}, urlParams.categories, slugName, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` :
				filterUrl({[urlParams.categories]: slugName}, null, null)
	}

	const renderLink = (title: string, slugName: string, url: string, extraClass = "") => (
		<Link href={url}>
			<a
				onClick={() => {
					sessionStorage.setItem("page", (0).toString())
					sessionStorage.setItem("scrollPosition", (0).toString())
					reactGA?.event({
						category: gaCategories.megaMenu,
						action: gaEvents.category,
						label: `${gaEvents.category} ${title}`,
						nonInteraction: false
					})
				}}
				className={`link-reset ${extraClass}`}
			>
				<Typography style={{color: isBrandWorld ? "#fff" : dark}}
					variant="bodyLg"
					className="d-none d-md-block">
					{title}
				</Typography>
				<Typography style={{color: isBrandWorld ? "#fff" : dark}}
					variant="bodySm"
					className="d-md-none">
					{title}
				</Typography>
			</a>
		</Link>
	)

	return (
		<div style={{maxWidth: sectionWidth || 183, width: "100%"}}>
			<div className={`${styles.megaMenuTopCategories} scrollbar-hide`}>
				<ul className="list-unstyled mb-0">
					{topCategories.map(({title, slugName}, idx) => {
						const url = getCategoryUrl(slugName)
						const isActive = activeItem === title
						const bgColor = getOtherInstanceUrl ? "#C8E6DC" : "#FEC04D33"

						return (
							<li
								key={idx}
								className={`rounded-3 d-flex justify-content-between align-items-center ${styles.megamenuTopCategoryItem} ${navItemClassName}`}
								onClick={onBtnClick}
								onMouseEnter={() => handleMouseEnter(title, slugName)}
								style={{
									backgroundColor: isActive ? bgColor : "",
									maxWidth: 240,
									minWidth: 155,
									width: "100%"
								}}
							>
								{renderLink(title, slugName, url, getOtherInstanceUrl ? "col-8 d-inline-block" : hasItemArrow ? "col-8" : "col-12")}
								{hasItemArrow && (
									<div className={`d-flex ${getOtherInstanceUrl ? "col-2" : "col-4"}`}>
										<Image src={"/assets/icons/chevron-right.svg"}
											width={20}
											height={20} />
									</div>
								)}
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

export default TopCategoriesMegaMenu

import React, {FC, useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import {CheckLg, X} from "react-bootstrap-icons"
import {ModalContext} from "../../../context/ModalContext"
import styles from "./UpsellModalContent.module.scss"
import {Slides} from "../components/Slides"
import CompatibleAccessory from "../../bikeDetails/CompatibleAccessory"
import Button from "../components/Button"
import {colors} from "../../../colors/Colors"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"

type Props = {
    sellProducts: any[],
    bike: any,
	setAddingToCart: (bolean)=> void
	deals?:number
    onModalClose?: () => void
}

const UpsellModalContent: FC<Props> = ({sellProducts, bike, setAddingToCart, deals, onModalClose}) => {
	const {t} = useTranslation()
	const {closeModal} = useContext(ModalContext)
	const {primary, darkBlack, white} = colors

	const onHandleClosePopup = () => {
		setAddingToCart(false)
		closeModal()
	}

	return (
		<div className={`${styles.fixedWrapper}`}>
			<div className={`${styles.modalWrapper} position-relative px-md-0 `}>
				<div onClick={() => {
					closeModal()
					onModalClose()
					setAddingToCart(false)
				}}
				className={"position-absolute bg-white rounded-circle top-0 end-0 pt-md-4 cursor-pointer"}
				style={{
					marginRight: "3%",
					zIndex: 1
				}}>
					<X size={30}
						color={"black"}/>
				</div>

				<div className="row position-relative mx-0  justify-content-between">

					<div className={`${styles.contentWrapper} col-12 bg-white pt-3 py-md-4 ps-md-4 
                    pe-md-5 d-flex flex-column justify-content-center align-items-center pb-5 `}>
						<div className="container text-center mt-5 px-0 ps-md-0 mb-3 mb-md-4">
							<div style={{marginBottom: 40}}
								className="d-flex flex-wrap flex-md-nowrap  justify-content-center align-items-center">
								<CheckLg
									className="col-12 mb-3 mb-md-0 me-md-2 col-md-auto text-green-success text-center"
									size={50}/>
								<Typography style={{fontSize: "40px"}}
									className="text-green-success"
									variant="heading1"
									semanticTag="span">{t("great-choice")}</Typography>
							</div>
							<Typography className="text-start text-md-center"
								variant="bodyLg">{t("check-out-accessories")}</Typography>
						</div>

						<div className="container ps-0 pe-0 d-md-none mb-4">
							<Slides centerMode
								centerModePadding={{left: 0, bottom: 0, right: 25}}>
								{sellProducts?.length ? sellProducts?.map(product => {
									return (
										<div key={product.id}
											className="px-2">
											<CompatibleAccessory onHandleClosePopup={onHandleClosePopup}
												key={product.id}
												product={product}
												bike={bike}/>
										</div>
									)
								}) : null}
							</Slides>
						</div>

						<div className={"d-none d-md-block container mt-4"}
							style={{minHeight: 200}}>
							<div className={"row "}>
								<div className={"col-12"}>
									<div className={"text-center"}>

										{sellProducts.length > 3 ?
											<div className="row compatible-accessories">
												<Slides arrowsPositionX={-3}
													className="px-md-5"
													centerModePadding={{left: 7, bottom: 0, right: 10}}
													centerMode
													dotsPositionY={-80}
													arrows
													dots
													slidesToShow={3}>
													{sellProducts?.length ? sellProducts.map(product => {
														return (
															<div key={product.id}
																className={"h-100 col-auto compatible-accessories-item"}
																style={{background: "#F8F8F8", borderRadius: 24}}>
																<CompatibleAccessory
																	onHandleClosePopup={onHandleClosePopup}
																	key={product.id}
																	product={product}
																	bike={bike}/>
															</div>
														)
													}) : null}
												</Slides>
											</div>	:
											<div className="row justify-content-center gx-3">
												{sellProducts?.length ? sellProducts.map(product => {
													return (
														<div key={product.id}
															className={
																"h-100 col-4 px-0 px-2 compatible-accessories-item"
															}
															style={{background: "#fff", borderRadius: 24}}>
															<CompatibleAccessory onHandleClosePopup={onHandleClosePopup}
																key={product.id}
																product={product}
																bike={bike}/>
														</div>
													)
												}) : null}
											</div>
										}

									</div>
								</div>
							</div>
						</div>

						<div className="d-flex col-12 mb-4  flex-column flex-md-row justify-content-center">
							<Button className="d-md-none mb-4 col-12"
								outline
								label={t("All Accessories")}
								color={white}
								fontSizeSmall
								href={filterUrl({[urlParams.categories]: t("zubehoer")}, null, null)}
								onClick={() => {
									closeModal()
									onModalClose()
									setAddingToCart(true)
								}}/>
							<Button className="d-none d-md-block me-md-2 col-auto"
								outline
								label={t("All Accessories")}
								textColor={darkBlack}
								hoverColor={darkBlack}
								color={white}
								high
								href={filterUrl({[urlParams.categories]: t("zubehoer")}, null, null)}
								paddingY="3"
								onClick={() => {
									closeModal()
									onModalClose()
									setAddingToCart(true)
								}}/>

							<Button className="col-12 col-md-auto data-cypress-to-cart-btn"
								label={t("to-the-cart")}
								color={darkBlack}
								textColor={darkBlack}
								bgColor={primary}
								high
								paddingY="3"
								href={t("/checkout")}
								onClick={() => {
									closeModal()
									onModalClose()
									setAddingToCart(true)
								}}/>
						</div>

					</div>
				</div>
			</div>
		</div>

	)
}

export default UpsellModalContent

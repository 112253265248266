import React, {useContext, useState} from "react"
import Modal from "../reusable/components/Modal/Modal"
import {ExclamationCircle, X} from "react-bootstrap-icons"
import styles from "./bikeDetails.module.scss"
import Typography from "../reusable/Typography/Typography"
import Button from "../reusable/components/Button"
import {ErrorMessage, Field, Form, Formik} from "formik"
import * as Yup from "yup"
import {useTranslation} from "next-i18next"
import {fetchPost} from "../../firebaseAdmin/fetchPost"
import {colors} from "../../colors/Colors"
import {useRouter} from "next/router"
import {CartItem} from "../../context/CartContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"

type Values = {
    email: string
    name:string
}

type Props = {
    closeModal: () => void
    isModalOpen: boolean
	bike: any
	disabled: boolean
	handleSizeClick:()=>void
	cartItem: ()=> CartItem
}

function SponsorMeModal({closeModal, isModalOpen, bike, cartItem, disabled, handleSizeClick}: Props) {
	const sponsorshipForm = (isMobile?:boolean) => {
		return (<Formik
			initialValues={{
				email: "",
				name: ""
			}}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({touched, errors}) => (
				<Form className={`d-flex ${isMobile ? "container col-12" : ""} flex-column align-items-start`}
					style={{paddingTop: "40px"}}>
					{ error ? <Typography variant={"bodySmBold"}
						className="  text-danger text-left">{t("something-went-wrong")}</Typography> : null}
					<div style={{marginBottom: "2rem", width: "100%"}}>
						<Typography variant={"bodySmBoldMdBodyLgBold"}>
							{t("name-label")} <span className="text-danger"
								style={{fontSize: 20}}>*</span>
						</Typography>
						<Field
							style={{
								width: "100%",
								height: "50px",
								border: touched.name && errors.name ? "2px solid red" : "1px solid #D2D7DC",
								borderRadius: "4px",
								outline: "none",
								padding: "3px",
								marginTop: "2px"
							}}
							id="name"
							name="name"

						/>
						<ErrorMessage name="name"
							component="div"
							className="text-danger small mb-2" />
					</div>
					<div style={{marginBottom: "2rem", width: "100%"}}>
						<Typography variant={"bodySmBoldMdBodyLgBold"}>
							{t("email-label")} <span className="text-danger"
								style={{fontSize: 20}}>*</span>
						</Typography>
						<Field
							style={{
								width: "100%",
								height: "50px",
								border: touched.email && errors.email ? "2px solid red" : "1px solid #D2D7DC",
								borderRadius: "4px",
								outline: "none",
								padding: "3px",
								marginTop: "2px"
							}}
							id="email"
							name="email"
							type="email"
						/>
						<ErrorMessage name="email"
							component="div"
							className="text-danger small" />
					</div>
					{disabled ? <a onClick={handleSizeClick}
						href="#size"
						style={{backgroundColor: "#777777"}}
						className="text-decoration-none text-white col-12 col-md-12 d-flex justify-content-center align-items-center rounded p-2">
						<div className="col-auto">
							<ExclamationCircle size={16}/>
						</div>
						<span style={{fontSize: 14}}
							className="text-bold text-uppercase ms-2">
							{t("fixed-btn-select-size", {ns: "productDescrPage"})}
						</span>
					</a> : <Button
						className={`${isMobile ? "w-100 mb-3" : "w-50 mt-0 "}`}
						label={t("send-me-link")}
						color={"white"}
						type={"submit"}
						fontSizeSmall
						bgColor={"black"}
						textColor={"white"}
						isLoading={isSubmitting}
						spinnerColor={"white"}
					/>}

				</Form>)}
		</Formik>)
	}

	const {description, shortDescription, similarBikes, crossSellProducts, short_description, ...product} = bike
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
	const router = useRouter()
	const [adminLink, setAdminLink] = useState<string>("")
	const [sponsorsLink, setSponsorsLink] = useState<string>("")
	const {t} = useTranslation("sponsorship")
	const [linkSent, setLinkSent] = useState<boolean>(false)
	const {reactGA} = useContext(AnalyticsContext)

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email("Invalid email address")
			.required("Email is required"),
		name: Yup.string()
			.required("Name is required")

	})
	const [error, setError] = useState<boolean>(false)

	const handleSubmit = async (
		values: Values
	) => {
		const cartItemObject = cartItem()
		setIsSubmitting(true)
		try {
			const res = await fetchPost("/api/firestore/createSponsorMe", {
				email: values.email,
				name: values.name,
				product,
				cartItem: {...cartItemObject, isSponsored: true}
			})
			if (res.status === 201) {
				const result = await res.json()
				setLinkSent(true)
				setAdminLink(`/sponsor-me/${result.token}/${result.adminToken}`)
				setSponsorsLink(`${process.env.NEXT_PUBLIC_APP_URL}/sponsor-me/${result.token}`)
			} else {
				setError(await res.json())
			}
		} catch (error) {
			console.error(error)
			setError(true)
		} finally {
			setIsSubmitting(false)
		}

		reactGA?.event({
			category: gaCategories.sponsorMe,
			action: gaEvents.createSponsorMe,
			label: gaEvents.createSponsorMe,
			nonInteraction: false
		})
	}

	const redirectToAdminLink = async () => {
		if (typeof window !== "undefined" && adminLink) {
			setIsRedirecting(true)
			await router.push(adminLink)
			setIsRedirecting(false)
		}
	}

	return (
		<Modal isOpen={isModalOpen}
			closeModal={closeModal} >
			<div className={` d-none d-md-flex ${styles.modalWrapper}`}>
				<div className={styles.contentWrapper}>
					<button onClick={closeModal}
						className={"d-none d-md-block position-absolute btn bg-white rounded-circle p-0 top-0 end-0"}
						style={{
							marginTop: "5%",
							marginRight: "3%",
							zIndex: 1
						}}>
						<X size={36}
							color={"black"}/>
					</button>
					<Typography variant="heading1"
						style={{fontSize: "36px", marginTop: "10px"}} >{t("modal-title")}</Typography>
					{linkSent ? "" : <Typography variant="bodyLg"
						style={{marginTop: 16}}>{t("modal-description")}</Typography>}
					{linkSent ?
						<div className="d-flex flex-column justify-content-start align-items-start">
							<Typography variant="bodySm"
								style={{fontSize: "15px", marginTop: "1.6rem", marginBottom: "1.6rem"}}>{t("modal-success-message")}</Typography>
							<div className="d-flex justify-content-between align-items-center"
								style={{
									width: "100%",
									height: "50px",
									border: "1px solid #D2D7DC",
									borderRadius: "4px",
									backgroundColor: "white",
									outline: "none",
									padding: "3px",
									marginTop: "2px"
								}}>
								<input type="text"
									readOnly
									style={{outline: "none", border: "none", backgroundColor: "transparent", width: "70%"}}
									id="name"
									name="name"
									value={sponsorsLink ? sponsorsLink : "Please Refresh the page"}

								/>
								<Button
									className={" w-20 "}
									label={t("copy")}
									color={colors.secondary}
									type={"submit"}
									fontSizeSmall
									bgColor="transparent"
									textColor={"black"}
									onClick={() => {
										navigator.clipboard.writeText(sponsorsLink || "Copy unsuccessful, Please Refresh the page")
									}}
									spinnerColor={"white"}
								/>
							</div>
							<Button
								className={" w-50 mt-4 d-none d-md-block "}
								label={t("got-to-my-sponsor-page")}
								color={"white"}
								type={"submit"}
								onClick={redirectToAdminLink}
								fontSizeSmall
								bgColor={colors.primary}
								textColor={"black"}
								isLoading={isRedirecting}
								spinnerColor={"white"}
							/>
							<Button
								className={" w-100 mt-4 d-block d-md-none "}
								label={t("got-to-my-sponsor-page")}
								color={"white"}
								type={"submit"}
								onClick={redirectToAdminLink}
								fontSizeSmall
								bgColor={colors.primary}
								textColor={"black"}
								isLoading={isRedirecting}
								spinnerColor={"white"}
							/>
						</div> : sponsorshipForm()}
				</div>
			</div>
			{/* End of desktop modal */}
			{/* Start of mobile modal */}
			<div className="slide-up d-block d-md-none d-flex justify-content-start flex-column"
				style={{position: "absolute", bottom: "-30px", left: 0, right: 0, background: "white", borderTopLeftRadius: "2rem", borderTopRightRadius: "2rem", zIndex: 9999999}}>
				<div style={{minHeight: linkSent ? "420px" : "542px"}}
					className=" position-relative d-flex justify-content-center mt-2 align-items-center flex-column">
					<button onClick={closeModal}
						className={"d-md-block position-absolute btn bg-white rounded-circle p-0 top-0 end-0"}
						style={{
							marginTop: "5%",
							marginRight: "3%",
							zIndex: 1
						}}>
						<X size={36}
							color={"black"}/>
					</button>
					<div className="container">
						<Typography variant="heading1"
							style={{fontSize: "28px", marginTop: "10px", lineHeight: "36px"}} >{t("modal-title")}</Typography>
						{linkSent ? "" : <Typography variant="bodyLg"
							style={{marginTop: 16, lineHeight: "28px"}}>{t("modal-description")}</Typography>}
					</div>
					{linkSent ?
						<div className="d-flex container flex-column justify-content-start align-items-start">
							<Typography variant="bodySm"
								style={{fontSize: "15px", marginTop: "1.6rem", marginBottom: "1.6rem"}}>{t("modal-success-message")}</Typography>
							<div className="d-flex justify-content-between align-items-center"
								style={{
									width: "100%",
									height: "50px",
									border: "1px solid #D2D7DC",
									borderRadius: "4px",
									backgroundColor: "white",
									outline: "none",
									padding: "3px",
									marginTop: "2px"
								}}>
								<input type="text"
									readOnly
									style={{outline: "none", border: "none", backgroundColor: "transparent", width: "70%"}}
									id="name"
									name="name"
									value={sponsorsLink ? sponsorsLink : "Please Refresh the page"}

								/>
								<Button
									className={" w-20 "}
									label={t("copy")}
									color={colors.secondary}
									type={"submit"}
									fontSizeSmall
									bgColor="transparent"
									textColor={"black"}
									onClick={() => {
										navigator.clipboard.writeText(sponsorsLink || "Copy unsuccessful, Please Refresh the page")
									}}
									spinnerColor={"white"}
								/>
							</div>
							<Button
								className={" w-50 mt-4 d-none d-md-block "}
								label={t("got-to-my-sponsor-page")}
								color={"white"}
								type={"submit"}
								onClick={redirectToAdminLink}
								fontSizeSmall
								bgColor={colors.primary}
								textColor={"black"}
								isLoading={isRedirecting}
								spinnerColor={"white"}
							/>
							<Button
								className={" w-100 mt-4 d-block d-md-none "}
								label={t("got-to-my-sponsor-page")}
								color={"white"}
								type={"submit"}
								onClick={redirectToAdminLink}
								fontSizeSmall
								bgColor={colors.primary}
								textColor={"black"}
								isLoading={isRedirecting}
								spinnerColor={"white"}
							/>
						</div> :
						<div className="col-12 pb-2 d-flex flex-column justify-content-start align-items-center">
							{sponsorshipForm(true)}
						</div>}

				</div>
			</div>
		</Modal>
	)
}

export default SponsorMeModal

import React, {useContext, useEffect, useMemo, useState} from "react"
import WishListContext from "../context/WishListContext"
import {BikeType} from "../utility/Types"
import FirebaseContext from "../context/FirebaseContext"
import useCache from "../hooks/useCache"
import {fetchPost} from "../firebaseAdmin/fetchPost"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"

type Props = {
    children: React.ReactNode
}

function WishListContextProvider({children}:Props) {
	const [wishList, setWishList] = useState<BikeType[]>([])
	const [isStoringNewWishListItem, setIsStoringNewWishListItem] = useState<boolean>(false)
	const [latestItemAdded, setLatestItemAdded] = useState<BikeType>(null)
	const {user} = useContext(FirebaseContext)
	const [wishListFetchedFromFirestore, setwishListFetchedFromFirestore] = useState<boolean>(false)
	const router = useRouter()
	const {query} = router
	const {t} = useTranslation()

	const resetNotifications = () => {
		setLatestItemAdded(() => null)
	}

	const addToWishList = async (bike: BikeType) => {
		const list = wishList || []
		const bikeAlreadyExists = getWishListItemBySlug(bike?.slug)
		if (bikeAlreadyExists) {
			await deleteWishListItemBySlug(bike?.slug)
			return null
		}

		setWishList(prev => [bike, ...prev])
		const storedSuccessfully = await storeWishList([bike, ...list])

		if (!storedSuccessfully) {
			console.error("Something went wrong with firestore")
		}
	}

	useEffect(() => {
		const timeout =	setTimeout(() => {
			resetNotifications()
		}, 6000)
		return () => clearTimeout(timeout)
	}, [latestItemAdded])
	const clearWishList = async () => {
		setWishList(() => [])

		const storedSuccessfully = await storeWishList([])

		if (!storedSuccessfully) {
			console.error("Something went wrong with firestore")
		}
	}

	const deleteWishListItemBySlug = async (slug: string) => {
		const newList = wishList.filter(item => !item?.slug?.includes(slug))
		setWishList(() => newList)
		const storedSuccessfully = await storeWishList(newList, true)

		if (!storedSuccessfully) {
			console.error("Something went wrong with firestore")
		}
	}

	const getWishListItemBySlug = (slug: string):BikeType => {
		const list = wishList || []
		const result = list?.find(item => item?.slug?.includes(slug)) || null
		return 	result
	}

	const storeWishList = async (newWishList, isDeletingAnItem = false) => {
		try {
			if (!isDeletingAnItem) {
				setLatestItemAdded(() => newWishList[0])
			}

			const res = await fetchPost("/api/firestore/createWishList", {uid: user.uid, wishList: newWishList})

			return res.status === 200
		} catch (error) {
			console.error(error)
		}
	}

	const wishListUrl = useMemo(() => {
		let url = null

		if (!wishListFetchedFromFirestore && ((user?.uid && query?.uid) || user?.uid)) {
			url = user?.uid !== query?.uid && typeof query?.uid !== "undefined" ? `/api/firestore/getWishList?uid=${query?.uid}` : `/api/firestore/getWishList?uid=${user?.uid}`
		}

		return url
	}, [wishListFetchedFromFirestore, user, query, wishList, storeWishList, addToWishList, deleteWishListItemBySlug])

	const {data, error, isLoading: isFetchingFromFirestore} = useCache(wishListUrl)
	useEffect(() => {
		if (data && data?.wishList && !error && data?.success) {
			setWishList(() => data.wishList)
			setwishListFetchedFromFirestore(true)
		}

		if (error || data?.success === false) {
			setWishList(() => [])
			setwishListFetchedFromFirestore(true)
		}
	}, [data, error])
	return (
		<WishListContext.Provider value={{wishList, latestItemAdded, resetNotifications, wishListFetchedFromFirestore, isStoringNewWishListItem, setWishList, addToWishList, deleteWishListItemBySlug, clearWishList, isFetchingFromFirestore, getWishListItemBySlug}}>
			{children}
		</WishListContext.Provider>
	)
}

export default WishListContextProvider

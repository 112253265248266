import React, {useContext} from "react"
import {BikeType} from "../../utility/Types"
import LayoutContext from "../../context/LayoutContext"
import BikesCollectionItem from "../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import {Stars} from "react-bootstrap-icons"
import CompatibleAccessory from "../bikeDetails/CompatibleAccessory"
import {Slides} from "../reusable/components/Slides"
import Button from "../reusable/components/Button"
import {useRouter} from "next/router"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import {ModalContext} from "../../context/ModalContext"

type Props = {
    list: Partial<BikeType[]>
	isAccessory?: boolean
	isAiSearchLandingPage?:boolean
    isAiResponse?: boolean
    isEmptyPage?: boolean
}
function SearchResultItems({list, isAccessory = false, isAiResponse = false, isAiSearchLandingPage, isEmptyPage = false}: Props) {
	const router = useRouter()
	const {t} = useTranslation()
	const {closeModal} = useContext(ModalContext)
	const {isMobile} = useContext(LayoutContext)
	const {reactGA} = useContext(AnalyticsContext)
	if (list && !isAiResponse) {
		return <div
			style={{width: isMobile ? "" : "85%"}}
			className={`${
				isMobile ?
					"mobile-search-results" : ""
			}`}
		>
			{list.length ?
				list.map((bike, idx) => (
					list.length >= 1 && isMobile ? <BikesCollectionItem
						isIkeaDesign
						isGridBottomRightRounded={isMobile && list.length % 2 > 0 ? idx === list.length - 2 || idx === list.length - 1 : idx === list.length - 1}
						isGridBottomLeftRounded={isMobile ? list.length % 2 > 0 ? idx === list.length - 1 : idx === list.length - 2 : idx === list.length - 5 || idx === 0}
						isGridTopRightRounded={isMobile ? idx === 1 : idx === 4 || idx === list.length - 1}
						isGridTopLeftRounded={idx === 0}
						key={idx}
						withOneImage
						cardWidth={isMobile ? "170px" : "196px"}
						hideComparison
						bike={bike}
						isTwoCardsView={true}
						isRowForMobile={true}
					/> : <BikesCollectionItem isSearchResult
						bike={bike}
						withOneImage
						listView
						isListviewItemSmall
						cardWidth="85%" />
				)) :
				null}
		</div>
	}

	if (list && isAiResponse) {
		return <><Slides className="d-md-none"
			centerMode
			slidesToShow={1}
			dots
			dotsPositionY={-50}
			draggable
			centerModePadding={{left: 0, bottom: 0, right: -6}}>
			{list.length ?
				list.map((bike, idx) => (
					list.length >= 1 ?
						<div key={idx}
							className="carousel-ai-card p-3 d-flex align-items-center justify-content-center flex-column gap-3" >
							<CompatibleAccessory hideComparison
								isReferredByAi={isAiResponse}
								product={bike} />
							<div className="d-flex align-items-start justify-content-start gap-2 flex-column">
								<div className="d-flex align-items-center justify-content-start gap-1">
									<Stars /><Typography variant="bodySmBold">{t("why-this-is-good-label")}</Typography>
								</div>
								<Typography style={{maxHeight: "180px", overflowY: "auto"}}
									variant="bodySm">{bike?.explanation}</Typography>
							</div>
							<Button onClick={() => {
								closeModal()
								const productUrl = `/${t("produkt")}/${bike.slug}?ai-suggested=true`
								reactGA?.event({
									category: gaCategories.megaMenu,
									action: gaEvents.userClickedAiBikeSuggestion,
									label: `AI Bike suggestion Clicked: ${bike.name}`,
									nonInteraction: false
								})
								router.push(productUrl)
							}}
							label={t("view-bike")}
							textColor="#fff"
							bgColor={"#A219D4"}
							fontSizeSmall
							className="col-12"/></div> : null
				)) :
				null}
		</Slides>
		<div style={{display: isEmptyPage && isMobile ? "none" : ""}}
			className={isEmptyPage ? "grid-cols-2" : isAiSearchLandingPage ? "search-ai-results-on-landing-page" :
				"d-none overflow-auto d-md-flex align-item-start justify-content-start gap-4 flex-column"}>
			{list.length ?
				list.map((bike, idx) => (
					list.length >= 1 ?
						<div key={idx}
							className="carousel-ai-card p-3 d-flex align-items-center justify-content-center flex-column gap-3" >
							<CompatibleAccessory hideComparison
								isReferredByAi={isAiResponse}
								product={bike} />
							<div className="d-flex align-items-start justify-content-start gap-2 flex-column">
								<div className="d-flex align-items-center justify-content-start gap-1">
									<Stars /><Typography variant="bodySmBold">{t("why-this-is-good-label")}</Typography>
								</div>
								<Typography style={{
									maxHeight: isEmptyPage || isAiSearchLandingPage ?
										"100px" : "none", overflowY: "auto"
								}}
								variant="bodySm">{bike?.explanation}</Typography>
							</div>
							<Button onClick={() => {
								closeModal()
								const productUrl = `/${t("produkt")}/${bike.slug}?ai-suggested=true`
								reactGA?.event({
									category: gaCategories.megaMenu,
									action: gaEvents.userClickedAiBikeSuggestion,
									label: `AI Bike suggestion Clicked: ${bike.name}`,
									nonInteraction: false
								})
								router.push(productUrl)
							}}
							textColor="#fff"
							label={t("view-bike")}
							bgColor={"#A219D4"}
							fontSizeSmall
							className="col-12"/></div> : null
				)) :
				null}
		</div>
		</>
	}

	return <></>
}

export default SearchResultItems

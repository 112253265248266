import React, {useContext, useState} from "react"
import Button from "../../../reusable/components/Button"
import {otherInstancesCategoryTitles} from "../../../../constants/Instances"
import {filterUrl} from "../../../filter/FilterUrl"
import urlParams from "../../../../utility/UrlParams"
import {gaCategories, gaEvents} from "../../../../config/googleAnalytics/events"
import TopCategoriesMegaMenu from "../../TopCategoriesMegaMenu"
import AccordionAnimation from "../../../reusable/components/AnimationComponent/AccordionAnimation"
import Typography from "../../../reusable/Typography/Typography"
import Link from "next/link"
import styles from "../../navigation.module.scss"
import {generateUUID} from "../../../../utility/UUID"
import {CollectionName, premiumBrands} from "../../../../constants"
import LinkWithIcon from "../../../reusable/components/LinkWithIcon"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import {colors} from "../../../../colors/Colors"
import DataContext from "../../../../context/DataContext"
import {findTopBrandsPerCategory} from "../../../../utility/helpers/findTopBrandsPerCategory"
import AnalyticsContext from "../../../../context/AnalyticsContext"
import {formatStrapiData} from "../../../../strapi/strapiHelpers"

interface Props {
	toggleMenu?: (router, pathname) => void
	item?: any,
	otherInstancesBrandsForMegaMenu?:any[]
	themeColor?: string
}

const DesktopMegaMenuItem: React.FC<Props> = ({toggleMenu = () => {}, item, otherInstancesBrandsForMegaMenu, themeColor}) => {
	const {t} = useTranslation("common")
	const router = useRouter()
	const {query, pathname} = router

	// Context
	const {brands, height, accessoryFilters, pageProps: {strapiContent}} = useContext(DataContext)

	const mega_menus = strapiContent?.data?.mega_menus

	const top_brands_per_categories = strapiContent?.data?.top_brands_per_categories
	const items = formatStrapiData(mega_menus, ["topCategories", "title", "buttonTitle", "link", "slugName", "allBrandsLink", "hideButton", "isDropDown", "showSuggestionSection", "showHeight", "isAccessory", "simpleCategories", "simpleBrands"])
	const dataItem = items.find(({slugName}) => slugName === item.slugName || slugName === item?.categorySlug)

	// Design variables
	const {primary} = colors

	// States/conditionals variables for MegaMenu

	const activeItemTitle = dataItem?.topCategories?.[0]?.title || dataItem?.name
	const activeLink = dataItem?.link || dataItem?.slugName
	const isAccessoriesActive = dataItem?.isAccessory
	const [activeItemLink, setActiveItemLink] = useState(activeLink)
	const [activeItem, setActiveItem] = useState(activeItemTitle)

	const [loading, setLoading] = useState(false)
	const [isToggleOpen, setIsToggleOpen] = useState(true)
	const hideButton = dataItem?.hideButton
	const showHeight = dataItem?.showHeight

	// Brands for MegaMenu
	const accessoriesBrands = accessoryFilters?.find(filter => filter.slug === "brand")
	const sortedBrands = brands?.length ? brands?.sort((a, b) => a?.fields?.menuLabel?.localeCompare(b?.fields?.menuLabel)) : brands
	const totalBrands = sortedBrands?.length
	const brandsPerColumn = Math.ceil(totalBrands / 3)

	// Top brands for MegaMenu
	const topBrandsList = findTopBrandsPerCategory(activeItem || activeItemTitle, top_brands_per_categories, brands)

	const onBtnClick = () => {
		sessionStorage.setItem("page", (0).toString())
		sessionStorage.setItem("scrollPosition", (0).toString())
		setLoading(true)
		toggleMenu(router, pathname)
	}

	const {reactGA} = useContext(AnalyticsContext)
	const accessoriesPageLink = filterUrl({[urlParams.categories]: t("accessories-url-slug")}, urlParams.categories, t("accessories-url-slug"))

	return (
		<>
			<div style={{padding: "24px 51px 24px 20px"}}>
				{!hideButton &&
					<Button
						label={t(item?.buttonTitle)}
						textColor={themeColor === primary || !themeColor ? "#212529" : "white"}
						color="#212529"
						bgColor={themeColor ? themeColor : primary}
						outline={false}
						paddingY="2"
						href={
							isAccessoriesActive ? accessoriesPageLink : item?.slugName === "e-bikes" ? filterUrl({[urlParams.categories]: item.slugName || item.categorySlug}, null, null) : (item?.slugName === "nicht-elektrische-bikes" || item?.slugName === "velos-non-electriques") ? filterUrl({[urlParams.categories]: item.slugName || item.categorySlug}, null, null) : filterUrl({}, null, null)}
						className={"col-auto"}
						isLoading={loading}
						onClick={() => {
							sessionStorage.setItem("page", (0).toString())
							sessionStorage.setItem("scrollPosition", (0).toString())
							onBtnClick()
							reactGA?.event({
								category: gaCategories.megaMenu,
								action: gaEvents.allEBikesButton,
								label: gaEvents.allEBikesButton,
								nonInteraction: false
							})
						}}/>}

				<div className="d-flex mt-4 justify-content-between"
					style={{columnGap: 16}}>
					{dataItem?.topCategories?.length ?
						<div className="d-flex justify-content-between col-auto">
							<div className="col-auto pe-4"
								style={{borderRight: "1px solid #DEE2E6", width: 270}}>
								<TopCategoriesMegaMenu sectionWidth={240}
									megaMenuTopCategories={dataItem.topCategories}
									activeItem={activeItem}
									handleToggleOpen={setIsToggleOpen}
									handleActiveItemLinkSelect={setActiveItemLink}
									handleActiveItemSelect={setActiveItem}/>
							</div>
							<AccordionAnimation
								inProp={isToggleOpen}
								duration={200}
								transTimingFn="ease-out"
								unmount
								customHeight={"100%"}>
								{activeItem && !isAccessoriesActive && activeItem && activeItemLink !== "45-km" && (
									<>
										{dataItem.topCategories.map((category:any, index:number) => (
											<React.Fragment key={`cat_${index}`}>
												{category.subCategories.length && category.title === activeItem ?
													<div
														className="px-4"
														style={{width: 200}}>
														{category.title === activeItem && <Typography variant="bodySmBold"
															className="px-3"
															style={{marginBottom: 12}}>{t("Categories")}</Typography>}

														<div className="list-unstyled">
															{
																category
																	.title === activeItem && category.subCategories?.map((item, idx) => (
																	<li onClick={() => {
																		sessionStorage.setItem("page", (0).toString())
																		sessionStorage.setItem("scrollPosition", (0).toString())
																		reactGA?.event({
																			category: gaCategories.megaMenu,
																			action: gaEvents.topBrands,
																			nonInteraction: false
																		})
																	}}
																	key={idx}
																	className="list-unstyled mb-0">
																		<Link
																			href={filterUrl({[urlParams.categories]: item.slugName || item.categorySlug}, null, null)}>
																			<a className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
																				<Typography
																					variant="bodySm">{item.title}</Typography>
																			</a>
																		</Link>
																	</li>
																))
															}
														</div>
													</div> : null}
											</React.Fragment>

										))}

									</>
								)}
							</AccordionAnimation>
						</div> : null
					}

					{isAccessoriesActive && <div className="col-auto">
						<Typography variant="bodySmBold"
							className="px-3"
							style={{marginBottom: 12}}>{t("Categories")}</Typography>
						{dataItem.simpleCategories ? dataItem.simpleCategories?.map(item => {
							const {name, slug} = item
							return <div key={generateUUID()}
								onClick={onBtnClick}>
								<Link
									href={filterUrl({page: "-"}, "accessory-type", slug, CollectionName.Accessories, "Accessories")}
									passHref
									legacyBehavior>
									<a onClick={() => {
										sessionStorage.setItem("page", (0).toString())
										sessionStorage.setItem("scrollPosition", (0).toString())
									}}
									className={`text-decoration-none rounded-3 text-dark height-link d-block ${styles.megamenuItem}`}>
										<Typography
											variant={`${query?.type === slug ? "bodyLgBold" : "bodySm"}`}>{t(name)}</Typography>
									</a>
								</Link>
							</div>
						}
						) : null}
					</div>}

					{/* Show height suggestions for MyBikePlan Instance */}
					{showHeight &&
						<div className="col-auto"
							style={{width: 153}}>
							<Typography variant="bodySmBold"
								className="px-3"
								style={{marginBottom: 12}}>{t("Your height")}</Typography>
							{
								height?.options?.map(({name, slug}) =>
									<div key={generateUUID()}
										className="col-auto">
										<Link href={filterUrl({
											[urlParams.categories]: activeItemLink,
											[urlParams.bikerHeight]: slug
										}, null, null)}
										passHref
										legacyBehavior>
											<a onClick={() => {
												sessionStorage.setItem("page", (0).toString())
												sessionStorage.setItem("scrollPosition", (0).toString())
											}}
											className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
												<Typography variant={"bodySm"}>{name}</Typography>
											</a>
										</Link>
									</div>
								)
							}
						</div>}

					{/* Show brands section */}
					{activeItemLink !== "is_2025" &&
                    activeItemLink !== "sale_" &&
                    activeItemLink !== "best_sellers" && <div style={{width: "auto"}}
						className="col-auto">
						<Typography variant="bodySmBold"
							style={{marginBottom: 12}}
							className="px-3">{item.slugName === "all-brands" ? t("alle-marken") : t("top-brands")}</Typography>
						{isAccessoriesActive ?
							<ul className="list-unstyled">
								{dataItem.simpleBrands ? dataItem.simpleBrands?.map(item => {
									const {name, slug} = item
									return <div key={generateUUID()}>
										<Link href={filterUrl({
											[urlParams.brand]: slug
										}, null, null)}>
											<a
												style={{backgroundColor: query.brand === slug && "#eff1f3"}}
												className={`text-decoration-none rounded-3 text-dark 
                                                        d-inline-block ${styles.megamenuItem}`}>
												<Typography
													variant={`${query.brand === slug ?
														"bodySmBold" : "bodySm"}`}>
													{name}
												</Typography>
											</a>
										</Link>
									</div>
								}
								) : null}
							</ul> :
							// If there are TopBrands from Other instances provided we will showcase them
							otherInstancesBrandsForMegaMenu ?
								<ul className="list-unstyled">
									{otherInstancesBrandsForMegaMenu?.map((brand, idx) => {
										const {menuLabel, href} = brand
										return (
											<li key={idx}>
												<Link href={href}>
													<a target="_blank"
														onClick={() => {
															sessionStorage.setItem("page", (0).toString())
															sessionStorage.setItem("scrollPosition", (0).toString())
															reactGA?.event({
																category: gaCategories.megaMenu,
																action: gaEvents.topBrands,
																label: menuLabel,
																nonInteraction: false
															})
														}}
														className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
														<Typography variant={"bodySm"}>{menuLabel}</Typography>
													</a>
												</Link>
											</li>)
									})}
								</ul> :
								<div className="d-flex gap-4">
									{item.slugName === "all-brands" ?
										[0, 1, 2].map(columnIndex => (
											<ul key={columnIndex}
												className="list-unstyled mb-3"
												style={{flex: "1"}}>
												{sortedBrands?.slice(columnIndex * brandsPerColumn, (columnIndex + 1) * brandsPerColumn)?.map(({
													fields: {
														menuLabel,
														slug
													}
												}) => {
													const isPremiumBrand = premiumBrands.includes(slug)

													return (
														<li key={menuLabel}>
															<Link href={isPremiumBrand ? router.locale === "de" ? ` /brand-world/${slug}` : ` /fr/brand-world/${slug}` : filterUrl({[urlParams.brand]: slug}, null, null)}>
																<a onClick={() => {
																	sessionStorage.setItem("page", (0).toString())
																	sessionStorage.setItem("scrollPosition", (0).toString())
																	reactGA?.event({
																		category: gaCategories.megaMenu,
																		action: gaEvents.topBrands,
																		label: menuLabel,
																		nonInteraction: false
																	})
																}}
																className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
																	<Typography
																		variant={`${query.slug === slug ? "bodyLgBold" : "bodySm"}`}>{menuLabel}</Typography>
																</a>
															</Link>
														</li>
													)
												})}
											</ul>
										)) :
										<ul className="list-unstyled">
											{/* Refactor to load from strapi */}
											{topBrandsList?.map(({fields}, idx) => {
												const {menuLabel, slug, filterSlug} = fields
												const isPremiumBrand = premiumBrands.includes(slug)

												return (
													<li key={idx}>
														<Link href={isPremiumBrand ? router.locale === "de" ? ` /brand-world/${slug}` : ` /fr/brand-world/${slug}` : filterUrl({
															[urlParams.categories]: activeItemLink,
															[urlParams.brand]: filterSlug
														}, null, null)}>
															<div onClick={() => {
																sessionStorage.setItem("page", (0).toString())
																sessionStorage.setItem("scrollPosition", (0).toString())
																reactGA?.event({
																	category: gaCategories.megaMenu,
																	action: gaEvents.topBrands,
																	label: menuLabel,
																	nonInteraction: false
																})
															}}
															className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
																<Typography
																	variant={`${query.slug === slug ? "bodyLgBold" : "bodySm"}`}>{menuLabel}</Typography>
															</div>
														</Link>
													</li>)
											})}
										</ul>}
									{item.slugName === "all-brands" &&
										<div className="col-auto">
											<ul className="list-unstyled">
												{accessoriesBrands ? accessoriesBrands?.options?.map(({name, slug}) =>
													<div key={generateUUID()}>
														<Link href={filterUrl({
															[urlParams.brand]: slug
														}, null, null)}>
															<a
																style={{backgroundColor: query.brand === slug && "#eff1f3"}}
																className={`text-decoration-none rounded-3 text-dark 
                                                        d-inline-block ${styles.megamenuItem}`}>
																<Typography
																	variant={`${query.brand === slug ?
																		"bodySmBold" : "bodySm"}`}>
																	{name}
																</Typography>
															</a>
														</Link>
													</div>
												) : null}
											</ul>
										</div>
									}
								</div>
						}

						{dataItem?.allBrandsLink &&
							<div onClick={() => {
								onBtnClick()
								sessionStorage.setItem("page", (0).toString())
								sessionStorage.setItem("scrollPosition", (0).toString())

								reactGA?.event({
									category: gaCategories.megaMenu,
									action: gaEvents.allEBrandsButton,
									label: gaEvents.allEBrandsButton,
									nonInteraction: false
								})
							}}>
								<LinkWithIcon
									href={otherInstancesCategoryTitles.includes(item?.title) ? "https://www.mysofaplan.ch/alle-produkte-schweiz/kategorie/-/marke/-/farbe/-/preisbereich/-/verfugbar/-/sortieren/preis/relevanz/-" : "/alle-marken"}
									label={t("Alle Marken")}
									iconBgColor="#EBEBEB"
									iconMargin="ms-2"
									labelVariant={"bodySmBold"}
									padding={"5.5px 16px"}
									icon={
										<Image src="/assets/icons/chevron-dark-bg-light.svg"
											width={20}
											height={20}
											alt="chevron right"/>
									}/>
							</div>}
					</div>}

				</div>

			</div>
			{dataItem?.showSuggestionSection ? <div onClick={() => {
				sessionStorage.setItem("page", (0).toString())
				sessionStorage.setItem("scrollPosition", (0).toString())
			}}
			className="d-flex justify-content-center align-items-center"
			style={{padding: "8px 8px", backgroundColor: "#F8F8F8", borderBottomLeftRadius: "32px",
				borderBottomRightRadius: "32px"}}>
				<Typography variant="bodySm"
					semanticTag="span"
					className="me-2">{t("mega-menu-title-text-1")}</Typography>
				<LinkWithIcon
					href={"/suggestion/1"}
					label={t("start-quiz")}
					iconBgColor="#EBEBEB"
					iconMargin="ms-2"
					labelVariant={"bodySmBold"}
					target={"_blank"}
					padding={"4px"}
					icon={
						<Image src="/assets/icons/chevron-dark-bg-light.svg"
							width={20}
							height={20}
							alt="chevron right"/>
					}/>
			</div> : null}
		</>
	)
}

export default DesktopMegaMenuItem

import Typography from "../../reusable/Typography/Typography"
import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Button from "../../reusable/components/Button"
import Image from "next/image"
import BookAppointmentButton from "../../reusable/ModalButtons/BookAppointmentButton"
import Link from "next/link"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

interface Props {
	imageSrc: string
	title: string
	description: string
	btnLabel?: string
	btnIconSrc?: string
	btnHref?: string
	isConsultationBtn?: boolean
	titleWidth?: string
	leadMagnetsModalDesign?: boolean
    closeModal?: () => void
}

const FindPerfectBikeCard: React.FC<Props> = ({closeModal = () => {}, imageSrc, title, description, btnLabel, leadMagnetsModalDesign, btnIconSrc, isConsultationBtn, btnHref, titleWidth}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	return (

		<div className={`col-12 info-card ${leadMagnetsModalDesign ? "p-0" : "p-3 pb-4"}`}
			style={{backgroundColor: "#F8F8F8", borderRadius: 32}}>
			<div className="col-12 info-card-image position-relative">
				<Image src={imageSrc}
					layout={"fill"}
					objectFit={"cover"}
				/>
				<Typography variant="heading1Black"
					className={`position-absolute text-white info-card-title pe-2 
						${leadMagnetsModalDesign && "d-none"}`}
					style={{width: titleWidth}}>{title}</Typography>

				{leadMagnetsModalDesign &&
					<div className="d-flex justify-content-between align-items-center position-absolute"
						style={{bottom: 16, left: 16, right: 16, zIndex: 1000}}>
						<Typography variant="bodySmBlack"
							className={"col-8 text-white"}>{title}</Typography>
						<Link href={btnHref} >
							<a onClick={() => closeModal()}>
								<div
									className="col-auto leadmagnet-card-btn d-flex align-items-center justify-content-center"
									style={{width: 40, height: 40, backgroundColor: "#FEC04D"}}>
									<Image src={"/assets/icons/arrow-right.svg"}
										width={18}
										height={16}
										alt="arrow right" />
								</div>
							</a>
						</Link>
					</div>
				}
			</div>

			{!leadMagnetsModalDesign &&
			<div className="info-card-text d-flex flex-column align-items-baseline align-items-center mt-4">
				<Typography variant={"bodyLg"}
					semanticTag="span"
					style={{minHeight: 70}}>{description}</Typography>
				<div className="col-12 col-md-auto mt-3"
					onClick={() => closeModal()}>
					{isConsultationBtn ?
						<BookAppointmentButton
							high
							fontSizeSmall
							textColor="#212529"
							bgColor="#FEC04D"
							icon={<Image src={"/assets/icons/phone.svg"}
								width={20}
								height={20} />}
							color="#212529"
							padding={"2px 0px"}
							label={t("Beratung Buchen")}
							iconFirst/> :
						<Button
							href={btnHref}
							onClick={() => {
								reactGA?.event({
									categories: gaCategories.homePage,
									action: gaEvents.homePageFindPerfectBike,
									label: `${gaEvents.homePageFindPerfectBike}: ${title} `,
									nonInteraction: false
								})
							}}
							fontSizeSmall
							bgColor="#FEC04D"
							textColor="#212529"
							color="#212529"
							className="w-100">
							<Image src={btnIconSrc}
								width={20}
								height={20}
								objectFit="contain"/>
							<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{btnLabel}</Typography>
						</Button> }
				</div>
			</div>}
		</div>
	)
}

export default FindPerfectBikeCard

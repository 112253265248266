import React from "react"
import Link from "next/link"
import Typography from "../Typography/Typography"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import {colors} from "../../../colors/Colors"

type Props = {
	href?: string
	onClick?: () => void
	label: string
	icon?: React.ReactNode
	iconMargin?: any
	color?: string
	labelVariant?: any
	iconBgColor?: string
	padding?: string
	target?: string
	isBrandWorld?:boolean
    iconFirst?: boolean
    borderRadius?: string | number
    backgroundColor?: string
}

const LinkWithIcon = ({
	onClick,
	target,
	href,
	label,
	color = "#212529",
	icon,
	labelVariant = "bodyLgBold",
	iconBgColor = "#805B34",
	padding,
	iconMargin,
	isBrandWorld,
	iconFirst,
	borderRadius,
	backgroundColor
}: Props): React.ReactElement => {
	const {t} = useTranslation()
	const {primary} = colors
	return (
		<>
			{href ? <Link href={href}>
				<a className={"text-decoration-none d-inline-block"}
					style={{color: "#212529"}}
					target={target && target}>
					<div className="d-flex align-items-center rounded read-more-btn"
						style={{padding: padding ? padding : "8px"}}>
						<Typography
							style={{color: isBrandWorld ? "#fff" : "#212529"}}
							variant={labelVariant}
							semanticTag="span">{t(label)}</Typography>
						{icon ?
							<div className={`d-flex align-items-center ${iconMargin ? iconMargin : ""}`}>{icon}</div> :
							<div className="d-flex justify-content-center align-items-center"
								style={{
									width: 20, height: 20, background: iconBgColor ? iconBgColor : `${primary}`,
									borderRadius: "50%", marginLeft: 10
								}}>
								<Image src={"/assets/icons/arrow-right-mob.svg"}
									width={6.5}
									height={12}/>
							</div>
						}
					</div>
				</a>
			</Link> :
				<div className={"text-decoration-none d-inline-block cursor-pointer"}
					style={{color: "#212529"}}
					onClick={onClick && onClick}>
					<div className={`d-flex align-items-center read-more-btn ${iconFirst && "flex-row-reverse"}`}
						style={{padding: padding ? padding : "8px",
							borderRadius: borderRadius ? borderRadius : 4,
							backgroundColor: backgroundColor ? backgroundColor : ""}}>
						<Typography variant={labelVariant}
							semanticTag="span">{t(label)}</Typography>
						{icon ?
							<div className={`d-flex align-items-center ${iconMargin ? iconMargin : ""}`}>{icon}</div> :
							<div className="d-flex justify-content-center align-items-center"
								style={{
									width: 20, height: 20, background: iconBgColor ? iconBgColor : `${primary}`,
									borderRadius: "50%", marginLeft: 10
								}}>
								<Image src={"/assets/icons/arrow-right-mob.svg"}
									width={6.5}
									height={12}/>
							</div>
						}
					</div>
				</div>
			}
		</>

	)
}

export default LinkWithIcon

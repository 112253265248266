import React, {useMemo} from "react"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import {getSortedItemsByBikeSlug} from "../filter/utility"
import {useRouter} from "next/router"

type Props = {
    items: any[],
    label: string,
    bike: any
}

const RelatedProducts = ({items, label, bike}: Props) => {
	const {t} = useTranslation()
	const sortedItems = useMemo(() => getSortedItemsByBikeSlug(items, bike?.slug), [bike])
	const {query} = useRouter()

	return (items?.length ?
		<div className="d-flex gap-2 align-items-center mt-3">
			<Typography variant="bodySmBold"
				className="col-auto "
				semanticTag="span">
				{label}
			</Typography>
			<div className="col-8">
				<div className={"row g-1"}>
					{sortedItems.map((rel: any) => <div key={rel.slug}
						className={"col-auto"}>
						<Link key={rel.slug}
							href={`/${"produkt"}/${rel.slug}`}>
							<a className="d-inline-block link-reset cursor-pointer filter-item-box"
								style={{
									padding: "10px 16px",
									borderRadius: 16,
									border: query.bike === rel.slug ? "1px solid #212529" : "1px solid #DEE2E6",
									color: "#212529",
									backgroundColor: bike.slug === rel.slug ? "#FFF2DB" : ""
								}}>
								<Typography variant={"bodySm"}
									semanticTag={"span"}>
									{t(rel.label)}
								</Typography>
							</a>
						</Link>
					</div>)}
				</div>
			</div>
		</div> : null
	)
}

RelatedProducts.propTypes = {}

export default RelatedProducts

import React, {useContext, useEffect, useState} from "react"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import NavTabLink from "./NavTabLink"
import FirebaseContext from "../../../context/FirebaseContext"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import NewMobileMegaMenu from "./NewMobileMegaMenu"

export const onMegaMenuBtnClick = (router, pathname) => {
	if (!router) {
		console.error("Router is undefined")
		return
	}

	const params = new URLSearchParams()

	if (router?.query) {
		Object.entries(router.query).forEach(([key, value]) => {
			params.set(key, value as string)
		})
	}

	if (params.has("megamenu")) {
		params.delete("megamenu")
		router.replace(`${pathname}?${params}`, null, {shallow: true, scroll: false})
		return
	}

	params.append("megamenu", "open")
	router.push(`${pathname}?${params}`, null, {shallow: true, scroll: false})
}

function NavigationTabs() {
	const router = useRouter()
	const {pathname} = router
	const {asPath} = router
	const isMenuVisible = router?.query?.megamenu === "open" || false
	const [isBackgroundOn, setIsBackgroundOn] = useState(false)
	const {t} = useTranslation()
	const {user} = useContext(FirebaseContext)
	const toggleMenu = e => {
		e.stopPropagation()
		onMegaMenuBtnClick(router, pathname)
	}

	useEffect(() => {
		if (!isBackgroundOn) {
			document.body.style.overflow = "auto"
		} else if (isBackgroundOn) {
			document.body.style.overflow = "hidden"
		}
	}, [isBackgroundOn])

	useEffect(() => {
		if (!isMenuVisible) {
			document.body.style.overflow = "auto"
		} else if (isMenuVisible) {
			document.body.style.overflow = "hidden"
		}

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [isMenuVisible])

	const links = [
		{
			isActive: !isMenuVisible && pathname === "/",
			href: "/",
			imgSrc: "/assets/navigationTabs/home.svg",
			label: t("home-tab")
		},
		{
			isActive: !isMenuVisible && pathname.includes("/wish-list"),
			href: `${t("/wish-list")}/${user?.uid || ""}`,
			imgSrc: "/assets/navigationTabs/heart.svg",
			label: t("wish-list-tab")
		},
		{
			isActive: !isMenuVisible && asPath === "https://api.whatsapp.com/send?phone=41435051318",
			href: "https://api.whatsapp.com/send?phone=41435051318",
			imgSrc: "/assets/navigationTabs/whatsapp.svg",
			label: t("write-us-tab")
		},
		{
			isActive: isMenuVisible,
			onClick: toggleMenu,
			imgSrc: "/assets/navigationTabs/catalog.svg",
			label: t("catalog-tab")
		},
		{
			isCart: true,
			isActive: !isMenuVisible && pathname.includes(t("/checkout")),
			href: t("/checkout"),
			imgSrc: "/assets/navigationTabs/cart.svg",
			label: t("cart-tab")
		}
	]
	return (
		<>
			<div className="nav-tabs d-md-none">
				<div className="d-flex align-items-between justify-content-center container py-2">
					{links.map((link, index) => (
						<NavTabLink key={index}
							{...link} />
					))}
				</div>

			</div>
			<div>

				<AnimationComponent type="slideRight"
					inProp={isMenuVisible}>
					<div
						className={"position-fixed vh-100 w-100 border-top overflow-auto"}
						style={{zIndex: 99999, top: 0}}
						onClick={toggleMenu}>
						<NewMobileMegaMenu showSearch
							onMegaMenuBtnClick={onMegaMenuBtnClick} />
					</div>
				</AnimationComponent>
			</div>
		</>
	)
}

export default NavigationTabs

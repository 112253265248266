import React, {useEffect, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import RyderAiStaticTile from "./staticTiles/RyderAiStaticTile"
import Button from "../reusable/components/Button"
import {filterUrl} from "../filter/FilterUrl"
import SearchResultItems from "../search/SearchResultItems"
import ContactUsOnEmptyPlp from "./ContactUsOnEmptyPlp"
import {clarityEvents} from "../../config/googleAnalytics/events"

type NoBikesProps = {
  count: number;
};

const NoBikesState: React.FC<NoBikesProps> = ({count}) => {
	const {t} = useTranslation("common")
	const [bikeRecommendations, setBikeRecommendations] = useState([])
	useEffect(() => {
		if (typeof window !== "undefined") {
			window.clarity("set", clarityEvents.zeroResultPage, "User Landed on zero results page")
		}
	}, [])
	return (
		<div style={{gap: "16px"}}
			className="d-flex container containe-md-none justify-content-start align-items-start flex-column mt-2 mb-3">
			<Typography style={{color: "#212529", fontSize: "28px"}}
				variant="bodyLgBlack"
				semanticTag="div"
				className="col-auto">
				{t("Leider entsprechen keine E-Bikes diesen Kriterien.")}
			</Typography>

			<Typography style={{color: "#212529", fontSize: "14px", lineHeight: "20px"}}
				variant="bodySm"
				semanticTag="div"
				className="col-auto">
				{t("talk-to-ryder")}
			</Typography>
			<div className="d-flex col-12 flex-md-row flex-column gap-4 align-items-start justify-content-evenly ">
				<RyderAiStaticTile setBikeRecommendations={setBikeRecommendations}
					customPredefinedQuestions={["mountain-bike-predefined-question",
						"city/trekking-bike-predefined-question",
						"velo-predefined-question"]}
					customLayoutClassname="d-flex align-items-start
					justify-content-evenly col-md-6 col-12 h-100 flex-column"
					isEmptyPage />
				{/* for this results displaying to show below contact only for desktop */}
				<div className=" d-md-none  col-12">
					{bikeRecommendations?.length ?
						<div className={`col-12  ai-suggestions-slider ${bikeRecommendations?.length ? "slide-in-right" : ""}`}>
							<Typography semanticTag="div"
								className="my-2"
								style={{fontSize: "14px", lineHeight: "20px"}}
								variant="bodySmBold">{t("bikes-added-to-wishlist")}</Typography>
							<SearchResultItems isEmptyPage
								isAiResponse
								list={bikeRecommendations || []}
								isAccessory />
						</div> : null}
				</div>
				<div style={{height: "1px", background: "#DEE2E6"}}
					className="col-12 d-md-none my-3" />
				<ContactUsOnEmptyPlp />
			</div>
			{/* for this results displaying to show below contact only for desktop */}
			<div className="d-none d-md-block col-12">
				{bikeRecommendations?.length ?
					<div className={`col-12 ai-suggestions-slider ${bikeRecommendations?.length ?
						"slide-in-right" : ""}`}>
						<Typography semanticTag="div"
							className="my-2"
							style={{fontSize: "14px", lineHeight: "20px"}}
							variant="bodySmBold">{t("bikes-added-to-wishlist")}</Typography>
						<SearchResultItems isEmptyPage
							isAiResponse
							list={bikeRecommendations || []}
							isAccessory />
					</div> : null}
			</div>
			<div style={{height: "1px", background: "#DEE2E6"}}
				className="col-12 my-3" />
			<Typography style={{color: "#212529", fontSize: "14px", lineHeight: "20px"}}
				variant="bodySm"
				semanticTag="div"
				className="col-auto">
				{t("Entferne einen oder mehrere Filter, um mehr E-Bikes zu sehen.")}
			</Typography>
			<Button
				label={t("reset-filters")}
				bgColor="white"
				href={filterUrl({}, null, null)}
				fontSizeSmall
				outline
				color="#000"/>
		</div>
	)
}

export default NoBikesState

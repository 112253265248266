import React from "react"
import PropTypes from "prop-types"
import {ColorData} from "../../../utility/Types"

type props = {
	currentColor?: ColorData
	colors?: ColorData[]
}

function ProductColors({colors, currentColor}: props) {
	if (Array.isArray(colors) && colors?.length) {
		return (
			<div className="d-flex  col-12 justify-content-start gap-1 flex-wrap align-items-center">
				{colors.map((item, index) =>
					<div style={{
						borderRadius: 4,
						width: 20,
						border: "1px solid #edebeb",
						height: 20,
						backgroundColor: item.color
					}}
					key={index}>
					</div>
				)}
			</div>
		)
	}

	return null
}

ProductColors.propTypes = {
	colors: PropTypes.any.isRequired
}

export default ProductColors

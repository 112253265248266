import Link from "next/link"
import React from "react"
import getMainCategory from "../../filter/GetMainCategory"
import {useTranslation} from "next-i18next"
import useBadges from "../../../hooks/useBadges"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"
import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"

type Props = {
    bike: any
    className?: string
}

const PDPBreadcrumb: React.FC<Props> = ({
	bike,
	className
}) => {
	const {t} = useTranslation()
	const {isNonEBike} = useBadges(bike)
	const mainCategory = getMainCategory(bike?.categories)
	const mainCategoryName = t(mainCategory?.name)
	const isAccessory = bike?.categories?.some(cat => cat?.slug === "zubehoer")
	const generalCategorySelection = isAccessory ? t("zubehoer") : isNonEBike ?
		t("nicht-elektrische-bikes") : t("e-bikes")

	return (
		<div className={`d-flex align-items-center pdp-breacrumbs ${className ? className : ""}`}>
			<Link href={filterUrl({}, null, null)}>
				<a className="link-reset">
					<Typography variant="bodyXSm"
						semanticTag="span">{t("Alle Modelle")}</Typography>
				</a>
			</Link>
			<Image src="/assets/icons/chevron-right-gray.svg"
				width={16}
				height={16} />
			<Link href={filterUrl({[urlParams.categories]: generalCategorySelection}, null, null)}>
				<a className="link-reset">
					<Typography variant="bodyXSm"
						semanticTag="span">
						{isAccessory ? t("Accessories") : isNonEBike ? "Velos" : "E-Bikes"}
					</Typography>
				</a>
			</Link>
			<Image src="/assets/icons/chevron-right-gray.svg"
				width={16}
				height={16}/>
			<Link href={filterUrl({[urlParams.categories]: mainCategory?.slug}, null, null)}>
				<a className="link-reset">
					<Typography variant="bodyXSm"
						semanticTag="span">
						{mainCategoryName || ""}
					</Typography>
				</a>
			</Link>
		</div>
	)
}

export default PDPBreadcrumb

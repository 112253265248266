import React, {useContext} from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../../context/LayoutContext"
import BookAppointmentButton from "../../reusable/ModalButtons/BookAppointmentButton"
import BookConsultation from "../../bikeDetails/BookConsultation"

export const SignUpForConsultationBanner = () => {
	const {t} = useTranslation("PDPTiles")
	const {isListView, device} = useContext(LayoutContext)
	const tablet = device === "tablet"
	const bgImageStyles = isListView ? {} : {
		backgroundImage: "url('/assets/images/banners/banner-near-bike-consultation.jpg')",
		backgroundPosition: "center",
		backgroundSize: "cover",
		padding: "48px 72px",
		outline: "1px solid #DEE2E6"
	}
	return (
		<>
			<div className="d-md-none mb-5">
				<BookConsultation/>
			</div>

			{isListView ?
				<div className="row justify-content-center py-4 d-none d-md-flex">
					<BookConsultation isListView
						className="col-auto"
						imageWidth={81}/>
				</div>				:
				<div style={{...bgImageStyles, maxHeight: "432px", height: "100%", minWidth: "250px", margin: "0 0.5px"}}
					className={`d-none d-md-flex row position-relative ${!isListView && " pb-0"} justify-content-center ${isListView && "mb-4"}`}>

					<div
						className={`${isListView ? "col-md-6 col-lg-4 px-0 pe-4  my-auto" : "col-12 col-lg-12  position-absolute bottom-0 mb-3 px-4  text-white"}`}>
						<div className={`text-center ${!isListView && "mb-lg-2 mb-md-5"}`}>
							<Typography className={"mb-md-4 mb-3 mb-lg-2 px-3 px-lg-2"}
								variant={tablet ? "bodyLgBold" : "heading3"}
								semanticTag="h4">
								{t("cta-findYourBike-content")}
							</Typography>
							<BookAppointmentButton plp
								label={t("Beratung Buchen")}
								color="#212529"
								textColor="#212529"
								bgColor="#FEC04D"
								high
								fontSizeSmall={!isListView}
								className="mt-4"/>
						</div>
					</div>
				</div>
			}
		</>
	)
}

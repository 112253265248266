import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import SearchContext from "../context/SearchContext"
import {useRouter} from "next/router"
import {localStorageKeys} from "../constants/LocalStorageKeys"
import AnalyticsContext from "../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../config/googleAnalytics/events"

type Props = {
    children: React.ReactNode
}

const SearchContextProvider: React.FC<Props> = ({children}) => {
	const [isOn, toggle] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const [results, setResults] = useState(null)
	const [value, setValue] = useState(null)
	const [recentSearchTerms, setRecentSearchTerms] = useState<string[]>([])
	const [recentSearchTermsRefs, setRecentSearchTermsRefs] = useState<any[]>([])
	const [termValue, setTermValue] = useState("")
	const {reactGA} = useContext(AnalyticsContext)

	const router = useRouter()

	useEffect(() => {
		// Please make sure to store somewhere the local storage key in an enum or object to avoid using magic strings like in my example below :)
		const localRecentSearchTerms = localStorage.getItem(localStorageKeys.recentSearchTerms)
		if (localRecentSearchTerms) {
			setRecentSearchTerms(JSON.parse(localRecentSearchTerms))
		}
	}, [])

	useEffect(() => {
		localStorage.setItem(localStorageKeys.recentSearchTerms, JSON.stringify(recentSearchTerms))
	}, [recentSearchTerms])

	const handleSearch = async term => {
		const dynamicHandleSearch = (await import("../search/handleSearch")).handleSearch
		try {
			reactGA?.event({
				category: gaCategories.search,
				action: gaEvents.searchTerm,
				label: `${gaEvents.searchTerm} | ${term}`,
				nonInteraction: false
			})
		} catch (e) {
			console.error("Could not send search term event", e)
		}

		return dynamicHandleSearch(term, setRecentSearchTerms, router, setResults, setLoading)
	}

	return (
		<SearchContext.Provider value={{isOn, toggle, isLoading, setLoading, results, setResults, value, setValue, handleSearch, recentSearchTerms, recentSearchTermsRefs, setRecentSearchTermsRefs, termValue, setTermValue}}>
			{children}
		</SearchContext.Provider>
	)
}

SearchContextProvider.propTypes = {
	children: PropTypes.node
}

export default SearchContextProvider

import Image from "next/image"
import React from "react"
import styles from "./PureSlider.module.scss"

type Props = {
    prevSlide: () => void;
    nextSlide: () => void;
    children?: any,
    arrowsBottom?: boolean
    arrowsPositionX?: number
}

const SliderArrows: React.FC<Props> = ({
	prevSlide,
	nextSlide,
	children,
	arrowsBottom,
	arrowsPositionX
}) => {
	return (
		<div className={arrowsBottom ? "d-flex align-items-center justify-content-between" : ""}>
			<button onClick={prevSlide}
				className={`${styles["prev-slide"]} ${arrowsBottom ? styles["arrow-left-down"] : "position-absolute"}`}
				style={{
					left: arrowsPositionX ? arrowsPositionX : -50
				}}
			>
				<Image src="/assets/icons/arrow-left.svg"
					width={16}
					height={12}
					alt="Previous" />
			</button>
			{children ? children : null}
			<button onClick={nextSlide}
				className={`${styles["next-slide"]} ${arrowsBottom ? styles["arrow-right-down"] : "position-absolute"}`}
				style={{
					right: arrowsPositionX ? arrowsPositionX : -50
				}}
			>
				<Image src="/assets/icons/arrow-right.svg"
					width={16}
					height={12}
					alt="Next" />
			</button>
		</div>
	)
}

export default SliderArrows

import React from "react"

export type AnalyticsContextProviderState = {
    uuid: string | null,
    setUuid: (uuid: string | null) => void,
    clientId: string | null,
	reactGA: any | null
}

const AnalyticsContext = React.createContext<AnalyticsContextProviderState>({
	uuid: null,
	setUuid: () => {
		// Do nothing
	},
	clientId: null,
	reactGA: null
})

export default AnalyticsContext

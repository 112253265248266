import {dealPlanLengthFormater, getCountryBy, getCountryValueByLabel, removeUndefined} from "./Helper"
import {gaCategories, gaEvents} from "../config/googleAnalytics/events"

export const formatDeals = (deals: any[], contact: any, uid: string, checkoutId: string, discountCode: string) => {
	return deals.map(deal => {
		delete deal.initialPrice
		delete deal.uniqueId

		return {
			...removeUndefined(deal),
			deliveryTimeInDays: deal.deliveryTimeInDays,
			dealname: `${contact.firstname} ${contact.lastname}, ${deal.e_bike_choice}`,
			plan_length: dealPlanLengthFormater(deal.plan_length),
			amount: deal.amount * 0.25,
			google_user_id: contact.google_user_id,
			google_client_id: contact.google_client_id,
			all_agb: contact.all_agb ? "true" : "false",
			uid,
			checkout_id: checkoutId,
			discount_code: discountCode
		}
	})
}

export const formatContact = async contactData => {
	const contact = {...contactData}
	delete contact.onShipAddress
	delete contact.id_type
	delete contact.permit_valid_until_month
	delete contact.permit_valid_until_day
	delete contact.permit_valid_until_year
	delete contact.date_of_entry_month
	delete contact.date_of_entry_day
	delete contact.date_of_entry_year
	delete contact.date_of_birth_month
	delete contact.date_of_birth_day
	delete contact.date_of_birth_year
	delete contact.bob_agb
	delete contact.heidipay_agb
	delete contact.all_agb

	// Check country choice field and reformat if necessary
	if (!getCountryBy(contact.country_choice, "value")) {
		contact.country_choice = getCountryValueByLabel(contact.country_choice) || "Switzerland"
	}

	// Generate referral code
	contact.unique_referral_code = (contact.lastname + Math.floor((Math.random() * 90) + 10)).toUpperCase()

	const reformattedPhoneNumber: any | null = await reformatPhoneNumber(contact.prefix, contact.phone)
	const reformattedContactValues = {
		...contact,
		phone: reformattedPhoneNumber?.number || contact.phone
	}

	delete reformattedContactValues.prefix

	return reformattedContactValues
}

export const reformatPhoneNumber = async (prefix: string, phoneNumber: string | number) => {
	const phone = typeof phoneNumber === "string" ? phoneNumber : phoneNumber.toString()
	const _prefix = prefix ? prefix.split(" ")[0] : null
	const {isPossiblePhoneNumber, parsePhoneNumber} = await import("libphonenumber-js")
	// @ts-ignore
	const phoneFormatted = _prefix && phone && isPossiblePhoneNumber(phone, _prefix as any) ? parsePhoneNumber(phone, _prefix as any) : null
	return phoneFormatted || null
}

export const events = (data, reactGA) => {
	reactGA?.event({
		category: gaCategories.checkout,
		action: gaEvents.checkoutFormSubmitted,
		label: gaEvents.checkoutFormSubmitted,
		nonInteraction: false,
		value: data.planLength
	})

	// Log event
	reactGA?.event({
		category: gaCategories.checkout,
		action: gaEvents.checkoutStepCompleted,
		label: gaEvents.checkoutStepCompleted,
		nonInteraction: false,
		value: 2
	})

	// Log event
	reactGA?.event({
		category: gaCategories.checkout,
		action: gaEvents.checkoutStep2Completed,
		label: gaEvents.checkoutStep2Completed,
		nonInteraction: false
	})
}

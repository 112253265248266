import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import SearchContext from "../../context/SearchContext"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"
import {CollectionName} from "../../constants"
import ActiveSearchTab from "./ActiveSearchTab"
import Spinner from "../reusable/Spinner"
import SearchSuggestions from "./SearchSuggestions"
import LayoutContext from "../../context/LayoutContext"
import SearchResultItems from "./SearchResultItems"
import DataContext from "../../context/DataContext"

type Props = {
	setIsBackgroundOn: Dispatch<SetStateAction<boolean>>
}
const SearchResults = ({setIsBackgroundOn}:Props) => {
	const {results, value, isLoading, toggle} = useContext(SearchContext)
	const {isMobile} = useContext(LayoutContext)
	const {brandWorld} = useContext(DataContext)
	const {t} = useTranslation()
	const router = useRouter()
	const isBrandWorld = router.pathname.includes("/brand-world")
	const [activeTab, setActiveTab] = useState<string>("searchTab")
	useEffect(() => {
		router.events.on("routeChangeComplete", () => toggle(false))
		return () => {
			router.events.off("routeChangeComplete", () => toggle(false))
		}
	}, [router.events])

	return (
		(value?.length >= 3 && toggle) ?
			<div className="search-content bg-white"
				style={{width: "100%", overflowY: "auto", left: 0, zIndex: 99999000, marginTop: isBrandWorld ? "3rem" : isMobile ? "1rem" : "-3rem", height: "80dvh", borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}>
				<div className={`cypress-search-results-bikes-count-${results?.bikes?.length}   cypress-search-results-accessories-count-${results?.accessories?.length}`} />
				{<div className={` d-flex ${isMobile ? "flex-column-reverse" : "flex-row"} justify-content-evenly align-items-start`}>
					<SearchSuggestions setIsBackgroundOn={setIsBackgroundOn} />
					<div className={"col-12 col-lg-5 mb-4"}>
						<ActiveSearchTab setActiveTab={setActiveTab}
							activeTab={activeTab} />
						<div className={`${isMobile ? "d-flex flex-wrap justify-content-between " : "px-3"}`}
							style={{overflow: "hidden", marginBottom: "1rem", rowGap: "24px"}}>
							{isLoading ? <div className={"p-4"}>
								<Spinner />
							</div> : activeTab === "searchTab" && results ? <SearchResultItems list={results?.bikes } /> : results && results?.accessories?.length && activeTab === "accessoriesTab" ? <SearchResultItems list={results?.accessories || [] }
								isAccessory/> : null}
							{(results && (results.bikes?.length || results.accessories?.length)) || isLoading ? null : <Typography variant={"bodySm"}
								semanticTag={"div"}
								className={"mb-0 cursor-pointer"}>
								{t("no-results-found", {ns: "search"})}
							</Typography> }
						</div>
						<div onClick={() => {
							if (activeTab === "searchTab") {
								router.push(isBrandWorld ? `${filterUrl({[urlParams.searchValue]: value}, "accessory-type", value, CollectionName.BrandWorld, CollectionName.BrandWorld, brandWorld.brandSlug)}#products-listing` : filterUrl({[urlParams.searchValue]: value, page: "-"}, null, null))
							} else {
								router.push(filterUrl({}, null, null, CollectionName.Accessories))
							}
						}}
						className="d-flex cursor-pointer justify-content-start gap-2 aling-items-center"><Typography variant="bodySmBold"> {t("see-all-results", {ns: "search"})}</Typography> <img src="/assets/icons/chevron-dark-bg-light.svg"
								alt="see all results" /></div>
					</div>

				</div>
				}
			</div> : null
	)
}

export default SearchResults

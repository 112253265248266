import React, {FC, useState} from "react"
import {useTranslation} from "next-i18next"
import {findBikeAttributeByName, findBikeMetaFieldByName} from "../../utility/Helper"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"
import getMainCategory from "../filter/GetMainCategory"

const attributesInfo = {
	Attachments: {
		"Child seat": {
			icon: "/assets/icons/aboutThisBike/childSeat.svg",
			text: "Child seat attachable"
		},
		"Trailer connection": {
			icon: "/assets/icons/aboutThisBike/trailerConnection.svg",
			text: "Trailer connection"
		}
	},

	batterie_size: {
		icon: "/assets/icons/aboutThisBike/batteryCapacity.svg",
		text: "Battery capacity"
	},
	chargingTime: {
		icon: "/assets/icons/aboutThisBike/chargingTime.svg",
		text: "Charging time"
	},

	Geschwindigkeit: {
		icon: "/assets/icons/aboutThisBike/maxSpeed.svg",
		text: "Max speed"
	},
	Reichweite: {
		icon: "/assets/icons/aboutThisBike/maxRange.svg",
		text: "Max range"
	},
	"Max. Gewicht": {
		icon: "/assets/icons/weight_icon.svg",
		text: "Max Gewicht"
	},

	"Motor Marke": {
		icon: "/assets/icons/motor-brand.svg",
		text: "Motor Marke"
	},

	hex_color: {
		text: "Colors"
	}

}

interface Props {
	bike: any
	small?: boolean
	extraSmall?: boolean
	noTitle?: boolean
	twoColumn?: boolean
	isSecondHand?: boolean
	noIcon?: boolean
	isBikeDetails?: boolean
	isForPDP?: boolean
	mostAskedOnly?: boolean
    detailsInCustomSliderMobile?: boolean
}

const AboutThisBike: FC<Props> = ({
	detailsInCustomSliderMobile,
	isSecondHand = false,
	noIcon = false,
	isBikeDetails,
	mostAskedOnly,
	extraSmall,
	twoColumn,
	isForPDP,
	noTitle,
	small,
	bike
}) => {
	const {t} = useTranslation(["productDescrPage"])
	const year = findBikeMetaFieldByName(bike, "year")
	const mileage = findBikeMetaFieldByName(bike, "mileage")
	const [colorData, setColorData] = useState(bike?.colorData)

	const mainCatgory = getMainCategory(bike?.categories)?.name

	const attributes = Object.entries(attributesInfo)?.reduce((acc, [attrName, attrInfo]) => {
		const attrValue = findBikeAttributeByName(bike, attrName)

		if (!attrValue || attrValue.length === 0) {
			return acc
		}

		if (attrName === "Attachments") {
			const attachments = attrValue.map(name => {
				const attr = attrInfo[name]
				return {...attr, value: false}
			})
			return [...acc, ...attachments]
		}

		return [...acc, {...attrInfo, value: attrValue}]
	}, [])

	const updatedAttributes = [{icon: "", text: "Type", value: [mainCatgory]}, ...attributes]

	return (
		<>
			{detailsInCustomSliderMobile ?
				<div className="row gx-5 gy-4 pt-md-5  pb-4 px-3 justify-content-center">
					{updatedAttributes.map(({icon, text, value}, index) =>
						<div key={index}
							className={"col-4 px-2 d-flex flex-column gap-1 align-items-center text-center"}>
							{value ?
								<Typography variant={"bodySm"}
									semanticTag="span">
									{text === "Colors" ? null : `${t(text)}`}
								</Typography> : null}
							<Typography
								variant={"bodySmBold"}>
								{value ? text === "Colors" ? null : value : null}
							</Typography>
						</div>
					)}
				</div> :
				mostAskedOnly ?
					<div className="row gx-3">
						{attributes.map(({icon, text, value}) => (
							text === "Max range" || text === "Battery capacity" ||
                    text === "Max Gewicht" ||
                    text === "Motor Marke" ||
                    text === "Max speed" ?
								<div key={text}
									className="col-auto d-flex align-items-center">
									{icon &&
							<Image src={icon}
								width={small ? 20 : 24}
								height={small ? 20 : 24}
								alt="value"/>}
									<Typography
										variant={small ? "bodySmBold" : extraSmall ? "bodyXSmBold" : "bodyLgBold"}
										className="ms-2">{value && value}</Typography>
								</div> : null
						))}
					</div> : null}
			{isForPDP &&
			<div className={"row gy-md-4 bike-highlights-pdp"}>
				{attributes.map(({icon, text, value}, i) =>
					(text !== "Colors" && value && <div key={i}
						className={`${twoColumn ? "col-6" : "col-12"} d-flex col-md-4`}>
						<div className="col-auto d-none d-md-block">
							{ value ?
								<span className={"col-auto me-3"}><Image src={icon}
									alt="icon"
									width={48}
									height={48}
									objectFit="contain"/></span> : null}
							<>
								<Typography
									variant={small ? "bodySmBold" : extraSmall ? "bodySmBold" : "bodyLgBold"}>
									{value ? value : null}
								</Typography>
								{value ?
									<Typography variant={small ? "bodySm" : extraSmall ? "bodyXSm" : "bodyLg"}
										semanticTag="span">
										{text === "Colors" ? null : `${t(text)}`}
									</Typography> : null}
							</>
						</div>
						<div className="col-12 d-flex align-items-start d-md-none mb-3">
							{ value ?
								<span className={"col-auto me-3"}><Image src={icon}
									alt="icon"
									width={24}
									height={24}
									objectFit="contain"/></span> : null}
							<div>
								<Typography
									variant={small ? "bodySmBold" : extraSmall ? "bodySmBold" : "bodyLgBold"}
									semanticTag="div">{value ? value : null}</Typography>
								{value ?
									<Typography variant={small ? "bodySm" : extraSmall ? "bodyXSm" : "bodyLg"}
										semanticTag="p"
										className="mb-0">{text === "Colors" ? null : `${t(text)}`}</Typography> : null}
							</div>
						</div>
					</div>)
				)}
			</div> }
			{!isForPDP && (!detailsInCustomSliderMobile && !mostAskedOnly) &&
			<div className="row px-3">
				<div className="col-12 px-0">
					{!noTitle &&
					<Typography className="d-none d-md-block"
						variant={small ? "bodySmBold" : "bodyLgBold"}
						semanticTag="h4">{t("aboutBike-title")}:</Typography>}
				</div>
				<div className="col-12 d-flex px-0 flex-wrap">

					{isSecondHand && (<>
						<div className={`${twoColumn ? "col-6" : "col-12"} col-md-6 mt-3`}>
							<span className={`col-auto ${small ? "me-2" : "me-3"}`}>
								<img src={"/assets/icons/aboutThisBike/calendar.svg"}
									height={small ? 16 : 23}/>
							</span>
							<Typography variant={small ? "bodySm" : "bodyLg"}
								semanticTag="span"
								className="">{`${t("aboutBike-year")}: ${year}`}</Typography>
						</div>

						<div className={`${twoColumn ? "col-6" : "col-12"} col-md-6 mt-3`}>
							<span className={`col-auto ${small ? "me-2" : "me-3"}`}>
								<img src={"/assets/icons/aboutThisBike/mileage.svg"}
									height={small ? 16 : 23}/>
							</span>
							<Typography variant={small ? "bodySm" : "bodyLg"}
								semanticTag="span"
								className="">{`${t("aboutBike-mileage")}: ${mileage} km`}</Typography>
						</div>
					</>)}
					<div className={"grid-cols-2"}>
						{attributes.map(({icon, text, value}, i) =>
							<div key={i}
								className={""}>
								{text !== "Colors" && value ?
									<span className={`col-auto me-3 ${noIcon && "d-none"}`}><img src={icon}
										alt="icon"/></span> : null}
								<div className="col-auto mx-md-2">
									{text === "Colors" && !isBikeDetails ?
										<>
											<div className="d-flex">
												{colorData?.map((color, i) =>
													<div key={i}
														className="col-auto me-2"
														style={{width: 16, height: 16, borderRadius: "50%",
															backgroundColor: `${color.color}`}}/>
												)}
											</div>
											<Typography
												variant={small ? "bodySmBold" : extraSmall ? "bodySmBold" :
													"bodyLgBold"}
												style={{fontSize: small ? 14 : extraSmall ? 12 : 18}}>
                                                    Colors
											</Typography></> :
										<>

											<Typography
												variant={small ? "bodySmBold" : extraSmall ? "bodySmBold" :
													"bodyLgBold"}
												style={{fontSize: small ? 14 : extraSmall ? 12 : 18}}>
												{value ? text === "Colors" ? null : value : null}
											</Typography>
											{value ?
												<Typography variant={small ? "bodySm" : extraSmall ? "bodyXSm" :
													"bodyLg"}
												semanticTag="span">
													{text === "Colors" ? null : `${t(text)}`}
												</Typography> : null}
										</>
									}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			}

		</>
	)
}

export default AboutThisBike

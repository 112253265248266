import React, {useContext, useEffect, useState} from "react"
import {Stars} from "react-bootstrap-icons"
import Typography from "../../reusable/Typography/Typography"
import Spinner from "../../reusable/Spinner"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import {useTranslation} from "next-i18next"
import CompatibleAccessory from "../../bikeDetails/CompatibleAccessory"
import {BikeType} from "../../../utility/Types"
import LayoutContext from "../../../context/LayoutContext"
import MobileModal from "../../reusable/MobileModal"
import Button from "../../reusable/components/Button"
import {useRouter} from "next/router"

type Props = { closeModal: any, bike:BikeType, loading: boolean, isModalOpen:boolean, aiText: string }
function PlpAiExplanation({closeModal, bike, isModalOpen, loading, aiText}: Props) {
	const {isMobile} = useContext(LayoutContext)
	const [isDrawerOpen, setIsDrawerOpen] = useState(null)
	const router = useRouter()
	const {t} = useTranslation()
	useEffect(() => {
		setIsDrawerOpen(isModalOpen)
	}, [isModalOpen])
	if (isMobile) {
		return <MobileModal className="h-75"
			modalTitle={t("is-this-for-me")}
			isAiModal
			modalOpen={isModalOpen}
			handleClose={closeModal}>
			<div style={{height: "100%", overflow: "auto"}}
				className="d-flex  align-items-start justify-content-start flex-column p-3 gap-3">

				{loading ?
					<div className=" col-12 d-flex align-items-center justify-content-center"> <Spinner /></div> :
					null}
				{aiText && !loading ? aiText.length === 2 ?
					<div className="d-flex slide-in-right px-3 pb-4 gap-2 align-items-start justify-content-start flex-column">
						<Typography style={{fontSize: "20px", lineHeight: "32px"}}
							variant="bodySmBold">{aiText[0]}</Typography>
						<CompatibleAccessory hideComparison
							product={bike}/>
						<div className="d-flex gap-3 align-items-start justify-content-start flex-column">
							<Stars />
							<Typography style={{fontSize: "14px", lineHeight: "20px"}}
								variant="bodySm">{aiText[1]}</Typography></div>
						<div className="d-md-none col-12 d-grid">
							<Button href={router.locale === "de" ?
								"https://meetings.hubspot.com/cristian127/consultation-call-coming-from-ai" :
								"https://meetings.hubspot.com/cristian127/fr-consultation-ai"}
							label={t("Beratung Buchen")}
							high
							bgColor="#212529"
							color="#fff"
							textColor="#fff"
							outline/>
						</div>
					</div> : aiText.length === 1 && <Typography style={{fontSize: "28px"}}
						variant="bodySmBold">{aiText[0]}</Typography> :
					!loading && <Typography style={{fontSize: "28px"}}
						variant="bodySmBold">{t("something-is-wrong")}</Typography>}
			</div>
		</MobileModal>
	}

	return (
		<>
			<AnimationComponent inProp={isDrawerOpen}
				type="fade">
				<div className="position-fixed vh-100 vw-100"
					style={{
						backgroundColor: "rgba(0,0,0,0.8)",
						zIndex: 10000005,
						top: 0, left: 0
					}}
					onClick={e => {
						e.stopPropagation()
						closeModal()
					}}>
				</div>
			</AnimationComponent>
			<div onClick={e => e.stopPropagation()}
				className={`position-fixed shadow-left px-2 py-4 bg-white top-0 bottom-0 end-0 ${isDrawerOpen === "false" ? "slide-out-right" : isDrawerOpen ? "slide-in-right" : "d-none"}`}
				style={{
					zIndex: 10000006,
					borderRadius: "16px 0px  0px  16px ",
					border: "1px solid #f4f4f4"
				}}>
				<div style={{borderRadius: "8px", maxWidth: "460px", overflow: "auto", height: "100%"}} >
					<div className="d-flex  align-items-start justify-content-start flex-column p-3 gap-2">
						<div className="d-flex col-12 align-item-center justify-content-center">
							<div className="d-flex align-items-center gap-2 justify-content-start ">
								<Stars /><Typography style={{fontSize: "56px", lineHeight: "64px"}}
									variant="bodySmBlack">{ t("is-this-for-me")}</Typography></div>
						</div>
						{loading ?
							<div className=" col-12 d-flex align-items-center justify-content-center h-100"> <Spinner />
							</div> : null}
						{aiText && !loading ? aiText.length === 2 ? <div style={{}}
							className="d-flex slide-in-right p-3 gap-3 align-items-start justify-content-start flex-column">
							<Typography style={{fontSize: "20px", lineHeight: "32px"}}
								variant="bodySmBold">{aiText[0]}</Typography>
							<CompatibleAccessory hideComparison
								product={bike}/>
							<div className="d-flex gap-3 overflow-auto align-items-start justify-content-start flex-column">
								<Stars />
								<Typography style={{fontSize: "14px", lineHeight: "20px"}}
									variant="bodySm">{aiText[1]}</Typography>
							</div>
							<div className="d-none col-12 d-md-grid">
								<Button href={router.locale === "de" ? "https://meetings.hubspot.com/cristian127/consultation-call-coming-from-ai" : "https://meetings.hubspot.com/cristian127/fr-consultation-ai"}
									label={t("Beratung Buchen")}
									high
									bgColor="#212529"
									color="#fff"
									textColor="#fff"
									outline/>
							</div>
						</div> : aiText.length === 1 || !loading ? <Typography style={{fontSize: "28px", lineHeight: "34px"}}
							variant="bodySmBold">{t("something-is-wrong")}</Typography> : null : null}
					</div>
				</div>
			</div>
		</>
	)
}

export default PlpAiExplanation

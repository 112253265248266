import slugify from "slugify"
import {fetchToken} from "../Helper"

export const uploadImageHandler = async (files: any, tag: string) => {
	try {
		const entries = Object.entries(files)
		const slugTag = tag.replace(/ /g, "-").trim().toLowerCase()
		const frontTag = slugify(`${slugTag}_front`, {lower: true}).trim()
		const backTag = slugify(`${slugTag}_back`, {lower: true}).trim()
		const rightTag = slugify(`${slugTag}_right`, {lower: true}).trim()
		const leftTag = slugify(`${slugTag}_left`, {lower: true}).trim()

		const promises = entries.map(([key, value]) => {
			if (key === "fileFront") {
				return uploadAws(value, slugify(frontTag).trim())
			}

			if (key === "fileBack") {
				return uploadAws(value, slugify(backTag).trim())
			}

			if (key === "fileRight") {
				return uploadAws(value, slugify(rightTag).trim())
			}

			if (key === "fileLeft") {
				return uploadAws(value, slugify(leftTag).trim())
			}

			return null
		})

		const linkResponses = await Promise.all(promises).then(values => values)
		const links = linkResponses.map(link => ({
			side: link.includes("front") ? "front" : link.includes("right") ? "right" : link.includes("left") ? "left" : "back",
			link
		}))

		return links
	} catch (e) {
		console.error(e)
		return []
	}
}

export const uploadAws = async (file: any, tag: string) => {
	try {
		const type = file.type.split("/")
		const uid = await fetchToken()
		const Key = encodeURIComponent(slugify(`${tag}_${uid}.${type[1]}`).trim())
		const Bucket = `uploadid-next-${process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? "production" : "dev"}`
		const ContentType = file.type
		const body = JSON.stringify({Bucket, Key, ContentType})
		const getSignedUrlResponse = await fetch("/api/aws/getsignedurl", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body
		}).then(response => {
			return response
		}).catch(e => {
			console.error(e)
			return null
		})

		const {url} = await getSignedUrlResponse.json()

		if (url) {
			const formData = new FormData()
			formData.append("file", file)
			try {
				await fetch(`${url}`, {
					method: "PUT",
					headers: {
						"Content-Type": file.type
					},
					body: file
				}).catch(e => {
					console.error(e)
				})

				const imageUrl = (`https://s3.console.aws.amazon.com/s3/object/${Bucket}?region=eu-central-1&prefix=${Key}`).trim()
				return imageUrl
			} catch (e) {
				console.error(e)
				return ""
			}
		}

		return ""
	} catch (e) {
		console.error(e.response)
		return ""
	}
}

export const fileKeys = {
	front: "picture_of_display",
	right: "picture_right_side",
	left: "picture_left_side"
}

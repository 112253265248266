export const gaEvents = {
	recentlyViewedTabClick: "Recently Viewed Bikes Tab Clicked",
	userOpenedWhatsappFromNotification: "Live Notification: User Went To Whatsapp",
	pdpTabClicked: "PDP Tab Clicked:",
	userClickedOnBikeFromNotification: "Live Notification: User Clicked On Recommended Bike",
	userClickedOnCreditCheckFromNotification: "Live Notification: User Clicked On Credit Check",
	recommendedForYouTabClick: "Recommended Bikes Tab Clicked",
	previousFiltersClicked: "Previous Filters Tab Clicked",
	zeroInterestTooltipClicked: "Zero Interest Tooltip Clicked",
	onlyShowBikeDifferencesClicked: "Only Show Bike Differenced Switch Clicked On PDP",
	compareAllSimilarBikesOnPdp: "Compare All Similar Bikes On PDP Clicked",
	tooltipClicked: "Tooltip Clicked",
	showMoreTextBtnClicked: "Show More Button Clicked",
	showLessTextBtnClicked: "Show Less Button Clicked",
	sixthFaqQuestion: "Last FAQ Clicked",
	fifthFaqQuestion: "Will My E-Bike Be Delivered To Home FAQ Clicked",
	fourthFaqQuestion: "When Is The First Installment Due FAQ Clicked",
	thirdFaqQuestion: "How To Calculate Monthly Price FAQ Clicked",
	secondFaqQuestion: "Second FAQ Clicked",
	firstFaqQuestion: "0% Interedt FAQ Clicked",
	disabledAddToCartClicked: "Add To Cart Clicked With Size Not Selected",
	goBackOnPdpClicked: "Go Back Button Clicked On PDP",
	addBookMark: "User Added Bike To Wishlist: ",
	deleteBookMark: "User Removed Bike From Wishlist: ",
	seeFullFaq: "See Full Faq Button Clicked",
	pdpShareButtonClicked: "Pdp Share Button Clicked",
	creditCheckClickedOnPLP: "Credit Check Clicked On PLP",
	getCustomOfferClickedOnPlp: "Get Custom Offer Clicked On PLP",
	oneTimePaymentCheckboxChecked: "Sofort kaufen checkbox Checked",
	myPlanGreySection: "My Plan Grey Section Collapsed",
	instagramLinkClicked: "Instagram Link Clicked",
	ebookDownloadBtnClicked: "E-book Download Button Clicked",
	serviceCentersButton: "Service Centers Button Clicked",
	allBlogsButtonClicked: "All Blogs Button Clicked",
	homePageFindPerfectBike: "Home Page Find Perfect Bike CTA Clicked:",
	homePageMainCategoryBoxClicked: "Carousel Main Category Clicked On Home Page",
	homePageSubCategoryBoxClicked: "Carousel SubCategory Clicked On Home Page",
	homePageCarouselArrowClicked: "Carousel Arrow Clicked On Home Page",
	mobileNavigationTab: "Clicked Mobile Navigation Tab",
	AiAnswer: "AI Answer On PDP:",
	userAskedComparisonAi: "User Asked Comparison AI:",
	askedPdpAi: "User wrote a question to PDP AI : ",
	wroteLifestyleDescription: "User Wrote Lifestyle to AI:",
	AiBikeEvaluation: "AI Evaluation of Bike based on lifestyle:",
	userChoseAISuggestion: "User Chose AI Generated Lifestyle Suggestion: ",
	userClickedAiBikeSuggestion: "User Chose AI Suggested Bike: ",
	storyWatched: "Story Watch Time",
	heroSectionRecommendations: "Clicked hero section recommendations",
	homepageStoryThumbnailClicked: "Clicked on a story",
	goToNextStory: "Go to the next story",
	storyCtaClicked: "Story CTA Clicked",
	goToPreviousStory: "Go to the previous story",
	heroSectionConsultation: "Clicked hero section consultation",
	heroSectionCreditCheck: "Clicked hero section credit check",
	wishListVisited: "Wish list visited",
	headers: "Header's",
	plp: "PLP",
	freeServicesDropdown: "Free services dropdown",
	pdp: "PDP",
	sent: "Sent",
	checkoutFormSubmitted: "Checkout Form Submitted",
	checkoutSuccessA: "Successful Checkout A",
	checkoutFailedA: "Failed Checkout A",
	checkoutSuccessB: "Successful Checkout B",
	checkoutFailedB: "Failed Checkout B",
	openStory: "Open Story",
	checkoutStepCompleted: "Checkout Step Completed",
	checkoutStep1Completed: "Checkout Step 1 Completed - Cart",
	checkoutStep2Completed: "Checkout Step 2 Completed - Contact Data Entry",
	checkoutStep3Completed: "Checkout Step 3 Completed - Confirmation",
	filteredProducts: "Filtered Products Collection",
	bookAppointment: "Book Appointment",
	askAQuestion: "Ask a Question",
	suggestProduct: "Suggest Product",
	setPlanLengthProductListingsPage: "Set Plan Length - Product Listing Page",
	setPlanLength: "Set Plan Length",
	setPlanLengthPLP: "Set Plan Length - Listing Page",
	setPlanLengthPDP: "Set Plan Length - Product Page",
	setPlanLengthCart: "Set Plan Length - Cart Page",
	planLength12: "Set Plan Length 12 Months - Product Page",
	planLength24: "Set Plan Length 24 Months - Product Page",
	planLength36: "Set Plan Length 36 Months - Product Page",
	planLength48: "Set Plan Length 48 Months - Product Page",
	planLengthCart12: "Set Plan Length 12 Months - Cart Page",
	planLengthCart24: "Set Plan Length 24 Months - Cart Page",
	planLengthCart36: "Set Plan Length 36 Months - Cart Page",
	planLengthCart48: "Set Plan Length 48 Months - Cart Page",
	planLengthProductListingsPage12: "Set Plan Length 12 Months - Product Listing Page",
	planLengthProductListingsPage24: "Set Plan Length 24 Months - Product Listing Page",
	planLengthProductListingsPage36: "Set Plan Length 36 Months - Product Listing Page",
	planLengthProductListingsPage48: "Set Plan Length 48 Months - Product Listing Page",
	planLength3: "Set Plan Length 3 Months - Product Page",
	planLength6: "Set Plan Length 6 Months - Product Page",
	planLength9: "Set Plan Length 9 Months - Product Page",
	planLength12ForUsed: "Set Plan Length 12 Months - Product Page",
	planLengthProductListingsPage3: "Set Plan Length 3 Months - Product Listing Page",
	planLengthProductListingsPage6: "Set Plan Length 6 Months - Product Listing Page",
	planLengthProductListingsPage9: "Set Plan Length 9 Months - Product Listing Page",
	planLengthProductListingsPage12ForUsed: "Set Plan Length 12 Months - Product Listing Page",
	newsletterFormSubmitted: "Newsletter Form Submitted",
	newsletterFormSubmittedBlog: "Newsletter Form Submitted Blog",
	search: "Search",
	searchTerm: "Search Term",
	searchTool: "Search Tool",
	brandsBellowSearch: "Brands Bellow Search",
	categoryBellowSearch: "Category Bellow Search",
	plpLayout: "plpLayout",
	addPlusOneToCart: "Add +1 To Cart",
	deleteFromCart: "Delete from Cart",
	seeCart: "See Cart",
	discountCode: "Discount Code",
	showTechnicalDetails: "Show Technical Details",
	showDetails: "Show Details",
	brandLogo: "Brand Logo",
	collectionView: "Collection View",
	blog: "Blog",
	quickAccessFilterCategories: "Section Categories",
	quickAccessFilterBrands: "Section Brands",
	quickAccessFilterCheckedItem: "Checked Item",
	quickAccessFilterAllCheckedItems: "All Checked Items",
	allProductsButton: "All Products Button Pressed",
	allEBrandsButton: "All Brands Button Pressed",
	recommendByCustomers: "Recommend By Customers",
	letsGoButton: "Lets's Go Button",
	googleReviews: "Google Reviews",
	homeCatBoxes: "Products Categories Boxes",
	topModels: "Top Models",
	partnersLogosSection: "Partners Logos Section",
	faq: "FAQ",
	priceRange: "Price Range",
	sortOut: "Sort Out",
	seeNextCustomerReview: "See Next Customer Review",
	deliveryAndFinancing: "Delivery and Financing",
	descriptionSection: "Description Section",
	highlightsAndSpecsTabs: "Highlights And Specs Tabs",
	askYourQuestion: "Ask Your Question Button Clicked",
	consultationPopUp: "Consultation Pop Up",
	newsletterPopUp: "Newsletter Pop Up",
	discountNewsletterPopup: "Discount Newsletter Pop Up",
	remindMeFiltersNoResults: "Remind me: Filter no results (PLP)",
	remindMeFiltersFewResults: "Remind me: Filter few results (PLP)",
	remindMeSizeUnavailable: "Remind me: Size unavailable (PDP)",
	unavailableSize: "Unavailable Size",
	addToCart: "Add To Cart",
	aiAddToCart: "Ai Suggestion Add To Cart",
	watchLiveQuestions: "Watch Live Questions",
	chooseYourGift: "Choose Your Gift",
	addProductToComparison: "Add Product To Comparison",
	removeProductFromComparison: "Remove Product From Comparison",
	removeAllProductsFromComparison: "Remove All Products From Comparison",
	goToComparisonPage: "Go To Comparison Page",
	startQuiz: "Start Quiz",
	category: "Category",
	topBrands: "Top Brands",
	failedToCreateCheckoutDocument: "Failed to create checkout document",
	downPaymentValue: "Down Payment Value",
	addInsurance: "Add Insurance",
	payOneTime: "Pay One Time",
	readMoreAboutCalingo: "Read More About Calingo",
	pdpExpertReviewFeature: "PDP_Expert Review Feature",
	whatsAppButtonTextUs: "WhatsApp Button TextUs",
	filterEBikes: "Filter EBikes",
	allEBikesButton: "All EBikes Button",
	suggestEBike: "Suggest EBikes",
	createSponsorMe: "Create Sponsor Me",
	applicationError: "Application Error",
	testDriveCTA: "Test Drive CTA",
	downPaymentBracketValue: "Down Payment Bracket Value",
	stickyBarCTA: "Sticky Bar CTA",
	plpBikeSliderInteraction: "PLP Bike Slider Interaction",
	openSlider: "Click Here Interaction to Open Slider from Bottom Sticky Bar",
	closeBottomStickyBar: "Close Icon Clicked in Bottom Sticky Bar",
	clickedOnLeadMagnet: "Click on lead magnet in popup slider"
}

export const clarityEvents = {
	purchase: "Purchase",
	emptyPage: "Empty page redirect",
	askedAi: "Asked AI",
	zeroResultPage: "User landed on zero results PLP"

}

export const gaCategories = {
	wishList: "Wish List",
	mobileNavigationTab: "Mobile Navigation Tab",
	checkout: "Checkout",
	checkoutDocument: "Checkout Document",
	cart: "Cart",
	pageVisit: "Page Visit",
	productFilters: "Product Filters",
	support: "Support",
	consultation: "consultation",
	feedback: "Feedback",
	newsletter: "Newsletter",
	planLength: "Plan Length",
	search: "Search",
	plp: "plp",
	brandLogo: "Brand Logo",
	collectionView: "Collection View",
	quickAccessFilter: "Quick Access Filter",
	megaMenu: "Mega Menu",
	homePage: "Home Page",
	pdp: "pdp",
	comparisonPage: "Comparison Page",
	eBikefilters: "EBike Filters",
	secondaryHeroSection: "Secondary Hero Section",
	sponsorMe: "Sponsor Me",
	errorBoundary: "Error Boundary",
	testDrive: "Test Drive"
}

export const ecommerceEvents = {
	event: "event",
	beginCheckout: "begin_checkout",
	purchase: "purchase",
	CHF: "CHF",
	viewItem: "view_item"
}

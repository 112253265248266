// All E-Bikes
export const EBikesList = {
	de: [
		{
			title: "E-Mountain",
			slugName: "e-mountain",
			link: "e-mountain",
			buttonTitle: "Alle Mountain E-Bikes"
		},
		{
			title: "E-City",
			slugName: "e-urban",
			link: "e-urban",
			buttonTitle: "Alle City E-Bikes"
		},
		{
			title: "E-Trekking",
			slugName: "e-trekking",
			link: "e-trekking",
			buttonTitle: "Alle Trekking E-Bikes"
		},
		{
			title: "45 Km/h",
			slugName: "45-km",
			link: "45-km",
			buttonTitle: "Alle 45km/h E-Bikes"
		},
		{
			title: "Cargo E-Bikes",
			slugName: "cargo-e-bikes",
			link: "cargo-e-bikes",
			buttonTitle: "Alle Cargo-E-Bikes"
		}
	], fr: [
		{
			title: "E-Mountain",
			slugName: "e-mountain",
			link: "e-mountain",
			buttonTitle: "Tous les Mountain E-Bikes"
		},
		{
			title: "E-City",
			slugName: "e-urban",
			link: "e-urban",
			buttonTitle: "Tous les City E-Bikes"
		},
		{
			title: "E-Trekking",
			slugName: "e-trekking",
			link: "e-trekking",
			buttonTitle: "Tous les Trekking E-Bikes"
		},
		{
			title: "45 Km/h",
			slugName: "45-km",
			link: "45-km",
			buttonTitle: "Tous les 45km/h E-Bikes"
		},
		{
			title: "Cargo E-Bikes",
			slugName: "cargo-e-bikes",
			link: "cargo-e-bikes",
			buttonTitle: "Tous les Cargo-E-Bikes"
		}
	]
}

// All Bio Bikes
const BioBikesList = {
	de: [
		{
			title: "Rennvelos",
			slugName: "road-bikes",
			link: "road-bikes",
			buttonTitle: "Alle Road Bikes"
		},
		{
			title: "Gravel Bikes",
			slugName: "gravel-bikes",
			link: "gravel-bikes",
			buttonTitle: "Alle Gravel Bikes"
		},
		{
			title: "Fully",
			slugName: "mountain-fully",
			link: "mountain-fully",
			buttonTitle: "Alle Mountain Fully"
		},
		{
			title: "Hardtail",
			slugName: "mountain-hardtail",
			link: "mountain-hardtail",
			buttonTitle: "Alle Mountain Hardtail"
		}
	], 	fr: [
		{
			title: "Vélos de course",
			slugName: "road-bikes",
			link: "road-bikes",
			buttonTitle: "Tous les Road Bikes"
		},
		{
			title: "Gravel Bikes",
			slugName: "gravel-bikes",
			link: "gravel-bikes",
			buttonTitle: "Tous les Gravel Bikes"
		},
		{
			title: "Fully",
			slugName: "mountain-fully",
			link: "mountain-fully",
			buttonTitle: "Tous les Mountain Fully"
		},
		{
			title: "Hardtail",
			slugName: "mountain-hardtail",
			link: "mountain-hardtail",
			buttonTitle: "Tous les Mountain Hardtail"
		}
	]
}

export const VELOS_SUBCATEGORIES = ["road-bikes", "gravel-bikes", "mountain-fully", "mountain-hardtail", "velos-non-electriques", "nicht-elektrische-bikes"]
export const EBIKES_SUBCATEGORIES = ["e-mountain", "e-urban", "e-trekking", "45-km", "cargo-e-bikes"]

const AllProductsItems = {
	de: [
		{
			title: "E-bikes",
			buttonTitle: "Alle E-bikes",
			link: "",
			slugName: "e-bikes",
			itemsList: EBikesList
		},
		{
			title: "Velos",
			buttonTitle: "Alle Velos",
			link: "nicht-elektrische-bikes",
			slugName: "nicht-elektrische-bikes",
			itemsList: BioBikesList.de
		},
		{
			title: "Accessories",
			buttonTitle: "Alle Accessories",
			slugName: "zubehoer",
			link: "/zubehoer"
		}
	],
	fr: [
		{
			title: "E-bikes",
			buttonTitle: "Tous les E-bikes",
			link: "",
			slugName: "e-bikes",
			itemsList: EBikesList.fr
		},
		{
			title: "Vélos",
			buttonTitle: "Tous les vélos",
			link: "velos-non-electriques",
			slugName: "velos-non-electriques",
			itemsList: BioBikesList.fr
		},
		{
			title: "Accessoires",
			buttonTitle: "Tous les Accessoires",
			slugName: "accessoires",
			link: "/accessoires"
		}
	]
}
// Home cat boxes - subcategories on sale
export const EBikesOnSaleList = {
	de: [
		{
			title: "E-Mountain",
			slugName: "e-mountain",
			link: "e-mountain",
			buttonTitle: "Alle Mountain E-Bikes"
		},
		{
			title: "E-City",
			slugName: "e-urban",
			link: "e-urban",
			buttonTitle: "Alle City E-Bikes"
		},
		{
			title: "E-Trekking",
			slugName: "e-trekking",
			link: "e-trekking",
			buttonTitle: "Alle Trekking E-Bikes"
		},
		{
			title: "Velos",
			buttonTitle: "Alle Velos",
			link: "nicht-elektrische-bikes",
			slugName: "nicht-elektrische-bikes"
		}
	], fr: [
		{
			title: "E-Mountain",
			slugName: "e-mountain",
			link: "e-mountain",
			buttonTitle: "Tous les Mountain E-Bikes"
		},
		{
			title: "E-City",
			slugName: "e-urban",
			link: "e-urban",
			buttonTitle: "Tous les City E-Bikes"
		},
		{
			title: "E-Trekking",
			slugName: "e-trekking",
			link: "e-trekking",
			buttonTitle: "Tous les Trekking E-Bikes"
		},
		{
			title: "Bikes",
			buttonTitle: "Tous les bikes",
			link: "velos-non-electriques",
			slugName: "velos-non-electriques"
		}
	]
}

// Home Category Boxes - HomePage
export const HomeCatBoxesSlugsList = {
	de: [
		{
			title: "Sale",
			buttonTitle: "Alle Modelle",
			link: "sale",
			slugName: "sale",
			itemsList: [
				...EBikesOnSaleList.de
			]
		},
		{
			title: "E-Bikes",
			buttonTitle: "Alle E-Bikes",
			link: "",
			slugName: "e-bikes",
			itemsList: [
				{
					title: "Top Modelle",
					slugName: "top-modelle",
					link: "e-bikes",
					buttonTitle: "Alle E-Bikes"
				},
				...EBikesList.de
			]
		},
		{
			title: "Velos",
			buttonTitle: "Alle Velos",
			link: "nicht-elektrische-bikes",
			slugName: "nicht-elektrische-bikes",
			itemsList: [
				{
					title: "Topmodelle",
					slugName: "nicht-elektrische-bikes",
					link: "nicht-elektrische-bikes",
					buttonTitle: "Alle Velos"
				},
				...BioBikesList.de
			]
		},
		{
			title: "Zubehör",
			buttonTitle: "Alle Zubehör",
			slugName: "zubehoer",
			link: "/zubehoer"
		}
	],
	fr: [
		{
			title: "Sale",
			buttonTitle: "Tous les Top Modèles",
			link: "sale",
			slugName: "sale",
			itemsList: [
				...EBikesOnSaleList.fr
			]
		},
		{
			title: "E-Bikes",
			buttonTitle: "Tous les e-bikes",
			link: "",
			slugName: "e-bikes",
			itemsList: [
				{
					title: "Top Modèles",
					slugName: "top-modelles",
					link: "e-bikes",
					buttonTitle: "Tous les E-Bikes"
				},
				...EBikesList.fr
			]
		},
		{
			title: "Bikes",
			buttonTitle: "Tous les bikes",
			link: "velos-non-electriques",
			slugName: "velos-non-electriques",
			itemsList: [
				{
					title: "Top modèles",
					slugName: "velos-non-electriques",
					link: "velos-non-electriques",
					buttonTitle: "Tous les bikes"
				},
				...BioBikesList.fr
			]
		},
		{
			title: "Accessoires",
			buttonTitle: "Tous les accessoires",
			slugName: "accessoires",
			link: "/accessoires"
		}
	]
}

// Home Category Boxes - Competition Confimration Page - Custom Categories
export const CustomHomeCatBoxesSlugsList = {
	de: [
		{
			title: "All E-Bikes",
			buttonTitle: "E-Bikes",
			link: "",
			slugName: "e-bikes"
		},
		{
			title: "E-Mountain",
			slugName: "e-mountain",
			link: "e-mountain",
			buttonTitle: "Mountain E-Bikes"
		},
		{
			title: "E-City",
			slugName: "e-urban",
			link: "e-urban",
			buttonTitle: "City E-Bikes"
		}
	],
	fr: [
		{
			title: "Alle E-Bikes",
			buttonTitle: "Tous les e-bikes",
			link: "",
			slugName: "e-bikes"
		},
		{
			title: "E-Mountain",
			slugName: "e-mountain",
			link: "e-mountain",
			buttonTitle: "Tous les Mountain E-Bikes"
		},
		{
			title: "E-City",
			slugName: "e-urban",
			link: "e-urban",
			buttonTitle: "Tous les City E-Bikes"
		}
	]
}

// Filter Mobile Tabs - PLP
export const FilterMobileTabsSlugsList = {
	de: [
		{
			title: "Alle Modelle",
			slugName: "-",
			itemsList: [
				...EBikesList.de,
				{
					title: "Swiss Made",
					slugName: "swiss-made"
				},
				...BioBikesList.de
			]
		},
		{
			title: "E-Bikes",
			slugName: "e-bikes",
			itemsList: EBikesList.de
		},
		{
			title: "Velos",
			slugName: "nicht-elektrische-bikes",
			itemsList: BioBikesList.de
		},
		{
			title: "Zubehör",
			slugName: "zubehoer"
		}
	],
	fr: [
		{
			title: "Tous les modèles",
			itemsList: [
				...EBikesList.fr,
				{
					title: "Swiss Made",
					slugName: "swiss-made"
				},
				...BioBikesList.fr
			]
		},
		{
			title: "E-Bikes",
			slugName: "e-bikes",
			itemsList: EBikesList.fr
		},
		{
			title: "Vélos",
			slugName: "velos-non-electriques",
			itemsList: BioBikesList.fr
		},
		{
			title: "Accessoires",
			slugName: "accessoires"
		}
	]
}

// Navigation Links Desktop & Mobile
const MegaMenuNavigationLinks = {
	de: [
		{
			title: "E-Bikes",
			link: "e-bikes",
			slugName: "e-bikes",
			buttonTitle: "Alle E-Bikes"
		},
		{
			title: "Velos",
			link: "nicht-elektrische-bikes",
			slugName: "nicht-elektrische-bikes",
			buttonTitle: "Alle Velos"
		},
		{
			title: "45 Km/h E-Bikes",
			link: "45-km",
			slugName: "45-km",
			buttonTitle: "45 Km/h E-Bikes"
		},
		{
			title: "Zubehör",
			link: "zubehoer",
			slugName: "zubehoer",
			buttonTitle: "All Accessories"
		},
		{
			title: "Alle Marken",
			link: null,
			slugName: "all-brands",
			buttonTitle: "Alle Marken"
		},
		// {
		//	title: "quick-delivery",
		//	link: "/alle-modelle-e-bike-schweiz?availability=instock",
		//	slugName: "quick-delivery",
		//	buttonTitle: ""
		// },
		// {
		//	title: "Sale",
		//	link: "/alle-modelle-e-bike-schweiz?sortOrder=sale",
		//	slugName: "sale",
		//	buttonTitle: ""
		// },
		{
			title: "Christmas Deals",
			link: "/alle-modelle-e-bike-schweiz?sortOrder=sale",
			slugName: "sale",
			buttonTitle: ""
		}
	],
	fr: [
		{
			title: "E-Bikes",
			link: "e-bikes",
			slugName: "e-bikes",
			buttonTitle: "Alle E-Bikes"
		},
		{
			title: "Bikes",
			link: "velos-non-electriques",
			slugName: "velos-non-electriques",
			buttonTitle: "Tous les vélos"
		},
		{
			title: "45 Km/h E-Bikes",
			link: "45-km",
			slugName: "45-km",
			buttonTitle: "45 Km/h E-Bikes"
		},
		{
			title: "Accessoires",
			link: "accessoires",
			slugName: "accessoires",
			buttonTitle: "Tous les accessoires"
		},
		{
			title: "Tous les marques",
			link: null,
			slugName: "all-brands",
			buttonTitle: "Tous les marques"
		},
		// {
		//	title: "quick-delivery",
		//	link: "/alle-modelle-e-bike-schweiz?availability=instock",
		//	slugName: "quick-delivery",
		//	buttonTitle: ""
		// },
		// {
		//	title: "Sale",
		//	link: "/alle-modelle-e-bike-schweiz?sortOrder=sale",
		//	slugName: "sale",
		//	buttonTitle: ""
		// },
		{
			title: "Christmas Deals",
			link: "/alle-modelle-e-bike-schweiz?sortOrder=sale",
			slugName: "sale",
			buttonTitle: ""
		}
	]
}

// Mobile MegaMenu Navigation Links
export const NewMobileMegaMenuSlugsList = {
	de: [
		{
			title: "Alle Modelle",
			buttonTitle: "Alle Modelle",
			link: "",
			slugName: "all-models"
		},
		...MegaMenuNavigationLinks.de
	],
	fr: [
		{
			title: "Tous les modèles",
			buttonTitle: "Tous les modèles",
			link: "",
			slugName: "all-models"
		},
		...MegaMenuNavigationLinks.fr
	]
}

// E-Bikes & Bio Bikes List - Search Content
export const SearchTopCategories = {
	de: [
		...EBikesList.de,
		...BioBikesList.de
	],
	fr: [
		...EBikesList.fr,
		...BioBikesList.fr
	]
}

// MyBikePlan MegaMenu Categories - All Models
export const topCategoriesMegaMenuMBP = {
	de: [
		{
			title: "E-Bikes",
			buttonTitle: "Alle E-Bikes",
			slugName: "e-bikes",
			subcategories: EBikesList.de
		},
		{
			title: "Velos",
			buttonTitle: "Alle Velos",
			slugName: "nicht-elektrische-bikes",
			subcategories: BioBikesList.de
		},
		{
			title: "45 Km/h E-Bikes",
			buttonTitle: "Alle 45 Km/h",
			slugName: "45-km",
			subcategories: []
		},
		{
			title: "Zubehör",
			buttonTitle: "Alles Zubehör",
			slugName: "zubehoer",
			subcategories: []
		}
	],
	fr: [
		{
			title: "E-Bikes",
			buttonTitle: "Tous les e-bikes",
			slugName: "e-bikes",
			subcategories: EBikesList.fr
		},
		{
			title: "Vélos",
			buttonTitle: "Tous les vélos",
			slugName: "velos-non-electriques",
			subcategories: BioBikesList.fr
		},
		{
			title: "45 Km/h E-Bikes",
			buttonTitle: "Tous les 45 Km/h e-bikes",
			slugName: "45-km",
			subcategories: []
		},
		{
			title: "Accessoires",
			buttonTitle: "Tous les accessoires",
			slugName: "accessoires",
			subcategories: []
		}
	]
}

export const EMountainSubcategories = [
	{
		title: "E-Fully",
		slugName: "e-mountain-fully"
	},
	{
		title: "E-Hardtail",
		slugName: "e-mountain-hardtail"
	}
]

// MyHomePlan MegaMenu Categories
export const topCategoriesMegaMenuMHP = [
	{
		title: "Sofas",
		link: "sofas", translateLink: false,
		subcategories: [
			{
				name: "Sofas",
				link: "sofas",
				items: [
					{title: "L-Form", link: "l-form"},
					{title: "Ecksofas", link: "ecksofas"},
					{title: "Ledersofas", link: "ledersofas"},
					{title: "Bettsofas", link: "bettsofas"},
					{title: "Modularsofas", link: "modularsofas"}
				]
			}
		]
	},
	{
		title: "Tische & Stühle",
		link: "tische,stuhle", translateLink: false,
		subcategories: [
			{
				name: "Tische",
				link: "tische",
				items: [
					{title: "Esstische", link: "esstische"},
					{title: "Ausziehtische", link: "ausziehtische"},
					{title: "Couchtische", link: "couchtische"},
					{title: "Nachttische", link: "nachttische"},
					{title: "Beistelltische", link: "beistelltische"},
					{title: "Schreibtische", link: "schreibtische"},
					{title: "Tisch-Set", link: "tisch-set"}
				]
			},
			{
				name: "Stuhle",
				link: "stuhle",
				items: [
					{title: "Esszimmerstühle", link: "dinner-chair"},
					{title: "Bürostühle", link: "burostuhle"},
					{title: "Barhocker", link: "barhocker"},
					{title: "Hocker", link: "hocker"},
					{title: "Sessel", link: "sessel"}
				]
			}
		]
	},
	{
		title: "Betten",
		link: "betten", translateLink: false,
		subcategories: [
			{
				name: "Betten",
				link: "betten",
				items: [
					{title: "Doppelbetten", link: "doppelbetten"},
					{title: "Einzelbetten", link: "einzelbetten"},
					{title: "Matratzen", link: "mattress"},
					{title: "Bett-Set", link: "bett-set"}
				]
			}
		]
	},
	{
		title: "Aufbewahrung",
		link: "aufbewahrung", translateLink: false,
		subcategories: [
			{
				name: "Aufbewahrung",
				link: "aufbewahrung",
				items: [
					{title: "Schränke", link: "schranke"},
					{title: "Kommoden", link: "kommoden"},
					{title: "Regale", link: "shelf-2"},
					{title: "TV-Möbel ", link: "tv-moebel"}
				]
			}
		]
	},
	{
		title: "Dekoration",
		link: "dekoration", translateLink: false,
		subcategories: [
			{
				name: "Dekoration",
				link: "dekoration",
				items: [
					{title: "Lampen", link: "lampen"},
					{title: "Teppiche", link: "teppiche"},
					{title: "Spiegel", link: "spiegel"}
				]
			}
		]
	},
	{
		title: "Outdoor",
		link: "balkon-and-garten", translateLink: false,
		subcategories: [
			{
				name: "Outdoor",
				link: "balkon-and-garten",
				items: [
					{title: "Gartensofas", link: "gartensofas"},
					{title: "Gartensessel", link: "gartensessel"},
					{title: "Gartentische", link: "gartentische"},
					{title: "Outdoor-Set", link: "outdoor-set"}
				]
			}
		]
	},
	{
		title: "Bundles",
		link: "bundle", translateLink: false,
		subcategories: [
			{
				name: "Bundles",
				link: "bundle",
				items: [
					{title: "Alle Bundles", link: "bundle"}
				]
			}
		]
	}
]

export const HomeCatsContentData = {
	de: [{
		fields: {
			title: "Bike Now. Pay Later.",
			collapsible: false,
			text: {
				content: [{
					nodeType: "paragraph",
					content: [{value: "Egal ob E-Bike, Velo oder passende Accessoires: Mit MyBikePlan kannst du alles mit 0% Zinsen finanzieren und ab dem ersten Tag besitzen. Bestelle jetzt dein E-Bike und bezahle stressfrei in 12-48 Monatsraten."}]
				}]
			}
		}
	}],
	fr: [{
		fields: {
			title: "Ton E-Bike en plusieurs versements.",
			collapsible: false,
			text: {
				content: [{
					nodeType: "paragraph",
					content: [{value: "Que ce soit un E-Bike, un vélo ou des accessoires adaptés. Avec MyBikePlan, tu peux tout financer à 0% d'intérêt et en être propriétaire dès le premier jour. Commande maintenant ton E-Bike et paye sans stress en 12 à 48 mensualités."}]
				}]
			}
		}
	}]
}

export const downpaymentBracketsArr = [
	{
		id: 1,
		title: "10%"
	},
	{
		id: 2,
		title: "20%"
	},
	{
		id: 3,
		title: "30%"
	},
	{
		id: 4,
		title: "40%"
	},
	{
		id: 5,
		title: "50%"
	}
]

import React, {Dispatch, SetStateAction, useContext, useEffect} from "react"
import useCache from "../../hooks/useCache"
import {useRouter} from "next/router"
import Spinner from "../reusable/Spinner"
import BikesCollectionItem from "../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import LayoutContext from "../../context/LayoutContext"
import CompatibleAccessory from "../bikeDetails/CompatibleAccessory"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

function StoryFeaturedBike({slug, cta, setBikeFetched, className}: { slug: string, setBikeFetched: Dispatch<SetStateAction<boolean>>, className?:string, cta?:string }) {
	const router = useRouter()
	const {data, isLoading} = useCache(`/api/products/bikes/fetch-bikes-by-slugs/${[slug]}?locale=${router?.locale || "de"}&multiple=false`)
	const {isMobile} = useContext(LayoutContext)
	const {t} = useTranslation()
	if (!slug) {
		return null
	}

	useEffect(() => {
		if (data?.length && !isLoading && typeof slug !== "undefined") {
			setBikeFetched(true)
		}
	}, [data])

	if (data?.length && !isLoading && !isMobile) {
		return (
			<div className={`d-flex align-items-start flex-column justify-content-start gap-3 ${className}`}>
				<Typography style={{fontSize: "16px", lineHeight: "20px"}}
					className="text-light px-2"
					variant="bodySm">{cta || t("check-featured-bike-label")}</Typography>
				<BikesCollectionItem cardWidth="315px"
					isStory
					bike={data?.[0]} />
			</div>
		)
	}

	if (data?.length && !isLoading && isMobile) {
		return (
			<div style={{top: "70dvh", position: "absolute", left: "50%", transform: "translateX(-50%)", background: "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%)"}}
				className="col-12   ">
				<div style={{zIndex: 9999999999999}}
					className={`d-flex container align-items-center justify-content-center flex-column gap-0 mt-4 ${className} `}>
					<Typography style={{fontSize: "16px", lineHeight: "20px"}}
						className="text-light px-2"
						variant="bodySmBold">{cta || t("check-featured-bike-label")}</Typography>
					<CompatibleAccessory isStory
						product={data?.[0]}
						bike={data?.[0]} />
				</div>
			</div>
		)
	}

	if (isLoading && isMobile) {
		return <div style={{top: "70%", position: "absolute", left: "50%", transform: "translateX(-50%)", background: "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%)"}}
			className="col-12   ">
			<div style={{zIndex: 9999999999999}}
				className={`d-flex container align-items-center justify-content-center flex-column gap-0 mt-4 ${className} `}>
				<Spinner />
			</div>
		</div>
	}

	if (isLoading && !isMobile) {
		return <div className={`d-flex align-items-start flex-column justify-content-start ps-4 ${className}`}>
			<Spinner />
		</div>
	}

	return null
}

export default StoryFeaturedBike

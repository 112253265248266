import React from "react"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"

const ComparisonSection = () => {
	const {t} = useTranslation()

	return (
		<div
			className="container pb-lg-4 mb-5 mt-4 text-start text-md-start d-md-flex flex-column align-items-center">
			<Typography className="mb-4 ps-0 ms-0 text-align-start d-block d-md-none"
				variant="bodyLgBlack"
				semanticTag="h3"
				style={{fontSize: 28}}>{t("comparison-section-title")}</Typography>
			<Typography className="mb-4 ps-0 ms-0 text-align-start d-none d-md-block"
				variant="heading2Black"
				semanticTag="h2">{t("comparison-section-title")}</Typography>
			<div className="container justify-content-end d-none d-md-flex">
				<Typography className="col-2 text-center text-secondary"
					variant="bodyLg">{t("comparison-header-1")}</Typography>
				<Typography className="col-2 text-center text-secondary"
					variant="bodyLg">{t("comparison-header-2")}</Typography>
				<Typography className="col-2 text-center "
					variant="bodyLgBold">{t("comparison-header-3")}</Typography>
			</div>
			<div className="container d-flex justify-content-end d-block d-md-none">
				<Typography className="col-2 text-center text-secondary"
					variant="bodySm">{t("comparison-header-1")}</Typography>
				<Typography className="col-2 text-center text-secondary"
					variant="bodySm">{t("comparison-header-2")}</Typography>
				<Typography className="col-2 text-center"
					variant="bodySmBold">{t("comparison-header-3")}</Typography>
			</div>
			{
				comparisonList.map(({idx, label, mietenIcon, leasenIcon, mbpIcon}) => {
					return (
						<div className=" container d-flex text-start mt-4 col-12 "
							key={idx}>
							<Typography className="col-6"
								variant="bodyLg">{t(label)}</Typography>
							<div className="d-flex col-2 justify-content-center">
								<Image src={mietenIcon}
									layout={"intrinsic"}
									objectFit={"contain"}
									width={24}
									height={24}/>
							</div>
							<div className="d-flex col-2 justify-content-center">
								<Image src={leasenIcon}
									layout={"intrinsic"}
									objectFit={"contain"}
									width={24}
									height={24}/>
							</div>
							<div className="d-flex col-2 justify-content-center">
								<Image src={mbpIcon}
									layout={"intrinsic"}
									objectFit={"contain"}
									width={24}
									height={24}/>
							</div>
						</div>
					)
				})
			}
		</div>
	)
}

export default ComparisonSection

const comparisonList = [
	{
		idx: 1,
		label: "comparison-section-text-1",
		mietenIcon: "/assets/icons/comparison-cross-icon.png",
		leasenIcon: "/assets/icons/comparison-cross-icon.png",
		mbpIcon: "/assets/icons/comparison-cross-icon.png"
	},
	{
		idx: 2,
		label: "comparison-section-text-2",
		mietenIcon: "/assets/icons/comparison-cross-icon.png",
		leasenIcon: "/assets/icons/comparison-cross-icon.png",
		mbpIcon: "/assets/icons/comparison-checked-icon.png"
	},
	{
		idx: 3,
		label: "comparison-section-text-3",
		mietenIcon: "/assets/icons/comparison-checked-icon.png",
		leasenIcon: "/assets/icons/comparison-checked-icon.png",
		mbpIcon: "/assets/icons/comparison-checked-icon.png"
	},
	{
		idx: 4,
		label: "comparison-section-text-4",
		mietenIcon: "/assets/icons/comparison-cross-icon.png",
		leasenIcon: "/assets/icons/comparison-checked-icon.png",
		mbpIcon: "/assets/icons/comparison-cross-icon.png"
	},
	{
		idx: 5,
		label: "comparison-section-text-5",
		mietenIcon: "/assets/icons/comparison-checked-icon.png",
		leasenIcon: "/assets/icons/comparison-checked-icon.png",
		mbpIcon: "/assets/icons/comparison-cross-icon.png"
	},
	{
		idx: 6,
		label: "comparison-section-text-6",
		mietenIcon: "/assets/icons/comparison-cross-icon.png",
		leasenIcon: "/assets/icons/comparison-cross-icon.png",
		mbpIcon: "/assets/icons/comparison-checked-icon.png"
	},
	{
		idx: 7,
		label: "comparison-section-text-7",
		mietenIcon: "/assets/icons/comparison-cross-icon.png",
		leasenIcon: "/assets/icons/comparison-cross-icon.png",
		mbpIcon: "/assets/icons/comparison-checked-icon.png"
	},
	{
		idx: 8,
		label: "comparison-section-text-8",
		mietenIcon: "/assets/icons/comparison-cross-icon.png",
		leasenIcon: "/assets/icons/comparison-cross-icon.png",
		mbpIcon: "/assets/icons/comparison-checked-icon.png"
	}
]

import React from "react"
import {NextRouter, withRouter} from "next/router"
import {fetchPost} from "../../firebaseAdmin/fetchPost"
import {isProduction} from "../../utility/Helper"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"

interface ErrorBoundaryProps {
	children: React.ReactNode;
	router: NextRouter;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	private hasFetched: boolean = false

	static analyticsContext = AnalyticsContext

	constructor(props: ErrorBoundaryProps) {
		super(props)
		this.state = {hasError: false}
	}

	static getDerivedStateFromError(error: Error): ErrorBoundaryState {
		return {hasError: true}
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		console.error("The error object DEBUG ||: ", {error, errorInfo})

		if (!this.hasFetched) {
			this.hasFetched = true // Set flag to prevent multiple fetches

			const errorDetails = {
				message: error.message,
				stack: error.stack,
				componentStack: errorInfo.componentStack
			}
			if (isProduction()) {
				fetchPost("/api/firestore/storeErrorLogsToFirebase", errorDetails)
					.then(response => response.json().then(() => // Send GA4 event
						this.context.reactGA?.event({
							category: gaCategories.errorBoundary,
							action: gaEvents.applicationError,
							label: "Application Error",
							nonInteraction: true
						})))
					.then(data => console.log("Error stored in Firestore"))
					.catch(err => console.error("Storing error in Firestore failed:", err))
			}
		}
	}

	componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: ErrorBoundaryState) {
		if (this.state.hasError && !prevState.hasError && isProduction()) {
			this.props.router.replace("/404")
		}
	}

	render() {
		if (this.state.hasError) {
			return null
		}

		return this.props.children
	}
}

export default withRouter(ErrorBoundary)

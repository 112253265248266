import {useTranslation} from "next-i18next"
import React, {useContext, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"

interface Props {
    shortDescription: string;
}

const ShortDescriptionPDP: React.FC<Props> = ({shortDescription}) => {
	const {t} = useTranslation("common")
	const [fullTextShown, setFullTextShown] = useState(false)
	const {reactGA} = useContext(AnalyticsContext)

	const words = shortDescription.split(" ")
	const showReadMoreBtn = words.length > 14

	const handleShowMoreClick = () => {
		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.showMoreTextBtnClicked,
			label: `${gaEvents.showMoreTextBtnClicked} On PDP`,
			nonInteraction: false
		})
		setFullTextShown(true)
	}

	const handleShowLessClick = () => {
		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.showLessTextBtnClicked,
			label: `${gaEvents.showLessTextBtnClicked} On PDP`,
			nonInteraction: false
		})
		setFullTextShown(false)
	}

	return (
		<div className="pb-2 pb-md-0 mb-md-4 position-relative">
			<Typography
				variant="bodySm"
				semanticTag="p"
			>
				{fullTextShown ? (
					shortDescription
				) : (
					<>
						{words.slice(0, 14).join(" ")}...{" "}
						{showReadMoreBtn && (
							<Typography variant="bodySmBold"
								onClick={handleShowMoreClick}
								semanticTag="span"
								className="pointer new-mbp-link">
								{t("read-more")}
							</Typography>
						)}
					</>
				)}
			</Typography>
			{fullTextShown && showReadMoreBtn && (
				<div
					className="mt-3 pointer new-mbp-link"
					onClick={handleShowLessClick}
				>
					<Typography variant="bodySmBold"
						semanticTag="span">
						{t("read-less")}
					</Typography>
				</div>
			)}
		</div>
	)
}

export default ShortDescriptionPDP

import Typography from "../../reusable/Typography/Typography"
import React from "react"
import {useTranslation} from "next-i18next"
import FindPerfectBikeCard from "./FindPerfectBikeCard"

const page1Data = [
	{
		imageSrc: "/assets/images/whatsapp-suggestion-pic.jpg",
		title: "custom-offer-card-title",
		description: "take-short-quiz",
		btnLabel: "take-quiz-btn",
		btnIconSrc: "/assets/icons/question-mark-box.svg",
		btnHref: "/suggestion/1",
		titleWidth: "65%"
	},
	{
		imageSrc: "/assets/images/book-consultation-cropped.jpg",
		title: "get-contacted-card-title",
		description: "talk-to-our-experts-card-desc",
		isConsultationBtn: true,
		titleWidth: "95%"
	},
	{
		imageSrc: "/assets/images/credit-check.jpg",
		title: "creditcheck-eligible-for-financing-title",
		description: "eligible-for-financing-desc",
		btnLabel: "check-score-btn",
		btnIconSrc: "/assets/icons/credit-check-icon.svg",
		btnHref: "/creditcheck/2",
		titleWidth: "95%"
	}
]

  interface CardData {
    imageSrc: string;
    title: string;
    description: string;
    btnLabel?: string;
    btnIconSrc?: string;
    btnHref?: string;
    isConsultationBtn?: boolean;
    titleWidth?: string;
  }

  interface FindPerfectBikeProps {
    cardsData?: CardData[];
  }

const FindPerfectBike: React.FC<FindPerfectBikeProps> = ({cardsData = page1Data}) => {
	const {t} = useTranslation()

	return (
		<div id="find-perfect-bike"
			className="col-12 container mb-5 pb-3 mb-md-0 pb-md-0 mt-3 mt-md-5 find-perfect-bike">
			<Typography variant={"heading2Black"}
				semanticTag="h1"
				className={"text-center mb-5 d-none d-md-block"}>{t("find-perfect-bike-title")}</Typography>
			<Typography variant={"heading2"}
				semanticTag="h2"
				className={"text-start mb-4 pb-2 d-md-none"}
				style={{lineHeight: "36px"}}>{t("find-perfect-bike-title")}</Typography>

			<div className="d-md-flex justify-content-center"
				style={{columnGap: 40}}>
				{cardsData?.map((card, index) => (
					<div className="col-12 col-md-4 mb-4 mb-md-0"
						key={index}>
						<FindPerfectBikeCard
							imageSrc={card.imageSrc}
							title={t(card.title)}
							description={t(card?.description)}
							btnLabel={t(card?.btnLabel)}
							btnIconSrc={card?.btnIconSrc}
							btnHref={card?.btnHref ? card?.btnHref : "/"}
							isConsultationBtn={card?.isConsultationBtn}
							titleWidth={card?.titleWidth}
						/>
					</div>
				))}
			</div>
		</div>
	)
}

export default FindPerfectBike

import Image from "next/image"
import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {formatPrice} from "../../../utility/Helper"

function DownpaymentGift({amount}: { amount: number }) {
	const {t} = useTranslation()
	if (amount !== 0 && amount !== null) {
		return (
			<div className="d-flex align-items-center justify-content-start"
				style={{gap: "2px"}}>

				<Typography semanticTag="span"
					variant="bodySm"
					style={{fontSize: "12px", lineHeight: "16px"}}
					className={"text-secondary ms-1"}>
					{"% "}
					{t("dowpayment-gift-label").replace("x", formatPrice(amount))}
				</Typography>
			</div>
		)
	}

	return <></>
}

export default DownpaymentGift

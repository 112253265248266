import React from "react"
import Typography from "../../reusable/Typography/Typography"
import BikeOrdersCount from "../BikeOrdersCount"
import ShortDescriptionPDP from "../ShortDescriptionPDP"
import OurReview from "./OurReview"
import NumberOfVisitors from "../../reusable/NumberOfVisitors"
import BikePrice from "../BikePrice"
import Image from "next/image"
import TotalPriceOnPdp from "./TotalPriceOnPdp"
import PDPTooltip from "../PDPTooltip"
import {BikeType} from "../../../utility/Types"
import AboutThisBike from "../AboutThisBike"
import {formatPrice} from "../../../utility/Helper"

type Props = {
    bike: any
    bikeOrdersCount: number
    isMobile?: boolean
    shortDescription?: string
    isSecondHand?: boolean
    setIsOpen?: (isOpen: boolean) => void;
    isOpen?: boolean
    finalPriceWithoutDownPayment?: any
    onSale?: boolean
    formatteduvpPriceForSecondHand?: string
    formattedPriceForSecondHand?: string
    t?: any,
    discount:number
}

const BikeNameAndDescSection: React.FC<Props> = ({
	bike,
	bikeOrdersCount,
	isMobile,
	shortDescription,
	isSecondHand,
	isOpen,
	setIsOpen = () => {},
	finalPriceWithoutDownPayment,
	onSale,
	formatteduvpPriceForSecondHand,
	formattedPriceForSecondHand,
	t,
	discount
}) => {
	return (
		<div className="mt-2 mb-md-3">
			<div className="row justify-content-start">
				<BikeOrdersCount count={bikeOrdersCount}
					t={t}/>
			</div>
			<div className="border-md-bottom mb-md-2">
				<Typography variant="heading1Black"
					semanticTag="h2"
					className="mb-1">{t(bike.name)}</Typography>
				<NumberOfVisitors label={t("visitors-number-label")}
					isMobile={isMobile}
					amount={bike.views}
					className="mb-1" />
				<OurReview bike={bike}/>
			</div>

			<div className="pt-md-1">
				<div style={{marginBottom: 10}}
					className="d-none d-md-block">
					<AboutThisBike bike={bike}
						mostAskedOnly />
				</div>
				{typeof shortDescription !== "undefined" && shortDescription &&
                    <ShortDescriptionPDP shortDescription={shortDescription} />}

			</div>
			{/* Only On Mobile */}
			<div className="d-flex justify-content-between align-items-center mb-1 d-none">
				<BikePrice
					isSecondHand={isSecondHand}
					product={bike}
				/>
				<Image onClick={() => setIsOpen(!isOpen)}
					src={`${isOpen ? "/assets/icons/info-active.svg" : "/assets/icons/info-transparent.svg"}`}
					height={18}
					width={18}
					className="pointer"
				/>
			</div>
			<div id="size"
				className="position-relative d-none">
				{!isSecondHand && (
					<TotalPriceOnPdp bike={bike as BikeType}
						finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
						isSecondHand={isSecondHand}
						onSale={onSale}
						discount={discount}/>)}
				{isSecondHand && (<>
					<Typography variant="bodySm"
						semanticTag="span"
						className={"text-secondary"}
						style={{color: "#6C7074"}}>
						{`${t("Gesamtpreis")} - `}
					</Typography>
					<Typography variant="bodySm"
						semanticTag="span"
						className={"text-decoration-line-through"}
						style={{color: "#6C7074"}}>
						{`${formatPrice(formatteduvpPriceForSecondHand)}`}
					</Typography>
					<Typography variant="bodySm"
						semanticTag="span"
						className={"ms-2"}
						style={{color: "#6C7074"}}>
						{`${formatPrice(formattedPriceForSecondHand)}`}
					</Typography>
				</>
				)}
				<PDPTooltip isOpen={isOpen}
					setIsOpen={setIsOpen}
					label="financing"/>
			</div>
		</div>
	)
}

export default BikeNameAndDescSection

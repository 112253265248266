import AWS from "aws-sdk"

AWS.config.update({
	accessKeyId: process.env.AWS_ACCESS_KEY_ID_DATA,
	secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY_DATA,
	region: "eu-central-1"
})

const s3 = new AWS.S3()

export const checkIfFileExistsInS3 = async (Key: string, Bucket:string = process.env.PRODUCTS_S3_BUCKET): Promise<boolean> => {
	const params = {
		Bucket,
		Key
	}

	try {
		await s3.headObject(params).promise()
		console.log(`File ${Key} exists in S3`)
		return true // File exists
	} catch (error) {
		if (error.code === "NotFound") {
			console.log(`File ${Key} does not exists in S3`)
			return false // File does not exist
		}

		throw error // Unexpected error
	}
}

export const readJsonFromS3 = async (Key:string, Bucket:string = process.env.PRODUCTS_S3_BUCKET):Promise<any> => {
	return new Promise((resolve, reject) => {
		const params = {
			Bucket,
			Key
		}

		s3.getObject(params, (err, data) => {
			if (err) {
				reject(err)
			} else {
				const jsonContent = JSON.parse(data.Body.toString("utf-8"))
				resolve(jsonContent)
			}
		})
	})
}

export const storeJsonToS3 = (Key:string, Body:any, Bucket:string = process.env.PRODUCTS_S3_BUCKET):Promise<any> => {
	// Store JSON to S3
	return new Promise((resolve, reject) => {
		const params = {
			Bucket,
			Key,
			Body: JSON.stringify(Body)
		}

		s3.putObject(params, (err, data) => {
			if (err) {
				reject(err)
			} else {
				resolve(data)
			}
		})
	})
}


import React from "react"
import DesktopMegaMenuItem from "./NewMegamenuComponents/DesktopMegamenuItem"
import MobileMegaMenuItem from "./NewMegamenuComponents/MobileMegaMenuItem"
import {useRouter} from "next/router"

type Props = {
	toggleMenu?: () => void
	item?: any,
	megaMenuTopCategories?:any[]
	otherInstancesBrandsForMegaMenu?:any[]
	themeColor?: string
	mainCategory?: string
}

const NewMegaMenu: React.FC<Props> = ({toggleMenu, item = "", megaMenuTopCategories, mainCategory, otherInstancesBrandsForMegaMenu, themeColor}) => {
	const router = useRouter()
	const isBrandWorld = router.pathname.includes("/brand-world ")
	return (
		<>
			<div className="bg-white d-none d-md-block"
				onMouseLeave={toggleMenu}
				style={{zIndex: 9999,
					borderBottomLeftRadius: "32px",
					borderBottomRightRadius: "32px",
					borderTop: "1px solid #DEE2E6",
					width: "auto"}}
			>
				{isBrandWorld ? null : <DesktopMegaMenuItem item={item}
					toggleMenu={toggleMenu}
					otherInstancesBrandsForMegaMenu={otherInstancesBrandsForMegaMenu}
					themeColor={themeColor} />}
			</div>
			{/*	Mobile */}
			<div style={{background: isBrandWorld ? "#212529" : "#fff"}}
				className=" d-md-none">
				<MobileMegaMenuItem item={item}
					toggleMenu={toggleMenu}
					megaMenuTopCategories={megaMenuTopCategories} />

			</div>
		</>
	)
}

export default NewMegaMenu

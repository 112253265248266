const urlParams = {
	categories: "categories",
	frameVariants: "frame-variant",
	speed: "speed",
	brand: "brand",
	motorBrand: "motor-brand",
	sortBy: "sortBy",
	sortOrder: "sortOrder",
	bikerHeight: "rider-height",
	priceRange: "priceRange",
	availability: "availability",
	type: "type",
	accessoryBrand: "accessoryBrand",
	batterieSize: "batterieSize",
	attachments: "attachments",
	maxGewicht: "maxGewicht",
	filtersFarbe: "filtersFarbe",
	searchValue: "searchValue",
	page: "page",
	organization: "organization",
	customInstanceSale: "customSale"
}

const localizedAttributeMap = {
	brand: {
		de: "Marke",
		fr: "Marque"
	},
	frameVariants: {
		de: "Rahmenvarianten",
		fr: "Cadre"
	},
	motorBrand: {
		de: "Motor Marke",
		fr: "Marque du moteur"
	}

}

export const getAttributeNamebyUrlParam = (param: string, locale = "de") => {
	if (param === urlParams.frameVariants) {
		return localizedAttributeMap[param][locale]
	}

	if (param === urlParams.page) {
		return "Page"
	}

	if (param === urlParams.speed) {
		return "Geschwindigkeit"
	}

	if (param === urlParams.brand) {
		return localizedAttributeMap[param][locale]
	}

	if (param === urlParams.motorBrand) {
		return localizedAttributeMap[param][locale]
	}

	if (param === urlParams.bikerHeight) {
		return "Körpergrösse"
	}

	if (param === urlParams.type) {
		return "accessory_type"
	}

	if (param === urlParams.accessoryBrand) {
		return "accessory_brand"
	}

	if (param === urlParams.batterieSize) {
		return "batterie_size"
	}

	if (param === urlParams.attachments) {
		return "Attachments"
	}

	if (param === urlParams.maxGewicht) {
		return "Max. Gewicht"
	}

	if (param === urlParams.filtersFarbe) {
		return "Filters Farbe"
	}
}

export default urlParams

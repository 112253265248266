import React from "react"
import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import {useTranslation} from "next-i18next"

type Props = {
isMobile?: boolean
	forSearchContentItem?: boolean
	isIkeaDesign?: boolean
}
function SwissMadeBadge({isMobile = false, isIkeaDesign = false, forSearchContentItem}:Props) {
	const {t} = useTranslation()
	return (
		<>
			{forSearchContentItem ? <div style={{
				borderRadius: "12px",
				width: "9.5rem",
				padding: forSearchContentItem ? "0px" : "2px 8px",
				color: "#212529"
			}}
			className={" d-none d-md-flex align-items-center justify-content-center"}>
				<Image src="/assets/icons/switzerland-icon.svg"
					width={12}
					height={12} />
				<Typography style={{fontSize: "12px", color: "#212529", display: "block"}}
					variant="bodyXSmBold"
					semanticTag="span"
					className={`badge ${forSearchContentItem ? "py-0" : "py-1"}`}
				>{t("swiss-made")}</Typography> </div> :
				<div style={{
					borderRadius: "12px",
					width: "9.5rem",
					padding: isMobile ? "0" : "2px 8px",
					color: "#212529",
					zIndex: "999",
					backgroundColor: isIkeaDesign ? "transparent" : "white"
				}}
				className={` d-flex align-items-center justify-content-center ${isIkeaDesign ? isMobile ? "swiss-badge-position-mobile" : "swiss-badge-position-ikea-design" : "swiss-badge-position"}`}>
					<Image layout="fixed"
						style={{borderRadius: "4px"}}
						src="/assets/icons/switzerland-icon.svg"
						width={12}
						height={12} />
					<Typography style={{fontSize: "12px", color: "#212529", display: "block", fontWeight: 600}}
						variant="bodySm"
						semanticTag="span"
						className={"badge py-1"}
					>{t("swiss-made")}</Typography> </div>
			}
		</>

	)
}

export default SwissMadeBadge

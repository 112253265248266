import React, {useContext} from "react"
import Story from "./Story"
import {ModalContext} from "../../context/ModalContext"
import {StoryItem} from "../../context/StoryContext"
import Typography from "../reusable/Typography/Typography"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
	size?: number,
	story: StoryItem,
	stories: StoryItem[]
}

const StoryButton = ({story, stories, size = 72}:Props) => {
	const {setModalContent, openStory} = useContext(ModalContext)
	const {reactGA} = useContext(AnalyticsContext)

	const handleClick = () => {
		// Log event
		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.openStory,
			label: gaEvents.openStory,
			nonInteraction: false
		})

		setModalContent(<Story stories={stories}
			currentStory={story}/>)
		openStory()
	}

	return (
		<div className={"text-center"}>
			<div className={"rounded-circle cursor-pointer border  position-relative mb-1"}
				style={{width: size, height: size * 1.5}}
				onClick={handleClick}>
				<div className={""}
					style={{
						width: "100%",
						height: "100%",
						backgroundImage: `url('${story.imageUrl}')`,
						backgroundSize: "cover"
					}}/>
			</div>
			<Typography variant={"bodyLg"}
				semanticTag={"span"}>
				{story.label}
			</Typography>
		</div>
	)
}

export default StoryButton

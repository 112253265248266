import React, {useEffect, useMemo, useRef, useState} from "react"
import {filterValueType} from "../SimilarBikesModal"
import {useRouter} from "next/router"
import {endpoints} from "../../../constants/API"
import {Attribute} from "../../../utility/Types"
import useCache from "../../../hooks/useCache"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import RenderBikesGrid from "./RenderBikesGrid"
import useOnScreen from "../../../hooks/OnScreen"
import Image from "next/image"
import Spinner from "../../reusable/Spinner"

type Props = {
    activeFilter: filterValueType
	attributes: Attribute[]
	isMobile?:boolean
}
function SimilarBikesModalContent({activeFilter, attributes, isMobile = false}: Props) {
	const [lazyLoadedPage, setLazyLoadedPage] = useState(0)
	const totalNumberOfProducts = useRef<number>(0)
	const {t} = useTranslation()
	const [filteredProducts, setFilteredProducts] = useState([])
	const [doneFetchingProducts, setDoneFetchingProducts] = useState(false)
	const router = useRouter()
	const endOfTheListIndicator = useRef<HTMLDivElement>(null)

	const reset = () => {
		setLazyLoadedPage(() => 0)
		setFilteredProducts(() => [])
		setDoneFetchingProducts(() => false)
	}

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("resetBikes", () => reset())
		}

		return window.removeEventListener("resetBikes", () => reset())
	}, [])

	const filterUrl = useMemo(() => {
		const batchSize = Number(process.env.NEXT_PUBLIC_BATCH_SIZE)
		const offsetNumber = filteredProducts.length ? lazyLoadedPage * batchSize : 0
		const baseParams = `locale=${router.locale}&limit=${batchSize}&offset=${offsetNumber}`

		if (activeFilter && !doneFetchingProducts) {
			const filteredAttributes = attributes?.find((att => att.slug === activeFilter))
			const params = {[filteredAttributes?.slug]: filteredAttributes?.terms?.map(({slug}) => slug).join(",")} || {}
			return `${endpoints.filterProducts}?params=${JSON.stringify(params)}&${baseParams}`
		}

		return null
	}, [activeFilter, lazyLoadedPage, lazyLoadedPage, doneFetchingProducts, filteredProducts])

	const {data, isLoading, error} = useCache(filterUrl)

	const getFilterUrlForDefaultSimilarBike = filter => {
		const attributeExists = attributes?.find(attribute => attribute.slug === filter)
		if (attributeExists) {
			const baseParams = `locale=${router.locale}&limit=${isMobile ? 6 : 5}&offset=${0}`
			const params = {[attributeExists?.slug]: attributeExists?.terms?.map(({slug}) => slug).join(",")} || {}
			return `${endpoints.filterProducts}?params=${JSON.stringify(params)}&${baseParams}`
		}

		return null
	}

	useEffect(() => {
		if (data?.products?.length > 0 && !isLoading) {
			totalNumberOfProducts.current = JSON.parse(data?.totalNumberOfProducts)
			setDoneFetchingProducts(() => filteredProducts.concat(data?.products).length === JSON.parse(data?.totalNumberOfProducts) || (Number(lazyLoadedPage + 1) * Number(process.env.NEXT_PUBLIC_BATCH_SIZE) >= JSON.parse(data?.totalNumberOfProducts)))
			setFilteredProducts(prev => [...prev, ...data.products])
		}

		if (error || data?.products?.length === 0) {
			setDoneFetchingProducts(true)
		}
	}, [data, isLoading])

	const triggerFetchMore = () => {
		if (!isLoading && !doneFetchingProducts) {
			setLazyLoadedPage(prevState => prevState + 1)
		}
	}

	const isOnScreen = useOnScreen(endOfTheListIndicator)

	useEffect(() => {
		if (isOnScreen) {
			triggerFetchMore()
		}
	}, [isOnScreen])

	const {data: similarRangeDefaultBikes, isLoading: isLoadingSimilarRangeBikes} = useCache(getFilterUrlForDefaultSimilarBike("range"))
	const {data: similarSpeedDefaultBikes, isLoading: isLoadingSimilarSpeedBikes} = useCache(getFilterUrlForDefaultSimilarBike("speed"))
	const {data: similarBatterySizeDefaultBikes, isLoading: isLoadingSimilarBatteryBikes} = useCache(getFilterUrlForDefaultSimilarBike("battery-capacity"))

	if (activeFilter) {
		return <>
			{isLoading && lazyLoadedPage === 0 ? <div className="p-4 col-12"> <Spinner /></div> : <RenderBikesGrid isMobile={isMobile}
				ref={endOfTheListIndicator}
				useLazyLoading
				triggerFetchMore={triggerFetchMore}
				isLoadingMoreProducts={isLoading}
				doneFetchingProducts={doneFetchingProducts}
				bikesList={filteredProducts} />}
		</>
	}

	if (isLoadingSimilarBatteryBikes || isLoadingSimilarRangeBikes || isLoadingSimilarSpeedBikes) {
		return <div className="p-4 col-12"> <Spinner /></div>
	}

	return <div style={{maxHeight: "65dvh"}}
		className=" overflow-auto col-12 pb-2 ">

		{!isLoadingSimilarRangeBikes && similarRangeDefaultBikes?.products?.length ? <div className="d-flex col-12 flex-column  mt-3  justify-content-start align-items-start">
			<Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 700}}
				className="d-flex similar-bikes-child-container-padding justify-content-start align-items-center gap-1"
				variant="bodySmBold"><Image width={18}
					height={18}
					alt={"Range"}
					src={"/assets/icons/max-range.svg"}/>{t("same-max-range") + "-" + (attributes?.find(attribute => attribute.slug === "range").terms[0].name ? attributes.find(attribute => attribute.slug === "range").terms[0].name : "")}</Typography>
			<RenderBikesGrid isMobile={isMobile}
				showOverflow={false}
				bikesList={similarRangeDefaultBikes.products || []} />
		</div> : null}

		{!isLoadingSimilarSpeedBikes && similarSpeedDefaultBikes?.products?.length ? <div className="d-flex col-12 flex-column  mt-3 justify-content-start align-items-start">
			<Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 700}}
				className="d-flex similar-bikes-child-container-padding justify-content-start align-items-center gap-1"
				variant="bodySmBold"><Image width={18}
					height={18}
					alt={"Speed"}
					src={"/assets/icons/speed.svg"}/>{t("same-speed") + "-" + (attributes?.find(attribute => attribute.slug === "speed").terms[0].name ? attributes.find(attribute => attribute.slug === "speed").terms[0].name : "")}</Typography>
			<RenderBikesGrid isMobile={isMobile}
				showOverflow={false}
				bikesList={similarSpeedDefaultBikes.products || []} />
		</div> : null}

		{!isLoadingSimilarBatteryBikes && similarBatterySizeDefaultBikes?.products?.length ? <div className="d-flex col-12 flex-column  mt-3 justify-content-start align-items-start">
			<Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 700}}
				className="d-flex similar-bikes-child-container-padding justify-content-start align-items-center gap-1"
				variant="bodySmBold"><Image width={18}
					height={18}
					alt={"Battery capacity"}
					src={"/assets/icons/battery-capacity.svg"}/>{t("same-battery-capacity") + "-" + (attributes?.find(attribute => attribute.slug === "battery-capacity").terms[0].name ? attributes.find(attribute => attribute.slug === "battery-capacity").terms[0].name : "")}</Typography>
			<RenderBikesGrid isMobile={isMobile}
				showOverflow={false}
				bikesList={similarBatterySizeDefaultBikes.products || []} />
		</div> : null}

	</div>
}

export default SimilarBikesModalContent

export const getContentFields = (strapiData:any, fields:string[]) => {
	const data:any = {}
	fields?.forEach((field:string) => {
		data[field] = strapiData?.attributes?.[field]
	})
	return data
}

export const strapiFieldKeys = {
	seoTitle: "seoTitle",
	seoDescription: "seoDescription",
	slug: "slug",
	productListing: "productListing",
	paragraph: "paragraph",
	text: "text",
	menuLabel: "menuLabel",
	image: "image",
	type: "type",
	filterSlug: "filterSlug",
	addToMegaMenu: "addToMegaMenu",
	cta: "cta",
	addToFooter: "addToFooter",
	icon: "icon",
	headerImage: "headerImage",
	headerTitle: "headerTitle",
	headerSubtitle: "headerSubtitle",
	headerTagline: "headerTagline",
	thumbnail: "thumbnail",
	address: "address",
	brands: "brands",
	email: "email",
	phone: "phone",
	locale:	"locale",
	name: "name"
}

export const formatImageSrc = (path: string) =>
	path ? `${process.env.NEXT_PUBLIC_STRAPI_URL}${path}` : null

export const formatStrapiFieldsData = (data:any[], fields:string[]) => {
	return data.map(item => getContentFields(item, fields))
}

export const formatStrapiData = (strapiData:any[], fields:string[]) => strapiData?.map(data => getContentFields(data, fields))


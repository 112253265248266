import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import Link from "next/link"
import styles from "../navigation.module.scss"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import React from "react"
import {useTranslation} from "next-i18next"

type Props = {
	isInfocenterMenuVisible: any
	setIsInfocenterMenuVisible: any
	menuItems: any
}

const InfocenterMenu: React.FC<Props> = ({isInfocenterMenuVisible, setIsInfocenterMenuVisible, menuItems}) => {
	const {t} = useTranslation("common")

	return (
		<AnimationComponent type="slideRight"
			inProp={isInfocenterMenuVisible}>
			<div
				className={"position-fixed border-top bg-white start-0 px-3 pt-4 overflow-auto"}
				style={{zIndex: 99999, top: 0, width: "100%", height: "100%", paddingBottom: 250}}>
				<div className="d-flex align-items-center py-2 mb-3">
					<Image
						src="/assets/icons/arrow-left.svg"
						width={20}
						height={20}
						alt={"arrow left"}
						className="cursor-pointer"
						onClick={() => setIsInfocenterMenuVisible(false)}
					/>
					<Typography variant="heading3MdBodyLgBold"
						semanticTag="span"
						className="ms-3">{t("Resources")}</Typography>
				</div>

				<div className="d-flex flex-column ps-4">
					{menuItems.map((item, index) =>
						<Link href={item.name === "FAQ" ? `/faq${t("/konzept")}` : item.name === "Showroom" ? t("showroom-brand-world-link") : item.href}
							key={index}>
							<a className={`text-decoration-none rounded-3 text-dark ${styles.megamenuItem}`}>
								{t(item.name)}
							</a>
						</Link>
					)}
				</div>
			</div>
		</AnimationComponent>
	)
}

export default InfocenterMenu

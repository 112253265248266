import React, {useState} from "react"
import PropTypes from "prop-types"
import AccordionItem from "../Accordion/AccordionItem"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
    title: string,
    text: any,
	referral?: boolean
	translation?: Function
}

const FaqItem: React.FC<Props> = ({title, text, referral}) => {
	const {t} = useTranslation()

	const [active, setActive] = useState(null)

	const onToggle = title => {
		if (active === title) {
			setActive(null)
		} else {
			// Router.push(`${tab}/${slugify(`${title}`)}`, undefined, {shallow: true, scroll: false}).then()
			setActive(title)
		}
	}

	const classCol = referral ? "col-12" : "col-12 col-md-6"

	return (
		<div className={classCol}>
			<AccordionItem
				active={active === title}
				title={title}
				onToggle={onToggle}
				multiple={true}
				grey
				noShadow
				plusIcon
			>
				{typeof text === "string" ? <Typography variant={"bodyLg"}
					semanticTag={"p"}>{text}</Typography> : (
					<>
						{text.order.map((element, i) => {
							if (element === "list") {
								return (<ul>
									{text[element].items.map((item, i) => {
										return <li key={++i}>{t(item)}</li>
									})}
								</ul>)
							}

							return <Typography key={++i}
								variant={"bodyLg"}
								semanticTag={"p"}>{t(text[element])}</Typography>
						})
						}
					</>
				)}
			</AccordionItem>
		</div>
	)
}

FaqItem.propTypes = {
	title: PropTypes.string,
	text: PropTypes.any,
	referral: PropTypes.bool,
	translation: PropTypes.func

}

export default FaqItem

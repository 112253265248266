import React, {Dispatch, SetStateAction} from "react"

export type SearchContextProviderState = {
    isOn: boolean,
    toggle: Dispatch<SetStateAction<boolean>>,
    isLoading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>,
	results: any | null,
	setResults: (results: any | null)=>void,
	value: string | null,
	setValue: Dispatch<SetStateAction<string>>,
	handleSearch: (value:string)=>void,
	recentSearchTerms: string[],
	recentSearchTermsRefs: any[],
	setRecentSearchTermsRefs: (value:any)=>void,
	termValue: string,
	setTermValue: Dispatch<SetStateAction<string>>
}

const SearchContext = React.createContext<SearchContextProviderState>({
	isOn: false,
	toggle: () => {
		// Do nothing
	},
	isLoading: false,
	setLoading: () => {
		// Do nothing
	},
	results: null,
	setResults: () => {
		// Do nothing
	},
	value: null,
	setValue: () => {
		// Do nothing
	},
	handleSearch: () => {
		// Do nothing
	},
	recentSearchTerms: [],
	recentSearchTermsRefs: [],
	setRecentSearchTermsRefs: () => {
		// Do nothing
	},
	termValue: "",
	setTermValue: () => {
		// Do nothing
	}
})

export default SearchContext

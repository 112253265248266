import React, {useEffect, useRef, useState} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import styles from "./BookAppointment.module.scss"
import {CheckLg} from "react-bootstrap-icons"
import Button from "../components/Button"

type Props = {
	closeModal: ()=> void
}

const BookAppointmentModalContent: React.FC<Props> = ({closeModal}) => {
	const {t} = useTranslation("common")
	const scriptRef = useRef<HTMLScriptElement>(null)
	const iframeContainertRef = useRef<HTMLDivElement>()
	const [update, forceUpdate] = useState(0)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const scriptTag = document.createElement("script")
		scriptTag.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
		scriptRef.current = scriptTag
		document.body.appendChild(scriptTag)
		setTimeout(() => forceUpdate(1), 2000)
		return () => {
			scriptRef.current?.remove()
		}
	}, [])
	useEffect(() => {
		if (iframeContainertRef.current.firstElementChild) {
			setIsLoading(false)
		}
	}, [update])
	return (
		<>
			{isLoading &&
			<div style={{zIndex: 15000}}
				className="position-absolute d-flex justify-content-center align-items-center w-100 h-100">
				<div className="spinner-border text-primary p-5"/>
			</div>
			}
			<div className={`container book-appointment ${isLoading ? styles.modalHidden : styles.modalActive}`}>
				<div style={{maxHeight: "80vh"}}
					className={"row justify-content-center align-items-center"}>
					<div className={"col-12 col-md-12 col-lg-12"}>
						<div className={"bg-white"}
							onClick={e => {
								e.stopPropagation()
							}}>
							<div className="row bg-white position-relative rounded">
								<div className="col-12 col-lg-7 d-flex flex-column justify-content-center mt-1 ms-1 ms-md-0 ps-4 ps-md-5">
									<Typography className="mb-0 mt-3"
										variant="heading2"
										semanticTag="h2">
										{t("Kostenlose Beratung buchen")}
									</Typography>
									<ul className={"row mt-1 mb-1 pb-1 mb-lg-3 pb-lg-3 justify-content-start list-unstyled"}>
										<li className="mb-1 d-flex"><CheckLg className="mt-1"
											size={16}/><Typography className="ms-2"
											variant="bodyLg"
											semanticTag="span">{t("Free consultation")}</Typography></li>
										<li className="mb-1 d-flex"><CheckLg className="mt-1"
											size={16}/><Typography className="ms-2"
											variant="bodyLg"
											semanticTag="span">{t("It will take 15-30 minutes")}</Typography></li>
										<li className="d-flex"><CheckLg className="mt-1"
											size={16}/><Typography className="ms-2"
											variant="bodyLg"
											semanticTag="span">{t("You will find which e-bike is perfect for you!")}</Typography></li>
									</ul>
								</div>

								<div className="position-absolute w-auto"
									style={{top: "70%", left: 40}}>
									<Button className="d-none d-md-block text-uppercase pointer"
										onClick={closeModal}
										bgColor="#FEC04D"
										color="#212529"
										textColor="#212529"
										label={t("Abbrechen")}/>
								</div>

								<div className="col-12 col-lg-5 overflow-hidden">
									<div className="d-flex justify-content-center">
										<Button className="d-md-none mt-3 text-uppercase pointer mx-auto  "
											onClick={closeModal}
											bgColor="#FEC04D"
											fontSizeSmall
											color="#212529"
											textColor="#212529"
											label={t("Abbrechen")} />
									</div>
									<div id={"hubspot_frame"}
										ref={iframeContainertRef}
										className="row meetings-iframe-container d-flex justify-content-center"
										data-src={t("link-meeting-consultation")}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default BookAppointmentModalContent

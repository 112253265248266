import {useEffect, useRef, useState} from "react"

function useHandleNavbarScrollBehaviour(isSimilarBikesModalOpen:boolean) {
	const [isScrollingDown, setIsScrollingDown] = useState(false)
	const lastScrollPosition = useRef<number>(0)
	const handleScroll = () => {
		const currentScrollPosition = window.scrollY
		if (currentScrollPosition > lastScrollPosition.current && currentScrollPosition > 100) {
			setIsScrollingDown(true)
		} else if ((currentScrollPosition < lastScrollPosition.current) && !isSimilarBikesModalOpen) {
			setIsScrollingDown(false)
		}

		lastScrollPosition.current = currentScrollPosition
	}

	useEffect(() => {
		if (isSimilarBikesModalOpen) {
			setIsScrollingDown(true)
		} else {
			setIsScrollingDown(false)
		}

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [isSimilarBikesModalOpen])
	return {isScrollingDown}
}

export default useHandleNavbarScrollBehaviour

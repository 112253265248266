import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import {CheckLg, X} from "react-bootstrap-icons"
import Image from "next/image"
import BookAppointmentButton from "../ModalButtons/BookAppointmentButton"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"
import Button from "../components/Button"
import AskAQuestion from "../../bikeDetails/AskAQuestion"
import {ModalContext} from "../../../context/ModalContext"

type Props = {closeModal: ()=>void}

const ConsultBookingModalContent: React.FC<Props> = ({closeModal}) => {
	const bookAppoinmentIcon = <img src="/assets/icons/componentsIcons/consultation.png"
		width={24}
		alt=""/>

	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	const {setModalContent, openModal} = useContext(ModalContext)

	reactGA?.event({
		category: gaCategories.pdp,
		action: gaEvents.consultationPopUp,
		label: gaEvents.consultationPopUp,
		nonInteraction: false
	})

	return (
		<div onClick={e => {
			e.stopPropagation()
		}}
		className="newsletter-modal container-md bg-white px-2 px-md-0 ">

			<div className={"d-md-none row m-0 justify-content-end"}>
				<div className={"col-auto px-0 px-md-3"}>
					<div className={"py-2 px-0"}>
						<button type={"button"}
							className={"btn px-0"}
							onClick={closeModal}>
							<X size={30}
								color={"black"}/>
						</button>
					</div>
				</div>
			</div>

			<div className="d-md-none row mx-1">
				<div className="col-3 p-0 ps-2 rounded-3 overflow-hidden position-relative">
					<Image src={"/assets/images/blog/PortraitSarina.jpg"}
						objectFit="cover"
						layout="responsive"
						width={64}
						height={64}/>
				</div>
				<div className="col-9">
					<Typography variant="bodyLgBold"
						semanticTag="h3"
						className="col-auto">{t("modal-consult-title-1")}</Typography>
					<Typography variant="bodyLg"
						semanticTag="p"
						className="col-auto m-0">{t("modal-consult-title-2")}</Typography>
				</div>
			</div>

			<div className="row position-relative mt-3 mt-md-3 mx-0 justify-content-between">
				<div className="col-12 col-md-6 bg-white py-md-5 d-flex justify-content-center align-items-center">
					<div className="container px-0 px-md-4">
						<div className="row mb-md-4">

							<Typography className="d-none d-md-block col-12 mb-2"
								variant="heading3"
								semanticTag="h2">{t("modal-consult-title-1")}</Typography>
							<Typography className="d-none d-md-block col-12 mb-0"
								variant="bodyLg"
								semanticTag="h3">{t("modal-consult-title-2")}</Typography>

							<ul className={"col mt-3 mb-1 pb-3 justify-content-start list-unstyled"}>
								<li className="mb-2 d-flex"><CheckLg className="mt-1 col-auto"
									size={16}/><Typography className="ms-2"
									variant="bodyLg"
									semanticTag="span">{t("modal-consult-content-list-1")}</Typography></li>
								<li className="mb-2 d-flex"><CheckLg className="mt-1 col-auto"
									size={16}/><Typography className="ms-2"
									variant="bodyLg"
									semanticTag="span">{t("modal-consult-content-list-2")}</Typography></li>
								<li className="d-flex"><CheckLg className="mt-1 col-auto"
									size={16}/><Typography className="ms-2"
									variant="bodyLg"
									semanticTag="span">{t("modal-consult-content-list-3")}</Typography></li>
							</ul>
						</div>
						<div className={"row mb-0"}>
							<div className={"col-12 mb-3"}>
								<BookAppointmentButton color="orange"
									label={t("Beratung Buchen")}
									icon={bookAppoinmentIcon} />

							</div>
						</div>
						<div className={"row mb-4 mb-md-4"}>
							<div className={"col-12"}>
								{/* //old question button */}
								{/* <SuggestBikeButton textColor="#212529" hoverColor="#212529" color="white" label={(t("Stelle eine Frage")).toUpperCase()}/> */}

								{/* new question button */}
								<Button icon={<img src="/assets/icons/share/mail.svg"
									alt="" />}
								className="col-12"
								onClick={() => {
									setModalContent(<AskAQuestion setAskAQuestion={closeModal}/>)
									openModal()
								}}
								textColor="#212529"
								color="#fff"
								bgColor="#fff"
								hoverColor="#212529"
								outline
								label={t("Stelle eine Frage")}/>

							</div>
						</div>
					</div>
				</div>

				<div className="d-none d-md-block col-5 p-0 position-relative overflow-hidden">
					<Image src="/assets/images/sarina-popup-modal.jpg"
						alt="bike"
						layout="responsive"
						width={410}
						height={605}
						objectFit="contain"
						objectPosition="center"
					/>
					<button type={"button"}
						className={"btn bg-white rounded-circle p-1 m-2 position-absolute top-0 end-0 pointer"}
						onClick={closeModal}>
						<X size={20}
							color={"black"}/>
					</button>
				</div>

			</div>
		</div>
	)
}

export default ConsultBookingModalContent

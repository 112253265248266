import {CollectionName} from "../../constants"

const getBasePath = (pageName: CollectionName, collectionName?: string, customUrlBase?: string, brand = ""): string => {
	switch (pageName) {
		case CollectionName.Accessories:
			return "/alle-modelle-e-bike-schweiz"
		case CollectionName.Categories:
			return `/kategorien/${collectionName}`
		case CollectionName.Brands:
			return `/marken/${collectionName}`
		case CollectionName.Custom:
			return `/${customUrlBase}`
		case CollectionName.BrandWorld:
			return `/brand-world/${brand}${customUrlBase}`
		default:
			return "/alle-modelle-e-bike-schweiz"
	}
}

export const filterUrl = (
	params: Record<string, any>,
	key: string,
	value: any,
	pageName?: CollectionName,
	collectionName?: string,
	customUrlBase?: string,
	brand?:string
): string => {
	const basePath = getBasePath(pageName, collectionName, customUrlBase, brand)
	const queryParams = new URLSearchParams()
	Object.entries(params)?.forEach(([paramKey, paramValue]) => {
		if (paramValue !== undefined && paramKey !== key) {
			queryParams.append(paramKey, paramValue)
		}
	})

	if (key && value !== undefined) {
		queryParams.set(key, value)
	}

	const url = `${basePath}${Object.entries(params)?.length > 0 ? "?" + queryParams.toString() : ""}`
	return decodeURIComponent(url).split("=,").join("=")
}

import React, {Dispatch, SetStateAction, useContext} from "react"
import Typography from "../reusable/Typography/Typography"
import urlParams from "../../utility/UrlParams"
import {fetchGet} from "../../firebaseAdmin/fetchGet"
import {filterUrl} from "../filter/FilterUrl"
import {useRouter} from "next/router"
import SearchContext from "../../context/SearchContext"
import {useTranslation} from "next-i18next"

type Props = {
    topSearches: any[]
    setIsBackgroundOn:Dispatch<SetStateAction<boolean>>
		forSearchContent?: boolean
}
function TopSearches({topSearches, setIsBackgroundOn, forSearchContent}: Props) {
	const router = useRouter()
	const {value, setValue, setTermValue} = useContext(SearchContext)
	const {t} = useTranslation()
	return (
		<div style={{
			rowGap: "12px",
			overflowY: "hidden"
		}}
		className={`d-flex ${forSearchContent ? "gap-2 top-searches" : "flex-column mt-3"}`}>
			{topSearches && topSearches?.length ? topSearches.map((suggestion, index) =>
				<div onClick={async () => {
					await fetchGet(`/api/search?term=${value}&index=${index}&limit=10&offset=0&locale=${router.locale}&source=user-clicked-on-a-specific-bike`)
					router.replace(filterUrl({[urlParams.searchValue]: suggestion.name, page: "-"}, null, null))
					setIsBackgroundOn(() => false)
					setValue(() => suggestion.name)
					setTermValue(() => suggestion.name)
				}}
				key={index}
				className="d-flex align-items-center justify-content-start">
					<Typography variant={"bodySm"}
						semanticTag={"div"}
						className={"mb-0 cursor-pointer"}>
						{suggestion.name}
					</Typography></div>
			) : null}
		</div>
	)
}

export default TopSearches

import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Price from "./Price"
import TotalPrice from "./TotalPrice"
import Typography from "../../reusable/Typography/Typography"
import CollectionItemBadge from "./CollectionItemBadge"
import AboutThisBike from "../../bikeDetails/AboutThisBike"
import {isOccasionBike} from "../../../utility/Helper"
import BikeName from "./BikeName"
import ComparisonCheckboxButton from "./ComparisonCheckboxButton"
import Image from "next/image"
import Link from "next/link"
import StockWarning from "./StockWarning"
import getMainCategory from "../../filter/GetMainCategory"
import ProductColors from "./ProductColors"
import useDiscount from "../../../hooks/useDiscount"
import SwissMadeBadge from "./SwissMadeBadge"
import {BikeType} from "../../../utility/Types"
import useHandleBookmark from "../../../hooks/useHandleBookmark"
import DataContext from "../../../context/DataContext"
import {getOrganizationDataFromStrapi} from "../../../utility/strapi/helpers"

type BageProps = {
	isBikeOfTheMonth: boolean
	is2025: boolean
	isNew: boolean
	kilometers: boolean
	hasTestDrive: boolean
    isRentable: boolean
	occasion: boolean
	isInStock: boolean
	isSwissMade: boolean
	isNonEBike: boolean
	isFeatured: boolean
	isBrandWorldRecommended: boolean
	isRecommended: boolean
}

type Props = {
	productUrl: string
	selected: boolean
	soldOut: boolean
	bike: BikeType
	isSearchResult?:boolean
	badgeProps: BageProps
	handleMouseEnter: (bikeSlug) => void
	handleMouseLeave: React.MouseEventHandler<HTMLElement>
	mainCategory?: string
	onScreenCallback?: any
	isLast?: boolean
	isGridTopLeftRounded?: boolean
	isGridTopRightRounded?: boolean
	isGridBottomRightRounded?: boolean
	isGridBottomLeftRounded?: boolean
	isIkeaDesign?: boolean
	isListviewItemSmall?: boolean
}

const CollectionItemListView = ({
	productUrl,
	selected,
	soldOut,
	bike,
	badgeProps,
	handleMouseEnter,
	handleMouseLeave,
	isSearchResult,
	mainCategory,
	isGridTopRightRounded = false,
	isGridTopLeftRounded = false,
	isGridBottomRightRounded = false,
	isGridBottomLeftRounded = false,
	isListviewItemSmall
}: Props) => {
	const {isBikeOfTheMonth, is2025, isNew, kilometers, occasion, isNonEBike, isFeatured, isSwissMade, isBrandWorldRecommended} = badgeProps
	const {t} = useTranslation()
	const isSecondHand = isOccasionBike(bike)
	const totalPhysicalStock = bike?.totalPhysicalStock
	const {discount, onSale, finalPriceWithDownPayment, organizationSlug, downPayment, hasOrganizationDiscount} = useDiscount(bike)
	const {pageProps} = useContext(DataContext)
	const organizations = pageProps?.strapiContent?.data?.organizations

	const organizationData = getOrganizationDataFromStrapi(organizations, organizationSlug)
	const showPartnershipExclusiveText = Boolean(bike?.customInstanceDiscounts?.[organizationSlug])
	const {handleAddBookmark, handleDeleteBookmark, isBookmarked} = useHandleBookmark(bike)
	return (
		<> {isListviewItemSmall ?
			<Link href={productUrl}
				passHref={true}
				legacyBehavior
				replace>
				<a
					className={`link-reset  d-md-flex align-items-center row cursor-pointer mb-2 position-relative justify-content-between position-relative card-item ${selected ? "card-shadow" : null}`}
					onMouseEnter={() => handleMouseEnter(bike?.slug)}
					onMouseLeave={handleMouseLeave}
					style={{minHeight: 80, minWidth: 325, marginBottom: "1px", border: (isFeatured || isBrandWorldRecommended) && selected && !isSearchResult ? "1px solid #FEC04D" : isSearchResult ? "1px solid #DEE2E6" : "1px solid transparent", borderRadius: 16}}>
					<div className="col-12 d-flex justify-content-start gap-3 flex-nowrap align-items-center px-2 py-1">
						<div className="px-0 col-3"
							style={{
								height: 72,
								maxWidth: 72,
								position: "relative",
								backgroundPosition: "center",
								backgroundSize: "cover"
							}}>
							<Image style={{borderRadius: "8px"}}
								src={bike?.image || bike.images[0]?.src}
								layout="fill" />
						</div>
						<div className="col-8 px-0">
							<div className="col-12 d-flex gap-1">
								<CollectionItemBadge
									isBikeOfTheMonth={isBikeOfTheMonth}
									isFeatured={isFeatured}
									isBrandWorldRecommended={isBrandWorldRecommended}
									isNonEBike={isNonEBike}
									is2025={is2025}
									isNew={isNew}
									kilometers={kilometers}
									occasion={occasion}
									onSale={onSale}
									soldOut={soldOut}
									discount={discount}/>
								{isSwissMade ? <SwissMadeBadge forSearchContentItem/> : null}
								<StockWarning islistView
									amount={totalPhysicalStock as number}
									forSearchContentItem/>
							</div>
							<Typography variant={"bodySmBold"}
								semanticTag="h4"
								className="mt-2 mb-2">
								{bike.name}
							</Typography>
							<Price price={bike.uvpPrice}
								discountedPrice={finalPriceWithDownPayment}
								onSale={onSale}
								isSecondHand={isSecondHand}
								secondHandPrice={bike.displayPrice}
								priceFontSize={24}/>
						</div>
					</div>
				</a>
			</Link> : <>
				<Link href={productUrl}
					passHref={true}>
					<a
						className={`row d-block d-md-none link-reset cursor-pointer position-relative justify-content-between position-relative  ${selected ? "card-shadow" : null}`}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						style={{outline: (isFeatured || isBrandWorldRecommended) ? "1px solid #FEC04D" : "1px solid #DEE2E6", margin: "0.5px", minWidth: "343px", padding: "16px 0px 8px 0px", borderBottomLeftRadius: isGridBottomLeftRounded ? "8px" : "", borderBottomRightRadius: isGridBottomRightRounded ? "8px" : "", borderTopRightRadius: isGridTopRightRounded ? "8px" : "", borderTopLeftRadius: isGridTopLeftRounded ? "8px" : ""}}
					>
						{isBookmarked ? <div style={{width: "50px"}}
							onClick={handleDeleteBookmark}> <img className="bookmark-list-view"
								src={"/assets/icons/Heart-gold.svg"}
								alt="Wish list icon" /></div> : <div style={{width: "40px"}}
							onClick={handleDeleteBookmark}><img onClick={handleAddBookmark}
								className="bookmark-list-view"
								src={"/assets/icons/Heart.svg"}
								alt="Wish list icon" /> </div>}

						{/* {isSwissMade ? <SwissMadeBadge isMobile/> : null} */}
						<div className="col-12 bg-white mb-2 "
							style={{height: 96, borderRadius: 8}}
						>
							<div className="d-flex justify-content-start flex-nowrap align-items-center">
								<div className="px-0 col-auto position-relative">
									<div
										className="position-absolute col-12 d-flex align-items-start z-index"
										style={{top: -8, left: "5rem"}}>
										<CollectionItemBadge
											isBikeOfTheMonth={isBikeOfTheMonth}
											isNonEBike={isNonEBike}
											isFeatured={isFeatured}
											isBrandWorldRecommended={isBrandWorldRecommended}
											is2025={is2025}
											isNew={isNew}
											kilometers={kilometers}
											occasion={occasion}
											onSale={onSale}
											soldOut={soldOut}
											discount={discount}
										/>
									</div>
									<div style={{height: 72}}>
										<Image src={bike?.image || bike.images[0]?.src}
											height={72}
											width={72}/>
									</div>
									<div className={"mt-2"}>
										<StockWarning amount={totalPhysicalStock as number} />
									</div>
								</div>
								<div className="col-8 ms-3 mt-4">
									<BikeName bike={bike} />
									{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug && showPartnershipExclusiveText && <div className="d-flex col-12 align-items-start pb-2 gap-2">
										<img src={organizationData?.companyIcon}
											width={"auto"}
											height={18}/>
										<Typography style={{marginTop: -2}}
											variant={ "bodyXSm"}
											semanticTag="span">
											{t(organizationData?.listingItem?.text).replace("instance", organizationData?.companyName)}
										</Typography>
									</div>}
									<Price isListViewOnMobile={true}
										price={bike.uvpPrice}
										discountedPrice={finalPriceWithDownPayment}
										onSale={onSale}
										isSecondHand={isSecondHand}
										secondHandPrice={bike.displayPrice}/>
									<TotalPrice isListViewOnMobile={true}
										price={bike.uvpPrice}
										onSale={onSale}
										listView
										isSecondHand={isSecondHand}
										secondHandPrice={bike.displayPrice}
										discountedPrice={finalPriceWithDownPayment}
										priceVariant={ (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && hasOrganizationDiscount) || isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm" } />
								</div>

							</div>
						</div>
						<div className="d-flex flex-wrap"
						>
							{isBikeOfTheMonth && <Typography variant="bodySmBold"
								semanticTag="span"
								style={{backgroundColor: "#000"}}
								className="badge text-white text-uppercase py-2 px-3 mb-1 me-2">
								{t("bike-of-th-month")}
							</Typography>}

						</div>
						<div className="col-12">
							<div className="col-12  mt-3 mb-2 border-bottom" />
							<div className="d-flex col-12 justify-content-between mb-4 px-0">
								<Typography variant="bodyXSm"
									semanticTag="span"
									className={"col-auto"}>
									{getMainCategory(bike?.categories)?.name || bike?.categories?.[0].name || mainCategory}
								</Typography>
							</div>
							<div className="d-flex gap-1 flex-column align-items-start justify-content-start">
								<AboutThisBike bike={bike}
									noTitle
									noIcon={true}
									extraSmall
									twoColumn
									isSecondHand={isSecondHand} />
								<div className="col-6 d-flex flex-column gap-2   mt-2">
									<Typography
										variant="bodyXSmBold">
								Colors
									</Typography>
									<ProductColors colors={bike.colorData} />
								</div>
							</div>

						</div>

						<ComparisonCheckboxButton isListView={true}
							slug={bike.slug}/>

					</a>
				</Link>
				<Link href={productUrl}
					passHref={true}>
					<a
						className={`link-reset d-none d-md-flex align-items-center row p-3 cursor-pointer  pb-3 position-relative justify-content-between position-relative card-item ${selected ? "card-shadow" : null}`}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						style={{outline: (isFeatured || isBrandWorldRecommended) ? "1px solid #FEC04D" : "1px solid #DEE2E6", margin: "0.5px", borderBottomLeftRadius: isGridBottomLeftRounded ? "16px" : "", borderBottomRightRadius: isGridBottomRightRounded ? "16px" : "", borderTopRightRadius: isGridTopRightRounded ? "16px" : "", borderTopLeftRadius: isGridTopLeftRounded ? "16px" : "", minHeight: 288, maxWidth: 1096}}
					>
						<div className="col-7 bg-white"
							style={{minHeight: 96, borderRadius: 8, position: "relative"}}>
							{/* {isSwissMade ? <SwissMadeBadge /> : null} */}
							{isBookmarked ? <div style={{width: "50px"}}
								onClick={handleDeleteBookmark}> <img className="bookmark-list-view"
									src={"/assets/icons/Heart-gold.svg"}
									alt="Wish list icon" /></div> : <div style={{width: "40px"}}
								onClick={handleDeleteBookmark}><img onClick={handleAddBookmark}
									className="bookmark-list-view"
									src={"/assets/icons/Heart.svg"}
									alt="Wish list icon" /> </div>}
							<ComparisonCheckboxButton isListView={true}
								slug={bike.slug}/>

							<div className="d-flex justify-content-between flex-nowrap align-items-center p-2">
								<div className="px-0 col-6"
									style={{
										height: 248,
										maxWidth: 256,
										position: "relative",
										backgroundPosition: "center",
										backgroundSize: "cover"
									}}>
									<Image src={bike.images[0]?.src}
										layout="fill" />
									<div
										className="position-absolute col-12 d-flex align-items-start z-index"
										style={{top: -8, left: 8}}>

										<CollectionItemBadge swissMade={isSwissMade}
											isBikeOfTheMonth={isBikeOfTheMonth}
											isFeatured={isFeatured}
											isBrandWorldRecommended={isBrandWorldRecommended}
											isNonEBike={isNonEBike}
											is2025={is2025}
											isNew={isNew}
											kilometers={kilometers}
											occasion={occasion}
											onSale={onSale}
											soldOut={soldOut}
											discount={discount}/>
									</div>
									<StockWarning islistView
										amount={totalPhysicalStock as number} />
								</div>
								<div className="col-5 ms-4 px-0">
									<BikeName bike={bike} />
									{Boolean(process.env.NEXT_PUBLIC_CUSTOM_INSTANCE) && organizationSlug && showPartnershipExclusiveText && <div className="d-flex align-items-start py-3 gap-2">
										<img src={organizationData?.companyIcon}
											width={"auto"}
											height={18}/>
										<Typography style={{marginTop: -2}}
											variant={ "bodyXSm"}
											semanticTag="span">
											{t(organizationData?.listingItem?.text).replace("instance", organizationData?.companyName)}
										</Typography>
									</div>}
									<div className="d-flex col-12 justify-content-between mb-4 px-0">
										<Typography variant="bodyXSm"
											semanticTag="span"
											className={"col-auto"}>
											{getMainCategory(bike?.categories)?.name || bike?.categories?.[0].name || mainCategory}
										</Typography>
									</div>
									<Price price={bike.uvpPrice}
										discountedPrice={finalPriceWithDownPayment}
										onSale={onSale}
										isSecondHand={isSecondHand}
										secondHandPrice={bike.displayPrice}/>
									<TotalPrice price={bike.uvpPrice}
										onSale={onSale}
										listView
										isSecondHand={isSecondHand}
										secondHandPrice={bike.displayPrice}
										discountedPrice={finalPriceWithDownPayment}
										priceVariant={ (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && hasOrganizationDiscount) || isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm" } />
								</div>
							</div>
						</div>
						<div className="col-5 ps-4">
							<AboutThisBike noIcon
								bike={bike}
								noTitle
								twoColumn
								small
								isSecondHand={isSecondHand} />
							<div style={{marginLeft: 4}}
								className="col-6 row  mt-2">
								<Typography
									variant="bodySmBold">
								Colors
								</Typography>
								<ProductColors colors={bike.colorData} />
							</div>

						</div>
					</a>
				</Link>
			</>}
		</>
	)
}

export default CollectionItemListView

CollectionItemListView.displayName = "CollectionItemListView"

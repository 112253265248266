import React, {useContext, useMemo} from "react"
import getAccordionContent from "../components/bikeDetails/AccordionContent"
import CustomerQuestionsSection from "../components/bikeDetails/CustomerQuestionsSection"
import Typography from "../components/reusable/Typography/Typography"
import {variants} from "../middleware"
import ABContext from "../context/ABContext"

function useGetAccordionContent(bike, isAccessory, isMobile, selectedVariation, questions, t) {
	const {variant} = useContext(ABContext)

	const accordionContentOrigin = useMemo(() => getAccordionContent(bike, isAccessory, t), [
		bike,
		isAccessory,
		isMobile
	])

	const isNew = variant === variants.new

	const customerQuestionsContent = {
		title: `${t("customers_questions")} ${questions?.length ? `(${questions?.length})` : ""} `,
		slug: t("customers_questions"),
		content: (
			<CustomerQuestionsSection
				selectedVariation={selectedVariation}
				bike={bike}
				questions={questions}
			/>
		),
		titleContent: (
			<Typography semanticTag="h3"
				className="mb-0"
				variant={"heading1Black"}>
				{`${t("customers_questions")} ${questions?.length ? `(${questions?.length})` : ""}`}
			</Typography>
		)
	}

	const accordionContent = isNew ?
		[...accordionContentOrigin, customerQuestionsContent] :
		[...accordionContentOrigin, customerQuestionsContent]

	return {accordionContent}
}

export default useGetAccordionContent

import {createContext, Dispatch, SetStateAction} from "react"
import {BikeType} from "../utility/Types"

type WishListContextProviderState = {
    setWishList: Dispatch<SetStateAction<BikeType[]>>
    wishList: BikeType[]
    addToWishList: (Bike: BikeType) => void
    clearWishList: () => void
    deleteWishListItemBySlug: (id: string) => void
    getWishListItemBySlug: (id: string) => BikeType
    isFetchingFromFirestore?: boolean
    wishListFetchedFromFirestore?: boolean
    isStoringNewWishListItem?: boolean
    latestItemAdded?: BikeType
    resetNotifications?: () => void
}

const WishListContext = createContext<WishListContextProviderState>({
	setWishList: () => null,
	wishList: [],
	addToWishList: () => null,
	clearWishList: () => null,
	deleteWishListItemBySlug: () => null,
	getWishListItemBySlug: () => null,
	isFetchingFromFirestore: null,
	isStoringNewWishListItem: false,
	wishListFetchedFromFirestore: false,
	latestItemAdded: null,
	resetNotifications: () => null
})

export default WishListContext

import React, {Dispatch, SetStateAction} from "react"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type props = {
    setActiveTab: Dispatch<SetStateAction<string>>
    activeTab:string
}
function ActiveSearchTab({setActiveTab, activeTab}:props) {
	const {t} = useTranslation()
	return (<>
		<div className="d-none d-md-flex align-items-start justify-content-start">
			<Typography onClick={() => setActiveTab(() => "searchTab")}
				variant={"bodyLg"}
				style={{padding: "8px 9px", fontSize: "14px", lineHeight: "20px", fontWeight: activeTab === "searchTab" ? "700" : "", borderBottom: activeTab === "searchTab" ? "2px solid #FEC04D" : "2px solid #DEE2E6"}}
				semanticTag={"div"}
				className={`mb-4 cursor-pointer ${activeTab === "searchTab" ? "text-black" : "text-secondary"}`}>
				{t("bikes-title", {ns: "search"})}
			</Typography>
			<Typography onClick={() => setActiveTab(() => "accessoriesTab")}
				variant={"bodyLg"}
				style={{padding: "8px 9px", fontSize: "14px", lineHeight: "20px", fontWeight: activeTab === "accessoriesTab" ? "700" : "", borderBottom: activeTab === "accessoriesTab" ? "2px solid #FEC04D" : "2px solid #DEE2E6"}}
				semanticTag={"div"}
				className={`mb-4 cypress-accessories-tab-results cursor-pointer ${activeTab === "accessoriesTab" ? "text-black" : "text-secondary"}`}>
				{t("Accessories", {ns: "search"})}
			</Typography>
		</div>
		{/* Mobile tabs below */}
		<div className="d-flex d-md-none col-12 align-items-start justify-content-center">
			<Typography onClick={() => setActiveTab(() => "searchTab")}
				variant={"bodyLg"}
				style={{padding: "8px 9px", fontSize: "14px", lineHeight: "20px", fontWeight: activeTab === "searchTab" ? "700" : "", borderBottom: activeTab === "searchTab" ? "2px solid #FEC04D" : "2px solid #DEE2E6"}}
				semanticTag={"div"}
				className={`mb-4 col-6 text-center cursor-pointer ${activeTab === "searchTab" ? "text-black" : "text-secondary"}`}>
				{t("bikes-title", {ns: "search"})}
			</Typography>
			<Typography onClick={() => setActiveTab(() => "accessoriesTab")}
				variant={"bodyLg"}
				style={{padding: "8px 9px", fontSize: "14px", lineHeight: "20px", fontWeight: activeTab === "accessoriesTab" ? "700" : "", borderBottom: activeTab === "accessoriesTab" ? "2px solid #FEC04D" : "2px solid #DEE2E6"}}
				semanticTag={"div"}
				className={`mb-4 col-6 text-center cursor-pointer ${activeTab === "accessoriesTab" ? "text-black" : "text-secondary"}`}>
				{t("Accessories", {ns: "search"})}
			</Typography>
		</div>
	</>
	)
}

export default ActiveSearchTab

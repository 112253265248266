import Image from "next/image"
import React from "react"
import {BikeType} from "../../../utility/Types"
import useHandleBookmark from "../../../hooks/useHandleBookmark"

type Props = {
bike:BikeType
}
function BookmarkBtn({bike}: Props) {
	const {handleAddBookmark, handleDeleteBookmark, isBookmarked} = useHandleBookmark(bike)
	return (
		<div style={{height: 32, width: 32}} >
			{isBookmarked ? <Image className="cursor-pointer"
				onClick={e => handleDeleteBookmark(e)}
				height={24}
				width={24}
				src="/assets/icons/Heart-gold.svg"/> : <Image className="cursor-pointer"
				onClick={e => handleAddBookmark(e)}
				height={24}
				width={24}
				src="/assets/icons/Heart.svg"/>}
		</div>
	)
}

export default BookmarkBtn

import React, {useEffect} from "react"
import {AppProps} from "next/app"
import "../styles/main.scss"
import CartContextProvider from "../provider/CartContextProvider"
import ABContextProvider from "../provider/ABContextProvider"
import CheckoutContextProvider from "../provider/CheckoutContextProvider"
import RouteTransitionContextProvider from "../provider/RouteTransitionContextProvider"
import ProgressBar from "../components/routeTransition/ProgressBar"
import LayoutContextProvider from "../provider/LayoutContextProvider"
import CookieConsent from "../components/consent/CookieConsent"
import ModalContextProvider from "../provider/ModalContextProvider"
import Chat from "../components/HubSpot/chat"
import SearchContextProvider from "../provider/SearchContextProvider"
import DataContextProvider from "../provider/DataContextProvider"
import ToastMessageContestProvider from "../provider/ToastMessageContextProvider"
import AnalyticsContextProvider from "../provider/AnalyticsContextProvider"
import FirebaseContextProvider from "../provider/FirebaseContextProvider"
import {appWithTranslation} from "next-i18next"
import nextI18NextConfig from "../next-i18next.config.js"
import {useRouter} from "next/router"
import {fbEvents} from "../config/facebook/events"
import OrganizationContextProvider from "../provider/OrganizationContextProvider"
import WishListContextProvider from "../provider/WishListContextProvider"
import Auth from "../components/staging/Auth"
import ErrorBoundary from "../components/errorBoundary/ErrorBoundary"
import NavigationTabs from "../components/navigation/mobile/NavigationTabs"
import {setCookie} from "cookies-next"
import {variants} from "../middleware"
import NotificationContextProvider from "../provider/NotificationContextProvider"

declare global {
	interface Window {
		fbq: any
		YT:any
		_AutofillCallbackHandler?: any
		clarity:any
	}
}
const App = ({Component, pageProps}: AppProps) => {
	const {query, asPath, pathname} = useRouter()

	useEffect(() => {
		if (query.variant === variants.forceNew) {
			setCookie("mbp_app_nav_variant", variants.new)
		} else if (query.variant === variants.forceOld) {
			setCookie("mbp_app_nav_variant", variants.old)
		}
	}, [query])

	useEffect(() => {
		// Track data for FB page view
		if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
			try {
				// @ts-ignore
				if (typeof window !== "undefined" && window?.fbq) {
					window.fbq("track", fbEvents.pageView)
				}
			} catch (e) {
				console.error(e)
			}
		}
	}, [])
	useEffect(() => {
		if (typeof window !== "undefined") {
			window._AutofillCallbackHandler = window?._AutofillCallbackHandler || function () {
				return null
			}

			localStorage.removeItem("recentlyViewedBikes")
		}
	}, [])
	// Store utm params in local storage
	const router = useRouter()
	const isBrandWorld = router.asPath.includes("/brand-world")

	useEffect(() => {
		const isUtm = router.asPath.includes("utm")
		if (isUtm) {
			window.localStorage.setItem("checkoutUtm", router.asPath)
		}
	}, [router.asPath])
	useEffect(() => {
		const handleRouteChange = () => {
			sessionStorage?.setItem("referrer", `${router.asPath}`)
		}

		router.events.on("routeChangeStart", handleRouteChange)

		return () => {
			router.events.off("routeChangeStart", handleRouteChange)
		}
	}, [router])
	return (
		<ErrorBoundary>
			<ABContextProvider>
				<OrganizationContextProvider>
					<AnalyticsContextProvider>
						<FirebaseContextProvider pageProps={pageProps}>
							<LayoutContextProvider>
								<ToastMessageContestProvider>
									<RouteTransitionContextProvider>
										<CartContextProvider>
											<CheckoutContextProvider>
												<WishListContextProvider>
													<SearchContextProvider>
														<DataContextProvider pageProps={pageProps}>
															<ModalContextProvider>
																<CookieConsent/>
																<Chat/>
																<ProgressBar />
																<NotificationContextProvider>
																	<Component {...pageProps} />
																</NotificationContextProvider>
																{isBrandWorld ? null : <NavigationTabs />}
															</ModalContextProvider>
														</DataContextProvider>
													</SearchContextProvider>
												</WishListContextProvider>
											</CheckoutContextProvider>
										</CartContextProvider>
									</RouteTransitionContextProvider>
								</ToastMessageContestProvider>
							</LayoutContextProvider>
						</FirebaseContextProvider>
					</AnalyticsContextProvider>
					{(process.env.NEXT_PUBLIC_ENVIRONMENT === "development" || process.env.NEXT_PUBLIC_ENVIRONMENT === "preview" || process.env.NEXT_PUBLIC_ENVIRONMENT === "local") && <Auth/>}
				</OrganizationContextProvider>
			</ABContextProvider>
		</ErrorBoundary>
	)
}

export default appWithTranslation(App, nextI18NextConfig)

import {useRouter} from "next/router"
import React, {Dispatch, SetStateAction, useContext} from "react"
import {useTranslation} from "next-i18next"
import {BikeType} from "../../../utility/Types"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {storeDataToSpreadsheet} from "../../../utility/Helper"
import Typography from "../../reusable/Typography/Typography"

type Props = {
	bike: BikeType,
     setLoading:Dispatch<SetStateAction<boolean>>,
     setIsModalOpen:Dispatch<SetStateAction<boolean>>,
     userLifestyle: string,
     openDrawer: () => void,
     closeModal: () => void,
     setModalContent:Dispatch<SetStateAction<React.ReactNode>>,
     isSelected:boolean,
     isMobile?:boolean,
    setResult: Dispatch<SetStateAction<any>>
}

function PlpAiAssistant({bike, isMobile, setLoading, isSelected, setIsModalOpen, userLifestyle, setResult}:Props) {
	const {t} = useTranslation()
	const router = useRouter()
	const {reactGA} = useContext(AnalyticsContext)
	const getBikeAiCompatibility = async e => {
		e.preventDefault()
		e.stopPropagation()
		setLoading(() => true)
		setIsModalOpen(true)
		const updatedMessages = `${userLifestyle}`

		try {
			const res = await fetch(`/api/ai/chat/${bike.slug}?isPLP=true&locale=${router.locale || "de"}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					description: updatedMessages
				})
			})

			const data = await res.json()

			setResult(() => data.response)

			reactGA?.event({
				category: gaCategories.megaMenu,
				action: gaEvents.AiBikeEvaluation,
				label: `${gaEvents.AiBikeEvaluation} ${bike.name}: ${data?.response || ""}`,
				nonInteraction: false
			})

			await storeDataToSpreadsheet({
				question: `BIKE:${bike.name} User lifestyle: ${userLifestyle}`,
				answer: data.response?.[1] || ""
			}, "plpAi")
		} catch (error) {
			console.error("Error communicating with the API:", error)
		}

		setLoading(false)
	}

	if (isSelected || isMobile) {
		return (
			<>{userLifestyle?.length && router.pathname !== "/" ? <div onClick={getBikeAiCompatibility}
				style={{bottom: "2%", left: isMobile ? "" : "50%", zIndex: "1000", transform: isMobile ? "translate(-3%)" : "translate(-50%)"}}
				className={` ai-explanation 
					${isMobile ? "" : "position-absolute"} 
					justify-content-center align-items-center gap-2`}>
				<button
					style={{
						fontFeatureSettings: "'pnum' on, 'lnum' on",
						outline: "none", border: "none", borderRadius: "8px"
					}}
					onClick={getBikeAiCompatibility}
					className="d-flex justify-content-center btn plp py-2 px-2 try-ai position-relative align-items-center">
					<img src={"/assets/icons/ryder-logo.svg"}
						width={14}
						height={14} />
					<Typography
						variant="bodySmBold"
						style={{whiteSpace: "nowrap", fontSize: "12.5px", color: "#fff"}}>
						{t("is-this-for-me")}</Typography>
				</button>
			</div> : null}</>
		)
	}

	return <></>
}

export default PlpAiAssistant

import slugify from "slugify"
import NotificationsContext from "../../context/NotificationsContext"
import useOnScreen from "../../hooks/OnScreen"
import BikeDescriptionSection from "./BikeDescriptionSection"
import React, {useContext, useEffect, useRef} from "react"

type Props = {
accordionContent:any
tab:any
t:any
active:any
setActive: any
setActiveLink,
bike:any
isAccessory:any
}

const ProductInfo: React.FC<Props> = ({
	accordionContent,
	setActiveLink,
	tab,
	t,
	active,
	setActive,
	bike,
	isAccessory}) => {
	const ref = useRef(null)
	const refQandA = useRef(null)
	const isOnScreen = useOnScreen(ref)
	const isQandAOnScreen = useOnScreen(refQandA)
	const {displayNotificationAfterUsing} = useContext(NotificationsContext)

	useEffect(() => {
		if (!active?.length) {
			const allSlugs = accordionContent.map(({slug, title}: any) => {
				return slugify(slug || title || "").toLowerCase()
			}
			)
			setActive(allSlugs)
		}
	}, [accordionContent, active, setActive])

	useEffect(() => {
		displayNotificationAfterUsing(60, "whatsapp-notification", isOnScreen)
		if (isOnScreen || isQandAOnScreen) {
			setActiveLink(isOnScreen ? "Übersicht" : "Q&A")
		}
	}, [isOnScreen, isQandAOnScreen])

	return (
		<div

			className="border-top">
			<div style={{marginTop: "-16rem", position: "absolute"}}
				ref={ref}
				id="overview" />
			<BikeDescriptionSection bike={bike}
				multiple
				setActiveLink={setActiveLink}
				noDividerBeforeBody
				variant={"B"}
				accordionContent={accordionContent}
				slug={bike.slug}
				tab={tab}
				t={t}
				active={active}
				setActive={setActive}
				isAccessory={isAccessory} />
		</div>

	)
}

export default ProductInfo

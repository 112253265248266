import React, {useContext} from "react"
import {ModalContext} from "../context/ModalContext"
import BannerWithForm from "../components/BannerWithForm/BannerWithForm"
import {Clock, GeoAlt} from "react-bootstrap-icons"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import LayoutContext from "../context/LayoutContext"
import BannerWithCalendar from "../components/bikesCollection/BannerWithCalendar"

function useBookShowroom() {
	const {openModal, setModalContent} = useContext(ModalContext)
	const {t} = useTranslation()
	const openShowroomBookingModal = () => {
		setModalContent(() => <div className="showroom-booking-bg px-1 py-4 p-md-0"
			style={{maxHeight: "90dvh", overflowY: "auto", borderRadius: "16px"}}><BannerWithCalendar bannerTitle={t("visit-showroom-title")}
				listTitle={t("mbp-showroom-label")}
				stepsWithIconsContent={[{
					header: <><GeoAlt /></>, description: "Zweierstrasse 100, 8003 Zurich"
				},
				{header: <><Clock /></>, description: t("opening-hours-label")},
				{header: <Image src={"/assets/icons/coffee.svg"}
					width={16}
					height={16}
					layout="fixed" />, description: t("coffee-on-the-house")
				}]}
				bgImageSrc={"/assets/images/showroom-v2.jpg"}
			/></div>)
		openModal()
	}

	return openShowroomBookingModal
}

export default useBookShowroom

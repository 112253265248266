import React from "react"
import styles from "./Typography.module.scss"

export enum Variants {
  heading1, heading1Bold, heading1Light, heading1Black, heading2, heading2Black, heading3, heading3Sm, heading3SmBlack, heading3Black, heading4Black, heading6, bodyLg, bodyLgBold, bodyLgExtraBold, bodyLgBlack, bodySm, bodySmBold, bodySmBlack, bodyXSm, bodyXSmBold, bodyXSmExtraBold, heading1MdHeading2, heading2MdLgBold, heading2MdHeading1, heading2MdHeading3, bodySmMdBodyLg, bodySmBoldMdBodyLgBold, heading3MdBodyLgBold, heading3MdBodyLgBlack, bodyXSmMdBodyLgBold, bodyXSmExtraBoldMdHeading3, bodyXSmBoldMdBodySmBold, heading28To36, heading20To28
}

export enum SemanticTags {
  h1 = "h1", h2 = "h2", h3 = "h3", h4 = "h4", h5 = "h5", h6 = "h6", p = "p", div = "div", span = "span", li = "li"
}

type Props = {
  variant: keyof typeof Variants
  semanticTag?: keyof typeof SemanticTags
  className?: string
  style?: {}
  children?: React.ReactNode
  onClick?: () => void
  id?: string,
  ref?: any,
  htmlContent?: string
}

const Typography = ({
	variant,
	semanticTag,
	className,
	style,
	children,
	onClick,
	id,
	ref,
	htmlContent
}: Props): React.ReactElement => {
	const classes = `${styles[variant]} ${className ? className : ""}`
	const Tag = semanticTag ? SemanticTags[semanticTag] : "div"

	return htmlContent ? <Tag ref={ref && ref}
		id={id ? id : ""}
		style={style ? style : null}
		onClick={onClick}
		className={classes}
		dangerouslySetInnerHTML={{__html: htmlContent}}/> :
		<Tag suppressHydrationWarning
			ref={ref && ref}
			id={id ? id : ""}
			style={style ? style : null}
			onClick={onClick}
			className={classes}>
			{children}
		</Tag>
}

export default Typography

import React from "react"
import {useTranslation} from "next-i18next"
import PropTypes from "prop-types"
import {StockStatus} from "./BikeDetails"
import Typography from "../reusable/Typography/Typography"
import {Slides} from "../reusable/components/Slides"
import CompatibleAccessory from "./CompatibleAccessory"
import Button from "../reusable/components/Button"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"

type Props = {
  sellProducts: any[],
  bikeName?: string,
  bike?: any,
}

const CrossSellProducts: React.FC<Props> = ({sellProducts, bike, bikeName}) => {
	const {t} = useTranslation()

	const crossSellProducts = sellProducts?.filter(bike => bike.stock_status === StockStatus.inStock ||
        bike.stock_status === StockStatus.onBackOrder)
	return (
		<>
			{!crossSellProducts || crossSellProducts?.length < 1 ? null :
				<div>
					<div className="d-md-none mt-5">
						<div className="container">
							<Typography className="mb-3"
								variant="bodyLgBlack"
								style={{fontSize: 28}}>{t("Zubehör")}</Typography>
							<Typography className="mb-4 pb-2"
								variant="bodyLg">
								{`${t("They will work great with")} ${bikeName}`}
							</Typography>
							{
								crossSellProducts?.length > 1 ?
									<Slides centerMode
										centerModePadding={{left: 0, bottom: 0, right: 25}}>
										{crossSellProducts.map((product, idx) => {
											if (idx <= 2) {
												return (
													<div key={product.id}
														className="px-2">
														<CompatibleAccessory key={product.id}
															product={product}/>
													</div>
												)
											}

											return null
										})}
									</Slides> :
									crossSellProducts.map((product, idx) => {
										if (idx <= 2) {
											return (
												<CompatibleAccessory key={product.id}
													product={product}/>
											)
										}

										return null
									})
							}

						</div>
					</div>

					<div className={"d-none d-md-block container mb-4 mt-5"}
						style={{minHeight: 300}}>
						<div className={"row bikes-collection-not-absolute"}>
							<div className={"col-12"}>
								<div className={"text-center"}>
									<Typography variant="heading2Black"
										className="mb-4 pb-3"
										semanticTag="h2">
										{t("Zubehör")}
									</Typography>

									{crossSellProducts?.length > 4 ?
										<div className="row compatible-accessories">
											<Slides arrowsPositionX={-3}
												className="px-md-5"
												centerModePadding={{left: 7, bottom: 0, right: 10}}
												centerMode
												dotsPositionY={-80}
												arrows
												dots
												slidesToShow={4}>
												{crossSellProducts.map(product => {
													return (
														<div key={product.id}
															className={"h-100 col-auto compatible-accessories-item"}
															style={{background: "#F8F8F8", borderRadius: 24}}>
															<CompatibleAccessory key={product.id}
																product={product}/>
														</div>
													)
												})}
											</Slides>
										</div>	:
										<div className="row justify-content-center gx-3">
											{crossSellProducts.map(product => {
												return (
													<div key={product.id}
														className={"h-100 col-3 px-0 mx-3 compatible-accessories-item"}
														style={{background: "#F8F8F8", borderRadius: 24}}>
														<CompatibleAccessory key={product.id}
															product={product}/>
													</div>
												)
											})}
										</div>
									}
									<Button
										label={t("All Accessories")}
										href={filterUrl({[urlParams.categories]: t("zubehoer")}, null, null)}
										bgColor="#FEC04D"
										textColor="#212529"
										color="#212529"
										className={"mb-4 mt-5"}
										paddingY="3"
										btnFontSize={14}
										fontWeight={800}
										isNoUppercase
									/>
								</div>
							</div>
						</div>
					</div>
				</div>}

		</>
	)
}

CrossSellProducts.propTypes = {
	sellProducts: PropTypes.array.isRequired
}

export default CrossSellProducts

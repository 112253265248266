/* eslint-disable max-params */
import React, {useContext, useState} from "react"
import CartContext, {CartItem} from "../context/CartContext"
import CheckoutContext from "../context/CheckoutContext"
import {findBikeMetaFieldByName, isOccasionBike} from "../utility/Helper"
import {cartTypeGuard, logCartItem} from "../utility/typeGuards"
import {generateUUID} from "../utility/UUID"
import {useRouter} from "next/router"
import {categorySlugs} from "../components/filter/config/Categories"
import getMainCategory from "../components/filter/GetMainCategory"
import {StockStatus} from "../components/bikeDetails/BikeDetails"
import {ecommerceEvents, gaCategories, gaEvents} from "../config/googleAnalytics/events"
import AnalyticsContext from "../context/AnalyticsContext"
import {useTranslation} from "next-i18next"

function useCheckoutHandler(
	bike,
	isCartCompatibility,
	finalPriceWithoutDownPayment,
	selectedVariation,
	discount,
	onSale,
	selectedSize,
	setShowUpsellModal
) {
	const {addItem} = useContext(CartContext)
	const {setData} = useContext(CheckoutContext)
	const isSecondHand = isOccasionBike(bike)
	const frameNumber = findBikeMetaFieldByName(bike, "frame_number")
	const [showToast, setShowToast] = useState<boolean>(null)
	const [currentCompatibilityBike, setCurrentCompatibilityBike] = useState({})
	const router = useRouter()
	const {query} = router
	const isAiRecommendation = query["ai-suggested"]
	const mainCategory = getMainCategory(bike?.categories)
	const isAccessory = mainCategory?.name.toLowerCase() === categorySlugs.accessories
	const {reactGA} = useContext(AnalyticsContext)
	const secondHandPrice = bike.displayPrice
	const {t} = useTranslation()
	const getCartItem = () => {
		const resultBike = isSecondHand ? {...bike, price: finalPriceWithoutDownPayment, frameNumber} : {
			...bike,
			finalPriceWithoutDownPayment
		}
		const product = {
			...resultBike,
			sku: selectedVariation?.sku,
			logisticsCompany: selectedVariation?.logisticsCompany || "Zibatra",
			bikeQuantity: 1,
			totalQuantity: 1,
			customInstanceDiscount: discount,
			isAiRecommendation,
			discount,
			finalPriceWithoutDownPayment,
			totalPrice: bike.uvpPrice,
			onSale,
			expected_delivery_date__website_: selectedVariation.erpStockInfo?.deliveryTime || null
		}
		const cartItem = {
			product,
			onSale,
			selectedSize,
			selectedVariationId: parseInt(selectedVariation?.id, 10),
			uniqueId: generateUUID(),
			initialPrice: bike.uvpPrice
		} as unknown as CartItem
		return cartItem
	}

	const handleCheckoutBtnClick = async (isDirectPayment:boolean = false) => {
		if (isCartCompatibility) {
			const cartItem = getCartItem()
			const correctCartItem = cartTypeGuard([cartItem])
			if (correctCartItem?.length !== 1) {
				logCartItem(cartItem)
				setShowToast(true)
				router.push("/alle-modelle-e-bike-schweiz")
				return
			}

			addItem(cartItem)

			setData(prevData => ({
				...prevData,
				accessoriesFilled: isAccessory
			}))

			if (isAiRecommendation) {
				reactGA?.event({
					category: gaCategories.pdp,
					action: gaEvents.aiAddToCart,
					label: ` ${gaEvents.aiAddToCart} ${bike.name}`,
					nonInteraction: true
				})
			} else {
				reactGA?.event({
					category: gaCategories.pdp,
					action: gaEvents.addToCart,
					label: `${gaEvents.addToCart} ${bike.name}`,
					nonInteraction: true
				})
			}

			reactGA?.gtag(ecommerceEvents.event, gaEvents.addToCart, {
				currency: ecommerceEvents.CHF,
				value: Number(bike?.uvpPrice),
				items: [
					{
						item_id: selectedVariation?.sku,
						item_name: bike?.name
					}
				]
			})

			// Upsell modal  show or no
			if (isDirectPayment === true) {
				router.push("/checkout-pay-one-time")
			} else if (!isAccessory && bike.crossSellProducts && bike.crossSellProducts.filter(product => product.stock_status === StockStatus.inStock || product.stock_status === StockStatus.onBackOrder).length) {
				setShowUpsellModal(true)
			} else {
				router.push(t("/checkout")).then()
			}
		} else {
			const resultBike = isSecondHand ? {...bike, price: secondHandPrice, frameNumber} : {...bike}
			const compatibilityBike = {
				product: {
					...resultBike,
					sku: selectedVariation?.sku,
					expected_delivery_date__website_: findBikeMetaFieldByName(selectedVariation, "delivery_estimation")
				},
				isOnSale: onSale,
				initialPrice: bike.uvpPrice,
				selectedSize,
				selectedVariationId: parseInt(selectedVariation?.id, 10),
				uniqueId: generateUUID()
			} as CartItem
			setCurrentCompatibilityBike(compatibilityBike)
			setShowUpsellModal(true)
		}
	}

	return {showToast, setShowToast, getCartItem, handleCheckoutBtnClick, currentCompatibilityBike}
}

export default useCheckoutHandler

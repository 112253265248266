import React, {useState} from "react"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import {formContactHandler} from "../../utility/postHandler"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import SuccessMessage from "../reusable/SuccessMessage"

interface MyFormValues {
    firstname: string,
    lastname: string,
    email: string,
    questions: string
}

const schema = Yup.object().shape({
	firstname: Yup.string()
		.required("Required"),
	lastname: Yup.string()
		.required("Required"),
	email: Yup.string()
		.email("Invalid email")
		.required("Required"),
	questions: Yup.string()
		.required("Required")
})

const ContactFormOnProductList: React.FC = () => {
	const {t} = useTranslation()
	const router = useRouter()
	const {locale} = router
	const currentUrl = router.asPath
	const [formSubmitted, setFormSubmitted] = useState(false)
	const initialValues: MyFormValues = {
		firstname: "",
		lastname: "",
		email: "",
		questions: ""
	}
	return (
		<>
			{formSubmitted ? <SuccessMessage text={t("Abgeschickt")}/> : (
				<Formik
					initialValues={initialValues}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						const obj = {
							formId: process.env.NEXT_PUBLIC_FORM_ID,
							instanceId: locale === "de" ? process.env.NEXT_PUBLIC_FORM_CONTACT_IN_PRODUCTLIST_DE :
								locale === "fr" ? process.env.NEXT_PUBLIC_FORM_CONTACT_IN_PRODUCTLIST_FR : process.env.NEXT_PUBLIC_FORM_CONTACT_IN_PRODUCTLIST_DE,
							data: values,
							context: {
								// hutk: ":hutk", // Include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
								pageUri: currentUrl
								// pageName: "Example page"
							}
						}
						const response = await formContactHandler(obj)

						if (response.status === 200) {
							setFormSubmitted(true)
						}

						actions.setSubmitting(false)
					}}
				>
					{({errors, touched}) => (
						<Form className="col-12 mt-5 mb-5">
							<div className="row">
								<div className="col-12 col-lg-6">
									<div className="border-bold rounded mb-3 pt-3 pb-3 mx-auto">
										<label className="label-absolute col-12 bg-white text-dark  text-center"
											htmlFor="firstname">{t("Vorname*")}</label>
										<Field className="col-12 transparent-input text-dark"
											id="firstname"
											name="firstname"/>
										{errors.firstname && touched.firstname ? (
											<div style={{color: "red"}}>{errors.firstname}</div>
										) : null}
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="border-bold rounded mb-3 pt-3 pb-3">
										<label className="label-absolute col-12 bg-white text-dark text-center"
											htmlFor="lastname">{t("Nachname*")}</label>
										<Field className="col-12 transparent-input text-dark"
											id="lastname"
											name="lastname"/>
										{errors.lastname && touched.lastname ? (
											<div style={{color: "red"}}>{errors.lastname}</div>
										) : null}
									</div>
								</div>
							</div>
							<div className="border-bold rounded mb-3 pt-3 pb-3">
								<label className="label-absolute col-12 bg-white text-dark  text-center"
									htmlFor="email">{t("E-Mail*")}</label>
								<Field className="col-12 transparent-input text-dark"
									id="email"
									name="email"/>
								{errors.email && touched.email ? (
									<div style={{color: "red"}}>{errors.email}</div>
								) : null}
							</div>
							<div className="border-bold rounded mb-3 pt-3 pb-3">
								<label className="label-absolute col-12 bg-white text-dark  text-center"
									htmlFor="questions">{t("Nachricht*")}</label>
								<Field as="textarea"
									className="col-12 transparent-input text-dark"
									id="questions"
									name="questions"/>
								{errors.questions && touched.questions ? (
									<div style={{color: "red"}}>{errors.questions}</div>
								) : null}
							</div>
							<div className={"row mt-5"}>
								<div className={"col-12"}>
									<div className={"d-grid"}>
										<button type="submit"
											className="btn btn-primary btn-lg">{t("Absenden")}</button>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	)
}

export default ContactFormOnProductList

import confetti from "canvas-confetti"
import {logger} from "../../utility/Helper"

export function startConfettiAnimation(effect = "default") {
	if (typeof window === "undefined") {
		return null
	}

	try {
		if (effect === "snowflake" || effect === "hearts") {
			const duration = 12 * 1000 // 12 seconds duration
			const animationEnd = Date.now() + duration

			const randomInRange = (min, max) => (Math.random() * (max - min)) + min
			const iconPath = "M41.0849 28.1614C35.693 32.6172 29.1646 34.8782 23.4548 39C21.536 32.8624 17.4252 29.9433 12.8878 25.8196C6.81516 20.3004 0.935456 5.16679 13.114 2.85113C16.3604 2.2346 19.6179 3.95181 22.139 6.12624C24.503 8.16557 26.491 10.6548 27.9715 13.4277C29.4063 6.65501 35.6892 1.27862 42.4819 1.01038C43.6827 0.963039 44.9173 1.0658 46.0161 1.56726C47.0318 2.03178 47.8814 2.81726 48.5761 3.70512C51.4722 7.4074 51.627 12.815 49.8146 17.1734C47.9984 21.5411 44.7965 25.1364 41.0849 28.1614Z"

			const shape = effect === "snowflake" ?
				confetti.shapeFromText({text: "❆", color: "#fff"}) :
				confetti.shapeFromPath({path: iconPath, color: "#7CB001"})

			const frame = () => {
				const timeLeft = animationEnd - Date.now()
				const ticks = 10000

				if (Math.random() < 0.1) {
					confetti({
						particleCount: 1,
						startVelocity: 0,
						ticks,
						origin: {
							x: Math.random(),
							y: (Math.random() * 0.2) - 0.2
						},
						zIndex: 9999999999,
						colors: effect === "snowflake" ? ["#ffffff"] : ["#7CB001"],
						shapes: [shape],
						gravity: 0.3,
						scalar: randomInRange(2, 4),
						drift: randomInRange(-0.3, 0.3),
						flat: true
					})
				}

				if (timeLeft > 0) {
					requestAnimationFrame(frame)
				}
			}

			frame()
		} else if (effect === "fireworks") {
			const duration = 5 * 1000
			const animationEnd = Date.now() + duration
			const defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 9999999999}

			const randomInRange = (min, max) => (Math.random() * (max - min)) + min

			const interval = setInterval(() => {
				const timeLeft = animationEnd - Date.now()

				if (timeLeft <= 0) {
					return clearInterval(interval)
				}

				const particleCount = 50 * (timeLeft / duration)
				const origin1 = {x: randomInRange(0.1, 0.3), y: Math.random() - 0.2}
				const origin2 = {x: randomInRange(0.7, 0.9), y: Math.random() - 0.2}

				confetti({...defaults, particleCount, origin: origin1})
				confetti({...defaults, particleCount, origin: origin2})
			}, 250)
		} else {
			confetti({
				particleCount: 90,
				startVelocity: 30,
				spread: 360,
				origin: {x: 0.5, y: 0.5},
				gravity: 0.8,
				zIndex: 9999999999,
				ticks: 500,
				decay: 0.95
			})
		}
	} catch (error) {
		logger.error(error)
		confetti({
			particleCount: 90,
			startVelocity: 30,
			spread: 360,
			origin: {x: 0.5, y: 0.5},
			gravity: 0.8,
			zIndex: 9999999999,
			ticks: 500,
			decay: 0.95
		})
	}
}

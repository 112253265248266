import React from "react"
import Typography from "../../reusable/Typography/Typography"
import PropTypes from "prop-types"
import {findBikeAttributeBySlug, findBikeMetaFieldByName} from "../../../utility/Helper"
import {StockStatus} from "../BikeDetails"
import {useTranslation} from "next-i18next"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"
import {useRouter} from "next/router"
import LinkWithIcon from "../../reusable/components/LinkWithIcon"
import CollectionItemBadge from "../../bikesCollection/BikesCollectionItem/CollectionItemBadge"

type Props = {
	variation: any,
	activeId: any,
	handleSizeBtnClick: any,
	mainCategory: any,
	is45KmH: any,
	selectedSize: any,
	selectedVariation: any,
	isOutStockSelected: any,
	isInDropdown: boolean
	isDropdownOpen?: boolean
	isSelectedVariation?: boolean
	isLastVariation?: boolean
	isMobile?: boolean
    setIsModalOpen?: any
    variations: any[]
}

const VariationItem: React.FC<Props> = ({
	variation,
	activeId,
	handleSizeBtnClick,
	mainCategory,
	is45KmH,
	selectedSize,
	selectedVariation,
	isOutStockSelected,
	isInDropdown,
	isDropdownOpen,
	isSelectedVariation,
	isLastVariation,
	isMobile,
	setIsModalOpen = () => {},
	variations
}) => {
	const sizeAttr = findBikeAttributeBySlug(variation, "sizes", false, true)

	const sizeAttribute = sizeAttr !== "No Data" ? sizeAttr :
		findBikeAttributeBySlug(variation, "seat-tube-length", false, true)
	const [size, sizeLabel] = sizeAttribute.split(": ")
	const inStock = variation?.stock_status === StockStatus.inStock
	const outOfStock = variation?.stock_status === StockStatus.outOfStock
	const expressPickup = variation.erpStockInfo?.expressPickUp
	const variationQuantity = variation.erpStockInfo?.physicalStock
	const {t} = useTranslation()
	const router = useRouter()
	const {locale} = router
	const timePeriod = variation?.erpStockInfo?.deliveryTime
	const bikerHeight = findBikeMetaFieldByName(variation, "biker_height")
	const selectdSizeInDropdown = (isSelectedVariation && isInDropdown)
	const quantityLeftLabel = variationQuantity ? variationQuantity === 1 ? t("last-piece") :
		variationQuantity < 4 ? t("only-x-bikes-left", {value: variationQuantity}) : null : null

	const bikerHeightValues = (bikerHeight && bikerHeight.length) ?
		bikerHeight?.split("-").map(item => item.split("")
			.filter(item => !isNaN(Math.floor(item)))).map(item => item.join("").trim())
			.map(item => {
				const arrOfNumbers = item.split("")
				const lastElement = arrOfNumbers[arrOfNumbers.length - 1]
				if (lastElement !== "0" && lastElement !== "5") {
					arrOfNumbers.splice(arrOfNumbers.length - 1, 1, "5")
				}

				return arrOfNumbers.join("")
			}) : []

	const bikerHeightForFilterUrl = bikerHeightValues?.reduce((acc, heightValues) => {
		const heightValuesToNumber = Math.floor(heightValues)
		if (heightValuesToNumber < 190 && heightValuesToNumber >= 155) {
			acc.push(`${heightValuesToNumber}-${heightValuesToNumber + 5}-cm`)
		} else if (heightValuesToNumber >= 190) {
			acc.push("190-195-cm")
			return acc
		} else if (heightValuesToNumber < 155) {
			acc.push("155-160-cm")
		}

		return acc
	}, [])

	const url = filterUrl({[urlParams.bikerHeight]: (bikerHeightForFilterUrl.length ?
		[...bikerHeightForFilterUrl] : ["/-"]), [urlParams.categories]:
        (mainCategory?.slug ? mainCategory?.slug : is45KmH ? ["45-km"] : ["/-"])}, null, null)

	let date = null
	let period = null

	if (timePeriod) {
		const match = timePeriod.match(/(\d+)-?(\d*)\s?(\w+)/)
		if (match) {
			date = match[1]
			if (match[2]) {
				date = `${date}-${match[2]}`
			}

			period = match[3].replace(/\s/g, "")
		}
	}

	const getMonth = dateString => {
		const date = new Date(dateString)

		// Get next month
		const month = date.getDate() > 15 ? date.getMonth() === 11 ? 0 : date.getMonth() + 1 : date.getMonth()
		date.setMonth(month)

		return date.toLocaleString(locale, {month: "long"})
	}

	const getEstimatedDeliveryRange = (timePeriod: string) => {
		const today = new Date()
		const match = timePeriod.match(/(\d+)-?(\d*)\s?(\w+)/)

		if (!match) {
			return null
		}

		let startDays = parseInt(match[1], 10)
		let endDays = match[2] ? parseInt(match[2], 10) : startDays
		const unit = match[3].toLowerCase()

		switch (unit) {
			case "weeks":
				startDays *= 5
				endDays *= 5
				break
			case "months":
				startDays *= 30
				endDays *= 30
				break
			default:
				startDays *= 1
				endDays *= 1
		}

		const startDate = new Date(today)
		startDate.setDate(today.getDate() + startDays)
		const endDate = new Date(today)
		endDate.setDate(today.getDate() + endDays)

		const startDateString = `${startDate.toLocaleString(locale, {weekday: "short", day: "numeric"})} ${startDate.toLocaleString(locale, {month: "long"})}`
		const endDateString = endDays > startDays ? `${endDate.toLocaleString(locale, {weekday: "short", day: "numeric"})} ${endDate.toLocaleString(locale, {month: "long"})}` : ""

		// Removes the comma after the weekday abbreviation and the period after the day
		const formattedStartDate = startDateString.replace(/.,/, ".")
		const formattedEndDate = endDateString.replace(/.,/, ".")

		return {startDateString: formattedStartDate, endDateString: formattedEndDate}
	}

	const estimatedDelivery = timePeriod ? getEstimatedDeliveryRange(timePeriod) : {startDateString: "", endDateString: ""}

	const bikerHeightNormalized = (() => {
		if (!bikerHeight) {
			return null
		}

		// Removes spaces and "cm"
		const cleanedHeight = bikerHeight.replace(/\s+/g, "").replace(/cm/g, "")

		return cleanedHeight
	})()

	const calculateDeliveryDays = date => {
		let deliveryDays = null

		if (date?.includes("-")) {
			const [startDate, endDate] = date.split("-").map(Number)
			deliveryDays = `${startDate * 5} - ${endDate * 5}`
		} else {
			deliveryDays = date * 5
		}

		return deliveryDays
	}

	return (
		<React.Fragment>
			<div className={`col-12 cypress-e2e-size-button  position-relative variation-item rounded-2 cursor-pointer 
            ${isInDropdown && !outOfStock ? "selected-size-variation" : ""}
			p-2 pt-2 ${isSelectedVariation && !isInDropdown ? "px-3 pt-12 pb-12 border" :
			selectdSizeInDropdown ? "border-active" : "border"}
            ${isInDropdown && "mb-12px"}
            `}
			onClick={() => {
				if (!outOfStock) {
					handleSizeBtnClick(sizeAttribute, variation)
					setIsModalOpen(false)
				}
			}}
			style={{cursor: outOfStock ? "not-allowed" : "pointer"}}>
				<div>
					{expressPickup && <Typography variant="bodySmBold"
						semanticTag="div"
						className="text-success">Express Pickup</Typography>}
					<div className={`d-flex justify-content-between col-12
                    ${quantityLeftLabel ? "align-items-start" : "align-items-start"}`}>
						<div className="col-12">
							<div className="d-flex justify-content-between align-items-center">
								<div className="d-flex align-items-center pb-1"
									style={{
										opacity: outOfStock ? 0.4 : 1}}
								>
									<Typography variant="bodySm"
										semanticTag="span"
										className={sizeLabel ? "me-1" : ""}>
										{sizeLabel ? `${t(sizeLabel)}` : ""}
									</Typography>
									<Typography variant="bodySm"
										semanticTag="span"
										className="me-1">{size}</Typography>
									{bikerHeight ?
										<>
											<Typography variant="bodySm"
												semanticTag="span"
												className="me-1">
									/
											</Typography>
											{bikerHeightNormalized ? (
												<>
													<Typography variant="bodySmBold"
														semanticTag="span">
														{bikerHeightNormalized}
													</Typography>
													<Typography variant="bodySm"
														semanticTag="span"
														className="ms-1">
                                                cm
													</Typography>
												</>
											) : null}
										</> : null}
								</div>
								{isSelectedVariation && !isInDropdown && variations.length > 1 && <Typography variant="bodySmBold"
									semanticTag="span"
									className="new-mbp-link d-inline-block">{t("change")}</Typography>
								}
							</div>
							{!outOfStock &&
							<div className="col-12 d-flex aling-items-start mt-2"
								style={{lineHeight: 16}}>
								{variation?.stock_status === StockStatus.inStock ?
									<>
										<Typography variant="bodyXSm"
											semanticTag="span"
											style={{color: date === "4-10" ? "#077940" :
												"#6C7074", lineHeight: "16px"}}>
											{variation?.erpStockInfo?.deliveryTime ? (
												<span>{t("delivery")}: {" "}
													{period === "Days" ?
														<>
															{date} {t(period)}
														</> :
														<>
															{date?.includes("-") ?
																calculateDeliveryDays(date) : date * 5}
															{t("days")}
														</>
													}
												</span>
											) : (
												<span>{t("10-days-delivery")}</span>
											)}
										</Typography>
									</> : variation?.stock_status === StockStatus.onBackOrder ?
										<>
											{variation?.erpStockInfo?.isDropshipping ?
												<Typography variant="bodyXSm"
													semanticTag="span"
													style={{color: "#6C7074"}}>
													{t("delivery")}: {" "}
													{period === "Days" ?
														<>
															{date} {t(period)}
														</> :
														<>
															{date?.includes("-") ?
																calculateDeliveryDays(date) : date * 5}
															{" "}
															{t("days")}
														</>
													}
												</Typography> : variation?.erpStockInfo?.deliveryUntil ?
													<Typography variant="bodySmMdBodyLg"
														semanticTag="span">
														{t(getMonth(variation?.erpStockInfo?.deliveryUntil))}
													</Typography> :
													<Typography variant="bodyXSm"
														semanticTag="span"
														style={{color: date === "4-10" ? "#077940" :
															"#6C7074", lineHeight: "16px"}}>
														{variation?.erpStockInfo?.deliveryTime ? (
															<span>{t("delivery")}: {" "}
																{period === "Days" ?
																	<>
																		{date} {t(period)}
																	</> :
																	<>
																		{date?.includes("-") ?
																			calculateDeliveryDays(date) : date * 5}
																		{t("days")}
																	</>
																}
															</span>
														) : (
															<span>{t("10-days-delivery")}</span>
														)}
													</Typography>
											}
										</> : null}
							</div>}
							<div className={`d-flex gap-1 ${outOfStock ? "py-1" : "mt-2 pt-1"}`}>
								{variationQuantity &&
                                    <CollectionItemBadge bikeQuantityLeftLabel={quantityLeftLabel}/>
								}
								<CollectionItemBadge
									inStock={inStock}
									outOfStock={outOfStock}
									onBackOrder={!(inStock || outOfStock)}
								/>
							</div>
						</div>
					</div>

					<div>
						{/* Temporarily Removed */}
						{/* { !outOfStock &&
							<div className="d-flex align-items-center position-relative">
								<Typography variant="bodyXSm" semanticTag="span" className="me-2" style={{lineHeight: "16px"}}>{t("shipped-by")} SEMG</Typography>
								<Image className="cursor-pointer" src={"/assets/icons/info-transparent.svg"}
									alt="tools icon" width={20} height={20}
									onMouseEnter={() => setIsToolTipOpen(true)}
									onMouseLeave={() => setIsToolTipOpen(false)}
									onTouchStart={() => setIsToolTipOpen(true)}
									onTouchEnd={() => setIsToolTipOpen(false)}
								/>
								<div className={`border size-tooltip ${isToolTipOpen ? "active" : ""}`}>
									<Typography variant="bodySm" semanticTag="span">
										{estimatedDelivery.endDateString && `${t("delivery-between")} ${estimatedDelivery.startDateString} ${t("and")} ${estimatedDelivery.endDateString}`}
										{!estimatedDelivery.endDateString && `${t("delivery-on")} ${estimatedDelivery.startDateString}`}
									</Typography></div>
							</div>
						} */}
					</div>
				</div>

				{outOfStock &&
				<div className="col-12 px-0 pb-1">
					<Typography variant="bodyXSm"
						className="my-1">{t("currently-unavailable")}</Typography>
					<LinkWithIcon
						label={t("similar-same-size-bike")}
						icon={<img src="/assets/icons/chevron-right-bg-grey.svg"/>}
						href={url}
						labelVariant={"bodyXSmBold"}
						padding={"4px 0px"}
						iconMargin={"ms-2"}
					/>
				</div>
				}
			</div>
		</React.Fragment>
	)
}

export default VariationItem

import React, {useContext} from "react"
import CartContext from "../../context/CartContext"
import LayoutContext from "../../context/LayoutContext"

type Props = {
	isTab?:boolean
}
const CartIndicator: React.FC<Props> = ({isTab = false}) => {
	const {items} = useContext(CartContext)
	const {isMobile} = useContext(LayoutContext)
	return (
		<>
			{items ? <span className={`d-block d-flex align-items-center justify-content-center position-absolute ${items.length > 0 ? "bg-primary" : "bg-black"}`}
				style={{
					borderRadius: "100%",
					width: 16,
					height: 16,
					padding: "0.5px 4px",
					lineHeight: "16px",
					top: isMobile ? 0 : -5,
					right: isTab ? 15 : isMobile ? 0 : -5,
					fontSize: "12px",
					fontWeight: 700,
					color: items?.length > 0 ? "#212529" : "#fff"
				}}>
				{items.length}
			</span> : null}
		</>

	)
}

export default CartIndicator

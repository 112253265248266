import Image from "next/image"
import React, {useContext} from "react"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

type Props = {
  title?: string
  className?: string
}

const MobileShare = ({title, className}: Props): React.ReactElement => {
	const classes = `pointer ${className ? className : ""}`
	const {reactGA} = useContext(AnalyticsContext)

	const handleOnClick = () => {
		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.pdpShareButtonClicked,
			label: `${gaEvents.pdpShareButtonClicked}`,
			nonInteraction: false
		})
		if (navigator.share) {
			navigator
				.share({
					title: `${title} | mybikeplan.ch`,
					text: `Check out ${title} on mybikeplan.ch`,
					url: document.location.href
				})
				.then()
				.catch(error => {
					console.error("Something went wrong sharing the page", error)
				})
		}
	}

	return (
		<div style={{height: 32, width: 32}}
			className={classes}
			onClick={handleOnClick}>
			<Image height={28}
				width={28}
				style={{minWidth: "20px"}}
				src="/assets/icons/share-btn.svg"
				alt="sharebtn" />
		</div>
	)
}

export default MobileShare

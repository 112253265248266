import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
	amount: number
	islistView?: boolean
	forSearchContentItem?: boolean
}

const StockWarning = ({amount, islistView, forSearchContentItem} : Props) => {
	const {t} = useTranslation()
	return (<>
		{amount ?
			<Typography variant={"bodySmBold"}
				semanticTag="span"
				className={"badge px-2 "}
				style={{
					borderRadius: "13px",
					color: "#C54809",
					fontSize: "11px",
					padding: forSearchContentItem ? "0px" : "1px 8px",
					height: forSearchContentItem ? "20px" : "",
					backgroundColor: "#C5480914"
				}}>{amount === 1 ? `${t("last-piece")}!` : (amount > 0 && amount < 5) ? `${t("only-yet")} ${amount} ${t("available")}!` : null}</Typography> : null
		}
	</>)
}

export default StockWarning

import React, {useContext, useState} from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {isCategory} from "../../../utility/Helper"
import Image from "next/image"
import Link from "next/link"
import {filterUrl} from "../../filter/FilterUrl"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"

type Props= {
    bike: any
}

const BikeSpecs: React.FC<Props> = ({bike}) => {
	const {t} = useTranslation()
	const {attributes} = bike
	const [showMore, setShowMore] = useState(false)
	const isSwissMade = isCategory(bike, "swiss-made")
	const {reactGA} = useContext(AnalyticsContext)

	const filteredAttributes = attributes?.filter(
		attribute =>
			!["hex-color", "youtube-link", "rider-height", "test-drive", "sizes"].includes(attribute.slug)
	)

	const desiredSlugs = [
		"brand",
		"speed",
		"battery",
		"weight",
		"motor-brand",
		"max-weight",
		"range",
		"attachments",
		"frame-variant"
	]

	const usedAttributes = new Set()

	const displayedAttributes = desiredSlugs.map(slug => {
		const match = filteredAttributes?.find(attr => attr.slug === slug)
		if (match) {
			usedAttributes.add(match.id) // Marks as used
			return match
		}

		return null
	})

	// Fills missing slots with remaining attributes
	const remainingAttributes = filteredAttributes?.filter(attr => !usedAttributes.has(attr?.id))
	displayedAttributes?.forEach((item, index) => {
		if (!item && remainingAttributes?.length > 0) {
			displayedAttributes[index] = remainingAttributes.shift()!
		}
	})

	const renderValue = (attribute: any) => {
		const value = t(attribute.terms?.[0]?.name)

		const linkableSlugs = [
			"brand",
			"speed",
			"battery",
			"weight",
			"motor-brand",
			"max-weight",
			"range",
			"battery-size",
			"filters-color"
		]

		if (linkableSlugs.includes(attribute.slug)) {
			return (
				<Link
					href={filterUrl({[attribute?.slug]: attribute?.terms?.[0]?.slug}, null, null)}
				>
					<a
						href={filterUrl({[attribute?.slug]: attribute?.terms?.[0]?.slug}, null, null)}
						className="new-mbp-link"
						target="_blank"
						rel="noopener noreferrer"
					>
						{value}
					</a>
				</Link>
			)
		}

		return value
	}

	const handleBtnClick = () => {
		setShowMore(prev => !prev)

		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.descriptionSection,
			label: showMore ? "Specs Section - Clicked Show More" : "Specs Section - Clicked Show Less",
			nonInteraction: false
		})
	}

	return (
		<div className="bike-specs">
			<div className="row flex-wrap">
				{[0, 1, 2].map(rowIndex => (
					<div className={`px-2 d-flex justify-content-between 
                        ${attributes?.length > 2 ? "mb-2 pb-1" : ""}`}
					key={`row-${rowIndex}`}>
						{displayedAttributes && displayedAttributes
							?.slice(rowIndex * 2, (rowIndex * 2) + 3)
							?.map((attribute, colIndex) => (
								<div className="spec-item px-2 col-6"
									key={`col-${rowIndex}-${colIndex}`}>
									{attribute ? (
										<div className="d-flex flex-column flex-md-row gap-md-2">
											<Typography variant="bodySm"
												semanticTag="span"
												className="text-secondary">
												{t(attribute?.name)}:
											</Typography>
											<div className={attribute?.slug === "brand" &&
                                                isSwissMade ? "d-flex align-items-center gap-1" : ""}>
												<Typography variant="bodySmBold"
													semanticTag="span"
												>
													{renderValue(attribute)}
												</Typography>
												{attribute?.slug === "brand" && isSwissMade &&
											<Image
												src="/assets/icons/switzerland-icon.svg"
												width={16}
												height={16}
												alt="swiss flag"
											/>}
											</div>
										</div>
									) : (
										<div></div>
									)}
								</div>
							))}
					</div>
				))}
			</div>
			{showMore && remainingAttributes?.length > 1 && (
				<div className="row px-md-2">
					{remainingAttributes?.map((attribute, index) => (
						<div className="col-6 mb-3 px-md-2"
							key={attribute.id + "-" + index}>
							<div className="d-flex flex-column flex-md-row gap-md-2">
								<Typography variant="bodySm"
									semanticTag="span"
									className="text-secondary">
									{t(attribute?.name)}:
								</Typography>
								<Typography variant="bodySmBold">
									{renderValue(attribute)}
								</Typography>
							</div>
						</div>
					))}
				</div>
			)}
			{remainingAttributes && remainingAttributes?.length > 1 && <div onClick={handleBtnClick}
				className="d-flex gap-2 pointer new-mbp-link">
				<Typography variant="bodySmBold"
					semanticTag="span">
					{showMore ? t("read-less") : t("read-more")}
				</Typography>
				<Image src={"/assets/icons/chevron-down-golden.svg"}
					height={6.5}
					width={12}
					alt="down"
					style={{transform: showMore ? "rotate(180deg)" : ""}}
				/>
			</div>}
		</div>
	)
}

export default BikeSpecs

import React, {useContext} from "react"
import CheckoutContext from "../../../context/CheckoutContext"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import {customPlanLengthMap} from "../../bikeDetails/plan/customPlanLengthMap"

type Props = {
	price: number,
	onSale: boolean,
	badgeOnly?: boolean,
	isSecondHand?: boolean,
	secondHandPrice?: number
	isRowForMobile?: boolean
	isAccessoryPrice?: boolean
	priceFontSize?: number
	discountedPrice?: number
	isListViewOnMobile?:boolean

}

const Price: React.FC<Props> = ({
	price,
	onSale,
	badgeOnly,
	isSecondHand,
	secondHandPrice,
	isRowForMobile,
	isAccessoryPrice,
	priceFontSize = 36,
	discountedPrice,
	isListViewOnMobile
}) => {
	const {data} = useContext(CheckoutContext)
	const {t} = useTranslation()

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = data?.planLength ? planLengthMap ? planLengthMap[data?.planLength] : data?.planLength : 48

	return (
		<>
			<div className={"row g-2 align-items-center"}>
				<div className={"col-12 col-md-12 mt-0"}>
					<div className={"row align-items-center g-0"}>
						<div className={"col-12"}>
							{onSale ?
								<>
									<div className={"col-12 d-block d-md-none"}
										style={{color: "#E61C45"}}>
										<Typography variant="bodyXSmBold"
											semanticTag="span">
												CHF
										</Typography>
										<Typography variant="heading1Black"
											semanticTag="h1"
											className={"mx-1 mb-0 d-inline-block"}
											style={{fontSize: isAccessoryPrice ? 20 : priceFontSize}}>
											{Math.ceil(discountedPrice / planLength)}
										</Typography>
										{planLength !== 1 && <Typography variant="bodyXSmBold"
											semanticTag="span">
											{"/m"} x {planLength}
										</Typography>}
									</div>
									<div className={"col-12 d-none d-md-block"}
										style={{color: "#E61C45"}}>
										<Typography variant="bodyXSmBold"
											semanticTag="span">
												CHF
										</Typography>
										<Typography
											variant="heading1Black"
											semanticTag="h1"
											className={`d-inline-block  mb-0 pb-0 px-md-1 ${isRowForMobile ? "" : "ps-3 pe-3"} text-size-12`}
											style={{fontSize: priceFontSize ? priceFontSize : ""}}
										>
											{`${Math.ceil(discountedPrice / planLength)}`}
										</Typography>
										{planLength !== 1 && <Typography variant="bodyXSmBold"
											semanticTag="span">
											{"/m"} x {planLength}
										</Typography>}
									</div>
								</> :
								isSecondHand ?
									<>
										<div className={"col-12 d-block d-md-none"}
											style={{color: "#E61C45"}}>
											<Typography variant="bodyXSmBold"
												semanticTag="span">
														CHF
											</Typography>
											<Typography variant="heading1Black"
												semanticTag="h1"
												className={"mx-1 mb-0 d-inline-block"}
												style={{fontSize: isAccessoryPrice ? 20 : priceFontSize}}>
												{Math.ceil(data && data?.planLength ? (secondHandPrice / data.planLength) : secondHandPrice / 12)}
											</Typography>
											{planLength !== 1 && <Typography variant="bodyXSmBold"
												semanticTag="span">
												{"/m"} x {planLength}
											</Typography>}
										</div>
										<div className={"col-12 d-none d-md-block"}
											style={{color: "#E61C45"}}>
											<Typography variant="bodyXSmBold"
												semanticTag="span">
														CHF
											</Typography>
											<Typography variant="heading1Black"
												semanticTag="h1"
												className={`d-inline-block  mb-0 p-2 pb-0 px-md-1 ${isRowForMobile ? "" : "ps-3 pe-3"} text-size-12`}>
												{Math.ceil(data && data?.planLength ? (secondHandPrice / data.planLength) : secondHandPrice / 12)}
											</Typography>
											{planLength !== 1 && <Typography variant="bodyXSmBold"
												semanticTag="span">
												{"/m"} x {planLength}
											</Typography>}
										</div>
									</> :
									<div className={"col-12 px-0 "}>
										<Typography style={{fontSize: isListViewOnMobile ? "12px" : "", lineHeight: isListViewOnMobile ? "16px" : ""}}
											variant="bodyXSmBold"
											semanticTag="span">
													CHF
										</Typography>
										<Typography variant="heading1Black"
											semanticTag="h1"
											className={"mx-1 mb-0 d-inline-block"}
											style={{fontSize: isAccessoryPrice ? 20 : isListViewOnMobile ? 24 : priceFontSize}}>
											{Math.ceil(data && planLength >= 12 ? (price / planLength) : price / planLength)}
										</Typography>
										{planLength !== 1 && <Typography style={{fontSize: isListViewOnMobile ? "12px" : "", lineHeight: isListViewOnMobile ? "16px" : ""}}
											variant="bodyXSmBold"
											semanticTag="span">
											{"/m"} x {planLength}
										</Typography>}
									</div>
							}
						</div>
						{badgeOnly && <div className={"col-auto"}>
							<small className={"d-inline d-md-none text-xs ms-2"}>
								{` / ${t("pro Monat")}`}
							</small>
						</div>}
					</div>
				</div>
			</div>
		</>
	)
}

export default Price

import React, {useState} from "react"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"

interface Props {
    question: any
}

const CustomerQuestionItem:React.FC<Props> = ({question}) => {
	const {t} = useTranslation("common")
	// This only applies to text that is translated, the original is in the foreign language, for the text that is already belonging to current locale, there's no translated question nor answer
	const [showTranslatedText, setShowTranslatedText] = useState(true)
	const {firstName, questionText, lastAdminAnswer, translatedQuestionText, translatedAdminAnswers, locale} = question
	const router = useRouter()
	const isNotOriginalLanguage = translatedQuestionText && Object.keys(translatedQuestionText)?.length && translatedAdminAnswers && Object.keys(translatedAdminAnswers)?.length && router.locale !== locale
	const {answerText} = lastAdminAnswer[0]
	const userName = `${firstName}`

	return (
		<div className="col-12 ">
			<Typography style={{fontSize: "18px", lineHeight: "26px"}}
				semanticTag="span"
				variant="bodySmBoldMdBodyLgBold">{isNotOriginalLanguage && showTranslatedText && router?.locale ? translatedQuestionText[router?.locale] : questionText}</Typography>
			<div className="col-12 d-flex align-items-end justify-content-start gap-2">
				<Typography className={"mb-0 mt-1"}
					semanticTag="p"
					style={{fontSize: "12px", lineHeight: "16px", color: "#212529"}}
					variant={"bodySm"}>
					{`${t("by-label")} ${userName}.`}
				</Typography>
				{ isNotOriginalLanguage ? <div className="col-6 d-flex align-items-end justify-content-start gap-1">
					<Typography className={"mb-0 mt-1"}
						semanticTag="p"
						style={{fontSize: "12px", lineHeight: "16px", color: "#6C7074"}}
						variant={"bodySm"}> {t("translated-by-label") }</Typography>
					<Image src="/assets/icons/google-logo-colored.svg"
						height={15}
						width={35}
						alt="Translated by google" />
					<Typography onClick={() => setShowTranslatedText(prev => !prev)}
						className={"mb-0 mt-1 cursor-pointer"}
						semanticTag="p"
						style={{fontSize: "12px", lineHeight: "16px", color: "#6C7074", marginLeft: "3px"}}
						variant={"bodySmBold"}> {showTranslatedText ? t("show-original-label") : t("show-translation-label") }</Typography>
				</div> : null}
			</div>
			<div className="position-relative  rounded  row align-items-center g-0 ">
				<div className="col-12">
					<div className="col-12 mt-3">
						<Typography semanticTag="span"
							style={{fontSize: "14px", lineHeight: "20px", marginBottom: "4px"}}
							variant="bodySm">{isNotOriginalLanguage && showTranslatedText && router?.locale ? translatedAdminAnswers[router?.locale] : answerText}</Typography>
					</div>
					<div className="d-flex gap-2 align-items-center mt-2">
						<div className="d-flex d-md-none">
							<Image src={"/assets/images/vale-avatar.svg"}
								width={30}
								height={30} />
						</div>
						<div className="d-none d-md-flex">
							<Image src={"/assets/images/vale-avatar.svg"}
								width={38}
								height={38} />
						</div>
						<Typography semanticTag="span"
							style={{fontSize: "12px", lineHeight: "16px", color: "#212529"}}
							variant="bodySm">{t("experts_answer")}</Typography>
					</div>
				</div>
				<div style={{marginTop: "-15px"}}
					className="position-absolute top-0"> </div>
			</div>
		</div>
	)
}

export default CustomerQuestionItem

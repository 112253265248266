import React, {useContext, useState} from "react"
import Button from "./components/Button"
import {colors} from "../../colors/Colors"
import {ModalContext} from "../../context/ModalContext"
import MyBikePlanAiChat from "./MyBikePlanAiChatModalContent"
import LayoutContext from "../../context/LayoutContext"
import {useTranslation} from "next-i18next"
import Image from "next/image"

function NavBarAI() {
	const {setModalContent, openDrawer, closeModal} = useContext(ModalContext)
	const {isMobile} = useContext(LayoutContext)
	const [isOpen, setIsOpen] = useState(false)
	const handleOpen = () => {
		if (isMobile !== true) {
			setModalContent(<MyBikePlanAiChat hideDrawerContent={false}
				isOpen={false}
				closeModal={closeModal} />)
			openDrawer()
		}

		setIsOpen(true)
	}

	const {t} = useTranslation()
	return (<>
		<div>
			<Button bgColor={colors.primary}
				onClick={handleOpen}
				textColor="#fff"
				className="try-ai position-relative"
				fontSizeSmall
				style={{maxWidth: isMobile ? 40 : null}}
				label={isMobile ? null : t("ai-search-label")}
				iconFirst={!isMobile}
				icon={<Image src={"/assets/icons/ryder-logo.svg"}
					width={24}
					height={24} />} />
		</div>
		<MyBikePlanAiChat hideDrawerContent={true}
			isOpen={isOpen && isMobile}
			closeModal={() => setIsOpen(false)} />
	</>
	)
}

export default NavBarAI

import React, {useEffect, useState} from "react"
import AnimationComponent from "../AnimationComponent/AnimationComponent"

type Props = {
    isOpen: boolean
    closeModal?:()=>void
    children: React.ReactNode,
	closeOnTouch?: boolean
	overflow?:string
    hasShadow?: boolean
}

function Drawer({isOpen, closeModal, children, hasShadow = true}: Props) {
	const [isDrawerOpen, setIsDrawerOpen] = useState(null)
	useEffect(() => {
		setIsDrawerOpen(isOpen)
	}, [isOpen])
	return (
		<>
			<AnimationComponent inProp={isDrawerOpen}
				type="fade">
				<div className="position-fixed vh-100 vw-100"
					style={{
						backgroundColor: "rgba(0,0,0,0.64)",
						zIndex: 100000005,
						top: 0, left: 0
					}}
					onClick={e => {
						e.stopPropagation()
						closeModal()
					}}>
				</div>
			</AnimationComponent>
			<div onClick={e => e.stopPropagation()}
				className={`position-fixed ${hasShadow && "shadow-left"} py-4 bg-white top-0 bottom-0 end-0 ${isDrawerOpen === "false" ? "slide-out-right" : isDrawerOpen ? "slide-in-right" : "d-none"}`}
				style={{
					zIndex: 1000000006,
					borderRadius: "16px 0px  0px  16px ",
					border: "1px solid #f4f4f4"
				}}>
				{children}
			</div>

		</>
	)
}

export default Drawer

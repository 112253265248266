import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react"
import {useTranslation} from "next-i18next"
import PropTypes from "prop-types"
import BikesCollectionItem from "../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import Typography from "../reusable/Typography/Typography"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import ComparisonPLPFixedComponent from "../bikesCollection/ComparisonPLPFixedComponent"
import AnalyticsContext from "../../context/AnalyticsContext"
import Button from "../reusable/components/Button"
import {BikeType} from "../../utility/Types"
import DataContext from "../../context/DataContext"
import {useRouter} from "next/router"
import {getItemsToHideInComparisonFromBike} from "../../utility/Helper"
import Switch from "../reusable/Switch"
import LayoutContext from "../../context/LayoutContext"
import SliderContent from "../reusable/components/SliderContent/SliderContent"
import useOnScreen from "../../hooks/OnScreen"

type Props = {
  similarBikes: any[],
  isAccessory?: boolean,
	hasComparisonSection
	currentBike?: BikeType
	setActiveLink?:Dispatch<SetStateAction<string>>
}

const SimilarBikes: React.FC<Props> = ({hasComparisonSection = true, setActiveLink, similarBikes, isAccessory, currentBike
}) => {
	const {t} = useTranslation()
	const tCommon = useTranslation().t
	const router = useRouter()
	const attributesToHide = useRef({})
	const {reactGA} = useContext(AnalyticsContext)
	const [onlyShowDifferences, setOnlyShowDifferences] = useState(false)
	const [currentSlide, setCurrentSlide] = useState(0)

	const {setComparisonProducts, comparisonProducts, comparisonAttributes} = useContext(DataContext)
	const {isMobile} = useContext(LayoutContext)
	useEffect(() => {
		if (JSON.parse(localStorage.getItem("pdpViewed"))) {
			const pageView = JSON.parse(localStorage.getItem("pdpViewed"))
			pageView.times++

			localStorage.setItem("pdpViewed", JSON.stringify(pageView))
		} else {
			localStorage.setItem("pdpViewed", JSON.stringify({viewed: true, times: 0}))
		}
	}, [])

	useEffect(() => {
		attributesToHide.current = {}
		attributesToHide.current = {
			...attributesToHide?.current,
			...getItemsToHideInComparisonFromBike(currentBike, comparisonAttributes)
		}
	}, [currentBike])

	const redirectToComparisonPage = () => {
		reactGA?.event({
			category: gaCategories.pdp,
			action: gaEvents.compareAllSimilarBikesOnPdp,
			label: `${gaEvents.compareAllSimilarBikesOnPdp}`,
			nonInteraction: false
		})
		const bikesToCompare = similarBikes?.map(bike => bike.slug)
		setComparisonProducts([...bikesToCompare, ...comparisonProducts])
		router.push(tCommon("/comparison-page"))
	}

	const similarBikesRef = useRef()

	const isOnScreen = useOnScreen(similarBikesRef)
	useEffect(() => {
		if (isOnScreen) {
			setActiveLink("Ähnliche E-Bikes")
		}
	}, [isOnScreen])
	return (
		<>
			{!similarBikes || similarBikes?.length < 1 ? null :
				<div
					style={{marginBottom: isAccessory ? "" : "5rem"}}
					className={"container"}>
					<div
						id="similar-products"
						style={{rowGap: isMobile ? "2rem" : "1rem"}}
						className={"d-flex flex-column justify-content-start align-items-center"}>
						<div className={"col-12 pt-5 pt-md-3"}>
							<div className="text-md-center">
								{isAccessory ? <Typography variant="bodyLgBlack"
									semanticTag="h2"
									style={{fontSize: 28}}>
									{t("Kompatible E-Bikes")}
								</Typography> :
									<div style={{marginBottom: similarBikes.length < 4 ? "2rem" : ""}}
										className="d-flex justify-content-start align-items-md-center
                                        align-items-start flex-column gap-1 gap-md-3">
										<Typography variant="bodyLgBlack"
											className="d-block d-md-none pb-2"
											semanticTag="h2"
											style={{fontSize: 28}}>
											{tCommon("Ähnliche E-Bikes")}
										</Typography>
										<Typography variant="heading2Black"
											className="d-none d-md-block pb-2"
											semanticTag="h2">
											{tCommon("Ähnliche E-Bikes")}
										</Typography>
										<div className="d-flex justify-content-center align-items-center gap-2">
											<Switch onClick={() => {
												reactGA?.event({
													category: gaCategories.pdp,
													action: gaEvents.onlyShowBikeDifferencesClicked,
													label: `${gaEvents.onlyShowBikeDifferencesClicked}`,
													nonInteraction: false
												})
												setOnlyShowDifferences(prev => !prev)
											}} />
											<Typography variant="bodySm"
												semanticTag="span"
												style={{fontSize: "12px"}}>
												{tCommon("display-differences-label")}
											</Typography></div>
									</div>}
								{hasComparisonSection &&
								<div className="d-none d-md-block mb-3">
									<ComparisonPLPFixedComponent sticky={false}/>
								</div>}
								<div ref={similarBikesRef}
									className="row mt-0 mb-md-4 justify-content-center similar-bikes">
									<div className="bikes-slider-wrapper d-block d-md-none mb-0 mt-3 pb-3"
										style={{minHeight: 345, maxHeight: 755}}>
										<SliderContent
											productsList={similarBikes}
											setCurrentSlide={setCurrentSlide}
											currentSlide={currentSlide}
											totalItems={similarBikes.length}
											slideWidth={isMobile ? 196 : 305}
											showComparisonAttributes={!isAccessory}
											attributesToHide={onlyShowDifferences ? attributesToHide.current : {}}
											isRowForMobile
										/>
									</div>
									{similarBikes?.length >= 4 ?
										<div className="bikes-slider-wrapper d-none mt-md-5
                                        d-md-block d-md-flex flex-wrap row col-12"
										style={{minHeight: 565, maxHeight: 755}}>
											<SliderContent
												productsList={similarBikes}
												setCurrentSlide={setCurrentSlide}
												currentSlide={currentSlide}
												totalItems={similarBikes.length}
												slideWidth={305}
												dots
												arrows
												arrowsPositionX={-20}
												showComparisonAttributes={!isAccessory}
												attributesToHide={onlyShowDifferences ? attributesToHide.current : {}}
											/>
										</div> :
										<div style={{
											minHeight: 500,
											width: "100%",
											marginBottom: similarBikes?.length < 4 ? "2rem" : ""}}
										className="d-none d-md-flex justify-content-center">
											{similarBikes?.map((bike, index) => {
												return (
													<div key={bike.id}
														className={"col-12 col-md-3"}>
														<BikesCollectionItem
															attributesToHide={onlyShowDifferences ? attributesToHide.current : {}}
															showIsCurrentBikeLabel={bike.id === currentBike.id && !isAccessory}
															showComparisonAttributes={!isAccessory}
															bike={bike}
														/>
													</div>
												)
											})}
										</div>}

								</div>
							</div>
						</div>
						{isAccessory ? null : <Button onClick={redirectToComparisonPage}
							bgColor="#FEC04D"
							textColor="black"
							label={t("view-all-comparisons-label")}
							btnFontSize={14}
							fontWeight={800}
							isNoUppercase
						/>}

					</div>
					<div className="d-md-none">
						<ComparisonPLPFixedComponent sticky={false}/>
					</div>
				</div>}
		</>
	)
}

SimilarBikes.propTypes = {
	similarBikes: PropTypes.array.isRequired
}

export default SimilarBikes

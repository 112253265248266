export const HubspotDealCategories = {
	"E-Urban": "E-Urban",
	"E-Trekking": "E-Trekking",
	"E-Mountain": "E-Mountain",
	Accessories: "Accessories",
	"Shipping request": "Shipping request",
	Bikes: "Bikes",
	"45 km": "45km/h",
	"Cargo E-Bikes": "Cargo E-Bikes",
	"e-urban": "E-Urban",
	"e-trekking": "E-Trekking",
	"e-mountain": "E-Mountain",
	accessoires: "Accessories",
	zubehoer: "Accessories",
	"nicht-elektrische-bikes": "Bikes",
	"velos-non-electriques": "Bikes",
	"45-km": "45km/h",
	"cargo-e-bikes": "Cargo E-Bikes",
	"gravel-bikes": "Gravel Bikes"
}

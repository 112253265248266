import {type NextRequest, NextResponse} from "next/server"

// Make sure the middleware only runs when
// the requested url starts with `/templates`
export const config = {
	matcher: ["/:path*"]
}

export enum variants {
	new="new",
	old="old",
	forceNew="force_new",
	forceOld="force_old"
}

export enum cookies {

	mbp_app_nav_variant="mbp_app_nav_variant"
}

const THRESHOLD = 0.2 // Initial threshold for the new variant (20%)
const COOKIE_NAME = "mbp_app_nav_variant" // Name of the cookie to store the variant

export function middleware(req: NextRequest) {
	const url = req.nextUrl.clone()
	const {pathname} = url

	if (
		pathname.startsWith("/_next") || // Exclude Next.js internal files
		pathname.startsWith("/static") || // Exclude static files
		pathname.startsWith("/api") || // Exclude API routes
		pathname.startsWith("/monitoring") || // Exclude Sentry routes
		pathname === "/favicon.ico" || // Exclude favicon
		pathname === "/robots.txt" || // Exclude robots.txt
		pathname.endsWith(".js") || // Exclude JavaScript files
		pathname.endsWith(".css") || // Exclude CSS files
		pathname.endsWith(".png") || // Exclude images
		pathname.endsWith(".jpg") || // Exclude images
		pathname.endsWith(".svg") || // Exclude SVG files
		pathname.endsWith(".json") // Exclude JSON files
	) {
		return NextResponse.next()
	}

	// Get the variant from the cookie
	// If not found, randomly set a variant based on threshold
	const variant =
		url.hostname === "localhost" ? variants.new : req.cookies.get(COOKIE_NAME) || (Math.random() < THRESHOLD ? variants.new : variants.old)

	const res = NextResponse.rewrite(url)

	// Set the variant in the cookie if not already set
	if (!req.cookies.get(COOKIE_NAME)) {
		res.cookies.set(COOKIE_NAME, variant)
	}

	return res
}

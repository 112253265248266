import React, {useContext, useEffect, useState} from "react"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import {X} from "react-bootstrap-icons"
import CartContext from "../../../context/CartContext"
import {isOccasionBike} from "../../../utility/Helper"
import Button from "../components/Button"

type Props = {
    closeModal: ()=>void
    currentBike: any
}

const CartCompatibilityModalContent: React.FC<Props> = ({closeModal, currentBike}) => {
	const {t} = useTranslation()
	const {items, addItem, removeItem} = useContext(CartContext)
	const router = useRouter()
	const [isCartBikesSelected, setCartBikesSelected] = useState(null)
	const [mapCurrentBike, setMapCurrentBike] = useState([])

	const [addingToCart, setAddingToCart] = useState(false)
	useEffect(() => {
		setMapCurrentBike([currentBike])
	}, [])

	useEffect(() => {
		if (router.pathname.includes("checkout")) {
			closeModal()
		}
	}, [router.pathname])

	const isIncludesOccassionBikes = arr => {
		if (arr?.filter(item => isOccasionBike(item.product)).length > 0) {
			return true
		}

		return false
	}

	const handlContinue = async () => {
		if (isCartBikesSelected) {
			closeModal()
		}

		if (!isCartBikesSelected) {
			items?.forEach(item => removeItem(item))
			addItem(currentBike)
			setAddingToCart(true)
			await router.push(t("/checkout")).then()
			// CloseModal()
		}
	}

	return (
		<div className="cart-compatibility-modal container bg-white px-1 pb-4 ps-lg-4 overflow-scroll scrollbar-hide">
			<div className={"row m-0 justify-content-end pt-2"}>
				<div className={"col-auto px-0 px-md-1"}>
					<button type={"button"}
						className={"btn py-0 px-0"}
						onClick={closeModal}>
						<X size={30}
							color={"black"}/>
					</button>
				</div>
			</div>

			<div className="row position-relative mt-1 mx-0 justify-content-between">
				<div className="col-12 bg-white d-flex justify-content-center align-items-center mb-3">
					<div className="container px-0">
						<div className="row mb-md-4">
							<Typography style={{fontSize: "20px", lineHeight: "32px"}}
								variant="bodyLgBold"
								semanticTag="h1"
								className="col-12 m-0 py-0">{t("Please select which e-bike you want to have in cart")}</Typography>
						</div>
					</div>
				</div>

				<div className="d-lg-flex mb-lg-4">
					{/* products from cart */}
					{items.length >= 1 && <div className="col-12 col-lg-6 mb-3 pe-lg-3">
						<Typography className={"text-secondary mb-3"}
							variant="bodyLgBold"
							semanticTag="p" >
							{isIncludesOccassionBikes(items) ? t("Secondhand e-bike") : t("Firsthand e-bike")}
						</Typography>
						<div className="cart-compatibility-modal-section overflow-scroll scrollbar-hide">
							{items.map(item => {
								const frame = item.product.attributes?.find(e => e.name === "Rahmenvarianten")?.options[0] ?? ""
								return (
									<div key={item.uniqueId}
										className={"row align-items-center mb-2"}>
										<div className={"col-12"}>
											<div className={"row align-items-center"}>
												<div className={"col-4"}>
													<img src={item.product.images[0]?.src}
														width={"100%"}
														height={"auto"}/>
												</div>
												<div className={"col-8"}>
													<div>
														<Typography variant="bodyLgBold"
															semanticTag="span">
															{item.product.name}
														</Typography>
													</div>
													<div>
														<Typography variant="bodyLg"
															semanticTag="span"
															className={"text-secondary"}>
															{frame && `${item.selectedSize}, ${frame}`}
														</Typography>
													</div>
												</div>
											</div>
										</div>

									</div>

								)
							})}
						</div>
						<div className="d-flex mt-2 mt-lg-0">
							<div className="me-2">
								<input className="form-check-input mt-0"
									type="radio"
									checked={isCartBikesSelected === true}
									onChange={() => setCartBikesSelected(true)}
									value=""
									aria-label=""></input>
							</div>
							<Typography variant="bodyLg"
								semanticTag="span">
								{isIncludesOccassionBikes(items) ? t("Add only secondhand e-bike to cart, remove firsthand e-bike") : t("Leave firsthand e-bike in cart, don’t add secondhand e-bike")}
							</Typography>
						</div>
					</div>}

					{/* current product */}
					{mapCurrentBike.length >= 1 && !addingToCart && <div className="col-12 col-lg-6 mb-3 ps-lg-3">
						<Typography className={"text-secondary mb-3"}
							variant="bodyLgBold"
							semanticTag="p" >
							{isIncludesOccassionBikes(mapCurrentBike) ? t("Secondhand e-bike") : t("Firsthand e-bike")}
						</Typography>
						{mapCurrentBike.map(item => {
							const frame = item.product.attributes?.find(e => e.name === "Rahmenvarianten")?.options[0] ?? ""
							return (
								<div key={item.uniqueId}
									className={"row align-items-center mb-2"}>
									<div className={"col-12"}>
										<div className={"row align-items-center"}>
											<div className={"col-4"}>
												<img src={item.product.images[0]?.src}
													width={"100%"}
													height={"auto"}/>
											</div>
											<div className={"col-8"}>
												<div>
													<Typography variant="bodyLgBold"
														semanticTag="span">
														{item.product.name}
													</Typography>
												</div>
												<div>
													<Typography variant="bodyLg"
														semanticTag="span"
														className={"text-secondary"}>
														{frame && `${item.selectedSize}, ${frame}`}
													</Typography>
												</div>
											</div>
										</div>
									</div>

								</div>
							)
						})}
						<div className="d-flex">
							<div className="me-2">
								<input className="form-check-input mt-0"
									type="radio"
									checked={isCartBikesSelected === false}
									onChange={() => setCartBikesSelected(false)}
									value=""
									aria-label=""></input>
							</div>
							<Typography variant="bodyLg"
								semanticTag="span">
								{isIncludesOccassionBikes(mapCurrentBike) ? t("Add only secondhand e-bike to cart, remove firsthand e-bike") : t("Leave firsthand e-bike in cart, don’t add secondhand e-bike")}
							</Typography>
						</div>
					</div>}

				</div>

				<div style={{maxWidth: "350px"}}
					className="d-flex justify-content-between">
					<div className="col-5">
						<Button className="w-100"
							color="light"
							label={t("Cancel")}
							outline
							fontSizeSmall
							onClick={closeModal}/>
					</div>
					<div className="col-5">
						<Button className={`w-100 ${isCartBikesSelected === null && "btn-disabled"}`}
							color="orange"
							label={t("Continue")}
							fontSizeSmall
							onClick={handlContinue}
							isLoading={addingToCart}
							spinnerColor={"white"}/>
					</div>
				</div>

			</div>
		</div>
	)
}

export default CartCompatibilityModalContent

import {useTranslation} from "next-i18next"
import React, {useContext} from "react"
import Typography from "../../reusable/Typography/Typography"
import CheckoutContext from "../../../context/CheckoutContext"
import {formatPrice} from "../../../utility/Helper"

type props = {
    handleEditFinanceBtnClick?: (isModalOpen?: boolean) => void
    finalPriceWithDownPayment?:any
		finalPriceWithoutDownPayment?: any
    montlyPriceSize?: number | string
    hasEditFinancingBtn?: boolean
    isSecondHand: boolean
    isInPopop?: boolean
    planLength: number
}
function PriceDisplay({
	finalPriceWithoutDownPayment,
	finalPriceWithDownPayment,
	handleEditFinanceBtnClick,
	hasEditFinancingBtn,
	montlyPriceSize,
	isSecondHand,
	planLength,
	isInPopop
}:props) {
	const {t} = useTranslation()
	const {data} = useContext(CheckoutContext)
	const checkoutData = data
	return (<div
		className="d-flex align-items-center justify-content-between text-start">
		<div>
			{checkoutData?.planLength > 1 ? <div className="col-auto ">
				<div className="d-flex align-items-end"
					style={{lineHeight: "28px"}}>
					<Typography style={{fontSize: "12px", lineHeight: "16px"}}
						variant={"bodySmBold"}
						semanticTag="span"
						className={"text-capitalize"}>
				CHF
					</Typography>
					<Typography variant={"heading2Black"}
						className="ms-1"
						semanticTag="span"
						style={{
							fontSize: montlyPriceSize ? montlyPriceSize : 36,
							lineHeight: "28px"
						}}>
						{`${Math.ceil(finalPriceWithDownPayment / planLength)}`}
					</Typography>
					<Typography style={{fontSize: "12px", lineHeight: "16px"}}
						variant={"bodySmBold"}
						semanticTag="span"
						className="ms-1">
				/ {`${t("pro Monat")}`} x {planLength}
					</Typography>
				</div>
				{!isSecondHand && <Typography style={{fontSize: "12px", lineHeight: "16px"}}
					variant="bodySm"
					semanticTag="div"
					className={"text-start col-12 text-capitalize mb-2"}>
					{`${t("Gesamtpreis")}: ${formatPrice(finalPriceWithoutDownPayment)}`}
				</Typography>}
			</div> : <div className="col-auto">
				<Typography variant={"heading1Black"}
					semanticTag="span">CHF</Typography>
				<Typography variant={"heading1Black"}
					className="ms-1"
					semanticTag="span"
					style={{
						fontSize: 36
					}}>
					{finalPriceWithoutDownPayment}
				</Typography>
			</div>}
		</div>
		{hasEditFinancingBtn &&
		<Typography variant="bodyXSmBold"
			semanticTag="p"
			className="mb-0 new-mbp-link cursor-pointer"
			onClick={handleEditFinanceBtnClick}
		>{t("edit-plan")}</Typography>
		}
	</div>)
}

export default PriceDisplay

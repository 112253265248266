import React, {FC, useCallback, useEffect} from "react"
import AnimationComponent, {AnimationType} from "../AnimationComponent/AnimationComponent"

type Props = {
    isOpen: boolean
	type?: keyof typeof AnimationType
    closeModal?:()=>void
    children: React.ReactNode,
	closeOnTouch?: boolean
	overflow?:string
}

const Modal: FC<Props> = ({closeOnTouch = true, isOpen, type = "fade", closeModal, children, overflow = "auto"}):React.ReactElement => {
	const handlePressEscapeToCloseModal = useCallback(e => {
		if (e.code === "Escape") {
			closeModal()
		}
	}, [])

	useEffect(() => {
		document.addEventListener("keydown", handlePressEscapeToCloseModal)

		return () => document.removeEventListener("keydown", handlePressEscapeToCloseModal)
	}, [isOpen])

	return (
		<>
			<AnimationComponent inProp={isOpen}
				type={type}>

				<div className="position-fixed "
					style={{
						height: "100%",
						width: "100%",
						backgroundColor: "rgba(0,0,0,0.7)",
						zIndex: 999999999,
						top: 0, left: 0
					}}

					onClick={ () => {
						// Close on touching
						if (closeOnTouch) {
							closeModal()
						}
						// Do nothing
					}}
				>

					<div className={"container-fluid"}>
						<div className={"row justify-content-center align-items-start align-items-md-center pt-3 pt-md-0 vh-100"}>
							<div className={"col-auto p-0"}
								style={{overflow}}
								onClick={e => e.stopPropagation()}>
								{children}
							</div>
						</div>
					</div>
				</div>
			</AnimationComponent>
		</>
	)
}

export default Modal
